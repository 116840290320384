(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function EnvConfig($compileProvider) {
  'ngInject';

  $compileProvider.debugInfoEnabled(false);
}

_angular2.default.module('gnaas.app').config(EnvConfig).constant('APP_ENV', window.env['APP_ENV']).constant('APP_ORG', window.env['APP_ORG']).constant('APP_ORG_SHORT', window.env['APP_ORG_SHORT']).constant('API_URL', window.env['API_URL'] || '').constant('BING_MAPS_KEY', window.env['BING_MAPS_KEY']).constant('GOOGLE_GEOCODING_KEY', window.env['GOOGLE_GEOCODING_KEY']);

},{"angular":"angular"}],2:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  controller: ['APP_ENV', 'Session', function (APP_ENV, Session) {
    this.isProductionEnvironment = APP_ENV === 'production';
    this.isAuthenticated = function () {
      return Session.exists() && !!Session.user() && !Session.user().should_change_password;
    };
  }],
  template: '\n    <div ng-if="!$ctrl.isProductionEnvironment" class="u-login-warning">\n        TRAINING VERSION - do not use for real patients/missions.\n    </div>\n\n    <div layout="row" class="app" flex>\n        <md-sidenav ng-if="$ctrl.isAuthenticated()"\n                    class="md-sidenav-left app-sidenav"\n                    md-theme="dark"\n                    hide-print\n                    md-component-id="sidenav-main"\n                    md-is-locked-open="$mdMedia(\'gt-md\')"\n                    md-disable-backdrop\n                    md-whiteframe="2">\n            <sidenav-menu layout="column"></sidenav-menu>\n        </md-sidenav>\n\n        <div flex role="main" tabindex="-1" layout="column">\n              <topbar ng-if="$ctrl.isAuthenticated()"></topbar>\n              <div ui-view layout="column" flex></div>\n        </div>\n    </div>'
};

},{}],3:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = AppConfig;

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _leaflet = require('leaflet');

var _leaflet2 = _interopRequireDefault(_leaflet);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

_moment2.default.updateLocale('en', {
  week: {
    dow: 1
  }
});

_leaflet2.default.Icon.Default.imagePath = 'assets/images/leaflet/';

function AppConfig($urlRouterProvider, $httpProvider, $mdThemingProvider, $mdDateLocaleProvider, JwtInterceptorProvider, $mdGestureProvider) {
  'ngInject';

  /**
   * ROUTING
   */

  $urlRouterProvider.otherwise('/');

  /**
   * HTTP
   */
  // $httpProvider.defaults.withCredentials = true
  $httpProvider.defaults.headers.common.Accept = 'application/x.arc-ems.v1+json';
  $httpProvider.defaults.headers.post['Content-Type'] = 'application/json';
  $httpProvider.defaults.headers.put['Content-Type'] = 'application/json';
  $httpProvider.interceptors.push(['$injector', function ($injector) {
    return $injector.get('JwtInterceptor');
  }]);

  /**
   * Locale
   */
  $mdDateLocaleProvider.formatDate = function (date) {
    return date ? (0, _moment2.default)(date).format('DD-MM-YYYY') : '';
  };

  $mdDateLocaleProvider.parseDate = function (dateString) {
    var m = (0, _moment2.default)(dateString, 'DD-MM-YYYY', true);
    return m.isValid() ? m.toDate() : new Date(NaN);
  };

  $mdGestureProvider.skipClickHijack();

  /**
   * THEMING
   *
   * For primary & warn:
   * 500 - default
   * 300 - hue 1
   * 800 - hue 2
   * A100 - hue 3
   *
   * For accent:
   * A200 - default
   * A100 - hue 1
   * A400 - hue 2
   * A700 - hue 3
   *
   * For background:
   * 50 - default
   * 800 - dark default
   */
  var gnaasPrimary = $mdThemingProvider.extendPalette('blue-grey', {
    '500': '4C617C',
    '300': '2D5744',
    '800': '304055',
    'A100': 'FFFFFF',
    'contrastDefaultColor': 'light',
    'contrastDarkColors': ['A100']
  });
  $mdThemingProvider.definePalette('gnaasPrimary', gnaasPrimary);

  var gnaasAccent = $mdThemingProvider.extendPalette('amber', {
    'A200': 'FFC742',
    // 'A100': '',
    'A400': 'CFCFCF',
    'A700': '838383',
    'contrastLightColors': ['A700']
  });
  $mdThemingProvider.definePalette('gnaasAccent', gnaasAccent);

  var gnaasBackground = $mdThemingProvider.extendPalette('grey', {
    '50': 'FFFFFF',
    '800': '282828'
  });
  $mdThemingProvider.definePalette('gnaasBackground', gnaasBackground);

  $mdThemingProvider.theme('default').primaryPalette('gnaasPrimary').accentPalette('gnaasAccent').warnPalette('red').backgroundPalette('gnaasBackground');

  $mdThemingProvider.theme('dark').dark();
}

},{"leaflet":"leaflet","moment":"moment"}],4:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

require('angular-animate');

require('angular-aria');

require('angular-messages');

require('angular-material');

require('angular-sanitize');

require('angular-filter');

require('angular-ui-grid');

var _angularUiRouter = require('angular-ui-router');

var _angularUiRouter2 = _interopRequireDefault(_angularUiRouter);

var _app = require('./app.config');

var _app2 = _interopRequireDefault(_app);

var _app3 = require('./app.run');

var _app4 = _interopRequireDefault(_app3);

var _app5 = require('./app.component');

var _app6 = _interopRequireDefault(_app5);

var _module = require('./common/module');

var _module2 = _interopRequireDefault(_module);

var _module3 = require('./core/module');

var _module4 = _interopRequireDefault(_module3);

var _module5 = require('./pages/module');

var _module6 = _interopRequireDefault(_module5);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var appModule = _angular2.default.module('gnaas.app', ['ngAnimate', 'ngAria', 'ngMessages', 'ngMaterial', 'ngSanitize', 'angular.filter', _angularUiRouter2.default, _module2.default, _module4.default, _module6.default]).config(_app2.default).run(_app4.default).component('app', _app6.default);

exports.default = appModule;

},{"./app.component":2,"./app.config":3,"./app.run":5,"./common/module":48,"./core/module":172,"./pages/module":374,"angular":"angular","angular-animate":"angular-animate","angular-aria":"angular-aria","angular-filter":"angular-filter","angular-material":"angular-material","angular-messages":"angular-messages","angular-sanitize":"angular-sanitize","angular-ui-grid":"angular-ui-grid","angular-ui-router":"angular-ui-router"}],5:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = AppRun;

var _stateChangeHandlers = require('common/auth/services/stateChangeHandlers');

function AppRun($rootScope, $injector, JWT) {
  'ngInject';

  $rootScope.$on('$stateChangeSuccess', function (e, to) {
    document.title = to.data && to.data.title ? 'ARC-EMS | ' + to.data.title : 'ARC-EMS';
  });

  var initStateChange = $rootScope.$on('$stateChangeStart', function (e, to, toParams) {
    initStateChange();
    var initStateChangeHandler = JWT.exists() ? _stateChangeHandlers.stateChangeHandlerWithToken : _stateChangeHandlers.stateChangeHanlerWithoutToken;
    $injector.invoke(initStateChangeHandler)(e, to, toParams);
  });
}

},{"common/auth/services/stateChangeHandlers":13}],6:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var AUTH_EVENTS = exports.AUTH_EVENTS = {
  sessionUserUpdated: 'auth:sessionUserUpdated'
};

},{}],7:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _authResource = require('./services/authResource');

var _authResource2 = _interopRequireDefault(_authResource);

var _idleCheck = require('./services/idleCheck');

var _idleCheck2 = _interopRequireDefault(_idleCheck);

var _jwt = require('./services/jwt');

var _jwt2 = _interopRequireDefault(_jwt);

var _jwtInterceptor = require('./services/jwtInterceptor');

var _jwtInterceptor2 = _interopRequireDefault(_jwtInterceptor);

var _session = require('./services/session');

var _session2 = _interopRequireDefault(_session);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _module = _angular2.default.module('gnaas.common.auth', []).provider('JwtInterceptor', _jwtInterceptor2.default).service('AuthResource', _authResource2.default).service('IdleCheck', _idleCheck2.default).service('JWT', _jwt2.default).service('Session', _session2.default);

exports.default = _module.name;

},{"./services/authResource":8,"./services/idleCheck":9,"./services/jwt":10,"./services/jwtInterceptor":11,"./services/session":12,"angular":"angular"}],8:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _crewMemberResource = require('pages/crewMembers/services/crewMemberResource');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var AuthResource = function () {
  /**
   * @constructor
   * @ngInject
   */
  function AuthResource($http, API_URL) {
    _classCallCheck(this, AuthResource);

    this.$http = $http;
    this.AUTH_URL = API_URL + 'auth';
  }

  _createClass(AuthResource, [{
    key: 'login',
    value: function login(email, password) {
      return this.$http.post('' + this.AUTH_URL, { email: email, password: password }).then(function (response) {
        return response.data;
      });
    }
  }, {
    key: 'logout',
    value: function logout() {
      return this.$http.delete('' + this.AUTH_URL).then(function (response) {
        return response.data;
      });
    }
  }, {
    key: 'getUser',
    value: function getUser() {
      return this.$http.get('' + this.AUTH_URL).then(function (response) {
        return (0, _crewMemberResource.format)(response.data.data);
      });
    }
  }, {
    key: 'refresh',
    value: function refresh() {
      return this.$http.put('' + this.AUTH_URL).then(function (response) {
        return response.data;
      });
    }
  }, {
    key: 'check',
    value: function check() {
      var refresh = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

      return this.$http.get(this.AUTH_URL + '/check?refresh_flow=' + refresh).then(function (response) {
        return response.data;
      });
    }
  }]);

  return AuthResource;
}();

exports.default = AuthResource;

},{"pages/crewMembers/services/crewMemberResource":362}],9:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Calculate timer delay, based off token TTL.
 * Add 1 second delay padding.
 *
 * @param payload JWT payload
 * @returns {number} Timer delay (ms)
 */
function getDelay(payload) {
  var diff = payload.exp - payload.iat;
  var tokenTtlInMinutes = Math.floor(diff / 60);
  var result = tokenTtlInMinutes * 60 * 1000 + 1000;

  // timeouts that overflow a 32 bit int resolve immediately
  if (result > 0x7FFFFFFF) {
    return 0x7FFFFFFF;
  }

  return result;
}

var IdleCheck = function () {
  /**
   * @constructor
   * @ngInject
   */
  function IdleCheck($state, $timeout, AuthResource, JWT, Session) {
    _classCallCheck(this, IdleCheck);

    this.$state = $state;
    this.$timeout = $timeout;
    this.AuthResource = AuthResource;
    this.JWT = JWT;
    this.Session = Session;
    this.timer = null;
  }

  _createClass(IdleCheck, [{
    key: 'start',
    value: function start() {
      var _this = this;

      this.stop();

      this.timer = this.$timeout(function () {
        _this.AuthResource.check(true).then(function () {
          _this.start();
        }).catch(function () {
          _this.JWT.destroy();
          _this.Session.destroy();
          _this.stop();
          _this.$state.go('login', { previousState: Object.assign({}, _this.$state.current, { params: _this.$state.params }) });
        });
      }, getDelay(this.JWT.parse()));
    }
  }, {
    key: 'stop',
    value: function stop() {
      if (this.timer === null) return;
      this.$timeout.cancel(this.timer);
      this.timer = null;
    }
  }]);

  return IdleCheck;
}();

exports.default = IdleCheck;

},{}],10:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/* global atob */

var TOKEN_KEY = 'X_AUTH_TOKEN';

var JWT = function () {
  function JWT() {
    _classCallCheck(this, JWT);
  }

  _createClass(JWT, [{
    key: 'set',

    /**
     * Set JWT.
     *
     * @param token
     */
    value: function set(token) {
      window.localStorage.setItem(TOKEN_KEY, token);
    }

    /**
     * Get JWT.
     *
     * @returns {string | null}
     */

  }, {
    key: 'get',
    value: function get() {
      return window.localStorage.getItem(TOKEN_KEY);
    }

    /**
     * JWT exists.
     *
     * @returns {boolean}
     */

  }, {
    key: 'exists',
    value: function exists() {
      var token = window.localStorage.getItem(TOKEN_KEY);
      return typeof token !== 'undefined' && token != null;
    }

    /**
     * Destroy JWT.
     */

  }, {
    key: 'destroy',
    value: function destroy() {
      window.localStorage.removeItem(TOKEN_KEY);
    }
  }, {
    key: 'parse',
    value: function parse() {
      if (this.get() == null) return null;

      var base64Url = this.get().split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
    }
  }]);

  return JWT;
}();

exports.default = JWT;

},{}],11:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = JwtInterceptor;
function JwtInterceptor() {
  var refreshInProgress = false;
  var requestBuffer = [];

  var isProtectedState = function isProtectedState(stateName) {
    return ['', 'login', 'passwordReset'].indexOf(stateName) === -1;
  };

  this.$get = function ($injector, $q, API_URL, JWT, Session) {
    'ngInject';

    var $http = void 0,
        $state = void 0,
        AuthResource = void 0,
        Dialog = void 0,
        IdleCheck = void 0;

    var isAuthRequest = function isAuthRequest(config) {
      var method = config.method.toUpperCase();
      return ~['PUT', 'POST'].indexOf(method) && config.url === API_URL + 'auth' || method === 'GET' && ~config.url.indexOf(API_URL + 'auth/check');
    };

    var retryRequests = function retryRequests() {
      var _loop = function _loop() {
        var _requestBuffer$shift = requestBuffer.shift(),
            config = _requestBuffer$shift.config,
            deferred = _requestBuffer$shift.deferred;

        $http(config).then(function (data) {
          return deferred.resolve(data);
        }).catch(function (err) {
          return deferred.reject(err);
        });
      };

      while (requestBuffer.length) {
        _loop();
      }
    };

    var refreshToken = function refreshToken(config) {
      refreshInProgress = true;
      $http = $http || $injector.get('$http');
      AuthResource = AuthResource || $injector.get('AuthResource');
      IdleCheck = IdleCheck || $injector.get('IdleCheck');

      var deferred = $q.defer();

      AuthResource.refresh().then(function (data) {
        refreshInProgress = false;
        JWT.set(data.meta.token);
        IdleCheck.start();
        retryRequests();
        return $http(config);
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (err) {
        refreshInProgress = false;
        deferred.reject(err);
      });

      return deferred.promise;
    };

    var clearData = function clearData() {
      $state = $state || $injector.get('$state');
      Dialog = Dialog || $injector.get('Dialog');
      IdleCheck = IdleCheck || $injector.get('IdleCheck');

      JWT.destroy();
      Session.destroy();
      IdleCheck.stop();
      Dialog.cancel();
      if (isProtectedState($state.current.name)) {
        $state.go('login', { previousState: Object.assign({}, $state.current, { params: $state.params }) });
      }
    };

    return {
      request: function request(config) {
        if (config.url.indexOf(API_URL) === 0) {
          config.headers['Authorization'] = 'Bearer ' + JWT.get();
        }
        return config;
      },
      responseError: function responseError(err) {
        if (err.status === 401) {
          if (!isAuthRequest(err.config)) {
            if (refreshInProgress) {
              var _deferred = $q.defer();
              requestBuffer.push({ config: err.config, deferred: _deferred });
              return _deferred.promise;
            }
            return refreshToken(err.config);
          }
          clearData();
        }

        return $q.reject(err);
      }
    };
  };
};

},{}],12:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _module = require('common/auth/module.constants');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Session = function () {
  /**
   * @constructor
   * @ngInject
   */
  function Session($rootScope) {
    _classCallCheck(this, Session);

    this._rootScope = $rootScope;

    this._user = {};
    this._attributes = {};
    this._initialized = false;
  }

  _createClass(Session, [{
    key: 'start',
    value: function start(user, attributes) {
      if (this._initialized) {
        throw new Error('Session already exists.');
      }

      this._initialized = true;

      this._user = Object.assign(user);

      if (Array.isArray(attributes) && attributes.length > 0) {
        attributes = attributes.reduce(function (previous, current) {
          return Object.assign(previous, current);
        });
      }

      Object.assign(this._attributes, attributes);
    }
  }, {
    key: 'destroy',
    value: function destroy() {
      this._user = {};
      this._attributes = {};
      this._initialized = false;
    }
  }, {
    key: 'exists',
    value: function exists() {
      return this._initialized;
    }
  }, {
    key: 'set',
    value: function set(attribute, value) {
      this._attributes[attribute] = value;
    }
  }, {
    key: 'get',
    value: function get(attribute) {
      return this._attributes[attribute];
    }
  }, {
    key: 'user',
    value: function user() {
      return this._user;
    }
  }, {
    key: 'setUser',
    value: function setUser(user) {
      this._user = Object.assign(user);
      this._rootScope.$emit(_module.AUTH_EVENTS.sessionUserUpdated);
    }
  }, {
    key: 'updateShouldChangePassword',
    value: function updateShouldChangePassword(value) {
      if (!this._user) {
        return;
      }

      this._user.should_change_password = value;
    }
  }, {
    key: 'isAuthenticatedUser',
    value: function isAuthenticatedUser(id) {
      if (!this._user) {
        return false;
      }

      return this._user.id === id;
    }
  }, {
    key: 'isSysadminUser',
    value: function isSysadminUser() {
      return this._user.group != null && this._user.group.slug === 'system-administrators';
    }
  }]);

  return Session;
}();

exports.default = Session;

},{"common/auth/module.constants":6}],13:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.stateChangeHandlerWithToken = stateChangeHandlerWithToken;
exports.stateChangeHanlerWithoutToken = stateChangeHanlerWithoutToken;
function isProtectedState(stateName) {
  return ['', 'login', 'passwordReset'].indexOf(stateName) === -1;
}

function stateChangeHandler($state, Session) {
  'ngInject';

  return function (e, to) {
    if (Session.exists() && !isProtectedState(to.name) || !Session.exists() && isProtectedState(to.name)) {
      e.preventDefault();
    }

    if (Session.exists() && Session.user().group.slug === 'charity-staff' && $state.current.name !== 'calendar') {
      $state.go('calendar');
    }
  };
}

function stateChangeHandlerWithToken($injector, $rootScope, $state, AuthResource, IdleCheck, Session) {
  'ngInject';

  return function (e, to, toParams) {
    e.preventDefault();
    $rootScope.$on('$stateChangeStart', $injector.invoke(stateChangeHandler));

    AuthResource.getUser().then(function (data) {
      Session.start(data);
      IdleCheck.start();

      if (data.should_change_password) {
        $state.go('changePassword');
        return;
      }

      if (Session.user().group.slug === 'charity-staff') {
        $state.go('calendar');
        return;
      }
      var next = isProtectedState(to.name) ? to.name : 'dashboard';
      $state.go(next, toParams);
    }).catch(function () {
      var next = Session.exists() && isProtectedState(to.name) ? to.name : 'login';
      var params = next === 'login' && to.name !== next ? { previousState: Object.assign({}, to, { params: toParams }) } : {};
      $state.go(next, params);
    });
  };
}

function stateChangeHanlerWithoutToken($injector, $rootScope, $state) {
  'ngInject';

  return function (e, to, toParams) {
    $rootScope.$on('$stateChangeStart', $injector.invoke(stateChangeHandler));

    if (isProtectedState(to.name)) {
      e.preventDefault();
      $state.go('login', { previousState: Object.assign({}, to, { params: toParams }) });
    }
  };
}

},{}],14:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function detectLeftButton() {
  var evt = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : window.event;

  if ('buttons' in evt) {
    return evt.buttons === 1;
  }
  var button = evt.which || evt.button;
  return button === 1;
}

function calcDistancePercentageMovedX(elX, svgWidth) {
  return elX / svgWidth * 100 + '%';
}

function calcDistancePercentageMovedY(elY, svgHeight) {
  return elY / svgHeight * 100 + '%';
}

var BodyPartSelector = function () {
  function BodyPartSelector() {
    _classCallCheck(this, BodyPartSelector);
  }

  _createClass(BodyPartSelector, [{
    key: '$onInit',
    value: function $onInit() {
      var _this = this;

      var bodySvg = document.getElementById('body-svg');
      this.svgWidth = bodySvg.getAttribute('width');
      this.svgHeight = bodySvg.getAttribute('height');
      this.circleRadius = document.getElementById('marker').getAttribute('r');

      if (this.item) {
        this.item = this.updateMarkerPosition(this.item, this.item.x, this.item.y);
      }

      if (this.markers) {
        this.markers.forEach(function (item) {
          return _this.updateMarkerPosition(item, item.x, item.y);
        });
      }
    }
  }, {
    key: '$onChanges',
    value: function $onChanges(changesObj) {
      var _this2 = this;

      if (!changesObj.markers || !changesObj.markers.currentValue) return;

      this.markers = changesObj.markers.currentValue.map(function (item) {
        item = _this2.updateMarkerPosition(item, item.x, item.y);
        return item;
      }).sort(function (a, b) {
        if (a.class === 'green') {
          return -1;
        }
        if (a.class === 'red') {
          return 1;
        }
        return 0;
      });
    }
  }, {
    key: 'move',
    value: function move(evt) {
      if (!detectLeftButton(evt) || !this.item) {
        return;
      }
      evt.preventDefault();
      this.item = this.updateMarkerPosition(this.item, evt.offsetX, evt.offsetY);
    }
  }, {
    key: 'updateMarkerPosition',
    value: function updateMarkerPosition(marker, x, y) {
      marker.x = x;
      marker.y = y;
      marker.cx = calcDistancePercentageMovedX(x - this.circleRadius / 2, this.svgWidth);
      marker.cy = calcDistancePercentageMovedY(y - this.circleRadius / 2, this.svgHeight);
      return marker;
    }
  }]);

  return BodyPartSelector;
}();

exports.default = {
  bindings: {
    item: '<',
    markers: '<',
    isEditing: '<'
  },
  controller: BodyPartSelector,
  templateUrl: 'common/body-part-selector/components/body-part-selector.tpl.html'
};

},{}],15:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ngCx;
function ngCx() {
  return function (scope, elem, attrs) {
    attrs.$observe('ngCx', function (x) {
      elem.attr('cx', x);
    });
  };
};

},{}],16:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ngCy;
function ngCy() {
  return function (scope, elem, attrs) {
    attrs.$observe('ngCy', function (y) {
      elem.attr('cy', y);
    });
  };
};

},{}],17:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _bodyPartSelector = require('./components/body-part-selector.component');

var _bodyPartSelector2 = _interopRequireDefault(_bodyPartSelector);

var _ngCx = require('./directives/ngCx');

var _ngCx2 = _interopRequireDefault(_ngCx);

var _ngCy = require('./directives/ngCy');

var _ngCy2 = _interopRequireDefault(_ngCy);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _module = _angular2.default.module('gnaas.common.body-part-selector', []).component('bodyPartSelector', _bodyPartSelector2.default).directive('ngCx', _ngCx2.default).directive('ngCy', _ngCy2.default);

exports.default = _module.name;

},{"./components/body-part-selector.component":14,"./directives/ngCx":15,"./directives/ngCy":16,"angular":"angular"}],18:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = {
    bindings: {
        inverted: '@',
        title: '@',
        text: '@',
        image: '@',
        icon: '@',
        onClick: '&'
    },
    controller: function controller() {},
    template: function template($element, $attrs) {
        'ngInject';

        var clickEvent = $attrs.onClick ? 'ng-click="$ctrl.onClick()"' : '';

        return '<md-list-item class="md-2-line md-list-item-no-padding c-avatar-item" ' + clickEvent + '>\n        <md-icon ng-if="$ctrl.icon" md-svg-src="{{ $ctrl.icon }}" aria-label="avatar"\n            class="md-icon-48 md-avatar-icon c-avatar-item__avatar--svg">\n        </md-icon>\n\n        <img ng-if="$ctrl.image || !$ctrl.image && !$ctrl.icon" class="c-avatar-item__avatar" alt=""\n            ng-src="{{ $ctrl.image ? $ctrl.image : \'assets/images/temporary/crewMemberPlaceholder.png\' }}" />\n\n        <div class="md-list-item-text">\n            <h3 ng-if="!$ctrl.inverted">{{ $ctrl.title }}</h3>\n            <p class="f-label">{{ $ctrl.text }}</p>\n            <h3 ng-if="$ctrl.inverted">{{ $ctrl.title }}</h3>\n        </div>\n    </md-list-item>';
    }
};

},{}],19:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  bindings: {
    onClick: '&',
    label: '@'
  },
  controller: function controller() {},
  template: '<md-button aria-label="{{ $ctrl.label }}" class="md-icon-button" ng-click="$ctrl.onClick()">\n        <md-icon md-svg-icon="assets/images/icons/add-icon.svg" aria-label="Plus icon"></md-icon>\n  </md-button>'
};

},{}],20:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  bindings: {
    onClick: '&',
    label: '@'
  },
  controller: function controller() {},
  template: '<md-button aria-label="{{ $ctrl.label }}" class="md-icon-button" ng-click="$ctrl.onClick()">\n        <md-icon md-svg-icon="assets/images/icons/minus-icon.svg" aria-label="Minus icon"></md-icon>\n  </md-button>'
};

},{}],21:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  templateUrl: 'common/components/drop-down/drop-down.tpl.html',
  controller: function controller() {},

  bindings: {
    itemsName: '@',
    items: '<',
    label: '@'
  }
};

},{}],22:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var InformationBarController = exports.InformationBarController = function () {
  function InformationBarController() {
    _classCallCheck(this, InformationBarController);
  }

  _createClass(InformationBarController, [{
    key: '$onInit',
    value: function $onInit() {
      this.icon = this.getIcon();
    }
  }, {
    key: 'getIcon',
    value: function getIcon() {
      if (this.type === 'warn') {
        return 'error';
      }
      return this.type;
    }
  }]);

  return InformationBarController;
}();

exports.default = {
  templateUrl: 'common/components/information-bar/information-bar.tpl.html',
  controller: InformationBarController,
  transclude: true,
  bindings: {
    type: '@'
  }
};

},{}],23:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  controller: function controller() {},

  bindings: {
    isLoading: '<'
  },
  template: '\n    <span class="loading-indicator">\n      <md-progress-linear md-mode="query" ng-if="$ctrl.isLoading" class="md-accent"></md-progress-linear>\n    </span>\n  '
};

},{}],24:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _avatarItem = require('./avatar-item/avatar-item.component');

var _avatarItem2 = _interopRequireDefault(_avatarItem);

var _dropDown = require('./drop-down/drop-down');

var _dropDown2 = _interopRequireDefault(_dropDown);

var _searchGrid = require('./search-grid/search-grid');

var _searchGrid2 = _interopRequireDefault(_searchGrid);

var _informationBar = require('./information-bar/information-bar');

var _informationBar2 = _interopRequireDefault(_informationBar);

var _addButton = require('./buttons/add-button.component');

var _addButton2 = _interopRequireDefault(_addButton);

var _removeButton = require('./buttons/remove-button.component');

var _removeButton2 = _interopRequireDefault(_removeButton);

var _tabGroup = require('./tab-group/tab-group');

var _tabGroup2 = _interopRequireDefault(_tabGroup);

var _loadingIndicator = require('./loading-indicator/loading-indicator');

var _loadingIndicator2 = _interopRequireDefault(_loadingIndicator);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _module = _angular2.default.module('gnaas.common.components', []).component('avatarItem', _avatarItem2.default).component('dropDown', _dropDown2.default).component('searchGrid', _searchGrid2.default).component('informationBar', _informationBar2.default).component('addButton', _addButton2.default).component('removeButton', _removeButton2.default).component('tabGroup', _tabGroup2.default).component('loadingIndicator', _loadingIndicator2.default);

exports.default = _module.name;

},{"./avatar-item/avatar-item.component":18,"./buttons/add-button.component":19,"./buttons/remove-button.component":20,"./drop-down/drop-down":21,"./information-bar/information-bar":22,"./loading-indicator/loading-indicator":23,"./search-grid/search-grid":25,"./tab-group/tab-group":26,"angular":"angular"}],25:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var ACTION_COL_TPL = '<div class="ui-grid-cell-contents t-no-padding" title="actions">\n      <md-button class="md-button-low md-button-narrow"\n          ng-repeat="action in grid.appScope.$ctrl.actions track by $index"\n          ng-if="!action.isHidden(row.entity)"\n          ng-click="action.callback(row.entity)">{{ action.name }}</md-button>\n  </div>';

var VALID_COLUMN_DEF_PROPS = ['cellClass', 'cellFilter', 'defaultSort', 'sort', 'type', 'width'];

var DEFAULT_GRID_OPTIONS = {
  columnDefs: [],
  data: [],
  enableColumnResizing: true,
  enableFiltering: true,
  enableSorting: true,
  paginationTemplate: '<span></span>',
  rowHeight: 35,
  useExternalPagination: false,
  useExternalSorting: false
};

var ACTION_COL_DEFAULTS = {
  name: 'actions',
  displayName: '',
  enableFiltering: false,
  enableSorting: false,
  enableHiding: false,
  field: 'widgets',
  width: '*',
  cellTemplate: ACTION_COL_TPL
};

var SearchGridController = exports.SearchGridController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function SearchGridController(uiGridConstants, $scope) {
    _classCallCheck(this, SearchGridController);

    this._uiGridConstants = uiGridConstants;
    this._scope = $scope;
  }

  _createClass(SearchGridController, [{
    key: '$onInit',
    value: function $onInit() {
      var _this = this;

      this.gridOptions = Object.assign({}, DEFAULT_GRID_OPTIONS, this.options || {});
      this.columns = this.columns || [];

      if (this.options && this.options.enablePagination) {
        this._setupPagination();
      }

      this.gridOptions.data = this.data;
      this.gridOptions.columnDefs = this._buildColumnDefs();

      this.gridOptions.onRegisterApi = function (gridApi) {
        _this.gridApi = gridApi;

        if (_this.pagination) {
          gridApi.core.on.sortChanged(_this._scope, _this._onSortingChanged.bind(_this));
          gridApi.pagination.on.paginationChanged(_this._scope, _this._onPaginationChanged.bind(_this));
        }
      };
    }
  }, {
    key: '$onChanges',
    value: function $onChanges(changed) {
      if (!changed.data || !this.gridOptions) return;

      if (changed.data && changed.data.currentValue) {
        if (this.options && this.options.totalItems) {
          this.gridOptions.totalItems = this.options.totalItems;
        }
        if (this.options && this.options.pageNumber && this.pagination.pageNumber !== this.options.pageNumber) {
          this.pagination.pageNumber = this.options.pageNumber;
          this.gridApi.pagination.seek(this.pagination.pageNumber);
        }

        this.gridOptions.columnDefs = [];
        if (changed.data.currentValue.length) {
          this.gridOptions.columnDefs = this._buildColumnDefs();
        }

        this.gridOptions.data = changed.data.currentValue.map(function (item) {
          return Object.keys(item).reduce(function (acc, cur) {
            return Object.assign({}, acc, _defineProperty({}, cur, item[cur]));
          }, {});
        });

        this.gridApi.core.notifyDataChange(this._uiGridConstants.dataChange.COLUMN);
      }
    }
  }, {
    key: '_setupPagination',
    value: function _setupPagination() {
      this.gridOptions.paginationPageSizes = [25, 50, 75];
      this.gridOptions.paginationPageSize = 25;
      this.gridOptions.paginationTemplate = 'common/components/search-grid/search-grid-pagination.tpl.html';
      this.gridOptions.totalItems = this.options.totalItems ? this.options.totalItems : 0;

      if (this.options.useExternalPagination) {
        this.gridOptions.enableFiltering = false;
        this.gridOptions.enableSorting = false;
      }

      this.pagination = {
        pageNumber: this.options.pageNumber || 1,
        pageSize: 25,
        sort: null
      };
    }
  }, {
    key: '_buildColumnDefs',
    value: function _buildColumnDefs() {
      var colDefs = Object.entries(this.columns).map(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            key = _ref2[0],
            value = _ref2[1];

        var col = {
          name: key,
          displayName: value.name
        };

        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = VALID_COLUMN_DEF_PROPS[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var prop = _step.value;

            if (value[prop]) col[prop] = value[prop];
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        return col;
      });

      if (this.actionColumn) {
        this.actions = this.actionColumn.actions.map(function (item) {
          return Object.assign({}, item);
        });
        var actionCol = Object.assign({}, ACTION_COL_DEFAULTS, this.actionColumn.options || {});
        if (this.actionColumn.position && this.actionColumn.position.toLowerCase() === 'end') {
          colDefs.push(actionCol);
        } else {
          colDefs.unshift(actionCol);
        }
      }

      return colDefs;
    }
  }, {
    key: '_onPaginationChanged',
    value: function _onPaginationChanged(pageNumber, pageSize) {
      if (!this.pagination) {
        return;
      }

      this.pagination.pageNumber = pageNumber;
      this.pagination.pageSize = pageSize;

      if (this.options && this.options.resolvePage) {
        this.options.resolvePage(pageNumber, pageSize);
      }
    }
  }, {
    key: '_onSortingChanged',
    value: function _onSortingChanged(grid, sortColumns) {
      if (!this.pagination) {
        return;
      }

      if (sortColumns.length === 0) {
        this.pagination.sort = null;
      } else {
        this.pagination.sort = sortColumns[0].sort.direction;
      }
    }
  }]);

  return SearchGridController;
}();

exports.default = {
  controller: SearchGridController,
  templateUrl: 'common/components/search-grid/search-grid.tpl.html',
  bindings: {
    columns: '<',
    data: '<',
    options: '<',
    actionColumn: '<'
  }
};

},{}],26:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var TabGroupController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function TabGroupController($state) {
    _classCallCheck(this, TabGroupController);

    this.$state = $state;
  }

  _createClass(TabGroupController, [{
    key: '$onInit',
    value: function $onInit() {
      if (this.tabs == null) {
        throw new Error('Tabs must be defined');
      }
      this.selectedTab = this.selectedTabId ? this.selectedTabId : this.tabs[0].id;
    }
  }, {
    key: 'selectTab',
    value: function selectTab(tab) {
      this.selectedTab = tab.id;

      if (typeof this.onTabSelect === 'function') {
        this.onTabSelect({ tab: tab });
      }
    }
  }]);

  return TabGroupController;
}();

exports.default = {
  controller: TabGroupController,
  templateUrl: 'common/components/tab-group/tab-group.tpl.html',
  bindings: {
    onTabSelect: '&tgOnTabSelect',
    tabs: '<tgTabs',
    selectedTabId: '<tgSelectedTabId'
  }
};

},{}],27:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = CompareTo;
function CompareTo() {
  return {
    replace: false,
    restrict: 'A',
    require: '?ngModel',
    scope: {
      compareTo: '=compareTo'
    },
    link: function link($scope, $element, $attrs, ngModel) {
      ngModel.$validators.compareTo = function (modelValue, viewValue) {
        return modelValue === $scope.compareTo;
      };

      $scope.$watch('compareTo', function (newVal) {
        ngModel.$validate();
      });
    }
  };
}

},{}],28:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = FormCheckbox;
function FormCheckbox() {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      disabled: '=feDisabled',
      required: '=feRequired',
      label: '@feLabel',
      model: '=feModel'
    },
    controller: function controller() {},
    controllerAs: '$ctrl',
    bindToController: true,
    template: '\n      <div class="form-checkbox" layout="column" layout-align="center"\n              ng-class="{\'s-checked\': $ctrl.model}">\n          <md-checkbox ng-model="$ctrl.model" ng-disabled="$ctrl.disabled" ng-required="$ctrl.required">\n              <span>{{ $ctrl.label }}</span>\n          </md-checkbox>\n      </div>\n    '
  };
}

},{}],29:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

exports.default = FormCheckboxList;

var _angular = require('angular');

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function FormCheckboxList() {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      columns: '@feColumns',
      formSubmitted: '<feFormSubmitted',
      hideListLabel: '<feHideListLabel',
      hideListToggle: '<feHideListToggle',
      labelKey: '@feLabelKey',
      list: '=feList',
      listTitle: '@feListTitle',
      required: '<feRequired',
      hideToggleAllButton: '<feHideToggleAllButton',
      selectedItems: '=feSelectedItems',
      trackByKey: '@feTrackByKey'
    },
    controller: function controller($element, $scope, $timeout, Utils) {
      'ngInject';

      var _this = this;

      this.trackByKey = this.trackByKey || 'id';
      this.labelKey = this.labelKey || 'name';
      this.columns = this.columns || '3';
      this.list = this.list || [];
      this.selectedItems = this.selectedItems || [];
      this.flex = { 1: 1, 2: 50, 3: 33, 4: 25 }[this.columns];
      this.hideListLabel = !!this.hideListLabel;
      this.hideListToggle = !!this.hideListToggle;
      this.hideToggleAllButton = !!this.hideToggleAllButton;
      this.required = !!this.required;
      this.formSubmitted = !!this.formSubmitted;
      this.listToggled = true;

      var list = void 0,
          listHeight = void 0;

      var getIndex = _typeof(this.list[0]) === 'object' ? function (item, list) {
        var object = Utils.findObjectInArrayByKey(list, item[_this.trackByKey], _this.trackByKey);
        return list.indexOf(object);
      } : function (item, list) {
        return list.indexOf(item);
      };

      var setHasErrors = function setHasErrors() {
        _this.hasErrors = _this.required ? _this.formSubmitted && !_this.selectedItems.length : false;
      };

      var setHeight = function setHeight() {
        $timeout(function () {
          list = (0, _angular.element)($element[0].querySelector('div.form-checkbox-list__items'));
          listHeight = list.prop('clientHeight');
          if (listHeight !== 0) {
            list.css('height', _this.listToggled ? listHeight : 0);
          }
        }, 100);
      };

      setHeight();
      setHasErrors();
      $scope.$watch(function () {
        return _this.list;
      }, function () {
        setHeight();
      });
      $scope.$watch(function () {
        return _this.formSubmitted;
      }, function () {
        setHasErrors();
      });
      $scope.$watch(function () {
        return _this.selectedItems;
      }, function () {
        setHasErrors();
      }, true);

      this.toggleList = function () {
        _this.listToggled = !_this.listToggled;
        if (_this.listToggled) {
          list.css('height', listHeight + 'px');
        } else {
          list.css('height', 0);
        }
      };

      this.exists = function (item) {
        return getIndex(item, _this.selectedItems) > -1;
      };

      this.toggleCheckbox = function (item) {
        var i = getIndex(item, _this.selectedItems);
        if (i > -1) {
          _this.selectedItems.splice(i, 1);
        } else {
          _this.selectedItems.push(item);
        }
      };

      this.toggleAll = function () {
        _this.selectedItems = _this.list.length === _this.selectedItems.length ? [] : [].concat(_toConsumableArray(_this.list));
      };
    },
    controllerAs: '$ctrl',
    bindToController: true,
    templateUrl: 'common/form/directives/formCheckboxList.tpl.html'
  };
};

},{"angular":"angular"}],30:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = FormDate;

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function FormDate() {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      disabled: '=feDisabled',
      classes: '@feClasses',
      errors: '=feErrors',
      isEmbedded: '@feIsEmbedded',
      label: '@feLabel',
      maxDate: '=feMaxDate',
      minDate: '=feMinDate',
      model: '=feModel',
      mode: '@feMode',
      name: '@feName',
      placeholder: '@fePlaceholder',
      required: '=feRequired',
      onChange: '&feOnChange'
    },
    controller: function controller() {
      this.placeholder = this.placeholder || 'Enter date';
      if (this.mode && ~['month', 'day'].indexOf(this.mode.toLowerCase())) {
        this.mode = this.mode.toLowerCase();
      }
      this.dateLocale = {};
      if (this.mode === 'month') {
        this.dateLocale = {
          formatDate: function formatDate(date) {
            return date ? (0, _moment2.default)(date).format('MM-YYYY') : '';
          },
          parseDate: function parseDate(dateString) {
            var m = (0, _moment2.default)(dateString, 'MM-YYYY', true);
            return m.isValid() ? m.toDate() : new Date(NaN);
          }
        };
      }
    },
    controllerAs: '$ctrl',
    bindToController: true,
    template: '\n      <div class="form-date" ng-class="{\'s-is-disabled\': $ctrl.disabled, \'form-date--embedded\': $ctrl.isEmbedded}">\n          <label>{{ $ctrl.label }}</label>\n          <md-datepicker\n                class="{{ $ctrl.classes }}"\n                name="{{ $ctrl.name }}"\n                ng-model="$ctrl.model"\n                ng-change="$ctrl.onChange({date: $ctrl.model})"\n                ng-disabled="!!$ctrl.disabled"\n                ng-required="!!$ctrl.required"\n                md-mode="{{ $ctrl.mode }}"\n                md-min-date="$ctrl.minDate"\n                md-max-date="$ctrl.maxDate"\n                md-placeholder="{{ $ctrl.placeholder }}"\n                md-date-locale="$ctrl.dateLocale"\n          ></md-datepicker>\n          <div class="validation-messages" ng-messages="$ctrl.errors">\n              <div ng-message="valid">This must be a valid date.</div>\n              <div ng-message="required">This is required.</div>\n              <div ng-message="mindate">Date is too early.</div>\n              <div ng-message="maxdate">Date is too late.</div>\n          </div>\n      </div>\n    '
  };
}

},{"moment":"moment"}],31:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = FormInput;
function FormInput() {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      // Attributes
      disabled: '=feDisabled',
      errors: '=feErrors',
      label: '@feLabel',
      model: '=feModel',
      name: '@feName',
      required: '=feRequired',
      type: '@feType',
      // Directives
      noFloat: '=feNoFloat',
      modelOptions: '=feModelOptions',
      // Validators
      max: '@feMax',
      maxLength: '@feMaxLength',
      min: '@feMin',
      minLength: '@feMinLength',
      pattern: '=fePattern',
      // Error messages
      patternError: '@fePatternError',
      // Outputs
      onChange: '&feOnChange'
    },
    controller: function controller() {
      this.type = this.type || 'text';
    },
    controllerAs: '$ctrl',
    bindToController: true,
    link: function link($scope, $el) {
      if ($scope.$ctrl.type.toLowerCase() === 'number') {
        $el.on('mousewheel', function (e) {
          e.target.blur();
        });
      }
    },
    template: function template(element, $attrs) {
      var onChange = '';
      if ($attrs.feOnChange) {
        onChange = 'ng-change="$ctrl.onChange({ $event: { value: $ctrl.model, name: $ctrl.name } })"';
      }

      var modelOptions = '';
      if ($attrs.feModelOptions) {
        modelOptions = 'ng-model-options="' + $attrs.feModelOptions + '"';
      }

      return '<div class="form-input" ng-class="{\'s-is-disabled\': $ctrl.disabled}">\n            <md-input-container md-no-float="$ctrl.noFloat">\n                <input type="{{ $ctrl.type }}" name="{{ $ctrl.name }}"\n                        ng-disabled="$ctrl.disabled"\n                        ng-model="$ctrl.model"\n                        ' + modelOptions + '\n                        ng-pattern="$ctrl.pattern"\n                        ng-required="!!$ctrl.required"\n                        ng-max="{{ $ctrl.max }}"\n                        ng-maxlength="{{ $ctrl.maxLength }}"\n                        ng-min="{{ $ctrl.min }}"\n                        ng-minlength="{{ $ctrl.minLength }}"\n                        ' + onChange + '\n                        placeholder="{{ $ctrl.label }}">\n                <div ng-messages="$ctrl.errors" role="alert">\n                    <div ng-message="required">This is required.</div>\n                    <div ng-message="max">Value can\'t be greater than {{ $ctrl.max }}.</div>\n                    <div ng-message="maxlength">Value can\'t be longer than {{ $ctrl.maxLength }} characters.</div>\n                    <div ng-message="min">Value must be at least {{ $ctrl.min }}.</div>\n                    <div ng-message="minlength">Value must be at least {{ $ctrl.minLength }} characters long.</div>\n                    <div ng-message="email">This must be a valid email.</div>\n                    <div ng-message="time">This must be a valid time.</div>\n                    <div ng-message="pattern">{{ $ctrl.patternError || \'Pattern does not match.\'}}</div>\n                </div>\n            </md-input-container>\n        </div>\n      ';
    }
  };
};

},{}],32:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = FormLabel;
function FormLabel() {
  return {
    replace: true,
    restrict: 'E',
    transclude: true,
    template: '<div class="form-label"><div flex ng-transclude></div></div>'
  };
}

},{}],33:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = FormRadioGroup;
function FormRadioGroup() {
  return {
    replace: true,
    restrict: 'E',
    scope: {
      buttons: '=feButtons',
      disabled: '=feDisabled',
      errors: '=feErrors',
      label: '@feLabel',
      model: '=feModel',
      name: '@feName',
      required: '=feRequired'
    },
    controller: function controller() {},
    controllerAs: '$ctrl',
    bindToController: true,
    templateUrl: 'common/form/directives/formRadioGroup.tpl.html'
  };
}

},{}],34:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = FormSelect;
function FormSelect() {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      classes: '@feClasses',
      disabled: '=feDisabled',
      errors: '=feErrors',
      isEmbedded: '@feIsEmbedded',
      isSearchable: '=feSearchable',
      label: '@feLabel',
      model: '=feModel',
      multiple: '=feMultiple',
      name: '@feName',
      noFloat: '=feNoFloat',
      onChange: '&feOnChange',
      options: '=feOptions',
      optionLabelKey: '@feOptionLabelKey',
      optionValueKey: '@feOptionValueKey',
      optionOrderByKey: '=feOptionOrderByKey',
      required: '=feRequired',
      trackByValue: '=feTrackByValue'
    },
    controller: function controller($scope) {
      'ngInject';

      var _this = this;

      this.optionLabelKey = this.optionLabelKey || 'name';
      this.optionValueKey = this.optionValueKey || null;

      this.clearSearchTerm = function () {
        if (_this.isSearchable) {
          _this.searchTerm = '';
        }
      };

      $scope.$watch(function () {
        return _this.model;
      }, function (newVal, oldVal) {
        if (newVal && newVal['$$hashKey']) {
          delete newVal['$$hashKey'];
        }
      });
    },
    controllerAs: '$ctrl',
    bindToController: true,
    template: function template($element, $attrs) {
      var searchBox = '';
      if ($attrs.feSearchable) {
        searchBox = '<md-select-header class="form-select__search-header" ng-if="$ctrl.isSearchable">\n            <input type="search" class="form-select__search-input md-text" placeholder="Search\u2026"\n                ng-model="$ctrl.searchTerm" ng-keydown="$event.stopPropagation()">\n        </md-select-header>';
      }

      var nameAttr = '';
      if ($attrs.feName) {
        nameAttr = 'name="{{ $ctrl.name }}"';
      }

      var modelOptionsTrackBy = '$value.id';
      var trackByAttr = 'track by option.id';
      var optionLabel = '{{ option[$ctrl.optionLabelKey] }}';
      if ($attrs.feTrackByValue) {
        trackByAttr = 'track by option';
        optionLabel = '{{ option }}';
      }
      if ($attrs.feOptionValueKey) {
        trackByAttr = 'track by option.' + $attrs.feOptionValueKey;
      }
      if ($attrs.feTrackByValue || $attrs.feOptionValueKey) {
        modelOptionsTrackBy = '$value';
      }

      var onChange = '';
      if ($attrs.feOnChange) {
        onChange = 'ng-change="$ctrl.onChange({ $event: { name: \'' + $attrs.feName + '\', value: $ctrl.model } })"';
      }

      var orderByAttr = '$ctrl.optionLabelKey';
      if ($attrs.feOptionOrderByKey) {
        orderByAttr = $attrs.feOptionOrderByKey;
      }

      var multiple = ~['1', 'true'].indexOf($attrs.feMultiple) ? 'true' : 'false';

      return '\n        <div ng-class="{\'s-is-disabled\': $ctrl.disabled, \'form-select--embedded\': $ctrl.isEmbedded}"\n                class="form-select">\n            <md-input-container md-no-float="$ctrl.noFloat">\n                <label ng-if="!$ctrl.noFloat">{{ $ctrl.label }}</label>\n                <md-select ng-model="$ctrl.model"\n                        class="{{ $ctrl.classes }}"\n                        ' + nameAttr + '\n                        ng-disabled="$ctrl.disabled"\n                        ng-required="$ctrl.required"\n                        ' + onChange + '\n                        multiple="' + multiple + '"\n                        md-on-close="$ctrl.clearSearchTerm()"\n                        ng-model-options="{trackBy: \'' + modelOptionsTrackBy + '\'}"\n                        placeholder="{{ $ctrl.noFloat ? $ctrl.label : \'\' }}">\n                    ' + searchBox + '\n                    <md-option ng-if="!$ctrl.required && $ctrl.model" value=""></md-option>\n                    <md-option ng-repeat="option in $ctrl.options\n                                | filter:$ctrl.searchTerm\n                                | orderBy:' + orderByAttr + '\n                                ' + trackByAttr + '"\n                            ng-value="$ctrl.optionValueKey ? option[$ctrl.optionValueKey] : option"\n                            ng-disabled="!!option.disabled">\n                        ' + optionLabel + '\n                    </md-option>\n                </md-select>\n                <div class="errors" ng-messages="$ctrl.errors">\n                    <div ng-message="required">This is required.</div>\n                </div>\n            </md-input-container>\n        </div>\n      ';
    }
  };
};

},{}],35:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = FormTextarea;
function FormTextarea() {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      errors: '=feErrors',
      label: '@feLabel',
      model: '=feModel',
      name: '@feName',
      required: '=feRequired',
      disabled: '=feDisabled',
      rows: '@feRows'
    },
    controller: function controller() {},
    controllerAs: '$ctrl',
    bindToController: true,
    template: '\n      <div class="form-textarea">\n          <md-input-container>\n              <label>{{ $ctrl.label }}</label>\n              <textarea name="{{ $ctrl.name }}" rows="{{ $ctrl.rows || 3 }}"\n                  ng-model="$ctrl.model" ng-required="!!$ctrl.required" ng-disabled="!!$ctrl.disabled">\n              </textarea>\n              <div ng-messages="$ctrl.errors">\n                  <div ng-message="required">This is required.</div>\n              </div>\n          </md-input-container>\n      </div>\n    '
  };
}

},{}],36:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = PasswordValidator;
function PasswordValidator() {
  return {
    replace: false,
    restrict: 'A',
    require: '?ngModel',
    link: function link($scope, $element, $attrs, ngModel) {
      if (!$attrs.passwordValidator) {
        return;
      }

      var rules = [/[A-Z]+/, // uppercase
      /[a-z]+/, // lowercase
      /[0-9]+/, // numeric
      /[^a-zA-Z\d\s]+/];

      ngModel.$validators.password = function (modelValue, viewValue) {
        var validRules = 0;
        rules.forEach(function (rule) {
          if (rule.test(viewValue)) {
            validRules++;
          }
        });
        return validRules > 2;
      };
    }
  };
}

},{}],37:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _compareTo = require('./directives/compareTo');

var _compareTo2 = _interopRequireDefault(_compareTo);

var _passwordValidator = require('./directives/passwordValidator');

var _passwordValidator2 = _interopRequireDefault(_passwordValidator);

var _formCheckbox = require('./directives/formCheckbox');

var _formCheckbox2 = _interopRequireDefault(_formCheckbox);

var _formCheckboxList = require('./directives/formCheckboxList');

var _formCheckboxList2 = _interopRequireDefault(_formCheckboxList);

var _formDate = require('./directives/formDate');

var _formDate2 = _interopRequireDefault(_formDate);

var _formInput = require('./directives/formInput');

var _formInput2 = _interopRequireDefault(_formInput);

var _formLabel = require('./directives/formLabel');

var _formLabel2 = _interopRequireDefault(_formLabel);

var _formRadioGroup = require('./directives/formRadioGroup');

var _formRadioGroup2 = _interopRequireDefault(_formRadioGroup);

var _formSelect = require('./directives/formSelect');

var _formSelect2 = _interopRequireDefault(_formSelect);

var _formTextarea = require('./directives/formTextarea');

var _formTextarea2 = _interopRequireDefault(_formTextarea);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _module = _angular2.default.module('gnaas.common.form', []).directive('compareTo', _compareTo2.default).directive('passwordValidator', _passwordValidator2.default).directive('formCheckbox', _formCheckbox2.default).directive('formCheckboxList', _formCheckboxList2.default).directive('formDate', _formDate2.default).directive('formInput', _formInput2.default).directive('formLabel', _formLabel2.default).directive('formRadioGroup', _formRadioGroup2.default).directive('formSelect', _formSelect2.default).directive('formTextarea', _formTextarea2.default);

exports.default = _module.name;

},{"./directives/compareTo":27,"./directives/formCheckbox":28,"./directives/formCheckboxList":29,"./directives/formDate":30,"./directives/formInput":31,"./directives/formLabel":32,"./directives/formRadioGroup":33,"./directives/formSelect":34,"./directives/formTextarea":35,"./directives/passwordValidator":36,"angular":"angular"}],38:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function getVisiblePages(totalPageCount, currentPageNumber, pageButtonCount) {
  var pages = [].concat(_toConsumableArray(Array(totalPageCount + 1).keys()));
  pages.shift();

  if (totalPageCount <= pageButtonCount) {
    return [].concat(_toConsumableArray(pages));
  }

  // If 1 or 2 - return first 5
  if (currentPageNumber === 1 || currentPageNumber === 2) {
    return [].concat(_toConsumableArray(pages)).slice(0, pageButtonCount);
  }

  // If last or one before last - return last 5
  if (currentPageNumber === totalPageCount || currentPageNumber === totalPageCount - 1) {
    return [].concat(_toConsumableArray(pages)).slice(-5);
  }

  var i = pages.indexOf(currentPageNumber);
  return [].concat(_toConsumableArray(pages)).slice(i - 2, i + 3);
}

var PaginationController = function () {
  function PaginationController() {
    _classCallCheck(this, PaginationController);
  }

  _createClass(PaginationController, [{
    key: '$onInit',
    value: function $onInit() {
      if (!this.pages) this.pages = [];
    }
  }, {
    key: 'isActivePage',
    value: function isActivePage(page) {
      return page === this.currentPage;
    }
  }, {
    key: '$onChanges',
    value: function $onChanges(changes) {
      if (changes.currentPage && changes.currentPage.currentValue != null) {
        this.currentPage = parseInt(changes.currentPage.currentValue, 10);
      }

      if (changes.totalPages && changes.totalPages.currentValue != null) {
        this.totalPages = parseInt(changes.totalPages.currentValue, 10);
      }

      if (changes.totalPages || changes.currentPage) {
        this.pages = getVisiblePages(this.totalPages, this.currentPage, 5);
      }
    }
  }]);

  return PaginationController;
}();

var PaginationComponent = {
  bindings: {
    onNextClick: '&bOnNextClick',
    onPreviousClick: '&bOnPreviousClick',
    onPageClick: '&bOnPageClick',
    currentPage: '@bCurrentPage',
    totalPages: '@bTotalPages',
    pageButtonCount: '@bPageButtonCount'
  },
  controller: PaginationController,
  templateUrl: 'common/lists/components/pagination/pagination.tpl.html'
};

exports.default = PaginationComponent;

},{}],39:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var TableListController = exports.TableListController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function TableListController($attrs) {
    _classCallCheck(this, TableListController);

    this._attrs = $attrs;
  }

  _createClass(TableListController, [{
    key: '$onInit',
    value: function $onInit() {
      this.fields = this.fields || {};
      this.items = this.items || [];
      this.orderBy = this.orderBy || Object.keys(this.fields)[0] || 'name';
      this.orderByReverse = this.orderByReverse || false;
      this.noResultsMessage = this.noResultsMessage || 'No records found.';
      if (this.showButton === undefined) this.showButton = true;
      if (this.search === undefined) this.search = true;
    }
  }, {
    key: '$onChanges',
    value: function $onChanges(changes) {
      var _this = this;

      if (!changes.items || !changes.items.currentValue) return;

      if (this._attrs.rowBackgroundWarn) {
        this.items = changes.items.currentValue.map(function (item) {
          return Object.assign({}, item, { __bgWarn: _this.rowBackgroundWarn({ item: item }) });
        });
      }
    }
  }, {
    key: 'listItemClickAction',
    value: function listItemClickAction(evt, item) {
      if (this._attrs.onItemClick) {
        this.onItemClick({ event: evt, item: item });
      }
    }
  }, {
    key: 'transformValue',
    value: function transformValue(value) {
      if (typeof value === 'boolean') {
        return value ? 'Yes' : 'No';
      }

      return value;
    }
  }, {
    key: 'getFieldValue',
    value: function getFieldValue(item, key) {
      var _this2 = this;

      if (!item) return;
      if (key.indexOf('.') === -1) return this.transformValue(item[key]);
      try {
        return key.split('.').reduce(function (acc, part) {
          if (!acc.hasOwnProperty(part)) {
            throw new Error();
          }
          return _this2.transformValue(acc[part]);
        }, item);
      } catch (e) {
        return null;
      }
    }
  }]);

  return TableListController;
}();

exports.default = {
  bindings: {
    fields: '<',
    items: '<',
    onItemClick: '&',
    listToSidenav: '<',
    search: '<',
    showButton: '<',
    rowBackgroundWarn: '&',
    noResultsMessage: '@',
    orderBy: '@',
    orderByReverse: '<'
  },
  controller: TableListController,
  templateUrl: 'common/lists/components/table-list/table-list.tpl.html'
};

},{}],40:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _pagination = require('./components/pagination/pagination.component');

var _pagination2 = _interopRequireDefault(_pagination);

var _tableList = require('./components/table-list/table-list.component');

var _tableList2 = _interopRequireDefault(_tableList);

var _checkboxListDialog = require('./services/checkbox-list-dialog/checkbox-list-dialog.service');

var _checkboxListDialog2 = _interopRequireDefault(_checkboxListDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _module = _angular2.default.module('gnaas.common.lists', []).component('pagination', _pagination2.default).component('tableList', _tableList2.default).service('CheckboxListDialog', _checkboxListDialog2.default);

exports.default = _module.name;

},{"./components/pagination/pagination.component":38,"./components/table-list/table-list.component":39,"./services/checkbox-list-dialog/checkbox-list-dialog.service":42,"angular":"angular"}],41:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _angular = require('angular');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var CheckboxListDialogController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function CheckboxListDialogController(Dialog) {
    _classCallCheck(this, CheckboxListDialogController);

    this.Dialog = Dialog;

    if (!this.locals.resource) throw new Error('Resource not provided');
    this.Resource = this.locals.resource;

    this.title = this.locals.title || 'Checkbox List';
    this.trackByKey = this.locals.trackByKey || 'id';
    this.options = this.locals.options ? (0, _angular.copy)(this.locals.options) : [];
    this.items = this.locals.items ? (0, _angular.copy)(this.locals.items) : [];
    this.parse = this.locals.parse && typeof this.locals.parse === 'function' ? this.locals.parse : function (items) {
      return items;
    };
  }

  _createClass(CheckboxListDialogController, [{
    key: 'submit',
    value: function submit(items) {
      var _this = this;

      var data = this.parse(items);
      this.Resource.create(data).then(function () {
        _this.Dialog.hide(data);
      });
    }
  }, {
    key: 'cancel',
    value: function cancel() {
      this.Dialog.cancel();
    }
  }]);

  return CheckboxListDialogController;
}();

exports.default = CheckboxListDialogController;

},{"angular":"angular"}],42:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _checkboxListDialog = require('./checkbox-list-dialog.controller');

var _checkboxListDialog2 = _interopRequireDefault(_checkboxListDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var CheckboxListDialogService = function () {
  /**
   * @constructor
   * @ngInject
   */
  function CheckboxListDialogService(Dialog) {
    _classCallCheck(this, CheckboxListDialogService);

    this.Dialog = Dialog;

    this.dialogParams = {
      controller: _checkboxListDialog2.default,
      templateUrl: 'common/lists/services/checkbox-list-dialog/checkbox-list-dialog.tpl.html'
    };
  }

  _createClass(CheckboxListDialogService, [{
    key: 'show',
    value: function show(_ref) {
      var $event = _ref.$event,
          items = _ref.items,
          options = _ref.options,
          resource = _ref.resource,
          title = _ref.title,
          parse = _ref.parse,
          trackByKey = _ref.trackByKey;

      return this.Dialog.show(Object.assign({}, this.dialogParams, {
        targetEvent: $event || null,
        locals: { items: items, options: options, resource: resource, title: title, parse: parse, trackByKey: trackByKey }
      }));
    }
  }]);

  return CheckboxListDialogService;
}();

exports.default = CheckboxListDialogService;

},{"./checkbox-list-dialog.controller":41}],43:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MiniMapController = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var _map = require('common/map/services/map');

var _map2 = _interopRequireDefault(_map);

var _leaflet = require('leaflet');

var _leaflet2 = _interopRequireDefault(_leaflet);

var _mapMarkerIcon = require('common/map/services/mapMarkerIcon');

var _mapMarkerIcon2 = _interopRequireDefault(_mapMarkerIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function isCorrectLength(location) {
  return location ? location.length > 3 : false;
}

function isValidObject(location) {
  return (typeof location === 'undefined' ? 'undefined' : _typeof(location)) === 'object' && location.lat && location.lng;
}

var MiniMapController = exports.MiniMapController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function MiniMapController($timeout, $scope, Location, BING_MAPS_KEY) {
    _classCallCheck(this, MiniMapController);

    this._timeout = $timeout;
    this._scope = $scope;
    this._Location = Location;
    this._BING_MAPS_KEY = BING_MAPS_KEY;
  }

  _createClass(MiniMapController, [{
    key: '$onInit',
    value: function $onInit() {
      var _this = this;

      this._scope.$watch(function () {
        return _this.location;
      }, function (newVal, oldVal) {
        if (!_this.map) return;

        if (isValidObject(newVal)) {
          _this._addMapMarker(newVal);
        } else if (typeof newVal === 'string') {
          if (isCorrectLength(newVal)) {
            _this._getCoordinatesFromLocation(newVal).then(_this._addMapMarker.bind(_this));
          } else {
            _this.map.removeMarker(_this.currentMapMarker);
          }
        }
      }, true);
    }
  }, {
    key: '$postLink',
    value: function $postLink() {
      var _this2 = this;

      this._timeout(function () {
        _this2.map = new _map2.default(_this2.mapId, { bingMapsKey: _this2._BING_MAPS_KEY });
        if (isValidObject(_this2.location)) {
          _this2._addMapMarker(_this2.location);
        }
      }, 250);
    }
  }, {
    key: '$onDestroy',
    value: function $onDestroy() {
      if (this.map) {
        this.map.destroy();
        this.map = null;
      }
    }
  }, {
    key: '_addMapMarker',
    value: function _addMapMarker(coords) {
      this.map.removeMarker(this.currentMapMarker);
      this.currentMapMarker = this.map.addMarker([coords.lat, coords.lng], { icon: _mapMarkerIcon2.default.predefined('incident') });
      this.map.panTo(new _leaflet2.default.LatLng(coords.lat, coords.lng));
    }
  }, {
    key: '_getCoordinatesFromLocation',
    value: function _getCoordinatesFromLocation(location) {
      var _this3 = this;

      return this._Location.resolveCoordinates(location).catch(function () {
        _this3.map.removeMarker(_this3.currentMapMarker);
        _this3.currentMapMarker = null;
      });
    }
  }]);

  return MiniMapController;
}();

exports.default = {
  bindings: {
    location: '<',
    mapId: '@'
  },
  controller: MiniMapController,
  template: '\n    <div ng-attr-id="{{ $ctrl.mapId }}" class="c-mini-map"></div>\n  '
};

},{"common/map/services/map":46,"common/map/services/mapMarkerIcon":47,"leaflet":"leaflet"}],44:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _miniMap = require('./components/miniMap');

var _miniMap2 = _interopRequireDefault(_miniMap);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _module = _angular2.default.module('gnaas.common.map', []).component('miniMap', _miniMap2.default);

exports.default = _module.name;

},{"./components/miniMap":43,"angular":"angular"}],45:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (options) {
  return new BingLayer(options);
};

var _leaflet = require('leaflet');

var _leaflet2 = _interopRequireDefault(_leaflet);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function toQuadKey(x, y, z) {
  var index = '';
  for (var i = z; i > 0; i--) {
    var b = 0;
    var mask = 1 << i - 1;
    if ((x & mask) !== 0) b++;
    if ((y & mask) !== 0) b += 2;
    index += b.toString();
  }
  return index;
} /* global fetch, Promise */


var VALID_IMAGERY_SETS = ['Road', 'OrdnanceSurvey'];

var BingLayer = _leaflet2.default.TileLayer.extend({
  options: {
    bingMapsKey: null,
    imagerySet: 'Road'
  },

  statics: {
    METADATA_URL: 'https://dev.virtualearth.net/REST/v1/Imagery/Metadata/{imagerySet}' + '?key={bingMapsKey}&include=ImageryProviders&uriScheme=https&output=json'
  },

  getAttribution: function getAttribution() {
    return '&copy; <a href="http://bing.com/maps">Bing Maps</a>';
  },

  initialize: function initialize(options) {
    if (!options || !options.bingMapsKey) {
      throw new Error('bingMapsKey must be provided');
    }
    options = _leaflet2.default.setOptions(this, options);

    if (VALID_IMAGERY_SETS.indexOf(options.imagerySet) < 0) {
      throw new Error('Invalid imagerySet');
    }

    var metaDataUrl = _leaflet2.default.Util.template(BingLayer.METADATA_URL, {
      bingMapsKey: this.options.bingMapsKey,
      imagerySet: this.options.imagerySet
    });

    fetch(metaDataUrl).then(function (response) {
      return response.json();
    }).then(this._metaDataOnLoad.bind(this)).catch(console.error.bind(console));

    // https://github.com/Leaflet/Leaflet/issues/137
    if (!_leaflet2.default.Browser.android) {
      this.on('tileunload', this._onTileRemove);
    }
  },

  createTile: function createTile(coords, done) {
    var tile = document.createElement('img');
    _leaflet2.default.DomEvent.on(tile, 'load', _leaflet2.default.bind(this._tileOnLoad, this, done, tile));
    _leaflet2.default.DomEvent.on(tile, 'error', _leaflet2.default.bind(this._tileOnError, this, done, tile));
    if (this.options.crossOrigin) {
      tile.crossOrigin = '';
    }
    tile.alt = '';
    if (this._url) {
      tile.src = this.getTileUrl(coords);
    }
    return tile;
  },

  getTileUrl: function getTileUrl(coords) {
    return _leaflet2.default.Util.template(this._url, {
      quadkey: toQuadKey(coords.x, coords.y, coords.z),
      subdomain: this._getSubdomain(coords),
      culture: 'en_GB'
    });
  },

  _metaDataOnLoad: function _metaDataOnLoad(metaData) {
    if (metaData.statusCode !== 200) {
      throw new Error('Bing Imagery Metadata error: \n' + JSON.stringify(metaData, null, '  '));
    }
    var resource = metaData.resourceSets[0].resources[0];
    this.options.subdomains = resource.imageUrlSubdomains;
    this.setUrl(resource.imageUrl);
    return Promise.resolve();
  }
});

;

},{"leaflet":"leaflet"}],46:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }(); /* global setTimeout */


var _leaflet = require('leaflet');

var _leaflet2 = _interopRequireDefault(_leaflet);

var _bingMapsLayer = require('./bing-maps-layer');

var _bingMapsLayer2 = _interopRequireDefault(_bingMapsLayer);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var getDefaultOpts = function getDefaultOpts() {
  return {
    map: {
      center: [51.380037, -2.355878],
      zoom: 9,
      minZoom: 9,
      scrollWheelZoom: true
    },
    tileLayers: {
      '500k': {
        minZoom: 9,
        maxZoom: 10,
        opacity: 1.0,
        tms: false,
        bounds: _leaflet2.default.latLngBounds(_leaflet2.default.latLng(52.911301, -9.247032), _leaflet2.default.latLng(56.461343, 1.277031))
      },
      '250k': {
        minZoom: 11,
        maxZoom: 12,
        opacity: 1.0,
        tms: false
      }
    }
  };
};

var Map = function () {
  function Map(elementId, credentials, opts) {
    _classCallCheck(this, Map);

    if (!credentials || !credentials.bingMapsKey) {
      throw new Error('Bing maps key required');
    }
    this._credentials = credentials;

    this._map = this._initMap(elementId, opts);
    this._markers = _leaflet2.default.featureGroup([]).addTo(this._map);
    this._routePlanning = false;
    this._routePoints = [];
    this._routePlotLine = null;
    this._routeStartMarker = null;
  }

  _createClass(Map, [{
    key: 'destroy',
    value: function destroy() {
      this._map.remove();
    }

    /**
     * Getters
     */

  }, {
    key: 'getMap',
    value: function getMap() {
      return this._map;
    }

    /**
     * Events
     */

  }, {
    key: 'addEventListener',
    value: function addEventListener(event, handler) {
      this._map.on(event, handler);
    }
  }, {
    key: 'removeEventListener',
    value: function removeEventListener(event, handler) {
      this._map.off(event, handler);
    }
  }, {
    key: 'addOneTimeEventListener',
    value: function addOneTimeEventListener(event, handler) {
      this._map.once(event, handler);
    }

    /**
     * Markers
     */

  }, {
    key: 'addMarker',
    value: function addMarker(latLng, opts, popup) {
      var marker = _leaflet2.default.marker(latLng, opts);
      if (popup) {
        marker.bindPopup(popup);
      }
      this._markers.addLayer(marker);
      return marker;
    }
  }, {
    key: 'removeMarker',
    value: function removeMarker(marker) {
      this._markers.removeLayer(marker);
    }
  }, {
    key: 'panTo',
    value: function panTo(latLng) {
      this._map.panTo(latLng);
    }
  }, {
    key: 'openPopup',
    value: function openPopup(popup) {
      this._map.openPopup(popup);
    }
  }, {
    key: 'closePopup',
    value: function closePopup(popup) {
      this._map.closePopup(popup);
    }

    /**
     * Route planning
     */

  }, {
    key: 'enableRoutePlanning',
    value: function enableRoutePlanning(cb) {
      this._routePlanning = true;
      // Store a reference to route planning map and map marker
      // handlers bound with a callback function
      this._boundRoutePlanningMapHandler = this._routePlanningMapHandler.bind(this, cb);
      this._boundRoutePlanningMapMarkerHandler = this._routePlanningMapMarkerHandler.bind(this, cb);
      this._map.on('click', this._boundRoutePlanningMapHandler);
      this._markers.on('click', this._boundRoutePlanningMapMarkerHandler);
    }
  }, {
    key: 'disableRoutePlanning',
    value: function disableRoutePlanning() {
      this._routePlanning = false;
      this._map.off('click', this._boundRoutePlanningMapHandler);
      this._markers.off('click', this._boundRoutePlanningMapMarkerHandler);
    }
  }, {
    key: 'addRoutePoint',
    value: function addRoutePoint(point) {
      this._routePoints.push(point);

      if (this._routePoints.length === 1) {
        this._routePlotLine = _leaflet2.default.polyline(this._routePoints, { color: 'red' }).addTo(this._map);
        this._routeStartMarker = _leaflet2.default.marker(this._routePoints[0]);
        this._map.addLayer(this._routeStartMarker);
      } else if (this._routePoints.length > 1) {
        this._routePlotLine.addLatLng(this._routePoints[this._routePoints.length - 1]);
      }
    }
  }, {
    key: 'getRouteDistance',
    value: function getRouteDistance() {
      var _this = this;

      if (this._routePoints.length < 2) {
        return 0;
      }

      return this._routePoints.reduce(function (previous, current, currentIndex) {
        if ((typeof previous === 'undefined' ? 'undefined' : _typeof(previous)) === 'object') {
          return previous.distanceTo(current);
        }
        return previous + _this._routePoints[currentIndex - 1].distanceTo(current);
      });
    }
  }, {
    key: 'clearRoute',
    value: function clearRoute() {
      if (!this._routePlotLine) {
        return;
      }
      this._routePoints.length = 0;
      this._map.removeLayer(this._routePlotLine);
      this._map.removeLayer(this._routeStartMarker);
      this._routePlotLine = null;
      this._routeStartMarker = null;
    }

    /**
     * Private methods
     */

  }, {
    key: '_routePlanningMapHandler',
    value: function _routePlanningMapHandler(cb, e) {
      if (this._routePlanning) {
        this.addRoutePoint(e.latlng);
        if (typeof cb === 'function') {
          cb();
        }
      }
    }
  }, {
    key: '_routePlanningMapMarkerHandler',
    value: function _routePlanningMapMarkerHandler(cb, e) {
      if (this._routePlanning) {
        e.layer.closePopup();
        this.addRoutePoint(e.latlng);
        if (typeof cb === 'function') {
          cb();
        }
      }
    }
  }, {
    key: '_initMap',
    value: function _initMap(elementId) {
      var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      var defaultOpts = getDefaultOpts();
      var bingMapsKey = this._credentials.bingMapsKey;


      var map = _leaflet2.default.map(elementId, Object.assign({}, defaultOpts.map, opts.map));
      map.attributionControl.setPrefix('');

      var layers = [(0, _bingMapsLayer2.default)({ bingMapsKey: bingMapsKey, minZoom: defaultOpts.map.minZoom, maxZoom: 11 }), (0, _bingMapsLayer2.default)({ bingMapsKey: bingMapsKey, imagerySet: 'OrdnanceSurvey', minZoom: 12, maxZoom: 17 }), (0, _bingMapsLayer2.default)({ bingMapsKey: bingMapsKey, minZoom: 18, maxZoom: 19 })];
      layers.forEach(function (layer) {
        return map.addLayer(layer);
      });

      return map;
    }
  }]);

  return Map;
}();

exports.default = Map;

},{"./bing-maps-layer":45,"leaflet":"leaflet"}],47:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _leaflet = require('leaflet');

var _leaflet2 = _interopRequireDefault(_leaflet);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var predefinedIcons = {
  'avoid': { iconUrl: 'assets/images/markers/avoid.svg' },
  'base': { iconUrl: 'assets/images/markers/base.svg' },
  'generic': { iconUrl: 'assets/images/markers/generic.svg' },
  'hazard': { iconUrl: 'assets/images/markers/hazard.svg' },
  'hospital': { iconUrl: 'assets/images/markers/hospital.svg' },
  'incident': { iconUrl: 'assets/images/markers/incident.svg' },
  'landing-site': { iconUrl: 'assets/images/markers/landing-site.svg' },
  'refuel-station': { iconUrl: 'assets/images/markers/refuel-station.svg' },
  'target': { iconUrl: 'assets/images/markers/target.svg' },
  'helicopter': { iconUrl: 'assets/images/icons/helicopter-icon-48px.svg' },
  'car': { iconUrl: 'assets/images/icons/car-icon-48px.svg' }
};

var defaultOpts = {
  iconSize: [36, 36]
};

var MapMarkerIcon = function () {
  function MapMarkerIcon(opts) {
    _classCallCheck(this, MapMarkerIcon);

    return _leaflet2.default.icon(Object.assign({}, defaultOpts, opts));
  }

  _createClass(MapMarkerIcon, null, [{
    key: 'getAvailableIcons',
    value: function getAvailableIcons() {
      return Object.keys(predefinedIcons);
    }
  }, {
    key: 'predefinedUrl',
    value: function predefinedUrl(icon) {
      if (!predefinedIcons[icon]) {
        throw new Error('Icon \'' + icon + '\' does not exist.');
      }
      return predefinedIcons[icon].iconUrl;
    }
  }, {
    key: 'predefined',
    value: function predefined(icon) {
      if (!predefinedIcons[icon]) {
        throw new Error('Icon \'' + icon + '\' does not exist.');
      }
      return new MapMarkerIcon(Object.assign({}, defaultOpts, predefinedIcons[icon]));
    }
  }]);

  return MapMarkerIcon;
}();

exports.default = MapMarkerIcon;

},{"leaflet":"leaflet"}],48:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _module2 = require('./auth/module');

var _module3 = _interopRequireDefault(_module2);

var _module4 = require('./body-part-selector/module');

var _module5 = _interopRequireDefault(_module4);

var _module6 = require('./components/module');

var _module7 = _interopRequireDefault(_module6);

var _module8 = require('./form/module');

var _module9 = _interopRequireDefault(_module8);

var _module10 = require('./lists/module');

var _module11 = _interopRequireDefault(_module10);

var _module12 = require('./map/module');

var _module13 = _interopRequireDefault(_module12);

var _module14 = require('./navigation/module');

var _module15 = _interopRequireDefault(_module14);

var _module16 = require('./resource/module');

var _module17 = _interopRequireDefault(_module16);

var _module18 = require('./services/module');

var _module19 = _interopRequireDefault(_module18);

var _module20 = require('./utils/module');

var _module21 = _interopRequireDefault(_module20);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _module = _angular2.default.module('gnaas.common', [_module3.default, _module5.default, _module7.default, _module9.default, _module11.default, _module13.default, _module15.default, _module17.default, _module19.default, _module21.default]);

exports.default = _module.name;

},{"./auth/module":7,"./body-part-selector/module":17,"./components/module":24,"./form/module":37,"./lists/module":40,"./map/module":44,"./navigation/module":53,"./resource/module":58,"./services/module":62,"./utils/module":76,"angular":"angular"}],49:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = {
    bindings: {
        routes: '<'
    },
    template: '\n    <div class="c-breadcrumbs">\n        <span ng-repeat="route in $ctrl.routes">\n            <span ui-sref-active="active">\n                <a ng-if="route.state" ui-sref="{{ route.state }}">{{ route.title }}</a>\n                <span ng-if="!route.state">{{ route.title }}</span>\n            </span>\n            <span ng-if="!$last" class="c-breadcrumbs__chevron">\n                &gt;\n            </span>\n        </span>\n    </div>\n  '
};

},{}],50:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

var SIDENAV = 'sidenav-main';

var CONSUMABLE_SUBMENU = [{ name: 'Consumable List', sref: 'admin.consumables.list' }, { name: 'Consumable Stock', sref: 'admin.consumables.stock' }];

var DRUG_SUBMENU = [{ name: 'Batch Management', sref: 'admin.drugs.batchManagement.allDrugs' }, { name: 'Transaction History', sref: 'admin.drugs.transactionHistory' }, { name: 'Stock Checks', sref: 'admin.drugs.stockChecks.list' }];

function generateMainMenu() {
  return [{ name: 'Dashboard', sref: 'dashboard' }, { name: 'Map', sref: 'map' }, { name: 'Request Log', sref: 'requestLog' }, { name: 'Calendar', sref: 'calendar' }, { name: 'Crew Members', sref: 'crewMembers' }];
}

function generateMainMenuWithReports() {
  return [].concat(_toConsumableArray(generateMainMenu()), [{ name: 'Audit Tool', sref: 'auditTool' }, { name: 'Reports', sref: 'reports' }]);
}

function generateMainMenuWithReportsAndKPIs() {
  return [].concat(_toConsumableArray(generateMainMenuWithReports()), [{ name: 'KPI', sref: 'kpi' }]);
}

function generateMainMenuForAdmins() {
  return [].concat(_toConsumableArray(generateMainMenuWithReportsAndKPIs()), [{ name: 'Shifts', sref: 'shifts' }]);
}

function generateAdminMenu(categoryMap) {
  var categories = Object.keys(categoryMap).sort().map(function (category) {
    return {
      name: categoryMap[category].labels.multiple,
      sref: 'admin.category',
      srefParams: { categoryName: category }
    };
  });

  var drugSubmenu = [{ name: 'Drug List', sref: 'admin.drugs.drugList' }, { name: 'Non-formulary Drug List', sref: 'admin.drugs.nonFormularyDrugList' }, { name: 'Infusion List', sref: 'admin.drugs.infusionList' }].concat(DRUG_SUBMENU);

  var reportablesSubmenu = [{ name: 'Drugs', sref: 'admin.reports.reportables', srefParams: { type: 'drug' } }, { name: 'Interventions', sref: 'admin.reports.reportables', srefParams: { type: 'intervention' } }];

  return [{ name: 'Bases', sref: 'admin.bases' }, { name: 'Categories', children: categories }, { name: 'Consumables', parentSref: 'admin.consumables', children: CONSUMABLE_SUBMENU }, { name: 'ARC Devices', sref: 'admin.devices' }, { name: 'Drugs', sref: 'admin.drugs', children: drugSubmenu }, { name: 'Equipment Locations', sref: 'admin.equipmentLocations.list' }, { name: 'Flagged Referrals', sref: 'admin.flaggedIncidents' }, { name: 'Hospitals', sref: 'admin.hospitals' }, { name: 'Reportable Items', sref: 'admin.reports', children: reportablesSubmenu }, { name: 'Vehicles', sref: 'admin.vehicles' }, { name: 'Vehicle Callsigns', sref: 'admin.vehicleCallsigns' }];
}

function generateMedicAdminMenu() {
  return [{ name: 'Consumable Stock', sref: 'admin.consumables.stock' }, { name: 'Drugs', parentSref: 'admin.drugs', children: DRUG_SUBMENU }, { name: 'Equipment Locations', sref: 'admin.equipmentLocations.list' }];
}

function generateOpsAdminsAdminMenu() {
  return [{ name: 'Consumable Stock', sref: 'admin.consumables.stock' }];
}

function generateCharityMainMenu() {
  return [{ name: 'Calendar', sref: 'calendar' }];
}

var SidenavMenuController = exports.SidenavMenuController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function SidenavMenuController($state, $stateParams, $mdSidenav, Session, CATEGORY_MAP, APP_ORG_SHORT) {
    _classCallCheck(this, SidenavMenuController);

    this._state = $state;
    this._stateParams = $stateParams;
    this._mdSidenav = $mdSidenav;
    this._Session = Session;
    this._CATEGORY_MAP = CATEGORY_MAP;
    this.APP_ORG_SHORT = APP_ORG_SHORT;
  }

  _createClass(SidenavMenuController, [{
    key: '$onInit',
    value: function $onInit() {
      this._menu = [];
      this._adminMenu = [];

      this.user = this._Session.user();

      switch (this.user.group.slug) {
        case 'system-administrators':
          this._menu = generateMainMenuForAdmins();
          this._adminMenu = generateAdminMenu(this._CATEGORY_MAP);
          break;
        case 'medical-staff':
        case 'medical-staff-prescribers':
          this._menu = generateMainMenuWithReportsAndKPIs();
          this._adminMenu = generateMedicAdminMenu();
          break;
        case 'ops-admins':
          this._menu = generateMainMenuWithReports();
          this._adminMenu = generateOpsAdminsAdminMenu();
          break;
        case 'charity-staff':
          this._menu = generateCharityMainMenu();
          break;
        default:
          this._menu = generateMainMenu();
          break;
      }
    }
  }, {
    key: 'isActive',
    value: function isActive(sref, srefParams) {
      if (srefParams && srefParams.categoryName) {
        return this._state.current.name === sref && this._stateParams.categoryName === srefParams.categoryName;
      } else if (srefParams && srefParams.type) {
        return this._state.current.name === sref && this._stateParams.type === srefParams.type;
      } else {
        return this._state.current.name === sref;
      }
    }
  }, {
    key: 'sidenavIsLockedOpen',
    value: function sidenavIsLockedOpen() {
      return this._mdSidenav(SIDENAV).isLockedOpen();
    }
  }, {
    key: 'closeSidenav',
    value: function closeSidenav() {
      this._mdSidenav(SIDENAV).close();
    }
  }, {
    key: 'getMenu',
    value: function getMenu() {
      return this._menu;
    }
  }, {
    key: 'getAdminMenu',
    value: function getAdminMenu() {
      return this._adminMenu;
    }
  }, {
    key: 'goto',
    value: function goto(state, params) {
      var _this = this;

      this._state.go(state, params).then(function () {
        if (!_this.sidenavIsLockedOpen()) {
          _this.closeSidenav();
        }
      });
    }
  }]);

  return SidenavMenuController;
}();

exports.default = {
  templateUrl: 'common/navigation/templates/sidenavMenu.tpl.html',
  controller: SidenavMenuController
};

},{}],51:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var SidenavSubmenuController = exports.SidenavSubmenuController = function () {
  /* @ngInject */
  function SidenavSubmenuController($element, $state, $stateParams, $timeout) {
    _classCallCheck(this, SidenavSubmenuController);

    this._element = $element;
    this._state = $state;
    this._stateParams = $stateParams;
    this._timeout = $timeout;
  }

  _createClass(SidenavSubmenuController, [{
    key: '$postLink',
    value: function $postLink() {
      var _this = this;

      this.toggled = false;
      this._list = this._element.find('ul');
      this._timeout(function () {
        _this._list.css('height', 0);
      });
    }
  }, {
    key: 'toggle',
    value: function toggle() {
      this.toggled = !this.toggled;
      if (this.toggled) {
        this._list.css('height', 'auto');
      } else {
        this._list.css('height', 0);
      }
    }
  }, {
    key: 'childIsActive',
    value: function childIsActive() {
      var _this2 = this;

      if (this.item.parentSref) {
        return ~this._state.current.name.indexOf(this.item.parentSref);
      }

      if (this.item.children) {
        return this.item.children.some(function (element) {
          return ~element.sref.indexOf(_this2._state.current.name);
        });
      }

      return false;
    }
  }]);

  return SidenavSubmenuController;
}();

exports.default = {
  bindings: {
    item: '<',
    closeSidenav: '&'
  },
  require: {
    sidenavMenu: '^sidenavMenu'
  },
  controller: SidenavSubmenuController,
  templateUrl: 'common/navigation/templates/sidenavSubmenu.tpl.html'
};

},{}],52:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TopbarController = TopbarController;

var _module = require('common/auth/module.constants');

var SIDENAV = 'sidenav-main';

function TopbarController($rootScope, $state, $mdSidenav, JWT, Session, AuthResource, IdleCheck) {
  'ngInject';

  var _this = this;

  var updateUser = function updateUser() {
    var user = Session.user();
    _this.username = 'User';
    if (user) {
      _this.username = user.first_name && user.last_name ? user.first_name + ' ' + user.last_name : user.email;
      _this.profile_image = user.profile_image;
    }
  };

  this.$onInit = function () {
    updateUser();
    $rootScope.$on(_module.AUTH_EVENTS.sessionUserUpdated, updateUser.bind(this));
  };

  this.toggleSidenav = function () {
    $mdSidenav(SIDENAV).toggle();
  };

  this.openUserDropdownMenu = function ($mdOpenMenu, ev) {
    $mdOpenMenu(ev);
  };

  this.logout = function () {
    var handle = function handle() {
      JWT.destroy();
      Session.destroy();
      IdleCheck.stop();
      $state.go('login');
    };

    AuthResource.logout().then(handle).catch(function () {
      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      console.log(args);
      handle();
    });
  };
}

exports.default = {
  templateUrl: 'common/navigation/templates/topbar.tpl.html',
  controller: TopbarController
};

},{"common/auth/module.constants":6}],53:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _breadcrumbs = require('./components/breadcrumbs');

var _breadcrumbs2 = _interopRequireDefault(_breadcrumbs);

var _sidenavMenu = require('./components/sidenavMenu');

var _sidenavMenu2 = _interopRequireDefault(_sidenavMenu);

var _sidenavSubmenu = require('./components/sidenavSubmenu');

var _sidenavSubmenu2 = _interopRequireDefault(_sidenavSubmenu);

var _topbar = require('./components/topbar');

var _topbar2 = _interopRequireDefault(_topbar);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _module = _angular2.default.module('gnaas.common.navigation', []).component('breadcrumbs', _breadcrumbs2.default).component('sidenavMenu', _sidenavMenu2.default).component('sidenavSubmenu', _sidenavSubmenu2.default).component('topbar', _topbar2.default);

exports.default = _module.name;

},{"./components/breadcrumbs":49,"./components/sidenavMenu":50,"./components/sidenavSubmenu":51,"./components/topbar":52,"angular":"angular"}],54:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _module = require('common/resource/module.constants');

exports.default = {
  bindings: {
    fields: '<',
    item: '<',
    onClick: '&',
    hideEditButton: '<',
    editButtonLabel: '<'
  },
  controller: function controller($mdSidenav) {
    'ngInject';

    this.profileFieldTypeItem = _module.RESOURCE_ITEM_FIELD_TYPES.item;
    this.profileFieldTypeCollection = _module.RESOURCE_ITEM_FIELD_TYPES.collection;
    this.profileFieldTypeRadio = _module.RESOURCE_ITEM_FIELD_TYPES.radio;

    this.hideEditButton = this.hideEditButton || false;
    this.editButtonLabel = this.editButtonLabel || 'Edit';

    this.close = function () {
      $mdSidenav('resource-item').close();
    };

    this.edit = function (event, item) {
      if (typeof this.onClick !== 'function') {
        return;
      }
      this.onClick({ event: event, item: item });
    };

    this.getProperty = function (item, key) {
      if (!item) return;
      if (key.indexOf('.') === -1) return item[key];
      try {
        return key.split('.').reduce(function (acc, part) {
          if (!acc.hasOwnProperty(part)) throw new Error();
          return acc[part];
        }, item);
      } catch (e) {
        return null;
      }
    };
  },
  templateUrl: 'common/resource/templates/resourceItem.tpl.html'
};

},{"common/resource/module.constants":57}],55:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ResourceItemListController = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _angular = require('angular');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var ResourceItemListController = exports.ResourceItemListController = function () {
  /* @ngInject */
  function ResourceItemListController($mdSidenav, Toast, Utils, Dialog, Session) {
    _classCallCheck(this, ResourceItemListController);

    this._mdSidenav = $mdSidenav;
    this._Toast = Toast;
    this._Utils = Utils;
    this._Dialog = Dialog;
    this._Session = Session;
  }

  _createClass(ResourceItemListController, [{
    key: '$onInit',
    value: function $onInit() {
      this.items = this.items || [];
      this.fields = this.fields || {};
      this.hasProfiles = this.hasProfiles || false;
    }
  }, {
    key: 'listButtonAction',
    value: function listButtonAction(evt, item) {
      if (this.hasProfiles) {
        this.showSidenav(item);
      } else {
        this.showCreateDialog(evt, item);
      }
    }
  }, {
    key: 'showSidenav',
    value: function showSidenav(item) {
      if (!this.hasProfiles) {
        return;
      }
      this.selectedItem = item;
      this._mdSidenav('resource-item').open();
    }
  }, {
    key: 'showCreateDialog',
    value: function showCreateDialog(evt, data) {
      var _this = this;

      if (this.hasProfiles) {
        this._mdSidenav('resource-item').close();
      }

      var editing = !!data;

      var dialogParams = {
        controller: this.createDialogController,
        locals: {
          editing: editing,
          item: (0, _angular.copy)(data),
          relationData: this.relationData
        },
        targetEvent: evt,
        templateUrl: this.createDialogTemplateUrl
      };

      this._Dialog.show(dialogParams).then(function (item) {
        if (editing) {
          _this._Utils.replaceInArrayById(_this.items, item.id, item);
          _this._Toast.showSimple(_this.singularName + ' updated.');
        } else {
          _this.items.push(item);
          _this._Toast.showSimple('New ' + _this.singularName + ' created.');
        }
      }).catch(function (itemId) {
        if (itemId) {
          _this._Utils.removeFromArrayById(_this.items, itemId);
          _this._Toast.showSimple(_this.singularName + ' deleted.');
        }
      });
    }
  }, {
    key: 'isAdmin',
    value: function isAdmin() {
      var user = this._Session.user();
      return user.group.slug === 'system-administrators';
    }
  }]);

  return ResourceItemListController;
}();

;

exports.default = {
  bindings: {
    items: '<',
    singularName: '@',
    pluralName: '@',
    createDialogController: '<',
    createDialogTemplateUrl: '@',
    relationData: '<',
    listFields: '<',
    hasProfiles: '<',
    profileSidenavId: '@',
    profileFields: '<'
  },
  controller: ResourceItemListController,
  templateUrl: 'common/resource/templates/resourceItemList.tpl.html'
};

},{"angular":"angular"}],56:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * @abstract
 */
var ResourceDialogController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function ResourceDialogController($injector, Dialog, Toast) {
    _classCallCheck(this, ResourceDialogController);

    if (this.constructor === ResourceDialogController) {
      throw new Error('Cannot construct an abstract class.');
    }
    this.$injector = $injector;
    this.Dialog = Dialog;
    this.Toast = Toast;
    this.init();
  }

  _createClass(ResourceDialogController, [{
    key: 'init',
    value: function init() {
      this.validationErrorMessage = 'Form validation error, please check all fields.';
    }
  }, {
    key: 'submit',
    value: function submit(formData) {
      if (!this.form) {
        throw new Error('Form not found.');
      }

      if (this.form.$invalid) {
        this.Toast.showSimple(this.validationErrorMessage);
        return;
      }

      if (this.editing) {
        this.update(formData);
      } else {
        this.create(formData);
      }
    }
  }, {
    key: 'cancel',
    value: function cancel() {
      this.Dialog.cancel();
    }
  }, {
    key: 'delete',
    value: function _delete(id) {
      var _this = this;

      this.Dialog.confirm().then(function () {
        _this.Dialog.cancel(id);
      });
    }
  }, {
    key: 'create',
    value: function create(formData) {
      this.Dialog.hide(formData);
    }
  }, {
    key: 'update',
    value: function update(formData) {
      this.Dialog.hide(formData);
    }
  }]);

  return ResourceDialogController;
}();

exports.default = ResourceDialogController;

},{}],57:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var RESOURCE_ITEM_FIELD_TYPES = exports.RESOURCE_ITEM_FIELD_TYPES = {
  item: 'RESOURCE_ITEM_FIELD_TYPE_ITEM',
  collection: 'RESOURCE_ITEM_FIELD_TYPE_COLLECTION',
  radio: 'RESOURCE_ITEM_FIELD_TYPE_RADIO'
};

var DATE_FORMAT = exports.DATE_FORMAT = 'YYYY-MM-DD';
var TIME_FORMAT = exports.TIME_FORMAT = 'HH:mm:ss';
var DATE_TIME_FORMAT = exports.DATE_TIME_FORMAT = DATE_FORMAT + ' ' + TIME_FORMAT;

},{}],58:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _resourceItem = require('./components/resourceItem');

var _resourceItem2 = _interopRequireDefault(_resourceItem);

var _resourceItemList = require('./components/resourceItemList');

var _resourceItemList2 = _interopRequireDefault(_resourceItemList);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var resourceModule = _angular2.default.module('gnaas.common.resource', []).component('resourceItem', _resourceItem2.default).component('resourceItemList', _resourceItemList2.default);

exports.default = resourceModule.name;

},{"./components/resourceItem":54,"./components/resourceItemList":55,"angular":"angular"}],59:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _angular = require('angular');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * @abstract
 */
var ResourceDialog = function () {
  /**
   * @constructor
   * @ngInject
   */
  function ResourceDialog($injector, Dialog) {
    _classCallCheck(this, ResourceDialog);

    if (this.constructor === ResourceDialog) {
      throw new Error('Cannot construct an abstract class.');
    }
    this.$injector = $injector;
    this._Dialog = Dialog;
    this._dialogOptions = {
      locals: {}
    };
    this.init();
  }

  _createClass(ResourceDialog, [{
    key: 'init',
    value: function init() {}
  }, {
    key: 'setController',
    value: function setController(controller) {
      this._dialogOptions.controller = controller;
    }
  }, {
    key: 'setTemplateUrl',
    value: function setTemplateUrl(templateUrl) {
      this._dialogOptions.templateUrl = templateUrl;
    }
  }, {
    key: 'setTemplate',
    value: function setTemplate(template) {
      this._dialogOptions.template = template;
    }
  }, {
    key: 'show',
    value: function show() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          $event = _ref.$event,
          item = _ref.item,
          relationData = _ref.relationData,
          locals = _ref.locals,
          multiple = _ref.multiple;

      var dialogParams = {
        locals: {
          editing: typeof item !== 'undefined' && item != null,
          item: (0, _angular.copy)(item),
          relationData: relationData
        },
        targetEvent: $event || null,
        multiple: !!multiple
      };
      if (locals && (typeof locals === 'undefined' ? 'undefined' : _typeof(locals)) === 'object') {
        Object.assign(dialogParams.locals, locals);
      }
      return this._Dialog.show(Object.assign({}, this._dialogOptions, dialogParams));
    }
  }]);

  return ResourceDialog;
}();

exports.default = ResourceDialog;

},{"angular":"angular"}],60:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _angular = require('angular');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * @abstract
 */
var RestResource = function () {
  /**
   * @constructor
   * @ngInject
   */
  function RestResource($q, $http, API_URL) {
    _classCallCheck(this, RestResource);

    if (this.constructor === RestResource) {
      throw new Error('Cannot construct an abstract class.');
    }
    this._q = $q;
    this._http = $http;
    this.apiUrl = API_URL;
    this.init();
  }

  _createClass(RestResource, [{
    key: 'init',
    value: function init() {
      this.resourcePath = '';
    }

    /* ****************************** */
    /*    RESTful resource methods    */
    /* ****************************** */

  }, {
    key: 'index',
    value: function index(query) {
      var _this = this;

      var url = this.apiUrl + this.resourcePath;

      if (query) {
        url += this._generateQueryString(query);
      }

      return this._http.get(url).then(function (response) {
        return response.data ? _this._formatData(response.data) : [];
      });
    }
  }, {
    key: 'show',
    value: function show(id, query) {
      var _this2 = this;

      var url = '' + this.apiUrl + this.resourcePath + '/' + id;

      if (query) {
        url += this._generateQueryString(query);
      }

      return this._http.get(url).then(function (response) {
        return response.data.data ? _this2._formatData(response.data.data) : null;
      });
    }
  }, {
    key: 'create',
    value: function create(data) {
      var _this3 = this;

      var url = this.apiUrl + this.resourcePath;
      return this._http.post(url, this._parseData(data)).then(function (response) {
        return response.data.data ? _this3._formatData(response.data.data) : null;
      });
    }
  }, {
    key: 'update',
    value: function update(id, data, query) {
      var _this4 = this;

      var url = '' + this.apiUrl + this.resourcePath + '/' + id;

      if (query) {
        url += this._generateQueryString(query);
      }

      var parsed = this._parseData(data);
      if (parsed.id) {
        delete parsed.id;
      }

      return this._http.put(url, parsed).then(function (response) {
        return response.data.data ? _this4._formatData(response.data.data) : null;
      });
    }
  }, {
    key: 'destroy',
    value: function destroy(id) {
      var url = '' + this.apiUrl + this.resourcePath + '/' + id;

      return this._http.delete(url).then(function (response) {
        return response.data;
      });
    }

    /* ************************** */
    /*    Parsers & formatters    */
    /* ************************** */

  }, {
    key: '_parseData',
    value: function _parseData(data) {
      var _this5 = this;

      if (Array.isArray(data)) {
        return data.map(function (item) {
          return _this5.parse(item);
        });
      } else {
        return this.parse(data);
      }
    }
  }, {
    key: '_formatData',
    value: function _formatData(data) {
      if (Array.isArray(data)) {
        return data.map(this.format);
      }

      if (data.data && data.meta && data.meta.pagination) {
        return {
          data: data.data.map(this.format),
          pagination: data.meta.pagination
        };
      }

      if (data.data) {
        return Array.isArray(data.data) ? data.data.map(this.format) : this.format(data.data);
      }

      return this.format(data);
    }
  }, {
    key: 'parse',
    value: function parse(item) {
      return (0, _angular.copy)(item);
    }
  }, {
    key: 'format',
    value: function format(item) {
      return (0, _angular.copy)(item);
    }

    /* ************** */
    /*    Includes    */
    /* ************** */

  }, {
    key: '_generateQueryString',
    value: function _generateQueryString() {
      var queryData = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var queryStr = '';
      var i = 0;

      Object.keys(queryData).forEach(function (key) {
        if (Array.isArray(queryData[key])) {
          queryData[key] = queryData[key].join(',');
        } else if (typeof queryData[key] === 'number') {
          queryData[key] = '' + queryData[key];
        } else if (typeof queryData[key] === 'boolean') {
          queryData[key] = queryData[key] ? 'true' : 'false';
        }

        if (queryData[key].length) {
          queryStr += (++i < 2 ? '?' : '&') + key + '=' + queryData[key];
        }
      });

      return queryStr.length > 1 ? queryStr : '';
    }
  }]);

  return RestResource;
}();

exports.default = RestResource;

},{"angular":"angular"}],61:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _gridRefToCoords = require('common/utils/services/gridRefToCoords');

var _invalidGridReferenceError = require('common/utils/errors/invalidGridReferenceError');

var _invalidGridReferenceError2 = _interopRequireDefault(_invalidGridReferenceError);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var REGEX_POSTCODE = /^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]?\s?[0-9][A-Za-z]{2}|[Gg][Ii][Rr]\s0[Aa]{2})$/;
var REGEX_GRID_REFERENCE = /^[a-zA-Z]{2}\s?\d{2,6}\s?\d{0,6}?$/;

var Location = function () {
  /**
   * @constructor
   * @ngInject
   */
  function Location($q, $http, GOOGLE_GEOCODING_KEY) {
    _classCallCheck(this, Location);

    this._$q = $q;
    this._$http = $http;
    this._GOOGLE_GEOCODING_KEY = GOOGLE_GEOCODING_KEY;
  }

  _createClass(Location, [{
    key: 'isGridReference',
    value: function isGridReference(value) {
      return REGEX_GRID_REFERENCE.test(value);
    }
  }, {
    key: 'isPostcode',
    value: function isPostcode(value) {
      return REGEX_POSTCODE.test(value);
    }
  }, {
    key: 'resolveCoordinatesFromGridReference',
    value: function resolveCoordinatesFromGridReference(gridref) {
      try {
        return (0, _gridRefToCoords.GridRefToCoords)(gridref);
      } catch (e) {
        if (e instanceof _invalidGridReferenceError2.default) {
          throw new Error('Invalid grid reference');
        } else {
          throw new Error('Converting grid reference to coordinates failed');
        }
      }
    }
  }, {
    key: 'resolveCoordinatesFromPostcode',
    value: function resolveCoordinatesFromPostcode(postcode) {
      var key = this._GOOGLE_GEOCODING_KEY;
      var url = 'https://maps.googleapis.com/maps/api/geocode/json?address=' + postcode + ',+UK&key=' + key;
      return this._$http.get(url).then(function (response) {
        var data = response.data;
        if (data.status !== 'OK' || !data.results) {
          throw new Error('Postcode lookup error');
        }
        return data.results[0].geometry.location;
      }, function () {
        throw new Error('Postcode lookup error');
      });
    }
  }, {
    key: 'resolveCoordinates',
    value: function resolveCoordinates(value) {
      var isPostcode = this.isPostcode(value);
      var isGridReference = this.isGridReference(value);

      if (!isPostcode && !isGridReference) {
        return this._$q.reject(new Error('Invalid grid reference or postcode'));
      }

      if (isPostcode) {
        return this.resolveCoordinatesFromPostcode(value);
      } else {
        try {
          return this._$q.resolve(this.resolveCoordinatesFromGridReference(value));
        } catch (e) {
          return this._$q.reject(e);
        }
      }
    }
  }]);

  return Location;
}();

exports.default = Location;

},{"common/utils/errors/invalidGridReferenceError":71,"common/utils/services/gridRefToCoords":79}],62:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _textareaDialog = require('./textarea-dialog/textarea-dialog.service');

var _textareaDialog2 = _interopRequireDefault(_textareaDialog);

var _timestampDialog = require('./timestamp-dialog/timestamp-dialog.service');

var _timestampDialog2 = _interopRequireDefault(_timestampDialog);

var _location = require('./location.service');

var _location2 = _interopRequireDefault(_location);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _module = _angular2.default.module('gnaas.common.services', []).service('TextareaDialog', _textareaDialog2.default).service('TimestampDialog', _timestampDialog2.default).service('Location', _location2.default);

exports.default = _module.name;

},{"./location.service":61,"./textarea-dialog/textarea-dialog.service":64,"./timestamp-dialog/timestamp-dialog.service":66,"angular":"angular"}],63:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var TextareaDialogController = function () {
  /**
    * @constructor
    * @ngInject
    */
  function TextareaDialogController(Dialog) {
    _classCallCheck(this, TextareaDialogController);

    this._Dialog = Dialog;
  }

  _createClass(TextareaDialogController, [{
    key: 'submit',
    value: function submit(value) {
      if (!this.form) {
        throw new Error('Form not found.');
      }

      if (this.form.$invalid) {
        return;
      }

      this._Dialog.hide(value);
    }
  }, {
    key: 'cancel',
    value: function cancel() {
      this._Dialog.cancel();
    }
  }]);

  return TextareaDialogController;
}();

exports.default = TextareaDialogController;

},{}],64:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _textareaDialog = require('./textarea-dialog.controller');

var _textareaDialog2 = _interopRequireDefault(_textareaDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * @abstract
 */
var TextareaDialog = function () {
  /**
   * @constructor
   * @ngInject
   */
  function TextareaDialog(Dialog) {
    _classCallCheck(this, TextareaDialog);

    this._Dialog = Dialog;
    this._dialogOptions = {
      controller: _textareaDialog2.default,
      templateUrl: 'common/services/textarea-dialog/textarea-dialog.tpl.html',
      locals: {}
    };
  }

  _createClass(TextareaDialog, [{
    key: 'show',
    value: function show(_ref) {
      var $event = _ref.$event,
          value = _ref.value,
          label = _ref.label,
          title = _ref.title;

      var dialogParams = {
        locals: { value: value, label: label, title: title },
        targetEvent: $event
      };
      return this._Dialog.show(Object.assign({}, this._dialogOptions, dialogParams));
    }
  }]);

  return TextareaDialog;
}();

exports.default = TextareaDialog;

},{"./textarea-dialog.controller":63}],65:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _resourceDialogController = require('common/resource/controllers/resourceDialogController');

var _resourceDialogController2 = _interopRequireDefault(_resourceDialogController);

var _dateTime = require('common/utils/services/dateTime');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var TimestampDialogController = function (_ResourceDialogContro) {
  _inherits(TimestampDialogController, _ResourceDialogContro);

  function TimestampDialogController() {
    _classCallCheck(this, TimestampDialogController);

    return _possibleConstructorReturn(this, (TimestampDialogController.__proto__ || Object.getPrototypeOf(TimestampDialogController)).apply(this, arguments));
  }

  _createClass(TimestampDialogController, [{
    key: 'init',
    value: function init() {
      _get(TimestampDialogController.prototype.__proto__ || Object.getPrototypeOf(TimestampDialogController.prototype), 'init', this).call(this);

      if (!this.item) {
        this.item = {
          date: new Date(),
          time: (0, _moment2.default)().startOf('minute').toDate()
        };
      }

      this.validateAgainst = this.locals.validateAgainst || [];
      this.validationErrorMessage = this.locals.validationErrorMessage || 'Timestamp invalid.';
    }
  }, {
    key: 'submit',
    value: function submit(timestamp) {
      if (!this.form) {
        throw new Error('Form not found.');
      }

      if (this.form.$invalid) {
        return;
      }

      // Validate against provided timestamps
      var timestampStr = (0, _dateTime.toDateTimeString)(timestamp.date, timestamp.time);
      if (~this.validateAgainst.indexOf(timestampStr)) {
        this.Dialog.alert(this.validationErrorMessage);
        return;
      }

      return this.Dialog.hide(timestamp);
    }
  }]);

  return TimestampDialogController;
}(_resourceDialogController2.default);

exports.default = TimestampDialogController;

},{"common/resource/controllers/resourceDialogController":56,"common/utils/services/dateTime":77,"moment":"moment"}],66:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _resourceDialog = require('common/resource/services/resourceDialog');

var _resourceDialog2 = _interopRequireDefault(_resourceDialog);

var _timestampDialog = require('./timestamp-dialog.controller');

var _timestampDialog2 = _interopRequireDefault(_timestampDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var TimestampDialog = function (_ResourceDialog) {
  _inherits(TimestampDialog, _ResourceDialog);

  function TimestampDialog() {
    _classCallCheck(this, TimestampDialog);

    return _possibleConstructorReturn(this, (TimestampDialog.__proto__ || Object.getPrototypeOf(TimestampDialog)).apply(this, arguments));
  }

  _createClass(TimestampDialog, [{
    key: 'init',
    value: function init() {
      _get(TimestampDialog.prototype.__proto__ || Object.getPrototypeOf(TimestampDialog.prototype), 'init', this).call(this);
      this.setController(_timestampDialog2.default);
      this.setTemplateUrl('common/services/timestamp-dialog/timestamp-dialog.tpl.html');
    }
  }]);

  return TimestampDialog;
}(_resourceDialog2.default);

exports.default = TimestampDialog;

},{"./timestamp-dialog.controller":65,"common/resource/services/resourceDialog":59}],67:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = decimalValidator;
function decimalValidator() {
  return {
    replace: false,
    require: 'ngModel',
    restrict: 'A',
    link: function link($scope, $element, $attrs, $ctrl) {
      $element.on('blur', function () {
        if (/^[0-9]{1,6}\.$/.test($ctrl.$modelValue)) {
          var newValue = $ctrl.$modelValue.replace('.', '');
          $ctrl.$setViewValue(newValue);
          $ctrl.$render();
        }
      });

      $ctrl.$parsers.push(function (input) {
        if (!input || /^[0-9]{1,6}(\.)?([0-9]{1,2})?$/.test(input)) return input;
        $ctrl.$setViewValue($ctrl.$modelValue);
        $ctrl.$render();
        return $ctrl.$modelValue;
      });

      $ctrl.$formatters.push(function (value) {
        return parseFloat(value);
      });
    }
  };
};

},{}],68:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = file;
function file() {
  return {
    scope: {
      file: '='
    },
    link: function link(scope, el, attrs) {
      el.bind('change', function (event) {
        var file = event.target.files[0];
        if (file) {
          scope.file = file;
        } else {
          scope.file = undefined;
        }
        scope.$apply();
      });
    }
  };
}

},{}],69:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = integerValidator;
function integerValidator() {
  return {
    replace: false,
    require: 'ngModel',
    restrict: 'A',
    link: function link($scope, $element, $attrs, $ctrl) {
      $element.on('keydown', function (e) {
        if (e.key === '.') e.preventDefault();
      });

      $ctrl.$parsers.push(function (input) {
        if (!input || /^[0-9]*$/.test(input)) return input;
        $ctrl.$setViewValue($ctrl.$modelValue);
        $ctrl.$render();
        return $ctrl.$modelValue;
      });

      $ctrl.$formatters.push(function (value) {
        return parseInt(value, 10);
      });
    }
  };
};

},{}],70:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = WindowResizeVerticalHandler;

var _angular = require('angular');

function WindowResizeVerticalHandler($rootScope, $window) {
  'ngInject';

  return {
    replace: false,
    restrict: 'A',
    link: function link($scope, $element) {
      var win = (0, _angular.element)($window);
      var height = win.prop('innerHeight');

      win.on('resize', function () {
        if (height !== win.prop('innerHeight')) {
          $rootScope.$emit('WINDOW_RESIZE');
        }
      });

      $scope.$on('$destroy', function () {
        win.off('resize');
      });
    }
  };
}

},{"angular":"angular"}],71:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
function InvalidGridReferenceError(message) {
  this.message = message;
  this.name = this.constructor.name;
  this.stack = new Error().stack;
}

InvalidGridReferenceError.prototype = Error.prototype;
InvalidGridReferenceError.prototype.constructor = InvalidGridReferenceError;

exports.default = InvalidGridReferenceError;

},{}],72:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = nhsNo;
function nhsNo() {
  return function nhsNo(input) {
    if (typeof input === 'undefined' || input == null) {
      return input;
    }
    return input.slice(0, 3) + ' ' + input.slice(3, 6) + ' ' + input.slice(6);
  };
};

},{}],73:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = nl2br;
function nl2br($sce) {
  'ngInject';

  return function nl2br(input) {
    if (typeof input === 'undefined' || input == null) {
      return input;
    }

    input = input.replace(/\n/g, '<br>');
    return $sce.trustAsHtml(input);
  };
};

},{}],74:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = prettify;
function prettify() {
  return function prettify(input) {
    if (typeof input === 'undefined' || input == null) {
      return input;
    }
    var output = input.split('_').join(' ');
    return output.charAt(0).toUpperCase() + output.slice(1);
  };
};

},{}],75:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ucfirst;
function ucfirst() {
  return function ucfirst(input) {
    if (typeof input === 'undefined' || input == null) {
      return input;
    }

    return input.charAt(0).toUpperCase() + input.slice(1);
  };
};

},{}],76:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _utils = require('./services/utils');

var _utils2 = _interopRequireDefault(_utils);

var _dialog = require('./services/dialog');

var _dialog2 = _interopRequireDefault(_dialog);

var _toast = require('./services/toast');

var _toast2 = _interopRequireDefault(_toast);

var _nl2br = require('./filters/nl2br');

var _nl2br2 = _interopRequireDefault(_nl2br);

var _ucfirst = require('./filters/ucfirst');

var _ucfirst2 = _interopRequireDefault(_ucfirst);

var _prettify = require('./filters/prettify');

var _prettify2 = _interopRequireDefault(_prettify);

var _nhsNo = require('./filters/nhsNo');

var _nhsNo2 = _interopRequireDefault(_nhsNo);

var _file = require('./directives/file');

var _file2 = _interopRequireDefault(_file);

var _decimalValidator = require('./directives/decimalValidator');

var _decimalValidator2 = _interopRequireDefault(_decimalValidator);

var _integerValidator = require('./directives/integerValidator');

var _integerValidator2 = _interopRequireDefault(_integerValidator);

var _windowResizeVerticalHandler = require('./directives/windowResizeVerticalHandler');

var _windowResizeVerticalHandler2 = _interopRequireDefault(_windowResizeVerticalHandler);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _module = _angular2.default.module('gnaas.common.utils', []).service('Utils', _utils2.default).service('Dialog', _dialog2.default).service('Toast', _toast2.default).filter('ucfirst', _ucfirst2.default).filter('nl2br', _nl2br2.default).filter('prettify', _prettify2.default).filter('nhsNo', _nhsNo2.default).directive('file', _file2.default).directive('decimalValidator', _decimalValidator2.default).directive('integerValidator', _integerValidator2.default).directive('windowResizeVerticalHandler', _windowResizeVerticalHandler2.default);

exports.default = _module.name;

},{"./directives/decimalValidator":67,"./directives/file":68,"./directives/integerValidator":69,"./directives/windowResizeVerticalHandler":70,"./filters/nhsNo":72,"./filters/nl2br":73,"./filters/prettify":74,"./filters/ucfirst":75,"./services/dialog":78,"./services/toast":80,"./services/utils":81,"angular":"angular"}],77:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toDateTimeString = toDateTimeString;
exports.toDateTimeObject = toDateTimeObject;

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _module = require('common/resource/module.constants');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function combineDateTime(date, time) {
  var t = (0, _moment2.default)(time);
  return (0, _moment2.default)(date).set({
    'hour': t.get('hour'),
    'minute': t.get('minute'),
    'second': t.get('second')
  });
}

function toDateTimeString(date) {
  var time = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

  if (time == null) return (0, _moment2.default)(date).format(_module.DATE_TIME_FORMAT);
  return combineDateTime(date, time).format(_module.DATE_TIME_FORMAT);
}

function toDateTimeObject(date) {
  var time = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

  if (time == null) return (0, _moment2.default)(date).toDate();
  return combineDateTime(date, time).toDate();
}

},{"common/resource/module.constants":57,"moment":"moment"}],78:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _angular = require('angular');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var defaultParams = {
  bindToController: true,
  controller: function controller() {},
  controllerAs: '$ctrl',
  clickOutsideToClose: false,
  escapeToClose: false,
  fullscreen: false,
  parent: (0, _angular.element)(document.body),
  multiple: false
};

var Dialog = function () {
  /**
   * @constructor
   * @ngInject
   */
  function Dialog($mdDialog) {
    _classCallCheck(this, Dialog);

    this._mdDialog = $mdDialog;
  }

  /**
   * Show a dialog with given parameters.
   *
   * @param params
   * @returns {*}
   */


  _createClass(Dialog, [{
    key: 'show',
    value: function show(params) {
      if (!params) {
        params = {};
      }
      var dialogParams = Object.assign({}, defaultParams, params);
      return this._mdDialog.show(dialogParams);
    }

    /**
     * Show a confirmation dialog.
     *
     * @param text
     * @returns {*}
     */

  }, {
    key: 'confirm',
    value: function confirm(text) {
      text = text || 'Are you sure?';
      var confirmOpts = this._mdDialog.confirm().title('Are you sure?').textContent(text).ariaLabel('Confirmation').ok('Yes').cancel('No');

      var opts = Object.assign({}, confirmOpts._options, { multiple: true });
      return this._mdDialog.show(opts);
    }

    /**
     * Show an alert dialog.
     *
     * @param text
     * @param title
     * @returns {*}
     */

  }, {
    key: 'alert',
    value: function alert(text, title) {
      text = text || "Something's not right!";
      title = title || 'Alert';
      var alertOpts = this._mdDialog.alert().title(title).textContent(text).ariaLabel('Alert').ok('Ok');

      var opts = Object.assign({}, alertOpts._options, { multiple: true });
      return this._mdDialog.show(opts);
    }

    /**
     * Show a delete prompt dialog.
     *
     * @returns {*}
     */

  }, {
    key: 'promptDelete',
    value: function promptDelete() {
      var promptOpts = this._mdDialog.prompt().title('Are you sure?').textContent("Please type 'delete' in the text box below to confirm.").placeholder('delete').ariaLabel('delete').required(true).ok('Yes').cancel('No');

      var promptController = function promptController($mdDialog, $mdConstant, $element, $timeout) {
        'ngInject';

        var _this = this;

        var ngModel = void 0;

        this.$onInit = function () {
          _this.abort = function () {
            return $mdDialog.cancel();
          };
          _this.keypress = function ($event) {
            if ($event.keyCode === $mdConstant.KEY_CODE.ENTER) _this.hide();
          };
          _this.hide = function () {
            if (_this.result === 'delete') {
              ngModel.$invalid = false;
              $mdDialog.hide();
            } else {
              ngModel.$invalid = true;
            }
          };
        };

        $timeout(function () {
          ngModel = $element.find('input').controller('ngModel');

          ngModel.$validators.matchesDelete = function (modelValue, viewValue) {
            var value = modelValue || viewValue;
            return value === 'delete';
          };
        });
      };

      var opts = Object.assign({}, promptOpts._options, { controller: promptController, multiple: true });
      return this._mdDialog.show(opts);
    }

    /**
     * Close (error) a dialog by ID
     *
     * @param id
     * @returns {*}
     */

  }, {
    key: 'cancel',
    value: function cancel(id) {
      return this._mdDialog.cancel(id);
    }

    /**
     * Hide (success) a dialog by ID
     *
     * @param data
     * @returns {*}
     */

  }, {
    key: 'hide',
    value: function hide(data) {
      return this._mdDialog.hide(data);
    }
  }]);

  return Dialog;
}();

exports.default = Dialog;
;

},{"angular":"angular"}],79:[function(require,module,exports){
/* eslint-disable */

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */
/* Ordnance Survey Grid Reference functions                           (c) Chris Veness 2005-2016  */
/*                                                                                   MIT Licence  */
/* www.movable-type.co.uk/scripts/latlong-gridref.html                                            */
/* www.movable-type.co.uk/scripts/geodesy/docs/module-osgridref.html                              */
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */

'use strict';
// if (typeof module!='undefined' && module.exports) var LatLon = require('./latlon-ellipsoidal.js');

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.GridRefToCoords = exports.CoordsToGridRef = undefined;

var _geodesy = require('geodesy');

var _invalidGridReferenceError = require('common/utils/errors/invalidGridReferenceError');

var _invalidGridReferenceError2 = _interopRequireDefault(_invalidGridReferenceError);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Convert OS grid references to/from OSGB latitude/longitude points.
 *
 * Formulation implemented here due to Thomas, Redfearn, etc is as published by OS, but is inferior
 * to Krüger as used by e.g. Karney 2011.
 *
 * www.ordnancesurvey.co.uk/docs/support/guide-coordinate-systems-great-britain.pdf.
 *
 * @module   osgridref
 * @requires latlon-ellipsoidal
 */
/*
 * Converted 2015 to work with WGS84 by default, OSGB36 as option;
 * www.ordnancesurvey.co.uk/blog/2014/12/confirmation-on-changes-to-latitude-and-longitude
 */

/**
 * Creates an OsGridRef object.
 *
 * @constructor
 * @param {number} easting - Easting in metres from OS false origin.
 * @param {number} northing - Northing in metres from OS false origin.
 *
 * @example
 *   var grid = new OsGridRef(651409, 313177);
 */
function OsGridRef(easting, northing) {
    // allow instantiation without 'new'
    if (!(this instanceof OsGridRef)) return new OsGridRef(easting, northing);

    this.easting = Number(easting);
    this.northing = Number(northing);
}

/**
 * Converts latitude/longitude to Ordnance Survey grid reference easting/northing coordinate.
 *
 * Note formulation implemented here due to Thomas, Redfearn, etc is as published by OS, but is
 * inferior to Krüger as used by e.g. Karney 2011.
 *
 * @param   {LatLon}    point - latitude/longitude.
 * @returns {OsGridRef} OS Grid Reference easting/northing.
 *
 * @example
 *   var p = new LatLon(52.65798, 1.71605);
 *   var grid = OsGridRef.latLonToOsGrid(p); // grid.toString(): TG 51409 13177
 *   // for conversion of (historical) OSGB36 latitude/longitude point:
 *   var p = new LatLon(52.65757, 1.71791, LatLon.datum.OSGB36);
 */
OsGridRef.latLonToOsGrid = function (point) {
    if (!(point instanceof _geodesy.LatLonEllipsoidal)) throw new TypeError('point is not LatLon object');

    // if necessary convert to OSGB36 first
    if (point.datum != _geodesy.LatLonEllipsoidal.datum.OSGB36) point = point.convertDatum(_geodesy.LatLonEllipsoidal.datum.OSGB36);

    var φ = point.lat.toRadians();
    var λ = point.lon.toRadians();

    var a = 6377563.396,
        b = 6356256.909; // Airy 1830 major & minor semi-axes
    var F0 = 0.9996012717; // NatGrid scale factor on central meridian
    var φ0 = 49 .toRadians(),
        λ0 = (-2).toRadians(); // NatGrid true origin is 49°N,2°W
    var N0 = -100000,
        E0 = 400000; // northing & easting of true origin, metres
    var e2 = 1 - b * b / (a * a); // eccentricity squared
    var n = (a - b) / (a + b),
        n2 = n * n,
        n3 = n * n * n; // n, n², n³

    var cosφ = Math.cos(φ),
        sinφ = Math.sin(φ);
    var ν = a * F0 / Math.sqrt(1 - e2 * sinφ * sinφ); // nu = transverse radius of curvature
    var ρ = a * F0 * (1 - e2) / Math.pow(1 - e2 * sinφ * sinφ, 1.5); // rho = meridional radius of curvature
    var η2 = ν / ρ - 1; // eta = ?

    var Ma = (1 + n + 5 / 4 * n2 + 5 / 4 * n3) * (φ - φ0);
    var Mb = (3 * n + 3 * n * n + 21 / 8 * n3) * Math.sin(φ - φ0) * Math.cos(φ + φ0);
    var Mc = (15 / 8 * n2 + 15 / 8 * n3) * Math.sin(2 * (φ - φ0)) * Math.cos(2 * (φ + φ0));
    var Md = 35 / 24 * n3 * Math.sin(3 * (φ - φ0)) * Math.cos(3 * (φ + φ0));
    var M = b * F0 * (Ma - Mb + Mc - Md); // meridional arc

    var cos3φ = cosφ * cosφ * cosφ;
    var cos5φ = cos3φ * cosφ * cosφ;
    var tan2φ = Math.tan(φ) * Math.tan(φ);
    var tan4φ = tan2φ * tan2φ;

    var I = M + N0;
    var II = ν / 2 * sinφ * cosφ;
    var III = ν / 24 * sinφ * cos3φ * (5 - tan2φ + 9 * η2);
    var IIIA = ν / 720 * sinφ * cos5φ * (61 - 58 * tan2φ + tan4φ);
    var IV = ν * cosφ;
    var V = ν / 6 * cos3φ * (ν / ρ - tan2φ);
    var VI = ν / 120 * cos5φ * (5 - 18 * tan2φ + tan4φ + 14 * η2 - 58 * tan2φ * η2);

    var Δλ = λ - λ0;
    var Δλ2 = Δλ * Δλ,
        Δλ3 = Δλ2 * Δλ,
        Δλ4 = Δλ3 * Δλ,
        Δλ5 = Δλ4 * Δλ,
        Δλ6 = Δλ5 * Δλ;

    var N = I + II * Δλ2 + III * Δλ4 + IIIA * Δλ6;
    var E = E0 + IV * Δλ + V * Δλ3 + VI * Δλ5;

    N = Number(N.toFixed(3)); // round to mm precision
    E = Number(E.toFixed(3));

    return new OsGridRef(E, N); // gets truncated to SW corner of 1m grid square
};

/**
 * Converts Ordnance Survey grid reference easting/northing coordinate to latitude/longitude
 * (SW corner of grid square).
 *
 * Note formulation implemented here due to Thomas, Redfearn, etc is as published by OS, but is
 * inferior to Krüger as used by e.g. Karney 2011.
 *
 * @param   {OsGridRef}    gridref - Grid ref E/N to be converted to lat/long (SW corner of grid square).
 * @param   {LatLon.datum} [datum=WGS84] - Datum to convert grid reference into.
 * @returns {LatLon}       Latitude/longitude of supplied grid reference.
 *
 * @example
 *   var gridref = new OsGridRef(651409.903, 313177.270);
 *   var pWgs84 = OsGridRef.osGridToLatLon(gridref);                     // 52°39′28.723″N, 001°42′57.787″E
 *   // to obtain (historical) OSGB36 latitude/longitude point:
 *   var pOsgb = OsGridRef.osGridToLatLon(gridref, LatLon.datum.OSGB36); // 52°39′27.253″N, 001°43′04.518″E
 */
OsGridRef.osGridToLatLon = function (gridref, datum) {
    if (!(gridref instanceof OsGridRef)) throw new TypeError('gridref is not OsGridRef object');
    if (datum === undefined) datum = _geodesy.LatLonEllipsoidal.datum.WGS84;

    var E = gridref.easting;
    var N = gridref.northing;

    var a = 6377563.396,
        b = 6356256.909; // Airy 1830 major & minor semi-axes
    var F0 = 0.9996012717; // NatGrid scale factor on central meridian
    var φ0 = 49 .toRadians(),
        λ0 = (-2).toRadians(); // NatGrid true origin is 49°N,2°W
    var N0 = -100000,
        E0 = 400000; // northing & easting of true origin, metres
    var e2 = 1 - b * b / (a * a); // eccentricity squared
    var n = (a - b) / (a + b),
        n2 = n * n,
        n3 = n * n * n; // n, n², n³

    var φ = φ0,
        M = 0;
    do {
        φ = (N - N0 - M) / (a * F0) + φ;

        var Ma = (1 + n + 5 / 4 * n2 + 5 / 4 * n3) * (φ - φ0);
        var Mb = (3 * n + 3 * n * n + 21 / 8 * n3) * Math.sin(φ - φ0) * Math.cos(φ + φ0);
        var Mc = (15 / 8 * n2 + 15 / 8 * n3) * Math.sin(2 * (φ - φ0)) * Math.cos(2 * (φ + φ0));
        var Md = 35 / 24 * n3 * Math.sin(3 * (φ - φ0)) * Math.cos(3 * (φ + φ0));
        M = b * F0 * (Ma - Mb + Mc - Md); // meridional arc
    } while (N - N0 - M >= 0.00001); // ie until < 0.01mm

    var cosφ = Math.cos(φ),
        sinφ = Math.sin(φ);
    var ν = a * F0 / Math.sqrt(1 - e2 * sinφ * sinφ); // nu = transverse radius of curvature
    var ρ = a * F0 * (1 - e2) / Math.pow(1 - e2 * sinφ * sinφ, 1.5); // rho = meridional radius of curvature
    var η2 = ν / ρ - 1; // eta = ?

    var tanφ = Math.tan(φ);
    var tan2φ = tanφ * tanφ,
        tan4φ = tan2φ * tan2φ,
        tan6φ = tan4φ * tan2φ;
    var secφ = 1 / cosφ;
    var ν3 = ν * ν * ν,
        ν5 = ν3 * ν * ν,
        ν7 = ν5 * ν * ν;
    var VII = tanφ / (2 * ρ * ν);
    var VIII = tanφ / (24 * ρ * ν3) * (5 + 3 * tan2φ + η2 - 9 * tan2φ * η2);
    var IX = tanφ / (720 * ρ * ν5) * (61 + 90 * tan2φ + 45 * tan4φ);
    var X = secφ / ν;
    var XI = secφ / (6 * ν3) * (ν / ρ + 2 * tan2φ);
    var XII = secφ / (120 * ν5) * (5 + 28 * tan2φ + 24 * tan4φ);
    var XIIA = secφ / (5040 * ν7) * (61 + 662 * tan2φ + 1320 * tan4φ + 720 * tan6φ);

    var dE = E - E0,
        dE2 = dE * dE,
        dE3 = dE2 * dE,
        dE4 = dE2 * dE2,
        dE5 = dE3 * dE2,
        dE6 = dE4 * dE2,
        dE7 = dE5 * dE2;
    φ = φ - VII * dE2 + VIII * dE4 - IX * dE6;
    var λ = λ0 + X * dE - XI * dE3 + XII * dE5 - XIIA * dE7;

    var point = new _geodesy.LatLonEllipsoidal(φ.toDegrees(), λ.toDegrees(), _geodesy.LatLonEllipsoidal.datum.OSGB36);
    if (datum != _geodesy.LatLonEllipsoidal.datum.OSGB36) point = point.convertDatum(datum);

    return point;
};

/**
 * Parses grid reference to OsGridRef object.
 *
 * Accepts standard grid references (eg 'SU 387 148'), with or without whitespace separators, from
 * two-digit references up to 10-digit references (1m × 1m square), or fully numeric comma-separated
 * references in metres (eg '438700,114800').
 *
 * @param   {string}    gridref - Standard format OS grid reference.
 * @returns {OsGridRef} Numeric version of grid reference in metres from false origin (SW corner of
 *   supplied grid square).
 * @throws Error on Invalid grid reference.
 *
 * @example
 *   var grid = OsGridRef.parse('TG 51409 13177'); // grid: { easting: 651409, northing: 313177 }
 */
OsGridRef.parse = function (gridref) {
    gridref = String(gridref).trim();

    // check for fully numeric comma-separated gridref format
    var match = gridref.match(/^(\d+),\s*(\d+)$/);
    if (match) return new OsGridRef(match[1], match[2]);

    // validate format
    match = gridref.match(/^[A-Z]{2}\s*[0-9]+\s*[0-9]+$/i);
    if (!match) throw new Error('Invalid grid reference');

    // get numeric values of letter references, mapping A->0, B->1, C->2, etc:
    var l1 = gridref.toUpperCase().charCodeAt(0) - 'A'.charCodeAt(0);
    var l2 = gridref.toUpperCase().charCodeAt(1) - 'A'.charCodeAt(0);
    // shuffle down letters after 'I' since 'I' is not used in grid:
    if (l1 > 7) l1--;
    if (l2 > 7) l2--;

    // convert grid letters into 100km-square indexes from false origin (grid square SV):
    var e100km = (l1 - 2) % 5 * 5 + l2 % 5;
    var n100km = 19 - Math.floor(l1 / 5) * 5 - Math.floor(l2 / 5);

    // skip grid letters to get numeric (easting/northing) part of ref
    var en = gridref.slice(2).trim().split(/\s+/);
    // if e/n not whitespace separated, split half way
    if (en.length == 1) en = [en[0].slice(0, en[0].length / 2), en[0].slice(en[0].length / 2)];

    // validation
    if (e100km < 0 || e100km > 6 || n100km < 0 || n100km > 12) throw new Error('Invalid grid reference');
    if (en.length != 2) throw new Error('Invalid grid reference');
    if (en[0].length != en[1].length) throw new Error('Invalid grid reference');

    // standardise to 10-digit refs (metres)
    en[0] = (en[0] + '00000').slice(0, 5);
    en[1] = (en[1] + '00000').slice(0, 5);

    var e = e100km + en[0];
    var n = n100km + en[1];

    return new OsGridRef(e, n);
};

/**
 * Converts ‘this’ numeric grid reference to standard OS grid reference.
 *
 * @param   {number} [digits=10] - Precision of returned grid reference (10 digits = metres).
 * @returns {string} This grid reference in standard format.
 */
OsGridRef.prototype.toString = function (digits) {
    digits = digits === undefined ? 10 : Number(digits);
    if (isNaN(digits)) throw new Error('Invalid precision');

    var e = this.easting;
    var n = this.northing;
    if (isNaN(e) || isNaN(n)) throw new Error('Invalid grid reference.');

    // use digits = 0 to return numeric format (in metres)
    if (digits == 0) return e.pad(6) + ',' + n.pad(6);

    // get the 100km-grid indices
    var e100k = Math.floor(e / 100000),
        n100k = Math.floor(n / 100000);

    if (e100k < 0 || e100k > 6 || n100k < 0 || n100k > 12) return '';

    // translate those into numeric equivalents of the grid letters
    var l1 = 19 - n100k - (19 - n100k) % 5 + Math.floor((e100k + 10) / 5);
    var l2 = (19 - n100k) * 5 % 25 + e100k % 5;

    // compensate for skipped 'I' and build grid letter-pairs
    if (l1 > 7) l1++;
    if (l2 > 7) l2++;
    var letPair = String.fromCharCode(l1 + 'A'.charCodeAt(0), l2 + 'A'.charCodeAt(0));

    // strip 100km-grid indices from easting & northing, and reduce precision
    e = Math.floor(e % 100000 / Math.pow(10, 5 - digits / 2));
    n = Math.floor(n % 100000 / Math.pow(10, 5 - digits / 2));

    var gridRef = letPair + ' ' + e.pad(digits / 2) + ' ' + n.pad(digits / 2);

    return gridRef;
};

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */

/** Polyfill String.trim for old browsers
 *  (q.v. blog.stevenlevithan.com/archives/faster-trim-javascript) */
if (String.prototype.trim === undefined) {
    String.prototype.trim = function () {
        return String(this).replace(/^\s\s*/, '').replace(/\s\s*$/, '');
    };
}

/** Extend Number object with method to pad with leading zeros to make it w chars wide
 *  (q.v. stackoverflow.com/questions/2998784 */
if (Number.prototype.pad === undefined) {
    Number.prototype.pad = function (w) {
        var n = this.toString();
        while (n.length < w) {
            n = '0' + n;
        }return n;
    };
}

function GridRefToCoords(gridRef) {
    var LatLon = void 0;
    try {
        LatLon = OsGridRef.osGridToLatLon(OsGridRef.parse(gridRef));
    } catch (e) {
        throw new _invalidGridReferenceError2.default('Invalid grid reference.');
    }

    return {
        lat: LatLon.lat,
        lng: LatLon.lon
    };
}

function CoordsToGridRef(latLng) {
    var latlon = new _geodesy.LatLonEllipsoidal(latLng.lat, latLng.lng);
    var parts = OsGridRef.latLonToOsGrid(latlon).toString().split(" ");
    return {
        square: parts[0],
        reference: parts[1].substr(0, 3) + parts[2].substr(0, 3)
    };
}

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */
// if (typeof module != 'undefined' && module.exports) module.exports = OsGridRef; // ≡ export default OsGridRef
exports.CoordsToGridRef = CoordsToGridRef;
exports.GridRefToCoords = GridRefToCoords;

},{"common/utils/errors/invalidGridReferenceError":71,"geodesy":"geodesy"}],80:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Toast;
function Toast($mdToast) {
  'ngInject';

  this.showSimple = function (textContent) {
    $mdToast.show($mdToast.simple().textContent(textContent).position('top right'));
  };
};

},{}],81:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Utils;
function Utils() {
  /**
   * Arrays.
   */
  function findObjectInArrayById(array, id) {
    var result = void 0;

    array.some(function (element) {
      var match = element.id === id;
      if (match) {
        result = element;
      }
      return match;
    });

    return result;
  }

  function findObjectInArrayByKey(array, keyValue, keyName) {
    var result = void 0;

    array.some(function (element) {
      var match = element[keyName] === keyValue;
      if (match) {
        result = element;
      }
      return match;
    });

    return result;
  }

  function removeFromArray(array, value) {
    var index = array.indexOf(value);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  function removeFromArrayById(array, id) {
    var item = findObjectInArrayById(array, id);
    removeFromArray(array, item);
  }

  function replaceInArrayById(array, id, replacement) {
    var item = findObjectInArrayById(array, id);
    var index = array.indexOf(item);
    if (index !== -1) {
      array[index] = replacement;
    }
  }

  /**
   * Strings.
   */
  function spacesToCamelCase(string) {
    return string.trim().replace(/\w\S*/g, function (string, index) {
      return index === 0 ? string.toLowerCase() : string.charAt(0).toUpperCase() + string.substr(1).toLowerCase();
    }).replace(/\s*/g, '');
  }

  function getDateRangeString(start, end) {
    var dateString = '';
    if (start.year() === end.year() && start.month() === end.month()) {
      // Year and month the same
      dateString = start.format('D') + ' \u2013 ' + end.format('D MMM YYYY');
    } else if (start.year() === end.year() && start.month() !== end.month()) {
      // Different months
      dateString = start.format('D MMM') + ' \u2013 ' + end.format('D MMM YYYY');
    } else {
      // Different years
      dateString = start.format('D MMM YYYY') + ' \u2013 ' + end.format('D MMM YYYY');
    }
    return dateString;
  }

  return {
    findObjectInArrayById: findObjectInArrayById,
    removeFromArray: removeFromArray,
    removeFromArrayById: removeFromArrayById,
    replaceInArrayById: replaceInArrayById,
    spacesToCamelCase: spacesToCamelCase,
    getDateRangeString: getDateRangeString,
    findObjectInArrayByKey: findObjectInArrayByKey
  };
}

},{}],82:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _abortReason = require('./resources/abort-reason.resource');

var _abortReason2 = _interopRequireDefault(_abortReason);

var _ambulanceService = require('./resources/ambulance-service.resource');

var _ambulanceService2 = _interopRequireDefault(_ambulanceService);

var _bodyPart = require('./resources/body-part.resource');

var _bodyPart2 = _interopRequireDefault(_bodyPart);

var _calendarEventType = require('./resources/calendar-event-type.resource');

var _calendarEventType2 = _interopRequireDefault(_calendarEventType);

var _consumableType = require('./resources/consumable-type.resource');

var _consumableType2 = _interopRequireDefault(_consumableType);

var _deploymentReason = require('./resources/deployment-reason.resource');

var _deploymentReason2 = _interopRequireDefault(_deploymentReason);

var _deploymentStatus = require('./resources/deployment-status.resource');

var _deploymentStatus2 = _interopRequireDefault(_deploymentStatus);

var _grade = require('./resources/grade.resource');

var _grade2 = _interopRequireDefault(_grade);

var _hospitalSpecialty = require('./resources/hospital-specialty.resource');

var _hospitalSpecialty2 = _interopRequireDefault(_hospitalSpecialty);

var _illness = require('./resources/illness.resource');

var _illness2 = _interopRequireDefault(_illness);

var _injury = require('./resources/injury.resource');

var _injury2 = _interopRequireDefault(_injury);

var _intervention = require('./resources/intervention.resource');

var _intervention2 = _interopRequireDefault(_intervention);

var _location = require('./resources/location.resource');

var _location2 = _interopRequireDefault(_location);

var _locationType = require('./resources/location-type.resource');

var _locationType2 = _interopRequireDefault(_locationType);

var _offileReason = require('./resources/offile-reason.resource');

var _offileReason2 = _interopRequireDefault(_offileReason);

var _patientType = require('./resources/patient-type.resource');

var _patientType2 = _interopRequireDefault(_patientType);

var _profession = require('./resources/profession.resource');

var _profession2 = _interopRequireDefault(_profession);

var _refusalReason = require('./resources/refusal-reason.resource');

var _refusalReason2 = _interopRequireDefault(_refusalReason);

var _riskAssessmentOutcome = require('./resources/risk-assessment-outcome.resource');

var _riskAssessmentOutcome2 = _interopRequireDefault(_riskAssessmentOutcome);

var _serviceArea = require('./resources/service-area.resource');

var _serviceArea2 = _interopRequireDefault(_serviceArea);

var _stockLocation = require('./resources/stock-location.resource');

var _stockLocation2 = _interopRequireDefault(_stockLocation);

var _supplier = require('./resources/supplier.resource');

var _supplier2 = _interopRequireDefault(_supplier);

var _ward = require('./resources/ward.resource');

var _ward2 = _interopRequireDefault(_ward);

var _communication = require('./resources/communication.resource');

var _communication2 = _interopRequireDefault(_communication);

var _equipmentProblem = require('./resources/equipment-problem.resource');

var _equipmentProblem2 = _interopRequireDefault(_equipmentProblem);

var _organisationalDelay = require('./resources/organisational-delay.resource');

var _organisationalDelay2 = _interopRequireDefault(_organisationalDelay);

var _physiologicalDeterioration = require('./resources/physiological-deterioration.resource');

var _physiologicalDeterioration2 = _interopRequireDefault(_physiologicalDeterioration);

var _infusionSolution = require('./resources/infusion-solution.resource');

var _infusionSolution2 = _interopRequireDefault(_infusionSolution);

var _medicationRoute = require('./resources/medication-route.resource');

var _medicationRoute2 = _interopRequireDefault(_medicationRoute);

var _infectionStatus = require('./resources/infection-status.resource');

var _infectionStatus2 = _interopRequireDefault(_infectionStatus);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _module = _angular2.default.module('gnaas.core.categories', []).service('AbortReasonResource', _abortReason2.default).service('AmbulanceServiceResource', _ambulanceService2.default).service('BodyPartResource', _bodyPart2.default).service('CalendarEventTypeResource', _calendarEventType2.default).service('ConsumableTypeResource', _consumableType2.default).service('DeploymentReasonResource', _deploymentReason2.default).service('DeploymentStatusResource', _deploymentStatus2.default).service('GradeResource', _grade2.default).service('HospitalSpecialtyResource', _hospitalSpecialty2.default).service('IllnessResource', _illness2.default).service('InjuryResource', _injury2.default).service('InterventionResource', _intervention2.default).service('LocationResource', _location2.default).service('LocationTypeResource', _locationType2.default).service('OfflineReasonResource', _offileReason2.default).service('PatientTypeResource', _patientType2.default).service('ProfessionResource', _profession2.default).service('RefusalReasonResource', _refusalReason2.default).service('RiskAssessmentOutcomeResource', _riskAssessmentOutcome2.default).service('ServiceAreaResource', _serviceArea2.default).service('StockLocationResource', _stockLocation2.default).service('SupplierResource', _supplier2.default).service('WardResource', _ward2.default).service('CommunicationResource', _communication2.default).service('EquipmentProblemResource', _equipmentProblem2.default).service('OrganisationalDelayResource', _organisationalDelay2.default).service('PhysiologicalDeteriorationResource', _physiologicalDeterioration2.default).service('InfusionSolutionResource', _infusionSolution2.default).service('MedicationRouteResource', _medicationRoute2.default).service('InfectionStatusResource', _infectionStatus2.default);

exports.default = _module.name;

},{"./resources/abort-reason.resource":83,"./resources/ambulance-service.resource":84,"./resources/body-part.resource":85,"./resources/calendar-event-type.resource":86,"./resources/communication.resource":87,"./resources/consumable-type.resource":88,"./resources/deployment-reason.resource":89,"./resources/deployment-status.resource":90,"./resources/equipment-problem.resource":91,"./resources/grade.resource":92,"./resources/hospital-specialty.resource":93,"./resources/illness.resource":94,"./resources/infection-status.resource":95,"./resources/infusion-solution.resource":96,"./resources/injury.resource":97,"./resources/intervention.resource":98,"./resources/location-type.resource":99,"./resources/location.resource":100,"./resources/medication-route.resource":101,"./resources/offile-reason.resource":102,"./resources/organisational-delay.resource":103,"./resources/patient-type.resource":104,"./resources/physiological-deterioration.resource":105,"./resources/profession.resource":106,"./resources/refusal-reason.resource":107,"./resources/risk-assessment-outcome.resource":108,"./resources/service-area.resource":109,"./resources/stock-location.resource":110,"./resources/supplier.resource":111,"./resources/ward.resource":112,"angular":"angular"}],83:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var AbortReasonResource = function (_RestResource) {
  _inherits(AbortReasonResource, _RestResource);

  function AbortReasonResource() {
    _classCallCheck(this, AbortReasonResource);

    return _possibleConstructorReturn(this, (AbortReasonResource.__proto__ || Object.getPrototypeOf(AbortReasonResource)).apply(this, arguments));
  }

  _createClass(AbortReasonResource, [{
    key: 'init',
    value: function init() {
      _get(AbortReasonResource.prototype.__proto__ || Object.getPrototypeOf(AbortReasonResource.prototype), 'init', this).call(this);
      this.resourcePath = 'categories/abort-reasons';
    }
  }]);

  return AbortReasonResource;
}(_restResource2.default);

exports.default = AbortReasonResource;

},{"common/resource/services/restResource":60}],84:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parse = exports.format = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _format(item) {
  var formatted = {
    id: item.id,
    name: item.name,
    has_reference_number: !!item.has_reference_number
  };

  return formatted;
}

exports.format = _format;
function _parse(item) {
  var parsed = {
    id: item.id ? item.id : null,
    name: item.name,
    has_reference_number: item.has_reference_number ? 1 : 0
  };

  return parsed;
}

exports.parse = _parse;

var AmbulanceServiceResource = function (_RestResource) {
  _inherits(AmbulanceServiceResource, _RestResource);

  function AmbulanceServiceResource() {
    _classCallCheck(this, AmbulanceServiceResource);

    return _possibleConstructorReturn(this, (AmbulanceServiceResource.__proto__ || Object.getPrototypeOf(AmbulanceServiceResource)).apply(this, arguments));
  }

  _createClass(AmbulanceServiceResource, [{
    key: 'init',
    value: function init() {
      _get(AmbulanceServiceResource.prototype.__proto__ || Object.getPrototypeOf(AmbulanceServiceResource.prototype), 'init', this).call(this);
      this.resourcePath = 'categories/ambulance-services';
    }
  }, {
    key: 'format',
    value: function format(item) {
      return _format(item);
    }
  }, {
    key: 'parse',
    value: function parse(item) {
      return _parse(item);
    }
  }]);

  return AmbulanceServiceResource;
}(_restResource2.default);

exports.default = AmbulanceServiceResource;

},{"common/resource/services/restResource":60}],85:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parse = exports.format = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

var _injury = require('core/categories/resources/injury.resource');

var _illness = require('core/categories/resources/illness.resource');

var _intervention = require('core/categories/resources/intervention.resource');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _format(item) {
  var formatted = {
    id: item.id,
    name: item.name,
    marker: {
      x: item.marker_x,
      y: item.marker_y
    }
  };

  if (item.injuries) {
    formatted.injuries = item.injuries.data.map(function (item) {
      return (0, _injury.format)(item);
    });
  }

  if (item.illnesses) {
    formatted.illnesses = item.illnesses.data.map(function (item) {
      return (0, _illness.format)(item);
    });
  }

  if (item.interventions) {
    formatted.interventions = item.interventions.data.map(function (item) {
      return (0, _intervention.format)(item);
    });
  }

  return formatted;
}

exports.format = _format;
function _parse(item) {
  var parsed = {
    id: item.id ? item.id : null,
    name: item.name,
    marker_x: item.marker.x,
    marker_y: item.marker.y
  };

  if (item.injuries) {
    parsed.injuries = item.injuries.map(function (item) {
      return item.id;
    });
  }

  if (item.illnesses) {
    parsed.illnesses = item.illnesses.map(function (item) {
      return item.id;
    });
  }

  if (item.interventions) {
    parsed.interventions = item.interventions.map(function (item) {
      return item.id;
    });
  }

  return parsed;
}

exports.parse = _parse;

var BodyPartResource = function (_RestResource) {
  _inherits(BodyPartResource, _RestResource);

  function BodyPartResource() {
    _classCallCheck(this, BodyPartResource);

    return _possibleConstructorReturn(this, (BodyPartResource.__proto__ || Object.getPrototypeOf(BodyPartResource)).apply(this, arguments));
  }

  _createClass(BodyPartResource, [{
    key: 'init',
    value: function init() {
      _get(BodyPartResource.prototype.__proto__ || Object.getPrototypeOf(BodyPartResource.prototype), 'init', this).call(this);
      this.resourcePath = 'categories/bodyparts';
    }
  }, {
    key: 'format',
    value: function format(item) {
      return _format(item);
    }
  }, {
    key: 'parse',
    value: function parse(item) {
      return _parse(item);
    }
  }]);

  return BodyPartResource;
}(_restResource2.default);

exports.default = BodyPartResource;

},{"common/resource/services/restResource":60,"core/categories/resources/illness.resource":94,"core/categories/resources/injury.resource":97,"core/categories/resources/intervention.resource":98}],86:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var CalendarEventTypeResource = function (_RestResource) {
  _inherits(CalendarEventTypeResource, _RestResource);

  function CalendarEventTypeResource() {
    _classCallCheck(this, CalendarEventTypeResource);

    return _possibleConstructorReturn(this, (CalendarEventTypeResource.__proto__ || Object.getPrototypeOf(CalendarEventTypeResource)).apply(this, arguments));
  }

  _createClass(CalendarEventTypeResource, [{
    key: 'init',
    value: function init() {
      _get(CalendarEventTypeResource.prototype.__proto__ || Object.getPrototypeOf(CalendarEventTypeResource.prototype), 'init', this).call(this);
      this.resourcePath = 'categories/calendar-event-types';
    }
  }]);

  return CalendarEventTypeResource;
}(_restResource2.default);

exports.default = CalendarEventTypeResource;
;

},{"common/resource/services/restResource":60}],87:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var CommunicationResource = function (_RestResource) {
  _inherits(CommunicationResource, _RestResource);

  function CommunicationResource() {
    _classCallCheck(this, CommunicationResource);

    return _possibleConstructorReturn(this, (CommunicationResource.__proto__ || Object.getPrototypeOf(CommunicationResource)).apply(this, arguments));
  }

  _createClass(CommunicationResource, [{
    key: 'init',
    value: function init() {
      _get(CommunicationResource.prototype.__proto__ || Object.getPrototypeOf(CommunicationResource.prototype), 'init', this).call(this);
      this.resourcePath = 'categories/communications';
    }
  }]);

  return CommunicationResource;
}(_restResource2.default);

exports.default = CommunicationResource;

},{"common/resource/services/restResource":60}],88:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parse = exports.format = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

var _consumable = require('core/consumables/resources/consumable.resource');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _format(item) {
  var i = {
    id: item.id,
    name: item.name
  };

  if (item.consumables) {
    i.consumables = item.consumables.data.map(_consumable.format);
  }

  return i;
}

exports.format = _format;
function _parse(item) {
  return {
    id: item.id,
    name: item.name
  };
}

exports.parse = _parse;

var ConsumableTypeResource = function (_RestResource) {
  _inherits(ConsumableTypeResource, _RestResource);

  function ConsumableTypeResource() {
    _classCallCheck(this, ConsumableTypeResource);

    return _possibleConstructorReturn(this, (ConsumableTypeResource.__proto__ || Object.getPrototypeOf(ConsumableTypeResource)).apply(this, arguments));
  }

  _createClass(ConsumableTypeResource, [{
    key: 'init',
    value: function init() {
      _get(ConsumableTypeResource.prototype.__proto__ || Object.getPrototypeOf(ConsumableTypeResource.prototype), 'init', this).call(this);
      this.resourcePath = 'categories/consumable-types';
    }
  }, {
    key: 'format',
    value: function format(item) {
      return _format(item);
    }
  }, {
    key: 'parse',
    value: function parse(item) {
      return _parse(item);
    }
  }]);

  return ConsumableTypeResource;
}(_restResource2.default);

exports.default = ConsumableTypeResource;
;

},{"common/resource/services/restResource":60,"core/consumables/resources/consumable.resource":116}],89:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var DeploymentReasonResource = function (_RestResource) {
  _inherits(DeploymentReasonResource, _RestResource);

  function DeploymentReasonResource() {
    _classCallCheck(this, DeploymentReasonResource);

    return _possibleConstructorReturn(this, (DeploymentReasonResource.__proto__ || Object.getPrototypeOf(DeploymentReasonResource)).apply(this, arguments));
  }

  _createClass(DeploymentReasonResource, [{
    key: 'init',
    value: function init() {
      _get(DeploymentReasonResource.prototype.__proto__ || Object.getPrototypeOf(DeploymentReasonResource.prototype), 'init', this).call(this);
      this.resourcePath = 'categories/deployment-reasons';
    }
  }]);

  return DeploymentReasonResource;
}(_restResource2.default);

exports.default = DeploymentReasonResource;

},{"common/resource/services/restResource":60}],90:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.format = exports.parse = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _parse(item) {
  var parsed = {
    id: item.id,
    name: item.name,
    has_destination: item.has_destination ? 1 : 0,
    is_diverted: item.is_diverted ? 1 : 0,
    is_final_status: item.is_final_status ? 1 : 0,
    is_mobile: item.is_mobile ? 1 : 0,
    is_at_hospital: item.is_at_hospital ? 1 : 0,
    is_on_scene: item.is_on_scene ? 1 : 0,
    is_stand_down: item.is_stand_down ? 1 : 0,
    is_transporting_patient: item.is_transporting_patient ? 1 : 0
  };

  return parsed;
}

exports.parse = _parse;
function _format(item) {
  var formatted = {
    id: item.id,
    name: item.name,
    has_destination: !!item.has_destination,
    is_diverted: !!item.is_diverted,
    is_final_status: !!item.is_final_status,
    is_mobile: !!item.is_mobile,
    is_at_hospital: !!item.is_at_hospital,
    is_on_scene: !!item.is_on_scene,
    is_stand_down: !!item.is_stand_down,
    is_transporting_patient: !!item.is_transporting_patient
  };

  return formatted;
}

exports.format = _format;

var DeploymentStatusResource = function (_RestResource) {
  _inherits(DeploymentStatusResource, _RestResource);

  function DeploymentStatusResource() {
    _classCallCheck(this, DeploymentStatusResource);

    return _possibleConstructorReturn(this, (DeploymentStatusResource.__proto__ || Object.getPrototypeOf(DeploymentStatusResource)).apply(this, arguments));
  }

  _createClass(DeploymentStatusResource, [{
    key: 'init',
    value: function init() {
      _get(DeploymentStatusResource.prototype.__proto__ || Object.getPrototypeOf(DeploymentStatusResource.prototype), 'init', this).call(this);
      this.resourcePath = 'categories/deployment-statuses';
    }
  }, {
    key: 'parse',
    value: function parse(item) {
      return _parse(item);
    }
  }, {
    key: 'format',
    value: function format(item) {
      return _format(item);
    }
  }]);

  return DeploymentStatusResource;
}(_restResource2.default);

exports.default = DeploymentStatusResource;

},{"common/resource/services/restResource":60}],91:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var EquipmentProblemResource = function (_RestResource) {
  _inherits(EquipmentProblemResource, _RestResource);

  function EquipmentProblemResource() {
    _classCallCheck(this, EquipmentProblemResource);

    return _possibleConstructorReturn(this, (EquipmentProblemResource.__proto__ || Object.getPrototypeOf(EquipmentProblemResource)).apply(this, arguments));
  }

  _createClass(EquipmentProblemResource, [{
    key: 'init',
    value: function init() {
      _get(EquipmentProblemResource.prototype.__proto__ || Object.getPrototypeOf(EquipmentProblemResource.prototype), 'init', this).call(this);
      this.resourcePath = 'categories/equipment-problems';
    }
  }]);

  return EquipmentProblemResource;
}(_restResource2.default);

exports.default = EquipmentProblemResource;

},{"common/resource/services/restResource":60}],92:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var GradeResource = function (_RestResource) {
  _inherits(GradeResource, _RestResource);

  function GradeResource() {
    _classCallCheck(this, GradeResource);

    return _possibleConstructorReturn(this, (GradeResource.__proto__ || Object.getPrototypeOf(GradeResource)).apply(this, arguments));
  }

  _createClass(GradeResource, [{
    key: 'init',
    value: function init() {
      _get(GradeResource.prototype.__proto__ || Object.getPrototypeOf(GradeResource.prototype), 'init', this).call(this);
      this.resourcePath = 'categories/grades';
    }
  }]);

  return GradeResource;
}(_restResource2.default);

exports.default = GradeResource;

},{"common/resource/services/restResource":60}],93:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var HospitalSpecialtyResource = function (_RestResource) {
  _inherits(HospitalSpecialtyResource, _RestResource);

  function HospitalSpecialtyResource() {
    _classCallCheck(this, HospitalSpecialtyResource);

    return _possibleConstructorReturn(this, (HospitalSpecialtyResource.__proto__ || Object.getPrototypeOf(HospitalSpecialtyResource)).apply(this, arguments));
  }

  _createClass(HospitalSpecialtyResource, [{
    key: 'init',
    value: function init() {
      _get(HospitalSpecialtyResource.prototype.__proto__ || Object.getPrototypeOf(HospitalSpecialtyResource.prototype), 'init', this).call(this);
      this.resourcePath = 'categories/hospital-specialties';
    }
  }]);

  return HospitalSpecialtyResource;
}(_restResource2.default);

exports.default = HospitalSpecialtyResource;

},{"common/resource/services/restResource":60}],94:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parse = exports.format = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _format(item) {
  var i = {
    id: item.id,
    name: item.name
  };
  if (item.illnessTypes) {
    i.types = item.illnessTypes.data;
  }
  return i;
}

exports.format = _format;
function _parse(item) {
  var i = {
    id: item.id,
    name: item.name
  };
  if (item.types) i.types = item.types;
  return i;
}

exports.parse = _parse;

var IllnessResource = function (_RestResource) {
  _inherits(IllnessResource, _RestResource);

  function IllnessResource() {
    _classCallCheck(this, IllnessResource);

    return _possibleConstructorReturn(this, (IllnessResource.__proto__ || Object.getPrototypeOf(IllnessResource)).apply(this, arguments));
  }

  _createClass(IllnessResource, [{
    key: 'init',
    value: function init() {
      _get(IllnessResource.prototype.__proto__ || Object.getPrototypeOf(IllnessResource.prototype), 'init', this).call(this);
      this.resourcePath = 'categories/illnesses';
    }
  }, {
    key: 'format',
    value: function format(item) {
      return _format(item);
    }
  }, {
    key: 'parse',
    value: function parse(item) {
      return _parse(item);
    }
  }]);

  return IllnessResource;
}(_restResource2.default);

exports.default = IllnessResource;
;

},{"common/resource/services/restResource":60}],95:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parse = exports.format = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _format = function _format(item) {
  return {
    id: item.id,
    name: item.name
  };
};

exports.format = _format;
var _parse = function _parse(item) {
  return {
    id: item.id,
    name: item.name
  };
};

exports.parse = _parse;

var InfectionStatusResource = function (_RestResource) {
  _inherits(InfectionStatusResource, _RestResource);

  function InfectionStatusResource() {
    _classCallCheck(this, InfectionStatusResource);

    return _possibleConstructorReturn(this, (InfectionStatusResource.__proto__ || Object.getPrototypeOf(InfectionStatusResource)).apply(this, arguments));
  }

  _createClass(InfectionStatusResource, [{
    key: 'init',
    value: function init() {
      _get(InfectionStatusResource.prototype.__proto__ || Object.getPrototypeOf(InfectionStatusResource.prototype), 'init', this).call(this);
      this.resourcePath = 'categories/infection-statuses';
    }
  }, {
    key: 'format',
    value: function format(item) {
      return _format(item);
    }
  }, {
    key: 'parse',
    value: function parse(item) {
      return _parse(item);
    }
  }]);

  return InfectionStatusResource;
}(_restResource2.default);

exports.default = InfectionStatusResource;
;

},{"common/resource/services/restResource":60}],96:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var InfusionSolutionResource = function (_RestResource) {
  _inherits(InfusionSolutionResource, _RestResource);

  function InfusionSolutionResource() {
    _classCallCheck(this, InfusionSolutionResource);

    return _possibleConstructorReturn(this, (InfusionSolutionResource.__proto__ || Object.getPrototypeOf(InfusionSolutionResource)).apply(this, arguments));
  }

  _createClass(InfusionSolutionResource, [{
    key: 'init',
    value: function init() {
      _get(InfusionSolutionResource.prototype.__proto__ || Object.getPrototypeOf(InfusionSolutionResource.prototype), 'init', this).call(this);
      this.resourcePath = 'categories/infusion-solutions';
    }
  }]);

  return InfusionSolutionResource;
}(_restResource2.default);

exports.default = InfusionSolutionResource;

},{"common/resource/services/restResource":60}],97:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parse = exports.format = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _format(item) {
  var i = {
    id: item.id,
    name: item.name
  };
  if (item.injuryTypes) {
    i.types = item.injuryTypes.data;
  }
  return i;
}

exports.format = _format;
function _parse(item) {
  var i = {
    id: item.id,
    name: item.name
  };
  if (item.types) i.types = item.types;
  return i;
}

exports.parse = _parse;

var InjuryResource = function (_RestResource) {
  _inherits(InjuryResource, _RestResource);

  function InjuryResource() {
    _classCallCheck(this, InjuryResource);

    return _possibleConstructorReturn(this, (InjuryResource.__proto__ || Object.getPrototypeOf(InjuryResource)).apply(this, arguments));
  }

  _createClass(InjuryResource, [{
    key: 'init',
    value: function init() {
      _get(InjuryResource.prototype.__proto__ || Object.getPrototypeOf(InjuryResource.prototype), 'init', this).call(this);
      this.resourcePath = 'categories/injuries';
    }
  }, {
    key: 'format',
    value: function format(item) {
      return _format(item);
    }
  }, {
    key: 'parse',
    value: function parse(item) {
      return _parse(item);
    }
  }]);

  return InjuryResource;
}(_restResource2.default);

exports.default = InjuryResource;
;

},{"common/resource/services/restResource":60}],98:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.format = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _format(item) {
  var formated = {
    id: item.id,
    name: item.name
  };

  if (item.attributes) {
    formated.attributes = item.attributes.data.map(function (attribute) {
      var formatedAttribute = {
        id: attribute.id,
        name: attribute.name,
        type: attribute.type
      };

      if (attribute.values) {
        formatedAttribute.values = attribute.values.data.map(function (value) {
          return {
            id: value.id,
            value: value.value
          };
        });
      }

      return formatedAttribute;
    });
  }

  return formated;
}

exports.format = _format;

var InterventionResource = function (_RestResource) {
  _inherits(InterventionResource, _RestResource);

  function InterventionResource() {
    _classCallCheck(this, InterventionResource);

    return _possibleConstructorReturn(this, (InterventionResource.__proto__ || Object.getPrototypeOf(InterventionResource)).apply(this, arguments));
  }

  _createClass(InterventionResource, [{
    key: 'init',
    value: function init() {
      _get(InterventionResource.prototype.__proto__ || Object.getPrototypeOf(InterventionResource.prototype), 'init', this).call(this);
      this.resourcePath = 'categories/interventions';
    }
  }, {
    key: 'getAttributeTypes',
    value: function getAttributeTypes() {
      var url = this.apiUrl + 'categories/intervention-attribute-types';
      return this._http.get(url).then(function (response) {
        return response.data.data;
      });
    }
  }, {
    key: 'format',
    value: function format(item) {
      return _format(item);
    }
  }]);

  return InterventionResource;
}(_restResource2.default);

exports.default = InterventionResource;

},{"common/resource/services/restResource":60}],99:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var LocationTypeResource = function (_RestResource) {
  _inherits(LocationTypeResource, _RestResource);

  function LocationTypeResource() {
    _classCallCheck(this, LocationTypeResource);

    return _possibleConstructorReturn(this, (LocationTypeResource.__proto__ || Object.getPrototypeOf(LocationTypeResource)).apply(this, arguments));
  }

  _createClass(LocationTypeResource, [{
    key: 'init',
    value: function init() {
      _get(LocationTypeResource.prototype.__proto__ || Object.getPrototypeOf(LocationTypeResource.prototype), 'init', this).call(this);
      this.resourcePath = 'categories/location-types';
    }
  }]);

  return LocationTypeResource;
}(_restResource2.default);

exports.default = LocationTypeResource;

},{"common/resource/services/restResource":60}],100:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parse = exports.format = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _format(item) {
  var formatted = {
    id: item.id,
    name: item.name,
    lat: item.lat,
    lng: item.lng
  };

  if (item.locationType) {
    formatted.location_type = item.locationType.data;
  }

  return formatted;
}

exports.format = _format;
function _parse(item) {
  var parsed = {
    id: item.id || null,
    name: item.name,
    lat: item.lat,
    lng: item.lng
  };

  if (item.location_type) {
    parsed.location_type_id = item.location_type.id;
  }

  return parsed;
}

exports.parse = _parse;

var LocationResource = function (_RestResource) {
  _inherits(LocationResource, _RestResource);

  function LocationResource() {
    _classCallCheck(this, LocationResource);

    return _possibleConstructorReturn(this, (LocationResource.__proto__ || Object.getPrototypeOf(LocationResource)).apply(this, arguments));
  }

  _createClass(LocationResource, [{
    key: 'init',
    value: function init() {
      _get(LocationResource.prototype.__proto__ || Object.getPrototypeOf(LocationResource.prototype), 'init', this).call(this);
      this.resourcePath = 'locations';
    }
  }, {
    key: 'format',
    value: function format(item) {
      return _format(item);
    }
  }, {
    key: 'parse',
    value: function parse(item) {
      return _parse(item);
    }
  }]);

  return LocationResource;
}(_restResource2.default);

exports.default = LocationResource;

},{"common/resource/services/restResource":60}],101:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var MedicationRouteResource = function (_RestResource) {
  _inherits(MedicationRouteResource, _RestResource);

  function MedicationRouteResource() {
    _classCallCheck(this, MedicationRouteResource);

    return _possibleConstructorReturn(this, (MedicationRouteResource.__proto__ || Object.getPrototypeOf(MedicationRouteResource)).apply(this, arguments));
  }

  _createClass(MedicationRouteResource, [{
    key: 'init',
    value: function init() {
      _get(MedicationRouteResource.prototype.__proto__ || Object.getPrototypeOf(MedicationRouteResource.prototype), 'init', this).call(this);
      this.resourcePath = 'categories/medication-routes';
    }
  }]);

  return MedicationRouteResource;
}(_restResource2.default);

exports.default = MedicationRouteResource;

},{"common/resource/services/restResource":60}],102:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var OfflineReasonResource = function (_RestResource) {
  _inherits(OfflineReasonResource, _RestResource);

  function OfflineReasonResource() {
    _classCallCheck(this, OfflineReasonResource);

    return _possibleConstructorReturn(this, (OfflineReasonResource.__proto__ || Object.getPrototypeOf(OfflineReasonResource)).apply(this, arguments));
  }

  _createClass(OfflineReasonResource, [{
    key: 'init',
    value: function init() {
      _get(OfflineReasonResource.prototype.__proto__ || Object.getPrototypeOf(OfflineReasonResource.prototype), 'init', this).call(this);
      this.resourcePath = 'categories/vehicle-callsign-offline-reasons';
    }
  }]);

  return OfflineReasonResource;
}(_restResource2.default);

exports.default = OfflineReasonResource;

},{"common/resource/services/restResource":60}],103:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var OrganisationalDelayResource = function (_RestResource) {
  _inherits(OrganisationalDelayResource, _RestResource);

  function OrganisationalDelayResource() {
    _classCallCheck(this, OrganisationalDelayResource);

    return _possibleConstructorReturn(this, (OrganisationalDelayResource.__proto__ || Object.getPrototypeOf(OrganisationalDelayResource)).apply(this, arguments));
  }

  _createClass(OrganisationalDelayResource, [{
    key: 'init',
    value: function init() {
      _get(OrganisationalDelayResource.prototype.__proto__ || Object.getPrototypeOf(OrganisationalDelayResource.prototype), 'init', this).call(this);
      this.resourcePath = 'categories/organisational-delays';
    }
  }]);

  return OrganisationalDelayResource;
}(_restResource2.default);

exports.default = OrganisationalDelayResource;

},{"common/resource/services/restResource":60}],104:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var PatientTypeResource = function (_RestResource) {
  _inherits(PatientTypeResource, _RestResource);

  function PatientTypeResource() {
    _classCallCheck(this, PatientTypeResource);

    return _possibleConstructorReturn(this, (PatientTypeResource.__proto__ || Object.getPrototypeOf(PatientTypeResource)).apply(this, arguments));
  }

  _createClass(PatientTypeResource, [{
    key: 'init',
    value: function init() {
      _get(PatientTypeResource.prototype.__proto__ || Object.getPrototypeOf(PatientTypeResource.prototype), 'init', this).call(this);
      this.resourcePath = 'categories/patient-types';
    }
  }]);

  return PatientTypeResource;
}(_restResource2.default);

exports.default = PatientTypeResource;
;

},{"common/resource/services/restResource":60}],105:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var PhysiologicalDeteriorationResource = function (_RestResource) {
  _inherits(PhysiologicalDeteriorationResource, _RestResource);

  function PhysiologicalDeteriorationResource() {
    _classCallCheck(this, PhysiologicalDeteriorationResource);

    return _possibleConstructorReturn(this, (PhysiologicalDeteriorationResource.__proto__ || Object.getPrototypeOf(PhysiologicalDeteriorationResource)).apply(this, arguments));
  }

  _createClass(PhysiologicalDeteriorationResource, [{
    key: 'init',
    value: function init() {
      _get(PhysiologicalDeteriorationResource.prototype.__proto__ || Object.getPrototypeOf(PhysiologicalDeteriorationResource.prototype), 'init', this).call(this);
      this.resourcePath = 'categories/physiological-deteriorations';
    }
  }]);

  return PhysiologicalDeteriorationResource;
}(_restResource2.default);

exports.default = PhysiologicalDeteriorationResource;

},{"common/resource/services/restResource":60}],106:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ProfessionResource = function (_RestResource) {
  _inherits(ProfessionResource, _RestResource);

  function ProfessionResource() {
    _classCallCheck(this, ProfessionResource);

    return _possibleConstructorReturn(this, (ProfessionResource.__proto__ || Object.getPrototypeOf(ProfessionResource)).apply(this, arguments));
  }

  _createClass(ProfessionResource, [{
    key: 'init',
    value: function init() {
      _get(ProfessionResource.prototype.__proto__ || Object.getPrototypeOf(ProfessionResource.prototype), 'init', this).call(this);
      this.resourcePath = 'categories/professions';
    }
  }]);

  return ProfessionResource;
}(_restResource2.default);

exports.default = ProfessionResource;

},{"common/resource/services/restResource":60}],107:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var RefusalReasonResource = function (_RestResource) {
  _inherits(RefusalReasonResource, _RestResource);

  function RefusalReasonResource() {
    _classCallCheck(this, RefusalReasonResource);

    return _possibleConstructorReturn(this, (RefusalReasonResource.__proto__ || Object.getPrototypeOf(RefusalReasonResource)).apply(this, arguments));
  }

  _createClass(RefusalReasonResource, [{
    key: 'init',
    value: function init() {
      _get(RefusalReasonResource.prototype.__proto__ || Object.getPrototypeOf(RefusalReasonResource.prototype), 'init', this).call(this);
      this.resourcePath = 'categories/refusal-reasons';
    }
  }]);

  return RefusalReasonResource;
}(_restResource2.default);

exports.default = RefusalReasonResource;

},{"common/resource/services/restResource":60}],108:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var SupplierResource = function (_RestResource) {
  _inherits(SupplierResource, _RestResource);

  function SupplierResource() {
    _classCallCheck(this, SupplierResource);

    return _possibleConstructorReturn(this, (SupplierResource.__proto__ || Object.getPrototypeOf(SupplierResource)).apply(this, arguments));
  }

  _createClass(SupplierResource, [{
    key: 'init',
    value: function init() {
      _get(SupplierResource.prototype.__proto__ || Object.getPrototypeOf(SupplierResource.prototype), 'init', this).call(this);
      this.resourcePath = 'categories/risk-assessment-outcomes';
    }
  }]);

  return SupplierResource;
}(_restResource2.default);

exports.default = SupplierResource;

},{"common/resource/services/restResource":60}],109:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ServiceAreaResource = function (_RestResource) {
  _inherits(ServiceAreaResource, _RestResource);

  function ServiceAreaResource() {
    _classCallCheck(this, ServiceAreaResource);

    return _possibleConstructorReturn(this, (ServiceAreaResource.__proto__ || Object.getPrototypeOf(ServiceAreaResource)).apply(this, arguments));
  }

  _createClass(ServiceAreaResource, [{
    key: 'init',
    value: function init() {
      _get(ServiceAreaResource.prototype.__proto__ || Object.getPrototypeOf(ServiceAreaResource.prototype), 'init', this).call(this);
      this.resourcePath = 'categories/service-areas';
    }
  }]);

  return ServiceAreaResource;
}(_restResource2.default);

exports.default = ServiceAreaResource;

},{"common/resource/services/restResource":60}],110:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var StockLocationResource = function (_RestResource) {
  _inherits(StockLocationResource, _RestResource);

  function StockLocationResource() {
    _classCallCheck(this, StockLocationResource);

    return _possibleConstructorReturn(this, (StockLocationResource.__proto__ || Object.getPrototypeOf(StockLocationResource)).apply(this, arguments));
  }

  _createClass(StockLocationResource, [{
    key: 'init',
    value: function init() {
      _get(StockLocationResource.prototype.__proto__ || Object.getPrototypeOf(StockLocationResource.prototype), 'init', this).call(this);
      this.resourcePath = 'categories/stock-locations';
    }
  }]);

  return StockLocationResource;
}(_restResource2.default);

exports.default = StockLocationResource;

},{"common/resource/services/restResource":60}],111:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var SupplierResource = function (_RestResource) {
  _inherits(SupplierResource, _RestResource);

  function SupplierResource() {
    _classCallCheck(this, SupplierResource);

    return _possibleConstructorReturn(this, (SupplierResource.__proto__ || Object.getPrototypeOf(SupplierResource)).apply(this, arguments));
  }

  _createClass(SupplierResource, [{
    key: 'init',
    value: function init() {
      _get(SupplierResource.prototype.__proto__ || Object.getPrototypeOf(SupplierResource.prototype), 'init', this).call(this);
      this.resourcePath = 'suppliers';
    }
  }]);

  return SupplierResource;
}(_restResource2.default);

exports.default = SupplierResource;

},{"common/resource/services/restResource":60}],112:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var WardResource = function (_RestResource) {
  _inherits(WardResource, _RestResource);

  function WardResource() {
    _classCallCheck(this, WardResource);

    return _possibleConstructorReturn(this, (WardResource.__proto__ || Object.getPrototypeOf(WardResource)).apply(this, arguments));
  }

  _createClass(WardResource, [{
    key: 'init',
    value: function init() {
      _get(WardResource.prototype.__proto__ || Object.getPrototypeOf(WardResource.prototype), 'init', this).call(this);
      this.resourcePath = 'categories/wards';
    }
  }]);

  return WardResource;
}(_restResource2.default);

exports.default = WardResource;

},{"common/resource/services/restResource":60}],113:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _consumable = require('./resources/consumable.resource');

var _consumable2 = _interopRequireDefault(_consumable);

var _consumableBatch = require('./resources/consumable-batch.resource');

var _consumableBatch2 = _interopRequireDefault(_consumableBatch);

var _consumableStock = require('./resources/consumable-stock.resource');

var _consumableStock2 = _interopRequireDefault(_consumableStock);

var _createConsumableStockDialog = require('./services/create-consumable-stock-dialog/create-consumable-stock-dialog.service');

var _createConsumableStockDialog2 = _interopRequireDefault(_createConsumableStockDialog);

var _consumableOrderFormDialog = require('./services/consumable-order-form-dialog/consumable-order-form-dialog.service');

var _consumableOrderFormDialog2 = _interopRequireDefault(_consumableOrderFormDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _module = _angular2.default.module('gnaas.core.consumables', []).service('CreateConsumableStockDialog', _createConsumableStockDialog2.default).service('ConsumableOrderFormDialog', _consumableOrderFormDialog2.default).service('ConsumableResource', _consumable2.default).service('ConsumableBatchResource', _consumableBatch2.default).service('ConsumableStockResource', _consumableStock2.default);

exports.default = _module.name;

},{"./resources/consumable-batch.resource":114,"./resources/consumable-stock.resource":115,"./resources/consumable.resource":116,"./services/consumable-order-form-dialog/consumable-order-form-dialog.service":118,"./services/create-consumable-stock-dialog/create-consumable-stock-dialog.service":120,"angular":"angular"}],114:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parse = exports.format = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

var _module = require('common/resource/module.constants');

var _consumable = require('./consumable.resource');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _format(item) {
  if (item.consumable) {
    item.consumable = (0, _consumable.format)(item.consumable.data);
  }
  if (item.stockLocation) {
    item.stockLocation = item.stockLocation.data;
  }
  return item;
}

exports.format = _format;
function _parse(item) {
  return Object.assign({}, item, {
    expires_on: (0, _moment2.default)(item.expires_on).format(_module.DATE_FORMAT),
    received_on: (0, _moment2.default)(item.received_on).format(_module.DATE_TIME_FORMAT)
  });
}

exports.parse = _parse;

var ConsumableBatchResource = function (_RestResource) {
  _inherits(ConsumableBatchResource, _RestResource);

  function ConsumableBatchResource() {
    _classCallCheck(this, ConsumableBatchResource);

    return _possibleConstructorReturn(this, (ConsumableBatchResource.__proto__ || Object.getPrototypeOf(ConsumableBatchResource)).apply(this, arguments));
  }

  _createClass(ConsumableBatchResource, [{
    key: 'init',
    value: function init() {
      _get(ConsumableBatchResource.prototype.__proto__ || Object.getPrototypeOf(ConsumableBatchResource.prototype), 'init', this).call(this);
      this.resourcePath = 'consumable-batches';
    }
  }, {
    key: 'format',
    value: function format(item) {
      return _format(item);
    }
  }, {
    key: 'parse',
    value: function parse(item) {
      return _parse(item);
    }
  }]);

  return ConsumableBatchResource;
}(_restResource2.default);

exports.default = ConsumableBatchResource;

},{"./consumable.resource":116,"common/resource/module.constants":57,"common/resource/services/restResource":60,"moment":"moment"}],115:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

var _consumable = require('./consumable.resource');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var DrugStockResource = function (_RestResource) {
  _inherits(DrugStockResource, _RestResource);

  function DrugStockResource() {
    _classCallCheck(this, DrugStockResource);

    return _possibleConstructorReturn(this, (DrugStockResource.__proto__ || Object.getPrototypeOf(DrugStockResource)).apply(this, arguments));
  }

  _createClass(DrugStockResource, [{
    key: 'init',
    value: function init() {
      _get(DrugStockResource.prototype.__proto__ || Object.getPrototypeOf(DrugStockResource.prototype), 'init', this).call(this);
      this.resourcePath = 'consumble-stock';
    }
  }, {
    key: 'format',
    value: function format(item) {
      if (item.consumable) {
        item.consumable = (0, _consumable.format)(item.drug.data);
      }
      if (item.location) {
        item.location = item.location.data;
      }
      return item;
    }
  }, {
    key: 'generateOrderForms',
    value: function generateOrderForms(data) {
      var url = this.apiUrl + 'consumable-order-forms';

      return this._http({
        url: url,
        method: 'POST',
        data: data,
        responseType: 'arraybuffer'
      }).then(function (response) {
        return response.data;
      });
    }
  }]);

  return DrugStockResource;
}(_restResource2.default);

exports.default = DrugStockResource;

},{"./consumable.resource":116,"common/resource/services/restResource":60}],116:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parse = exports.format = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

var _equipmentLocation = require('core/equipment-locations/resources/equipment-location.resource');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _format(item) {
  var i = {
    id: item.id,
    name: item.name
  };

  if (item.type) {
    i.type = item.type.data;
  }

  if (item.stockLocationProperties) {
    i.stock_location_properties = item.stockLocationProperties.data.map(function (properties) {
      properties.supplier = properties.supplier.data;
      properties.stock_location = properties.stockLocation.data;
      delete properties.stockLocation;
      return properties;
    });
  }

  if (item.equipmentLocationCompartmentItems) {
    i.equipment_location_compartment_items = item.equipmentLocationCompartmentItems.data.map(_equipmentLocation.formatCompartmentItem);
  }

  if (item.productCodes) {
    i.product_codes = item.productCodes.data.map(function (pc) {
      if (pc.supplier) pc.supplier = pc.supplier.data;
      return pc;
    });
  }

  return i;
}

exports.format = _format;
function _parse(item) {
  var i = {
    id: item.id,
    name: item.name
  };

  if (item.type) {
    i.consumable_type_id = item.type.id;
  }

  if (item.stock_location_properties) {
    i.stock_location_properties = item.stock_location_properties.map(function (prop) {
      var copy = Object.assign({}, prop);
      copy.stock_location_id = copy.stock_location.id;
      copy.supplier_id = copy.supplier.id;
      delete copy.stock_location;
      delete copy.supplier;
      return copy;
    });
  }

  if (item.product_codes) {
    i.product_codes = item.product_codes.map(function (pc) {
      return {
        id: pc.id,
        product_code: pc.product_code,
        supplier_id: pc.supplier.id
      };
    });
  }

  return i;
}

exports.parse = _parse;

var ConsumableResource = function (_RestResource) {
  _inherits(ConsumableResource, _RestResource);

  function ConsumableResource() {
    _classCallCheck(this, ConsumableResource);

    return _possibleConstructorReturn(this, (ConsumableResource.__proto__ || Object.getPrototypeOf(ConsumableResource)).apply(this, arguments));
  }

  _createClass(ConsumableResource, [{
    key: 'init',
    value: function init() {
      _get(ConsumableResource.prototype.__proto__ || Object.getPrototypeOf(ConsumableResource.prototype), 'init', this).call(this);
      this.resourcePath = 'consumables';
    }
  }, {
    key: 'format',
    value: function format(item) {
      return _format(item);
    }
  }, {
    key: 'parse',
    value: function parse(item) {
      return _parse(item);
    }
  }]);

  return ConsumableResource;
}(_restResource2.default);

exports.default = ConsumableResource;

},{"common/resource/services/restResource":60,"core/equipment-locations/resources/equipment-location.resource":156}],117:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _resourceDialogController = require('common/resource/controllers/resourceDialogController');

var _resourceDialogController2 = _interopRequireDefault(_resourceDialogController);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; } /* global Blob, URL */


var DrugOrderFormDialogDialogController = function (_ResourceDialogContro) {
  _inherits(DrugOrderFormDialogDialogController, _ResourceDialogContro);

  function DrugOrderFormDialogDialogController() {
    _classCallCheck(this, DrugOrderFormDialogDialogController);

    return _possibleConstructorReturn(this, (DrugOrderFormDialogDialogController.__proto__ || Object.getPrototypeOf(DrugOrderFormDialogDialogController)).apply(this, arguments));
  }

  _createClass(DrugOrderFormDialogDialogController, [{
    key: 'init',
    value: function init() {
      var _this2 = this;

      _get(DrugOrderFormDialogDialogController.prototype.__proto__ || Object.getPrototypeOf(DrugOrderFormDialogDialogController.prototype), 'init', this).call(this);
      this.StockLocationResource = this.$injector.get('StockLocationResource');
      this.ConsumableTypeResource = this.$injector.get('ConsumableTypeResource');
      this.ConsumableStockResource = this.$injector.get('ConsumableStockResource');

      this.StockLocationResource.index().then(function (items) {
        _this2.stockLocations = items;
      });
      this.ConsumableTypeResource.index({ include: 'consumables' }).then(function (items) {
        _this2.consumableTypes = items;
      });

      this.item = {
        stock_locations: [],
        consumables: []
      };
    }
  }, {
    key: 'submit',
    value: function submit(data) {
      var _this3 = this;

      if (this.form.$invalid || this.item.stock_locations.length === 0 || this.item.consumables.length === 0) {
        return;
      }

      this.ConsumableStockResource.generateOrderForms(this._parseData(data)).then(function (pdf) {
        var file = new Blob([pdf], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        _this3.Dialog.hide();
      });
    }
  }, {
    key: 'addItem',
    value: function addItem() {
      this.item.consumables = [].concat(_toConsumableArray(this.item.consumables), [{}]);
    }
  }, {
    key: 'removeItem',
    value: function removeItem(index) {
      this.item.consumables = [].concat(_toConsumableArray(this.item.consumables.slice(0, index)), _toConsumableArray(this.item.consumables.slice(index + 1)));
    }
  }, {
    key: '_parseData',
    value: function _parseData(data) {
      return {
        stock_location_ids: data.stock_locations.map(function (i) {
          return i.id;
        }),
        consumables: data.consumables.map(function (i) {
          return { id: i.consumable_id, quantity: i.quantity };
        }),
        comments: data.comments
      };
    }
  }]);

  return DrugOrderFormDialogDialogController;
}(_resourceDialogController2.default);

exports.default = DrugOrderFormDialogDialogController;

},{"common/resource/controllers/resourceDialogController":56}],118:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _resourceDialog = require('common/resource/services/resourceDialog');

var _resourceDialog2 = _interopRequireDefault(_resourceDialog);

var _consumableOrderFormDialog = require('./consumable-order-form-dialog.controller');

var _consumableOrderFormDialog2 = _interopRequireDefault(_consumableOrderFormDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ConsumableOrderFormDialog = function (_ResourceDialog) {
  _inherits(ConsumableOrderFormDialog, _ResourceDialog);

  function ConsumableOrderFormDialog() {
    _classCallCheck(this, ConsumableOrderFormDialog);

    return _possibleConstructorReturn(this, (ConsumableOrderFormDialog.__proto__ || Object.getPrototypeOf(ConsumableOrderFormDialog)).apply(this, arguments));
  }

  _createClass(ConsumableOrderFormDialog, [{
    key: 'init',
    value: function init() {
      _get(ConsumableOrderFormDialog.prototype.__proto__ || Object.getPrototypeOf(ConsumableOrderFormDialog.prototype), 'init', this).call(this);
      this.setController(_consumableOrderFormDialog2.default);
      var serviceName = 'consumable-order-form-dialog';
      this.setTemplateUrl('core/consumables/services/' + serviceName + '/' + serviceName + '.tpl.html');
    }
  }]);

  return ConsumableOrderFormDialog;
}(_resourceDialog2.default);

exports.default = ConsumableOrderFormDialog;

},{"./consumable-order-form-dialog.controller":117,"common/resource/services/resourceDialog":59}],119:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _resourceDialogController = require('common/resource/controllers/resourceDialogController');

var _resourceDialogController2 = _interopRequireDefault(_resourceDialogController);

var _nodeUuid = require('node-uuid');

var _nodeUuid2 = _interopRequireDefault(_nodeUuid);

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var CreateConsumableDialogController = function (_ResourceDialogContro) {
  _inherits(CreateConsumableDialogController, _ResourceDialogContro);

  function CreateConsumableDialogController() {
    _classCallCheck(this, CreateConsumableDialogController);

    return _possibleConstructorReturn(this, (CreateConsumableDialogController.__proto__ || Object.getPrototypeOf(CreateConsumableDialogController)).apply(this, arguments));
  }

  _createClass(CreateConsumableDialogController, [{
    key: 'init',
    value: function init() {
      var _this2 = this;

      _get(CreateConsumableDialogController.prototype.__proto__ || Object.getPrototypeOf(CreateConsumableDialogController.prototype), 'init', this).call(this);

      this.StockLocationResource = this.$injector.get('StockLocationResource');
      this.ConsumableTypeResource = this.$injector.get('ConsumableTypeResource');
      this.ConsumableBatchResource = this.$injector.get('ConsumableBatchResource');

      this.today = new Date();
      this.stockLocationId = null;
      this.timestamp = (0, _moment2.default)().startOf('minute').toDate();
      this.batches = [];

      this.StockLocationResource.index().then(function (items) {
        _this2.stockLocations = items;
      });
      this.ConsumableTypeResource.index({ include: 'consumables' }).then(function (items) {
        _this2.consumableTypes = items;
        _this2.consumableTypeMap = items.reduce(function (acc, cur) {
          return Object.assign(acc, _defineProperty({}, cur.id, cur.consumables));
        }, {});
      });
    }
  }, {
    key: 'newBatch',
    value: function newBatch() {
      this.batches.push({ id: _nodeUuid2.default.v4() });
    }
  }, {
    key: 'removeBatch',
    value: function removeBatch(id) {
      this.batches = this.batches.filter(function (item) {
        return item.id !== id;
      });
    }
  }, {
    key: 'create',
    value: function create(input) {
      var _this3 = this;

      this.ConsumableBatchResource.create(this._parseData(input)).then(function (data) {
        _this3.Dialog.hide(data);
      }).catch(function (err) {
        if (err.status === 409) {
          var e = err.data.errors;
          _this3.Dialog.alert(e.consumable_name + ' batch expiring on ' + e.expires_on + ' already exist.', 'Consumable batch already exists');
        }
      });
    }
  }, {
    key: '_parseData',
    value: function _parseData(data) {
      var _this4 = this;

      return data.map(function (item) {
        return Object.assign({}, item, {
          stock_location_id: _this4.stockLocationId,
          received_on: _this4.timestamp
        });
      });
    }
  }]);

  return CreateConsumableDialogController;
}(_resourceDialogController2.default);

exports.default = CreateConsumableDialogController;

},{"common/resource/controllers/resourceDialogController":56,"moment":"moment","node-uuid":"node-uuid"}],120:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _resourceDialog = require('common/resource/services/resourceDialog');

var _resourceDialog2 = _interopRequireDefault(_resourceDialog);

var _createConsumableStockDialog = require('./create-consumable-stock-dialog.controller');

var _createConsumableStockDialog2 = _interopRequireDefault(_createConsumableStockDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var CreateConsumableStockDialog = function (_ResourceDialog) {
  _inherits(CreateConsumableStockDialog, _ResourceDialog);

  function CreateConsumableStockDialog() {
    _classCallCheck(this, CreateConsumableStockDialog);

    return _possibleConstructorReturn(this, (CreateConsumableStockDialog.__proto__ || Object.getPrototypeOf(CreateConsumableStockDialog)).apply(this, arguments));
  }

  _createClass(CreateConsumableStockDialog, [{
    key: 'init',
    value: function init() {
      _get(CreateConsumableStockDialog.prototype.__proto__ || Object.getPrototypeOf(CreateConsumableStockDialog.prototype), 'init', this).call(this);
      this.setController(_createConsumableStockDialog2.default);
      var serviceName = 'create-consumable-stock-dialog';
      this.setTemplateUrl('core/consumables/services/' + serviceName + '/' + serviceName + '.tpl.html');
    }
  }]);

  return CreateConsumableStockDialog;
}(_resourceDialog2.default);

exports.default = CreateConsumableStockDialog;

},{"./create-consumable-stock-dialog.controller":119,"common/resource/services/resourceDialog":59}],121:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  bindings: {
    crew: '<',
    vehicleCallsign: '<'
  },
  controller: function controller() {},
  templateUrl: 'core/deployments/components/deployment-crew/deployment-crew.tpl.html'
};

},{}],122:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  bindings: {
    item: '<'
  },
  controller: function controller() {
    this.deploymentTime = null;
    if (this.item.deployment_statuses) {
      var mobileStatuses = this.item.deployment_statuses.filter(function (status) {
        return status.deployment_status.is_mobile;
      });
      if (mobileStatuses.length) {
        this.deploymentTime = (0, _moment2.default)(mobileStatuses[0].time).format('HH:mm');
      }
    }

    this.patientsTreated = 0;
    if (this.item.patient_report_forms) {
      this.patientsTreated = this.item.patient_report_forms.length;
    }
  },
  templateUrl: 'core/deployments/components/deployment-list-item/deployment-list-item.tpl.html'
};

},{"moment":"moment"}],123:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _deploymentCrew = require('./components/deployment-crew/deployment-crew.component');

var _deploymentCrew2 = _interopRequireDefault(_deploymentCrew);

var _deploymentDialog = require('./services/deployment-dialog/deployment-dialog.service');

var _deploymentDialog2 = _interopRequireDefault(_deploymentDialog);

var _deploymentListDialog = require('./services/deployment-list-dialog/deployment-list-dialog.service');

var _deploymentListDialog2 = _interopRequireDefault(_deploymentListDialog);

var _deploymentListItem = require('./components/deployment-list-item/deployment-list-item');

var _deploymentListItem2 = _interopRequireDefault(_deploymentListItem);

var _deployment = require('./resources/deployment.resource');

var _deployment2 = _interopRequireDefault(_deployment);

var _deploymentStatusUpdateDialog = require('./services/deployment-status-update-dialog/deployment-status-update-dialog.service');

var _deploymentStatusUpdateDialog2 = _interopRequireDefault(_deploymentStatusUpdateDialog);

var _deploymentStatusUpdate = require('./resources/deployment-status-update.resource');

var _deploymentStatusUpdate2 = _interopRequireDefault(_deploymentStatusUpdate);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _module = _angular2.default.module('gnaas.core.deployments', []).service('DeploymentDialog', _deploymentDialog2.default).service('DeploymentListDialog', _deploymentListDialog2.default).service('DeploymentResource', _deployment2.default).service('DeploymentStatusUpdateDialog', _deploymentStatusUpdateDialog2.default).service('DeploymentStatusUpdateResource', _deploymentStatusUpdate2.default).component('deploymentCrew', _deploymentCrew2.default).component('deploymentListItem', _deploymentListItem2.default);

exports.default = _module.name;

},{"./components/deployment-crew/deployment-crew.component":121,"./components/deployment-list-item/deployment-list-item":122,"./resources/deployment-status-update.resource":124,"./resources/deployment.resource":125,"./services/deployment-dialog/deployment-dialog.service":127,"./services/deployment-list-dialog/deployment-list-dialog.service":129,"./services/deployment-status-update-dialog/deployment-status-update-dialog.service":131,"angular":"angular"}],124:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.format = exports.parse = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _deploymentStatus = require('core/categories/resources/deployment-status.resource');

var _deployment = require('core/deployments/resources/deployment.resource');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _parse(item) {
  var parsed = {
    id: item.id,
    time: (0, _moment2.default)(item.time).format('YYYY-MM-DD HH:mm:ss'),
    destination_type: item.destination_type
  };

  if (item.deployment) {
    parsed.deployment_id = item.deployment.id;
  }

  if (item.deployment_status) {
    parsed.deployment_status_id = item.deployment_status.id;
  }

  if (item.destination) {
    parsed.destination_id = item.destination.id;
  }

  return parsed;
}

exports.parse = _parse;
function _format(item) {
  var formatted = {
    id: item.id,
    time: (0, _moment2.default)(item.time, 'YYYY-MM-DD HH:mm:ss'),
    destination_type: item.destination_type
  };

  if (item.deploymentStatus) {
    formatted.deployment_status = (0, _deploymentStatus.format)(item.deploymentStatus.data);
  }

  if (item.deployment) {
    formatted.deployment = (0, _deployment.format)(item.deployment.data);
  }

  if (item.destination) {
    formatted.destination = item.destination.data;
  }

  return formatted;
}

exports.format = _format;

var DeploymentStatusUpdateResource = function (_RestResource) {
  _inherits(DeploymentStatusUpdateResource, _RestResource);

  function DeploymentStatusUpdateResource() {
    _classCallCheck(this, DeploymentStatusUpdateResource);

    return _possibleConstructorReturn(this, (DeploymentStatusUpdateResource.__proto__ || Object.getPrototypeOf(DeploymentStatusUpdateResource)).apply(this, arguments));
  }

  _createClass(DeploymentStatusUpdateResource, [{
    key: 'init',
    value: function init() {
      _get(DeploymentStatusUpdateResource.prototype.__proto__ || Object.getPrototypeOf(DeploymentStatusUpdateResource.prototype), 'init', this).call(this);
      this.resourcePath = 'deployment-status-updates';
    }
  }, {
    key: 'parse',
    value: function parse(item) {
      return _parse(item);
    }
  }, {
    key: 'format',
    value: function format(item) {
      return _format(item);
    }
  }]);

  return DeploymentStatusUpdateResource;
}(_restResource2.default);

exports.default = DeploymentStatusUpdateResource;

},{"common/resource/services/restResource":60,"core/categories/resources/deployment-status.resource":90,"core/deployments/resources/deployment.resource":125,"moment":"moment"}],125:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parse = exports.format = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

var _vehicleCallsignResource = require('pages/admin/vehicleCallsigns/services/vehicleCallsignResource');

var _vehicleResource = require('pages/admin/vehicles/services/vehicleResource');

var _incident = require('core/incidents/resources/incident.resource');

var _crewMemberResource = require('pages/crewMembers/services/crewMemberResource');

var _deploymentStatusUpdate = require('core/deployments/resources/deployment-status-update.resource');

var _hospitalResource = require('pages/admin/hospitals/services/hospitalResource');

var _prfFormatter = require('core/prf/services/prf-formatter.service');

var _prfFormatter2 = _interopRequireDefault(_prfFormatter);

var _module = require('common/resource/module.constants');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _format(item) {
  var formatted = {
    id: item.id,
    status: item.status,
    date: item.date ? (0, _moment2.default)(item.date, _module.DATE_TIME_FORMAT) : null,
    comment: item.comment,
    relative_info_leaflet_given: item.relative_info_leaflet_given,
    relative_traveling_in_ambulance: !!item.relative_traveling_in_ambulance,
    accepting_consultant_name: item.accepting_consultant_name,
    accepting_nurse_name: item.accepting_nurse_name,
    accepting_unit_contact_no: item.accepting_unit_contact_no,
    risk_assessment: item.risk_assessment
  };

  if (item.vehicleCallsign) {
    formatted.vehicle_callsign = (0, _vehicleCallsignResource.format)(item.vehicleCallsign.data);
  }

  if (item.vehicle) {
    formatted.vehicle = (0, _vehicleResource.format)(item.vehicle.data);
  }

  if (item.incident) {
    formatted.incident = (0, _incident.format)(item.incident.data);
  }

  if (item.users) {
    formatted.crew_members = item.users.data.map(function (user) {
      return Object.assign({}, (0, _crewMemberResource.format)(user), { type: user.type });
    });
  }

  if (item.deploymentStatuses) {
    formatted.deployment_statuses = item.deploymentStatuses.data.map(_deploymentStatusUpdate.format);
  }

  if (item.patientReportForms) {
    formatted.patient_report_forms = item.patientReportForms.data.map(_prfFormatter2.default);
  }

  if (item.acceptingHospital) {
    formatted.accepting_hospital = (0, _hospitalResource.format)(item.acceptingHospital.data);
  }

  if (item.acceptingConsultantSpecialty) {
    formatted.accepting_consultant_specialty = item.acceptingConsultantSpecialty.data;
  }

  if (item.riskAssessmentOutcome) {
    formatted.risk_assessment_outcome = (0, _hospitalResource.format)(item.riskAssessmentOutcome.data);
  }

  if (item.departureChecklistCompletedBy) {
    formatted.departure_checklist_completed_by = (0, _crewMemberResource.format)(item.departureChecklistCompletedBy.data);
  }

  if (item.departureChecklistApprovedBy) {
    formatted.departure_checklist_approved_by = (0, _crewMemberResource.format)(item.departureChecklistApprovedBy.data);
  }

  if (item.refCentreDepartureChecklistCompletedBy) {
    formatted.ref_centre_departure_checklist_completed_by = (0, _crewMemberResource.format)(item.refCentreDepartureChecklistCompletedBy.data);
  }

  if (item.refCentreDepartureChecklistApprovedBy) {
    formatted.ref_centre_departure_checklist_approved_by = (0, _crewMemberResource.format)(item.refCentreDepartureChecklistApprovedBy.data);
  }

  return formatted;
}

exports.format = _format;
function _parse(item) {
  var parsed = {
    id: item.id,
    status: item.status,
    date: item.date ? (0, _moment2.default)(item.date).format(_module.DATE_TIME_FORMAT) : null,
    comment: item.comment || null,
    relative_info_leaflet_given: item.relative_info_leaflet_given,
    relative_traveling_in_ambulance: item.relative_traveling_in_ambulance ? 1 : 0,
    accepting_consultant_name: item.accepting_consultant_name,
    accepting_nurse_name: item.accepting_nurse_name || null,
    accepting_unit_contact_no: item.accepting_unit_contact_no,
    risk_assessment: item.risk_assessment || null
  };

  if (item.incident) {
    parsed.incident_id = item.incident.id;
  }

  if (item.vehicle_callsign) {
    parsed.vehicle_callsign_id = item.vehicle_callsign.id;
  }

  if (item.accepting_hospital) {
    parsed.accepting_hospital_id = item.accepting_hospital.id;
  }

  if (item.accepting_consultant_specialty) {
    parsed.accepting_consultant_specialty_id = item.accepting_consultant_specialty.id;
  }

  if (item.risk_assessment_outcome) {
    parsed.risk_assessment_outcome_id = item.risk_assessment_outcome.id;
  }

  if (item.crew_members) {
    parsed.users = item.crew_members.map(function (i) {
      return { id: i.id, type: i.type };
    });
  }

  if (item.departure_checklist_completed_by) {
    parsed.departure_checklist_completed_by = item.departure_checklist_completed_by.id;
  }

  if (item.departure_checklist_approved_by) {
    parsed.departure_checklist_approved_by = item.departure_checklist_approved_by.id;
  }

  if (item.ref_centre_departure_checklist_completed_by) {
    parsed.ref_centre_departure_checklist_completed_by = item.ref_centre_departure_checklist_completed_by.id;
  }

  if (item.ref_centre_departure_checklist_approved_by) {
    parsed.ref_centre_departure_checklist_approved_by = item.ref_centre_departure_checklist_approved_by.id;
  }

  return parsed;
}

exports.parse = _parse;

var DeploymentResource = function (_RestResource) {
  _inherits(DeploymentResource, _RestResource);

  function DeploymentResource() {
    _classCallCheck(this, DeploymentResource);

    return _possibleConstructorReturn(this, (DeploymentResource.__proto__ || Object.getPrototypeOf(DeploymentResource)).apply(this, arguments));
  }

  _createClass(DeploymentResource, [{
    key: 'init',
    value: function init() {
      _get(DeploymentResource.prototype.__proto__ || Object.getPrototypeOf(DeploymentResource.prototype), 'init', this).call(this);
      this.resourcePath = 'deployments';
    }
  }, {
    key: 'updateStatus',
    value: function updateStatus(id, deploymentStatus) {
      this._http.put('' + this.apiUrl + this.resourcePath + '/' + id, {
        deployment_status_id: deploymentStatus.id,
        time: (0, _moment2.default)(deploymentStatus.time).format('YYYY-MM-DD HH:mm')
      }).then(function () {
        return deploymentStatus;
      }).catch(console.log.bind(console));
    }
  }, {
    key: 'format',
    value: function format(item) {
      return _format(item);
    }
  }, {
    key: 'parse',
    value: function parse(item) {
      return _parse(item);
    }
  }]);

  return DeploymentResource;
}(_restResource2.default);

exports.default = DeploymentResource;

},{"common/resource/module.constants":57,"common/resource/services/restResource":60,"core/deployments/resources/deployment-status-update.resource":124,"core/incidents/resources/incident.resource":165,"core/prf/services/prf-formatter.service":228,"moment":"moment","pages/admin/hospitals/services/hospitalResource":324,"pages/admin/vehicleCallsigns/services/vehicleCallsignResource":332,"pages/admin/vehicles/services/vehicleResource":338,"pages/crewMembers/services/crewMemberResource":362}],126:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TYPES = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _nodeUuid = require('node-uuid');

var _nodeUuid2 = _interopRequireDefault(_nodeUuid);

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _resourceDialogController = require('common/resource/controllers/resourceDialogController');

var _resourceDialogController2 = _interopRequireDefault(_resourceDialogController);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var PROMPT_SELECT_TPL = '<md-dialog class="o-dialog o-dialog--small">\n    <form novalidate name="$ctrl.form" ng-submit="$ctrl.submit()">\n        <md-dialog-content>\n            <div class="md-dialog-content">\n                Multiple shift crews available for {{ $ctrl.callsignName }}.\n                <br>\n                Please select one, or press \'Cancel\' to keep current crew.\n            </div>\n            <form-select fe-label="Shifts" fe-name="selected" fe-required="true" fe-model="$ctrl.selected"\n                fe-options="$ctrl.options" fe-errors="$ctrl.form.selected.$error"></form-select>\n        </md-dialog-content>\n        <md-dialog-actions>\n            <md-button class="md-raised" ng-click="$ctrl.close()">Cancel</md-button>\n            <md-button class="md-raised md-primary" type="submit">Submit</md-button>\n        </md-dialog-actions>\n    </form>\n</md-dialog>';

var TYPES = exports.TYPES = [{ name: 'Duty Consultant', value: 'duty_consultant', order: 1 }, { name: 'Duty Consultant On Base', value: 'duty_consultant_on_base', order: 2 }, { name: 'Transfer Doctor', value: 'transfer_doctor', order: 3 }, { name: 'Transfer Practitioner', value: 'transfer_practitioner', order: 4 }, { name: 'Transfer Doctor In Training', value: 'transfer_doctor_in_training', order: 5 }, { name: 'Transfer Practitioner In Training', value: 'transfer_practitioner_in_training', order: 6 }, { name: 'Advanced Transfer Practitioner', value: 'advanced_transfer_practitioner', order: 7 }, { name: 'Advanced Transfer Practitioner In Training', value: 'advanced_transfer_practitioner_in_training', order: 8 }, { name: 'Observer', value: 'observer', order: 9 }];

var STATUSES = [{ name: 'Referral', value: 'referral' }, { name: 'Transfer', value: 'transfer' }];

var INFO_LEAFLET_OPTIONS = [{ name: 'Yes', value: 'yes' }, { name: 'No', value: 'no' }, { name: 'Not Available', value: 'not_available' }];

function formatCrewMembers(items) {
  return items.map(function (item) {
    return Object.assign({}, item, {
      name: item.first_name + ' ' + item.last_name,
      disabled: false
    });
  });
}

var DeploymentDialogController = function (_ResourceDialogContro) {
  _inherits(DeploymentDialogController, _ResourceDialogContro);

  function DeploymentDialogController() {
    _classCallCheck(this, DeploymentDialogController);

    return _possibleConstructorReturn(this, (DeploymentDialogController.__proto__ || Object.getPrototypeOf(DeploymentDialogController)).apply(this, arguments));
  }

  _createClass(DeploymentDialogController, [{
    key: 'init',
    value: function init() {
      _get(DeploymentDialogController.prototype.__proto__ || Object.getPrototypeOf(DeploymentDialogController.prototype), 'init', this).call(this);
      this._scope = this.$injector.get('$rootScope').$new();
      this._state = this.$injector.get('$state');
      this._timeout = this.$injector.get('$timeout');
      this._resource = this.$injector.get('DeploymentResource');
      this._DEPLOYMENT_ID = _nodeUuid2.default.v4();
      this.types = TYPES;
      this.statuses = STATUSES;
      this.infoLeafletOptions = INFO_LEAFLET_OPTIONS;

      if (!this.item) {
        this.item = {
          incident: this.incident,
          crew_members: [],
          deployment_statuses: [],
          date: (0, _moment2.default)().seconds(0).milliseconds(0).toDate()
        };
      } else {
        if (this.item.date) {
          this.item.date = (0, _moment2.default)(this.item.date).toDate();
        } else {
          this.item.date = (0, _moment2.default)().seconds(0).milliseconds(0).toDate();
        }
      }

      this.minDate = (0, _moment2.default)(this.item.incident.date).toDate();

      this.vehicleCallsigns = this.getVehicleCallsigns();
      this.shiftCrew = this.getShiftCrew();
      this.setCrewMembers(this.shiftCrew.length ? this.shiftCrew : this.relationData.users);
      this.setupWatchers();
    }
  }, {
    key: 'setCrewMembers',
    value: function setCrewMembers(users) {
      var crewMembers = [].concat(_toConsumableArray(users));
      this.item.crew_members.forEach(function (crewMember) {
        if (!crewMembers.find(function (i) {
          return i.id === crewMember.id;
        })) {
          crewMembers.push(Object.assign({}, crewMember));
        }
      });
      this.crewMembers = formatCrewMembers(crewMembers);
      this.observers = [{ id: null, name: 'OTHER' }].concat(_toConsumableArray(this.crewMembers));
      this.departureChecklistCrewMembers = formatCrewMembers(crewMembers);
      this.selectedCrewMembers = this.filterSelectedCrewMembers(this.departureChecklistCrewMembers);
    }
  }, {
    key: 'addCrewMember',
    value: function addCrewMember() {
      this.item.crew_members = [].concat(_toConsumableArray(this.item.crew_members), [{}]);
    }
  }, {
    key: 'removeCrewMember',
    value: function removeCrewMember(i) {
      this.item.crew_members = [].concat(_toConsumableArray(this.item.crew_members.slice(0, i)), _toConsumableArray(this.item.crew_members.slice(i + 1)));
    }
  }, {
    key: 'delete',
    value: function _delete(id) {
      var _this3 = this;

      this.Dialog.promptDelete().then(function () {
        _this3._resource.destroy(id).then(function () {
          _this3.Dialog.cancel(id);
        }).catch(function (err) {
          var msg = 'Error deleting deployment.';
          if (err.status && err.status === 409) {
            msg = err.data.message;
          }
          _this3.Toast.showSimple(msg);
        });
      });
    }
  }, {
    key: 'create',
    value: function create(formData) {
      var _this4 = this;

      formData.id = this._DEPLOYMENT_ID;

      var copy = Object.assign({}, formData);
      copy.date = (0, _moment2.default)(formData.date);

      this._resource.create(copy).then(function () {
        _this4.Dialog.hide(formData);
      }).catch(function (err) {
        var msg = 'Error creating deployment.';
        if (err.status && err.status === 422 && err.data.errors && err.data.errors.vehicle_callsign_id) {
          msg = err.data.errors.vehicle_callsign_id[0];
        }
        _this4.Toast.showSimple(msg);
      });
    }
  }, {
    key: 'update',
    value: function update(formData) {
      var _this5 = this;

      var copy = Object.assign({}, formData);
      copy.date = (0, _moment2.default)(formData.date);

      this._resource.update(formData.id, copy, {
        include: ['incident', 'vehicleCallsign', 'vehicle', 'users', 'deploymentStatuses.deploymentStatus', 'incident.deployments', 'incident.deployments.vehicleCallsign', 'patientReportForms', 'acceptingHospital', 'acceptingConsultantSpecialty', 'riskAssessmentOutcome', 'departureChecklistCompletedBy', 'departureChecklistApprovedBy', 'refCentreDepartureChecklistCompletedBy', 'refCentreDepartureChecklistApprovedBy']
      }).then(function (deployment) {
        _this5.Dialog.hide(deployment);
      }).catch(function (err) {
        var msg = 'Error updating deployment.';
        if (err.status && err.status === 422 && err.data.errors && err.data.errors.vehicle_callsign_id) {
          msg = err.data.errors.vehicle_callsign_id[0];
        }
        _this5.Toast.showSimple(msg);
      });
    }
  }, {
    key: 'getVehicleCallsigns',
    value: function getVehicleCallsigns() {
      var today = (0, _moment2.default)().startOf('day');
      var incidentDate = (0, _moment2.default)(this.incident.date).startOf('day');
      return this.relationData.vehicleCallsigns.map(function (vehicleCallsign) {
        vehicleCallsign.disabled = false;
        var statusUpdate = vehicleCallsign.latest_status_update;
        // If the incident date is today & vehicle is either offline or it's last status update is
        // today and is not a final status - disable it from selection.
        if (incidentDate.isSame(today) && (!vehicleCallsign.online || statusUpdate && incidentDate.isSame(statusUpdate.time, 'day') && !statusUpdate.deployment_status.is_final_status)) {
          vehicleCallsign.disabled = true;
        }
        return vehicleCallsign;
      });
    }
  }, {
    key: 'getShiftCrew',
    value: function getShiftCrew() {
      return this.relationData.shifts.reduce(function (acc, cur) {
        if (!cur.users.length) return acc;
        var uniqueUsers = cur.users.filter(function (user) {
          return !acc.find(function (_ref) {
            var id = _ref.id;
            return id === user.id;
          });
        });
        return [].concat(_toConsumableArray(acc), _toConsumableArray(uniqueUsers));
      }, []);
    }
  }, {
    key: 'showCrewSelectionDialog',
    value: function showCrewSelectionDialog(callsign, shifts) {
      var _this = this;
      // It's possible to have more than one shift with the same ID, hence they need to be made unique.
      shifts = shifts.map(function (shift, i) {
        return Object.assign({}, shift, { id: shift.id + '-' + i });
      });
      return this.Dialog.show({
        controller: function controller() {
          var _this6 = this;

          this.callsignName = callsign.name;
          this.options = shifts;
          this.selected = null;
          this.close = function () {
            return _this.Dialog.cancel();
          };
          this.submit = function () {
            if (_this6.form.$invalid) return;
            _this.Dialog.hide(_this6.selected);
          };
        },
        multiple: true,
        targetEvent: null,
        template: PROMPT_SELECT_TPL
      });
    }
  }, {
    key: 'setupWatchers',
    value: function setupWatchers() {
      var _this7 = this;

      this._scope.$watch(function () {
        return _this7.item.vehicle_callsign;
      }, function (newVal, oldVal) {
        if (newVal && oldVal && newVal.id === oldVal.id) return;
        if (!_this7.shiftCrew.length) return _this7.setCrewMembers(_this7.relationData.users);
        if (!newVal) return _this7.setCrewMembers(_this7.shiftCrew);

        var shifts = _this7.relationData.shifts.filter(function (shift) {
          return shift.vehicle_callsign && shift.vehicle_callsign.id === newVal.id;
        });

        if (!shifts.length) return;

        if (shifts.length === 1) {
          if (!_this7.item.crew_members.length) {
            _this7.item.crew_members = formatCrewMembers(shifts[0].users);
          } else {
            _this7.Dialog.confirm('Would you like to set the ' + shifts[0].name + ' crew?').then(function () {
              _this7.item.crew_members = formatCrewMembers(shifts[0].users);
            });
          }
        } else {
          _this7.showCrewSelectionDialog(newVal, shifts).then(function (crew) {
            return _this7.item.crew_members = formatCrewMembers(crew.users);
          });
        }
      });

      this._scope.$watch(function () {
        return _this7.item.crew_members;
      }, function (newVal, oldVal) {
        _this7.selectedCrewMembers = _this7.filterSelectedCrewMembers(_this7.departureChecklistCrewMembers);
        _this7._timeout(function () {
          var ids = newVal.map(function (i) {
            return i.id;
          });
          _this7.crewMembers.forEach(function (i) {
            if (i.id == null) return;
            i.disabled = ids.indexOf(i.id) > -1;
          });
        });
      }, true);
    }
  }, {
    key: 'filterSelectedCrewMembers',
    value: function filterSelectedCrewMembers(crewMembers) {
      var _this8 = this;

      return crewMembers.filter(function (item) {
        return _this8.item.crew_members.find(function (i) {
          return i.id === item.id;
        });
      });
    }
  }]);

  return DeploymentDialogController;
}(_resourceDialogController2.default);

exports.default = DeploymentDialogController;

},{"common/resource/controllers/resourceDialogController":56,"moment":"moment","node-uuid":"node-uuid"}],127:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _resourceDialog = require('common/resource/services/resourceDialog');

var _resourceDialog2 = _interopRequireDefault(_resourceDialog);

var _deploymentDialog = require('./deployment-dialog.controller');

var _deploymentDialog2 = _interopRequireDefault(_deploymentDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var DeploymentDialog = function (_ResourceDialog) {
  _inherits(DeploymentDialog, _ResourceDialog);

  function DeploymentDialog() {
    _classCallCheck(this, DeploymentDialog);

    return _possibleConstructorReturn(this, (DeploymentDialog.__proto__ || Object.getPrototypeOf(DeploymentDialog)).apply(this, arguments));
  }

  _createClass(DeploymentDialog, [{
    key: 'init',
    value: function init() {
      _get(DeploymentDialog.prototype.__proto__ || Object.getPrototypeOf(DeploymentDialog.prototype), 'init', this).call(this);
      this.setController(_deploymentDialog2.default);
      this.setTemplateUrl('core/deployments/services/deployment-dialog/deployment-dialog.tpl.html');
    }
  }]);

  return DeploymentDialog;
}(_resourceDialog2.default);

exports.default = DeploymentDialog;

},{"./deployment-dialog.controller":126,"common/resource/services/resourceDialog":59}],128:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _angular = require('angular');

var _resourceDialogController = require('common/resource/controllers/resourceDialogController');

var _resourceDialogController2 = _interopRequireDefault(_resourceDialogController);

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var DeploymentListDialogController = function (_ResourceDialogContro) {
  _inherits(DeploymentListDialogController, _ResourceDialogContro);

  function DeploymentListDialogController() {
    _classCallCheck(this, DeploymentListDialogController);

    return _possibleConstructorReturn(this, (DeploymentListDialogController.__proto__ || Object.getPrototypeOf(DeploymentListDialogController)).apply(this, arguments));
  }

  _createClass(DeploymentListDialogController, [{
    key: 'init',
    value: function init() {
      _get(DeploymentListDialogController.prototype.__proto__ || Object.getPrototypeOf(DeploymentListDialogController.prototype), 'init', this).call(this);

      this.deploymentStatuses = this.deploymentStatuses.map(function (item) {
        item.name = item.deployment_status.name;
        item.timeString = (0, _moment2.default)(item.time).format('HH:mm');
        return item;
      });

      this.fields = {
        timeString: 'Time',
        name: 'Deployment Status'
      };

      if (typeof this.showEditDialog !== 'function') {
        this.showEditDialog = _angular.noop;
      }
    }
  }]);

  return DeploymentListDialogController;
}(_resourceDialogController2.default);

exports.default = DeploymentListDialogController;

},{"angular":"angular","common/resource/controllers/resourceDialogController":56,"moment":"moment"}],129:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _resourceDialog = require('common/resource/services/resourceDialog');

var _resourceDialog2 = _interopRequireDefault(_resourceDialog);

var _deploymentListDialog = require('./deployment-list-dialog.controller');

var _deploymentListDialog2 = _interopRequireDefault(_deploymentListDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var DeploymentListDialog = function (_ResourceDialog) {
  _inherits(DeploymentListDialog, _ResourceDialog);

  function DeploymentListDialog() {
    _classCallCheck(this, DeploymentListDialog);

    return _possibleConstructorReturn(this, (DeploymentListDialog.__proto__ || Object.getPrototypeOf(DeploymentListDialog)).apply(this, arguments));
  }

  _createClass(DeploymentListDialog, [{
    key: 'init',
    value: function init() {
      _get(DeploymentListDialog.prototype.__proto__ || Object.getPrototypeOf(DeploymentListDialog.prototype), 'init', this).call(this);
      this.setController(_deploymentListDialog2.default);
      this.setTemplateUrl('core/deployments/services/deployment-list-dialog/deployment-list-dialog.tpl.html');
    }
  }]);

  return DeploymentListDialog;
}(_resourceDialog2.default);

exports.default = DeploymentListDialog;

},{"./deployment-list-dialog.controller":128,"common/resource/services/resourceDialog":59}],130:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _nodeUuid = require('node-uuid');

var _nodeUuid2 = _interopRequireDefault(_nodeUuid);

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _resourceDialogController = require('common/resource/controllers/resourceDialogController');

var _resourceDialogController2 = _interopRequireDefault(_resourceDialogController);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function getDestinationsWithTypes(destinations) {
  var updated = {};

  if (destinations.hospitals) {
    updated.hospitals = destinations.hospitals.map(function (item) {
      item.destinationType = 'hospital';
      return item;
    });
  }

  if (destinations.locations) {
    updated.locations = destinations.locations.map(function (item) {
      item.destinationType = 'location';
      return item;
    });
  }

  if (destinations.bases) {
    updated.bases = destinations.bases.map(function (item) {
      item.destinationType = 'base';
      return item;
    });
  }

  return updated;
}

function formatTime(deploymentDate, statusUpdateTime) {
  var date = (0, _moment2.default)(deploymentDate).format('YYYY-MM-DD');
  var time = (0, _moment2.default)(statusUpdateTime).format('HH:mm:ss');
  return (0, _moment2.default)(date + ' ' + time).toDate();
}

var DeploymentStatusUpdateDialogController = function (_ResourceDialogContro) {
  _inherits(DeploymentStatusUpdateDialogController, _ResourceDialogContro);

  function DeploymentStatusUpdateDialogController() {
    _classCallCheck(this, DeploymentStatusUpdateDialogController);

    return _possibleConstructorReturn(this, (DeploymentStatusUpdateDialogController.__proto__ || Object.getPrototypeOf(DeploymentStatusUpdateDialogController)).apply(this, arguments));
  }

  _createClass(DeploymentStatusUpdateDialogController, [{
    key: 'init',
    value: function init() {
      _get(DeploymentStatusUpdateDialogController.prototype.__proto__ || Object.getPrototypeOf(DeploymentStatusUpdateDialogController.prototype), 'init', this).call(this);
      this._resource = this.$injector.get('DeploymentStatusUpdateResource');
      this._scope = this.$injector.get('$rootScope').$new();
      this._ID = _nodeUuid2.default.v4();
      this.searchTerm = '';
      this.availableDestinations = this.destinations;
      if (this.item) {
        this.item.date = (0, _moment2.default)(this.item.time).toDate();
      } else {
        this.item = {
          date: this._getDeploymentDate()
        };
      }

      if (this.deployments) {
        this.deployments = this.deployments.map(function (deployment) {
          deployment.date = (0, _moment2.default)(deployment.date).toDate();
          return deployment;
        });
      } else if (this.deployment) {
        this.deployment.date = this._getDeploymentDate();
      }

      this._setupWatchers();
    }
  }, {
    key: 'create',
    value: function create(formData) {
      var _this2 = this;

      formData.id = this._ID;
      formData.deployment = { id: this.deployment.id };
      formData.time = formatTime(formData.date, formData.time);
      if (formData.destination) {
        formData.destination_type = formData.destination.destinationType;
      }

      this._resource.create(formData).then(function () {
        var copy = Object.assign({}, formData);
        copy.time = (0, _moment2.default)(copy.time);
        _this2.Dialog.hide(copy);
      }).catch(console.log.bind(console));
    }
  }, {
    key: 'update',
    value: function update(formData) {
      var _this3 = this;

      if (!this.deployment) {
        this.deployment = formData.deployment;
      }
      formData.time = formatTime(formData.date, formData.time);
      if (formData.destination) {
        formData.destination_type = formData.destination.destinationType;
      }

      this._resource.update(formData.id, formData, {
        include: ['deploymentStatus', 'destination']
      }).then(function (deployment) {
        _this3.Dialog.hide(deployment);
      }).catch(console.log.bind(console));
    }
  }, {
    key: 'delete',
    value: function _delete(id) {
      var _this4 = this;

      this.Dialog.confirm().then(function () {
        _this4._resource.destroy(id).then(function () {
          _this4.Dialog.cancel(id);
        }).catch(console.log.bind(console));
      });
    }
  }, {
    key: '_setupWatchers',
    value: function _setupWatchers() {
      var _this5 = this;

      this._scope.$watch(function () {
        return _this5.relationData;
      }, function (newVal) {
        if (newVal && newVal.destinations) {
          _this5.destinations = getDestinationsWithTypes(newVal.destinations);
        }
      }, true);

      this._scope.$watch(function () {
        if (_this5.item) {
          return _this5.item.deployment_status;
        }
      }, function (newVal) {
        if (!newVal) {
          return;
        }

        if (newVal.is_at_hospital) {
          _this5.availableDestinations = {
            hospitals: _this5.destinations.hospitals
          };
        } else if (_this5.availableDestinations !== _this5.destinations) {
          _this5.availableDestinations = _this5.destinations;
        }
      });
    }
  }, {
    key: '_getDeploymentDate',
    value: function _getDeploymentDate() {
      return this.deployment ? (0, _moment2.default)(this.deployment.date).toDate() : new Date();
    }
  }]);

  return DeploymentStatusUpdateDialogController;
}(_resourceDialogController2.default);

exports.default = DeploymentStatusUpdateDialogController;

},{"common/resource/controllers/resourceDialogController":56,"moment":"moment","node-uuid":"node-uuid"}],131:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _resourceDialog = require('common/resource/services/resourceDialog');

var _resourceDialog2 = _interopRequireDefault(_resourceDialog);

var _deploymentStatusUpdateDialog = require('./deployment-status-update-dialog.controller');

var _deploymentStatusUpdateDialog2 = _interopRequireDefault(_deploymentStatusUpdateDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var DeploymentStatusUpdateDialog = function (_ResourceDialog) {
  _inherits(DeploymentStatusUpdateDialog, _ResourceDialog);

  function DeploymentStatusUpdateDialog() {
    _classCallCheck(this, DeploymentStatusUpdateDialog);

    return _possibleConstructorReturn(this, (DeploymentStatusUpdateDialog.__proto__ || Object.getPrototypeOf(DeploymentStatusUpdateDialog)).apply(this, arguments));
  }

  _createClass(DeploymentStatusUpdateDialog, [{
    key: 'init',
    value: function init() {
      _get(DeploymentStatusUpdateDialog.prototype.__proto__ || Object.getPrototypeOf(DeploymentStatusUpdateDialog.prototype), 'init', this).call(this);
      this.setController(_deploymentStatusUpdateDialog2.default);
      this.setTemplateUrl('core/deployments/services/deployment-status-update-dialog/deployment-status-update-dialog.tpl.html');
    }
  }]);

  return DeploymentStatusUpdateDialog;
}(_resourceDialog2.default);

exports.default = DeploymentStatusUpdateDialog;

},{"./deployment-status-update-dialog.controller":130,"common/resource/services/resourceDialog":59}],132:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _deviceDialog = require('./services/device-dialog/device-dialog.service');

var _deviceDialog2 = _interopRequireDefault(_deviceDialog);

var _device = require('./resources/device.resource');

var _device2 = _interopRequireDefault(_device);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _module = _angular2.default.module('gnaas.core.devices', []).service('DeviceDialog', _deviceDialog2.default).service('DeviceResource', _device2.default);

exports.default = _module.name;

},{"./resources/device.resource":133,"./services/device-dialog/device-dialog.service":135,"angular":"angular"}],133:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parse = exports.format = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

var _vehicleCallsignResource = require('pages/admin/vehicleCallsigns/services/vehicleCallsignResource');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _format(item) {
  return {
    id: item.id,
    name: item.name,
    device_id: item.device_id,
    vehicle_callsign: item.vehicleCallsign ? (0, _vehicleCallsignResource.format)(item.vehicleCallsign.data) : null,
    active: !!item.active
  };
}

exports.format = _format;
function _parse(item) {
  var i = Object.assign({}, item);
  i.vehicle_callsign_id = i.vehicle_callsign ? i.vehicle_callsign.id : null;
  delete i.vehicle_callsign;
  return i;
}

exports.parse = _parse;

var DeviceResource = function (_RestResource) {
  _inherits(DeviceResource, _RestResource);

  function DeviceResource() {
    _classCallCheck(this, DeviceResource);

    return _possibleConstructorReturn(this, (DeviceResource.__proto__ || Object.getPrototypeOf(DeviceResource)).apply(this, arguments));
  }

  _createClass(DeviceResource, [{
    key: 'init',
    value: function init() {
      _get(DeviceResource.prototype.__proto__ || Object.getPrototypeOf(DeviceResource.prototype), 'init', this).call(this);
      this.resourcePath = 'devices';
    }
  }, {
    key: 'format',
    value: function format(item) {
      return _format(item);
    }
  }, {
    key: 'parse',
    value: function parse(item) {
      return _parse(item);
    }
  }]);

  return DeviceResource;
}(_restResource2.default);

exports.default = DeviceResource;

},{"common/resource/services/restResource":60,"pages/admin/vehicleCallsigns/services/vehicleCallsignResource":332}],134:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _nodeUuid = require('node-uuid');

var _nodeUuid2 = _interopRequireDefault(_nodeUuid);

var _resourceDialogController = require('common/resource/controllers/resourceDialogController');

var _resourceDialogController2 = _interopRequireDefault(_resourceDialogController);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var DeviceDialogController = function (_ResourceDialogContro) {
  _inherits(DeviceDialogController, _ResourceDialogContro);

  function DeviceDialogController() {
    _classCallCheck(this, DeviceDialogController);

    return _possibleConstructorReturn(this, (DeviceDialogController.__proto__ || Object.getPrototypeOf(DeviceDialogController)).apply(this, arguments));
  }

  _createClass(DeviceDialogController, [{
    key: 'init',
    value: function init() {
      _get(DeviceDialogController.prototype.__proto__ || Object.getPrototypeOf(DeviceDialogController.prototype), 'init', this).call(this);
      this.DeviceResource = this.$injector.get('DeviceResource');
      this.ID = _nodeUuid2.default.v4();

      if (!this.item) {
        this.item = { active: true };
      }
    }
  }, {
    key: 'create',
    value: function create(formData) {
      var _this2 = this;

      formData.id = this.ID;
      this.DeviceResource.create(formData).then(function () {
        _this2.Dialog.hide(formData);
      }).catch(console.log.bind(console));
    }
  }, {
    key: 'update',
    value: function update(formData) {
      var _this3 = this;

      this.DeviceResource.update(formData.id, formData, { include: 'vehicleCallsign' }).then(function (data) {
        _this3.Dialog.hide(data);
      }).catch(console.log.bind(console));
    }
  }, {
    key: 'delete',
    value: function _delete(id) {
      var _this4 = this;

      this.Dialog.confirm('Are you sure?').then(function () {
        _this4.DeviceResource.destroy(id).then(function () {
          _this4.Dialog.cancel(_this4.item);
        });
      });
    }
  }]);

  return DeviceDialogController;
}(_resourceDialogController2.default);

exports.default = DeviceDialogController;

},{"common/resource/controllers/resourceDialogController":56,"node-uuid":"node-uuid"}],135:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _resourceDialog = require('common/resource/services/resourceDialog');

var _resourceDialog2 = _interopRequireDefault(_resourceDialog);

var _deviceDialog = require('./device-dialog.controller');

var _deviceDialog2 = _interopRequireDefault(_deviceDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var DeviceDialog = function (_ResourceDialog) {
  _inherits(DeviceDialog, _ResourceDialog);

  function DeviceDialog() {
    _classCallCheck(this, DeviceDialog);

    return _possibleConstructorReturn(this, (DeviceDialog.__proto__ || Object.getPrototypeOf(DeviceDialog)).apply(this, arguments));
  }

  _createClass(DeviceDialog, [{
    key: 'init',
    value: function init() {
      _get(DeviceDialog.prototype.__proto__ || Object.getPrototypeOf(DeviceDialog.prototype), 'init', this).call(this);
      this.setController(_deviceDialog2.default);
      this.setTemplateUrl('core/devices/services/device-dialog/device-dialog.tpl.html');
    }
  }]);

  return DeviceDialog;
}(_resourceDialog2.default);

exports.default = DeviceDialog;

},{"./device-dialog.controller":134,"common/resource/services/resourceDialog":59}],136:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _destroyBatchDialog = require('./services/destroy-batch-dialog/destroy-batch-dialog.service');

var _destroyBatchDialog2 = _interopRequireDefault(_destroyBatchDialog);

var _drugOrderFormDialog = require('./services/drug-order-form-dialog/drug-order-form-dialog.service');

var _drugOrderFormDialog2 = _interopRequireDefault(_drugOrderFormDialog);

var _createDrugStockCheckDialog = require('./services/create-drug-stock-check-dialog/create-drug-stock-check-dialog.service');

var _createDrugStockCheckDialog2 = _interopRequireDefault(_createDrugStockCheckDialog);

var _drugStockCheckDialog = require('./services/drug-stock-check-dialog/drug-stock-check-dialog.service');

var _drugStockCheckDialog2 = _interopRequireDefault(_drugStockCheckDialog);

var _manualAdjustmentDialog = require('./services/manual-adjustment-dialog/manual-adjustment-dialog.service');

var _manualAdjustmentDialog2 = _interopRequireDefault(_manualAdjustmentDialog);

var _recordDeliveryDialog = require('./services/record-delivery-dialog/record-delivery-dialog.service');

var _recordDeliveryDialog2 = _interopRequireDefault(_recordDeliveryDialog);

var _transactionApprovalDialog = require('./services/transaction-approval-dialog/transaction-approval-dialog.service');

var _transactionApprovalDialog2 = _interopRequireDefault(_transactionApprovalDialog);

var _transactionApproval = require('./services/transaction-approval-dialog/transaction-approval.service');

var _transactionApproval2 = _interopRequireDefault(_transactionApproval);

var _transferBatchDialog = require('./services/transfer-batch-dialog/transfer-batch-dialog.service');

var _transferBatchDialog2 = _interopRequireDefault(_transferBatchDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _module = _angular2.default.module('gnaas.core.drugs', []).service('CreateDrugStockCheckDialog', _createDrugStockCheckDialog2.default).service('DestroyBatchDialog', _destroyBatchDialog2.default).service('DrugOrderFormDialog', _drugOrderFormDialog2.default).service('DrugStockCheckDialog', _drugStockCheckDialog2.default).service('ManualAdjustmentDialog', _manualAdjustmentDialog2.default).service('RecordDeliveryDialog', _recordDeliveryDialog2.default).service('TransactionApprovalDialog', _transactionApprovalDialog2.default).service('TransactionApprovalService', _transactionApproval2.default).service('TransferBatchDialog', _transferBatchDialog2.default);

exports.default = _module.name;

},{"./services/create-drug-stock-check-dialog/create-drug-stock-check-dialog.service":138,"./services/destroy-batch-dialog/destroy-batch-dialog.service":140,"./services/drug-order-form-dialog/drug-order-form-dialog.service":142,"./services/drug-stock-check-dialog/drug-stock-check-dialog.service":144,"./services/manual-adjustment-dialog/manual-adjustment-dialog.service":146,"./services/record-delivery-dialog/record-delivery-dialog.service":148,"./services/transaction-approval-dialog/transaction-approval-dialog.service":150,"./services/transaction-approval-dialog/transaction-approval.service":151,"./services/transfer-batch-dialog/transfer-batch-dialog.service":153,"angular":"angular"}],137:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _resourceDialogController = require('common/resource/controllers/resourceDialogController');

var _resourceDialogController2 = _interopRequireDefault(_resourceDialogController);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var CreateDrugStockCheckDialogController = function (_ResourceDialogContro) {
  _inherits(CreateDrugStockCheckDialogController, _ResourceDialogContro);

  function CreateDrugStockCheckDialogController() {
    _classCallCheck(this, CreateDrugStockCheckDialogController);

    return _possibleConstructorReturn(this, (CreateDrugStockCheckDialogController.__proto__ || Object.getPrototypeOf(CreateDrugStockCheckDialogController)).apply(this, arguments));
  }

  return CreateDrugStockCheckDialogController;
}(_resourceDialogController2.default);

exports.default = CreateDrugStockCheckDialogController;

},{"common/resource/controllers/resourceDialogController":56}],138:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _resourceDialog = require('common/resource/services/resourceDialog');

var _resourceDialog2 = _interopRequireDefault(_resourceDialog);

var _createDrugStockCheckDialog = require('./create-drug-stock-check-dialog.controller');

var _createDrugStockCheckDialog2 = _interopRequireDefault(_createDrugStockCheckDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var CreateDrugStockCheckDialog = function (_ResourceDialog) {
  _inherits(CreateDrugStockCheckDialog, _ResourceDialog);

  function CreateDrugStockCheckDialog() {
    _classCallCheck(this, CreateDrugStockCheckDialog);

    return _possibleConstructorReturn(this, (CreateDrugStockCheckDialog.__proto__ || Object.getPrototypeOf(CreateDrugStockCheckDialog)).apply(this, arguments));
  }

  _createClass(CreateDrugStockCheckDialog, [{
    key: 'init',
    value: function init() {
      _get(CreateDrugStockCheckDialog.prototype.__proto__ || Object.getPrototypeOf(CreateDrugStockCheckDialog.prototype), 'init', this).call(this);
      this.setController(_createDrugStockCheckDialog2.default);
      this.setTemplateUrl('core/drugs/services/create-drug-stock-check-dialog/create-drug-stock-check-dialog.tpl.html');
    }
  }]);

  return CreateDrugStockCheckDialog;
}(_resourceDialog2.default);

exports.default = CreateDrugStockCheckDialog;

},{"./create-drug-stock-check-dialog.controller":137,"common/resource/services/resourceDialog":59}],139:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _angular = require('angular');

var _module = require('common/resource/module.constants');

var _resourceDialogController = require('common/resource/controllers/resourceDialogController');

var _resourceDialogController2 = _interopRequireDefault(_resourceDialogController);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var DEFAULT_DESTROY_TYPES = [{ id: 'destroyed', name: 'Destroyed' }, { id: 'broken', name: 'Broken' }, { id: 'pre-drawn-wasted', name: 'Pre-Drawn Wasted' }];

var DestroyBatchController = function (_ResourceDialogContro) {
  _inherits(DestroyBatchController, _ResourceDialogContro);

  function DestroyBatchController() {
    _classCallCheck(this, DestroyBatchController);

    return _possibleConstructorReturn(this, (DestroyBatchController.__proto__ || Object.getPrototypeOf(DestroyBatchController)).apply(this, arguments));
  }

  _createClass(DestroyBatchController, [{
    key: 'init',
    value: function init() {
      var _this2 = this;

      _get(DestroyBatchController.prototype.__proto__ || Object.getPrototypeOf(DestroyBatchController.prototype), 'init', this).call(this);

      this.StockLocationResource = this.$injector.get('StockLocationResource');
      this.DrugStockTransactionResource = this.$injector.get('DrugStockTransactionResource');
      this.DrugBatchesResource = this.$injector.get('DrugBatchesResource');
      this.TransactionApprovalService = this.$injector.get('TransactionApprovalService');

      this.StockLocationResource.index().then(function (items) {
        _this2.stockLocations = items;
      });
      this.destroyTypes = [].concat(DEFAULT_DESTROY_TYPES);

      this._createWatchers();

      // Handles pre-populating data from calendar.
      // Not the cleanest solution, but acceptable for the time being.
      // If this dialog needs to be more flexible - refactor!
      if (this.locals.fromCalendar && this.item) {
        this._setupFromCalendarUseCase();
      }

      this.item = Object.assign({}, this.item, { timestamp: (0, _moment2.default)().startOf('minute').toDate() });
    }
  }, {
    key: 'submit',
    value: function submit(input) {
      var _this3 = this;

      if (!this.form) throw new Error('Form not found.');
      if (this.form.$invalid) return;

      this.TransactionApprovalService.attempt(this._createTransaction.bind(this), input).then(function (data) {
        _this3.Dialog.hide(data);
      }).catch(function (err) {
        var errors = err.data.errors;
        if (errors && errors.hasOwnProperty('quantity')) {
          _this3.Dialog.alert('Could not destroy unit\\-s: ' + errors.quantity[0], 'Destroy Batch Error');
        }
      });
    }
  }, {
    key: 'onSelectChange',
    value: function onSelectChange(_ref) {
      var name = _ref.name;

      if (name === 'stock_location_id') {
        this.item.drug_id = null;
        this.item.expires_on = null;
        this.item.batch_id = null;
      }
      this.item.quantity = null;
    }
  }, {
    key: '_createWatchers',
    value: function _createWatchers() {
      var _this4 = this;

      this.$injector.get('$rootScope').$new().$watch(function () {
        return _this4.stockLocationId;
      }, function (newVal, oldVal) {
        if (!newVal) return;
        _this4._stockLocationChanged(newVal);
      });
    }
  }, {
    key: '_createTransaction',
    value: function _createTransaction(input) {
      var data = this._parseData(input);
      return this.DrugStockTransactionResource.create(data);
    }
  }, {
    key: '_stockLocationChanged',
    value: function _stockLocationChanged(stockLocationId) {
      var _this5 = this;

      this.DrugBatchesResource.index({ location_id: stockLocationId, include: 'drug', quantity_mode: 'non-empty' }).then(function (drugBatches) {
        _this5.drugMap = _this5._mapDrugs(drugBatches);
        _this5.drugs = Object.values(_this5.drugMap).map(function (item) {
          return { id: item.id, name: item.name };
        });
      }).catch(console.log.bind(console));
    }
  }, {
    key: '_mapDrugs',
    value: function _mapDrugs(batches) {
      return batches.reduce(function (acc, item) {
        var drugId = item.drug.id;
        if (!acc.hasOwnProperty(drugId)) {
          acc[drugId] = { id: drugId, name: item.drug.name, expiryMap: {}, expiries: [] };
        }

        var expiryMap = acc[drugId].expiryMap;
        var expiries = acc[drugId].expiries;
        var expiry = item.expires_on.split(' ')[0];
        if (!expiryMap.hasOwnProperty(expiry)) {
          expiryMap[expiry] = [];
          expiries.push(expiry);
        }

        expiryMap[expiry].push(item);

        return acc;
      }, {});
    }
  }, {
    key: '_parseData',
    value: function _parseData(data) {
      var d = (0, _angular.copy)(data);

      d.timestamp = (0, _moment2.default)(d.timestamp).format(_module.DATE_TIME_FORMAT);
      delete d.expires_on;
      delete d.drug_id;

      return d;
    }
  }, {
    key: '_setupFromCalendarUseCase',
    value: function _setupFromCalendarUseCase() {
      this.stockLocationId = this.item.location ? this.item.location.id : null;
      this.item.transaction_type = 'destroyed';
      this.item.expires_on = this.item.expires_on.split(' ')[0];
      this.item.drug_id = this.item.drug.id;
      this.item.batch_id = this.item.id;
      this.item.quantity = this.item.available_quantity;
    }
  }]);

  return DestroyBatchController;
}(_resourceDialogController2.default);

exports.default = DestroyBatchController;

},{"angular":"angular","common/resource/controllers/resourceDialogController":56,"common/resource/module.constants":57,"moment":"moment"}],140:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _resourceDialog = require('common/resource/services/resourceDialog');

var _resourceDialog2 = _interopRequireDefault(_resourceDialog);

var _destroyBatchDialog = require('./destroy-batch-dialog.controller');

var _destroyBatchDialog2 = _interopRequireDefault(_destroyBatchDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ManualAdjustmentDialog = function (_ResourceDialog) {
  _inherits(ManualAdjustmentDialog, _ResourceDialog);

  function ManualAdjustmentDialog() {
    _classCallCheck(this, ManualAdjustmentDialog);

    return _possibleConstructorReturn(this, (ManualAdjustmentDialog.__proto__ || Object.getPrototypeOf(ManualAdjustmentDialog)).apply(this, arguments));
  }

  _createClass(ManualAdjustmentDialog, [{
    key: 'init',
    value: function init() {
      _get(ManualAdjustmentDialog.prototype.__proto__ || Object.getPrototypeOf(ManualAdjustmentDialog.prototype), 'init', this).call(this);
      this.setController(_destroyBatchDialog2.default);
      this.setTemplateUrl('core/drugs/services/destroy-batch-dialog/destroy-batch-dialog.tpl.html');
    }
  }]);

  return ManualAdjustmentDialog;
}(_resourceDialog2.default);

exports.default = ManualAdjustmentDialog;

},{"./destroy-batch-dialog.controller":139,"common/resource/services/resourceDialog":59}],141:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _resourceDialogController = require('common/resource/controllers/resourceDialogController');

var _resourceDialogController2 = _interopRequireDefault(_resourceDialogController);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; } /* global Blob, URL */


var DrugOrderFormDialogDialogController = function (_ResourceDialogContro) {
  _inherits(DrugOrderFormDialogDialogController, _ResourceDialogContro);

  function DrugOrderFormDialogDialogController() {
    _classCallCheck(this, DrugOrderFormDialogDialogController);

    return _possibleConstructorReturn(this, (DrugOrderFormDialogDialogController.__proto__ || Object.getPrototypeOf(DrugOrderFormDialogDialogController)).apply(this, arguments));
  }

  _createClass(DrugOrderFormDialogDialogController, [{
    key: 'init',
    value: function init() {
      var _this2 = this;

      _get(DrugOrderFormDialogDialogController.prototype.__proto__ || Object.getPrototypeOf(DrugOrderFormDialogDialogController.prototype), 'init', this).call(this);
      this.StockLocationResource = this.$injector.get('StockLocationResource');
      this.DrugStockResource = this.$injector.get('DrugStockResource');

      this.selectedStockLocations = [];
      this.StockLocationResource.index().then(function (items) {
        _this2.stockLocations = items;
      });

      this.item = { stock_locations: [] };
    }
  }, {
    key: 'submit',
    value: function submit(data) {
      var _this3 = this;

      if (this.form.$invalid || this.item.stock_locations.length === 0) return;

      this.DrugStockResource.generateDrugOrderForms(this._parseData(data)).then(function (pdf) {
        var file = new Blob([pdf], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        _this3.Dialog.hide();
      });
    }
  }, {
    key: '_parseData',
    value: function _parseData(data) {
      var d = Object.assign({}, data);
      d.stock_location_ids = d.stock_locations.map(function (item) {
        return item.id;
      });
      delete d.stock_locations;
      return d;
    }
  }]);

  return DrugOrderFormDialogDialogController;
}(_resourceDialogController2.default);

exports.default = DrugOrderFormDialogDialogController;

},{"common/resource/controllers/resourceDialogController":56}],142:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _resourceDialog = require('common/resource/services/resourceDialog');

var _resourceDialog2 = _interopRequireDefault(_resourceDialog);

var _drugOrderFormDialog = require('./drug-order-form-dialog.controller');

var _drugOrderFormDialog2 = _interopRequireDefault(_drugOrderFormDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var IncidentDialog = function (_ResourceDialog) {
  _inherits(IncidentDialog, _ResourceDialog);

  function IncidentDialog() {
    _classCallCheck(this, IncidentDialog);

    return _possibleConstructorReturn(this, (IncidentDialog.__proto__ || Object.getPrototypeOf(IncidentDialog)).apply(this, arguments));
  }

  _createClass(IncidentDialog, [{
    key: 'init',
    value: function init() {
      _get(IncidentDialog.prototype.__proto__ || Object.getPrototypeOf(IncidentDialog.prototype), 'init', this).call(this);
      this.setController(_drugOrderFormDialog2.default);
      this.setTemplateUrl('core/drugs/services/drug-order-form-dialog/drug-order-form-dialog.tpl.html');
    }
  }]);

  return IncidentDialog;
}(_resourceDialog2.default);

exports.default = IncidentDialog;

},{"./drug-order-form-dialog.controller":141,"common/resource/services/resourceDialog":59}],143:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _resourceDialogController = require('common/resource/controllers/resourceDialogController');

var _resourceDialogController2 = _interopRequireDefault(_resourceDialogController);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var DrugStockCheckDialogController = function (_ResourceDialogContro) {
  _inherits(DrugStockCheckDialogController, _ResourceDialogContro);

  function DrugStockCheckDialogController() {
    _classCallCheck(this, DrugStockCheckDialogController);

    return _possibleConstructorReturn(this, (DrugStockCheckDialogController.__proto__ || Object.getPrototypeOf(DrugStockCheckDialogController)).apply(this, arguments));
  }

  _createClass(DrugStockCheckDialogController, [{
    key: 'init',
    value: function init() {
      _get(DrugStockCheckDialogController.prototype.__proto__ || Object.getPrototypeOf(DrugStockCheckDialogController.prototype), 'init', this).call(this);
      this.DrugStockCheckItemResource = this.$injector.get('DrugStockCheckItemResource');
    }
  }, {
    key: 'submit',
    value: function submit(formData) {
      var _this2 = this;

      if (this.form.$invalid) return;
      this.DrugStockCheckItemResource.update(this.item.id, formData).then(function (item) {
        _this2.Dialog.hide(item);
      });
    }
  }]);

  return DrugStockCheckDialogController;
}(_resourceDialogController2.default);

exports.default = DrugStockCheckDialogController;

},{"common/resource/controllers/resourceDialogController":56}],144:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _resourceDialog = require('common/resource/services/resourceDialog');

var _resourceDialog2 = _interopRequireDefault(_resourceDialog);

var _drugStockCheckDialog = require('./drug-stock-check-dialog.controller');

var _drugStockCheckDialog2 = _interopRequireDefault(_drugStockCheckDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var DrugStockCheckDialog = function (_ResourceDialog) {
  _inherits(DrugStockCheckDialog, _ResourceDialog);

  function DrugStockCheckDialog() {
    _classCallCheck(this, DrugStockCheckDialog);

    return _possibleConstructorReturn(this, (DrugStockCheckDialog.__proto__ || Object.getPrototypeOf(DrugStockCheckDialog)).apply(this, arguments));
  }

  _createClass(DrugStockCheckDialog, [{
    key: 'init',
    value: function init() {
      _get(DrugStockCheckDialog.prototype.__proto__ || Object.getPrototypeOf(DrugStockCheckDialog.prototype), 'init', this).call(this);
      this.setController(_drugStockCheckDialog2.default);
      this.setTemplateUrl('core/drugs/services/drug-stock-check-dialog/drug-stock-check-dialog.tpl.html');
    }
  }]);

  return DrugStockCheckDialog;
}(_resourceDialog2.default);

exports.default = DrugStockCheckDialog;

},{"./drug-stock-check-dialog.controller":143,"common/resource/services/resourceDialog":59}],145:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _angular = require('angular');

var _module = require('common/resource/module.constants');

var _resourceDialogController = require('common/resource/controllers/resourceDialogController');

var _resourceDialogController2 = _interopRequireDefault(_resourceDialogController);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ManualAdjustmentController = function (_ResourceDialogContro) {
  _inherits(ManualAdjustmentController, _ResourceDialogContro);

  function ManualAdjustmentController() {
    _classCallCheck(this, ManualAdjustmentController);

    return _possibleConstructorReturn(this, (ManualAdjustmentController.__proto__ || Object.getPrototypeOf(ManualAdjustmentController)).apply(this, arguments));
  }

  _createClass(ManualAdjustmentController, [{
    key: 'init',
    value: function init() {
      var _this2 = this;

      _get(ManualAdjustmentController.prototype.__proto__ || Object.getPrototypeOf(ManualAdjustmentController.prototype), 'init', this).call(this);

      this.StockLocationResource = this.$injector.get('StockLocationResource');
      this.DrugStockTransactionResource = this.$injector.get('DrugStockTransactionResource');
      this.DrugBatchesResource = this.$injector.get('DrugBatchesResource');
      this.TransactionApprovalService = this.$injector.get('TransactionApprovalService');

      this.StockLocationResource.index().then(function (items) {
        _this2.stockLocations = items;
      });

      this.item = {
        timestamp: (0, _moment2.default)().startOf('minute').toDate()
      };

      this._createWatchers();
    }
  }, {
    key: 'create',
    value: function create(input) {
      var _this3 = this;

      this.TransactionApprovalService.attempt(this._createTransaction.bind(this), input).then(function (data) {
        _this3.Dialog.hide(data);
      });
    }
  }, {
    key: 'onBatchIdChange',
    value: function onBatchIdChange(_ref) {
      var name = _ref.name,
          value = _ref.value;

      var arr = this.drugMap[this.item.drug_id].expiryMap[this.item.expires_on];
      var found = arr.filter(function (item) {
        return item.id === value;
      });
      this.item.quantity = found[0].available_quantity;
      this.maxQuantity = found[0].initial_quantity;
    }
  }, {
    key: 'onSelectChange',
    value: function onSelectChange(_ref2) {
      var name = _ref2.name;

      if (name === 'stock_location_id') {
        this.item.drug_id = null;
        this.item.expires_on = null;
        this.item.batch_id = null;
      }
      this.item.quantity = null;
    }
  }, {
    key: '_createTransaction',
    value: function _createTransaction(input) {
      var data = this._parseData(input);
      return this.DrugStockTransactionResource.create(data);
    }
  }, {
    key: '_createWatchers',
    value: function _createWatchers() {
      var _this4 = this;

      this.scope = this.$injector.get('$rootScope').$new();
      this.scope.$watch(function () {
        return _this4.stockLocationId;
      }, function (newVal, oldVal) {
        if (newVal === oldVal) return;
        _this4._stockLocationChanged(newVal);
      });
    }
  }, {
    key: '_stockLocationChanged',
    value: function _stockLocationChanged(stockLocationId) {
      var _this5 = this;

      this.DrugBatchesResource.index({ location_id: stockLocationId, include: 'drug' }).then(function (drugBatches) {
        _this5.drugMap = _this5._mapDrugs(drugBatches);
        _this5.drugs = Object.values(_this5.drugMap).map(function (item) {
          return { id: item.id, name: item.name };
        });
      }).catch(console.log.bind(console));
    }
  }, {
    key: '_mapDrugs',
    value: function _mapDrugs(batches) {
      return batches.reduce(function (acc, item) {
        var drugId = item.drug.id;
        if (!acc.hasOwnProperty(drugId)) {
          acc[drugId] = { id: drugId, name: item.drug.name, expiryMap: {}, expiries: [] };
        }

        var expiryMap = acc[drugId].expiryMap;
        var expiries = acc[drugId].expiries;
        var expiry = item.expires_on.split(' ')[0];
        if (!expiryMap.hasOwnProperty(expiry)) {
          expiryMap[expiry] = [];
          expiries.push(expiry);
        }

        expiryMap[expiry].push(item);

        return acc;
      }, {});
    }
  }, {
    key: '_parseData',
    value: function _parseData(data) {
      var d = (0, _angular.copy)(data);

      d.transaction_type = 'manual';
      d.timestamp = (0, _moment2.default)(d.timestamp).format(_module.DATE_TIME_FORMAT);
      delete d.expires_on;
      delete d.drug_id;

      return d;
    }
  }]);

  return ManualAdjustmentController;
}(_resourceDialogController2.default);

exports.default = ManualAdjustmentController;

},{"angular":"angular","common/resource/controllers/resourceDialogController":56,"common/resource/module.constants":57,"moment":"moment"}],146:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _resourceDialog = require('common/resource/services/resourceDialog');

var _resourceDialog2 = _interopRequireDefault(_resourceDialog);

var _manualAdjustmentDialog = require('./manual-adjustment-dialog.controller');

var _manualAdjustmentDialog2 = _interopRequireDefault(_manualAdjustmentDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ManualAdjustmentDialog = function (_ResourceDialog) {
  _inherits(ManualAdjustmentDialog, _ResourceDialog);

  function ManualAdjustmentDialog() {
    _classCallCheck(this, ManualAdjustmentDialog);

    return _possibleConstructorReturn(this, (ManualAdjustmentDialog.__proto__ || Object.getPrototypeOf(ManualAdjustmentDialog)).apply(this, arguments));
  }

  _createClass(ManualAdjustmentDialog, [{
    key: 'init',
    value: function init() {
      _get(ManualAdjustmentDialog.prototype.__proto__ || Object.getPrototypeOf(ManualAdjustmentDialog.prototype), 'init', this).call(this);
      this.setController(_manualAdjustmentDialog2.default);
      this.setTemplateUrl('core/drugs/services/manual-adjustment-dialog/manual-adjustment-dialog.tpl.html');
    }
  }]);

  return ManualAdjustmentDialog;
}(_resourceDialog2.default);

exports.default = ManualAdjustmentDialog;

},{"./manual-adjustment-dialog.controller":145,"common/resource/services/resourceDialog":59}],147:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _nodeUuid = require('node-uuid');

var _nodeUuid2 = _interopRequireDefault(_nodeUuid);

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _angular = require('angular');

var _module = require('common/resource/module.constants');

var _resourceDialogController = require('common/resource/controllers/resourceDialogController');

var _resourceDialogController2 = _interopRequireDefault(_resourceDialogController);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var RecordDeliveryController = function (_ResourceDialogContro) {
  _inherits(RecordDeliveryController, _ResourceDialogContro);

  function RecordDeliveryController() {
    _classCallCheck(this, RecordDeliveryController);

    return _possibleConstructorReturn(this, (RecordDeliveryController.__proto__ || Object.getPrototypeOf(RecordDeliveryController)).apply(this, arguments));
  }

  _createClass(RecordDeliveryController, [{
    key: 'init',
    value: function init() {
      var _this2 = this;

      _get(RecordDeliveryController.prototype.__proto__ || Object.getPrototypeOf(RecordDeliveryController.prototype), 'init', this).call(this);

      this.StockLocationResource = this.$injector.get('StockLocationResource');
      this.DrugResource = this.$injector.get('DrugResource');
      this.DrugStockTransactionResource = this.$injector.get('DrugStockTransactionResource');
      this.TransactionApprovalService = this.$injector.get('TransactionApprovalService');

      this.StockLocationResource.index().then(function (items) {
        _this2.stockLocations = items;
      });
      this.DrugResource.index().then(function (items) {
        _this2.drugs = items.filter(function (item) {
          return !item.exclude_from_stock;
        });
      });

      this.today = new Date();
      this.item = {
        batches_received: [],
        timestamp: (0, _moment2.default)().startOf('minute').toDate()
      };
    }
  }, {
    key: 'newBatch',
    value: function newBatch() {
      this.item.batches_received.push({ id: _nodeUuid2.default.v4() });
    }
  }, {
    key: 'removeBatch',
    value: function removeBatch(id) {
      this.item.batches_received = this.item.batches_received.filter(function (item) {
        return item.id !== id;
      });
    }
  }, {
    key: 'create',
    value: function create(input) {
      var _this3 = this;

      this.TransactionApprovalService.attempt(this._createTransaction.bind(this), input).then(function (data) {
        _this3.Dialog.hide(data);
      });
    }
  }, {
    key: 'drugChanged',
    value: function drugChanged($event, batchId) {
      var batch = this.item.batches_received.find(function (item) {
        return item.id === batchId;
      });

      if ($event.value == null) {
        batch.dose_per_unit = null;
        batch.measurement_type = null;
        return;
      }

      var drug = this.drugs.find(function (item) {
        return item.id === $event.value;
      });
      batch.dose_per_unit = drug.dose_per_unit ? drug.dose_per_unit : null;
      batch.measurement_type = drug.measurement_type;
    }
  }, {
    key: '_createTransaction',
    value: function _createTransaction(input) {
      var data = this._parseData(input, this.stockLocationId);
      return this.DrugStockTransactionResource.create(data);
    }
  }, {
    key: '_parseData',
    value: function _parseData(data, stockLocationId) {
      var d = (0, _angular.copy)(data);

      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = d.batches_received[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var batch = _step.value;

          batch.expires_on = (0, _moment2.default)(batch.expires_on).format(_module.DATE_FORMAT);
          batch.stock_location_id = stockLocationId;
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      d.transaction_type = 'received';
      d.timestamp = (0, _moment2.default)(d.timestamp).format(_module.DATE_TIME_FORMAT);

      return d;
    }
  }]);

  return RecordDeliveryController;
}(_resourceDialogController2.default);

exports.default = RecordDeliveryController;

},{"angular":"angular","common/resource/controllers/resourceDialogController":56,"common/resource/module.constants":57,"moment":"moment","node-uuid":"node-uuid"}],148:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _resourceDialog = require('common/resource/services/resourceDialog');

var _resourceDialog2 = _interopRequireDefault(_resourceDialog);

var _recordDeliveryDialog = require('./record-delivery-dialog.controller');

var _recordDeliveryDialog2 = _interopRequireDefault(_recordDeliveryDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var RecordDeliveryDialog = function (_ResourceDialog) {
  _inherits(RecordDeliveryDialog, _ResourceDialog);

  function RecordDeliveryDialog() {
    _classCallCheck(this, RecordDeliveryDialog);

    return _possibleConstructorReturn(this, (RecordDeliveryDialog.__proto__ || Object.getPrototypeOf(RecordDeliveryDialog)).apply(this, arguments));
  }

  _createClass(RecordDeliveryDialog, [{
    key: 'init',
    value: function init() {
      _get(RecordDeliveryDialog.prototype.__proto__ || Object.getPrototypeOf(RecordDeliveryDialog.prototype), 'init', this).call(this);
      this.setController(_recordDeliveryDialog2.default);
      this.setTemplateUrl('core/drugs/services/record-delivery-dialog/record-delivery-dialog.tpl.html');
    }
  }]);

  return RecordDeliveryDialog;
}(_resourceDialog2.default);

exports.default = RecordDeliveryDialog;

},{"./record-delivery-dialog.controller":147,"common/resource/services/resourceDialog":59}],149:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var TransactionApprovalController = function () {

  /**
   * @ngInject
   * @constructor
   */
  function TransactionApprovalController(Dialog, DrugStockTransactionResource, Toast, $q) {
    _classCallCheck(this, TransactionApprovalController);

    this._Dialog = Dialog;
    this._DrugStockTransactionResource = DrugStockTransactionResource;
    this._Toast = Toast;
    this._q = $q;

    if (this.dataOnly == null) {
      this.dataOnly = false;
    }

    this.users = {};
    if (this.userEmails && this.userEmails.first) {
      this.users.first_user = { email: this.userEmails.first };
    }
    if (this.userEmails && this.userEmails.second) {
      this.users.second_user = { email: this.userEmails.second };
    }
  }

  _createClass(TransactionApprovalController, [{
    key: 'submit',
    value: function submit(users, approvalId) {
      var _this = this;

      if (this.form.$invalid) return;

      if (this.dataOnly) {
        return this._Dialog.hide(users);
      }

      return this._DrugStockTransactionResource.approve(approvalId, users).then(function () {
        _this._Dialog.hide();
      }).catch(function (err) {
        if (_this.users.first_user) {
          _this.users.first_user.password = null;
        }
        _this.users.second_user.password = null;
        _this._Toast.showSimple('Stock transaction approval unsuccessful.');
        _this._q.reject(err.data.errors ? err.data.errors : {});
      });
    }
  }, {
    key: 'cancel',
    value: function cancel() {
      this._Dialog.cancel();
    }
  }]);

  return TransactionApprovalController;
}();

exports.default = TransactionApprovalController;

},{}],150:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _transactionApprovalDialog = require('./transaction-approval-dialog.controller');

var _transactionApprovalDialog2 = _interopRequireDefault(_transactionApprovalDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var TransactionApprovalDialog = function () {
  /**
   * @constructor
   * @ngInject
   */
  function TransactionApprovalDialog(Dialog) {
    _classCallCheck(this, TransactionApprovalDialog);

    this._Dialog = Dialog;
    this._dialogOptions = {
      controller: _transactionApprovalDialog2.default,
      templateUrl: 'core/drugs/services/transaction-approval-dialog/transaction-approval-dialog.tpl.html'
    };
  }

  _createClass(TransactionApprovalDialog, [{
    key: 'show',
    value: function show(_ref) {
      var $event = _ref.$event,
          approvalId = _ref.approvalId,
          dataOnly = _ref.dataOnly,
          userEmails = _ref.userEmails;

      var dialogParams = Object.assign({}, this._dialogOptions, {
        targetEvent: $event || null,
        multiple: true,
        locals: { approvalId: approvalId, dataOnly: dataOnly, userEmails: userEmails }
      });
      return this._Dialog.show(dialogParams);
    }
  }]);

  return TransactionApprovalDialog;
}();

exports.default = TransactionApprovalDialog;

},{"./transaction-approval-dialog.controller":149}],151:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var TranscationApprovalService = function () {
  /**
   * @ngInject
   * @constructor
   */
  function TranscationApprovalService(TransactionApprovalDialog, Toast, Session) {
    _classCallCheck(this, TranscationApprovalService);

    this._TransactionApprovalDialog = TransactionApprovalDialog;
    this._Toast = Toast;
    this._Session = Session;
  }

  _createClass(TranscationApprovalService, [{
    key: 'attempt',
    value: function attempt(createFn, input) {
      var _this = this;

      var successToast = this._showSuccessToast.bind(this);

      return createFn(input).then(successToast).catch(function (err) {
        var errors = err.data.errors;

        if (errors && errors.hasOwnProperty('stock_transaction_approval_id')) {
          var approvalId = errors.stock_transaction_approval_id[0];
          var userEmails = { first: _this._Session.user().email };

          return _this._TransactionApprovalDialog.show({ approvalId: approvalId, userEmails: userEmails }).then(function () {
            input.stock_transaction_approval_id = approvalId;
            return createFn(input).then(successToast);
          });
        }

        throw err;
      });
    }
  }, {
    key: '_showSuccessToast',
    value: function _showSuccessToast() {
      this._Toast.showSimple('Stock transaction successful.');
    }
  }]);

  return TranscationApprovalService;
}();

exports.default = TranscationApprovalService;

},{}],152:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _angular = require('angular');

var _module = require('common/resource/module.constants');

var _resourceDialogController = require('common/resource/controllers/resourceDialogController');

var _resourceDialogController2 = _interopRequireDefault(_resourceDialogController);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var TransferBatchController = function (_ResourceDialogContro) {
  _inherits(TransferBatchController, _ResourceDialogContro);

  function TransferBatchController() {
    _classCallCheck(this, TransferBatchController);

    return _possibleConstructorReturn(this, (TransferBatchController.__proto__ || Object.getPrototypeOf(TransferBatchController)).apply(this, arguments));
  }

  _createClass(TransferBatchController, [{
    key: 'init',
    value: function init() {
      var _this2 = this;

      _get(TransferBatchController.prototype.__proto__ || Object.getPrototypeOf(TransferBatchController.prototype), 'init', this).call(this);

      this.StockLocationResource = this.$injector.get('StockLocationResource');
      this.DrugStockTransactionResource = this.$injector.get('DrugStockTransactionResource');
      this.DrugBatchesResource = this.$injector.get('DrugBatchesResource');
      this.q = this.$injector.get('$q');
      this.TransactionApprovalService = this.$injector.get('TransactionApprovalService');

      this.StockLocationResource.index().then(function (items) {
        _this2.stockLocations = items;
      });

      this.item = {
        batches_transferred: [],
        timestamp: (0, _moment2.default)().startOf('minute').toDate()
      };

      this._resetData();
      this._createWatcher();
    }
  }, {
    key: 'newBatch',
    value: function newBatch() {
      this.item.batches_transferred.push({});
    }
  }, {
    key: 'create',
    value: function create(input) {
      var _this3 = this;

      this.TransactionApprovalService.attempt(this._createTransaction.bind(this), input).then(function (data) {
        _this3.Dialog.hide(data);
      });
    }
  }, {
    key: '_resetData',
    value: function _resetData() {
      this.drugMap = {};
      this.drugs = [];
      this.item.batches_transferred = [];
    }
  }, {
    key: '_createWatcher',
    value: function _createWatcher() {
      var _this4 = this;

      this.$injector.get('$rootScope').$new().$watch(function () {
        return _this4.fromLocationId;
      }, function (newVal, oldVal) {
        if (oldVal === newVal || _this4._resetFromLocation) {
          _this4._resetFromLocation = false;
          return;
        }

        _this4.q.resolve(function () {
          if (!_this4.item.batches_transferred.length) return;
          return _this4.Dialog.confirm('Changing location will remove all existing transfer batches.');
        }()).then(function () {
          _this4._onFromStockLocationChanged(newVal);
        }).catch(function () {
          _this4._resetFromLocation = true;
          _this4.fromLocationId = oldVal;
        });
      });
    }
  }, {
    key: '_createTransaction',
    value: function _createTransaction(input) {
      var data = this._parseData(input, this.toLocationId);
      return this.DrugStockTransactionResource.create(data);
    }
  }, {
    key: '_parseData',
    value: function _parseData(data, toStockLocationId) {
      var d = (0, _angular.copy)(data);

      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = d.batches_transferred[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var item = _step.value;

          item.stock_location_id = toStockLocationId;
          delete item.drug_id;
          delete item.expires_on;
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      d.transaction_type = 'transferred';
      d.timestamp = (0, _moment2.default)(d.timestamp).format(_module.DATE_TIME_FORMAT);

      return d;
    }
  }, {
    key: '_onFromStockLocationChanged',
    value: function _onFromStockLocationChanged(locationId) {
      var _this5 = this;

      this._resetData();
      this.toStockLocations = this.stockLocations.filter(function (item) {
        return item.id !== locationId;
      });

      this.DrugBatchesResource.index({ location_id: locationId, expiry_mode: 'non-expired', quantity_mode: 'non-empty', include: 'drug' }).then(function (drugBatches) {
        _this5.drugMap = _this5._mapDrugs(drugBatches);
        _this5.drugs = Object.values(_this5.drugMap).map(function (item) {
          return { id: item.id, name: item.name };
        });
      }).catch(console.log.bind(console));
    }
  }, {
    key: '_mapDrugs',
    value: function _mapDrugs(batches) {
      return batches.reduce(function (acc, item) {
        var drugId = item.drug.id;
        if (!acc.hasOwnProperty(drugId)) {
          acc[drugId] = { id: drugId, name: item.drug.name, expiryMap: {}, expiries: [] };
        }

        var expiryMap = acc[drugId].expiryMap;
        var expiries = acc[drugId].expiries;
        var expiry = item.expires_on.split(' ')[0];
        if (!expiryMap.hasOwnProperty(expiry)) {
          expiryMap[expiry] = [];
          expiries.push(expiry);
        }

        expiryMap[expiry].push(item);

        return acc;
      }, {});
    }
  }]);

  return TransferBatchController;
}(_resourceDialogController2.default);

exports.default = TransferBatchController;

},{"angular":"angular","common/resource/controllers/resourceDialogController":56,"common/resource/module.constants":57,"moment":"moment"}],153:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _resourceDialog = require('common/resource/services/resourceDialog');

var _resourceDialog2 = _interopRequireDefault(_resourceDialog);

var _transferBatchDialog = require('./transfer-batch-dialog.controller');

var _transferBatchDialog2 = _interopRequireDefault(_transferBatchDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var TransferBatchDialog = function (_ResourceDialog) {
  _inherits(TransferBatchDialog, _ResourceDialog);

  function TransferBatchDialog() {
    _classCallCheck(this, TransferBatchDialog);

    return _possibleConstructorReturn(this, (TransferBatchDialog.__proto__ || Object.getPrototypeOf(TransferBatchDialog)).apply(this, arguments));
  }

  _createClass(TransferBatchDialog, [{
    key: 'init',
    value: function init() {
      _get(TransferBatchDialog.prototype.__proto__ || Object.getPrototypeOf(TransferBatchDialog.prototype), 'init', this).call(this);
      this.setController(_transferBatchDialog2.default);
      this.setTemplateUrl('core/drugs/services/transfer-batch-dialog/transfer-batch-dialog.tpl.html');
    }
  }]);

  return TransferBatchDialog;
}(_resourceDialog2.default);

exports.default = TransferBatchDialog;

},{"./transfer-batch-dialog.controller":152,"common/resource/services/resourceDialog":59}],154:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/* global requestAnimationFrame */

function collapse(element) {
  var sectionHeight = element.scrollHeight;
  var elementTransition = element.style.transition;
  element.style.transition = '';
  requestAnimationFrame(function () {
    element.style.height = sectionHeight + 'px';
    element.style.transition = elementTransition;
    requestAnimationFrame(function () {
      element.style.height = 0 + 'px';
    });
  });
  element.setAttribute('aria-expanded', 'true');
}

function expand(element) {
  var sectionHeight = element.scrollHeight;
  element.style.height = sectionHeight + 'px';
  var eventListener = function eventListener(e) {
    element.removeEventListener('transitionend', eventListener);
    element.style.height = null;
  };
  element.addEventListener('transitionend', eventListener);
  element.setAttribute('aria-expanded', 'false');
}

var EquipmentLocationCompartmentController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function EquipmentLocationCompartmentController($element, $timeout) {
    _classCallCheck(this, EquipmentLocationCompartmentController);

    this.$element = $element;
    this.$timeout = $timeout;
  }

  _createClass(EquipmentLocationCompartmentController, [{
    key: '$onInit',
    value: function $onInit() {
      this.contentsToggled = false;
      this.nested = this.nested || false;
    }
  }, {
    key: '$postLink',
    value: function $postLink() {
      this.content = this.$element[0].querySelector('.c-compartment__content');
      collapse(this.content);
    }
  }, {
    key: 'toggleContents',
    value: function toggleContents() {
      this.contentsToggled = !this.contentsToggled;
      if (this.contentsToggled) {
        expand(this.content);
      } else {
        collapse(this.content);
      }
    }
  }]);

  return EquipmentLocationCompartmentController;
}();

var componentName = 'equipment-location-compartment';
exports.default = {
  bindings: {
    compartment: '<',
    nested: '<'
  },
  controller: EquipmentLocationCompartmentController,
  templateUrl: 'core/equipment-locations/components/' + componentName + '/' + componentName + '.tpl.html'
};

},{}],155:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _equipmentLocationCompartment = require('./components/equipment-location-compartment/equipment-location-compartment.component');

var _equipmentLocationCompartment2 = _interopRequireDefault(_equipmentLocationCompartment);

var _equipmentLocation = require('./resources/equipment-location.resource');

var _equipmentLocation2 = _interopRequireDefault(_equipmentLocation);

var _equipmentLocationDialog = require('./services/equipment-location-dialog/equipment-location-dialog.service');

var _equipmentLocationDialog2 = _interopRequireDefault(_equipmentLocationDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _module = _angular2.default.module('gnaas.core.equipment-locations', []).component('equipmentLocationCompartment', _equipmentLocationCompartment2.default).service('EquipmentLocationDialog', _equipmentLocationDialog2.default).service('EquipmentLocationResource', _equipmentLocation2.default);

exports.default = _module.name;

},{"./components/equipment-location-compartment/equipment-location-compartment.component":154,"./resources/equipment-location.resource":156,"./services/equipment-location-dialog/equipment-location-dialog.service":159,"angular":"angular"}],156:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parse = exports.format = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

exports.formatCompartmentItem = formatCompartmentItem;
exports.formatCompartment = formatCompartment;
exports.parseCompartmentItem = parseCompartmentItem;
exports.parseCompartment = parseCompartment;

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function formatCompartmentItem(item) {
  var i = {
    id: item.id,
    item_type: item.item_type,
    item_id: item.item_id,
    item_name: item.item_name,
    quantity: item.quantity
  };
  if (item.item) {
    i.item = {
      id: item.item.data.id,
      name: item.item.data.name
    };
  }
  if (item.compartment) {
    i.compartment = formatCompartment(item.compartment.data);
  }
  return i;
}

function formatCompartment(item) {
  var i = {
    id: item.id,
    name: item.name
  };
  // if (item.parent_id) i.parent_id = item.parent_id;
  if (item.compartments) i.compartments = item.compartments.data.map(formatCompartment);
  if (item.items) i.items = item.items.data.map(formatCompartmentItem);
  if (item.location) i.location = _format(item.location.data);
  return i;
}

function _format(item) {
  var i = {
    id: item.id,
    name: item.name
  };
  if (item.compartments) i.compartments = item.compartments.data.map(formatCompartment);
  return i;
}

exports.format = _format;
function parseCompartmentItem(item) {
  return {
    id: item.id,
    item_id: item.item_id,
    item_type: item.item_type,
    quantity: item.quantity
  };
}

function parseCompartment(item) {
  var i = {
    id: item.id,
    name: item.name
  };
  if (item.compartments) i.compartments = item.compartments.map(parseCompartment);
  if (item.items) i.items = item.items.map(parseCompartmentItem);
  return i;
}

function _parse(item) {
  var i = {
    id: item.id,
    name: item.name
  };
  if (item.compartments) i.compartments = item.compartments.map(parseCompartment);
  return i;
}

exports.parse = _parse;

var EquipmentLocationResource = function (_RestResource) {
  _inherits(EquipmentLocationResource, _RestResource);

  function EquipmentLocationResource() {
    _classCallCheck(this, EquipmentLocationResource);

    return _possibleConstructorReturn(this, (EquipmentLocationResource.__proto__ || Object.getPrototypeOf(EquipmentLocationResource)).apply(this, arguments));
  }

  _createClass(EquipmentLocationResource, [{
    key: 'init',
    value: function init() {
      _get(EquipmentLocationResource.prototype.__proto__ || Object.getPrototypeOf(EquipmentLocationResource.prototype), 'init', this).call(this);
      this.resourcePath = 'equipment-locations';
    }
  }, {
    key: 'format',
    value: function format(item) {
      return _format(item);
    }
  }, {
    key: 'parse',
    value: function parse(item) {
      return _parse(item);
    }
  }]);

  return EquipmentLocationResource;
}(_restResource2.default);

exports.default = EquipmentLocationResource;

},{"common/resource/services/restResource":60}],157:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _nodeUuid = require('node-uuid');

var _nodeUuid2 = _interopRequireDefault(_nodeUuid);

var _angular = require('angular');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var TPL = 'core/equipment-locations/services/equipment-location-dialog/compartment-dialog.tpl.html';

var DIALOGS_OPEN = 0;

var CompartmentDialogController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function CompartmentDialogController(Dialog) {
    _classCallCheck(this, CompartmentDialogController);

    this.Dialog = Dialog;
    this.ID = _nodeUuid2.default.v4();
    this.item = Object.assign({ compartments: [], items: [] }, this.item);

    if (this.locals.parent) {
      this.item.parent = this.locals.parent;
      this.breadcrumbs = this._getParentBreadcrumbs(this.locals.parent).join(' &gt; ');
    }
  }

  _createClass(CompartmentDialogController, [{
    key: '_getParentBreadcrumbs',
    value: function _getParentBreadcrumbs(parent) {
      var crumbs = [];
      crumbs.push(parent.name);
      if (parent.parent) {
        crumbs = [].concat(_toConsumableArray(this._getParentBreadcrumbs(parent.parent)), _toConsumableArray(crumbs));
      }
      return crumbs;
    }
  }, {
    key: 'addItem',
    value: function addItem() {
      this.item.items = [].concat(_toConsumableArray(this.item.items), [{ id: _nodeUuid2.default.v4(), quantity: null }]);
    }
  }, {
    key: 'removeItem',
    value: function removeItem(item) {
      this.item.items = this.item.items.filter(function (i) {
        return i.id !== item.id;
      });
    }
  }, {
    key: 'submit',
    value: function submit(formData) {
      if (this.form.$invalid) return;
      if (!formData.id) formData.id = this.ID;
      if (this.locals.parent && !this.item.parent_id) this.item.parent_id = this.locals.parent.id;
      this.Dialog.hide(formData);
    }
  }, {
    key: 'cancel',
    value: function cancel() {
      this.Dialog.cancel();
    }
  }, {
    key: 'delete',
    value: function _delete(id) {
      var _this = this;

      this.Dialog.confirm('Are you sure you wish to remove this compartment?').then(function () {
        _this.Dialog.cancel(id);
      });
    }
  }, {
    key: 'showCompartmentDialog',
    value: function showCompartmentDialog($event) {
      var _this2 = this;

      var item = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

      if (DIALOGS_OPEN === 2) return;
      DIALOGS_OPEN++;

      this.constructor.show({ Dialog: this.Dialog, relationData: this.locals.relationData, parent: this.item, $event: $event, item: item }).then(function (data) {
        _this2.item.compartments = !item ? [].concat(_toConsumableArray(_this2.item.compartments), [data]) : _this2.item.compartments.map(function (i) {
          return i.id === data.id ? data : i;
        });
      }).catch(function (id) {
        if (id) _this2.item.compartments = _this2.item.compartments.filter(function (c) {
          return c.id !== id;
        });
      }).finally(function () {
        DIALOGS_OPEN--;
      });
    }
  }, {
    key: 'dialogsOpen',
    get: function get() {
      return DIALOGS_OPEN;
    }
  }], [{
    key: 'show',
    value: function show(_ref) {
      var Dialog = _ref.Dialog,
          relationData = _ref.relationData,
          $event = _ref.$event,
          _ref$parent = _ref.parent,
          parent = _ref$parent === undefined ? null : _ref$parent,
          _ref$item = _ref.item,
          item = _ref$item === undefined ? null : _ref$item;

      if (!Dialog || !relationData) throw new Error('Dialog and relationData missing');
      var dialogParams = {
        controller: CompartmentDialogController,
        locals: {
          editing: typeof item !== 'undefined' && item != null,
          item: (0, _angular.copy)(item),
          parent: parent,
          relationData: relationData
        },
        targetEvent: $event,
        templateUrl: TPL,
        multiple: true
      };
      return Dialog.show(dialogParams);
    }
  }]);

  return CompartmentDialogController;
}();

exports.default = CompartmentDialogController;

},{"angular":"angular","node-uuid":"node-uuid"}],158:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _resourceDialogController = require('common/resource/controllers/resourceDialogController');

var _resourceDialogController2 = _interopRequireDefault(_resourceDialogController);

var _compartmentDialog = require('./compartment-dialog.controller');

var _compartmentDialog2 = _interopRequireDefault(_compartmentDialog);

var _nodeUuid = require('node-uuid');

var _nodeUuid2 = _interopRequireDefault(_nodeUuid);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var EquipmentLocationDialog = function (_ResourceDialogContro) {
  _inherits(EquipmentLocationDialog, _ResourceDialogContro);

  function EquipmentLocationDialog() {
    _classCallCheck(this, EquipmentLocationDialog);

    return _possibleConstructorReturn(this, (EquipmentLocationDialog.__proto__ || Object.getPrototypeOf(EquipmentLocationDialog)).apply(this, arguments));
  }

  _createClass(EquipmentLocationDialog, [{
    key: 'init',
    value: function init() {
      var _this2 = this;

      _get(EquipmentLocationDialog.prototype.__proto__ || Object.getPrototypeOf(EquipmentLocationDialog.prototype), 'init', this).call(this);
      this.ID = _nodeUuid2.default.v4();

      if (!this.item) this.item = { compartments: [] };

      this.EquipmentLocationResource = this.$injector.get('EquipmentLocationResource');
      this.ConsumableResource = this.$injector.get('ConsumableResource');
      this.DrugResource = this.$injector.get('DrugResource');

      this.itemTypes = [{ name: 'Consumable', value: 'consumable' }, { name: 'Drug', value: 'drug' }];
      this.itemTypeMap = { consumable: [], drug: [] };

      this.ConsumableResource.index().then(function (items) {
        _this2.itemTypeMap.consumable = [].concat(_toConsumableArray(items));
      });
      this.DrugResource.index().then(function (items) {
        _this2.itemTypeMap.drug = [].concat(_toConsumableArray(items));
      });
    }
  }, {
    key: 'showCompartmentDialog',
    value: function showCompartmentDialog($event, item) {
      var _this3 = this;

      var relationData = { itemTypes: this.itemTypes, itemTypeMap: this.itemTypeMap };
      _compartmentDialog2.default.show({ Dialog: this.Dialog, relationData: relationData, $event: $event, item: item }).then(function (data) {
        _this3.item.compartments = !item ? [].concat(_toConsumableArray(_this3.item.compartments), [data]) : _this3.item.compartments.map(function (i) {
          return i.id === data.id ? data : i;
        });
      }).catch(function (id) {
        if (id) _this3.item.compartments = _this3.item.compartments.filter(function (c) {
          return c.id !== id;
        });
      });
    }
  }, {
    key: 'create',
    value: function create(formData) {
      var _this4 = this;

      formData.id = this.ID;
      this.EquipmentLocationResource.create(formData).then(function () {
        _this4.Toast.showSimple('Equipment location created.');
        _this4.Dialog.hide(formData);
      }).catch(function () {
        _this4.Toast.showSimple('Error while creating equipment location.');
      });
    }
  }, {
    key: 'update',
    value: function update(formData) {
      var _this5 = this;

      this.EquipmentLocationResource.update(formData.id, formData, { include: 'compartments.items,compartments.compartments' }).then(function (data) {
        _this5.Toast.showSimple('Equipment location updated.');
        _this5.Dialog.hide(data);
      }).catch(function () {
        _this5.Toast.showSimple('Error while updating equipment location.');
      });
    }
  }, {
    key: 'delete',
    value: function _delete(id) {
      var _this6 = this;

      this.Dialog.confirm().then(function () {
        _this6.EquipmentLocationResource.destroy(id).then(function () {
          _this6.Toast.showSimple('Equipment location deleted.');
          _this6.Dialog.cancel(id);
        }).catch(function () {
          _this6.Toast.showSimple('Error while deleting equipment location.');
        });
      });
    }
  }]);

  return EquipmentLocationDialog;
}(_resourceDialogController2.default);

exports.default = EquipmentLocationDialog;

},{"./compartment-dialog.controller":157,"common/resource/controllers/resourceDialogController":56,"node-uuid":"node-uuid"}],159:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _resourceDialog = require('common/resource/services/resourceDialog');

var _resourceDialog2 = _interopRequireDefault(_resourceDialog);

var _equipmentLocationDialog = require('./equipment-location-dialog.controller');

var _equipmentLocationDialog2 = _interopRequireDefault(_equipmentLocationDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var EquipmentLocationDialog = function (_ResourceDialog) {
  _inherits(EquipmentLocationDialog, _ResourceDialog);

  function EquipmentLocationDialog() {
    _classCallCheck(this, EquipmentLocationDialog);

    return _possibleConstructorReturn(this, (EquipmentLocationDialog.__proto__ || Object.getPrototypeOf(EquipmentLocationDialog)).apply(this, arguments));
  }

  _createClass(EquipmentLocationDialog, [{
    key: 'init',
    value: function init() {
      _get(EquipmentLocationDialog.prototype.__proto__ || Object.getPrototypeOf(EquipmentLocationDialog.prototype), 'init', this).call(this);
      this.setController(_equipmentLocationDialog2.default);
      this.setTemplateUrl('core/equipment-locations/services/equipment-location-dialog/equipment-location-dialog.tpl.html');
    }
  }]);

  return EquipmentLocationDialog;
}(_resourceDialog2.default);

exports.default = EquipmentLocationDialog;

},{"./equipment-location-dialog.controller":158,"common/resource/services/resourceDialog":59}],160:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  bindings: {
    incident: '<',
    statusDialog: '&'
  },
  controller: function controller() {
    this.getLatestDeploymentStatus = function (statuses) {
      return statuses[statuses.length - 1];
    };
  },
  templateUrl: 'core/incidents/components/incident-log/incident-log.tpl.html'
};

},{}],161:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var IncidentSummaryController = exports.IncidentSummaryController = function () {
  function IncidentSummaryController() {
    _classCallCheck(this, IncidentSummaryController);
  }

  _createClass(IncidentSummaryController, [{
    key: 'hasPrf',
    value: function hasPrf() {
      if (!this.incident) return false;
      return this.incident.deployments.length && this.incident.deployments[0].patient_report_forms.length;
    }
  }, {
    key: 'getDateOfBirth',
    value: function getDateOfBirth(dob) {
      if (!dob) return null;
      return dob.getFullYear() + '-' + ('0' + (dob.getMonth() + 1)).slice(-2) + '-' + ('0' + dob.getDate()).slice(-2);
    }
  }, {
    key: 'getPatientInfo',
    value: function getPatientInfo() {
      var prf = this.incident.deployments[0].patient_report_forms[0];
      var dob = this.getDateOfBirth(prf.date_of_birth);

      return (prf.first_name || '') + ' ' + (prf.last_name || '') + ' / ' + (dob || '') + ' / ' + (prf.nhs_number || '');
    }
  }]);

  return IncidentSummaryController;
}();

exports.default = {
  bindings: {
    incident: '<'
  },
  templateUrl: 'core/incidents/components/incident-summary/incident-summary.tpl.html',
  controller: IncidentSummaryController
};

},{}],162:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  bindings: {
    item: '<'
  },
  templateUrl: 'core/incidents/components/incident/incident.tpl.html'
};

},{}],163:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _incident = require('./components/incident/incident.component');

var _incident2 = _interopRequireDefault(_incident);

var _flagIncidentDialog = require('./services/flag-incident-dialog/flag-incident-dialog.service');

var _flagIncidentDialog2 = _interopRequireDefault(_flagIncidentDialog);

var _flaggedIncident = require('./resources/flagged-incident.resource');

var _flaggedIncident2 = _interopRequireDefault(_flaggedIncident);

var _incidentDialog = require('./services/incident-dialog/incident-dialog.service');

var _incidentDialog2 = _interopRequireDefault(_incidentDialog);

var _resolveIncidentDialog = require('./services/resolve-incident-dialog/resolve-incident-dialog.service');

var _resolveIncidentDialog2 = _interopRequireDefault(_resolveIncidentDialog);

var _incidentLog = require('./components/incident-log/incident-log.component');

var _incidentLog2 = _interopRequireDefault(_incidentLog);

var _incident3 = require('./resources/incident.resource');

var _incident4 = _interopRequireDefault(_incident3);

var _incidentSummary = require('./components/incident-summary/incident-summary.component');

var _incidentSummary2 = _interopRequireDefault(_incidentSummary);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _module = _angular2.default.module('gnaas.core.incidents', []).service('FlaggedIncidentDialog', _flagIncidentDialog2.default).service('FlaggedIncidentResource', _flaggedIncident2.default).service('IncidentDialog', _incidentDialog2.default).service('IncidentResource', _incident4.default).service('ResolveIncidentDialog', _resolveIncidentDialog2.default).component('incident', _incident2.default).component('incidentLog', _incidentLog2.default).component('incidentSummary', _incidentSummary2.default);

exports.default = _module.name;

},{"./components/incident-log/incident-log.component":160,"./components/incident-summary/incident-summary.component":161,"./components/incident/incident.component":162,"./resources/flagged-incident.resource":164,"./resources/incident.resource":165,"./services/flag-incident-dialog/flag-incident-dialog.service":167,"./services/incident-dialog/incident-dialog.service":169,"./services/resolve-incident-dialog/resolve-incident-dialog.service":171,"angular":"angular"}],164:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parse = exports.format = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _incident = require('./incident.resource');

var _crewMemberResource = require('pages/crewMembers/services/crewMemberResource');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _format(item) {
  var i = {
    id: item.id,
    comment: item.comment,
    flagged_on_time: (0, _moment2.default)(item.flagged_on_time).format('YYYY-MM-DD HH:mm:ss'),
    resolved_comment: item.resolved_comment,
    resolved: !!item.resolved,
    resolved_on_time: item.resolved_on_time ? (0, _moment2.default)(item.resolved_on_time).format('YYYY-MM-DD HH:mm:ss') : null
  };

  if (item.incident) i.incident = (0, _incident.format)(item.incident.data);
  if (item.flaggedBy) i.flagged_by = (0, _crewMemberResource.format)(item.flaggedBy.data);
  if (item.resolvedBy) i.resolved_by = (0, _crewMemberResource.format)(item.resolvedBy.data);

  return i;
}

exports.format = _format;
function _parse(item) {
  var i = {
    id: item.id,
    comment: item.comment,
    flagged_on_time: (0, _moment2.default)(item.flagged_on_time).format('YYYY-MM-DD HH:mm:ss'),
    resolved_comment: item.resolved_comment,
    resolved: item.resolved,
    resolved_on_time: (0, _moment2.default)(item.resolved_on_time).format('YYYY-MM-DD HH:mm:ss')
  };

  if (item.incident) {
    i.incident_id = item.incident.id;
  }

  return i;
}

exports.parse = _parse;

var IncidentResource = function (_RestResource) {
  _inherits(IncidentResource, _RestResource);

  function IncidentResource() {
    _classCallCheck(this, IncidentResource);

    return _possibleConstructorReturn(this, (IncidentResource.__proto__ || Object.getPrototypeOf(IncidentResource)).apply(this, arguments));
  }

  _createClass(IncidentResource, [{
    key: 'init',
    value: function init() {
      _get(IncidentResource.prototype.__proto__ || Object.getPrototypeOf(IncidentResource.prototype), 'init', this).call(this);
      this.resourcePath = 'flagged-incidents';
    }
  }, {
    key: 'format',
    value: function format(item) {
      return _format(item);
    }
  }, {
    key: 'parse',
    value: function parse(item) {
      return _parse(item);
    }
  }]);

  return IncidentResource;
}(_restResource2.default);

exports.default = IncidentResource;

},{"./incident.resource":165,"common/resource/services/restResource":60,"moment":"moment","pages/crewMembers/services/crewMemberResource":362}],165:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.format = exports.parse = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

var _deployment = require('core/deployments/resources/deployment.resource');

var _flaggedIncident = require('core/incidents/resources/flagged-incident.resource');

var _baseResource = require('pages/admin/bases/services/baseResource');

var _hospitalResource = require('pages/admin/hospitals/services/hospitalResource');

var _module = require('common/resource/module.constants');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _parse(item) {
  var parsed = {
    id: item.id,
    incident_number: item.incident_number,
    date: (0, _moment2.default)(item.date).format(_module.DATE_FORMAT),
    status: item.status,
    comment: item.comment || null,
    referrer_name: item.referrer_name,
    referrer_contact_no: item.referrer_contact_no,
    patients_named_consultant: item.patients_named_consultant || null,
    ambulance_reference_number: item.ambulance_reference_number || null
  };

  parsed.times = {};
  if (item.times.call.time) {
    parsed.times.call = (0, _moment2.default)(item.times.call.time).format(_module.DATE_TIME_FORMAT);
  }
  if (item.times.accepted.time) {
    parsed.times.accepted = (0, _moment2.default)(item.times.accepted.time).format(_module.DATE_TIME_FORMAT);
  }
  if (item.times.refused.time) {
    parsed.times.refused = (0, _moment2.default)(item.times.refused.time).format(_module.DATE_TIME_FORMAT);
  }

  if (item.deployment_reason) {
    parsed.deployment_reason_id = item.deployment_reason.id;
  }

  if (item.service_area) {
    parsed.service_area_id = item.service_area.id;
  }

  if (item.ambulance_service) {
    parsed.ambulance_service_id = item.ambulance_service.id;
  }

  if (item.refusal_reason) {
    parsed.refusal_reason_id = item.refusal_reason.id;
  }

  if (item.base) {
    parsed.base_id = item.base.id;
  }

  if (item.referring_hospital) {
    parsed.referring_hospital_id = item.referring_hospital.id;
  }

  if (item.referrer_grade) {
    parsed.referrer_grade_id = item.referrer_grade.id;
  }

  if (item.referrer_ward) {
    parsed.referrer_ward_id = item.referrer_ward.id;
  }

  if (item.referrer_specialty) {
    parsed.referrer_specialty_id = item.referrer_specialty.id;
  }

  return parsed;
}

exports.parse = _parse;
function _format(item) {
  var formatted = {
    id: item.id,
    incident_number: item.incident_number,
    date: item.hasOwnProperty('date') ? (0, _moment2.default)(item.date, _module.DATE_FORMAT) : null,
    status: item.hasOwnProperty('status') ? item.status : null,
    comment: item.hasOwnProperty('comment') ? item.comment : null,
    referrer_name: item.referrer_name,
    referrer_contact_no: item.referrer_contact_no,
    patients_named_consultant: item.patients_named_consultant,
    ambulance_reference_number: item.ambulance_reference_number
  };

  if (item.location) {
    formatted.location = {
      lat: item.location.lat,
      lng: item.location.lng
    };
  }

  if (item.times) {
    formatted.times = {};
    if (item.times.call) {
      formatted.times.call = item.times.call;
    }
    if (item.times.accepted) {
      formatted.times.accepted = item.times.accepted;
    }
    if (item.times.refused) {
      formatted.times.refused = item.times.refused;
    }
    Object.keys(formatted.times).forEach(function (key) {
      formatted.times[key].time = (0, _moment2.default)(formatted.times[key].time, _module.DATE_TIME_FORMAT).startOf('minute');
    });
  }

  if (item.deploymentReason) {
    formatted.deployment_reason = item.deploymentReason.data;
  }

  if (item.ambulanceService) {
    formatted.ambulance_service = item.ambulanceService.data;
  }

  if (item.refusalReason) {
    formatted.refusal_reason = item.refusalReason.data;
  }

  if (item.serviceArea) {
    formatted.service_area = item.serviceArea.data;
  }

  if (item.base) {
    formatted.base = (0, _baseResource.format)(item.base.data);
  }

  if (item.referringHospital) {
    formatted.referring_hospital = (0, _hospitalResource.format)(item.referringHospital.data);
  }

  if (item.referrerGrade) {
    formatted.referrer_grade = item.referrerGrade.data;
  }

  if (item.referrerWard) {
    formatted.referrer_ward = item.referrerWard.data;
  }

  if (item.referrerSpecialty) {
    formatted.referrer_specialty = item.referrerSpecialty.data;
  }

  if (item.deployments) {
    formatted.deployments = item.deployments.data.map(_deployment.format);
  }

  if (item.flaggedIncidents) {
    formatted.flagged_incidents = item.flaggedIncidents.data.map(_flaggedIncident.format);
  }

  return formatted;
}

exports.format = _format;

var IncidentResource = function (_RestResource) {
  _inherits(IncidentResource, _RestResource);

  function IncidentResource() {
    _classCallCheck(this, IncidentResource);

    return _possibleConstructorReturn(this, (IncidentResource.__proto__ || Object.getPrototypeOf(IncidentResource)).apply(this, arguments));
  }

  _createClass(IncidentResource, [{
    key: 'init',
    value: function init() {
      _get(IncidentResource.prototype.__proto__ || Object.getPrototypeOf(IncidentResource.prototype), 'init', this).call(this);
      this.resourcePath = 'incidents';
    }
  }, {
    key: 'parse',
    value: function parse(item) {
      return _parse(item);
    }
  }, {
    key: 'format',
    value: function format(item) {
      return _format(item);
    }
  }]);

  return IncidentResource;
}(_restResource2.default);

exports.default = IncidentResource;

},{"common/resource/module.constants":57,"common/resource/services/restResource":60,"core/deployments/resources/deployment.resource":125,"core/incidents/resources/flagged-incident.resource":164,"moment":"moment","pages/admin/bases/services/baseResource":266,"pages/admin/hospitals/services/hospitalResource":324}],166:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _nodeUuid = require('node-uuid');

var _nodeUuid2 = _interopRequireDefault(_nodeUuid);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var FlagIncidentDialogController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function FlagIncidentDialogController(Dialog, FlaggedIncidentResource) {
    _classCallCheck(this, FlagIncidentDialogController);

    this.Dialog = Dialog;
    this.Resource = FlaggedIncidentResource;
    this._ID = _nodeUuid2.default.v4();

    if (!this.item) {
      this.item = {
        incident: this.locals.incident
      };
    }
  }

  _createClass(FlagIncidentDialogController, [{
    key: 'submit',
    value: function submit(formData) {
      var _this = this;

      if (!this.form || this.form.$invalid) {
        return;
      }

      this.Dialog.confirm('Are you sure you want to flag this referral?').then(function () {
        formData.id = _this._ID;
        formData.flagged_on_time = new Date();

        _this.Resource.create(formData).then(function () {
          _this.Dialog.hide(formData);
        });
      });
    }
  }, {
    key: 'cancel',
    value: function cancel() {
      this.Dialog.cancel();
    }
  }]);

  return FlagIncidentDialogController;
}();

exports.default = FlagIncidentDialogController;

},{"node-uuid":"node-uuid"}],167:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _flagIncidentDialog = require('./flag-incident-dialog.controller');

var _flagIncidentDialog2 = _interopRequireDefault(_flagIncidentDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var FlagIncidentDialog = function () {
  /**
   * @constructor
   * @ngInject
   */
  function FlagIncidentDialog(Dialog) {
    _classCallCheck(this, FlagIncidentDialog);

    this.Dialog = Dialog;
    this.dialogParams = {
      controller: _flagIncidentDialog2.default,
      templateUrl: 'core/incidents/services/flag-incident-dialog/flag-incident-dialog.tpl.html'
    };
  }

  _createClass(FlagIncidentDialog, [{
    key: 'show',
    value: function show(_ref) {
      var $event = _ref.$event,
          incident = _ref.incident;

      var dialogParams = Object.assign({}, this.dialogParams, {
        targetEvent: $event || null,
        multiple: true,
        locals: { incident: incident }
      });
      return this.Dialog.show(dialogParams);
    }
  }]);

  return FlagIncidentDialog;
}();

exports.default = FlagIncidentDialog;

},{"./flag-incident-dialog.controller":166}],168:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _angular = require('angular');

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _nodeUuid = require('node-uuid');

var _nodeUuid2 = _interopRequireDefault(_nodeUuid);

var _resourceDialogController = require('common/resource/controllers/resourceDialogController');

var _resourceDialogController2 = _interopRequireDefault(_resourceDialogController);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var generateIncident = function generateIncident(date) {
  return {
    date: date,
    status: 'pending',
    times: {
      call: { date: date, time: date }
    }
  };
};

var formatIncident = function formatIncident(item) {
  return Object.assign({}, item, {
    date: (0, _moment2.default)(item.date).toDate(),
    times: Object.keys(item.times).reduce(function (acc, cur) {
      var datetime = (0, _moment2.default)(item.times[cur].time).toDate();
      return Object.assign({}, acc, _defineProperty({}, cur, Object.assign({}, item.times[cur], { time: datetime, date: datetime })));
    }, {})
  });
};

var IncidentDialogController = function (_ResourceDialogContro) {
  _inherits(IncidentDialogController, _ResourceDialogContro);

  function IncidentDialogController() {
    _classCallCheck(this, IncidentDialogController);

    return _possibleConstructorReturn(this, (IncidentDialogController.__proto__ || Object.getPrototypeOf(IncidentDialogController)).apply(this, arguments));
  }

  _createClass(IncidentDialogController, [{
    key: 'init',
    value: function init() {
      var _this2 = this;

      _get(IncidentDialogController.prototype.__proto__ || Object.getPrototypeOf(IncidentDialogController.prototype), 'init', this).call(this);
      this._timeout = this.$injector.get('$timeout');
      this._scope = this.$injector.get('$rootScope').$new();
      this._resource = this.$injector.get('IncidentResource');
      this._DeploymentResource = this.$injector.get('DeploymentResource');
      this.APP_ORG_SHORT = this.$injector.get('APP_ORG_SHORT');
      this._INCIDENT_ID = _nodeUuid2.default.v4();

      this.timeLabelMap = {
        accepted: 'Task Accepted',
        declined: 'Task Declined',
        call: 'Time to ' + this.APP_ORG_SHORT
      };

      this.today = (0, _moment2.default)().startOf('minute').toDate();

      this.item = this.item ? formatIncident(this.item) : generateIncident(this.today);

      this._scope.$watchGroup([function () {
        return _this2.item.referring_hospital ? _this2.item.referring_hospital : null;
      }, function () {
        return _this2.item.times && _this2.item.times.call ? _this2.item.times.call.time : null;
      }, function () {
        return _this2.item.date ? _this2.item.date : null;
      }], this._updateIncidentValues.bind(this));

      this.has_ambulance_reference_number = false;
      this._scope.$watch(function () {
        return _this2.item.ambulance_service;
      }, function () {
        if (!_this2.item.ambulance_service) {
          return;
        }
        _this2.has_ambulance_reference_number = _this2.item.ambulance_service.has_reference_number;

        if (!_this2.has_ambulance_reference_number) {
          delete _this2.item.ambulance_reference_number;
        }
      });
    }
  }, {
    key: 'submit',
    value: function submit(formData) {
      if (!this.form) {
        throw new Error('Form not found.');
      }

      if (this.form.$invalid) {
        this.Toast.showSimple(this.validationErrorMessage);
        return;
      }

      if (this.editing) {
        this.update(formData);
      } else {
        this.create(formData);
      }
    }
  }, {
    key: 'create',
    value: function create(formData) {
      var _this3 = this;

      var data = this._formatIncidentForRequest(formData, this.timeLabelMap);
      data.id = this._INCIDENT_ID;
      this._resource.create(data).then(function () {
        return _this3._DeploymentResource.create({
          id: _nodeUuid2.default.v4(),
          incident: { id: _this3._INCIDENT_ID },
          status: 'referral'
        });
      }).then(function () {
        _this3.Dialog.hide(data);
      }).catch(console.log.bind(console));
    }
  }, {
    key: 'update',
    value: function update(formData) {
      var _this4 = this;

      var data = this._formatIncidentForRequest(formData, this.timeLabelMap);
      this._resource.update(data.id, data, {
        include: ['serviceArea', 'refusalReason', 'referringHospital', 'base', 'referrerGrade', 'referrerWard', 'referrerSpecialty', 'ambulanceService', 'deploymentReason', 'deployments.vehicleCallsign', 'deployments.patientReportForms', 'deployments.deploymentStatuses.deploymentStatus', 'deployments.deploymentStatuses.destination']
      }).then(function (incident) {
        _this4.Dialog.hide(incident);
      }).catch(console.log.bind(console));
    }
  }, {
    key: 'delete',
    value: function _delete(id) {
      var _this5 = this;

      this.Dialog.promptDelete().then(function () {
        _this5._resource.destroy(id).then(function () {
          _this5.Dialog.cancel(id);
        }).catch(function (err) {
          var msg = 'Error deleting referral.';
          if (err.status && err.status === 409) {
            msg = err.data.message;
          }
          _this5.Toast.showSimple(msg);
        });
      });
    }
  }, {
    key: '_updateIncidentValues',
    value: function _updateIncidentValues() {
      var timeToOrg = 'HHmm';
      var hospitalString = 'HOS';
      if (this.item.times && this.item.times.call) {
        timeToOrg = (0, _moment2.default)(this.item.times.call.time).format('HHmm');
      }
      if (this.item.referring_hospital) {
        hospitalString = this.item.referring_hospital.abbreviation.toUpperCase();
        this.item.location = { lat: this.item.referring_hospital.lat, lng: this.item.referring_hospital.lng };
      }
      this.item = Object.assign({}, this.item, {
        incident_number: (0, _moment2.default)(this.item.date).format('YYYYMMDD') + '-' + timeToOrg + '-' + hospitalString
      });
    }
  }, {
    key: '_formatIncidentForRequest',
    value: function _formatIncidentForRequest(incident, timeLabelMap) {
      var item = (0, _angular.copy)(incident);

      item.date = (0, _moment2.default)(item.date);

      item.times = Object.keys(item.times).reduce(function (acc, cur) {
        if (item.times[cur].time) {
          var d = (0, _moment2.default)(item.times[cur].date);
          acc[cur] = { time: (0, _moment2.default)(item.times[cur].time).year(d.year()).month(d.month()).date(d.date()) };
        } else {
          acc[cur] = { time: null };
        }
        acc[cur].label = timeLabelMap[cur];
        return acc;
      }, {});

      return item;
    }
  }]);

  return IncidentDialogController;
}(_resourceDialogController2.default);

exports.default = IncidentDialogController;

},{"angular":"angular","common/resource/controllers/resourceDialogController":56,"moment":"moment","node-uuid":"node-uuid"}],169:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _resourceDialog = require('common/resource/services/resourceDialog');

var _resourceDialog2 = _interopRequireDefault(_resourceDialog);

var _incidentDialog = require('./incident-dialog.controller');

var _incidentDialog2 = _interopRequireDefault(_incidentDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var IncidentDialog = function (_ResourceDialog) {
  _inherits(IncidentDialog, _ResourceDialog);

  function IncidentDialog() {
    _classCallCheck(this, IncidentDialog);

    return _possibleConstructorReturn(this, (IncidentDialog.__proto__ || Object.getPrototypeOf(IncidentDialog)).apply(this, arguments));
  }

  _createClass(IncidentDialog, [{
    key: 'init',
    value: function init() {
      _get(IncidentDialog.prototype.__proto__ || Object.getPrototypeOf(IncidentDialog.prototype), 'init', this).call(this);
      this.setController(_incidentDialog2.default);
      this.setTemplateUrl('core/incidents/services/incident-dialog/incident-dialog.tpl.html');
    }
  }]);

  return IncidentDialog;
}(_resourceDialog2.default);

exports.default = IncidentDialog;

},{"./incident-dialog.controller":168,"common/resource/services/resourceDialog":59}],170:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var ResolveIncidentDialogController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function ResolveIncidentDialogController(Dialog, FlaggedIncidentResource) {
    _classCallCheck(this, ResolveIncidentDialogController);

    this.Dialog = Dialog;
    this.Resource = FlaggedIncidentResource;
  }

  _createClass(ResolveIncidentDialogController, [{
    key: 'submit',
    value: function submit(formData) {
      var _this = this;

      if (!this.form || this.form.$invalid) {
        return;
      }

      this.Dialog.confirm('Are you sure you want to resolve this flag?').then(function () {
        formData.resolved = true;
        formData.resolved_on_time = new Date();

        _this.Resource.update(formData.id, formData, { include: 'incident,flaggedBy,resolvedBy' }).then(function (incident) {
          _this.Dialog.hide(incident);
        });
      });
    }
  }, {
    key: 'cancel',
    value: function cancel() {
      this.Dialog.cancel();
    }
  }]);

  return ResolveIncidentDialogController;
}();

exports.default = ResolveIncidentDialogController;

},{}],171:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _resolveIncidentDialog = require('./resolve-incident-dialog.controller');

var _resolveIncidentDialog2 = _interopRequireDefault(_resolveIncidentDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var FlagIncidentDialog = function () {
  /**
   * @constructor
   * @ngInject
   */
  function FlagIncidentDialog(Dialog) {
    _classCallCheck(this, FlagIncidentDialog);

    this.Dialog = Dialog;
    this.dialogParams = {
      controller: _resolveIncidentDialog2.default,
      templateUrl: 'core/incidents/services/resolve-incident-dialog/resolve-incident-dialog.tpl.html'
    };
  }

  _createClass(FlagIncidentDialog, [{
    key: 'show',
    value: function show(_ref) {
      var $event = _ref.$event,
          item = _ref.item;

      var dialogParams = Object.assign({}, this.dialogParams, {
        targetEvent: $event || null,
        multiple: true,
        locals: { item: item }
      });
      return this.Dialog.show(dialogParams);
    }
  }]);

  return FlagIncidentDialog;
}();

exports.default = FlagIncidentDialog;

},{"./resolve-incident-dialog.controller":170}],172:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _module2 = require('./categories/module');

var _module3 = _interopRequireDefault(_module2);

var _module4 = require('./consumables/module');

var _module5 = _interopRequireDefault(_module4);

var _module6 = require('./deployments/module');

var _module7 = _interopRequireDefault(_module6);

var _module8 = require('./devices/module');

var _module9 = _interopRequireDefault(_module8);

var _module10 = require('./drugs/module');

var _module11 = _interopRequireDefault(_module10);

var _module12 = require('./equipment-locations/module');

var _module13 = _interopRequireDefault(_module12);

var _module14 = require('./incidents/module');

var _module15 = _interopRequireDefault(_module14);

var _module16 = require('./prf/module');

var _module17 = _interopRequireDefault(_module16);

var _module18 = require('./reports/module');

var _module19 = _interopRequireDefault(_module18);

var _module20 = require('./search/module');

var _module21 = _interopRequireDefault(_module20);

var _module22 = require('./shifts/module');

var _module23 = _interopRequireDefault(_module22);

var _module24 = require('./vehicles/module');

var _module25 = _interopRequireDefault(_module24);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _module = _angular2.default.module('gnaas.core', [_module3.default, _module5.default, _module7.default, _module9.default, _module11.default, _module13.default, _module15.default, _module17.default, _module19.default, _module21.default, _module23.default, _module25.default]);

exports.default = _module.name;

},{"./categories/module":82,"./consumables/module":113,"./deployments/module":123,"./devices/module":132,"./drugs/module":136,"./equipment-locations/module":155,"./incidents/module":163,"./prf/module":184,"./reports/module":236,"./search/module":247,"./shifts/module":252,"./vehicles/module":260,"angular":"angular"}],173:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var AdministeredDrugsController = function () {
  function AdministeredDrugsController() {
    _classCallCheck(this, AdministeredDrugsController);
  }

  _createClass(AdministeredDrugsController, [{
    key: '$onInit',
    value: function $onInit() {
      this.clearSearch();
    }
  }, {
    key: 'getAdministeredDrugMap',
    value: function getAdministeredDrugMap(administeredDrugs) {
      return administeredDrugs.reduce(function (drugMap, drug) {
        return Object.assign({}, drugMap, _defineProperty({}, drug.id, {
          prescription: drug.prescription
        }));
      }, {});
    }
  }, {
    key: '$onChanges',
    value: function $onChanges(changes) {
      if (changes.administeredDrugs) {
        this.drugMap = this.getAdministeredDrugMap(changes.administeredDrugs.currentValue);
      }
    }
  }, {
    key: 'onDrugChange',
    value: function onDrugChange(drug) {
      this.drugMap = this.getAdministeredDrugMap(this.administeredDrugs);

      if (!drug || !drug.prescription) return;
      drug.dose = drug.prescription.dose;
      drug.rate = 0;
    }
  }, {
    key: 'initSearch',
    value: function initSearch(index) {
      this.openIndex = index;
    }
  }, {
    key: 'clearSearch',
    value: function clearSearch() {
      this.openIndex = null;
      this.searchTerm = '';
    }
  }, {
    key: 'filterOptions',
    value: function filterOptions(index) {
      if (this.openIndex === index) return this.searchTerm;
    }
  }, {
    key: 'getClasses',
    value: function getClasses(name, index) {
      return {
        'has-errors': this.administeredDrugsForm[name + '_' + index].$invalid,
        't-no-padding': ~['prescription', 'date'].indexOf(name)
      };
    }
  }, {
    key: 'getSelectedName',
    value: function getSelectedName(prescription) {
      return prescription && prescription.drug ? prescription.drug.name : '-';
    }
  }, {
    key: 'getRateLimit',
    value: function getRateLimit(administeredDrugId, key) {
      if (!this.drugMap[administeredDrugId] || !this.drugMap[administeredDrugId].prescription) {
        return null;
      }

      if (key === 'min') {
        return this.drugMap[administeredDrugId].prescription.min_rate;
      }

      return this.drugMap[administeredDrugId].prescription.max_rate;
    }
  }]);

  return AdministeredDrugsController;
}();

exports.default = {
  bindings: {
    isEditing: '<',
    viewAllSurveys: '<',
    surveys: '<',
    administeredDrugs: '<',
    prescriptions: '<',
    onAddClick: '&',
    onRemoveClick: '&'
  },
  controller: AdministeredDrugsController,
  templateUrl: 'core/prf/components/administered-drugs/administered-drugs.tpl.html'
};

},{}],174:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _administeredInfusion = require('core/prf/services/administered-infusion.service');

var _dateTime = require('common/utils/services/dateTime');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var AdministeredInfusionsController = function () {
  /**
   * @ngInject
   * @constructor
   */
  function AdministeredInfusionsController(TimestampDialog) {
    _classCallCheck(this, AdministeredInfusionsController);

    this.TimestampDialog = TimestampDialog;
  }

  _createClass(AdministeredInfusionsController, [{
    key: '$onInit',
    value: function $onInit() {
      this.clearSearch();
    }
  }, {
    key: '$onChanges',
    value: function $onChanges(changes) {
      if (changes.administeredInfusions) {
        this.infusionMap = (0, _administeredInfusion.getAdministeredInfusionMap)(changes.administeredInfusions.currentValue);
      }
    }
  }, {
    key: 'onInfusionChange',
    value: function onInfusionChange() {
      this.infusionMap = (0, _administeredInfusion.getAdministeredInfusionMap)(this.administeredInfusions);
    }
  }, {
    key: 'initSearch',
    value: function initSearch(index) {
      this.openIndex = index;
    }
  }, {
    key: 'clearSearch',
    value: function clearSearch() {
      this.openIndex = null;
      this.searchTerm = '';
    }
  }, {
    key: 'filterOptions',
    value: function filterOptions(index) {
      if (this.openIndex === index) return this.searchTerm;
    }
  }, {
    key: 'hasErrors',
    value: function hasErrors(name, index) {
      var secondaryIndex = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

      var key = name + '_' + index + (secondaryIndex != null ? '_' + secondaryIndex : '');
      return this.administeredInfusionsForm[key] ? this.administeredInfusionsForm[key].$invalid : false;
    }
  }, {
    key: 'showTimestampDialog',
    value: function showTimestampDialog(detail) {
      var uniqueTimestamps = (0, _administeredInfusion.getUniqueInfusionDetailTimestamps)(this.administeredInfusions);
      this.TimestampDialog.show({
        item: {
          date: (0, _dateTime.toDateTimeObject)(detail.date),
          time: (0, _dateTime.toDateTimeObject)(detail.time)
        },
        locals: {
          validateAgainst: uniqueTimestamps,
          validationErrorMessage: 'Timestamp must be unique, this value already exists.'
        }
      }).then(function (timestamp) {
        detail.date = timestamp.date;
        detail.time = timestamp.time;
      });
    }
  }, {
    key: 'getRateLimit',
    value: function getRateLimit(administeredInfusionId, key) {
      if (!this.infusionMap[administeredInfusionId] || !this.infusionMap[administeredInfusionId].prescription) {
        return null;
      }

      var prescribedInfusionId = this.infusionMap[administeredInfusionId].prescription.id;

      if (this.infusionRangeMap && this.infusionRangeMap[prescribedInfusionId]) {
        if (key === 'min') {
          return this.infusionRangeMap[prescribedInfusionId][0];
        }

        return this.infusionRangeMap[prescribedInfusionId][1];
      }

      if (key === 'min') {
        return this.infusionMap[administeredInfusionId].prescription.min_rate;
      }

      return this.infusionMap[administeredInfusionId].prescription.max_rate;
    }
  }, {
    key: 'getSelectedName',
    value: function getSelectedName(prescription) {
      return prescription && prescription.infusion ? prescription.infusion.name : '-';
    }
  }]);

  return AdministeredInfusionsController;
}();

exports.default = {
  bindings: {
    isEditing: '<',
    viewAllSurveys: '<',
    surveys: '<',
    administeredInfusions: '<',
    administeredInfusionDetails: '<',
    infusionRangeMap: '<',
    prescriptions: '<',
    onAddInfusionClick: '&',
    onRemoveInfusionClick: '&',
    onAddDetailsClick: '&',
    onRemoveDetailsClick: '&'
  },
  controller: AdministeredInfusionsController,
  templateUrl: 'core/prf/components/administered-infusions/administered-infusions.tpl.html'
};

},{"common/utils/services/dateTime":77,"core/prf/services/administered-infusion.service":194}],175:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _observations = require('core/prf/services/observations.service');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var bpPattern = /^([01]?[0-9][0-9]?|2[0-4][0-9]|300)\/([01]?[0-9][0-9]?|2[0-4][0-9]|150)$/;
var singleDecimalPattern = /^\d{1,2}(.\d{1})?$/;
var doubleDecimalPattern = /^\d{1,2}(.\d{1,2})?$/;

var ecgOptions = [{ label: '-', value: null }, { label: 'SR', value: 'sr' }, { label: 'SB', value: 'sb' }, { label: 'ST', value: 'st' }, { label: 'SVT', value: 'svt' }, { label: 'VT', value: 'vt' }, { label: 'AF', value: 'af' }, { label: 'Arrest - asyst.', value: 'arrest-asystole' }, { label: 'Arrest - PEA', value: 'arrest-pea' }, { label: 'Arrest - VF', value: 'arrest-vf' }, { label: 'Arrest - VT', value: 'arrest-vt' }];

var breathingModeOptions = [{ label: '-', value: null }, { label: 'SV', value: 'sv' }, { label: 'FM', value: 'fm' }, { label: 'NC', value: 'nc' }, { label: 'HFNC', value: 'hfno' }, { label: 'CPAP', value: 'cpap' }, { label: 'NIV', value: 'niv' }, { label: 'IPPV (Spont)', value: 'ippv-spont' }, { label: 'IPPV (Mand)', value: 'ippv-mand' }];

var pupilSizeOptions = [{ label: '-', value: null }, { label: '1', value: '1' }, { label: '2', value: '2' }, { label: '3', value: '3' }, { label: '4', value: '4' }, { label: '5', value: '5' }, { label: '6', value: '6' }, { label: '7', value: '7' }, { label: '8', value: '8' }];

var pupilReactionOptions = [{ label: '-', value: null }, { label: 'Reactive', value: 'reactive' }, { label: 'Sluggish', value: 'sluggish' }, { label: 'Unreactive', value: 'unreactive' }];

var calculateGcs = function calculateGcs(obs) {
  if (obs.gcse == null && obs.gcsv == null && obs.gcsm == null) {
    obs.gcs = null;
    return;
  }
  obs.gcs = 0;
  if (obs.gcse !== null && !isNaN(obs.gcse)) obs.gcs += parseInt(obs.gcse, 10);
  if (obs.gcsv !== null && !isNaN(obs.gcsv)) obs.gcs += parseInt(obs.gcsv, 10);
  if (obs.gcsm !== null && !isNaN(obs.gcsm)) obs.gcs += parseInt(obs.gcsm, 10);
};

var gcsFieldRequired = function gcsFieldRequired(field, obs) {
  return ['gcse', 'gcsv', 'gcsm'].filter(function (i) {
    return i !== field.name && !isNaN(obs[i]) && obs[i] !== null;
  }).length > 0;
};

var disableDecimals = function disableDecimals($event) {
  if ($event.key === '.') $event.preventDefault();
};

var ObservationsController = function () {
  function ObservationsController() {
    _classCallCheck(this, ObservationsController);
  }

  _createClass(ObservationsController, [{
    key: '$onInit',
    value: function $onInit() {
      this.fields = [{ label: 'Date', name: 'date', type: 'date', required: true }, { label: 'Time', name: 'time', type: 'time', required: true }, { label: 'ECG', name: 'ecg', type: 'predefined', options: ecgOptions }, { label: 'HR', name: 'pulse', type: 'number', min: 0, max: 300, onKeyDown: disableDecimals }, { label: 'NIBP', name: 'nibp', type: 'string', placeholder: '###/###', pattern: bpPattern }, { label: 'NIBP (Mean)', name: 'nibp_mean', type: 'number', min: 0, max: 150, onKeyDown: disableDecimals }, { label: 'ABP', name: 'abp', type: 'string', placeholder: '###/###', pattern: bpPattern }, { label: 'MAP', name: 'abp_mean', type: 'number', min: 0, max: 200, onKeyDown: disableDecimals }, { label: 'Temp', name: 'temp', type: 'number', min: 20.0, max: 45.0, pattern: singleDecimalPattern }, { label: 'Breathing Mode', name: 'breathing_mode', type: 'predefined', options: breathingModeOptions,
        onChange: _observations.applyVentilationIntelligence }, { label: 'Oxygen', name: 'oxygen', type: 'oxygen', options: _observations.oxygenOptions }, { label: 'RR', name: 'respiratory_rate', type: 'number', min: 0, max: 100, onKeyDown: disableDecimals }, { label: 'SPO2', name: 'spo2', type: 'number', min: 0, max: 100, onKeyDown: disableDecimals }, { label: 'ETCO2', name: 'etco2', type: 'number', min: 0.0, max: 16.0, pattern: singleDecimalPattern }, { label: 'Vt', name: 'vt', type: 'number', min: 0, max: 1500, onKeyDown: disableDecimals }, { label: 'MV', name: 'mv', type: 'number', min: 0, max: 25.00, pattern: doubleDecimalPattern }, { label: 'FiO2', name: 'fio2', type: 'number', min: 0.21, max: 1.00, pattern: doubleDecimalPattern }, { label: 'Ppeak', name: 'ppeak', type: 'number', min: 0, max: 50, onKeyDown: disableDecimals }, { label: 'PEEP', name: 'peep', type: 'number', min: 0, max: 20, onKeyDown: disableDecimals }, { label: 'Psupp', name: 'psupp', type: 'number', min: 0, max: 30.00, pattern: doubleDecimalPattern }, { label: 'Ti', name: 'ti', type: 'number', min: 0, max: 2, pattern: doubleDecimalPattern }, { label: 'CPAP', name: 'cpap', type: 'number', min: 0, max: 25.00, pattern: doubleDecimalPattern }, { label: 'Flow', name: 'flow', type: 'number', min: 0, max: 80.00, pattern: doubleDecimalPattern }, { label: 'GCSE', name: 'gcse', type: 'number', min: 1, max: 4, onKeyDown: disableDecimals,
        onChange: calculateGcs, required: gcsFieldRequired }, { label: 'GCSV', name: 'gcsv', type: 'number', min: 1, max: 5, onKeyDown: disableDecimals,
        onChange: calculateGcs, required: gcsFieldRequired }, { label: 'GCSM', name: 'gcsm', type: 'number', min: 1, max: 6, onKeyDown: disableDecimals,
        onChange: calculateGcs, required: gcsFieldRequired }, { label: 'GCS', name: 'gcs', type: 'number', disabled: true }, { label: 'Left Pupil Size', name: 'pupil_size_left', type: 'predefined', options: pupilSizeOptions }, { label: 'Left Pupil Reaction', name: 'pupil_reaction_left', type: 'predefined', options: pupilReactionOptions }, { label: 'Right Pupil Size', name: 'pupil_size_right', type: 'predefined', options: pupilSizeOptions }, { label: 'Right Pupil Reaction', name: 'pupil_reaction_right', type: 'predefined',
        options: pupilReactionOptions }, { label: 'Urine output (mls)', name: 'urine_output_mls', type: 'number', min: 0, max: 5000,
        onKeyDown: disableDecimals }, { label: 'Drain output (mls)', name: 'drain_output_mls', type: 'number', min: 0, max: 5000,
        onKeyDown: disableDecimals }];
    }
  }, {
    key: 'isRequired',
    value: function isRequired(field, obs) {
      return typeof field.required === 'function' ? field.required(field, obs) : field.required;
    }
  }]);

  return ObservationsController;
}();

exports.default = {
  bindings: {
    isEditing: '<',
    viewAllSurveys: '<',
    surveys: '<',
    onAddClick: '&',
    onRemoveClick: '&'
  },
  controller: ObservationsController,
  templateUrl: 'core/prf/components/observations/observations.tpl.html'
};

},{"core/prf/services/observations.service":219}],176:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  bindings: {
    item: '<'
  },
  templateUrl: 'core/prf/components/patient-details/patient-details.tpl.html'
};

},{}],177:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var PrescribedDrugsController = function () {
  function PrescribedDrugsController() {
    _classCallCheck(this, PrescribedDrugsController);
  }

  _createClass(PrescribedDrugsController, [{
    key: '$onInit',
    value: function $onInit() {
      this.clearSearch();
    }
  }, {
    key: 'initSearch',
    value: function initSearch(index) {
      this.openIndex = index;
    }
  }, {
    key: 'clearSearch',
    value: function clearSearch() {
      this.openIndex = null;
      this.searchTerm = '';
    }
  }, {
    key: 'filterOptions',
    value: function filterOptions(index) {
      if (this.openIndex === index) return this.searchTerm;
    }
  }, {
    key: 'getClasses',
    value: function getClasses(name, index) {
      return {
        'has-errors': this.prescribedDrugsForm[name + '_' + index].$invalid,
        't-no-padding': ~['drug', 'route', 'date'].indexOf(name)
      };
    }
  }, {
    key: 'onDrugChange',
    value: function onDrugChange(drug) {
      if (!drug) return;
      drug.min_rate = 0;
      drug.max_rate = 0;
    }
  }]);

  return PrescribedDrugsController;
}();

exports.default = {
  bindings: {
    isEditing: '<',
    viewAllSurveys: '<',
    surveys: '<',
    drugs: '<',
    onAddClick: '&',
    onRemoveClick: '&',
    onAddNewDrugClick: '&'
  },
  controller: PrescribedDrugsController,
  templateUrl: 'core/prf/components/prescribed-drugs/prescribed-drugs.tpl.html'
};

},{}],178:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var PrescribedInfusionsController = function () {
  function PrescribedInfusionsController() {
    _classCallCheck(this, PrescribedInfusionsController);
  }

  _createClass(PrescribedInfusionsController, [{
    key: '$onInit',
    value: function $onInit() {
      this.clearSearch();
    }
  }, {
    key: 'initSearch',
    value: function initSearch(index) {
      this.openIndex = index;
    }
  }, {
    key: 'clearSearch',
    value: function clearSearch() {
      this.openIndex = null;
      this.searchTerm = '';
    }
  }, {
    key: 'filterOptions',
    value: function filterOptions(index) {
      if (this.openIndex === index) return this.searchTerm;
    }
  }, {
    key: 'getClasses',
    value: function getClasses(name, index) {
      return {
        'has-errors': this.prescribedInfusionsForm[name + '_' + index].$invalid,
        't-no-padding': ~['infusion', 'route', 'date'].indexOf(name)
      };
    }
  }, {
    key: 'onRangeChange',
    value: function onRangeChange(type, infusion) {
      this.handleRateChange(infusion.id, infusion.min_rate, infusion.max_rate);
    }
  }]);

  return PrescribedInfusionsController;
}();

exports.default = {
  bindings: {
    isEditing: '<',
    viewAllSurveys: '<',
    surveys: '<',
    infusions: '<',
    onAddClick: '&',
    onRemoveClick: '&',
    onAddNewInfusionClick: '&',
    handleRateChange: '<'
  },
  controller: PrescribedInfusionsController,
  templateUrl: 'core/prf/components/prescribed-infusions/prescribed-infusions.tpl.html'
};

},{}],179:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  bindings: {
    item: '<'
  },
  templateUrl: 'core/prf/components/prf-list-item/prf-list-item.tpl.html'
};

},{}],180:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  bindings: {
    isEditing: '<',
    onEditClick: '&',
    item: '<'
  },
  templateUrl: 'core/prf/components/survey-intervention-details/survey-intervention-details.tpl.html'
};

},{}],181:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  bindings: {
    type: '@',
    isEditing: '<',
    onEditClick: '&',
    item: '<'
  },
  templateUrl: 'core/prf/components/survey-item-details/survey-item-details.tpl.html'
};

},{}],182:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _laboratoryFindingsDialog = require('core/prf/services/laboratory-findings-dialog/laboratory-findings-dialog.controller');

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

exports.default = {
  bindings: {
    isEditing: '<',
    onEditClick: '&',
    item: '<'
  },
  controller: function controller() {
    'ngInject';

    var _this = this;

    this.$onInit = function () {
      _this.sections = [].concat(_toConsumableArray(_laboratoryFindingsDialog.SECTIONS));
    };
  },
  templateUrl: 'core/prf/components/survey-laboratory-findings-detail/survey-laboratory-findings-detail.tpl.html'
};

},{"core/prf/services/laboratory-findings-dialog/laboratory-findings-dialog.controller":213}],183:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var SurveySwitcherController = exports.SurveySwitcherController = function () {
  function SurveySwitcherController() {
    _classCallCheck(this, SurveySwitcherController);
  }

  _createClass(SurveySwitcherController, [{
    key: '$onChanges',
    value: function $onChanges(changes) {
      var _changes = Object.assign({}, changes),
          surveys = _changes.surveys;

      if (!surveys) return;

      var _surveys = Object.assign({}, surveys),
          previousValue = _surveys.previousValue,
          currentValue = _surveys.currentValue;

      if (!currentValue) {
        this.surveyRange = [];
        return;
      }

      this.surveyRange = Array.apply(null, { length: currentValue.length }).map(Number.call, Number);

      if (!this.isEditing) return;

      if (this.selectedIndex == null && currentValue.length > 0) {
        // Initial surveys loaded
        this.selectedIndex = 0;
        return;
      }

      if (!previousValue || !previousValue.length) return;

      if (currentValue.length > previousValue.length) {
        // New survey added
        this.selectedIndex = currentValue.length - 1;
      } else if (currentValue.length < previousValue.length) {
        // Survey deleted
        this.selectedIndex = this.selectedIndex > 0 ? this.selectedIndex - 1 : null;
      }
    }
  }, {
    key: 'select',
    value: function select(index) {
      if (this.onSelect({ $event: { index: index } })) {
        this.selectedIndex = index;
      }
    }
  }, {
    key: 'add',
    value: function add() {
      if (this.onAdd()) {
        this.selectedIndex = this.surveys.length;
      }
    }
  }]);

  return SurveySwitcherController;
}();

exports.default = {
  bindings: {
    isEditing: '<',
    surveys: '<',
    onSelect: '&',
    onAdd: '&'
  },
  controller: SurveySwitcherController,
  templateUrl: 'core/prf/components/survey-switcher/survey-switcher.tpl.html'
};

},{}],184:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _administeredDrugs = require('./components/administered-drugs/administered-drugs.component');

var _administeredDrugs2 = _interopRequireDefault(_administeredDrugs);

var _administeredInfusions = require('./components/administered-infusions/administered-infusions.component');

var _administeredInfusions2 = _interopRequireDefault(_administeredInfusions);

var _hospitalInformationDialog = require('./services/hospital-information-dialog/hospital-information-dialog.service');

var _hospitalInformationDialog2 = _interopRequireDefault(_hospitalInformationDialog);

var _illnessDialog = require('./services/illness-dialog/illness-dialog.service');

var _illnessDialog2 = _interopRequireDefault(_illnessDialog);

var _injuryDialog = require('./services/injury-dialog/injury-dialog.service');

var _injuryDialog2 = _interopRequireDefault(_injuryDialog);

var _interventionDialog = require('./services/intervention-dialog/intervention-dialog.service');

var _interventionDialog2 = _interopRequireDefault(_interventionDialog);

var _observations = require('./components/observations/observations.component');

var _observations2 = _interopRequireDefault(_observations);

var _patientDetails = require('./components/patient-details/patient-details.component');

var _patientDetails2 = _interopRequireDefault(_patientDetails);

var _patientDetailsDialog = require('./services/patient-details-dialog/patient-details-dialog.service');

var _patientDetailsDialog2 = _interopRequireDefault(_patientDetailsDialog);

var _prfListItem = require('./components/prf-list-item/prf-list-item.component');

var _prfListItem2 = _interopRequireDefault(_prfListItem);

var _prfApprovalDialog = require('./services/prf-approval-dialog/prf-approval-dialog.service');

var _prfApprovalDialog2 = _interopRequireDefault(_prfApprovalDialog);

var _prf = require('./resources/prf.resource');

var _prf2 = _interopRequireDefault(_prf);

var _printablePrf = require('./resources/printable-prf.resource');

var _printablePrf2 = _interopRequireDefault(_printablePrf);

var _surveyItemDetails = require('./components/survey-item-details/survey-item-details.component');

var _surveyItemDetails2 = _interopRequireDefault(_surveyItemDetails);

var _surveyInterventionDetails = require('./components/survey-intervention-details/survey-intervention-details.component');

var _surveyInterventionDetails2 = _interopRequireDefault(_surveyInterventionDetails);

var _survey = require('./resources/survey.resource');

var _survey2 = _interopRequireDefault(_survey);

var _surveySwitcher = require('./components/survey-switcher/survey-switcher.component');

var _surveySwitcher2 = _interopRequireDefault(_surveySwitcher);

var _recordDrugUseDialog = require('./services/record-drug-use-dialog/record-drug-use-dialog.service');

var _recordDrugUseDialog2 = _interopRequireDefault(_recordDrugUseDialog);

var _reviewDrugUseDialog = require('./services/review-drug-use-dialog/review-drug-use-dialog.service');

var _reviewDrugUseDialog2 = _interopRequireDefault(_reviewDrugUseDialog);

var _clinicalIncidentDialog = require('./services/clinical-incident-dialog/clinical-incident-dialog.service');

var _clinicalIncidentDialog2 = _interopRequireDefault(_clinicalIncidentDialog);

var _handoverDialog = require('./services/handover-dialog/handover-dialog.service');

var _handoverDialog2 = _interopRequireDefault(_handoverDialog);

var _clinicalIncident = require('./resources/clinical-incident.resource');

var _clinicalIncident2 = _interopRequireDefault(_clinicalIncident);

var _handover = require('./resources/handover.resource');

var _handover2 = _interopRequireDefault(_handover);

var _surveyLaboratoryFindingsDetail = require('./components/survey-laboratory-findings-detail/survey-laboratory-findings-detail.component');

var _surveyLaboratoryFindingsDetail2 = _interopRequireDefault(_surveyLaboratoryFindingsDetail);

var _laboratoryFindingsDialog = require('./services/laboratory-findings-dialog/laboratory-findings-dialog.service');

var _laboratoryFindingsDialog2 = _interopRequireDefault(_laboratoryFindingsDialog);

var _prescribedDrugs = require('./components/prescribed-drugs/prescribed-drugs.component');

var _prescribedDrugs2 = _interopRequireDefault(_prescribedDrugs);

var _prescribedInfusions = require('./components/prescribed-infusions/prescribed-infusions.component');

var _prescribedInfusions2 = _interopRequireDefault(_prescribedInfusions);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _module = _angular2.default.module('gnaas.core.prf', []).component('administeredDrugs', _administeredDrugs2.default).component('administeredInfusions', _administeredInfusions2.default).component('observations', _observations2.default).component('patientDetails', _patientDetails2.default).component('prescribedDrugs', _prescribedDrugs2.default).component('prescribedInfusions', _prescribedInfusions2.default).component('prfListItem', _prfListItem2.default).component('surveyItemDetails', _surveyItemDetails2.default).component('surveyInterventionDetails', _surveyInterventionDetails2.default).component('surveySwitcher', _surveySwitcher2.default).component('surveyLaboratoryFindingsDetail', _surveyLaboratoryFindingsDetail2.default).service('HospitalInformationDialog', _hospitalInformationDialog2.default).service('IllnessDialog', _illnessDialog2.default).service('InjuryDialog', _injuryDialog2.default).service('InterventionDialog', _interventionDialog2.default).service('PatientDetailsDialog', _patientDetailsDialog2.default).service('PRFApprovalDialog', _prfApprovalDialog2.default).service('PRFResource', _prf2.default).service('PrintablePRFResource', _printablePrf2.default).service('SurveyResource', _survey2.default).service('RecordDrugUseDialog', _recordDrugUseDialog2.default).service('ReviewDrugUseDialog', _reviewDrugUseDialog2.default).service('ClinicalIncidentDialog', _clinicalIncidentDialog2.default).service('HandoverDialog', _handoverDialog2.default).service('ClinicalIncidentResource', _clinicalIncident2.default).service('HandoverResource', _handover2.default).service('LaboratoryFindingsDialog', _laboratoryFindingsDialog2.default);

exports.default = _module.name;

},{"./components/administered-drugs/administered-drugs.component":173,"./components/administered-infusions/administered-infusions.component":174,"./components/observations/observations.component":175,"./components/patient-details/patient-details.component":176,"./components/prescribed-drugs/prescribed-drugs.component":177,"./components/prescribed-infusions/prescribed-infusions.component":178,"./components/prf-list-item/prf-list-item.component":179,"./components/survey-intervention-details/survey-intervention-details.component":180,"./components/survey-item-details/survey-item-details.component":181,"./components/survey-laboratory-findings-detail/survey-laboratory-findings-detail.component":182,"./components/survey-switcher/survey-switcher.component":183,"./resources/clinical-incident.resource":185,"./resources/handover.resource":186,"./resources/prf.resource":187,"./resources/printable-prf.resource":188,"./resources/survey.resource":189,"./services/clinical-incident-dialog/clinical-incident-dialog.service":202,"./services/handover-dialog/handover-dialog.service":204,"./services/hospital-information-dialog/hospital-information-dialog.service":206,"./services/illness-dialog/illness-dialog.service":208,"./services/injury-dialog/injury-dialog.service":210,"./services/intervention-dialog/intervention-dialog.service":212,"./services/laboratory-findings-dialog/laboratory-findings-dialog.service":214,"./services/patient-details-dialog/patient-details-dialog.service":221,"./services/prf-approval-dialog/prf-approval-dialog.service":227,"./services/record-drug-use-dialog/record-drug-use-dialog.service":231,"./services/review-drug-use-dialog/review-drug-use-dialog.service":233,"angular":"angular"}],185:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parse = exports.format = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _format(item) {
  var i = {
    id: item.id,
    clinical_incident_recorded: !!item.clinical_incident_recorded,
    datix_completed: !!item.datix_completed,
    datix_incident_number: item.datix_incident_number
  };

  if (item.communication) i.communication = item.communication.data;
  if (item.equipmentProblem) i.equipment_problem = item.equipmentProblem.data;
  if (item.organisationalDelay) i.organisational_delay = item.organisationalDelay.data;
  if (item.physiologicalDeterioration) i.physiological_deterioration = item.physiologicalDeterioration.data;

  return i;
}

exports.format = _format;
function _parse(item) {
  var i = {
    id: item.id,
    clinical_incident_recorded: item.clinical_incident_recorded ? 1 : 0,
    datix_completed: item.datix_completed ? 1 : 0,
    datix_incident_number: item.datix_incident_number || null
  };

  if (item.communication) i.communication_id = item.communication.id;
  if (item.equipment_problem) i.equipment_problem_id = item.equipment_problem.id;
  if (item.organisational_delay) i.organisational_delay_id = item.organisational_delay.id;
  if (item.physiological_deterioration) i.physiological_deterioration_id = item.physiological_deterioration.id;

  return i;
}

exports.parse = _parse;

var ClinicalIncidentResource = function (_RestResource) {
  _inherits(ClinicalIncidentResource, _RestResource);

  function ClinicalIncidentResource() {
    _classCallCheck(this, ClinicalIncidentResource);

    return _possibleConstructorReturn(this, (ClinicalIncidentResource.__proto__ || Object.getPrototypeOf(ClinicalIncidentResource)).apply(this, arguments));
  }

  _createClass(ClinicalIncidentResource, [{
    key: 'init',
    value: function init() {
      this.resourcePath = 'clinical-incidents';
    }
  }, {
    key: 'parse',
    value: function parse(item) {
      return _parse(item);
    }
  }, {
    key: 'format',
    value: function format(item) {
      return _format(item);
    }
  }]);

  return ClinicalIncidentResource;
}(_restResource2.default);

exports.default = ClinicalIncidentResource;

},{"common/resource/services/restResource":60}],186:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parse = exports.format = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _format(item) {
  return {
    id: item.id,
    clinical_notes: item.clinical_notes,
    care_handed_over_to_doctor: item.care_handed_over_to_doctor,
    care_handed_over_to_nurse: item.care_handed_over_to_nurse,
    controlled_drugs_disposed_at_receiving_hospital: !!item.controlled_drugs_disposed_at_receiving_hospital,
    infusions_continued_by_receiving_team: !!item.infusions_continued_by_receiving_team
  };
}

exports.format = _format;
function _parse(item) {
  return {
    id: item.id,
    clinical_notes: item.clinical_notes || null,
    care_handed_over_to_doctor: item.care_handed_over_to_doctor || null,
    care_handed_over_to_nurse: item.care_handed_over_to_nurse || null,
    controlled_drugs_disposed_at_receiving_hospital: item.controlled_drugs_disposed_at_receiving_hospital ? 1 : 0,
    infusions_continued_by_receiving_team: item.infusions_continued_by_receiving_team ? 1 : 0
  };
}

exports.parse = _parse;

var HandoverResource = function (_RestResource) {
  _inherits(HandoverResource, _RestResource);

  function HandoverResource() {
    _classCallCheck(this, HandoverResource);

    return _possibleConstructorReturn(this, (HandoverResource.__proto__ || Object.getPrototypeOf(HandoverResource)).apply(this, arguments));
  }

  _createClass(HandoverResource, [{
    key: 'init',
    value: function init() {
      this.resourcePath = 'handovers';
    }
  }, {
    key: 'parse',
    value: function parse(item) {
      return _parse(item);
    }
  }, {
    key: 'format',
    value: function format(item) {
      return _format(item);
    }
  }]);

  return HandoverResource;
}(_restResource2.default);

exports.default = HandoverResource;

},{"common/resource/services/restResource":60}],187:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

var _prfFormatter = require('core/prf/services/prf-formatter.service');

var _prfFormatter2 = _interopRequireDefault(_prfFormatter);

var _prfParser = require('core/prf/services/prf-parser.service');

var _prfParser2 = _interopRequireDefault(_prfParser);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; } /* global FormData */

function formatApproval(data) {
  if (!data) return data;
  var approval = { id: data.id };
  if (data.firstUser) {
    approval.first_user = data.firstUser.data;
  }
  if (data.secondUser) {
    approval.second_user = data.secondUser.data;
  }
  return approval;
}

var PRFResource = function (_RestResource) {
  _inherits(PRFResource, _RestResource);

  function PRFResource() {
    _classCallCheck(this, PRFResource);

    return _possibleConstructorReturn(this, (PRFResource.__proto__ || Object.getPrototypeOf(PRFResource)).apply(this, arguments));
  }

  _createClass(PRFResource, [{
    key: 'init',
    value: function init() {
      this.resourcePath = 'patient-report-forms';
    }
  }, {
    key: 'parse',
    value: function parse(item) {
      return (0, _prfParser2.default)(item);
    }
  }, {
    key: 'format',
    value: function format(item) {
      return (0, _prfFormatter2.default)(item);
    }
  }, {
    key: 'patch',
    value: function patch(id, data) {
      var _this2 = this;

      var url = '' + this.apiUrl + this.resourcePath + '/' + id;
      return this._http.patch(url, this._parseData(data)).then(function (response) {
        return _this2._formatData(response.data.data);
      });
    }
  }, {
    key: 'approve',
    value: function approve(id, data) {
      var url = '' + this.apiUrl + this.resourcePath + '/' + id + '/approve';
      return this._http.post(url, data).then(function (response) {
        return formatApproval(response.data.data);
      });
    }
  }, {
    key: 'secondApproval',
    value: function secondApproval(id, data) {
      var url = '' + this.apiUrl + this.resourcePath + '/' + id + '/second-approval';
      return this._http.post(url, data).then(function (response) {
        return formatApproval(response.data.data);
      });
    }
  }, {
    key: 'reject',
    value: function reject(id) {
      var url = '' + this.apiUrl + this.resourcePath + '/' + id + '/reject';
      return this._http.post(url).then(function (response) {
        return formatApproval(response.data.data);
      });
    }
  }, {
    key: 'uploadImage',
    value: function uploadImage(id, image, imageId) {
      var url = '' + this.apiUrl + this.resourcePath + '/' + id + '/image';
      var deferred = this._q.defer();
      var requestData = { imageId: imageId, image: image };
      this._http({
        method: 'POST',
        url: url,
        headers: {
          'Content-Type': undefined
        },
        uploadEventHandlers: {
          progress: function progress(e) {
            deferred.notify(e);
          }
        },
        data: requestData,
        transformRequest: function transformRequest(data, headersGetter) {
          var formData = new FormData();
          formData.append('image_id', data.imageId);
          formData.append('image', data.image);
          return formData;
        }
      }).then(function (response) {
        deferred.resolve(response.data.data);
      }).catch(function (err) {
        deferred.reject(err);
      });

      return deferred.promise;
    }
  }, {
    key: 'deleteImage',
    value: function deleteImage(id, imageId) {
      var url = '' + this.apiUrl + this.resourcePath + '/' + id + '/image/' + imageId;
      return this._http.delete(url).then(function (response) {
        return response.data;
      });
    }
  }, {
    key: 'uploadAttachment',
    value: function uploadAttachment(id, attachment, attachmentId) {
      var url = '' + this.apiUrl + this.resourcePath + '/' + id + '/attachment';
      var deferred = this._q.defer();
      var requestData = { attachmentId: attachmentId, attachment: attachment };
      this._http({
        method: 'POST',
        url: url,
        headers: {
          'Content-Type': undefined
        },
        uploadEventHandlers: {
          progress: function progress(e) {
            deferred.notify(e);
          }
        },
        data: requestData,
        transformRequest: function transformRequest(data, headersGetter) {
          var formData = new FormData();
          formData.append('attachment_id', data.attachmentId);
          formData.append('attachment', data.attachment);
          return formData;
        }
      }).then(function (response) {
        deferred.resolve(response.data.data);
      }).catch(function (err) {
        deferred.reject(err);
      });

      return deferred.promise;
    }
  }, {
    key: 'deleteAttachment',
    value: function deleteAttachment(id, attachmentId) {
      var url = '' + this.apiUrl + this.resourcePath + '/' + id + '/attachment/' + attachmentId;
      return this._http.delete(url).then(function (response) {
        return response.data;
      });
    }
  }]);

  return PRFResource;
}(_restResource2.default);

exports.default = PRFResource;

},{"common/resource/services/restResource":60,"core/prf/services/prf-formatter.service":228,"core/prf/services/prf-parser.service":229}],188:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var PrintablePRFResource = function (_RestResource) {
  _inherits(PrintablePRFResource, _RestResource);

  function PrintablePRFResource() {
    _classCallCheck(this, PrintablePRFResource);

    return _possibleConstructorReturn(this, (PrintablePRFResource.__proto__ || Object.getPrototypeOf(PrintablePRFResource)).apply(this, arguments));
  }

  _createClass(PrintablePRFResource, [{
    key: 'init',
    value: function init() {
      this.resourcePath = 'printable-prf';
    }
  }, {
    key: 'show',
    value: function show(id) {
      var url = '' + this.apiUrl + this.resourcePath + '/' + id;

      return this._http({
        url: url,
        method: 'GET',
        responseType: 'arraybuffer'
      }).then(function (response) {
        return response.data;
      });
    }
  }]);

  return PrintablePRFResource;
}(_restResource2.default);

exports.default = PrintablePRFResource;

},{"common/resource/services/restResource":60}],189:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

var _surveyFormatter = require('core/prf/services/survey-formatter.service');

var _surveyFormatter2 = _interopRequireDefault(_surveyFormatter);

var _surveyParser = require('core/prf/services/survey-parser.service');

var _surveyParser2 = _interopRequireDefault(_surveyParser);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var SurveyResource = function (_RestResource) {
  _inherits(SurveyResource, _RestResource);

  function SurveyResource() {
    _classCallCheck(this, SurveyResource);

    return _possibleConstructorReturn(this, (SurveyResource.__proto__ || Object.getPrototypeOf(SurveyResource)).apply(this, arguments));
  }

  _createClass(SurveyResource, [{
    key: 'init',
    value: function init() {
      this.resourcePath = 'surveys';
    }
  }, {
    key: 'parse',
    value: function parse(item) {
      return (0, _surveyParser2.default)(item);
    }
  }, {
    key: 'format',
    value: function format(item) {
      return (0, _surveyFormatter2.default)(item);
    }
  }]);

  return SurveyResource;
}(_restResource2.default);

exports.default = SurveyResource;

},{"common/resource/services/restResource":60,"core/prf/services/survey-formatter.service":234,"core/prf/services/survey-parser.service":235}],190:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = format;

var _crewMemberResource = require('pages/crewMembers/services/crewMemberResource');

var _prescribedDrugFormatter = require('./prescribed-drug-formatter.service');

var _prescribedDrugFormatter2 = _interopRequireDefault(_prescribedDrugFormatter);

var _dateTime = require('common/utils/services/dateTime');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function format(item) {
  var dateTime = (0, _dateTime.toDateTimeObject)(item.time);
  return {
    id: item.id,
    dose: parseFloat(item.dose),
    rate: parseFloat(item.rate),
    time: dateTime,
    date: dateTime,
    prescription: item.prescription ? (0, _prescribedDrugFormatter2.default)(item.prescription.data) : null,
    administered_by: (0, _crewMemberResource.format)(item.administeredBy.data)
  };
}

},{"./prescribed-drug-formatter.service":222,"common/utils/services/dateTime":77,"pages/crewMembers/services/crewMemberResource":362}],191:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = parse;

var _dateTime = require('common/utils/services/dateTime');

function parse(item) {
  return {
    id: item.id,
    prescription_id: item.prescription.id,
    dose: item.dose,
    rate: item.rate,
    time: (0, _dateTime.toDateTimeString)(item.date, item.time)
  };
}

},{"common/utils/services/dateTime":77}],192:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = format;

var _crewMemberResource = require('pages/crewMembers/services/crewMemberResource');

var _prescribedInfusionFormatter = require('./prescribed-infusion-formatter.service');

var _prescribedInfusionFormatter2 = _interopRequireDefault(_prescribedInfusionFormatter);

var _dateTime = require('common/utils/services/dateTime');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function format(item) {
  return {
    id: item.id,
    prescription: item.prescription ? (0, _prescribedInfusionFormatter2.default)(item.prescription.data) : null,
    administered_by: (0, _crewMemberResource.format)(item.administeredBy.data),
    details: item.details.data.map(function (detail) {
      var dateTime = (0, _dateTime.toDateTimeObject)(detail.time);
      return {
        id: detail.id,
        time: dateTime,
        date: dateTime,
        rate: parseFloat(detail.rate)
      };
    })
  };
}

},{"./prescribed-infusion-formatter.service":224,"common/utils/services/dateTime":77,"pages/crewMembers/services/crewMemberResource":362}],193:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = parse;

var _dateTime = require('common/utils/services/dateTime');

function parse(item) {
  return {
    id: item.id,
    prescription_id: item.prescription.id,
    details: item.details.map(function (detail) {
      return {
        id: detail.id,
        time: (0, _dateTime.toDateTimeString)(detail.date, detail.time),
        rate: detail.rate
      };
    })
  };
}

},{"common/utils/services/dateTime":77}],194:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAdministeredInfusionMap = getAdministeredInfusionMap;
exports.getUniqueInfusionDetailTimestamps = getUniqueInfusionDetailTimestamps;
exports.denormalizeAdministeredInfusionDetails = denormalizeAdministeredInfusionDetails;
exports.normalizeAdministeredInfusions = normalizeAdministeredInfusions;

var _nodeUuid = require('node-uuid');

var _nodeUuid2 = _interopRequireDefault(_nodeUuid);

var _dateTime = require('common/utils/services/dateTime');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/**
 * Get an ID based map of administered infusions. Details are mapped by timestamps.
 *
 * InfusionMap
 * {
 *   [infusion_id]: {
 *     [detail_timestamp]: { id: ..., rate: ..., administered_infusion_id: ... }
 *   }
 * }
 *
 * @param administeredInfusions Administered infusions
 */
function getAdministeredInfusionMap(administeredInfusions) {
  return administeredInfusions.reduce(function (infusionMap, infusion) {
    return Object.assign({}, infusionMap, _defineProperty({}, infusion.id, {
      prescription: infusion.prescription,
      details: infusion.details.reduce(function (detailMap, detail) {
        return Object.assign({}, detailMap, _defineProperty({}, (0, _dateTime.toDateTimeString)(detail.date, detail.time), {
          id: detail.id,
          rate: detail.rate,
          administered_infusion_id: infusion.id
        }));
      }, {})
    }));
  }, {});
}

/**
 * Get unique timestamps from administered infusion details.
 *
 * @param administeredInfusions Administered infusions
 */
function getUniqueInfusionDetailTimestamps(administeredInfusions) {
  return administeredInfusions
  // Flatten all administered infusion details
  .reduce(function (acc, cur) {
    return cur.details ? acc.concat(cur.details) : acc;
  }, [])
  // Get all unique timestamps as an array of strings
  .reduce(function (acc, cur) {
    var dtString = (0, _dateTime.toDateTimeString)(cur.date, cur.time);
    return ~acc.indexOf(dtString) ? acc : [].concat(_toConsumableArray(acc), [dtString]);
  }, []);
}

/**
 * Denormalize administered infusions, so that they can be used within the administered-infusions component.
 * This is also done in order to encapsulate data manipulation within a single component. Can be done either within
 * the parent component (survey-tab), or administered-infusions component itself.
 * The difference between the two is at what point denormalization and normalization happen.
 *
 * Details
 * [
 *   {
 *     date: ...,
 *     time: ...,
 *     details: [{ id: ..., rate: ..., administered_infusion_id: ... }, ...]
 *   }
 * ]
 *
 * @param infusions array Normalized administered infusions
 */
function denormalizeAdministeredInfusionDetails(infusions) {
  var infusionMap = getAdministeredInfusionMap(infusions);
  var uniqueTimestamps = getUniqueInfusionDetailTimestamps(infusions);

  return uniqueTimestamps.map(function (timestamp) {
    return {
      date: (0, _dateTime.toDateTimeObject)(timestamp),
      time: (0, _dateTime.toDateTimeObject)(timestamp),
      rates: Object.keys(infusionMap).map(function (infusionId) {
        if (infusionMap[infusionId].details[timestamp]) return infusionMap[infusionId].details[timestamp];
        return {
          id: _nodeUuid2.default.v4(),
          administered_infusion_id: infusionId,
          rate: null
        };
      })
    };
  });
}

/**
 * Normalize administered infusion & detail/rate data.
 *
 * @param infusions Denormalized administered infusions
 * @param details Denormalized administered infusion details
 */
function normalizeAdministeredInfusions(infusions, details) {
  var normalized = infusions.map(function (i) {
    return Object.assign({}, i, { details: [] });
  });

  details.forEach(function (detail) {
    detail.rates.forEach(function (rate) {
      if (rate.rate == null) return;
      var infusion = normalized.find(function (i) {
        return i.id === rate.administered_infusion_id;
      });
      infusion.details.push({
        id: rate.id,
        time: (0, _dateTime.toDateTimeObject)(detail.time),
        date: (0, _dateTime.toDateTimeObject)(detail.date),
        rate: rate.rate
      });
    });
  });

  return normalized;
}

},{"common/utils/services/dateTime":77,"node-uuid":"node-uuid"}],195:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = format;

var _bodyPart = require('core/categories/resources/body-part.resource');

var _intervention = require('core/categories/resources/intervention.resource');

var _crewMemberResource = require('pages/crewMembers/services/crewMemberResource');

function format(item) {
  var formatted = {
    id: item.id,
    notes: item.notes,
    performed_by_third_party: item.performed_by_third_party
  };

  if (item.intervention) {
    formatted.intervention = (0, _intervention.format)(item.intervention.data);
  }

  if (item.users) {
    formatted.crew_members = [];
    item.users.data.forEach(function (user) {
      formatted.crew_members.push((0, _crewMemberResource.format)(user));
    });
  }

  if (item.bodyPart) {
    formatted.body_part = (0, _bodyPart.format)(item.bodyPart.data);
  }

  if (item.attributes) {
    formatted.attributes = item.attributes.data.map(function (attribute) {
      return {
        id: attribute.id,
        value: attribute.value,
        intervention_attribute: attribute.interventionAttribute.data,
        intervention_attribute_value: attribute.interventionAttributeValue ? attribute.interventionAttributeValue.data : null
      };
    });
  }

  return formatted;
};

},{"core/categories/resources/body-part.resource":85,"core/categories/resources/intervention.resource":98,"pages/crewMembers/services/crewMemberResource":362}],196:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = parse;
function parse(item) {
  var parsed = {
    id: item.id,
    intervention_id: item.intervention.id,
    body_part_id: item.body_part.id,
    users: [],
    notes: item.notes,
    performed_by_third_party: item.performed_by_third_party
  };

  if (item.crew_members && Object.keys(item.crew_members).length) {
    parsed.users.push({ id: item.crew_members[0].id });

    if (item.crew_members[1]) {
      parsed.users.push({ id: item.crew_members[1].id });
    }
  }

  if (item.attributes && item.attributes.length) {
    parsed.attributes = item.attributes.map(function (attribute) {
      var parsedAttribute = {
        id: attribute.id,
        intervention_attribute_id: attribute.intervention_attribute.id,
        value: attribute.value
      };

      if (attribute.intervention_attribute_value) {
        parsedAttribute.intervention_attribute_value_id = attribute.intervention_attribute_value.id;
      }

      return parsedAttribute;
    });
  }

  return parsed;
};

},{}],197:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = format;

var _bodyPart = require('core/categories/resources/body-part.resource');

function format(item) {
  return {
    id: item.id,
    notes: item.notes,
    status: item.status,
    body_part: (0, _bodyPart.format)(item.bodyPart.data),
    illness: item.illness.data,
    illness_type: item.illnessType ? item.illnessType.data : null
  };
}

},{"core/categories/resources/body-part.resource":85}],198:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = parse;
function parse(item) {
  return {
    id: item.id,
    notes: item.notes,
    status: item.status,
    illness_id: item.illness.id,
    body_part_id: item.body_part.id,
    illness_type_id: item.illness_type ? item.illness_type.id : null
  };
}

},{}],199:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = format;

var _bodyPart = require('core/categories/resources/body-part.resource');

function format(item) {
  return {
    id: item.id,
    notes: item.notes,
    status: item.status,
    body_part: (0, _bodyPart.format)(item.bodyPart.data),
    injury: item.injury.data,
    injury_type: item.injuryType ? item.injuryType.data : null
  };
}

},{"core/categories/resources/body-part.resource":85}],200:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = parse;
function parse(item) {
  return {
    id: item.id,
    notes: item.notes,
    status: item.status,
    injury_id: item.injury.id,
    body_part_id: item.body_part.id,
    injury_type_id: item.injury_type ? item.injury_type.id : null
  };
}

},{}],201:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _nodeUuid = require('node-uuid');

var _nodeUuid2 = _interopRequireDefault(_nodeUuid);

var _resourceDialogController = require('common/resource/controllers/resourceDialogController');

var _resourceDialogController2 = _interopRequireDefault(_resourceDialogController);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ClinicalIncidentDialogController = function (_ResourceDialogContro) {
  _inherits(ClinicalIncidentDialogController, _ResourceDialogContro);

  function ClinicalIncidentDialogController() {
    _classCallCheck(this, ClinicalIncidentDialogController);

    return _possibleConstructorReturn(this, (ClinicalIncidentDialogController.__proto__ || Object.getPrototypeOf(ClinicalIncidentDialogController)).apply(this, arguments));
  }

  _createClass(ClinicalIncidentDialogController, [{
    key: 'init',
    value: function init() {
      _get(ClinicalIncidentDialogController.prototype.__proto__ || Object.getPrototypeOf(ClinicalIncidentDialogController.prototype), 'init', this).call(this);
      // This resource will temporarily use the PRF resource and update clinical incident as part of the PRF.
      // This is to save significand amount of time on development in the short term (especially iPad & offline sync).
      // Eventually this is to be refactored to use the proper ClinicalInicdentResource.
      this._resource = this.$injector.get('PRFResource');
      this._ID = _nodeUuid2.default.v4();
    }
  }, {
    key: '$onInit',
    value: function $onInit() {
      if (!this.item.clinical_incident) {
        this.item.clinical_incident = { clinical_incident_recorded: false };
      }
    }
  }, {
    key: 'create',
    value: function create(data) {
      var _this2 = this;

      data.clinical_incident.id = this._ID;
      if (data.clinical_incident.clinical_incident_recorded && data.clinical_incident.datix_completed == null) {
        data.clinical_incident.datix_completed = false;
      }

      this._resource.update(data.id, data, { include: 'clinicalIncident' }).then(function (item) {
        _this2.Dialog.hide(item);
      });
    }
  }, {
    key: 'update',
    value: function update(data) {
      var _this3 = this;

      this._resource.update(data.id, data, { include: 'clinicalIncident' }).then(function (item) {
        _this3.Dialog.hide(item);
      }).catch();
    }
  }]);

  return ClinicalIncidentDialogController;
}(_resourceDialogController2.default);

exports.default = ClinicalIncidentDialogController;

},{"common/resource/controllers/resourceDialogController":56,"node-uuid":"node-uuid"}],202:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _resourceDialog = require('common/resource/services/resourceDialog');

var _resourceDialog2 = _interopRequireDefault(_resourceDialog);

var _clinicalIncidentDialog = require('./clinical-incident-dialog.controller');

var _clinicalIncidentDialog2 = _interopRequireDefault(_clinicalIncidentDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ClinicalIncidentDialog = function (_ResourceDialog) {
  _inherits(ClinicalIncidentDialog, _ResourceDialog);

  function ClinicalIncidentDialog() {
    _classCallCheck(this, ClinicalIncidentDialog);

    return _possibleConstructorReturn(this, (ClinicalIncidentDialog.__proto__ || Object.getPrototypeOf(ClinicalIncidentDialog)).apply(this, arguments));
  }

  _createClass(ClinicalIncidentDialog, [{
    key: 'init',
    value: function init() {
      _get(ClinicalIncidentDialog.prototype.__proto__ || Object.getPrototypeOf(ClinicalIncidentDialog.prototype), 'init', this).call(this);
      this.setController(_clinicalIncidentDialog2.default);
      this.setTemplateUrl('core/prf/services/clinical-incident-dialog/clinical-incident-dialog.tpl.html');
    }
  }]);

  return ClinicalIncidentDialog;
}(_resourceDialog2.default);

exports.default = ClinicalIncidentDialog;

},{"./clinical-incident-dialog.controller":201,"common/resource/services/resourceDialog":59}],203:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _nodeUuid = require('node-uuid');

var _nodeUuid2 = _interopRequireDefault(_nodeUuid);

var _resourceDialogController = require('common/resource/controllers/resourceDialogController');

var _resourceDialogController2 = _interopRequireDefault(_resourceDialogController);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var HandoverDialogController = function (_ResourceDialogContro) {
  _inherits(HandoverDialogController, _ResourceDialogContro);

  function HandoverDialogController() {
    _classCallCheck(this, HandoverDialogController);

    return _possibleConstructorReturn(this, (HandoverDialogController.__proto__ || Object.getPrototypeOf(HandoverDialogController)).apply(this, arguments));
  }

  _createClass(HandoverDialogController, [{
    key: 'init',
    value: function init() {
      _get(HandoverDialogController.prototype.__proto__ || Object.getPrototypeOf(HandoverDialogController.prototype), 'init', this).call(this);
      // This resource will temporarily use the PRF resource and update handover as part of the PRF.
      // This is to save significand amount of time on development in the short term (especially iPad & offline sync).
      // Eventually this is to be refactored to use the proper HandoverResource.
      this._resource = this.$injector.get('PRFResource');
      this._ID = _nodeUuid2.default.v4();
    }
  }, {
    key: 'create',
    value: function create(data) {
      var _this2 = this;

      data.handover.id = this._ID;
      if (data.handover.controlled_drugs_disposed_at_receiving_hospital == null) {
        data.handover.controlled_drugs_disposed_at_receiving_hospital = false;
      }
      if (data.handover.infusions_continued_by_receiving_team == null) {
        data.handover.infusions_continued_by_receiving_team = false;
      }

      this._resource.update(data.id, data, { include: 'handover' }).then(function (item) {
        _this2.Dialog.hide(item);
      });
    }
  }, {
    key: 'update',
    value: function update(data) {
      var _this3 = this;

      this._resource.update(data.id, data, { include: 'handover' }).then(function (item) {
        _this3.Dialog.hide(item);
      }).catch();
    }
  }]);

  return HandoverDialogController;
}(_resourceDialogController2.default);

exports.default = HandoverDialogController;

},{"common/resource/controllers/resourceDialogController":56,"node-uuid":"node-uuid"}],204:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _resourceDialog = require('common/resource/services/resourceDialog');

var _resourceDialog2 = _interopRequireDefault(_resourceDialog);

var _handoverDialog = require('./handover-dialog.controller');

var _handoverDialog2 = _interopRequireDefault(_handoverDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var HandoverDialog = function (_ResourceDialog) {
  _inherits(HandoverDialog, _ResourceDialog);

  function HandoverDialog() {
    _classCallCheck(this, HandoverDialog);

    return _possibleConstructorReturn(this, (HandoverDialog.__proto__ || Object.getPrototypeOf(HandoverDialog)).apply(this, arguments));
  }

  _createClass(HandoverDialog, [{
    key: 'init',
    value: function init() {
      _get(HandoverDialog.prototype.__proto__ || Object.getPrototypeOf(HandoverDialog.prototype), 'init', this).call(this);
      this.setController(_handoverDialog2.default);
      this.setTemplateUrl('core/prf/services/handover-dialog/handover-dialog.tpl.html');
    }
  }]);

  return HandoverDialog;
}(_resourceDialog2.default);

exports.default = HandoverDialog;

},{"./handover-dialog.controller":203,"common/resource/services/resourceDialog":59}],205:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _resourceDialogController = require('common/resource/controllers/resourceDialogController');

var _resourceDialogController2 = _interopRequireDefault(_resourceDialogController);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var HospitalInformationDialogController = function (_ResourceDialogContro) {
  _inherits(HospitalInformationDialogController, _ResourceDialogContro);

  function HospitalInformationDialogController() {
    _classCallCheck(this, HospitalInformationDialogController);

    return _possibleConstructorReturn(this, (HospitalInformationDialogController.__proto__ || Object.getPrototypeOf(HospitalInformationDialogController)).apply(this, arguments));
  }

  _createClass(HospitalInformationDialogController, [{
    key: 'init',
    value: function init() {
      this._resource = this.$injector.get('PRFResource');
    }
  }, {
    key: 'submit',
    value: function submit(formData) {
      var _this2 = this;

      if (!this.form) {
        throw new Error('Form not found.');
      }

      if (this.form.$invalid) {
        return;
      }

      this._resource.patch(this.prfId, {
        handover_notes: formData
      }).then(function (prf) {
        _this2.Dialog.hide(prf.handover_notes);
      });
    }
  }]);

  return HospitalInformationDialogController;
}(_resourceDialogController2.default);

exports.default = HospitalInformationDialogController;

},{"common/resource/controllers/resourceDialogController":56}],206:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _resourceDialog = require('common/resource/services/resourceDialog');

var _resourceDialog2 = _interopRequireDefault(_resourceDialog);

var _hospitalInformationDialog = require('./hospital-information-dialog.controller');

var _hospitalInformationDialog2 = _interopRequireDefault(_hospitalInformationDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var HospitalInformationDialog = function (_ResourceDialog) {
  _inherits(HospitalInformationDialog, _ResourceDialog);

  function HospitalInformationDialog() {
    _classCallCheck(this, HospitalInformationDialog);

    return _possibleConstructorReturn(this, (HospitalInformationDialog.__proto__ || Object.getPrototypeOf(HospitalInformationDialog)).apply(this, arguments));
  }

  _createClass(HospitalInformationDialog, [{
    key: 'init',
    value: function init() {
      _get(HospitalInformationDialog.prototype.__proto__ || Object.getPrototypeOf(HospitalInformationDialog.prototype), 'init', this).call(this);
      this.setController(_hospitalInformationDialog2.default);
      this.setTemplateUrl('core/prf/services/hospital-information-dialog/hospital-information-dialog.tpl.html');
    }
  }]);

  return HospitalInformationDialog;
}(_resourceDialog2.default);

exports.default = HospitalInformationDialog;

},{"./hospital-information-dialog.controller":205,"common/resource/services/resourceDialog":59}],207:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _nodeUuid = require('node-uuid');

var _nodeUuid2 = _interopRequireDefault(_nodeUuid);

var _resourceDialogController = require('common/resource/controllers/resourceDialogController');

var _resourceDialogController2 = _interopRequireDefault(_resourceDialogController);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var IllnessDialogController = function (_ResourceDialogContro) {
  _inherits(IllnessDialogController, _ResourceDialogContro);

  function IllnessDialogController() {
    _classCallCheck(this, IllnessDialogController);

    return _possibleConstructorReturn(this, (IllnessDialogController.__proto__ || Object.getPrototypeOf(IllnessDialogController)).apply(this, arguments));
  }

  _createClass(IllnessDialogController, [{
    key: 'init',
    value: function init() {
      _get(IllnessDialogController.prototype.__proto__ || Object.getPrototypeOf(IllnessDialogController.prototype), 'init', this).call(this);

      this.relationData.bodyParts = this.relationData.bodyParts.filter(function (item) {
        return item.illnesses.length > 0;
      });

      this.addAnother = false;

      if (!this.item) {
        this.item = {
          status: 'red'
        };
      }
    }
  }, {
    key: 'submit',
    value: function submit(item, addAnother) {
      if (!this.form) {
        throw new Error('Form not found.');
      }

      if (this.form.$invalid) {
        return;
      }

      if (!item.id) {
        item.id = _nodeUuid2.default.v4();
      }
      return this.Dialog.hide({ item: item, addAnother: addAnother });
    }
  }, {
    key: 'delete',
    value: function _delete(id) {
      var _this2 = this;

      this.Dialog.confirm().then(function () {
        _this2.Dialog.cancel(id);
      });
    }
  }]);

  return IllnessDialogController;
}(_resourceDialogController2.default);

exports.default = IllnessDialogController;

},{"common/resource/controllers/resourceDialogController":56,"node-uuid":"node-uuid"}],208:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _resourceDialog = require('common/resource/services/resourceDialog');

var _resourceDialog2 = _interopRequireDefault(_resourceDialog);

var _illnessDialog = require('./illness-dialog.controller');

var _illnessDialog2 = _interopRequireDefault(_illnessDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var IllnessDialog = function (_ResourceDialog) {
  _inherits(IllnessDialog, _ResourceDialog);

  function IllnessDialog() {
    _classCallCheck(this, IllnessDialog);

    return _possibleConstructorReturn(this, (IllnessDialog.__proto__ || Object.getPrototypeOf(IllnessDialog)).apply(this, arguments));
  }

  _createClass(IllnessDialog, [{
    key: 'init',
    value: function init() {
      _get(IllnessDialog.prototype.__proto__ || Object.getPrototypeOf(IllnessDialog.prototype), 'init', this).call(this);
      this.setController(_illnessDialog2.default);
      this.setTemplateUrl('core/prf/services/illness-dialog/illness-dialog.tpl.html');
    }
  }]);

  return IllnessDialog;
}(_resourceDialog2.default);

exports.default = IllnessDialog;

},{"./illness-dialog.controller":207,"common/resource/services/resourceDialog":59}],209:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _nodeUuid = require('node-uuid');

var _nodeUuid2 = _interopRequireDefault(_nodeUuid);

var _resourceDialogController = require('common/resource/controllers/resourceDialogController');

var _resourceDialogController2 = _interopRequireDefault(_resourceDialogController);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var InjuryDialogController = function (_ResourceDialogContro) {
  _inherits(InjuryDialogController, _ResourceDialogContro);

  function InjuryDialogController() {
    _classCallCheck(this, InjuryDialogController);

    return _possibleConstructorReturn(this, (InjuryDialogController.__proto__ || Object.getPrototypeOf(InjuryDialogController)).apply(this, arguments));
  }

  _createClass(InjuryDialogController, [{
    key: 'init',
    value: function init() {
      _get(InjuryDialogController.prototype.__proto__ || Object.getPrototypeOf(InjuryDialogController.prototype), 'init', this).call(this);

      this.relationData.bodyParts = this.relationData.bodyParts.filter(function (item) {
        return item.injuries.length > 0;
      });

      this.addAnother = false;

      if (!this.item) {
        this.item = {
          status: 'red'
        };
      }
    }
  }, {
    key: 'submit',
    value: function submit(item, addAnother) {
      if (!this.form) {
        throw new Error('Form not found.');
      }

      if (this.form.$invalid) {
        return;
      }

      if (!item.id) {
        item.id = _nodeUuid2.default.v4();
      }
      return this.Dialog.hide({ item: item, addAnother: addAnother });
    }
  }, {
    key: 'delete',
    value: function _delete(id) {
      var _this2 = this;

      this.Dialog.confirm().then(function () {
        _this2.Dialog.cancel(id);
      });
    }
  }]);

  return InjuryDialogController;
}(_resourceDialogController2.default);

exports.default = InjuryDialogController;

},{"common/resource/controllers/resourceDialogController":56,"node-uuid":"node-uuid"}],210:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _resourceDialog = require('common/resource/services/resourceDialog');

var _resourceDialog2 = _interopRequireDefault(_resourceDialog);

var _injuryDialog = require('./injury-dialog.controller');

var _injuryDialog2 = _interopRequireDefault(_injuryDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var InjuryDialog = function (_ResourceDialog) {
  _inherits(InjuryDialog, _ResourceDialog);

  function InjuryDialog() {
    _classCallCheck(this, InjuryDialog);

    return _possibleConstructorReturn(this, (InjuryDialog.__proto__ || Object.getPrototypeOf(InjuryDialog)).apply(this, arguments));
  }

  _createClass(InjuryDialog, [{
    key: 'init',
    value: function init() {
      _get(InjuryDialog.prototype.__proto__ || Object.getPrototypeOf(InjuryDialog.prototype), 'init', this).call(this);
      this.setController(_injuryDialog2.default);
      this.setTemplateUrl('core/prf/services/injury-dialog/injury-dialog.tpl.html');
    }
  }]);

  return InjuryDialog;
}(_resourceDialog2.default);

exports.default = InjuryDialog;

},{"./injury-dialog.controller":209,"common/resource/services/resourceDialog":59}],211:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _resourceDialogController = require('common/resource/controllers/resourceDialogController');

var _resourceDialogController2 = _interopRequireDefault(_resourceDialogController);

var _nodeUuid = require('node-uuid');

var _nodeUuid2 = _interopRequireDefault(_nodeUuid);

var _angular = require('angular');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var convertNumericValues = function convertNumericValues(values) {
  return values.map(function (item) {
    if (!isNaN(item.value)) {
      item.value = parseFloat(item.value);
    }
    return item;
  });
};

var findAttributeById = function findAttributeById(id, array) {
  var result = array.filter(function (item) {
    return item.intervention_attribute.id === id;
  });
  return result.length ? result[0] : null;
};

var InterventionDialogController = function (_ResourceDialogContro) {
  _inherits(InterventionDialogController, _ResourceDialogContro);

  function InterventionDialogController() {
    _classCallCheck(this, InterventionDialogController);

    return _possibleConstructorReturn(this, (InterventionDialogController.__proto__ || Object.getPrototypeOf(InterventionDialogController)).apply(this, arguments));
  }

  _createClass(InterventionDialogController, [{
    key: 'init',
    value: function init() {
      _get(InterventionDialogController.prototype.__proto__ || Object.getPrototypeOf(InterventionDialogController.prototype), 'init', this).call(this);
      this._timeout = this.$injector.get('$timeout');
      this._scope = this.$injector.get('$rootScope').$new();

      this.bodyParts = this.relationData.bodyParts.filter(function (item) {
        return item.interventions.length > 0;
      });

      this.relationData.crewMembers.forEach(function (crewMember) {
        crewMember.name = crewMember.first_name + ' ' + crewMember.last_name;
        crewMember.disabled = false;
      });

      this._setupWatchers();
    }
  }, {
    key: 'submit',
    value: function submit(item, addAnother) {
      if (!this.form) {
        throw new Error('Form not found.');
      }

      if (this.form.$invalid) {
        return;
      }

      if (!item.id) {
        item.id = _nodeUuid2.default.v4();
      }
      this.Dialog.hide({ item: this._transformIntervention(item), addAnother: addAnother });
    }
  }, {
    key: 'delete',
    value: function _delete(id) {
      var _this2 = this;

      this.Dialog.confirm().then(function () {
        _this2.Dialog.cancel(id);
      });
    }
  }, {
    key: '_setupWatchers',
    value: function _setupWatchers() {
      this._watchInterventions();
      this._watchUsers();
      this._watchPerformedByThirdParty();
    }
  }, {
    key: '_watchInterventions',
    value: function _watchInterventions() {
      var _this3 = this;

      this._scope.$watch(function () {
        return _this3.item ? _this3.item.intervention : null;
      }, function (newIntervention) {
        if (!newIntervention || !newIntervention.attributes) {
          return;
        }

        _this3.item.attributes = newIntervention.attributes.map(function (attribute) {
          if (attribute.values && attribute.values.length) {
            attribute.values = convertNumericValues(attribute.values);
          }

          if (_this3.item.attributes) {
            var item = findAttributeById(attribute.id, _this3.item.attributes);
            if (item) {
              return Object.assign({}, item, { intervention_attribute: attribute });
            }
          }

          return {
            id: _nodeUuid2.default.v4(),
            intervention_attribute: Object.assign({}, attribute),
            intervention_attribute_value: null,
            value: null
          };
        });
      });
    }
  }, {
    key: '_watchUsers',
    value: function _watchUsers() {
      var _this4 = this;

      this._scope.$watchGroup([function () {
        if (_this4.item && _this4.item.crew_members && _this4.item.crew_members[0]) {
          return _this4.item.crew_members[0];
        }
      }, function () {
        if (_this4.item && _this4.item.crew_members && _this4.item.crew_members[1]) {
          return _this4.item.crew_members[1];
        }
      }], function (newVals, oldVals) {
        for (var i = 0; i < newVals.length; i++) {
          _this4._disableSelectedCrewMember(newVals[i], oldVals[i]);
        }
      });
    }
  }, {
    key: '_watchPerformedByThirdParty',
    value: function _watchPerformedByThirdParty() {
      var _this5 = this;

      this._scope.$watch(function () {
        return _this5.item ? _this5.item.performed_by_third_party : null;
      }, function (newVal) {
        if (newVal === true) {
          _this5.item.crew_members = [];
        }
      });
    }
  }, {
    key: '_transformIntervention',
    value: function _transformIntervention(model) {
      var intervention = (0, _angular.copy)(model);

      intervention.attributes.forEach(function (item) {
        delete item.values;
        delete item.intervention_attribute.values;
      });

      delete intervention.body_part.injuries;
      delete intervention.body_part.illnesses;
      delete intervention.body_part.interventions;

      if (intervention.crew_members) {
        Object.keys(intervention.crew_members).forEach(function (key) {
          delete intervention.crew_members[key].name;
          delete intervention.crew_members[key].disabled;
        });
      }

      return intervention;
    }
  }, {
    key: '_disableSelectedCrewMember',
    value: function _disableSelectedCrewMember(newCrewMember, oldCrewMember) {
      this._timeout(function () {
        if (newCrewMember) {
          newCrewMember.disabled = true;
        }
        if (oldCrewMember) {
          oldCrewMember.disabled = false;
        }
      });
    }
  }]);

  return InterventionDialogController;
}(_resourceDialogController2.default);

exports.default = InterventionDialogController;

},{"angular":"angular","common/resource/controllers/resourceDialogController":56,"node-uuid":"node-uuid"}],212:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _resourceDialog = require('common/resource/services/resourceDialog');

var _resourceDialog2 = _interopRequireDefault(_resourceDialog);

var _interventionDialog = require('./intervention-dialog.controller');

var _interventionDialog2 = _interopRequireDefault(_interventionDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var InterventionDialog = function (_ResourceDialog) {
  _inherits(InterventionDialog, _ResourceDialog);

  function InterventionDialog() {
    _classCallCheck(this, InterventionDialog);

    return _possibleConstructorReturn(this, (InterventionDialog.__proto__ || Object.getPrototypeOf(InterventionDialog)).apply(this, arguments));
  }

  _createClass(InterventionDialog, [{
    key: 'init',
    value: function init() {
      _get(InterventionDialog.prototype.__proto__ || Object.getPrototypeOf(InterventionDialog.prototype), 'init', this).call(this);
      this.setController(_interventionDialog2.default);
      this.setTemplateUrl('core/prf/services/intervention-dialog/intervention-dialog.tpl.html');
    }
  }]);

  return InterventionDialog;
}(_resourceDialog2.default);

exports.default = InterventionDialog;

},{"./intervention-dialog.controller":211,"common/resource/services/resourceDialog":59}],213:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SECTIONS = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _nodeUuid = require('node-uuid');

var _nodeUuid2 = _interopRequireDefault(_nodeUuid);

var _resourceDialogController = require('common/resource/controllers/resourceDialogController');

var _resourceDialogController2 = _interopRequireDefault(_resourceDialogController);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var SECTIONS = exports.SECTIONS = [{
  name: 'Blood Gasses',
  attributes: [{ label: 'pH', name: 'blood_gasses_ph', type: 'number' }, { label: 'pCO2', name: 'blood_gasses_pco2', type: 'number' }, { label: 'pO2', name: 'blood_gasses_po2', type: 'number' }, { label: 'HCO3', name: 'blood_gasses_hco3', type: 'number' }, { label: 'BE', name: 'blood_gasses_be', type: 'number', signed: true }, { label: 'Lactate', name: 'blood_gasses_lactate', type: 'number' }, { label: 'Glucose', name: 'blood_gasses_glucose', type: 'number' }, { label: 'Na', name: 'blood_gasses_na', type: 'number' }, { label: 'K', name: 'blood_gasses_k', type: 'number' }]
}, {
  name: 'Haematology',
  attributes: [{ label: 'Haemoglobin', name: 'haematology_hemoglobin', type: 'number' }, { label: 'WCC', name: 'haematology_wcc', type: 'number' }, { label: 'Neutrophils', name: 'haematology_neutrophils', type: 'number' }, { label: 'Platelets', name: 'haematology_platelets', type: 'number' }, { label: 'CRP', name: 'haematology_crp', type: 'number' }, { label: 'PT', name: 'haematology_pt', type: 'number' }, { label: 'INR', name: 'haematology_inr', type: 'number' }, { label: 'APTT', name: 'haematology_aptt', type: 'number' }]
}, {
  name: 'Biochemistry',
  attributes: [{ label: 'Na', name: 'biochemistry_na', type: 'number' }, { label: 'K', name: 'biochemistry_k', type: 'number' }, { label: 'Calcium', name: 'biochemistry_calcium', type: 'number' }, { label: 'Urea', name: 'biochemistry_urea', type: 'number' }, { label: 'Creatinine', name: 'biochemistry_creatinine', type: 'number' }, { label: 'Chloride', name: 'biochemistry_chloride', type: 'number' }]
}, {
  name: 'Microbiology',
  attributes: [{ label: 'Blood Cultures', name: 'microbiology_blood_cultures', type: 'text' }, { label: 'LP', name: 'microbiology_lp', type: 'text' }, { label: 'Stool', name: 'microbiology_stool', type: 'text' }, { label: 'Urine', name: 'microbiology_urine', type: 'text' }]
}, {
  name: 'Virology',
  attributes: [{ label: 'NPA', name: 'virology_npa', type: 'text' }, { label: 'Rapid flu test', name: 'virology_rapid_flu_test', type: 'text' }, { label: 'Stool', name: 'virology_stool', type: 'text' }, { label: 'Other', name: 'virology_urine', type: 'text' }]
}];

var LaboratoryFindingsDialogController = function (_ResourceDialogContro) {
  _inherits(LaboratoryFindingsDialogController, _ResourceDialogContro);

  function LaboratoryFindingsDialogController() {
    _classCallCheck(this, LaboratoryFindingsDialogController);

    return _possibleConstructorReturn(this, (LaboratoryFindingsDialogController.__proto__ || Object.getPrototypeOf(LaboratoryFindingsDialogController)).apply(this, arguments));
  }

  _createClass(LaboratoryFindingsDialogController, [{
    key: 'init',
    value: function init() {
      _get(LaboratoryFindingsDialogController.prototype.__proto__ || Object.getPrototypeOf(LaboratoryFindingsDialogController.prototype), 'init', this).call(this);
      this.numericPattern = /^[0-9]{1,6}(\.[0-9]{1,2})?$/;
      this.signedNumericPattern = /^-?[0-9]{1,6}(\.[0-9]{1,2})?$/;
      this.sections = [].concat(SECTIONS);
    }
  }, {
    key: 'submit',
    value: function submit(item) {
      if (!this.form) throw new Error('Form not found.');
      if (this.form.$invalid) return;
      if (!item.id) item.id = _nodeUuid2.default.v4();
      return this.Dialog.hide({ item: item });
    }
  }, {
    key: 'delete',
    value: function _delete(id) {
      var _this2 = this;

      this.Dialog.confirm().then(function () {
        _this2.Dialog.cancel(id);
      });
    }
  }]);

  return LaboratoryFindingsDialogController;
}(_resourceDialogController2.default);

exports.default = LaboratoryFindingsDialogController;

},{"common/resource/controllers/resourceDialogController":56,"node-uuid":"node-uuid"}],214:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _resourceDialog = require('common/resource/services/resourceDialog');

var _resourceDialog2 = _interopRequireDefault(_resourceDialog);

var _laboratoryFindingsDialog = require('./laboratory-findings-dialog.controller');

var _laboratoryFindingsDialog2 = _interopRequireDefault(_laboratoryFindingsDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var LaboratoryFindingsDialog = function (_ResourceDialog) {
  _inherits(LaboratoryFindingsDialog, _ResourceDialog);

  function LaboratoryFindingsDialog() {
    _classCallCheck(this, LaboratoryFindingsDialog);

    return _possibleConstructorReturn(this, (LaboratoryFindingsDialog.__proto__ || Object.getPrototypeOf(LaboratoryFindingsDialog)).apply(this, arguments));
  }

  _createClass(LaboratoryFindingsDialog, [{
    key: 'init',
    value: function init() {
      _get(LaboratoryFindingsDialog.prototype.__proto__ || Object.getPrototypeOf(LaboratoryFindingsDialog.prototype), 'init', this).call(this);
      this.setController(_laboratoryFindingsDialog2.default);
      this.setTemplateUrl('core/prf/services/laboratory-findings-dialog/laboratory-findings-dialog.tpl.html');
    }
  }]);

  return LaboratoryFindingsDialog;
}(_resourceDialog2.default);

exports.default = LaboratoryFindingsDialog;

},{"./laboratory-findings-dialog.controller":213,"common/resource/services/resourceDialog":59}],215:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = format;

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function format(item) {
  return Object.keys(item).reduce(function (acc, cur) {
    if (cur === 'time') {
      acc[cur] = (0, _moment2.default)(item[cur]).toDate();
    } else if (item[cur] != null && !isNaN(item[cur])) {
      acc[cur] = parseFloat(item[cur]);
    } else {
      acc[cur] = item[cur];
    }

    return acc;
  }, {});
};

},{"moment":"moment"}],216:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = parse;

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _module = require('common/resource/module.constants');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function parse(item) {
  return Object.assign({}, item, {
    time: (0, _moment2.default)(item.time).format(_module.DATE_TIME_FORMAT)
  });
};

},{"common/resource/module.constants":57,"moment":"moment"}],217:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = format;

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _module = require('common/resource/module.constants');

var _observations = require('core/prf/services/observations.service');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function format(_ref) {
  var oxygen = _ref.oxygen,
      oxygen_value = _ref.oxygen_value,
      item = _objectWithoutProperties(_ref, ['oxygen', 'oxygen_value']);

  var dateTime = (0, _moment2.default)(item.time, _module.DATE_TIME_FORMAT).seconds(0).toDate();

  var i = Object.assign({}, item, {
    nibp: item.nibp_systolic != null && item.nibp_diastolic != null ? item.nibp_systolic + '/' + item.nibp_diastolic : null,
    abp: item.abp_systolic != null && item.abp_diastolic != null ? item.abp_systolic + '/' + item.abp_diastolic : null,
    etco2: item.etco2 != null ? parseFloat(item.etco2) : null,
    temp: item.temp != null ? parseFloat(item.temp) : null,
    fio2: item.fio2 != null ? parseFloat(item.fio2) : null,
    flow: item.flow != null ? parseFloat(item.flow) : null,
    mv: item.mv != null ? parseFloat(item.mv) : null,
    psupp: item.psupp != null ? parseFloat(item.psupp) : null,
    cpap: item.cpap != null ? parseFloat(item.cpap) : null,
    ti: item.ti != null ? parseFloat(item.ti) : null,
    time: dateTime,
    date: dateTime,
    disabledFields: [],
    oxygen: parseFloat(oxygen_value),
    oxygenModeOptions: _observations.oxygenOptionMap[item.breathing_mode],
    pupil_size_left: item.pupil_size_left != null ? '' + item.pupil_size_left : null,
    pupil_size_right: item.pupil_size_right != null ? '' + item.pupil_size_right : null
  });

  delete item.nibp_systolic;
  delete item.nibp_diastolic;
  delete item.abp_systolic;
  delete item.abp_diastolic;

  (0, _observations.applyVentilationIntelligence)(i);

  return i;
};

},{"common/resource/module.constants":57,"core/prf/services/observations.service":219,"moment":"moment"}],218:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = parse;

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _module = require('common/resource/module.constants');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function parse(_ref) {
  var oxygenModeOptions = _ref.oxygenModeOptions,
      oxygen = _ref.oxygen,
      item = _objectWithoutProperties(_ref, ['oxygenModeOptions', 'oxygen']);

  var dateStr = (0, _moment2.default)(item.date).format(_module.DATE_FORMAT) + ' ' + (0, _moment2.default)(item.time).format(_module.TIME_FORMAT);

  var i = Object.assign({}, item, {
    time: dateStr,
    pupil_size_left: item.pupil_size_left != null ? parseInt(item.pupil_size_left, 10) : null,
    pupil_size_right: item.pupil_size_right != null ? parseInt(item.pupil_size_right, 10) : null,
    nibp_systolic: item.nibp != null ? item.nibp.split('/')[0] : null,
    nibp_diastolic: item.nibp != null ? item.nibp.split('/')[1] : null,
    abp_systolic: item.abp != null ? item.abp.split('/')[0] : null,
    abp_diastolic: item.abp != null ? item.abp.split('/')[1] : null,
    gcs: item.gcs || null,
    oxygen: (oxygenModeOptions || {}).unitKey,
    oxygen_value: oxygen
  });

  delete i.nibp;
  delete i.abp;
  delete i.disabledFields;

  return i;
};

},{"common/resource/module.constants":57,"moment":"moment"}],219:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.applyVentilationIntelligence = applyVentilationIntelligence;
var oxygenOptionMap = exports.oxygenOptionMap = {
  cpap: {
    label: '%',
    unitKey: 'pct',
    min: 21,
    max: 100
  },
  'ippv-spont': {
    label: '%',
    unitKey: 'pct',
    min: 21,
    max: 100
  },
  'ippv-mand': {
    label: '%',
    unitKey: 'pct',
    min: 21,
    max: 100
  },
  niv: {
    label: '%',
    unitKey: 'pct',
    min: 21,
    max: 100
  },
  sv: {
    label: 'Room Air',
    unitKey: 'room_air'
  },
  fm: {
    label: 'L/min',
    unitKey: 'l-min',
    min: 1,
    max: 15
  },
  nc: {
    label: 'L/min',
    unitKey: 'l-min',
    min: 1,
    max: 15
  },
  hfno: {
    label: '%',
    unitKey: 'pct',
    min: 21,
    max: 100
  }
};

var oxygenOptions = exports.oxygenOptions = [{ label: '-', value: null, modes: [] }, { label: 'FiO2', value: 'fio2', modes: [] }, { label: 'L/min', value: 'l-min', modes: ['fm', 'nc'] }, { label: '%', value: 'pct', modes: ['hfno', 'cpap', 'ippv-spont', 'ippv-mand', 'niv'] }, { label: 'Room Air', value: 'room_air', modes: ['sv'] }];

var ventilationFields = exports.ventilationFields = [{ name: 'oxygen', modes: ['sv', 'fm', 'nc', 'hfno', 'cpap', 'ippv-spont', 'ippv-mand', 'niv'] }, { name: 'respiratory_rate', modes: ['sv', 'fm', 'nc', 'hfno', 'cpap', 'ippv-spont', 'ippv-mand', 'niv'] }, { name: 'spo2', modes: ['sv', 'fm', 'nc', 'hfno', 'cpap', 'ippv-spont', 'ippv-mand', 'niv'] }, { name: 'etco2', modes: ['cpap', 'ippv-spont', 'ippv-mand', 'niv'] }, { name: 'vt', modes: ['ippv-spont', 'ippv-mand'] }, { name: 'mv', modes: ['cpap', 'ippv-spont', 'ippv-mand', 'niv'] }, { name: 'flow', modes: ['hfno'] }, { name: 'ppeak', modes: ['ippv-spont', 'ippv-mand'] }, { name: 'peep', modes: ['cpap', 'ippv-spont', 'ippv-mand', 'niv'] }, { name: 'psupp', modes: ['ippv-spont', 'ippv-mand', 'niv'] }, { name: 'ti', modes: ['ippv-mand'] }, { name: 'cpap', modes: [] }, { name: 'fio2', modes: [] }];

/**
 * This function is used to define which fields should be disabled
 * and which ones should be kept enabled
 */
function applyVentilationIntelligence(obs) {
  obs.disabledFields.length = 0;

  if (obs.breathing_mode === null || obs.breathing_mode === 'sv') {
    obs.oxygen = null;
    obs.oxygenModeOptions = null;
    return true;
  }

  obs.oxygenModeOptions = oxygenOptionMap[obs.breathing_mode];

  ventilationFields.forEach(function (vField) {
    if (!vField.modes.includes(obs.breathing_mode)) {
      obs[vField.name] = null;
      obs.disabledFields.push(vField.name);
    }
  });
};

},{}],220:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _nodeUuid = require('node-uuid');

var _nodeUuid2 = _interopRequireDefault(_nodeUuid);

var _resourceDialogController = require('common/resource/controllers/resourceDialogController');

var _resourceDialogController2 = _interopRequireDefault(_resourceDialogController);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var PatientDetailsDialogController = function (_ResourceDialogContro) {
  _inherits(PatientDetailsDialogController, _ResourceDialogContro);

  function PatientDetailsDialogController() {
    _classCallCheck(this, PatientDetailsDialogController);

    return _possibleConstructorReturn(this, (PatientDetailsDialogController.__proto__ || Object.getPrototypeOf(PatientDetailsDialogController)).apply(this, arguments));
  }

  _createClass(PatientDetailsDialogController, [{
    key: 'init',
    value: function init() {
      _get(PatientDetailsDialogController.prototype.__proto__ || Object.getPrototypeOf(PatientDetailsDialogController.prototype), 'init', this).call(this);
      this._resource = this.$injector.get('PRFResource');
      this._state = this.$injector.get('$state');
      this.maxDateOfBirth = (0, _moment2.default)().toDate();
      this._ID = _nodeUuid2.default.v4();
      this.genders = {
        'Female': 'female',
        'Male': 'male',
        'Other': 'other',
        'Unknown': 'unknown'
      };
      this.booleanEnumValues = [{ name: 'Yes', value: 'yes' }, { name: 'No', value: 'no' }];
      this.allergiesStatusValues = [{ name: 'No known allergies', value: 'no_known_allergies' }, { name: 'Allergy status unknown', value: 'allergy_status_unknown' }, { name: 'Has known allergies', value: 'has_known_allergies' }];
      this.infectionControlIssuesStatusValues = [{ name: 'Yes', value: 'yes' }, { name: 'No', value: 'no' }, { name: 'Colonised', value: 'colonised' }];

      this.selectedInfectionStatuses = [];
      if (this.item && this.item.infectionStatuses) {
        this.selectedInfectionStatuses = this.item.infectionStatuses;
      }
    }
  }, {
    key: 'create',
    value: function create(data) {
      var _this2 = this;

      data.id = this._ID;
      data.incident = this.incident;
      data.deployment = this.deployment;
      data.date = this.incident.date;
      data.infectionStatuses = this.selectedInfectionStatuses;

      data.nhs_number = data.nhs_number || null;
      data.incident_description = data.incident_description || null;
      data.handover_notes = data.handover_notes || null;
      data.allergies_status = data.allergies_status || null;
      data.allergies = data.allergies || null;
      data.address = data.address || null;
      data.weight = data.weight || null;
      data.regular_medication = data.regular_medication || null;
      data.infection_control_issues_status = data.infection_control_issues_status || null;
      data.infection_control_issues = data.infection_control_issues || null;
      data.nok_name = data.nok_name || null;
      data.nok_contact_no = data.nok_contact_no || null;
      data.known_to_social_care = data.known_to_social_care || null;
      data.social_worker_name = data.social_worker_name || null;
      data.social_worker_contact_no = data.social_worker_contact_no || null;
      data.safeguarding_concerns = data.safeguarding_concerns || null;
      data.safeguarding_concerns_notes = data.safeguarding_concerns_notes || null;

      this._resource.create(data).then(function () {
        _this2.Dialog.hide(data);
      }).catch(console.log.bind(console));
    }
  }, {
    key: 'update',
    value: function update(data) {
      var _this3 = this;

      data.infectionStatuses = this.selectedInfectionStatuses;
      this._resource.update(data.id, data).then(function (item) {
        _this3.Dialog.hide(item);
      });
    }
  }, {
    key: 'delete',
    value: function _delete(id) {
      var _this4 = this;

      this.Dialog.promptDelete().then(function () {
        _this4._resource.destroy(id).then(function () {
          _this4.Dialog.cancel(id);
        }).catch(function (err) {
          var msg = 'Error deleting PRF.';
          if (err.status && err.status === 409) {
            msg = err.data.message;
          }
          _this4.Toast.showSimple(msg);
        });
      });
    }
  }]);

  return PatientDetailsDialogController;
}(_resourceDialogController2.default);

exports.default = PatientDetailsDialogController;

},{"common/resource/controllers/resourceDialogController":56,"moment":"moment","node-uuid":"node-uuid"}],221:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _resourceDialog = require('common/resource/services/resourceDialog');

var _resourceDialog2 = _interopRequireDefault(_resourceDialog);

var _patientDetailsDialog = require('./patient-details-dialog.controller');

var _patientDetailsDialog2 = _interopRequireDefault(_patientDetailsDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var PatientDetailDialog = function (_ResourceDialog) {
  _inherits(PatientDetailDialog, _ResourceDialog);

  function PatientDetailDialog() {
    _classCallCheck(this, PatientDetailDialog);

    return _possibleConstructorReturn(this, (PatientDetailDialog.__proto__ || Object.getPrototypeOf(PatientDetailDialog)).apply(this, arguments));
  }

  _createClass(PatientDetailDialog, [{
    key: 'init',

    /**
     * @ngInject
     */
    value: function init() {
      _get(PatientDetailDialog.prototype.__proto__ || Object.getPrototypeOf(PatientDetailDialog.prototype), 'init', this).call(this);
      this.setController(_patientDetailsDialog2.default);
      this.setTemplateUrl('core/prf/services/patient-details-dialog/patient-details-dialog.tpl.html');
    }
  }]);

  return PatientDetailDialog;
}(_resourceDialog2.default);

exports.default = PatientDetailDialog;

},{"./patient-details-dialog.controller":220,"common/resource/services/resourceDialog":59}],222:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = format;

var _crewMemberResource = require('pages/crewMembers/services/crewMemberResource');

var _drug = require('pages/admin/drugs/resources/drug.resource');

var _dateTime = require('common/utils/services/dateTime');

function format(item) {
  var dateTime = (0, _dateTime.toDateTimeObject)(item.time);
  return {
    id: item.id,
    dose: parseFloat(item.dose),
    time: dateTime,
    date: dateTime,
    drug: (0, _drug.format)(item.drug.data),
    min_rate: parseFloat(item.min_rate),
    max_rate: parseFloat(item.max_rate),
    route: item.route.data,
    prescribed_by: (0, _crewMemberResource.format)(item.prescribedBy.data)
  };
}

},{"common/utils/services/dateTime":77,"pages/admin/drugs/resources/drug.resource":304,"pages/crewMembers/services/crewMemberResource":362}],223:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = parse;

var _dateTime = require('common/utils/services/dateTime');

function parse(item) {
  return {
    id: item.id,
    drug_id: item.drug.id,
    min_rate: item.min_rate,
    max_rate: item.max_rate,
    dose: item.dose,
    route_id: item.route.id,
    time: (0, _dateTime.toDateTimeString)(item.date, item.time)
  };
}

},{"common/utils/services/dateTime":77}],224:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = format;

var _crewMemberResource = require('pages/crewMembers/services/crewMemberResource');

var _infusion = require('pages/admin/drugs/resources/infusion.resource');

var _dateTime = require('common/utils/services/dateTime');

function format(item) {
  var dateTime = (0, _dateTime.toDateTimeObject)(item.time);
  return {
    id: item.id,
    min_rate: parseFloat(item.min_rate),
    max_rate: parseFloat(item.max_rate),
    time: dateTime,
    date: dateTime,
    infusion: (0, _infusion.format)(item.infusion.data),
    route: item.route.data,
    prescribed_by: (0, _crewMemberResource.format)(item.prescribedBy.data)
  };
}

},{"common/utils/services/dateTime":77,"pages/admin/drugs/resources/infusion.resource":305,"pages/crewMembers/services/crewMemberResource":362}],225:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = parse;

var _dateTime = require('common/utils/services/dateTime');

function parse(item) {
  return {
    id: item.id,
    infusion_id: item.infusion.id,
    route_id: item.route.id,
    min_rate: item.min_rate,
    max_rate: item.max_rate,
    time: (0, _dateTime.toDateTimeString)(item.date, item.time)
  };
}

},{"common/utils/services/dateTime":77}],226:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var PRFApprovalController = function () {

  /**
   * @ngInject
   * @constructor
   */
  function PRFApprovalController(Dialog) {
    _classCallCheck(this, PRFApprovalController);

    this._Dialog = Dialog;

    this.data = this.data || {};
    this.users = this.users || [];
    this.currentUserEmail = this.currentUserEmail || null;
    this.singlePersonApproval = this.singlePersonApproval || false;

    if (this.singlePersonApproval) {
      this._setupSingleApproval();
    } else {
      this._setupMultiUserApproval();
    }
  }

  _createClass(PRFApprovalController, [{
    key: "_setupSingleApproval",
    value: function _setupSingleApproval() {
      if (this.users.length === 1) {
        this.data.second_user = { email: this.users[0].email };
      }
    }
  }, {
    key: "_setupMultiUserApproval",
    value: function _setupMultiUserApproval() {
      if (this.users.length !== 2) return;

      if (this.data.first_user && this.data.first_user.email) {
        this.data.second_user = { email: this._getSpareEmail(this.data.first_user.email) };
      } else if (this.data.second_user && this.data.second_user.email) {
        this.data.first_user = { email: this._getSpareEmail(this.data.second_user.email) };
      }
    }
  }, {
    key: "_getSpareEmail",
    value: function _getSpareEmail(email) {
      return this.users.filter(function (i) {
        return i.email !== email;
      })[0].email;
    }
  }, {
    key: "submit",
    value: function submit(data) {
      if (this.form.$invalid) return;
      return this._Dialog.hide(data);
    }
  }, {
    key: "cancel",
    value: function cancel() {
      this._Dialog.cancel();
    }
  }]);

  return PRFApprovalController;
}();

exports.default = PRFApprovalController;

},{}],227:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _prfApprovalDialog = require('./prf-approval-dialog.controller');

var _prfApprovalDialog2 = _interopRequireDefault(_prfApprovalDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var TransactionApprovalDialog = function () {
  /**
   * @constructor
   * @ngInject
   */
  function TransactionApprovalDialog(Dialog) {
    _classCallCheck(this, TransactionApprovalDialog);

    this._Dialog = Dialog;
    this._dialogOptions = {
      controller: _prfApprovalDialog2.default,
      templateUrl: 'core/prf/services/prf-approval-dialog/prf-approval-dialog.tpl.html'
    };
  }

  _createClass(TransactionApprovalDialog, [{
    key: 'show',
    value: function show(_ref) {
      var $event = _ref.$event,
          users = _ref.users,
          data = _ref.data;

      var dialogParams = Object.assign({}, this._dialogOptions, {
        targetEvent: $event || null,
        multiple: true,
        locals: { users: users, data: data }
      });
      return this._Dialog.show(dialogParams);
    }
  }, {
    key: 'showSingle',
    value: function showSingle(_ref2) {
      var $event = _ref2.$event,
          users = _ref2.users,
          data = _ref2.data;

      var dialogParams = Object.assign({}, this._dialogOptions, {
        targetEvent: $event || null,
        multiple: true,
        locals: { singlePersonApproval: true, users: users, data: data }
      });
      return this._Dialog.show(dialogParams);
    }
  }]);

  return TransactionApprovalDialog;
}();

exports.default = TransactionApprovalDialog;

},{"./prf-approval-dialog.controller":226}],228:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = format;

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _deployment = require('core/deployments/resources/deployment.resource');

var _incident = require('core/incidents/resources/incident.resource');

var _drugStockTransaction = require('pages/admin/drugs/resources/drug-stock-transaction.resource');

var _clinicalIncident = require('core/prf/resources/clinical-incident.resource');

var _handover = require('core/prf/resources/handover.resource');

var _infectionStatus = require('core/categories/resources/infection-status.resource');

var _surveyFormatter = require('./survey-formatter.service');

var _surveyFormatter2 = _interopRequireDefault(_surveyFormatter);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function format(item) {
  var formatted = {
    id: item.id,
    reference_id: item.reference_id,
    first_name: item.first_name,
    last_name: item.last_name,
    gender: item.gender,
    patient_type: item.patientType ? item.patientType.data : null,
    weight: item.weight ? item.weight * 0.001 : null,
    nhs_number: item.nhs_number,
    address: item.address,
    incident_description: item.incident_description,
    handover_notes: item.handover_notes,
    approval_status: item.approval_status,
    allergies_status: item.allergies_status,
    allergies: item.allergies,
    regular_medication: item.regular_medication,
    infection_control_issues_status: item.infection_control_issues_status,
    infection_control_issues: item.infection_control_issues,
    nok_name: item.nok_name,
    nok_contact_no: item.nok_contact_no,
    known_to_social_care: item.known_to_social_care,
    social_worker_name: item.social_worker_name,
    social_worker_contact_no: item.social_worker_contact_no,
    safeguarding_concerns: item.safeguarding_concerns,
    safeguarding_concerns_notes: item.safeguarding_concerns_notes,
    infection_status_comments: item.infection_status_comments
  };

  if (item.date_of_birth) {
    formatted.date_of_birth = (0, _moment2.default)(item.date_of_birth, 'YYYY-MM-DD').toDate();
  }

  if (item.incident) {
    formatted.incident = (0, _incident.format)(item.incident.data);
  }

  if (item.deployment) {
    formatted.deployment = (0, _deployment.format)(item.deployment.data);
  }

  if (item.surveys) {
    formatted.surveys = item.surveys.data.map(_surveyFormatter2.default);
  }

  if (item.approval) {
    formatted.approval = { id: item.approval.data.id };
    if (item.approval.data.firstUser) {
      formatted.approval.first_user = item.approval.data.firstUser.data;
    }
    if (item.approval.data.secondUser) {
      formatted.approval.second_user = item.approval.data.secondUser.data;
    }
  }

  if (item.stockTransactions) {
    formatted.stock_transactions = item.stockTransactions.data.map(_drugStockTransaction.format);
  }

  if (item.images) {
    formatted.images = item.images.data;
  }

  if (item.attachments) {
    formatted.attachments = item.attachments.data;
  }

  if (item.clinicalIncident) {
    formatted.clinical_incident = (0, _clinicalIncident.format)(item.clinicalIncident.data);
  }

  if (item.handover) {
    formatted.handover = (0, _handover.format)(item.handover.data);
  }

  if (item.infectionStatuses) {
    formatted.infectionStatuses = item.infectionStatuses.data.map(_infectionStatus.format);
    formatted.infectionStatusesString = formatted.infectionStatuses.map(function (_ref) {
      var name = _ref.name;
      return name;
    }).join(', ');
  }

  return formatted;
}

},{"./survey-formatter.service":234,"core/categories/resources/infection-status.resource":95,"core/deployments/resources/deployment.resource":125,"core/incidents/resources/incident.resource":165,"core/prf/resources/clinical-incident.resource":185,"core/prf/resources/handover.resource":186,"moment":"moment","pages/admin/drugs/resources/drug-stock-transaction.resource":302}],229:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = parse;

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _clinicalIncident = require('core/prf/resources/clinical-incident.resource');

var _handover = require('core/prf/resources/handover.resource');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function parse(item) {
  var parsed = {
    id: item.id,
    first_name: item.first_name,
    last_name: item.last_name,
    gender: item.gender,
    nhs_number: item.nhs_number,
    address: item.address,
    incident_description: item.incident_description,
    handover_notes: item.handover_notes,
    allergies_status: item.allergies_status,
    allergies: item.allergies,
    regular_medication: item.regular_medication,
    infection_control_issues_status: item.infection_control_issues_status,
    infection_control_issues: item.infection_control_issues,
    nok_name: item.nok_name,
    nok_contact_no: item.nok_contact_no,
    known_to_social_care: item.known_to_social_care,
    social_worker_name: item.social_worker_name,
    social_worker_contact_no: item.social_worker_contact_no,
    safeguarding_concerns: item.safeguarding_concerns,
    safeguarding_concerns_notes: item.safeguarding_concerns_notes,
    infection_status_comments: item.infection_status_comments ? item.infection_status_comments : null
  };

  parsed.date_of_birth = item.date_of_birth ? (0, _moment2.default)(item.date_of_birth).format('YYYY-MM-DD') : null;
  parsed.weight = item.weight ? Math.round(item.weight / 0.001) : null;
  parsed.patient_type_id = item.patient_type ? item.patient_type.id : null;
  parsed.deployment_id = item.deployment ? item.deployment.id : null;

  if (item.clinical_incident) {
    parsed.clinical_incident = (0, _clinicalIncident.parse)(item.clinical_incident);
  }

  if (item.handover) {
    parsed.handover = (0, _handover.parse)(item.handover);
  }

  if (item.infectionStatuses) {
    parsed.infection_statuses = item.infectionStatuses.map(function (_ref) {
      var id = _ref.id;
      return id;
    });
  }

  return parsed;
}

},{"core/prf/resources/clinical-incident.resource":185,"core/prf/resources/handover.resource":186,"moment":"moment"}],230:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _nodeUuid = require('node-uuid');

var _nodeUuid2 = _interopRequireDefault(_nodeUuid);

var _resourceDialogController = require('common/resource/controllers/resourceDialogController');

var _resourceDialogController2 = _interopRequireDefault(_resourceDialogController);

var _module = require('common/resource/module.constants');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var RecordDrugUseDialogController = function (_ResourceDialogContro) {
  _inherits(RecordDrugUseDialogController, _ResourceDialogContro);

  function RecordDrugUseDialogController() {
    _classCallCheck(this, RecordDrugUseDialogController);

    return _possibleConstructorReturn(this, (RecordDrugUseDialogController.__proto__ || Object.getPrototypeOf(RecordDrugUseDialogController)).apply(this, arguments));
  }

  _createClass(RecordDrugUseDialogController, [{
    key: 'init',
    value: function init() {
      var _this2 = this;

      _get(RecordDrugUseDialogController.prototype.__proto__ || Object.getPrototypeOf(RecordDrugUseDialogController.prototype), 'init', this).call(this);
      if (!this.prf || !this.onFormSubmit) {
        throw new Error('PRF or onFormSubmit was not set.');
      }

      this.$q = this.$injector.get('$q');
      this.$timeout = this.$injector.get('$timeout');
      this.StockLocationResource = this.$injector.get('StockLocationResource');
      this.DrugBatchesResource = this.$injector.get('DrugBatchesResource');
      this.PRFApprovalDialog = this.$injector.get('PRFApprovalDialog');
      this.Dialog = this.$injector.get('Dialog');
      this.Session = this.$injector.get('Session');
      this.APP_ORG_SHORT = this.$injector.get('APP_ORG_SHORT');

      this.dateString = this.prf.deployment.date.format('dddd DD MMMM, YYYY [at] HH:MM');
      this.incidentNumber = this.prf.incident.incident_number;

      this.batches = {};
      this.administeredDrugData = this._getDrugData(this.prf.surveys);
      this.transactions = this._getTransactions(this.prf.stock_transactions);

      this.StockLocationResource.index().then(function (locations) {
        return _this2.stockLocations = locations;
      });
      this._loadBatches();
    }
  }, {
    key: 'newTransaction',
    value: function newTransaction(drugId, locationId) {
      if (!this.transactions[drugId]) this.transactions[drugId] = [];

      var transaction = { id: _nodeUuid2.default.v4(), location: { id: locationId } };
      if (locationId) {
        transaction.location = { id: locationId };
        var drugBatches = this.batches[drugId][locationId];
        if (drugBatches && drugBatches.expiries && drugBatches.expiries.length === 1) {
          transaction.expires_on = drugBatches.expiries[0];
          if (drugBatches.expiryMap[transaction.expires_on].length === 1) {
            transaction.batch_id = drugBatches.expiryMap[transaction.expires_on][0].id;
          }
        }
      }

      this.transactions[drugId] = [].concat(_toConsumableArray(this.transactions[drugId]), [transaction]);
    }
  }, {
    key: 'removeTransaction',
    value: function removeTransaction(drugId, id) {
      this.transactions[drugId] = this.transactions[drugId].filter(function (item) {
        return item.id !== id;
      });
    }
  }, {
    key: 'create',
    value: function create(transactions) {
      var _this3 = this;

      var users = this.prf.deployment.crew_members.map(function (i) {
        return { email: i.email, name: i.first_name + ' ' + i.last_name };
      });
      var data = { first_user: { email: this.Session.user().email } };
      return this._approveDrugAmounts(transactions).then(function () {
        return _this3.PRFApprovalDialog.show({ users: users, data: data });
      }).then(function (users) {
        return _this3.onFormSubmit(_this3._parseData(transactions, users, _this3.prf.id));
      }).then(function () {
        _this3.Dialog.hide();
      });
    }
  }, {
    key: '_getDrugData',
    value: function _getDrugData(surveys) {
      if (!surveys || !Array.isArray(surveys)) return {};

      return surveys
      // Flatten administered drugs from surveys
      .reduce(function (acc, survey) {
        return acc.concat(survey.administered_drugs);
      }, [])
      // Flatten drug data from administered drugs
      .reduce(function (acc, administeredDrug) {
        if (administeredDrug.prescription.drug.exclude_from_stock) return acc;
        var drugId = administeredDrug.prescription.drug.id;

        if (acc.hasOwnProperty(drugId)) {
          acc[drugId].totalDose += administeredDrug.dose;
          return acc;
        }

        acc[drugId] = {
          id: drugId,
          name: administeredDrug.prescription.drug.name,
          totalDose: administeredDrug.dose,
          unit: administeredDrug.prescription.drug.measurement_type
        };
        return acc;
      }, {});
    }
  }, {
    key: '_getTransactions',
    value: function _getTransactions(stockTransactions) {
      if (!stockTransactions || !Array.isArray(stockTransactions)) return {};

      return stockTransactions.reduce(function (acc, transaction) {
        return Object.assign({}, acc, _defineProperty({}, transaction.drug.id, [].concat(_toConsumableArray(acc.hasOwnProperty(transaction.drug.id) ? acc[transaction.drug.id] : []), [Object.assign({
          expires_on: transaction.batch.expires_on.split(' ')[0],
          batch_id: transaction.batch.id
        }, transaction)])));
      }, {});
    }
  }, {
    key: '_loadBatches',
    value: function _loadBatches() {
      var _this4 = this;

      Object.keys(this.administeredDrugData).forEach(function (drugId) {
        _this4._getBatchesForDrug(drugId).then(function (batches) {
          _this4.batches[drugId] = Object.assign({}, _this4._getDrugBatchData(batches));
        }).catch(function () {
          var drug = _this4.administeredDrugData[drugId].name;
          _this4.Dialog.alert('Error retrieving batches for ' + drug + '.');
        });
      });
    }
  }, {
    key: '_getBatchesForDrug',
    value: function _getBatchesForDrug(drugId) {
      return this.DrugBatchesResource.index({
        drug_id: drugId,
        include: 'drug,location',
        quantity_mode: 'non-empty',
        expiry_mode: 'non-expired',
        received_on_end: this.prf.deployment.date.format(_module.DATE_FORMAT)
      });
    }
  }, {
    key: '_getDrugBatchData',
    value: function _getDrugBatchData(batches) {
      return batches.reduce(function (acc, item) {
        var locationId = item.location.id;
        if (!acc.hasOwnProperty(locationId)) acc[locationId] = { expiryMap: {}, expiries: [] };

        var expiry = item.expires_on.split(' ')[0];
        if (!acc[locationId].expiryMap.hasOwnProperty(expiry)) {
          acc[locationId].expiryMap[expiry] = [];
          acc[locationId].expiries.push(expiry);
        }

        acc[locationId].expiryMap[expiry].push(item);
        return acc;
      }, {});
    }
  }, {
    key: '_approveDrugAmounts',
    value: function _approveDrugAmounts(transactions) {
      var _this5 = this;

      // Add up total trug amounts recorded & check if amount given and amount wasted adds up for each transaction
      var totalAmounts = {};
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = Object.keys(transactions)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var drugId = _step.value;

          totalAmounts[drugId] = 0;

          var _loop = function _loop(unit) {
            var totalAmount = parseFloat(unit.amount_given) + parseFloat(unit.amount_wasted);
            // This is needed for older data, in case batches are empty
            if (!_this5.batches.hasOwnProperty(drugId)) return 'continue';

            var batch = _this5.batches[drugId][unit.location.id].expiryMap[unit.expires_on].find(function (i) {
              return i.id === unit.batch_id;
            });

            if (totalAmount < batch.dose_per_unit || totalAmount % batch.dose_per_unit !== 0) {
              _this5.Dialog.alert('Invalid sum of amount given and amount wasted for ' + batch.drug.name + ' ' + ('batch ' + batch.batch_number + ' (' + batch.location.name + ').'), 'Invalid amount');
              return {
                v: _this5.$q.reject()
              };
            }
            totalAmounts[drugId] += unit.amount_given;
          };

          var _iteratorNormalCompletion3 = true;
          var _didIteratorError3 = false;
          var _iteratorError3 = undefined;

          try {
            for (var _iterator3 = transactions[drugId][Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
              var unit = _step3.value;

              var _ret = _loop(unit);

              switch (_ret) {
                case 'continue':
                  continue;

                default:
                  if ((typeof _ret === 'undefined' ? 'undefined' : _typeof(_ret)) === "object") return _ret.v;
              }
            }
          } catch (err) {
            _didIteratorError3 = true;
            _iteratorError3 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion3 && _iterator3.return) {
                _iterator3.return();
              }
            } finally {
              if (_didIteratorError3) {
                throw _iteratorError3;
              }
            }
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      this.$q.reject();

      var checkedAmounts = {};
      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = Object.keys(this.administeredDrugData)[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var _drugId = _step2.value;

          var drug = this.administeredDrugData[_drugId];
          if (totalAmounts[_drugId] > this.administeredDrugData[_drugId].totalDose) {
            this.Dialog.alert(drug.name + ' amount recorded is higher than administered.', 'Invalid amount');
            return this.$q.reject();
          }
          checkedAmounts[_drugId] = totalAmounts[_drugId] === drug.totalDose;
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }

      var invalidAmounts = Object.keys(checkedAmounts).filter(function (key) {
        return !checkedAmounts[key];
      }).map(function (drugId) {
        return _this5.administeredDrugData[drugId].name;
      });

      var len = invalidAmounts.length;
      if (len) {
        var drugStr = len > 1 ? invalidAmounts.slice(0, len - 1).join(', ') + (' & ' + invalidAmounts[len - 1]) : invalidAmounts[0];

        var msg = 'Please confirm that some or all of ' + drugStr + ' administered ' + (len > 1 ? 'were' : 'was') + (' not from ' + this.APP_ORG_SHORT + ' stock.');
        return this.Dialog.confirm(msg);
      }

      return this.$q.resolve();
    }
  }, {
    key: '_parseData',
    value: function _parseData(data, users, prfId) {
      var parsed = {
        timestamp: (0, _moment2.default)().format(_module.DATE_TIME_FORMAT),
        patient_report_form_id: prfId,
        drugs_administered: Object.values(data).reduce(function (acc, cur) {
          return acc.concat(cur);
        }, []),
        first_user: users.first_user
      };

      if (users.second_user && users.second_user.email && users.second_user.email.length && users.second_user.password && users.second_user.password.length) {
        parsed.second_user = users.second_user;
      }

      return parsed;
    }
  }]);

  return RecordDrugUseDialogController;
}(_resourceDialogController2.default);

exports.default = RecordDrugUseDialogController;

},{"common/resource/controllers/resourceDialogController":56,"common/resource/module.constants":57,"moment":"moment","node-uuid":"node-uuid"}],231:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _resourceDialog = require('common/resource/services/resourceDialog');

var _resourceDialog2 = _interopRequireDefault(_resourceDialog);

var _recordDrugUseDialog = require('./record-drug-use-dialog.controller');

var _recordDrugUseDialog2 = _interopRequireDefault(_recordDrugUseDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var RecordDrugUseDialog = function (_ResourceDialog) {
  _inherits(RecordDrugUseDialog, _ResourceDialog);

  function RecordDrugUseDialog() {
    _classCallCheck(this, RecordDrugUseDialog);

    return _possibleConstructorReturn(this, (RecordDrugUseDialog.__proto__ || Object.getPrototypeOf(RecordDrugUseDialog)).apply(this, arguments));
  }

  _createClass(RecordDrugUseDialog, [{
    key: 'init',
    value: function init() {
      _get(RecordDrugUseDialog.prototype.__proto__ || Object.getPrototypeOf(RecordDrugUseDialog.prototype), 'init', this).call(this);
      this.setController(_recordDrugUseDialog2.default);
      this.setTemplateUrl('core/prf/services/record-drug-use-dialog/record-drug-use-dialog.tpl.html');
    }
  }]);

  return RecordDrugUseDialog;
}(_resourceDialog2.default);

exports.default = RecordDrugUseDialog;

},{"./record-drug-use-dialog.controller":230,"common/resource/services/resourceDialog":59}],232:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _module = require('common/resource/module.constants');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function getAdministeredDrugsFromSurveys(surveys) {
  var administeredDrugs = {};
  surveys
  // Flatten all administered and not excluded from stock drugs from surveys
  .reduce(function (acc, cur) {
    return acc.concat(cur.administered_drugs);
  }, [])
  // Flatten all drugs from administered drugs, sum dosages, filter out duplicates
  .forEach(function (item) {
    if (item.drug.exclude_from_stock) return;
    var drugId = item.drug.id;
    if (!administeredDrugs[drugId]) {
      administeredDrugs[drugId] = {
        name: item.drug.name,
        totalDose: 0,
        unit: item.drug.measurement_type
      };
    }
    administeredDrugs[drugId].totalDose = administeredDrugs[drugId].totalDose + parseFloat(item.dose);
  });
  return administeredDrugs;
}

function getTransactionsByDrug(stockTransactions) {
  var transactions = {};
  stockTransactions.forEach(function (item) {
    var drugId = item.drug.id;
    if (!transactions.hasOwnProperty(drugId)) transactions[drugId] = [];
    item.batch.expires_on = (0, _moment2.default)(item.batch.expires_on).format(_module.DATE_FORMAT);
    transactions[drugId].push(item);
  });
  return transactions;
}

var ReviewDrugUseDialogController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function ReviewDrugUseDialogController(Dialog, PRFApprovalDialog, APP_ORG_SHORT) {
    _classCallCheck(this, ReviewDrugUseDialogController);

    this.Dialog = Dialog;
    this.PRFApprovalDialog = PRFApprovalDialog;
    this.APP_ORG_SHORT = APP_ORG_SHORT;

    if (!this.prf) throw new Error('PRF not set.');
    if (!this.onApproveClick) throw new Error('Approval callback not set.');
    if (!this.onRejectClick) throw new Error('Reject callback not set.');
  }

  _createClass(ReviewDrugUseDialogController, [{
    key: '$onInit',
    value: function $onInit() {
      this.administeredDrugs = getAdministeredDrugsFromSurveys(this.prf.surveys);
      this.transactions = getTransactionsByDrug(this.prf.stock_transactions);
    }
  }, {
    key: 'approve',
    value: function approve(_ref) {
      var _this = this;

      var $event = _ref.$event;

      this.onApproveClick({ $event: $event }).then(function () {
        _this.Dialog.hide();
      });
    }
  }, {
    key: 'cancel',
    value: function cancel() {
      this.Dialog.cancel();
    }
  }, {
    key: 'reject',
    value: function reject() {
      var _this2 = this;

      this.onRejectClick().then(function () {
        _this2.Dialog.hide();
      });
    }
  }]);

  return ReviewDrugUseDialogController;
}();

exports.default = ReviewDrugUseDialogController;

},{"common/resource/module.constants":57,"moment":"moment"}],233:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _angular = require('angular');

var _reviewDrugUseDialog = require('./review-drug-use-dialog.controller');

var _reviewDrugUseDialog2 = _interopRequireDefault(_reviewDrugUseDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var ReviewDrugUseDialog = function () {
  /**
   * @constructor
   * @ngInject
   */
  function ReviewDrugUseDialog(Dialog) {
    _classCallCheck(this, ReviewDrugUseDialog);

    this.Dialog = Dialog;
    this._dialogParams = {
      controller: _reviewDrugUseDialog2.default,
      templateUrl: 'core/prf/services/review-drug-use-dialog/review-drug-use-dialog.tpl.html',
      locals: {}
    };
  }

  _createClass(ReviewDrugUseDialog, [{
    key: 'show',
    value: function show(_ref) {
      var $event = _ref.$event,
          prf = _ref.prf,
          onApproveClick = _ref.onApproveClick,
          onRejectClick = _ref.onRejectClick;

      var dialogParams = {
        locals: { prf: (0, _angular.copy)(prf), onApproveClick: onApproveClick, onRejectClick: onRejectClick },
        targetEvent: $event || null
      };
      return this.Dialog.show(Object.assign({}, this._dialogParams, dialogParams));
    }
  }]);

  return ReviewDrugUseDialog;
}();

exports.default = ReviewDrugUseDialog;

},{"./review-drug-use-dialog.controller":232,"angular":"angular"}],234:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = format;

var _administeredDrugFormatter = require('./administered-drug-formatter.service');

var _administeredDrugFormatter2 = _interopRequireDefault(_administeredDrugFormatter);

var _assessedInjuryFormatter = require('./assessed-injury-formatter.service');

var _assessedInjuryFormatter2 = _interopRequireDefault(_assessedInjuryFormatter);

var _assessedIllnessFormatter = require('./assessed-illness-formatter.service');

var _assessedIllnessFormatter2 = _interopRequireDefault(_assessedIllnessFormatter);

var _administeredInterventionFormatter = require('./administered-intervention-formatter.service');

var _administeredInterventionFormatter2 = _interopRequireDefault(_administeredInterventionFormatter);

var _laboratoryFindingsFormatter = require('./laboratory-findings.formatter.service');

var _laboratoryFindingsFormatter2 = _interopRequireDefault(_laboratoryFindingsFormatter);

var _observationsFormatter = require('./observations-formatter.service');

var _observationsFormatter2 = _interopRequireDefault(_observationsFormatter);

var _administeredInfusionFormatter = require('./administered-infusion-formatter.service');

var _administeredInfusionFormatter2 = _interopRequireDefault(_administeredInfusionFormatter);

var _prescribedDrugFormatter = require('./prescribed-drug-formatter.service');

var _prescribedDrugFormatter2 = _interopRequireDefault(_prescribedDrugFormatter);

var _prescribedInfusionFormatter = require('./prescribed-infusion-formatter.service');

var _prescribedInfusionFormatter2 = _interopRequireDefault(_prescribedInfusionFormatter);

var _dateTime = require('common/utils/services/dateTime');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function format(item) {
  var dateTime = (0, _dateTime.toDateTimeObject)(item.time);

  var formatted = {
    id: item.id,
    time: dateTime,
    date: dateTime,
    name: item.name,
    comment: item.comment
  };

  formatted.observations = item.observations ? item.observations.data.map(_observationsFormatter2.default) : [];

  formatted.laboratory_findings = item.laboratoryFindings ? item.laboratoryFindings.data.map(_laboratoryFindingsFormatter2.default) : [];

  formatted.administered_drugs = item.administeredDrugs ? item.administeredDrugs.data.map(_administeredDrugFormatter2.default) : [];

  formatted.assessed_injuries = item.assessedInjuries ? item.assessedInjuries.data.map(_assessedInjuryFormatter2.default) : [];

  formatted.assessed_illnesses = item.assessedIllnesses ? item.assessedIllnesses.data.map(_assessedIllnessFormatter2.default) : [];

  formatted.administered_interventions = item.administeredInterventions ? item.administeredInterventions.data.map(_administeredInterventionFormatter2.default) : [];

  formatted.administered_infusions = item.administeredInfusions ? item.administeredInfusions.data.map(_administeredInfusionFormatter2.default) : [];

  formatted.prescribed_drugs = item.prescribedDrugs ? item.prescribedDrugs.data.map(_prescribedDrugFormatter2.default) : [];

  formatted.prescribed_infusions = item.prescribedInfusions ? item.prescribedInfusions.data.map(_prescribedInfusionFormatter2.default) : [];

  return formatted;
}

},{"./administered-drug-formatter.service":190,"./administered-infusion-formatter.service":192,"./administered-intervention-formatter.service":195,"./assessed-illness-formatter.service":197,"./assessed-injury-formatter.service":199,"./laboratory-findings.formatter.service":215,"./observations-formatter.service":217,"./prescribed-drug-formatter.service":222,"./prescribed-infusion-formatter.service":224,"common/utils/services/dateTime":77}],235:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = parse;

var _administeredDrugParser = require('./administered-drug-parser.service');

var _administeredDrugParser2 = _interopRequireDefault(_administeredDrugParser);

var _assessedIllnessParser = require('./assessed-illness-parser.service');

var _assessedIllnessParser2 = _interopRequireDefault(_assessedIllnessParser);

var _assessedInjuryParser = require('./assessed-injury-parser.service');

var _assessedInjuryParser2 = _interopRequireDefault(_assessedInjuryParser);

var _administeredInterventionParser = require('./administered-intervention-parser.service');

var _administeredInterventionParser2 = _interopRequireDefault(_administeredInterventionParser);

var _laboratoryFindingsParser = require('./laboratory-findings.parser.service');

var _laboratoryFindingsParser2 = _interopRequireDefault(_laboratoryFindingsParser);

var _observationsParser = require('./observations-parser.service');

var _observationsParser2 = _interopRequireDefault(_observationsParser);

var _administeredInfusionParser = require('./administered-infusion-parser.service');

var _administeredInfusionParser2 = _interopRequireDefault(_administeredInfusionParser);

var _prescribedDrugParser = require('./prescribed-drug-parser.service');

var _prescribedDrugParser2 = _interopRequireDefault(_prescribedDrugParser);

var _prescribedInfusionParser = require('./prescribed-infusion-parser.service');

var _prescribedInfusionParser2 = _interopRequireDefault(_prescribedInfusionParser);

var _dateTime = require('common/utils/services/dateTime');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function parse(item) {
  var parsed = {
    id: item.id,
    time: (0, _dateTime.toDateTimeString)(item.date, item.time),
    name: item.name || null,
    comment: item.comment || null
  };

  if (item.patient_report_form) {
    parsed.patient_report_form_id = item.patient_report_form.id;
  }

  if (item.observations) {
    parsed.observations = item.observations.map(_observationsParser2.default);
  }

  if (item.laboratory_findings) {
    parsed.laboratory_findings = item.laboratory_findings.map(_laboratoryFindingsParser2.default);
  }

  if (item.administered_drugs) {
    parsed.administered_drugs = item.administered_drugs.map(_administeredDrugParser2.default);
  }

  if (item.assessed_injuries) {
    parsed.assessed_injuries = item.assessed_injuries.map(_assessedInjuryParser2.default);
  }

  if (item.assessed_illnesses) {
    parsed.assessed_illnesses = item.assessed_illnesses.map(_assessedIllnessParser2.default);
  }

  if (item.administered_interventions) {
    parsed.administered_interventions = item.administered_interventions.map(_administeredInterventionParser2.default);
  }

  if (item.administered_infusions) {
    parsed.administered_infusions = item.administered_infusions.map(_administeredInfusionParser2.default);
  }

  if (item.prescribed_drugs) {
    parsed.prescribed_drugs = item.prescribed_drugs.map(_prescribedDrugParser2.default);
  }

  if (item.prescribed_infusions) {
    parsed.prescribed_infusions = item.prescribed_infusions.map(_prescribedInfusionParser2.default);
  }

  return parsed;
}

},{"./administered-drug-parser.service":191,"./administered-infusion-parser.service":193,"./administered-intervention-parser.service":196,"./assessed-illness-parser.service":198,"./assessed-injury-parser.service":200,"./laboratory-findings.parser.service":216,"./observations-parser.service":218,"./prescribed-drug-parser.service":223,"./prescribed-infusion-parser.service":225,"common/utils/services/dateTime":77}],236:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _kpi = require('./resources/kpi.resource');

var _kpi2 = _interopRequireDefault(_kpi);

var _report = require('./resources/report.resource');

var _report2 = _interopRequireDefault(_report);

var _reportables = require('./resources/reportables.resource');

var _reportables2 = _interopRequireDefault(_reportables);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _module = _angular2.default.module('gnaas.core.reports', []).service('KpiResource', _kpi2.default).service('ReportResource', _report2.default).service('ReportablesResource', _reportables2.default);

exports.default = _module.name;

},{"./resources/kpi.resource":237,"./resources/report.resource":238,"./resources/reportables.resource":239,"angular":"angular"}],237:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var KpiResource = function (_RestResource) {
  _inherits(KpiResource, _RestResource);

  function KpiResource() {
    _classCallCheck(this, KpiResource);

    return _possibleConstructorReturn(this, (KpiResource.__proto__ || Object.getPrototypeOf(KpiResource)).apply(this, arguments));
  }

  _createClass(KpiResource, [{
    key: 'init',
    value: function init() {
      _get(KpiResource.prototype.__proto__ || Object.getPrototypeOf(KpiResource.prototype), 'init', this).call(this);
      this.resourcePath = 'kpi';
    }
  }, {
    key: 'get',
    value: function get(from, to) {
      var params = { date_from: from, date_to: to };
      return this._http.post('' + this.apiUrl + this.resourcePath, params).then(function (res) {
        return res.data.data;
      });
    }
  }]);

  return KpiResource;
}(_restResource2.default);

exports.default = KpiResource;

},{"common/resource/services/restResource":60}],238:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ReportResource = function (_RestResource) {
  _inherits(ReportResource, _RestResource);

  function ReportResource() {
    _classCallCheck(this, ReportResource);

    return _possibleConstructorReturn(this, (ReportResource.__proto__ || Object.getPrototypeOf(ReportResource)).apply(this, arguments));
  }

  _createClass(ReportResource, [{
    key: 'init',
    value: function init() {
      _get(ReportResource.prototype.__proto__ || Object.getPrototypeOf(ReportResource.prototype), 'init', this).call(this);
      this.resourcePath = 'reports';
    }
  }, {
    key: 'download',
    value: function download(_ref) {
      var from = _ref.from,
          to = _ref.to,
          _ref$vehicleCallsignI = _ref.vehicleCallsignId,
          vehicleCallsignId = _ref$vehicleCallsignI === undefined ? null : _ref$vehicleCallsignI,
          _ref$csv = _ref.csv,
          csv = _ref$csv === undefined ? false : _ref$csv;

      var url = '' + this.apiUrl + this.resourcePath + '?from=' + from + '&to=' + to + '&csv=' + csv;
      if (vehicleCallsignId) {
        url += '&vehicle_callsign_id=' + vehicleCallsignId;
      }
      var params = {
        url: url,
        method: 'GET'
      };
      return this._http(params).then(function (response) {
        return response.data;
      });
    }
  }]);

  return ReportResource;
}(_restResource2.default);

exports.default = ReportResource;

},{"common/resource/services/restResource":60}],239:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parse = exports.format = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

var _drug = require('pages/admin/drugs/resources/drug.resource');

var _intervention = require('core/categories/resources/intervention.resource');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _format(item) {
  var i = {
    id: item.id,
    reportable_id: item.reportable_id,
    reportable_type: item.reportable_type
  };

  if (item.reportable) {
    switch (item.reportable_type) {
      case 'drug':
        i.reportable = (0, _drug.format)(item.reportable.data);
        break;
      case 'intervention':
        i.reportable = (0, _intervention.format)(item.reportable.data);
        break;
      default:
        i.reportable = null;
        break;
    }
  }

  return i;
}

exports.format = _format;
function _parse(item) {
  return {
    id: item.id,
    reportable_id: item.reportable_id,
    reportable_type: item.reportable_type
  };
}

exports.parse = _parse;

var ReportablesResource = function (_RestResource) {
  _inherits(ReportablesResource, _RestResource);

  function ReportablesResource() {
    _classCallCheck(this, ReportablesResource);

    return _possibleConstructorReturn(this, (ReportablesResource.__proto__ || Object.getPrototypeOf(ReportablesResource)).apply(this, arguments));
  }

  _createClass(ReportablesResource, [{
    key: 'init',
    value: function init() {
      _get(ReportablesResource.prototype.__proto__ || Object.getPrototypeOf(ReportablesResource.prototype), 'init', this).call(this);
      this.resourcePath = 'reportables';
    }
  }, {
    key: 'format',
    value: function format(item) {
      return _format(item);
    }
  }, {
    key: 'parse',
    value: function parse(item) {
      return _parse(item);
    }
  }]);

  return ReportablesResource;
}(_restResource2.default);

exports.default = ReportablesResource;

},{"common/resource/services/restResource":60,"core/categories/resources/intervention.resource":98,"pages/admin/drugs/resources/drug.resource":304}],240:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SearchCategoriesController = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _swacctsMapper = require('swacctsMapper');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var SearchCategoriesController = exports.SearchCategoriesController = function () {
  function SearchCategoriesController() {
    _classCallCheck(this, SearchCategoriesController);

    this.mapSearchCategoryName = _swacctsMapper.mapSearchCategoryName;
  }

  _createClass(SearchCategoriesController, [{
    key: 'isCategoryActive',
    value: function isCategoryActive(name) {
      return name === this.selectedCategoryName;
    }
  }, {
    key: 'hasCriteria',
    value: function hasCriteria(categoryName) {
      return this.query.hasOwnProperty(categoryName) && this.query[categoryName].criteria && this.query[categoryName].criteria.length;
    }
  }, {
    key: 'hasColumnsToDisplay',
    value: function hasColumnsToDisplay(categoryName) {
      return ~['incidents', 'deployments', 'patients'].indexOf(categoryName.toLowerCase()) && this.query.hasOwnProperty(categoryName) && this.query[categoryName].columnsToDisplay && this.query[categoryName].columnsToDisplay.length;
    }
  }]);

  return SearchCategoriesController;
}();

exports.default = {
  bindings: {
    categories: '<',
    query: '<',
    selectedCategoryName: '@',
    onCategoryClick: '&'
  },
  controller: SearchCategoriesController,
  templateUrl: 'core/search/components/search-categories/search-categories.tpl.html'
};

},{"swacctsMapper":405}],241:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SearchCriteriaController = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _angular = require('angular');

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _swacctsMapper = require('swacctsMapper');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function formatValue(value) {
  return typeof value.getMonth === 'function' ? (0, _moment2.default)(value).format('YYYY-MM-DD') : value;
};

function isValidCriteria(item) {
  return item.criteria && item.operation && item.value && (item.operation === 'between' ? item.secondValue : true);
}

function formatCriteriaItem(item) {
  var criteriaItem = {
    criteria: item.criteria.name,
    type: item.criteria.type,
    operation: item.operation,
    value: formatValue(item.value)
  };

  if (item.secondValue) {
    criteriaItem.secondValue = formatValue(item.secondValue);
  }

  return criteriaItem;
}

function formatCriteria(query) {
  return Object.keys(query).reduce(function (acc, curr) {
    return acc.concat(query[curr].criteria);
  }, []).filter(isValidCriteria).map(formatCriteriaItem);
}

function formatColumnsToDisplay(query) {
  return Object.keys(query).reduce(function (acc, curr) {
    return query[curr].columnsToDisplay ? acc.concat(query[curr].columnsToDisplay) : acc;
  }, []).map(function (item) {
    return item.name;
  });
}

var SearchCriteriaController = exports.SearchCriteriaController = function () {

  /**
   * @constructor
   * @ngInject
   */
  function SearchCriteriaController(Dialog, Toast, ColumnsToDisplayDialog, AuditToolResource) {
    _classCallCheck(this, SearchCriteriaController);

    this._Dialog = Dialog;
    this._Toast = Toast;
    this._ColumnsToDisplayDialog = ColumnsToDisplayDialog;
    this._AuditToolResource = AuditToolResource;
    this.mapSearchCategoryName = _swacctsMapper.mapSearchCategoryName;
  }

  _createClass(SearchCriteriaController, [{
    key: '$onInit',
    value: function $onInit() {
      this.query = this.query || {};
    }
  }, {
    key: 'addCriteria',
    value: function addCriteria() {
      if (this.query.hasOwnProperty(this.categoryName)) {
        this.query[this.categoryName].criteria = [].concat(_toConsumableArray(this.query[this.categoryName].criteria), [{}]);
      } else {
        this.query[this.categoryName] = { criteria: [{}] };
      }
    }
  }, {
    key: 'removeCriteria',
    value: function removeCriteria(row) {
      this.query[this.categoryName].criteria = this.query[this.categoryName].criteria.filter(function (item) {
        return row !== item;
      });
    }
  }, {
    key: 'clearAllCriteria',
    value: function clearAllCriteria() {
      var _this = this;

      this._Dialog.confirm('Are you sure you want to clear all filters?').then(function () {
        Object.keys(_this.query).forEach(function (key) {
          delete _this.query[key];
        });
        _this._Toast.showSimple('All filters cleared successfully.');
      });
    }
  }, {
    key: 'showColumnsToDisplayElements',
    value: function showColumnsToDisplayElements(categoryName) {
      return ['incidents', 'deployments', 'patients'].indexOf(categoryName.toLowerCase()) > -1;
    }
  }, {
    key: 'showColumnsToDisplayDialog',
    value: function showColumnsToDisplayDialog(evt, data) {
      var _this2 = this;

      if (!this.query[this.categoryName]) {
        this.query[this.categoryName] = {
          criteria: [],
          columnsToDisplay: []
        };
      }

      var dialogProps = {
        $event: evt,
        item: (0, _angular.copy)(data),
        locals: {
          // TODO This should be handled on the server side
          category: this.category.filter(function (item) {
            return ['pivot_deployment_user.user_id', 'pivot_administered_interventions_users.user_id'].indexOf(item.name) === -1;
          }),
          query: this.query[this.categoryName]
        }
      };

      this._ColumnsToDisplayDialog.show(dialogProps).then(function (data) {
        _this2.query[_this2.categoryName].columnsToDisplay = data.map(function (item) {
          return {
            id: item.id,
            name: item.name,
            title: item.title
          };
        });
      });
    }
  }, {
    key: 'search',
    value: function search() {
      if (this.form.$invalid) {
        this._Dialog.alert('Please check your search criteria.');
        return false;
      }

      if (!this._hasColumnsToDisplay()) {
        this._Dialog.alert('Please select at least one column to display.');
        return false;
      }

      var criteria = formatCriteria(this.query);
      var columnsToDisplay = formatColumnsToDisplay(this.query);
      this.onSearch({ $event: { criteria: criteria, columnsToDisplay: columnsToDisplay } });
    }
  }, {
    key: '_hasColumnsToDisplay',
    value: function _hasColumnsToDisplay() {
      var _this3 = this;

      return Object.keys(this.query).filter(function (key) {
        return _this3.query[key].columnsToDisplay && _this3.query[key].columnsToDisplay.length;
      }).length > 0;
    }
  }]);

  return SearchCriteriaController;
}();

exports.default = {
  bindings: {
    category: '<',
    categoryName: '@',
    operations: '<',
    values: '<',
    query: '<',
    onSearch: '&'
  },
  controller: SearchCriteriaController,
  templateUrl: 'core/search/components/search-criteria/search-criteria.tpl.html'
};

},{"angular":"angular","moment":"moment","swacctsMapper":405}],242:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
function filterCriteria(item) {
  return ['patient_report_forms.first_name', 'patient_report_forms.last_name', 'patient_report_forms.date_of_birth', 'patient_report_forms.address'].indexOf(item.name) === -1;
}

exports.default = {
  bindings: {
    category: '<',
    operations: '<',
    values: '<',
    queryItem: '<',
    formProps: '<'
  },
  controller: function controller() {
    var _this = this;

    this.$onInit = function () {
      _this.booleanSelectValues = [{ name: 'Yes', id: 1 }, { name: 'No', id: 0 }];

      _this.category = _this.category.filter(filterCriteria);
    };
  },
  templateUrl: 'core/search/components/search-query-item/search-query-item.tpl.html'
};

},{}],243:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SearchResultsController = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _swacctsMapper = require('swacctsMapper');

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function getColumnWidth(len) {
  if (len < 5) return len * 20;
  if (len < 10) return len * 15;
  if (len > 19) return len * 11;
  if (len > 13) return len * 14;
  return len * 15;
}

function formatKey(str) {
  return str.replace(/\./, '_');
}

function prettify(str) {
  var parts = str.split('.');
  var table = ucFirst(parts[0]) + ': ';
  var column = parts[1].split('_').map(function (item) {
    return ucFirst(item);
  }).join(' ');
  return table + column;
}

function ucFirst(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function isIdCol(key) {
  return ~['incidents_id', 'deployments_id', 'patient_report_forms_id', 'incidents.id', 'deployments.id', 'patient_report_forms.id'].indexOf(key);
}

var ACTIONS_COL_TPL = '<div class="ui-grid-cell-contents t-no-padding" title="actions">\n  <md-button target="_blank" class="md-button-low md-button-narrow" ng-if="row.entity.incidents_id"\n      ui-sref="incident({id: row.entity.incidents_id})">Referral</md-button>\n  <md-button target="_blank" class="md-button-low md-button-narrow" ng-if="row.entity.deployments_id"\n      ui-sref="deployments({id: row.entity.deployments_id})">Deployment</md-button>\n  <md-button target="_blank" class="md-button-low md-button-narrow" ng-if="row.entity.patient_report_forms_id"\n      ui-sref="prf.patientDetails({id: row.entity.patient_report_forms_id})">PRF</md-button>\n</div>';

var GRID_OPTIONS = {
  enableFiltering: true,
  enableSorting: true,
  useExternalPagination: false,
  useExternalSorting: false,
  enableColumnResizing: true,
  columnDefs: [],
  data: [],
  height: 900,
  rowHeight: 35,
  paginationPageSizes: [25, 50, 75],
  paginationPageSize: 25,
  paginationTemplate: 'core/search/components/search-results/search-results-pagination.tpl.html'
};

var SearchResultsController = exports.SearchResultsController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function SearchResultsController(uiGridConstants, $timeout, $scope) {
    _classCallCheck(this, SearchResultsController);

    this._uiGridConstants = uiGridConstants;
    this._timeout = $timeout;
    this._scope = $scope;
  }

  _createClass(SearchResultsController, [{
    key: '$onInit',
    value: function $onInit() {
      var _this = this;

      this.searchTriggered = false;

      this.pagination = {
        pageNumber: 1,
        pageSize: 25,
        sort: null
      };

      this.gridOptions = Object.assign({}, GRID_OPTIONS, {
        onRegisterApi: function onRegisterApi(gridApi) {
          _this.gridApi = gridApi;
          _this.gridApi.core.on.sortChanged(_this._scope, _this._onSortingChanged.bind(_this));
          _this.gridApi.pagination.on.paginationChanged(_this._scope, _this._onPaginationChanged.bind(_this));
        }
      });
    }
  }, {
    key: 'exportCsv',
    value: function exportCsv() {
      if (!this.data.length) return;
      var keys = Object.keys(this.data[0]);
      var data = this.data.map(function (item) {
        return Object.values(item).map(function (v) {
          return v === null ? '""' : '"' + v + '"';
        });
      });
      var csvContent = [keys].concat(_toConsumableArray(data)).reduce(function (acc, cur) {
        return '' + acc + cur.join(',') + '\r\n';
      }, '');
      this._downloadCsv(csvContent);
    }
  }, {
    key: '_downloadCsv',
    value: function _downloadCsv(csvContent) {
      var encodedUri = 'data:text/csv;charset=utf-8,' + encodeURI(csvContent);

      var link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', 'audit-tool-results.csv');
      link.setAttribute('target', '_blank');

      document.body.appendChild(link); // Required for FF
      link.click();
      document.body.removeChild(link); // Required for FF
    }
  }, {
    key: '_onPaginationChanged',
    value: function _onPaginationChanged(pageNumber, pageSize) {
      this.pagination.pageNumber = pageNumber;
      this.pagination.pageSize = pageSize;
    }
  }, {
    key: '_onSortingChanged',
    value: function _onSortingChanged(grid, sortColumns) {
      if (sortColumns.length === 0) {
        this.pagination.sort = null;
      } else {
        this.pagination.sort = sortColumns[0].sort.direction;
      }
    }
  }, {
    key: '$onChanges',
    value: function $onChanges(changesObj) {
      var _this2 = this;

      if (!changesObj.data) return;

      if (changesObj.data && changesObj.data.currentValue) {
        this.searchTriggered = true;

        // Reset columndefs
        this.gridOptions.columnDefs = [];

        if (changesObj.data.currentValue.length) {
          // Get columnDefs from first row of data
          var firstRow = changesObj.data.currentValue[0];
          var linkButtonCount = this._getLinksCount(firstRow);
          this.gridOptions.columnDefs = Object.keys(firstRow).map(function (key) {
            return _this2._getColumnDef(key);
          });
          this._setupActionCol(linkButtonCount);
        }

        // Transform data
        this.gridOptions.data = changesObj.data.currentValue.map(function (item) {
          return Object.keys(item).reduce(function (acc, cur) {
            acc[formatKey(cur)] = item[cur];
            return acc;
          }, {});
        });

        // Notify UI Grid about column change & update column widths
        this.gridApi.core.notifyDataChange(this._uiGridConstants.dataChange.COLUMN);
        this._timeout(function () {
          _this2.updateColumnWidths(_this2.gridOptions.data);
        });
      }
    }
  }, {
    key: 'updateColumnWidths',
    value: function updateColumnWidths(data) {
      var _this3 = this;

      var colWidths = this.gridApi.grid.columns.reduce(function (acc, cur) {
        acc[cur.name] = cur.name && cur.name.length > 8 ? cur.name.length : 8;
        return acc;
      }, {});

      if (!data.length) return;

      data.forEach(function (item) {
        Object.keys(item).forEach(function (key) {
          if (!item.hasOwnProperty(key) || item[key] === null || !item[key].length || item[key].length <= colWidths[key]) return;
          colWidths[key] = item[key].length;
        });
      });

      Object.keys(colWidths).forEach(function (key) {
        if (key === '$$hashKey' || key === 'actions') return;
        var col = _this3.gridApi.grid.getColumn(key);
        if (isIdCol(key)) {
          col.colDef.visible = false;
        } else {
          col.width = getColumnWidth(colWidths[key]);
          col.colDef.enableHiding = false;
        }
      });
    }
  }, {
    key: '_getColumnDef',
    value: function _getColumnDef(key) {
      var displayName = prettify((0, _swacctsMapper.mapSearchResultHeading)(key));
      return {
        name: formatKey(key),
        displayName: displayName,
        width: getColumnWidth(displayName)
      };
    }
  }, {
    key: '_getLinksCount',
    value: function _getLinksCount(row) {
      var c = 0;
      for (var key in row) {
        if (isIdCol(key)) c++;
      }
      return c;
    }
  }, {
    key: '_setupActionCol',
    value: function _setupActionCol(width) {
      width = width || 0;
      this.gridOptions.columnDefs.unshift({
        name: 'actions',
        displayName: '',
        enableFiltering: false,
        enableSorting: false,
        enableHiding: false,
        field: 'widgets',
        width: width === 3 ? width * 90 : width === 2 ? width * 107 : 117,
        visible: !!width,
        cellTemplate: ACTIONS_COL_TPL
      });
    }
  }]);

  return SearchResultsController;
}();

exports.default = {
  bindings: {
    data: '<'
  },
  controller: SearchResultsController,
  templateUrl: 'core/search/components/search-results/search-results.tpl.html'
};

},{"swacctsMapper":405}],244:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = SearchDatepicker;
function SearchDatepicker() {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      model: '=seModel',
      errors: '=seErrors',
      required: '=seRequired',
      disabled: '=seDisabled',
      name: '@seName',
      placeholder: '@sePlaceholder',
      maxDate: '=seMaxDate',
      minDate: '=seMinDate'
    },
    controller: function controller() {
      this.placeholder = this.placeholder || 'Enter date';
    },
    controllerAs: '$ctrl',
    bindToController: true,
    templateUrl: 'core/search/directives/search-datepicker/search-datepicker.tpl.html'
  };
}

},{}],245:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = SearchInput;
function SearchInput() {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      model: '=seModel',
      type: '@seType',
      name: '@seName',
      placeholder: '@sePlaceholder',
      errors: '=seErrors',
      label: '@seLabel',
      required: '=seRequired',
      disabled: '=seDisabled',
      pattern: '@sePattern',
      patternHint: '@sePatternHint'
    },
    controller: function controller() {
      this.placeholder = this.placeholder || 'Enter value';
    },
    controllerAs: '$ctrl',
    bindToController: true,
    templateUrl: 'core/search/directives/search-input/search-input.tpl.html'
  };
}

},{}],246:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = SearchSelect;
function SearchSelect() {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      name: '@seName',
      model: '=seModel',
      options: '=seOptions',
      valueKey: '@seValueKey',
      labelKey: '@seLabelKey',
      errors: '=seErrors',
      label: '@seLabel',
      placeholder: '@sePlaceholder',
      required: '=seRequired',
      disabled: '=seDisabled',
      objectAsValue: '=seObjectAsValue'
    },
    controller: function controller() {
      this.valueKey = this.valueKey || 'id';
      this.labelKey = this.labelKey || 'name';
      this.placeholder = this.placeholder || 'Select value';
    },
    controllerAs: '$ctrl',
    bindToController: true,
    templateUrl: 'core/search/directives/search-select/search-select.tpl.html'
  };
};

},{}],247:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

require('angular-ui-grid');

var _searchCategories = require('./components/search-categories/search-categories.component');

var _searchCategories2 = _interopRequireDefault(_searchCategories);

var _searchCriteria = require('./components/search-criteria/search-criteria.component');

var _searchCriteria2 = _interopRequireDefault(_searchCriteria);

var _searchResults = require('./components/search-results/search-results.component');

var _searchResults2 = _interopRequireDefault(_searchResults);

var _searchQueryItem = require('./components/search-query-item/search-query-item.component');

var _searchQueryItem2 = _interopRequireDefault(_searchQueryItem);

var _searchDatepicker = require('./directives/search-datepicker/search-datepicker.directive');

var _searchDatepicker2 = _interopRequireDefault(_searchDatepicker);

var _searchInput = require('./directives/search-input/search-input.directive');

var _searchInput2 = _interopRequireDefault(_searchInput);

var _searchSelect = require('./directives/search-select/search-select.directive');

var _searchSelect2 = _interopRequireDefault(_searchSelect);

var _columnsToDisplayDialog = require('./services/columns-to-display-dialog/columns-to-display-dialog.service');

var _columnsToDisplayDialog2 = _interopRequireDefault(_columnsToDisplayDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _module = _angular2.default.module('gnaas.core.search', ['ui.grid', 'ui.grid.pagination', 'ui.grid.autoResize', 'ui.grid.resizeColumns']).component('searchCategories', _searchCategories2.default).component('searchCriteria', _searchCriteria2.default).component('searchResults', _searchResults2.default).component('searchQueryItem', _searchQueryItem2.default).directive('searchDatepicker', _searchDatepicker2.default).directive('searchInput', _searchInput2.default).directive('searchSelect', _searchSelect2.default).service('ColumnsToDisplayDialog', _columnsToDisplayDialog2.default);

exports.default = _module.name;

},{"./components/search-categories/search-categories.component":240,"./components/search-criteria/search-criteria.component":241,"./components/search-query-item/search-query-item.component":242,"./components/search-results/search-results.component":243,"./directives/search-datepicker/search-datepicker.directive":244,"./directives/search-input/search-input.directive":245,"./directives/search-select/search-select.directive":246,"./services/columns-to-display-dialog/columns-to-display-dialog.service":249,"angular":"angular","angular-ui-grid":"angular-ui-grid"}],248:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _resourceDialogController = require('common/resource/controllers/resourceDialogController');

var _resourceDialogController2 = _interopRequireDefault(_resourceDialogController);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ColumnsToDisplayController = function (_ResourceDialogContro) {
  _inherits(ColumnsToDisplayController, _ResourceDialogContro);

  function ColumnsToDisplayController() {
    _classCallCheck(this, ColumnsToDisplayController);

    return _possibleConstructorReturn(this, (ColumnsToDisplayController.__proto__ || Object.getPrototypeOf(ColumnsToDisplayController)).apply(this, arguments));
  }

  _createClass(ColumnsToDisplayController, [{
    key: 'init',
    value: function init() {
      _get(ColumnsToDisplayController.prototype.__proto__ || Object.getPrototypeOf(ColumnsToDisplayController.prototype), 'init', this).call(this);
      if (!this.selected && this.query.columnsToDisplay) {
        this.selected = this.query.columnsToDisplay.map(function (item) {
          return item;
        });
      }
    }
  }, {
    key: 'submit',
    value: function submit(data) {
      if (this.form.$invalid) {
        return false;
      }
      this.Dialog.hide(data);
    }
  }]);

  return ColumnsToDisplayController;
}(_resourceDialogController2.default);

exports.default = ColumnsToDisplayController;

},{"common/resource/controllers/resourceDialogController":56}],249:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _resourceDialog = require('common/resource/services/resourceDialog');

var _resourceDialog2 = _interopRequireDefault(_resourceDialog);

var _columnsToDisplayDialog = require('./columns-to-display-dialog.controller');

var _columnsToDisplayDialog2 = _interopRequireDefault(_columnsToDisplayDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ColumnsToDisplayDialog = function (_ResourceDialog) {
  _inherits(ColumnsToDisplayDialog, _ResourceDialog);

  function ColumnsToDisplayDialog() {
    _classCallCheck(this, ColumnsToDisplayDialog);

    return _possibleConstructorReturn(this, (ColumnsToDisplayDialog.__proto__ || Object.getPrototypeOf(ColumnsToDisplayDialog)).apply(this, arguments));
  }

  _createClass(ColumnsToDisplayDialog, [{
    key: 'init',
    value: function init() {
      _get(ColumnsToDisplayDialog.prototype.__proto__ || Object.getPrototypeOf(ColumnsToDisplayDialog.prototype), 'init', this).call(this);
      this.setController(_columnsToDisplayDialog2.default);
      this.setTemplateUrl('core/search/services/columns-to-display-dialog/columns-to-display-dialog.tpl.html');
    }
  }]);

  return ColumnsToDisplayDialog;
}(_resourceDialog2.default);

exports.default = ColumnsToDisplayDialog;

},{"./columns-to-display-dialog.controller":248,"common/resource/services/resourceDialog":59}],250:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _angular = require('angular');

var _module = require('core/shifts/module.constants');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var ShiftSidebarController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function ShiftSidebarController($rootScope, $mdSidenav, ShiftDialog, ShiftCrewDialog, ShiftResource) {
    _classCallCheck(this, ShiftSidebarController);

    this.$rootScope = $rootScope;
    this.$mdSidenav = $mdSidenav;
    this.ShiftDialog = ShiftDialog;
    this.ShiftCrewDialog = ShiftCrewDialog;
    this.ShiftResource = ShiftResource;
  }

  _createClass(ShiftSidebarController, [{
    key: 'close',
    value: function close() {
      this.$mdSidenav('shift-sidebar-item').close();
    }
  }, {
    key: 'showShiftDialog',
    value: function showShiftDialog($event) {
      var _this = this;

      var dialogParams = {
        $event: $event,
        item: (0, _angular.copy)(this.shift),
        editing: true,
        locals: {
          bases: this.bases,
          vehicleCallsigns: this.vehicleCallsigns
        }
      };

      this.ShiftDialog.show(dialogParams).then(function (shift) {
        _this.shift = shift;
        _this.$rootScope.$emit(_module.SHIFT_EVENTS.updated, shift);
      }).catch(function (shiftId) {
        if (shiftId) {
          _this.$rootScope.$emit(_module.SHIFT_EVENTS.deleted, _this.shift);
          _this.close();
        }
      });
    }
  }, {
    key: 'showShiftCrewDialog',
    value: function showShiftCrewDialog($event) {
      this.ShiftCrewDialog.show({
        $event: $event,
        locals: {
          shift: this.shift,
          crewMembers: this.crewMembers
        }
      });
    }
  }]);

  return ShiftSidebarController;
}();

exports.default = {
  controller: ShiftSidebarController,
  templateUrl: 'core/shifts/components/shift-sidebar-item/shift-sidebar-item.tpl.html',
  bindings: {
    shift: '<',
    bases: '<',
    vehicleCallsigns: '<',
    crewMembers: '<'
  }
};

},{"angular":"angular","core/shifts/module.constants":251}],251:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var SHIFT_EVENTS = exports.SHIFT_EVENTS = {
  updated: 'shifts:updated',
  deleted: 'shifts:deleted'
};

},{}],252:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _shiftDialog = require('./services/shift-dialog/shift-dialog.service');

var _shiftDialog2 = _interopRequireDefault(_shiftDialog);

var _shiftCrewDialog = require('./services/shift-crew-dialog/shift-crew-dialog.service');

var _shiftCrewDialog2 = _interopRequireDefault(_shiftCrewDialog);

var _shift = require('./resources/shift.resource');

var _shift2 = _interopRequireDefault(_shift);

var _shiftSidebarItem = require('./components/shift-sidebar-item/shift-sidebar-item');

var _shiftSidebarItem2 = _interopRequireDefault(_shiftSidebarItem);

var _shift3 = require('./services/shift.service');

var _shift4 = _interopRequireDefault(_shift3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _module = _angular2.default.module('gnaas.core.shifts', []).component('shiftSidebarItem', _shiftSidebarItem2.default).service('ShiftDialog', _shiftDialog2.default).service('ShiftCrewDialog', _shiftCrewDialog2.default).service('ShiftResource', _shift2.default).service('ShiftService', _shift4.default);

exports.default = _module.name;

},{"./components/shift-sidebar-item/shift-sidebar-item":250,"./resources/shift.resource":253,"./services/shift-crew-dialog/shift-crew-dialog.service":255,"./services/shift-dialog/shift-dialog.service":257,"./services/shift.service":258,"angular":"angular"}],253:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parse = exports.format = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

exports.formatShiftUser = formatShiftUser;
exports.parseShiftUsers = parseShiftUsers;

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

var _crewMemberResource = require('pages/crewMembers/services/crewMemberResource');

var _baseResource = require('pages/admin/bases/services/baseResource');

var _vehicleCallsignResource = require('pages/admin/vehicleCallsigns/services/vehicleCallsignResource');

var _module = require('common/resource/module.constants');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

/**
 * Creates a moment instance from time & applies date changes if set.
 *
 * @param time Time string
 * @param date Date string
 * @returns {moment.Moment}
 */
function setDate(time, date) {
  var dateTime = (0, _moment2.default)(time, _module.TIME_FORMAT);
  if (date) {
    date = (0, _moment2.default)(date);
    dateTime.year(date.year()).month(date.month()).date(date.date());
  }
  return dateTime;
}

function _format(item, date) {
  var i = {
    id: item.id,
    name: item.name,
    start_date: item.start_date ? (0, _moment2.default)(item.start_date, _module.DATE_FORMAT).toDate() : null,
    end_date: item.end_date ? (0, _moment2.default)(item.end_date, _module.DATE_FORMAT).toDate() : null,
    start_time: null,
    end_time: null,
    inactive: !!item.end_date
  };

  if (item.start_time) {
    var startTime = setDate(item.start_time, date);
    i.start_time = startTime.toDate();
  }

  if (item.end_time) {
    var endTime = setDate(item.end_time, date);
    if (i.start_time && endTime.isBefore(i.start_time)) {
      endTime.add(1, 'day');
    }
    i.end_time = endTime.toDate();
  }

  if (item.base && !Array.isArray(item.base.data)) i.base = (0, _baseResource.format)(item.base.data);

  if (item.vehicleCallsign && !Array.isArray(item.vehicleCallsign.data)) {
    i.vehicle_callsign = (0, _vehicleCallsignResource.format)(item.vehicleCallsign.data);
  }

  if (item.users) i.users = item.users.data.map(formatShiftUser);

  return i;
}

exports.format = _format;
function _parse(item) {
  return {
    id: item.id,
    name: item.name,
    start_date: item.start_date ? (0, _moment2.default)(item.start_date).format(_module.DATE_FORMAT) : null,
    end_date: item.end_date ? (0, _moment2.default)(item.end_date).format(_module.DATE_FORMAT) : null,
    start_time: (0, _moment2.default)(item.start_time).format('HH:mm'),
    end_time: (0, _moment2.default)(item.end_time).format('HH:mm'),
    base_id: item.base ? item.base.id : null,
    vehicle_callsign_id: item.vehicle_callsign ? item.vehicle_callsign.id : null
  };
}

exports.parse = _parse;
function formatShiftUser(item) {
  return Object.assign({}, (0, _crewMemberResource.format)(item), {
    date: (0, _moment2.default)(item.date, _module.DATE_FORMAT).toDate(),
    type: item.type ? item.type : null
  });
}

function parseShiftUsers(item) {
  return {
    date: (0, _moment2.default)(item.date).format(_module.DATE_FORMAT),
    users: Array.isArray(item.users) ? item.users.map(function (i) {
      return { id: i.id, type: i.type ? i.type : null };
    }) : []
  };
}

/**
 * ShiftResource overrides multiple base methods to allow incorporating date manipulation
 * on certain properties.
 * Shifts reflect data based on the 'date' property, if added to query string. This in turn
 * needs to be reflected locally for more accurate data.
 */

var ShiftResource = function (_RestResource) {
  _inherits(ShiftResource, _RestResource);

  function ShiftResource() {
    _classCallCheck(this, ShiftResource);

    return _possibleConstructorReturn(this, (ShiftResource.__proto__ || Object.getPrototypeOf(ShiftResource)).apply(this, arguments));
  }

  _createClass(ShiftResource, [{
    key: 'init',
    value: function init() {
      _get(ShiftResource.prototype.__proto__ || Object.getPrototypeOf(ShiftResource.prototype), 'init', this).call(this);
      this.resourcePath = 'shifts';
    }

    /**
     * @override
     */

  }, {
    key: 'index',
    value: function index(query) {
      var _this2 = this;

      var url = this.apiUrl + this.resourcePath;

      if (query) {
        url += this._generateQueryString(query);
      }

      return this._http.get(url).then(function (response) {
        return response.data ? _this2._formatData(response.data, query ? query.date : null) : [];
      });
    }

    /**
     * @override
     */

  }, {
    key: 'show',
    value: function show(id, query) {
      var _this3 = this;

      var url = '' + this.apiUrl + this.resourcePath + '/' + id;

      if (query) {
        url += this._generateQueryString(query);
      }

      return this._http.get(url).then(function (response) {
        return response.data.data ? _this3._formatData(response.data.data, query ? query.date : null) : null;
      });
    }

    /**
     * @override
     */

  }, {
    key: 'update',
    value: function update(id, data, query) {
      var _this4 = this;

      var url = '' + this.apiUrl + this.resourcePath + '/' + id;

      if (query) {
        url += this._generateQueryString(query);
      }

      var parsed = this._parseData(data);
      if (parsed.id) {
        delete parsed.id;
      }

      return this._http.put(url, parsed).then(function (response) {
        return response.data.data ? _this4._formatData(response.data.data, query ? query.date : null) : null;
      });
    }

    /**
     * @override
     * @param date Date the items are referring to
     */

  }, {
    key: '_formatData',
    value: function _formatData(data, date) {
      var _this5 = this;

      if (Array.isArray(data)) {
        return data.map(function (item) {
          return _this5.format(item, date);
        });
      }

      if (data.data && data.meta && data.meta.pagination) {
        return {
          data: data.data.map(function (item) {
            return _this5.format(item, date);
          }),
          pagination: data.meta.pagination
        };
      }

      if (data.data) {
        return Array.isArray(data.data) ? data.data.map(function (item) {
          return _this5.format(item, date);
        }) : this.format(data.data, date);
      }

      return this.format(data, date);
    }
  }, {
    key: 'format',
    value: function format(item, date) {
      return _format(item, date);
    }
  }, {
    key: 'parse',
    value: function parse(item) {
      return _parse(item);
    }
  }, {
    key: 'setUsers',
    value: function setUsers(id, data, query) {
      var url = '' + this.apiUrl + this.resourcePath + '/' + id + '/users';
      if (query) {
        url += this._generateQueryString(query);
      }
      return this._http.put(url, parseShiftUsers(data)).then(function (response) {
        if (!response.data.data) return [];
        return response.data.data.map(formatShiftUser);
      });
    }
  }, {
    key: 'getUsers',
    value: function getUsers(id, query) {
      var url = '' + this.apiUrl + this.resourcePath + '/' + id + '/users';
      if (query) {
        url += this._generateQueryString(query);
      }
      return this._http.get(url).then(function (response) {
        if (!response.data.data) return [];
        return response.data.data.map(formatShiftUser);
      });
    }
  }]);

  return ShiftResource;
}(_restResource2.default);

exports.default = ShiftResource;

},{"common/resource/module.constants":57,"common/resource/services/restResource":60,"moment":"moment","pages/admin/bases/services/baseResource":266,"pages/admin/vehicleCallsigns/services/vehicleCallsignResource":332,"pages/crewMembers/services/crewMemberResource":362}],254:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _angular = require('angular');

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _resourceDialogController = require('common/resource/controllers/resourceDialogController');

var _resourceDialogController2 = _interopRequireDefault(_resourceDialogController);

var _module = require('common/resource/module.constants');

var _deploymentDialog = require('core/deployments/services/deployment-dialog/deployment-dialog.controller');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var CREW_LIST_DIALOG_TPL = '<md-dialog class="o-dialog o-dialog--small">\n  <md-dialog-content>\n    <form-checkbox-list\n      flex\n      fe-list-title="Crew Members"\n      fe-list="$ctrl.crewMembers"\n      fe-selected-items="$ctrl.users"\n      fe-form-submitted="$ctrl.form.$submitted"\n      fe-hide-toggle-all-button="true"\n      fe-hide-list-toggle="true">\n    </form-checkbox-list>\n  </md-dialog-content>\n  <md-dialog-actions>\n      <md-button class="md-raised" ng-click="$ctrl.close()">Cancel</md-button>\n      <md-button class="md-raised md-primary" ng-click="$ctrl.submit()">Ok</md-button>\n  </md-dialog-actions>\n</md-dialog>';

function getQuery(date) {
  return { date: (0, _moment2.default)(date).format(_module.DATE_FORMAT), include: 'profile.image,profile.profession' };
}

var ShiftCrewDialogController = function (_ResourceDialogContro) {
  _inherits(ShiftCrewDialogController, _ResourceDialogContro);

  function ShiftCrewDialogController() {
    _classCallCheck(this, ShiftCrewDialogController);

    return _possibleConstructorReturn(this, (ShiftCrewDialogController.__proto__ || Object.getPrototypeOf(ShiftCrewDialogController)).apply(this, arguments));
  }

  _createClass(ShiftCrewDialogController, [{
    key: 'init',
    value: function init() {
      this.ShiftResource = this.$injector.get('ShiftResource');
      this.closeOnSubmit = !!this.closeOnSubmit;
      this.types = _deploymentDialog.TYPES;

      this.item = {
        date: this.date ? (0, _moment2.default)(this.date).toDate() : new Date(),
        users: []
      };
      this.getShiftCrew(this.item.date);
    }
  }, {
    key: 'submit',
    value: function submit(formData) {
      var _this2 = this;

      if (!this.form) {
        throw new Error('Form not found.');
      }

      if (this.form.$invalid) {
        this.Toast.showSimple(this.validationErrorMessage);
        return;
      }

      this.ShiftResource.setUsers(this.shift.id, (0, _angular.copy)(formData), getQuery(this.item.date)).then(function (data) {
        _this2.Toast.showSimple('Shift crew set.');
        _this2.item.users = data;
        _this2.setOriginalUserIds();
        if (_this2.closeOnSubmit) {
          _this2.Dialog.hide(_this2.item);
        }
      }).catch(function () {
        _this2.Toast.showSimple('Error setting shift crew.');
      });
    }
  }, {
    key: 'cancel',
    value: function cancel() {
      var _this3 = this;

      if (!this.compareOriginalUserIds()) {
        return this.Dialog.confirm('You have unsaved shift crew changes, are you sure you wish to continue without saving?').then(function () {
          return _this3.Dialog.cancel();
        });
      }
      this.Dialog.cancel();
    }
  }, {
    key: 'showCrewList',
    value: function showCrewList($event) {
      var _this5 = this;

      this.Dialog.show({
        targetEvent: $event,
        controller: function controller() {
          'ngInject';

          var _this4 = this;

          this.close = function () {
            return _this4.Dialog.cancel();
          };
          this.submit = function () {
            return _this4.Dialog.hide(_this4.users);
          };
        },
        locals: {
          Dialog: this.Dialog,
          crewMembers: (0, _angular.copy)(this.crewMembers),
          users: (0, _angular.copy)(this.item.users)
        },
        multiple: true,
        template: CREW_LIST_DIALOG_TPL
      }).then(function (users) {
        _this5.item.users = users;
      });
    }
  }, {
    key: 'getShiftCrew',
    value: function getShiftCrew(date) {
      var _this6 = this;

      this.ShiftResource.getUsers(this.shift.id, getQuery(date)).then(function (data) {
        _this6.item.users = data;
        _this6.setOriginalUserIds();
      }).catch(function () {
        _this6.Toast.showSimple('Error retrieving shift crew.');
        _this6.item.users = [];
        _this6.setOriginalUserIds();
      });
    }
  }, {
    key: 'onDateChange',
    value: function onDateChange(date) {
      var _this7 = this;

      if (!this.compareOriginalUserIds()) {
        this.item.date = new Date(this.item.date);
        return this.Dialog.confirm('You have unsaved shift crew changes, are you sure you wish to continue without saving?').then(function () {
          _this7.item.date = date;
          _this7.getShiftCrew(date);
        });
      }
      this.getShiftCrew(date);
    }
  }, {
    key: 'setOriginalUserIds',
    value: function setOriginalUserIds() {
      this.originalUserIds = this.item.users.map(function (i) {
        return i.id;
      });
    }
  }, {
    key: 'compareOriginalUserIds',
    value: function compareOriginalUserIds() {
      var userIds = this.item.users.map(function (i) {
        return i.id;
      });
      if (this.originalUserIds.length !== userIds.length) return false;
      return this.originalUserIds.every(function (id) {
        return userIds.includes(id);
      });
    }
  }]);

  return ShiftCrewDialogController;
}(_resourceDialogController2.default);

exports.default = ShiftCrewDialogController;

},{"angular":"angular","common/resource/controllers/resourceDialogController":56,"common/resource/module.constants":57,"core/deployments/services/deployment-dialog/deployment-dialog.controller":126,"moment":"moment"}],255:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _resourceDialog = require('common/resource/services/resourceDialog');

var _resourceDialog2 = _interopRequireDefault(_resourceDialog);

var _shiftCrewDialog = require('./shift-crew-dialog.controller');

var _shiftCrewDialog2 = _interopRequireDefault(_shiftCrewDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ShiftCrewDialog = function (_ResourceDialog) {
  _inherits(ShiftCrewDialog, _ResourceDialog);

  function ShiftCrewDialog() {
    _classCallCheck(this, ShiftCrewDialog);

    return _possibleConstructorReturn(this, (ShiftCrewDialog.__proto__ || Object.getPrototypeOf(ShiftCrewDialog)).apply(this, arguments));
  }

  _createClass(ShiftCrewDialog, [{
    key: 'init',
    value: function init() {
      _get(ShiftCrewDialog.prototype.__proto__ || Object.getPrototypeOf(ShiftCrewDialog.prototype), 'init', this).call(this);
      this.setController(_shiftCrewDialog2.default);
      this.setTemplateUrl('core/shifts/services/shift-crew-dialog/shift-crew-dialog.tpl.html');
    }
  }]);

  return ShiftCrewDialog;
}(_resourceDialog2.default);

exports.default = ShiftCrewDialog;

},{"./shift-crew-dialog.controller":254,"common/resource/services/resourceDialog":59}],256:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _nodeUuid = require('node-uuid');

var _nodeUuid2 = _interopRequireDefault(_nodeUuid);

var _resourceDialogController = require('common/resource/controllers/resourceDialogController');

var _resourceDialogController2 = _interopRequireDefault(_resourceDialogController);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ShiftDialogController = function (_ResourceDialogContro) {
  _inherits(ShiftDialogController, _ResourceDialogContro);

  function ShiftDialogController() {
    _classCallCheck(this, ShiftDialogController);

    return _possibleConstructorReturn(this, (ShiftDialogController.__proto__ || Object.getPrototypeOf(ShiftDialogController)).apply(this, arguments));
  }

  _createClass(ShiftDialogController, [{
    key: 'init',
    value: function init() {
      this.ID = _nodeUuid2.default.v4();
      this.ShiftResource = this.$injector.get('ShiftResource');
    }
  }, {
    key: 'create',
    value: function create(formData) {
      var _this2 = this;

      formData.id = this.ID;
      this._setDates(formData);
      this.ShiftResource.create(formData).then(function () {
        _this2.Toast.showSimple('Shift created.');
        _this2.Dialog.hide(formData);
      }).catch(function (err) {
        var msg = 'Error creating shift.';
        if (err && err.status === 422) {
          msg = 'Shift validation error, please check form fields.';
        }
        _this2.Toast.showSimple(msg);
      });
    }
  }, {
    key: 'update',
    value: function update(formData) {
      var _this3 = this;

      this._setDates(formData);
      this.ShiftResource.update(formData.id, formData, { include: 'properties.base,properties.vehicleCallsign' }).then(function (shift) {
        _this3.Toast.showSimple('Shift updated.');
        _this3.Dialog.hide(shift);
      }).catch(function (err) {
        var msg = 'Error updating shift.';
        if (err && err.status === 422) {
          msg = 'Shift validation error, please check form fields.';
        }
        _this3.Toast.showSimple(msg);
      });
    }
  }, {
    key: 'delete',
    value: function _delete(id) {
      var _this4 = this;

      var msg = 'This action is not reversible. ' + 'It will delete all historical data associated with this shift.';

      this.Dialog.confirm(msg).then(function () {
        _this4.ShiftResource.destroy(id).then(function () {
          _this4.Toast.showSimple('Shift deleted.');
          _this4.Dialog.cancel(id);
        }).catch(function () {
          _this4.Toast.showSimple('Error deleting shift.');
        });
      });
    }
  }, {
    key: '_setDates',
    value: function _setDates(data) {
      if (!data.start_date) {
        data.start_date = new Date();
      }
      data.end_date = data.inactive ? new Date() : null;
    }
  }]);

  return ShiftDialogController;
}(_resourceDialogController2.default);

exports.default = ShiftDialogController;

},{"common/resource/controllers/resourceDialogController":56,"node-uuid":"node-uuid"}],257:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _resourceDialog = require('common/resource/services/resourceDialog');

var _resourceDialog2 = _interopRequireDefault(_resourceDialog);

var _shiftDialog = require('./shift-dialog.controller');

var _shiftDialog2 = _interopRequireDefault(_shiftDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ShiftDialog = function (_ResourceDialog) {
  _inherits(ShiftDialog, _ResourceDialog);

  function ShiftDialog() {
    _classCallCheck(this, ShiftDialog);

    return _possibleConstructorReturn(this, (ShiftDialog.__proto__ || Object.getPrototypeOf(ShiftDialog)).apply(this, arguments));
  }

  _createClass(ShiftDialog, [{
    key: 'init',
    value: function init() {
      _get(ShiftDialog.prototype.__proto__ || Object.getPrototypeOf(ShiftDialog.prototype), 'init', this).call(this);
      this.setController(_shiftDialog2.default);
      this.setTemplateUrl('core/shifts/services/shift-dialog/shift-dialog.tpl.html');
    }
  }]);

  return ShiftDialog;
}(_resourceDialog2.default);

exports.default = ShiftDialog;

},{"./shift-dialog.controller":256,"common/resource/services/resourceDialog":59}],258:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _module = require('common/resource/module.constants');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function getDateString(date) {
  // Might be worth adding a regex here
  if (typeof date === 'string') {
    return date;
  }

  if (_moment2.default.isMoment(date)) {
    return date.format(_module.DATE_FORMAT);
  }

  if (date instanceof Date) {
    return (0, _moment2.default)(date).format(_module.DATE_FORMAT);
  }

  return null;
}

var ShiftService = function () {
  /**
   * @constructor
   * @ngInject
   */
  function ShiftService(ShiftResource) {
    _classCallCheck(this, ShiftService);

    this.ShiftResource = ShiftResource;
  }

  /**
   * Get shifts for a specific date.
   * If date is not set - use today's date.
   *
   * To override default includes, pass in a different string, or an empty string to remove them.
   *
   * @param {moment.Moment|Date|string|null} date Date to retrieve shift data for
   * @param include Shift includes
   * @returns Promise
   */


  _createClass(ShiftService, [{
    key: 'getShiftsForDate',
    value: function getShiftsForDate() {
      var date = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var include = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

      date = getDateString(date);
      if (date === null) date = (0, _moment2.default)().format(_module.DATE_FORMAT);
      if (include === null) {
        include = 'properties.base,properties.vehicleCallsign,users.profile.image,users.profile.profession';
      }
      return this.ShiftResource.index({ date: date, include: include });
    }

    /**
     * Retrieve shifts from yesterday and add to dashboard page if shift end time is the next day.
     *
     * @param {moment.Moment|Date|string|null} date Date to retrieve shift data for
     * @param include Shift includes
     * @returns Promise
     */

  }, {
    key: 'getOvernightShiftsForDate',
    value: function getOvernightShiftsForDate() {
      var date = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var include = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

      date = date === null ? (0, _moment2.default)() : (0, _moment2.default)(date);
      var dayBefore = date.clone().subtract(1, 'day');
      return this.getShiftsForDate(dayBefore, include).then(function (data) {
        return data.filter(function (shift) {
          return (0, _moment2.default)(shift.end_time).isSame(date, 'date');
        });
      });
    }
  }]);

  return ShiftService;
}();

exports.default = ShiftService;

},{"common/resource/module.constants":57,"moment":"moment"}],259:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  bindings: {
    status: '<',
    statusDialog: '&'
  },
  templateUrl: 'core/vehicles/components/vehicle-callsign-status.tpl.html'
};

},{}],260:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _vehicleCallsignStatusUpdateDialog = require('./services/vehicle-callsign-status-update-dialog/vehicle-callsign-status-update-dialog.service');

var _vehicleCallsignStatusUpdateDialog2 = _interopRequireDefault(_vehicleCallsignStatusUpdateDialog);

var _vehicleCallsignStatus = require('./components/vehicle-callsign-status.component');

var _vehicleCallsignStatus2 = _interopRequireDefault(_vehicleCallsignStatus);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _module = _angular2.default.module('gnaas.core.vehicles', []).service('VehicleCallsignStatusUpdateDialog', _vehicleCallsignStatusUpdateDialog2.default).component('vehicleCallsignStatus', _vehicleCallsignStatus2.default);

exports.default = _module.name;

},{"./components/vehicle-callsign-status.component":259,"./services/vehicle-callsign-status-update-dialog/vehicle-callsign-status-update-dialog.service":262,"angular":"angular"}],261:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _resourceDialogController = require('common/resource/controllers/resourceDialogController');

var _resourceDialogController2 = _interopRequireDefault(_resourceDialogController);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var VehicleCallsignStatusUpdateDialogController = function (_ResourceDialogContro) {
  _inherits(VehicleCallsignStatusUpdateDialogController, _ResourceDialogContro);

  function VehicleCallsignStatusUpdateDialogController() {
    _classCallCheck(this, VehicleCallsignStatusUpdateDialogController);

    return _possibleConstructorReturn(this, (VehicleCallsignStatusUpdateDialogController.__proto__ || Object.getPrototypeOf(VehicleCallsignStatusUpdateDialogController)).apply(this, arguments));
  }

  _createClass(VehicleCallsignStatusUpdateDialogController, [{
    key: 'init',
    value: function init() {
      _get(VehicleCallsignStatusUpdateDialogController.prototype.__proto__ || Object.getPrototypeOf(VehicleCallsignStatusUpdateDialogController.prototype), 'init', this).call(this);
      this._resource = this.$injector.get('VehicleCallsignResource');
    }
  }, {
    key: 'create',
    value: function create(formData) {
      var _this2 = this;

      this._resource.createStatus(formData.vehicleCallsign.id, formData).then(function (statusUpdate) {
        _this2.Dialog.hide(statusUpdate);
      });
    }
  }, {
    key: 'update',
    value: function update(formData) {
      var _this3 = this;

      this._resource.updateStatus(formData.vehicleCallsign.id, formData.id, formData).then(function (statusUpdate) {
        _this3.Dialog.hide(statusUpdate);
      });
    }
  }, {
    key: 'delete',
    value: function _delete(formData) {
      var _this4 = this;

      this.Dialog.confirm().then(function () {
        _this4._resource.deleteStatus(formData.vehicleCallsign.id, formData.id).then(function () {
          _this4.Dialog.cancel(formData.id);
        });
      });
    }
  }]);

  return VehicleCallsignStatusUpdateDialogController;
}(_resourceDialogController2.default);

exports.default = VehicleCallsignStatusUpdateDialogController;

},{"common/resource/controllers/resourceDialogController":56}],262:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _resourceDialog = require('common/resource/services/resourceDialog');

var _resourceDialog2 = _interopRequireDefault(_resourceDialog);

var _vehicleCallsignStatusUpdateDialog = require('./vehicle-callsign-status-update-dialog.controller');

var _vehicleCallsignStatusUpdateDialog2 = _interopRequireDefault(_vehicleCallsignStatusUpdateDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var VehicleCallsignStatusUpdateDialog = function (_ResourceDialog) {
  _inherits(VehicleCallsignStatusUpdateDialog, _ResourceDialog);

  function VehicleCallsignStatusUpdateDialog() {
    _classCallCheck(this, VehicleCallsignStatusUpdateDialog);

    return _possibleConstructorReturn(this, (VehicleCallsignStatusUpdateDialog.__proto__ || Object.getPrototypeOf(VehicleCallsignStatusUpdateDialog)).apply(this, arguments));
  }

  _createClass(VehicleCallsignStatusUpdateDialog, [{
    key: 'init',
    value: function init() {
      _get(VehicleCallsignStatusUpdateDialog.prototype.__proto__ || Object.getPrototypeOf(VehicleCallsignStatusUpdateDialog.prototype), 'init', this).call(this);
      this.setController(_vehicleCallsignStatusUpdateDialog2.default);
      this.setTemplateUrl('core/vehicles/services/vehicle-callsign-status-update-dialog/' + 'vehicle-callsign-status-update-dialog.tpl.html');
    }
  }]);

  return VehicleCallsignStatusUpdateDialog;
}(_resourceDialog2.default);

exports.default = VehicleCallsignStatusUpdateDialog;

},{"./vehicle-callsign-status-update-dialog.controller":261,"common/resource/services/resourceDialog":59}],263:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BasesResourceController;

var _createBaseController = require('./createBaseController');

var _createBaseController2 = _interopRequireDefault(_createBaseController);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function BasesResourceController(BaseResource) {
  'ngInject';

  var _this = this;

  this.singularName = 'Base';
  this.pluralName = 'Bases';

  this.createDialogController = _createBaseController2.default;
  this.createDialogTemplateUrl = 'pages/admin/bases/templates/createDialog.tpl.html';

  BaseResource.index().then(function (items) {
    _this.items = items;
  });

  this.listFields = {
    name: 'Name',
    abbreviation: 'Abbreviation'
  };

  this.hasProfiles = false;
};

},{"./createBaseController":264}],264:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _nodeUuid = require('node-uuid');

var _nodeUuid2 = _interopRequireDefault(_nodeUuid);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var CreateBaseController = function () {
  function CreateBaseController(BaseResource, Dialog) {
    'ngInject';

    _classCallCheck(this, CreateBaseController);

    this._BaseResource = BaseResource;
    this._Dialog = Dialog;
    this.ID = _nodeUuid2.default.v4();
  }

  _createClass(CreateBaseController, [{
    key: 'submit',
    value: function submit(data) {
      if (this.form.$invalid) {
        return;
      }

      if (this.editing) {
        this._update(data);
      } else {
        this._create(data);
      }
    }
  }, {
    key: 'cancel',
    value: function cancel() {
      this._Dialog.cancel();
    }
  }, {
    key: 'delete',
    value: function _delete(id) {
      var _this = this;

      this._Dialog.confirm().then(function () {
        _this._BaseResource.destroy(id).then(function () {
          _this._Dialog.cancel(id);
        });
      });
    }
  }, {
    key: '_create',
    value: function _create(data) {
      var _this2 = this;

      data.id = this.ID;
      this._BaseResource.create(data).then(function () {
        _this2._Dialog.hide(data);
      }).catch(console.log.bind(console));
    }
  }, {
    key: '_update',
    value: function _update(data) {
      var _this3 = this;

      this._BaseResource.update(data.id, data).then(function (item) {
        _this3._Dialog.hide(item);
      }).catch(console.log.bind(console));
    }
  }]);

  return CreateBaseController;
}();

exports.default = CreateBaseController;

},{"node-uuid":"node-uuid"}],265:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _angularUiRouter = require('angular-ui-router');

var _angularUiRouter2 = _interopRequireDefault(_angularUiRouter);

var _basesResourceController = require('./controllers/basesResourceController');

var _basesResourceController2 = _interopRequireDefault(_basesResourceController);

var _baseResource = require('./services/baseResource');

var _baseResource2 = _interopRequireDefault(_baseResource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var moduleConfig = function moduleConfig($stateProvider) {
  'ngInject';

  $stateProvider.state('admin.bases', {
    url: '/bases',
    controller: _basesResourceController2.default,
    controllerAs: '$ctrl',
    templateUrl: 'common/resource/templates/resource.tpl.html',
    data: {
      title: 'Bases'
    }
  });
};

var _module = _angular2.default.module('gnaas.admin.bases', [_angularUiRouter2.default]).config(moduleConfig).service('BaseResource', _baseResource2.default);

exports.default = _module.name;

},{"./controllers/basesResourceController":263,"./services/baseResource":266,"angular":"angular","angular-ui-router":"angular-ui-router"}],266:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parse = exports.format = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _format(item) {
  return Object.assign({}, item);
}

exports.format = _format;
function _parse(item) {
  return Object.assign({}, item);
}

exports.parse = _parse;

var BaseResource = function (_RestResource) {
  _inherits(BaseResource, _RestResource);

  function BaseResource() {
    _classCallCheck(this, BaseResource);

    return _possibleConstructorReturn(this, (BaseResource.__proto__ || Object.getPrototypeOf(BaseResource)).apply(this, arguments));
  }

  _createClass(BaseResource, [{
    key: 'init',
    value: function init() {
      _get(BaseResource.prototype.__proto__ || Object.getPrototypeOf(BaseResource.prototype), 'init', this).call(this);
      this.resourcePath = 'bases';
    }
  }, {
    key: 'format',
    value: function format(item) {
      return _format(item);
    }
  }, {
    key: 'parse',
    value: function parse(item) {
      return _parse(item);
    }
  }]);

  return BaseResource;
}(_restResource2.default);

exports.default = BaseResource;
;

},{"common/resource/services/restResource":60}],267:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  /**
   * CALENDAR
   */
  'calendar-event-types': {
    controller: 'CreateCalendarEventTypeController',
    fields: {
      'name': 'Name'
    },
    labels: {
      single: 'Calendar Event Type',
      multiple: 'Calendar Event Types'
    },
    resource: 'CalendarEventTypeResource'
  },

  /**
   * CONSUMABLES
   */
  'consumable-types': {
    fields: {
      'name': 'Name'
    },
    labels: {
      single: 'Consumable Type',
      multiple: 'Consumable Types'
    },
    resource: 'ConsumableTypeResource'
  },

  /**
   * DEPLOYMENTS
   */
  /*
  'abort-reasons': {
    fields: {
      'name': 'Name',
    },
    labels: {
      single: 'Abort Reason',
      multiple: 'Abort Reasons',
    },
    resource: 'AbortReasonResource',
  },
   */

  'deployment-statuses': {
    controller: 'CreateDeploymentStatusController',
    fields: {
      'name': 'Name'
    },
    labels: {
      single: 'Deployment Status',
      multiple: 'Deployment Statuses'
    },
    resource: 'DeploymentStatusResource'
  },

  'risk-assessment-outcomes': {
    fields: {
      'name': 'Name'
    },
    labels: {
      single: 'Risk Assessment Outcome',
      multiple: 'Risk Assessment Outcomes'
    },
    resource: 'RiskAssessmentOutcomeResource'
  },

  /**
   * LOCATIONS
   */
  'locations': {
    fields: {
      'name': 'Name'
    },
    form: 'location.tpl.html',
    labels: {
      single: 'Location',
      multiple: 'Locations'
    },
    resource: 'LocationResource',
    query: {
      include: 'locationType'
    },
    relationData: {
      'locationTypes': {
        resource: 'LocationTypeResource',
        method: 'index'
      }
    }
  },

  'location-types': {
    fields: {
      'name': 'Name'
    },
    labels: {
      single: 'Location Type',
      multiple: 'Location Types'
    },
    resource: 'LocationTypeResource'
  },

  /**
   * DRUGS
   */
  'infusion-solutions': {
    fields: {
      'name': 'Name'
    },
    labels: {
      single: 'Infusion Solution',
      multiple: 'Infusion Solutions'
    },
    resource: 'InfusionSolutionResource'
  },

  'medication-routes': {
    fields: {
      'name': 'Name'
    },
    labels: {
      single: 'Medication Route',
      multiple: 'Medication Routes'
    },
    resource: 'MedicationRouteResource'
  },

  /**
   * HOSPITALS
   */
  'hospital-specialties': {
    fields: {
      'name': 'Name'
    },
    labels: {
      single: 'Hospital Specialty',
      multiple: 'Hospital Specialties'
    },
    resource: 'HospitalSpecialtyResource'
  },

  /**
   * INCIDENTS
   */
  'ambulance-services': {
    controller: 'CreateAmbulanceServiceController',
    fields: {
      'name': 'Name',
      'has_reference_number': 'Has Reference Number'
    },
    labels: {
      single: 'Ambulance Service',
      multiple: 'Ambulance Services'
    },
    resource: 'AmbulanceServiceResource',
    form: 'ambulance-service.tpl.html'
  },

  'transfer-reasons': {
    fields: {
      'name': 'Name'
    },
    labels: {
      single: 'Transfer Reason',
      multiple: 'Transfer Reasons'
    },
    resource: 'DeploymentReasonResource'
  },

  'decline-reasons': {
    fields: {
      'name': 'Name'
    },
    labels: {
      single: 'Decline Reason',
      multiple: 'Decline Reasons'
    },
    resource: 'RefusalReasonResource'
  },

  'service-areas': {
    fields: {
      'name': 'Name'
    },
    labels: {
      single: 'Service Area',
      multiple: 'Service Areas'
    },
    resource: 'ServiceAreaResource'
  },

  'grades': {
    fields: {
      'name': 'Name'
    },
    labels: {
      single: 'Grade',
      multiple: 'Grades'
    },
    resource: 'GradeResource'
  },

  'wards': {
    fields: {
      'name': 'Name'
    },
    labels: {
      single: 'Ward',
      multiple: 'Wards'
    },
    resource: 'WardResource'
  },

  /**
   * PRFs
   */
  'body-parts': {
    controller: 'CreateBodyPartController',
    fields: {
      'name': 'Name'
    },
    labels: {
      single: 'Body Region',
      multiple: 'Body Regions'
    },
    resource: 'BodyPartResource',
    relationData: {
      'injuries': {
        resource: 'InjuryResource',
        method: 'index'
      },
      'illnesses': {
        resource: 'IllnessResource',
        method: 'index'
      },
      'interventions': {
        resource: 'InterventionResource',
        method: 'index'
      }
    },
    multiSelections: ['injuries', 'illnesses', 'interventions']
  },

  'injuries': {
    controller: 'CreateCategoryWithTypesController',
    fields: {
      'name': 'Name'
    },
    labels: {
      single: 'Injury',
      multiple: 'Injuries'
    },
    resource: 'InjuryResource'
  },

  'interventions': {
    controller: 'CreateInterventionController',
    fields: {
      'name': 'Name'
    },
    labels: {
      single: 'Intervention',
      multiple: 'Interventions'
    },
    relationData: {
      'interventionAttributeTypes': {
        resource: 'InterventionResource',
        method: 'getAttributeTypes'
      }
    },
    resource: 'InterventionResource'
  },

  'illnesses': {
    controller: 'CreateCategoryWithTypesController',
    fields: {
      'name': 'Name'
    },
    labels: {
      single: 'Illness',
      multiple: 'Illnesses'
    },
    resource: 'IllnessResource'
  },

  'patient-types': {
    fields: {
      'name': 'Name'
    },
    labels: {
      single: 'Patient Type',
      multiple: 'Patient Types'
    },
    resource: 'PatientTypeResource'
  },

  'infection-statuses': {
    fields: {
      'name': 'Name'
    },
    labels: {
      single: 'Infection Status',
      multiple: 'Infection Statuses'
    },
    resource: 'InfectionStatusResource'
  },

  /**
   * CLINICAL INCIDENT
   */

  'communications': {
    fields: {
      'name': 'Name'
    },
    labels: {
      single: 'Communication',
      multiple: 'Communications'
    },
    resource: 'CommunicationResource'
  },

  'equipment-problems': {
    fields: {
      'name': 'Name'
    },
    labels: {
      single: 'Equipment Problem',
      multiple: 'Equipment Problems'
    },
    resource: 'EquipmentProblemResource'
  },

  'organisational-delays': {
    fields: {
      'name': 'Name'
    },
    labels: {
      single: 'Organisational Delay',
      multiple: 'Organisational Delays'
    },
    resource: 'OrganisationalDelayResource'
  },

  'physiological-deteriorations': {
    fields: {
      'name': 'Name'
    },
    labels: {
      single: 'Physiological Deterioration',
      multiple: 'Physiological Deteriorations'
    },
    resource: 'PhysiologicalDeteriorationResource'
  },

  /**
   * USERS
   */
  'professions': {
    fields: {
      'title': 'Title'
    },
    form: 'profession.tpl.html',
    labels: {
      single: 'Profession',
      multiple: 'Professions'
    },
    resource: 'ProfessionResource'
  },

  /**
   * VEHICLES
   */
  'offline-reasons': {
    fields: {
      'name': 'Name'
    },
    labels: {
      single: 'Offline Reason',
      multiple: 'Offline Reasons'
    },
    resource: 'OfflineReasonResource'
  },

  /**
   * STOCK
   */
  'suppliers': {
    fields: {
      'name': 'Name'
    },
    form: 'supplier.tpl.html',
    labels: {
      single: 'Supplier',
      multiple: 'Suppliers'
    },
    resource: 'SupplierResource'
  },

  'stock-locations': {
    fields: {
      'name': 'Name'
    },
    labels: {
      single: 'Stock Location',
      multiple: 'Stock Locations'
    },
    resource: 'StockLocationResource'
  }
};

},{}],268:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CategoryItemsController = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _angular = require('angular');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var CategoryItemsController = exports.CategoryItemsController = function () {
  function CategoryItemsController(Toast, Utils, Dialog) {
    'ngInject';

    _classCallCheck(this, CategoryItemsController);

    this._Toast = Toast;
    this._Utils = Utils;
    this._Dialog = Dialog;
  }

  _createClass(CategoryItemsController, [{
    key: '$onInit',
    value: function $onInit() {
      var _this = this;

      this.items = [];
      this.category.resource.index(this.category.query || {}).then(function (categoryData) {
        _this.items = categoryData;
      });
      this.relationData = {};
      if (this.category.relationData) {
        Object.keys(this.category.relationData).forEach(function (key) {
          var resource = _this.category.relationData[key].resource;
          var method = _this.category.relationData[key].method;
          resource[method]().then(function (data) {
            _this.relationData[key] = data;
          });
        });
      }
    }
  }, {
    key: 'showCreateDialog',
    value: function showCreateDialog(evt, categoryData) {
      var _this2 = this;

      var editing = !!categoryData;

      var dialogParams = {
        controller: this.category.controller || 'CreateCategoryController',
        locals: {
          categoryData: (0, _angular.copy)(categoryData), // copy to avoid passing by reference
          editing: editing,
          title: this.category.labels.single,
          resource: this.category.resource,
          relationData: this.relationData,
          multiSelections: this.category.multiSelections,
          form: this.category.form || 'default.tpl.html',
          includeQuery: this.category.include ? { include: this.category.include } : {}
        },
        targetEvent: evt,
        templateUrl: 'pages/admin/categories/templates/create-category-dialog.tpl.html'
      };

      this._Dialog.show(dialogParams).then(function (categoryItem) {
        if (editing) {
          var item = _this2._Utils.findObjectInArrayById(_this2.items, categoryItem.id);
          Object.assign(item, categoryItem);
          _this2._Toast.showSimple(_this2.category.labels.single + ' updated.');
        } else {
          _this2.items.push(categoryItem);
          _this2._Toast.showSimple('New ' + _this2.category.labels.single + ' created.');
        }
      }).catch(function (categoryItemId) {
        if (categoryItemId) {
          var item = _this2._Utils.findObjectInArrayById(_this2.items, categoryItemId);
          _this2._Utils.removeFromArray(_this2.items, item);
          _this2._Toast.showSimple(_this2.category.labels.single + ' deleted.');
        }
      });
    }
  }]);

  return CategoryItemsController;
}();

exports.default = {
  bindings: {
    category: '<'
  },
  controller: CategoryItemsController,
  templateUrl: 'pages/admin/categories/components/category-items/category-items.tpl.html'
};

},{"angular":"angular"}],269:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _nodeUuid = require('node-uuid');

var _nodeUuid2 = _interopRequireDefault(_nodeUuid);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var CreateAmbulanceServiceController = function () {
  function CreateAmbulanceServiceController(Dialog, $scope) {
    'ngInject';

    var _this = this;

    _classCallCheck(this, CreateAmbulanceServiceController);

    this._Dialog = Dialog;

    this.ID = _nodeUuid2.default.v4();

    this.includePath = 'pages/admin/categories/templates/forms/' + this.form;

    if (this.multiSelections) {
      this.selected = {};

      this.multiSelections.forEach(function (select) {
        _this.selected[select] = [];

        if (_this.categoryData && _this.categoryData[select]) {
          _this.selected[select] = _this.categoryData[select];
        }
      });
    }

    $scope.$watch(function () {
      return _this.categoryData;
    }, function (newVal) {
      if (!newVal || newVal.has_reference_number) return;
      _this.categoryData.has_reference_number = false;
    });
  }

  _createClass(CreateAmbulanceServiceController, [{
    key: 'submit',
    value: function submit(categoryData) {
      var _this2 = this;

      if (this.form.$invalid) {
        return;
      }

      if (this.multiSelections) {
        this.multiSelections.forEach(function (selection) {
          categoryData[selection] = _this2.selected[selection];
        });
      }

      if (this.editing) {
        this._update(categoryData);
      } else {
        this._create(categoryData);
      }
    }
  }, {
    key: 'cancel',
    value: function cancel() {
      this._Dialog.cancel();
    }
  }, {
    key: 'delete',
    value: function _delete(id) {
      var _this3 = this;

      this._Dialog.confirm().then(function () {
        _this3.resource.destroy(id).then(function () {
          _this3._Dialog.cancel(id);
        });
      });
    }
  }, {
    key: '_create',
    value: function _create(categoryData) {
      var _this4 = this;

      categoryData.id = this.ID;

      this.resource.create(categoryData).then(function () {
        _this4._Dialog.hide(categoryData);
      }).catch(console.log.bind(console));
    }
  }, {
    key: '_update',
    value: function _update(categoryData) {
      var _this5 = this;

      this.resource.update(categoryData.id, categoryData, this.includeQuery).then(function (category) {
        _this5._Dialog.hide(category);
      }).catch(console.log.bind(console));
    }
  }]);

  return CreateAmbulanceServiceController;
}();

exports.default = CreateAmbulanceServiceController;

},{"node-uuid":"node-uuid"}],270:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _nodeUuid = require('node-uuid');

var _nodeUuid2 = _interopRequireDefault(_nodeUuid);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var CreateBodyPartController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function CreateBodyPartController(Dialog) {
    var _this = this;

    _classCallCheck(this, CreateBodyPartController);

    this._Dialog = Dialog;
    this.ID = _nodeUuid2.default.v4();
    this.includePath = 'pages/admin/categories/templates/forms/body-part.tpl.html';

    if (!this.categoryData) {
      this.categoryData = this._initialiseCategoryData();
    }

    if (this.multiSelections) {
      this.selected = {};

      this.multiSelections.forEach(function (select) {
        _this.selected[select] = [];

        if (_this.categoryData && _this.categoryData[select]) {
          _this.selected[select] = _this.categoryData[select];
        }
      });
    }
  }

  _createClass(CreateBodyPartController, [{
    key: 'showAttributesDialog',
    value: function showAttributesDialog($event, title, options, selected) {
      var dialog = this._Dialog;
      var dialogParams = {
        controller: function controller() {
          this.submit = function () {
            dialog.hide();
          };
        },
        locals: {
          title: title,
          options: options,
          selected: selected
        },
        targetEvent: $event,
        templateUrl: 'pages/admin/categories/templates/forms/body-part-attributes.tpl.html',
        multiple: true
      };
      this._Dialog.show(dialogParams);
    }
  }, {
    key: 'submit',
    value: function submit(categoryData) {
      var _this2 = this;

      if (this.form.$invalid) {
        return;
      }

      if (this.multiSelections) {
        this.multiSelections.forEach(function (selection) {
          categoryData[selection] = _this2.selected[selection];
        });
      }

      if (this.editing) {
        this._update(categoryData);
      } else {
        this._create(categoryData);
      }
    }
  }, {
    key: 'cancel',
    value: function cancel() {
      this._Dialog.cancel();
    }
  }, {
    key: 'delete',
    value: function _delete(id) {
      var _this3 = this;

      this._Dialog.confirm().then(function () {
        return _this3.resource.destroy(id);
      }).then(function () {
        _this3._Dialog.cancel(id);
      });
    }
  }, {
    key: '_initialiseCategoryData',
    value: function _initialiseCategoryData() {
      return {
        id: '',
        name: '',
        marker: {
          x: 50,
          y: 50
        }
      };
    }
  }, {
    key: '_create',
    value: function _create(categoryData) {
      var _this4 = this;

      categoryData.id = this.ID;

      this.resource.create(categoryData).then(function () {
        _this4._Dialog.hide(categoryData);
      }).catch(console.log.bind(console));
    }
  }, {
    key: '_update',
    value: function _update(categoryData) {
      var _this5 = this;

      this.resource.update(categoryData.id, categoryData).then(function (category) {
        _this5._Dialog.hide(category);
      }).catch(console.log.bind(console));
    }
  }]);

  return CreateBodyPartController;
}();

exports.default = CreateBodyPartController;

},{"node-uuid":"node-uuid"}],271:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _nodeUuid = require('node-uuid');

var _nodeUuid2 = _interopRequireDefault(_nodeUuid);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var CalendarEventTypeController = function () {
  function CalendarEventTypeController($mdColorPalette, Dialog) {
    'ngInject';

    var _this = this;

    _classCallCheck(this, CalendarEventTypeController);

    this._colorPalette = $mdColorPalette;
    this._Dialog = Dialog;

    this.ID = _nodeUuid2.default.v4();

    this.includePath = 'pages/admin/categories/templates/forms/calendar-event-type.tpl.html';

    this.disabledColors = ['yellow', 'lime', 'amber'];
    this.colors = Object.keys($mdColorPalette).filter(function (color) {
      if (_this.disabledColors.indexOf(color) === -1) {
        return color;
      }
    }).map(function (color) {
      var value = $mdColorPalette[color][500].value;
      var nameParts = color.split('-').map(function (part) {
        return part.charAt(0).toUpperCase() + part.slice(1);
      });

      return {
        name: nameParts.join(' '),
        value: 'rgb(' + value[0] + ',' + value[1] + ',' + value[2] + ')'
      };
    });
  }

  _createClass(CalendarEventTypeController, [{
    key: 'submit',
    value: function submit(categoryData) {
      if (this.form.$invalid) {
        return;
      }

      if (this.editing) {
        this._update(categoryData);
      } else {
        this._create(categoryData);
      }
    }
  }, {
    key: 'cancel',
    value: function cancel() {
      this._Dialog.cancel();
    }
  }, {
    key: 'delete',
    value: function _delete(id) {
      var _this2 = this;

      this._Dialog.confirm().then(function () {
        _this2.resource.destroy(id).then(function () {
          _this2._Dialog.cancel(id);
        });
      });
    }
  }, {
    key: '_create',
    value: function _create(categoryData) {
      var _this3 = this;

      categoryData.id = this.ID;

      this.resource.create(categoryData).then(function () {
        _this3._Dialog.hide(categoryData);
      }).catch(console.log.bind(console));
    }
  }, {
    key: '_update',
    value: function _update(categoryData) {
      var _this4 = this;

      this.resource.update(categoryData.id, categoryData).then(function (category) {
        _this4._Dialog.hide(category);
      }).catch(console.log.bind(console));
    }
  }]);

  return CalendarEventTypeController;
}();

exports.default = CalendarEventTypeController;

},{"node-uuid":"node-uuid"}],272:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _nodeUuid = require('node-uuid');

var _nodeUuid2 = _interopRequireDefault(_nodeUuid);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var CreateCategoryWithTypesController = function () {
  function CreateCategoryWithTypesController(Utils, Dialog) {
    'ngInject';

    _classCallCheck(this, CreateCategoryWithTypesController);

    this._Utils = Utils;
    this._Dialog = Dialog;

    this.ID = _nodeUuid2.default.v4();

    this.includePath = 'pages/admin/categories/templates/forms/category-with-types.tpl.html';

    if (!this.categoryData) {
      this.categoryData = {};
    }

    if (!this.categoryData.types) {
      this.categoryData.types = [];
    }
  }

  _createClass(CreateCategoryWithTypesController, [{
    key: 'addType',
    value: function addType() {
      this.categoryData.types.push({
        id: _nodeUuid2.default.v4()
      });
    }
  }, {
    key: 'removeType',
    value: function removeType(item) {
      this._Utils.removeFromArray(this.categoryData.types, item);
    }
  }, {
    key: 'submit',
    value: function submit(categoryData) {
      if (this.form.$invalid) {
        return;
      }

      if (this.editing) {
        this._update(categoryData);
      } else {
        this._create(categoryData);
      }
    }
  }, {
    key: 'cancel',
    value: function cancel() {
      this._Dialog.cancel();
    }
  }, {
    key: 'delete',
    value: function _delete(id) {
      var _this = this;

      this._Dialog.confirm().then(function () {
        _this.resource.destroy(id).then(function () {
          _this._Dialog.cancel(id);
        });
      });
    }
  }, {
    key: '_create',
    value: function _create(categoryData) {
      var _this2 = this;

      categoryData.id = this.ID;

      this.resource.create(categoryData).then(function () {
        _this2._Dialog.hide(categoryData);
      }).catch(console.log.bind(console));
    }
  }, {
    key: '_update',
    value: function _update(categoryData) {
      var _this3 = this;

      this.resource.update(categoryData.id, categoryData).then(function (category) {
        _this3._Dialog.hide(category);
      }).catch(console.log.bind(console));
    }
  }]);

  return CreateCategoryWithTypesController;
}();

exports.default = CreateCategoryWithTypesController;

},{"node-uuid":"node-uuid"}],273:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _nodeUuid = require('node-uuid');

var _nodeUuid2 = _interopRequireDefault(_nodeUuid);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var CreateCategoryController = function () {
  function CreateCategoryController(Dialog) {
    'ngInject';

    var _this = this;

    _classCallCheck(this, CreateCategoryController);

    this._Dialog = Dialog;

    this.ID = _nodeUuid2.default.v4();

    this.includePath = 'pages/admin/categories/templates/forms/' + this.form;

    if (this.multiSelections) {
      this.selected = {};

      this.multiSelections.forEach(function (select) {
        _this.selected[select] = [];

        if (_this.categoryData && _this.categoryData[select]) {
          _this.selected[select] = _this.categoryData[select];
        }
      });
    }
  }

  _createClass(CreateCategoryController, [{
    key: 'submit',
    value: function submit(categoryData) {
      var _this2 = this;

      if (this.form.$invalid) {
        return;
      }

      if (this.multiSelections) {
        this.multiSelections.forEach(function (selection) {
          categoryData[selection] = _this2.selected[selection];
        });
      }

      if (this.editing) {
        this._update(categoryData);
      } else {
        this._create(categoryData);
      }
    }
  }, {
    key: 'cancel',
    value: function cancel() {
      this._Dialog.cancel();
    }
  }, {
    key: 'delete',
    value: function _delete(id) {
      var _this3 = this;

      this._Dialog.confirm().then(function () {
        _this3.resource.destroy(id).then(function () {
          _this3._Dialog.cancel(id);
        });
      });
    }
  }, {
    key: '_create',
    value: function _create(categoryData) {
      var _this4 = this;

      categoryData.id = this.ID;

      this.resource.create(categoryData).then(function () {
        _this4._Dialog.hide(categoryData);
      }).catch(console.log.bind(console));
    }
  }, {
    key: '_update',
    value: function _update(categoryData) {
      var _this5 = this;

      this.resource.update(categoryData.id, categoryData, this.includeQuery).then(function (category) {
        _this5._Dialog.hide(category);
      }).catch(console.log.bind(console));
    }
  }]);

  return CreateCategoryController;
}();

exports.default = CreateCategoryController;

},{"node-uuid":"node-uuid"}],274:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _nodeUuid = require('node-uuid');

var _nodeUuid2 = _interopRequireDefault(_nodeUuid);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var CreateDeploymentStatusController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function CreateDeploymentStatusController(Dialog, $scope, $timeout) {
    var _this = this;

    _classCallCheck(this, CreateDeploymentStatusController);

    this._Dialog = Dialog;
    this._scope = $scope;
    this._timeout = $timeout;

    this.ID = _nodeUuid2.default.v4();

    this.includePath = 'pages/admin/categories/templates/forms/deployment-status.tpl.html';

    this._scope.$watch(function () {
      return _this.categoryData;
    }, function (newVal) {
      if (!newVal) return;
      if (newVal.is_at_hospital) {
        _this.categoryData.has_destination = true;
      }
    });
  }

  _createClass(CreateDeploymentStatusController, [{
    key: 'submit',
    value: function submit(categoryData) {
      if (this.form.$invalid) {
        return;
      }

      if (this.editing) {
        this._update(categoryData);
      } else {
        this._create(categoryData);
      }
    }
  }, {
    key: 'cancel',
    value: function cancel() {
      this._Dialog.cancel();
    }
  }, {
    key: 'delete',
    value: function _delete(id) {
      var _this2 = this;

      this._Dialog.confirm().then(function () {
        _this2.resource.destroy(id).then(function () {
          _this2._Dialog.cancel(id);
        });
      });
    }
  }, {
    key: '_create',
    value: function _create(categoryData) {
      var _this3 = this;

      categoryData.id = this.ID;

      this.resource.create(categoryData).then(function () {
        _this3._Dialog.hide(categoryData);
      }).catch(console.log.bind(console));
    }
  }, {
    key: '_update',
    value: function _update(categoryData) {
      var _this4 = this;

      this.resource.update(categoryData.id, categoryData).then(function (category) {
        _this4._Dialog.hide(category);
      }).catch(console.log.bind(console));
    }
  }]);

  return CreateDeploymentStatusController;
}();

exports.default = CreateDeploymentStatusController;

},{"node-uuid":"node-uuid"}],275:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _nodeUuid = require('node-uuid');

var _nodeUuid2 = _interopRequireDefault(_nodeUuid);

var _interventionAttributeValues = require('./intervention-attribute-values.controller');

var _interventionAttributeValues2 = _interopRequireDefault(_interventionAttributeValues);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var CreateInterventionController = function () {
  function CreateInterventionController(Utils, Dialog) {
    'ngInject';

    _classCallCheck(this, CreateInterventionController);

    this._Utils = Utils;
    this._Dialog = Dialog;

    this.includePath = 'pages/admin/categories/templates/forms/intervention.tpl.html';

    if (!this.categoryData) {
      this.categoryData = {
        id: _nodeUuid2.default.v4(),
        attributes: []
      };
    }
  }

  _createClass(CreateInterventionController, [{
    key: 'addAttribute',
    value: function addAttribute() {
      this.categoryData.attributes.push({
        id: _nodeUuid2.default.v4()
      });
    }
  }, {
    key: 'removeAttribute',
    value: function removeAttribute(item) {
      this._Utils.removeFromArray(this.categoryData.attributes, item);
    }
  }, {
    key: 'showAttributeValuesDialog',
    value: function showAttributeValuesDialog($event, attribute) {
      var dialogParams = {
        controller: _interventionAttributeValues2.default,
        locals: {
          attribute: attribute
        },
        targetEvent: $event,
        templateUrl: 'pages/admin/categories/templates/forms/intervention-attribute-values.tpl.html',
        multiple: true
      };
      this._Dialog.show(dialogParams);
    }
  }, {
    key: 'submit',
    value: function submit(categoryData) {
      if (this.form.$invalid) {
        return;
      }

      if (this.editing) {
        this._update(categoryData);
      } else {
        this._create(categoryData);
      }
    }
  }, {
    key: 'cancel',
    value: function cancel() {
      this._Dialog.cancel();
    }
  }, {
    key: 'delete',
    value: function _delete(id) {
      var _this = this;

      this._Dialog.confirm().then(function () {
        _this.resource.destroy(id).then(function () {
          _this._Dialog.cancel(id);
        });
      });
    }
  }, {
    key: '_create',
    value: function _create(categoryData) {
      var _this2 = this;

      this.resource.create(categoryData).then(function () {
        _this2._Dialog.hide(categoryData);
      }).catch(console.log.bind(console));
    }
  }, {
    key: '_update',
    value: function _update(categoryData) {
      var _this3 = this;

      this.resource.update(categoryData.id, categoryData).then(function (category) {
        _this3._Dialog.hide(category);
      }).catch(console.log.bind(console));
    }
  }]);

  return CreateInterventionController;
}();

exports.default = CreateInterventionController;

},{"./intervention-attribute-values.controller":276,"node-uuid":"node-uuid"}],276:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _nodeUuid = require('node-uuid');

var _nodeUuid2 = _interopRequireDefault(_nodeUuid);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var InterventionAttributeValuesController = function () {
  function InterventionAttributeValuesController(Utils, Dialog) {
    'ngInject';

    _classCallCheck(this, InterventionAttributeValuesController);

    this._Utils = Utils;
    this._Dialog = Dialog;

    if (!this.attribute.values) {
      this.attribute.values = [];
    }
  }

  _createClass(InterventionAttributeValuesController, [{
    key: 'submit',
    value: function submit() {
      this._Dialog.hide();
    }
  }, {
    key: 'addValue',
    value: function addValue() {
      this.attribute.values.push({
        id: _nodeUuid2.default.v4()
      });
    }
  }, {
    key: 'removeValue',
    value: function removeValue(item) {
      this._Utils.removeFromArray(this.attribute.values, item);
    }
  }]);

  return InterventionAttributeValuesController;
}();

exports.default = InterventionAttributeValuesController;
;

},{"node-uuid":"node-uuid"}],277:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ModuleConfig;

var _angular = require('angular');

/**
 * Resolves object.
 *
 * @type {Object}
 */
var resolve = {
  category: function category($stateParams, CATEGORY_MAP) {
    'ngInject';

    return CATEGORY_MAP[$stateParams.categoryName];
  }
};

/**
 * Category controller.
 *
 * @param  {Object} $injector Injector object
 * @param  {Object} category  Category object
 * @return {void}
 */
function categoryController($injector, category) {
  'ngInject';

  var _this = this;

  this.category = (0, _angular.copy)(category);

  var injectedServices = {};

  try {
    var service = $injector.get(category.resource);
    this.category.resource = service;
    injectedServices[category.resource] = service;
  } catch (err) {
    console.log(err);
  }

  if (category.relationData) {
    try {
      Object.keys(category.relationData).forEach(function (key) {
        if (injectedServices[category.relationData[key].resource]) {
          _this.category.relationData[key].resource = injectedServices[category.relationData[key].resource];
        } else {
          var _service = $injector.get(category.relationData[key].resource);
          _this.category.relationData[key].resource = _service;
          injectedServices[category.relationData[key].resource] = _service;
        }
      });
    } catch (err) {
      console.log(err);
    }
  }

  if (category.controller) {
    this.category.controller = category.controller;
  }

  if (category.form) {
    this.category.form = category.form;
  }
};

/**
 * Prevents category list component from being loaded if no category resolved.
 *
 * @param  {Object} $state   State object
 * @param  {Object} category Category object
 * @return {void}
 */
function onEnter($state, category) {
  'ngInject';

  if (!category) {
    // NOTE Instead of dashboard, go back to previous state or prevent state change
    $state.go($state.current.name || 'dashboard');
  }
};

function ModuleConfig($stateProvider) {
  'ngInject';

  $stateProvider.state('admin.category', {
    url: '/categories/:categoryName',
    template: '<category-items category="$ctrl.category" layout="column" flex></category-items>',
    resolve: resolve,
    onEnter: onEnter,
    controller: categoryController,
    controllerAs: '$ctrl',
    data: {
      title: 'Categories'
    }
  });
}

},{"angular":"angular"}],278:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _angularUiRouter = require('angular-ui-router');

var _angularUiRouter2 = _interopRequireDefault(_angularUiRouter);

var _module2 = require('./module.config');

var _module3 = _interopRequireDefault(_module2);

var _categoryMap = require('./category-map');

var _categoryMap2 = _interopRequireDefault(_categoryMap);

var _createAmbulanceService = require('./controllers/create-ambulance-service.controller');

var _createAmbulanceService2 = _interopRequireDefault(_createAmbulanceService);

var _createBodyPart = require('./controllers/create-body-part.controller');

var _createBodyPart2 = _interopRequireDefault(_createBodyPart);

var _createCategory = require('./controllers/create-category.controller');

var _createCategory2 = _interopRequireDefault(_createCategory);

var _createCalendarEventType = require('./controllers/create-calendar-event-type.controller');

var _createCalendarEventType2 = _interopRequireDefault(_createCalendarEventType);

var _createCategoryWithTypes = require('./controllers/create-category-with-types.controller');

var _createCategoryWithTypes2 = _interopRequireDefault(_createCategoryWithTypes);

var _createDeploymentStatus = require('./controllers/create-deployment-status.controller');

var _createDeploymentStatus2 = _interopRequireDefault(_createDeploymentStatus);

var _createIntervention = require('./controllers/create-intervention.controller');

var _createIntervention2 = _interopRequireDefault(_createIntervention);

var _categoryItems = require('./components/category-items/category-items.component');

var _categoryItems2 = _interopRequireDefault(_categoryItems);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _module = _angular2.default.module('gnaas.pages.admin.categories', [_angularUiRouter2.default]).constant('CATEGORY_MAP', _categoryMap2.default).config(_module3.default).controller('CreateAmbulanceServiceController', _createAmbulanceService2.default).controller('CreateBodyPartController', _createBodyPart2.default).controller('CreateCategoryController', _createCategory2.default).controller('CreateCalendarEventTypeController', _createCalendarEventType2.default).controller('CreateCategoryWithTypesController', _createCategoryWithTypes2.default).controller('CreateDeploymentStatusController', _createDeploymentStatus2.default).controller('CreateInterventionController', _createIntervention2.default).component('categoryItems', _categoryItems2.default);

exports.default = _module.name;
//

},{"./category-map":267,"./components/category-items/category-items.component":268,"./controllers/create-ambulance-service.controller":269,"./controllers/create-body-part.controller":270,"./controllers/create-calendar-event-type.controller":271,"./controllers/create-category-with-types.controller":272,"./controllers/create-category.controller":273,"./controllers/create-deployment-status.controller":274,"./controllers/create-intervention.controller":275,"./module.config":277,"angular":"angular","angular-ui-router":"angular-ui-router"}],279:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ConsumableListController;

var _consumableDialog = require('../services/consumable-dialog/consumable-dialog.controller');

var _consumableDialog2 = _interopRequireDefault(_consumableDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var DEFAULT_LIST_FIELDS = {
  name: 'Name',
  typeString: 'Type'
};

function ConsumableListController(ConsumableResource, ConsumableTypeResource, SupplierResource, StockLocationResource) {
  'ngInject';

  var _this = this;

  this.singularName = 'Consumable';
  this.pluralName = 'Consumables';

  this.createDialogController = _consumableDialog2.default;
  this.createDialogTemplateUrl = 'pages/admin/consumables/services/consumable-dialog/consumable-dialog.tpl.html';

  ConsumableResource.index({ include: 'productCodes.supplier,stockLocationProperties,type' }).then(function (items) {
    _this.items = items.map(function (item) {
      return Object.assign(item, { typeString: item.type.name });
    });
  });

  this.relationData = { consumableTypes: [], stockLocations: [], suppliers: [] };
  ConsumableTypeResource.index().then(function (items) {
    return _this.relationData.consumableTypes = items;
  });
  SupplierResource.index().then(function (items) {
    _this.relationData.suppliers = items;
  });
  StockLocationResource.index().then(function (items) {
    _this.relationData.stockLocations = items;
  });

  this.hasProfiles = false;
  this.listFields = Object.assign({}, DEFAULT_LIST_FIELDS);
};

},{"../services/consumable-dialog/consumable-dialog.controller":283}],280:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _module = require('common/resource/module.constants');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var getCellClass = function getCellClass(grid, row) {
  return row.entity.isExpired ? 'ui-grid-cell-bg-red' : '';
};

var DEFAULT_COLUMNS = {
  consumable_name: { name: 'Name' },
  expires_on: { name: 'Batch Expiry', type: 'date', cellFilter: 'date:"dd-MM-yyyy"' },
  consumable_type: { name: 'Type' },
  location: { name: 'Location' }
};
Object.values(DEFAULT_COLUMNS).forEach(function (col) {
  Object.assign(col, { width: '*', cellClass: getCellClass });
});

var DEFAULT_GRID_OPTS = { enablePagination: true, useExternalPagination: true };

var ConsumableStockController = function () {
  /**
   * @ngInject
   * @constructor
   */
  function ConsumableStockController(ConsumableTypeResource, ConsumableBatchResource, ConsumableOrderFormDialog, CreateConsumableStockDialog, Dialog, StockLocationResource) {
    _classCallCheck(this, ConsumableStockController);

    this.ConsumableTypeResource = ConsumableTypeResource;
    this.ConsumableBatchResource = ConsumableBatchResource;
    this.ConsumableOrderFormDialog = ConsumableOrderFormDialog;
    this.CreateConsumableStockDialog = CreateConsumableStockDialog;
    this.Dialog = Dialog;
    this.StockLocationResource = StockLocationResource;
  }

  _createClass(ConsumableStockController, [{
    key: '$onInit',
    value: function $onInit() {
      var _this = this;

      this.actions = [{ name: 'Record Delivery', action: this._showCreateDialog.bind(this) }, { name: 'Print Order', action: this._showOrderFormDialog.bind(this) }];

      this.searchGridActionCol = {
        options: {
          width: '86'
        },
        actions: [{
          name: 'Remove',
          callback: this.removeBatch.bind(this)
        }]
      };

      this.pagination = { pageNumber: 0, pageSize: 0 };
      this.columns = Object.assign({}, DEFAULT_COLUMNS);
      this.options = Object.assign({}, DEFAULT_GRID_OPTS, {
        resolvePage: function resolvePage(pageNumber, pageAmount) {
          _this.updateData(pageNumber, pageAmount);
        }
      });

      this.filters = {};
      this.ConsumableTypeResource.index({ include: 'consumables' }).then(function (items) {
        _this.filters.consumableTypes = items;
        _this.filters.consumables = items.reduce(function (acc, cur) {
          return acc.concat(cur.consumables);
        }, []);
      });
      this.StockLocationResource.index().then(function (items) {
        return _this.filters.stockLocations = items;
      });

      this.criteria = {
        consumableId: null,
        consumableTypeId: null,
        stockLocationId: null,
        includeExpiredBatches: true
      };

      this.updateData();
    }
  }, {
    key: 'removeBatch',
    value: function removeBatch(batch) {
      var _this2 = this;

      this.Dialog.confirm('Are you sure you want to delete this batch?').then(function () {
        return _this2.ConsumableBatchResource.destroy(batch.id);
      }).then(this.updateData.bind(this)).catch(console.log.bind(console));
    }
  }, {
    key: 'updateData',
    value: function updateData(pageNumber, pageSize) {
      var _this3 = this;

      if (!pageNumber) pageNumber = 1;
      if (!pageSize) pageSize = 25;

      this.pagination.pageNumber = pageNumber;
      this.pagination.pageSize = pageSize;

      var params = {
        expiry_mode: this.criteria.includeExpiredBatches ? 'all' : 'non-expired',
        page: pageNumber,
        page_size: pageSize,
        include: 'consumable.type,stockLocation'
      };

      if (this.criteria.consumableId) params.consumable_id = this.criteria.consumableId;
      if (this.criteria.consumableTypeId) params.consumable_type_id = this.criteria.consumableTypeId;
      if (this.criteria.stockLocationId) params.stock_location_id = this.criteria.stockLocationId;

      this.ConsumableBatchResource.index(params).then(function (data) {
        _this3.options.pageNumber = pageNumber;
        _this3.options.totalItems = data.pagination.total;
        _this3.batches = _this3._transformData(data.data);
      }).catch(console.log.bind(console));
    }
  }, {
    key: 'onConsumableTypeChange',
    value: function onConsumableTypeChange(_ref) {
      var name = _ref.name,
          value = _ref.value;

      var type = this.filters.consumableTypes.find(function (item) {
        return item.id === value;
      });
      this.filters.consumables = type ? type.consumables : this.filters.consumableTypes.reduce(function (acc, cur) {
        return acc.concat(cur.consumables);
      }, []);
    }
  }, {
    key: '_transformData',
    value: function _transformData(data) {
      var now = (0, _moment2.default)();
      return data.map(function (item) {
        var expiresOn = (0, _moment2.default)(item.expires_on, _module.DATE_TIME_FORMAT);
        item.isExpired = expiresOn.isSameOrBefore(now);
        item.expires_on = expiresOn.toDate();
        item.location = item.stockLocation.name;
        item.consumable_name = item.consumable.name;
        item.consumable_type = item.consumable.type.name;
        return item;
      });
    }
  }, {
    key: '_showCreateDialog',
    value: function _showCreateDialog($event) {
      var _this4 = this;

      this.CreateConsumableStockDialog.show($event).then(function () {
        _this4.updateData(_this4.pagination.pageNumber, _this4.pagination.pageSize);
      });
    }
  }, {
    key: '_showOrderFormDialog',
    value: function _showOrderFormDialog($event) {
      this.ConsumableOrderFormDialog.show({ $event: $event });
    }
  }]);

  return ConsumableStockController;
}();

exports.default = ConsumableStockController;

},{"common/resource/module.constants":57,"moment":"moment"}],281:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function ($stateProvider) {
  'ngInject';

  $stateProvider.state('admin.consumables', {
    abstract: true,
    url: '/consumables',
    template: '<div ui-view layout="column" flex></div>'
  }).state('admin.consumables.list', {
    url: '/list',
    controllerAs: '$ctrl',
    controller: 'ConsumableListController',
    templateUrl: 'common/resource/templates/resource.tpl.html',
    data: {
      title: 'Consumable List'
    }
  }).state('admin.consumables.stock', {
    url: '/stock',
    controllerAs: '$ctrl',
    controller: 'ConsumableStockController',
    templateUrl: 'pages/admin/consumables/templates/consumable-stock.tpl.html',
    data: {
      title: 'Consumable Stock'
    }
  });
};

;

},{}],282:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _angularUiRouter = require('angular-ui-router');

var _angularUiRouter2 = _interopRequireDefault(_angularUiRouter);

var _module2 = require('./module.config');

var _module3 = _interopRequireDefault(_module2);

var _consumableList = require('./controllers/consumable-list.controller');

var _consumableList2 = _interopRequireDefault(_consumableList);

var _consumableStock = require('./controllers/consumable-stock.controller');

var _consumableStock2 = _interopRequireDefault(_consumableStock);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _module = _angular2.default.module('gnaas.pages.admin.consumables', [_angularUiRouter2.default, 'ui.grid', 'ui.grid.pagination', 'ui.grid.autoResize', 'ui.grid.resizeColumns']).config(_module3.default).controller('ConsumableListController', _consumableList2.default).controller('ConsumableStockController', _consumableStock2.default);

exports.default = _module.name;

},{"./controllers/consumable-list.controller":279,"./controllers/consumable-stock.controller":280,"./module.config":281,"angular":"angular","angular-ui-router":"angular-ui-router"}],283:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _nodeUuid = require('node-uuid');

var _nodeUuid2 = _interopRequireDefault(_nodeUuid);

var _resourceDialogController = require('common/resource/controllers/resourceDialogController');

var _resourceDialogController2 = _interopRequireDefault(_resourceDialogController);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function generateStockLocationProperties(stockLocations) {
  return stockLocations.map(function (location) {
    return { id: _nodeUuid2.default.v4(), stock_location: location, minimum_stock_level: null };
  });
}

function generateMissingStockLocationProperties(stockLocationProps, stockLocations) {
  var existingStockLocationPropIds = stockLocationProps.map(function (i) {
    return i.stock_location.id;
  });
  var pendingStockLocations = stockLocations.filter(function (i) {
    return existingStockLocationPropIds.indexOf(i.id) === -1;
  });
  var generatedStockLocationProps = generateStockLocationProperties(pendingStockLocations);
  return [].concat(_toConsumableArray(stockLocationProps), _toConsumableArray(generatedStockLocationProps));
}

function generateProductCodes(suppliers) {
  return suppliers.map(function (supplier) {
    return { id: _nodeUuid2.default.v4(), supplier: supplier, product_code: null };
  });
}

function generateMissingProductCodes(productCodes, suppliers) {
  var existingProductCodeIds = productCodes.map(function (i) {
    return i.supplier.id;
  });
  var pendingProductCodes = suppliers.filter(function (i) {
    return existingProductCodeIds.indexOf(i.id) === -1;
  });
  var generatedProductCodes = generateProductCodes(pendingProductCodes);
  return [].concat(_toConsumableArray(productCodes), _toConsumableArray(generatedProductCodes));
}

var CreateDrugController = function (_ResourceDialogContro) {
  _inherits(CreateDrugController, _ResourceDialogContro);

  function CreateDrugController() {
    _classCallCheck(this, CreateDrugController);

    return _possibleConstructorReturn(this, (CreateDrugController.__proto__ || Object.getPrototypeOf(CreateDrugController)).apply(this, arguments));
  }

  _createClass(CreateDrugController, [{
    key: 'init',
    value: function init() {
      _get(CreateDrugController.prototype.__proto__ || Object.getPrototypeOf(CreateDrugController.prototype), 'init', this).call(this);
      this.ConsumableResource = this.$injector.get('ConsumableResource');
      this.ID = _nodeUuid2.default.v4();

      if (!this.item) {
        this.item = {};
      }

      if (!this.item.stock_location_properties || !this.item.stock_location_properties.length) {
        this.item.stock_location_properties = generateStockLocationProperties(this.relationData.stockLocations);
      } else {
        this.item.stock_location_properties = generateMissingStockLocationProperties(this.item.stock_location_properties, this.relationData.stockLocations);
      }

      if (!this.item.product_codes || !this.item.product_codes.length) {
        this.item.product_codes = generateProductCodes(this.relationData.suppliers);
      } else {
        this.item.product_codes = generateMissingProductCodes(this.item.product_codes, this.relationData.suppliers);
      }
    }
  }, {
    key: 'delete',
    value: function _delete(id) {
      var _this2 = this;

      this.Dialog.confirm().then(function () {
        _this2.ConsumableResource.destroy(id).then(function () {
          _this2.Dialog.cancel(id);
        }).catch(function (err) {
          if (err.status === 409 && err.data.errors && err.data.errors.hasOwnProperty('live_batches')) {
            _this2.Dialog.alert('This consumable has batches that have not been removed.', 'Could not delete consumable');
          }
        });
      });
    }
  }, {
    key: 'create',
    value: function create(data) {
      var _this3 = this;

      data.id = this.ID;

      this.ConsumableResource.create(data).then(function () {
        _this3.Dialog.hide(Object.assign(data, { typeString: data.type.name }));
      }).catch(console.log.bind(console));
    }
  }, {
    key: 'update',
    value: function update(data) {
      var _this4 = this;

      this.ConsumableResource.update(data.id, data, { include: 'productCodes.supplier,stockLocationProperties,type' }).then(function (drug) {
        _this4.Dialog.hide(Object.assign(drug, { typeString: drug.type.name }));
      }).catch(console.log.bind(console));
    }
  }]);

  return CreateDrugController;
}(_resourceDialogController2.default);

exports.default = CreateDrugController;

},{"common/resource/controllers/resourceDialogController":56,"node-uuid":"node-uuid"}],284:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var DevicePageController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function DevicePageController(DeviceResource, DeviceDialog, VehicleCallsignResource) {
    _classCallCheck(this, DevicePageController);

    this.DeviceResource = DeviceResource;
    this.DeviceDialog = DeviceDialog;
    this.VehicleCallsignResource = VehicleCallsignResource;
  }

  _createClass(DevicePageController, [{
    key: '$onInit',
    value: function $onInit() {
      var _this = this;

      this.listFields = {
        name: 'Name',
        activeString: 'Active',
        vehicleCallsignString: 'Vehicle',
        device_id: 'Device ID'
      };
      this.relationData = { vehicleCallsigns: [] };
      this.DeviceResource.index({ include: 'vehicleCallsign' }).then(function (items) {
        _this.items = items.map(_this._formatItem);
      });
      this.VehicleCallsignResource.index().then(function (items) {
        _this.relationData.vehicleCallsigns = items;
      });
    }
  }, {
    key: 'showCreateDialog',
    value: function showCreateDialog($event, item) {
      var _this2 = this;

      var isEditing = typeof item !== 'undefined' && item != null;
      this.DeviceDialog.show({ $event: $event, item: item, relationData: this.relationData }).then(function (data) {
        var item = _this2._formatItem(data);
        _this2.items = isEditing ? _this2.items.map(function (i) {
          return i.id === item.id ? item : i;
        }) : [].concat(_toConsumableArray(_this2.items), [item]);
      }).catch(function (item) {
        if (item) {
          _this2.items = _this2.items.filter(function (i) {
            return i.id !== item.id;
          });
        }
      });
    }
  }, {
    key: '_formatItem',
    value: function _formatItem(item) {
      item.activeString = item.active ? 'Yes' : 'No';
      item.vehicleCallsignString = item.vehicle_callsign ? item.vehicle_callsign.name : '–';
      return item;
    }
  }]);

  return DevicePageController;
}();

exports.default = {
  controller: DevicePageController,
  templateUrl: 'pages/admin/devices/components/device-page/device-page.tpl.html'
};

},{}],285:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function ($stateProvider) {
  'ngInject';

  $stateProvider.state('admin.devices', {
    url: '/devices',
    template: '<device-page layout="column" flex />',
    data: {
      title: 'Devices'
    }
  });
};

;

},{}],286:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _angularUiRouter = require('angular-ui-router');

var _angularUiRouter2 = _interopRequireDefault(_angularUiRouter);

var _module2 = require('./module.config');

var _module3 = _interopRequireDefault(_module2);

var _devicePage = require('./components/device-page/device-page.component');

var _devicePage2 = _interopRequireDefault(_devicePage);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _module = _angular2.default.module('gnaas.pages.admin.devices', [_angularUiRouter2.default]).config(_module3.default).component('devicePage', _devicePage2.default);

exports.default = _module.name;

},{"./components/device-page/device-page.component":284,"./module.config":285,"angular":"angular","angular-ui-router":"angular-ui-router"}],287:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _batchManagement = require('./batch-management.controller');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var getCellClass = function getCellClass(grid, row) {
  return row.entity.has_expired_batches ? 'ui-grid-cell-bg-red' : '';
};

var DEFAULT_COLUMNS = {
  drug_name: { name: 'Drug Name', type: 'string', width: '*' },
  location: { name: 'Location', type: 'string', width: '*' },
  quantity: { name: 'Quantity', type: 'number', width: '*' }
};
Object.values(DEFAULT_COLUMNS).forEach(function (col) {
  Object.assign(col, { width: '*', cellClass: getCellClass });
});

var AllDrugsController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function AllDrugsController(DrugStockResource, $rootScope, uiGridConstants) {
    _classCallCheck(this, AllDrugsController);

    this._DrugStockResource = DrugStockResource;
    this._rootScope = $rootScope;
    this._uiGridConstants = uiGridConstants;
  }

  _createClass(AllDrugsController, [{
    key: '$onInit',
    value: function $onInit() {
      var _this = this;

      var defaultCols = Object.keys(DEFAULT_COLUMNS).reduce(function (acc, cur) {
        acc[cur] = Object.assign({}, DEFAULT_COLUMNS[cur], { defaultSort: { direction: _this._uiGridConstants.ASC } });
        return acc;
      }, {});
      this.columns = Object.assign({}, defaultCols);
      this._loadData();
      this._rootScope.$on(_batchManagement.BATCH_MANAGEMENT_UPDATE_EVENT, function () {
        _this._loadData();
      });
    }
  }, {
    key: '_loadData',
    value: function _loadData() {
      var _this2 = this;

      this._DrugStockResource.index().then(function (data) {
        _this2.data = data.map(function (item) {
          return {
            drug_name: item.drug.name,
            location: item.location.name,
            quantity: item.quantity,
            has_expired_batches: item.has_expired_batches
          };
        });
      }).catch(console.log.bind(console));
    }
  }]);

  return AllDrugsController;
}();

exports.default = AllDrugsController;

},{"./batch-management.controller":288}],288:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var BATCH_MANAGEMENT_UPDATE_EVENT = exports.BATCH_MANAGEMENT_UPDATE_EVENT = 'BATCH_MANAGEMENT_UPDATE_EVENT';

var BatchManagementController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function BatchManagementController($state, $stateParams, RecordDeliveryDialog, TransferBatchDialog, DrugOrderFormDialog, ManualAdjustmentDialog, DestroyBatchDialog, $rootScope) {
    _classCallCheck(this, BatchManagementController);

    this.$state = $state;
    this.$stateParams = $stateParams;
    this.RecordDeliveryDialog = RecordDeliveryDialog;
    this.TransferBatchDialog = TransferBatchDialog;
    this.DrugOrderFormDialog = DrugOrderFormDialog;
    this.ManualAdjustmentDialog = ManualAdjustmentDialog;
    this.DestroyBatchDialog = DestroyBatchDialog;
    this.$rootScope = $rootScope;
  }

  _createClass(BatchManagementController, [{
    key: '$onInit',
    value: function $onInit() {
      var _this = this;

      this.actions = [{
        name: 'Record Delivery',
        action: function action() {
          return _this.showDialog(_this.RecordDeliveryDialog);
        }
      }, {
        name: 'Manual Adjustment',
        action: function action() {
          return _this.showDialog(_this.ManualAdjustmentDialog);
        }
      }, {
        name: 'Transfer Batch',
        action: function action() {
          return _this.showDialog(_this.TransferBatchDialog);
        }
      }, {
        name: 'Destroy Batch',
        action: function action() {
          return _this.showDialog(_this.DestroyBatchDialog);
        }
      }, {
        name: 'Print Order',
        action: function action() {
          return _this.showDrugOrderFormDialog();
        }
      }];

      this.tabIndex = this.$state.includes('**.batchManagement.drugBreakdown') ? 1 : 0;
    }
  }, {
    key: 'showDialog',
    value: function showDialog(Resource) {
      var _this2 = this;

      Resource.show({}).then(function () {
        _this2.$rootScope.$emit(BATCH_MANAGEMENT_UPDATE_EVENT);
      });
    }
  }, {
    key: 'showDrugOrderFormDialog',
    value: function showDrugOrderFormDialog() {
      this.DrugOrderFormDialog.show({});
    }
  }]);

  return BatchManagementController;
}();

exports.default = BatchManagementController;

},{}],289:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _nodeUuid = require('node-uuid');

var _nodeUuid2 = _interopRequireDefault(_nodeUuid);

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var CreateStockCheckController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function CreateStockCheckController($q, $state, $stateParams, Dialog, TextareaDialog, DrugStockResource, DrugStockCheckResource, Session, TransactionApprovalDialog) {
    _classCallCheck(this, CreateStockCheckController);

    this._q = $q;
    this._state = $state;
    this._stateParams = $stateParams;
    this._Dialog = Dialog;
    this._TextareaDialog = TextareaDialog;
    this._DrugStockResource = DrugStockResource;
    this._DrugStockCheckResource = DrugStockCheckResource;
    this._Session = Session;
    this._TransactionApprovalDialog = TransactionApprovalDialog;
  }

  _createClass(CreateStockCheckController, [{
    key: '$onInit',
    value: function $onInit() {
      var _this = this;

      this.required = this.required || false;

      this.item = {
        id: _nodeUuid2.default.v4(),
        date: (0, _moment2.default)(),
        stock_location_id: this._stateParams.location_id,
        controlled: this._stateParams.drug_type.toLowerCase() === 'controlled'
      };

      this._DrugStockResource.index(this._stateParams).then(function (items) {
        _this.item.drug_stock_check_items = items.map(function (item) {
          return {
            id: _nodeUuid2.default.v4(),
            drug: item.drug,
            expected_amount: item.quantity,
            actual_amount: null,
            resolved: null,
            initial_comments: null
          };
        });
      });
    }
  }, {
    key: 'showCommentsDialog',
    value: function showCommentsDialog($event, item) {
      var required = true;
      this._TextareaDialog.show({ $event: $event, value: item.initial_comments, label: 'Comments', title: 'Add Comments', required: required }).then(function (value) {
        item.initial_comments = value;
      });
    }
  }, {
    key: 'onInputChange',
    value: function onInputChange(item) {
      item.resolved = item.expected_amount === item.actual_amount ? 1 : 0;
    }
  }, {
    key: 'cancel',
    value: function cancel() {
      this._state.go('admin.drugs.stockChecks.list');
    }
  }, {
    key: 'submit',
    value: function submit(data) {
      var _this2 = this;

      if (this.form.$invalid) return;

      var discrepancies = data.drug_stock_check_items.filter(function (item) {
        return item.expected_amount !== item.actual_amount;
      });

      this._validateDiscrepancyComments(discrepancies).then(function () {
        return _this2._submitStockCheck(_this2.item.controlled);
      }).then(function (users) {
        return _this2._DrugStockCheckResource.create(Object.assign({}, data, users));
      }).then(function () {
        return _this2._checkForDiscrepancies(discrepancies);
      }).then(function () {
        return _this2._state.go('admin.drugs.stockChecks.show', { id: _this2.item.id });
      });
    }
  }, {
    key: '_validateDiscrepancyComments',
    value: function _validateDiscrepancyComments(discrepancies) {
      var missingComments = discrepancies.filter(function (item) {
        return !item.initial_comments || !item.initial_comments.length;
      });
      if (missingComments.length) {
        var drugs = this._getDrugNamesString(missingComments);
        this._Dialog.alert('Please add comments to the following drugs: ' + drugs + '.', 'Discrepancies found');
        return this._q.reject();
      }
      return this._q.resolve();
    }
  }, {
    key: '_submitStockCheck',
    value: function _submitStockCheck(controlled) {
      if (controlled) {
        var dialogParams = { dataOnly: true, userEmails: { first: this._Session.user().email } };
        return this._TransactionApprovalDialog.show(dialogParams);
      } else {
        return this._q.resolve({});
      }
    }
  }, {
    key: '_checkForDiscrepancies',
    value: function _checkForDiscrepancies(discrepancies) {
      if (discrepancies.length) {
        var drugs = this._getDrugNamesString(discrepancies);
        return this._Dialog.alert('Please adjust stock for the following drugs: ' + drugs + '.', 'Adjust stock');
      } else {
        return this._q.resolve();
      }
    }
  }, {
    key: '_getDrugNamesString',
    value: function _getDrugNamesString(stockCheckItems) {
      return stockCheckItems.map(function (item) {
        return item.drug.name;
      }).join(', ');
    }
  }]);

  return CreateStockCheckController;
}();

exports.default = CreateStockCheckController;

},{"moment":"moment","node-uuid":"node-uuid"}],290:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = DrugListController;

var _drugDialog = require('../services/drug-dialog/drug-dialog.controller');

var _drugDialog2 = _interopRequireDefault(_drugDialog);

var _module = require('common/resource/module.constants');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var DEFAULT_LIST_FIELDS = {
  name: 'Name',
  concentration: 'Concentration'
};

var DEFAULT_PROFILE_FIELDS = [{
  key: 'concentration',
  label: 'Concentration',
  type: _module.RESOURCE_ITEM_FIELD_TYPES.item
}, {
  key: 'dose_per_unit',
  label: 'Total Dose Per Unit',
  type: _module.RESOURCE_ITEM_FIELD_TYPES.item
}, {
  key: 'measurement_type',
  label: 'Measurement Type',
  type: _module.RESOURCE_ITEM_FIELD_TYPES.item
}, {
  key: 'form',
  label: 'Form',
  type: _module.RESOURCE_ITEM_FIELD_TYPES.item
}, {
  key: 'unit_volume',
  label: 'Unit Volume',
  type: _module.RESOURCE_ITEM_FIELD_TYPES.item
}, {
  key: 'min_rate',
  label: 'Min Rate (ml/hr)',
  type: _module.RESOURCE_ITEM_FIELD_TYPES.item
}, {
  key: 'max_rate',
  label: 'Max Rate (ml/hr)',
  type: _module.RESOURCE_ITEM_FIELD_TYPES.item
}, {
  key: 'routes',
  collectionKey: 'name',
  label: 'Routes',
  type: _module.RESOURCE_ITEM_FIELD_TYPES.collection
}, {
  key: 'is_controlled_drug',
  label: 'Controlled Drug',
  type: _module.RESOURCE_ITEM_FIELD_TYPES.radio
}, {
  key: 'exclude_from_stock',
  label: 'Exclude From Stock',
  type: _module.RESOURCE_ITEM_FIELD_TYPES.radio
}, {
  key: 'comments',
  label: 'Comments',
  type: _module.RESOURCE_ITEM_FIELD_TYPES.item
}];

function DrugListController(DrugResource, SupplierResource, StockLocationResource, MedicationRouteResource) {
  'ngInject';

  var _this = this;

  this.singularName = 'Drug';
  this.pluralName = 'Drugs';

  this.createDialogController = _drugDialog2.default;
  this.createDialogTemplateUrl = 'pages/admin/drugs/services/drug-dialog/drug-dialog.tpl.html';
  DrugResource.index({ include: 'productCodes.supplier,stockLocationProperties' }).then(function (items) {
    _this.items = items;
  });

  this.relationData = { measurementTypes: [], stockLocations: [], suppliers: [], routes: [] };
  DrugResource.getMeasurementTypes().then(function (items) {
    _this.relationData.measurementTypes = items;
  });
  SupplierResource.index().then(function (items) {
    _this.relationData.suppliers = items;
  });
  StockLocationResource.index().then(function (items) {
    _this.relationData.stockLocations = items;
  });
  MedicationRouteResource.index().then(function (items) {
    _this.relationData.routes = items;
  });

  this.hasProfiles = true;
  this.listFields = Object.assign({}, DEFAULT_LIST_FIELDS);
  this.profileFields = [].concat(DEFAULT_PROFILE_FIELDS);
};

},{"../services/drug-dialog/drug-dialog.controller":306,"common/resource/module.constants":57}],291:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _batchManagement = require('./batch-management.controller');

var _module = require('common/resource/module.constants');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var getCellClass = function getCellClass(grid, row) {
  return row.entity.isExpired ? 'ui-grid-cell-bg-red' : '';
};

var DEFAULT_COLUMNS = {
  batch_number: { name: 'Batch Number' },
  expires_on: { name: 'Batch Expiry', type: 'date', cellFilter: 'date:"dd-MM-yyyy"' },
  location: { name: 'Location' },
  available_quantity: { name: 'Quantity' },
  dose_per_unit: { name: 'Total Dose Per Unit' }
};
Object.values(DEFAULT_COLUMNS).forEach(function (col) {
  Object.assign(col, { width: '*', cellClass: getCellClass });
});

var DrugsBreakdownController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function DrugsBreakdownController(DrugResource, DrugBatchesResource, $rootScope) {
    _classCallCheck(this, DrugsBreakdownController);

    this.DrugResource = DrugResource;
    this.DrugBatchesResource = DrugBatchesResource;
    this.$rootScope = $rootScope;
  }

  _createClass(DrugsBreakdownController, [{
    key: '$onInit',
    value: function $onInit() {
      var _this = this;

      this.showEmptyBatches = false;
      this.showExpiredBatches = true;
      this.pagination = { pageNumber: 0, pageSize: 0 };

      this.columns = Object.assign({}, DEFAULT_COLUMNS);
      this.options = {
        enablePagination: true,
        useExternalPagination: true,
        totalItems: 0,
        resolvePage: function resolvePage(pageNumber, pageAmount) {
          _this.updateData(pageNumber, pageAmount);
        }
      };

      this.DrugResource.index().then(function (drugs) {
        _this.drugs = drugs;
      });

      this.$rootScope.$on(_batchManagement.BATCH_MANAGEMENT_UPDATE_EVENT, function () {
        _this.updateData(_this.pagination.pageNumber, _this.pagination.pageSize);
      });
    }
  }, {
    key: 'updateData',
    value: function updateData(pageNumber, pageSize) {
      var _this2 = this;

      if (!this.drug || !this.drug.id) return;

      if (!pageNumber) {
        pageNumber = 1;
      }
      if (!pageSize) {
        pageSize = 25;
      }

      this.pagination.pageNumber = pageNumber;
      this.pagination.pageSize = pageSize;

      if (this.lastDrugId !== this.drug.id) {
        this.batches = undefined;
      }
      this.lastDrugId = this.drug.id;

      var params = {
        drug_id: this.drug.id,
        quantity_mode: this.showEmptyBatches ? 'all' : 'non-empty',
        expiry_mode: this.showExpiredBatches ? 'all' : 'non-expired',
        page: pageNumber,
        include: ['drug', 'location'],
        page_size: pageSize
      };

      this.DrugBatchesResource.index(params).then(function (data) {
        _this2.options.pageNumber = pageNumber;
        _this2.options.totalItems = data.pagination.total;
        _this2.batches = _this2.transformData(data.data);
      }).catch(console.log.bind(console));
    }
  }, {
    key: 'transformData',
    value: function transformData(data) {
      var now = (0, _moment2.default)();
      return data.map(function (item) {
        var expiresOn = (0, _moment2.default)(item.expires_on, _module.DATE_TIME_FORMAT);
        item.isExpired = expiresOn.isSameOrBefore(now);
        item.expires_on = expiresOn.toDate();
        item.location = item.location.name;
        return item;
      });
    }
  }]);

  return DrugsBreakdownController;
}();

exports.default = DrugsBreakdownController;

},{"./batch-management.controller":288,"common/resource/module.constants":57,"moment":"moment"}],292:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = InfusionListController;

var _infusionDialog = require('../services/infusion-dialog/infusion-dialog.controller');

var _infusionDialog2 = _interopRequireDefault(_infusionDialog);

var _module = require('common/resource/module.constants');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var DEFAULT_LIST_FIELDS = {
  name: 'Name',
  doseWithUnit: 'Dose',
  volume: 'Volume (ml)',
  'solution.name': 'Solution'
};

var DEFAULT_PROFILE_FIELDS = [{
  key: 'dose',
  label: 'Dose',
  type: _module.RESOURCE_ITEM_FIELD_TYPES.item
}, {
  key: 'measurement_type',
  label: 'Measurement Type',
  type: _module.RESOURCE_ITEM_FIELD_TYPES.item
}, {
  key: 'volume',
  label: 'Volume (ml)',
  type: _module.RESOURCE_ITEM_FIELD_TYPES.item
}, {
  key: 'min_rate',
  label: 'Min Rate (ml/hr)',
  type: _module.RESOURCE_ITEM_FIELD_TYPES.item
}, {
  key: 'max_rate',
  label: 'Max Rate (ml/hr)',
  type: _module.RESOURCE_ITEM_FIELD_TYPES.item
}, {
  key: 'routes',
  collectionKey: 'name',
  label: 'Routes',
  type: _module.RESOURCE_ITEM_FIELD_TYPES.collection
}, {
  key: 'solution.name',
  label: 'Solution',
  type: _module.RESOURCE_ITEM_FIELD_TYPES.item
}, {
  key: 'comments',
  label: 'Comments',
  type: _module.RESOURCE_ITEM_FIELD_TYPES.item
}];

function InfusionListController(InfusionResource, InfusionSolutionResource, MedicationRouteResource) {
  'ngInject';

  var _this = this;

  this.singularName = 'Infusion';
  this.pluralName = 'Infusions';

  this.createDialogController = _infusionDialog2.default;
  this.createDialogTemplateUrl = 'pages/admin/drugs/services/infusion-dialog/infusion-dialog.tpl.html';
  InfusionResource.index().then(function (items) {
    _this.items = items.map(function (item) {
      return Object.assign({}, item, {
        doseWithUnit: item.dose ? item.dose + item.measurement_type : null
      });
    });
  });

  this.relationData = { measurementTypes: [], solutions: [], routes: [] };
  InfusionResource.getMeasurementTypes().then(function (items) {
    _this.relationData.measurementTypes = items;
  });
  InfusionSolutionResource.index().then(function (items) {
    _this.relationData.solutions = items;
  });
  MedicationRouteResource.index().then(function (items) {
    _this.relationData.routes = items;
  });

  this.hasProfiles = true;
  this.listFields = Object.assign({}, DEFAULT_LIST_FIELDS);
  this.profileFields = [].concat(DEFAULT_PROFILE_FIELDS);
};

},{"../services/infusion-dialog/infusion-dialog.controller":308,"common/resource/module.constants":57}],293:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = NonFormularyDrugListController;

var _angular = require('angular');

var _reviewDialog = require('../services/review-dialog/review-dialog.controller');

var _reviewDialog2 = _interopRequireDefault(_reviewDialog);

var _module = require('common/resource/module.constants');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var DEFAULT_LIST_FIELDS = {
  name: 'Name',
  concentration: 'Concentration'
};

var DEFAULT_PROFILE_FIELDS = [{
  key: 'concentration',
  label: 'Concentration',
  type: _module.RESOURCE_ITEM_FIELD_TYPES.item
}, {
  key: 'dose_per_unit',
  label: 'Total Dose Per Unit',
  type: _module.RESOURCE_ITEM_FIELD_TYPES.item
}, {
  key: 'measurement_type',
  label: 'Measurement Type',
  type: _module.RESOURCE_ITEM_FIELD_TYPES.item
}, {
  key: 'form',
  label: 'Form',
  type: _module.RESOURCE_ITEM_FIELD_TYPES.item
}, {
  key: 'unit_volume',
  label: 'Unit Volume',
  type: _module.RESOURCE_ITEM_FIELD_TYPES.item
}, {
  key: 'min_rate',
  label: 'Min Rate (ml/hr)',
  type: _module.RESOURCE_ITEM_FIELD_TYPES.item
}, {
  key: 'max_rate',
  label: 'Max Rate (ml/hr)',
  type: _module.RESOURCE_ITEM_FIELD_TYPES.item
}, {
  key: 'routes',
  collectionKey: 'name',
  label: 'Routes',
  type: _module.RESOURCE_ITEM_FIELD_TYPES.collection
}, {
  key: 'is_controlled_drug',
  label: 'Controlled Drug',
  type: _module.RESOURCE_ITEM_FIELD_TYPES.radio
}, {
  key: 'exclude_from_stock',
  label: 'Exclude From Stock',
  type: _module.RESOURCE_ITEM_FIELD_TYPES.radio
}, {
  key: 'comments',
  label: 'Comments',
  type: _module.RESOURCE_ITEM_FIELD_TYPES.item
}];

function NonFormularyDrugListController(DrugResource, $mdSidenav, Dialog, Toast, Utils) {
  'ngInject';

  var _this = this;

  this._mdSidenav = $mdSidenav;
  this._Dialog = Dialog;
  this._Toast = Toast;
  this._Utils = Utils;

  this.singularName = 'Non-formulary Drug';
  this.pluralName = 'Non-formulary Drugs';
  this.editButtonLabel = 'Review';

  this.reviewDialogController = _reviewDialog2.default;
  this.reviewDialogTemplateUrl = 'pages/admin/drugs/services/review-dialog/review-dialog.tpl.html';

  DrugResource.index({ include: 'productCodes.supplier,stockLocationProperties', onlyNonFormulary: 1 }).then(function (items) {
    _this.items = items;
  });

  this.relationData = { drugs: [] };
  DrugResource.index().then(function (items) {
    _this.relationData.drugs = items;
  });

  this.hasProfiles = true;
  this.listFields = Object.assign({}, DEFAULT_LIST_FIELDS);
  this.profileFields = [].concat(DEFAULT_PROFILE_FIELDS);

  this.listButtonAction = function (evt, item) {
    _this.showSidenav(item);
  };

  this.showSidenav = function (item) {
    if (!_this.hasProfiles) {
      return;
    }
    _this.selectedItem = item;
    _this._mdSidenav('resource-item').open();
  };

  this.showReviewDialog = function (evt, data) {
    if (_this.hasProfiles) {
      _this._mdSidenav('resource-item').close();
    }

    var editing = !!data;

    var dialogParams = {
      controller: _this.reviewDialogController,
      locals: {
        editing: editing,
        item: (0, _angular.copy)(data),
        items: _this.items,
        relationData: _this.relationData
      },
      targetEvent: evt,
      templateUrl: _this.reviewDialogTemplateUrl
    };

    _this._Dialog.show(dialogParams).then(function (item) {
      _this._Utils.removeFromArrayById(_this.items, item.id);
      _this._Toast.showSimple('Non-formulary Drug approved.');
    }).catch(function (err) {
      if (err.status) {
        _this._Toast.showSimple('Error: could not approve drug.');
      }
    });
  };
};

},{"../services/review-dialog/review-dialog.controller":311,"angular":"angular","common/resource/module.constants":57}],294:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var FIELDS = {
  'location': {
    name: 'Location',
    type: 'string',
    width: '*'
  },
  'date': {
    name: 'Date',
    type: 'string',
    width: '*'
  },
  'drug_type': {
    name: 'Drug Type',
    type: 'string',
    width: '*'
  },
  'user': {
    name: 'User',
    type: 'string',
    width: '*'
  },
  'discrepancy_count': {
    name: 'Discrepancies',
    type: 'string',
    width: '*'
  }
};

var StockCheckListController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function StockCheckListController($state, Session, DrugStockResource, DrugStockCheckResource, CreateDrugStockCheckDialog, StockLocationResource) {
    _classCallCheck(this, StockCheckListController);

    this._state = $state;
    this._Session = Session;
    this._DrugStockResource = DrugStockResource;
    this._DrugStockCheckResource = DrugStockCheckResource;
    this._CreateDrugStockCheckDialog = CreateDrugStockCheckDialog;
    this._StockLocationResource = StockLocationResource;
  }

  _createClass(StockCheckListController, [{
    key: '$onInit',
    value: function $onInit() {
      var _this = this;

      var userGroup = this._Session.user().group.slug;
      this.userCanCreateStockCheck = ~['system-administrators', 'medical-staff'].indexOf(userGroup);

      this.gridColumns = Object.assign({}, FIELDS);
      this.gridActionCol = {
        position: 'end',
        options: { width: '64' },
        actions: [{
          name: 'View',
          callback: this.viewStockCheck.bind(this)
        }]
      };
      this.gridOptions = {
        enablePagination: true,
        useExternalPagination: true,
        totalItems: 0,
        resolvePage: function resolvePage(pageNumber, pageSize) {
          _this.loadData(pageNumber, pageSize);
        }
      };

      this.stockLocations = [];
      this._StockLocationResource.index().then(function (items) {
        var _stockLocations;

        (_stockLocations = _this.stockLocations).push.apply(_stockLocations, _toConsumableArray(items));
      });
      this.loadData();
    }
  }, {
    key: 'showCreateDialog',
    value: function showCreateDialog($event) {
      var _this2 = this;

      this._CreateDrugStockCheckDialog.show({
        $event: $event,
        locals: { stockLocations: this.stockLocations }
      }).then(function (data) {
        _this2._state.go('admin.drugs.stockChecks.create', data);
      });
    }
  }, {
    key: 'viewStockCheck',
    value: function viewStockCheck(stockCheck) {
      this._state.go('admin.drugs.stockChecks.show', { id: stockCheck.id });
    }
  }, {
    key: 'loadData',
    value: function loadData(pageNumber, pageSize) {
      var _this3 = this;

      if (!pageNumber) {
        pageNumber = 1;
      }
      if (!pageSize) {
        pageSize = 25;
      }

      this._DrugStockCheckResource.index({ include: 'stockLocation,user', page: pageNumber, page_size: pageSize }).then(function (data) {
        _this3.gridOptions.pageNumber = pageNumber;
        _this3.gridOptions.totalItems = data.pagination.total;
        _this3.items = data.data.map(_this3._formatItem);
      });
    }
  }, {
    key: '_formatItem',
    value: function _formatItem(item) {
      return Object.assign(item, {
        location: item.stock_location.name,
        date: item.date.format('DD/MM/YYYY'),
        drug_type: item.controlled ? 'Controlled' : 'Non-controlled',
        user: item.user.first_name + ' ' + item.user.last_name
      });
    }
  }]);

  return StockCheckListController;
}();

exports.default = StockCheckListController;

},{}],295:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _module = require('common/resource/module.constants');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var FIELDS = {
  name: 'Name',
  expected_amount: 'Expected',
  actual_amount: 'Actual',
  resolvedYN: 'Resolved'
};

var SIDEBAR_FIELDS = [{
  key: 'name',
  label: 'Name',
  type: _module.RESOURCE_ITEM_FIELD_TYPES.item
}, {
  key: 'expected_amount',
  label: 'Expected',
  type: _module.RESOURCE_ITEM_FIELD_TYPES.item
}, {
  key: 'actual_amount',
  label: 'Actual',
  type: _module.RESOURCE_ITEM_FIELD_TYPES.item
}, {
  key: 'resolvedYN',
  label: 'Resolved',
  type: _module.RESOURCE_ITEM_FIELD_TYPES.item
}, {
  key: 'initial_comments',
  label: 'Initial Comments',
  type: _module.RESOURCE_ITEM_FIELD_TYPES.item
}, {
  key: 'resolve_comments',
  label: 'Resolve Comments',
  type: _module.RESOURCE_ITEM_FIELD_TYPES.item
}];

var StockCheckController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function StockCheckController($scope, $stateParams, $mdSidenav, DrugStockCheckResource, DrugStockCheckDialog) {
    _classCallCheck(this, StockCheckController);

    this.$scope = $scope;
    this._stateParams = $stateParams;
    this._mdSidenav = $mdSidenav;
    this._DrugStockCheckResource = DrugStockCheckResource;
    this._DrugStockCheckDialog = DrugStockCheckDialog;
  }

  _createClass(StockCheckController, [{
    key: '$onInit',
    value: function $onInit() {
      var _this = this;

      this.fields = Object.assign({}, FIELDS);
      this.sidebarFields = [].concat(SIDEBAR_FIELDS);

      this._DrugStockCheckResource.show(this._stateParams.id, { include: 'stockLocation,user,stockCheckItems' }).then(function (item) {
        _this.item = Object.assign(item, {
          drug_stock_check_items: item.drug_stock_check_items.map(function (item) {
            return _this._formatItem(item);
          })
        });
      });
    }
  }, {
    key: 'showSidenav',
    value: function showSidenav(item) {
      this.selectedItem = item;
      this._mdSidenav('resource-item').open();
    }
  }, {
    key: 'showDialog',
    value: function showDialog(event, item) {
      var _this2 = this;

      this._DrugStockCheckDialog.show({ $event: event, item: item }).then(function (data) {
        var formatted = _this2._formatItem(data);
        _this2.selectedItem = formatted;
        _this2.item.drug_stock_check_items = _this2.item.drug_stock_check_items.map(function (item) {
          return item.id === data.id ? formatted : item;
        });
      });
    }
  }, {
    key: 'rowBgWarn',
    value: function rowBgWarn(item) {
      return !item.resolved;
    }
  }, {
    key: '_formatItem',
    value: function _formatItem(item) {
      item.resolvedYN = item.resolved ? 'Yes' : 'No';
      item.name = item.drug.name;
      return item;
    }
  }]);

  return StockCheckController;
}();

exports.default = StockCheckController;

},{"common/resource/module.constants":57}],296:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _module = require('common/resource/module.constants');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var DEFAULT_COLUMNS = {
  transaction_type: { name: 'Transaction Type', width: '180' },
  date: { name: 'Date', width: '120', type: 'date', cellFilter: 'date:"dd-MM-yyyy"' },
  time: { name: 'Time', width: '100', type: 'date', cellFilter: 'date:"HH:mm"' },
  location: { name: 'Batch Location', width: '180' },
  batch_number: { name: 'Batch Number', width: '180' },
  expiry: { name: 'Expiry', width: '120', type: 'date', cellFilter: 'date:"dd-MM-yyyy"' },
  quantity: { name: 'Quantity', width: '120' },
  drug_stock_left_after: { name: 'Total Stock', width: '180' },
  location_stock_left_after: { name: 'Location Stock', width: '180' },
  batch_stock_left_after: { name: 'Left In Batch', width: '180' },

  incident_number: { name: 'Referral Number', width: '210' },
  patient_name: { name: 'Patient Name', width: '210' },
  amount_given: { name: 'Amount Given', width: '180' },
  amount_wasted: { name: 'Amount Wasted', width: '180' },

  user: { name: 'User', width: '210' },
  approver_1: { name: 'Approver 1', width: '210' },
  approver_2: { name: 'Approver 2', width: '210' }
};

var SIDENAV_FIELDS = Object.keys(DEFAULT_COLUMNS).map(function (key) {
  return { key: key, label: DEFAULT_COLUMNS[key].name, type: _module.RESOURCE_ITEM_FIELD_TYPES.item };
});
SIDENAV_FIELDS.splice(1, 0, { key: 'comments', label: 'Comments', type: _module.RESOURCE_ITEM_FIELD_TYPES.item });

var DEFAULT_TRANSACTION_TYPES = [{ id: 'all', value: 'All Transactions' }, { id: 'adjust-up', value: 'Adjust Up' }, { id: 'adjust-down', value: 'Adjust Down' }, { id: 'issued', value: 'Issued' }, { id: 'received', value: 'Received' }, { id: 'destroyed', value: 'Destroyed' }, { id: 'administered', value: 'Administered' }, { id: 'broken', value: 'Broken' }, { id: 'pre-drawn-wasted', value: 'Pre-Drawn Waste' }];

var TransactionHistoryController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function TransactionHistoryController(DrugResource, DrugStockTransactionResource, $mdSidenav) {
    _classCallCheck(this, TransactionHistoryController);

    this.DrugResource = DrugResource;
    this.DrugStockTransactionResource = DrugStockTransactionResource;
    this.$mdSidenav = $mdSidenav;
  }

  _createClass(TransactionHistoryController, [{
    key: '$onInit',
    value: function $onInit() {
      var _this = this;

      this.sidenavFields = [].concat(_toConsumableArray(SIDENAV_FIELDS));
      this.columns = Object.assign({}, DEFAULT_COLUMNS);
      this.transactionTypes = Object.assign({}, DEFAULT_TRANSACTION_TYPES);
      this.transactionType = this.transactionTypes[0].id;
      this.searchGridActionCol = {
        options: {
          width: '64'
        },
        actions: [{
          name: 'View',
          callback: this.viewTransaction.bind(this)
        }]
      };

      this.options = {
        enablePagination: true,
        useExternalPagination: true,
        totalItems: 0,
        resolvePage: function resolvePage(pageNumber, pageAmount) {
          _this.updateData(pageNumber, pageAmount);
        }
      };

      this.DrugResource.index().then(function (drugs) {
        _this.drugs = drugs;
      }).catch(console.log.bind(console));
    }
  }, {
    key: 'viewTransaction',
    value: function viewTransaction(transaction) {
      console.log({ transaction: transaction });
      this.selectedTransaction = Object.assign({}, transaction, {
        date: (0, _moment2.default)(transaction.date).format('DD-MM-YYYY'),
        time: (0, _moment2.default)(transaction.time).format('HH:mm'),
        expiry: (0, _moment2.default)(transaction.expiry).format('DD-MM-YYYY')
      });
      this.$mdSidenav('resource-item').open();
    }
  }, {
    key: 'updateData',
    value: function updateData(pageNumber, pageSize) {
      var _this2 = this;

      if (!this.transactionType || !this.drugId) {
        return;
      }
      if (!pageNumber) {
        pageNumber = 1;
      }

      if (this.lastDrugId !== this.drugId) {
        this.data = undefined;
      }
      this.lastDrugId = this.drugId;

      var query = {
        type: this.transactionType,
        drug: this.drugId,
        page: pageNumber
      };

      if (pageSize) {
        query['page_size'] = pageSize;
      }

      this.DrugStockTransactionResource.index(query).then(function (data) {
        _this2.options.pageNumber = pageNumber;
        _this2.options.totalItems = data.pagination.total;
        _this2.data = _this2.transformData(data.data);
      }).catch(console.log.bind(console));
    }
  }, {
    key: 'transformData',
    value: function transformData(data) {
      return data.map(function (item) {
        var timestamp = (0, _moment2.default)(item.timestamp, _module.DATE_TIME_FORMAT).toDate();

        var transformed = {
          comments: item.comments,
          transaction_type: item.type.name,
          date: timestamp,
          time: timestamp,
          incident_number: item.prf && item.prf.incident ? item.prf.incident.incident_number : '-',
          patient_name: item.prf ? item.prf.first_name + ' ' + item.prf.last_name : '-',
          batch_number: item.batch.batch_number,
          expiry: (0, _moment2.default)(item.batch.expires_on, _module.DATE_TIME_FORMAT).toDate(),
          quantity: item.quantity,
          amount_given: item.amount_given != null ? item.amount_given : '-',
          amount_wasted: item.amount_wasted != null ? item.amount_wasted : '-',
          drug_stock_left_after: item.drug_stock_left_after != null ? item.drug_stock_left_after : '-',
          location_stock_left_after: item.location_stock_left_after != null ? item.location_stock_left_after : '-',
          batch_stock_left_after: item.batch_stock_left_after != null ? item.batch_stock_left_after : '-',
          location: item.location.name,
          user: item.user ? item.user.first_name + ' ' + item.user.last_name : '-',
          approver_1: '-',
          approver_2: '-'
        };

        if (item.approval && item.approval.first_user) {
          transformed.approver_1 = item.approval.first_user.first_name + ' ' + item.approval.first_user.last_name;
        }

        if (item.approval && item.approval.second_user) {
          transformed.approver_2 = item.approval.second_user.first_name + ' ' + item.approval.second_user.last_name;
        }

        return transformed;
      });
    }
  }, {
    key: 'clearSearchTerm',
    value: function clearSearchTerm() {
      this.searchTerm = '';
    }
  }]);

  return TransactionHistoryController;
}();

exports.default = TransactionHistoryController;
;

},{"common/resource/module.constants":57,"moment":"moment"}],297:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function ($stateProvider, $urlRouterProvider) {
  'ngInject';

  // $urlRouterProvider.when('/admin/drugs/stock-checks', );

  $stateProvider.state('admin.drugs', {
    abstract: true,
    url: '/drugs',
    template: '<div ui-view layout="column" flex></div>'
  }).state('admin.drugs.drugList', {
    url: '/drug-list',
    controllerAs: '$ctrl',
    controller: 'DrugListController',
    templateUrl: 'common/resource/templates/resource.tpl.html',
    data: {
      title: 'Drug List'
    }
  }).state('admin.drugs.nonFormularyDrugList', {
    url: '/non-formulary-drug-list',
    controllerAs: '$ctrl',
    controller: 'NonFormularyDrugListController',
    templateUrl: 'pages/admin/drugs/templates/non-formulary.tpl.html',
    data: {
      title: 'Non-formulary Drug List'
    }
  }).state('admin.drugs.infusionList', {
    url: '/infusion-list',
    controllerAs: '$ctrl',
    controller: 'InfusionListController',
    templateUrl: 'common/resource/templates/resource.tpl.html',
    data: {
      title: 'Infusion List'
    }
  }).state('admin.drugs.transactionHistory', {
    url: '/transaction-history',
    controllerAs: '$ctrl',
    controller: 'TransactionHistoryController',
    templateUrl: 'pages/admin/drugs/templates/transaction-history.tpl.html',
    data: {
      title: 'Transaction History'
    }
  }).state('admin.drugs.stockChecks', {
    abstract: true,
    url: '/stock-checks',
    template: '<div ui-view layout="column" flex></div>'
  }).state('admin.drugs.stockChecks.list', {
    url: '/',
    controllerAs: '$ctrl',
    controller: 'StockCheckListController',
    templateUrl: 'pages/admin/drugs/templates/stock-check-list.tpl.html',
    data: {
      title: 'Stock Checks'
    }
  }).state('admin.drugs.stockChecks.create', {
    url: '/create',
    controllerAs: '$ctrl',
    controller: 'CreateStockCheckController',
    templateUrl: 'pages/admin/drugs/templates/create-stock-check.tpl.html',
    params: {
      location_id: null,
      drug_type: null
    },
    data: {
      title: 'Create Stock Check'
    }
  }).state('admin.drugs.stockChecks.show', {
    url: '/{id}',
    controllerAs: '$ctrl',
    controller: 'StockCheckController',
    templateUrl: 'pages/admin/drugs/templates/stock-check.tpl.html',
    data: {
      title: 'Stock Check'
    }
  }).state('admin.drugs.batchManagement', {
    url: '/batch-management',
    abstract: true,
    controllerAs: '$ctrl',
    controller: 'BatchManagementController',
    templateUrl: 'pages/admin/drugs/templates/batch-management.tpl.html'
  }).state('admin.drugs.batchManagement.allDrugs', {
    url: '/all-drugs',
    controllerAs: '$ctrl',
    controller: 'AllDrugsController',
    templateUrl: 'pages/admin/drugs/templates/all-drugs.tpl.html',
    data: {
      title: 'All Drug Stock'
    }
  }).state('admin.drugs.batchManagement.drugBreakdown', {
    url: '/drug-breakdown',
    controllerAs: '$ctrl',
    controller: 'DrugBreakdownController',
    templateUrl: 'pages/admin/drugs/templates/drugs-breakdown.tpl.html',
    data: {
      title: 'Drug Stock Breakdown'
    }
  });
};

;

},{}],298:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _angularUiRouter = require('angular-ui-router');

var _angularUiRouter2 = _interopRequireDefault(_angularUiRouter);

var _module2 = require('./module.config');

var _module3 = _interopRequireDefault(_module2);

var _allDrugs = require('./controllers/all-drugs.controller');

var _allDrugs2 = _interopRequireDefault(_allDrugs);

var _batchManagement = require('./controllers/batch-management.controller');

var _batchManagement2 = _interopRequireDefault(_batchManagement);

var _createStockCheck = require('./controllers/create-stock-check.controller');

var _createStockCheck2 = _interopRequireDefault(_createStockCheck);

var _drugBatches = require('./resources/drug-batches.resource');

var _drugBatches2 = _interopRequireDefault(_drugBatches);

var _drugsBreakdown = require('./controllers/drugs-breakdown.controller');

var _drugsBreakdown2 = _interopRequireDefault(_drugsBreakdown);

var _drugList = require('./controllers/drug-list.controller');

var _drugList2 = _interopRequireDefault(_drugList);

var _nonFormularyDrugList = require('./controllers/non-formulary-drug-list.controller');

var _nonFormularyDrugList2 = _interopRequireDefault(_nonFormularyDrugList);

var _drug = require('./resources/drug.resource');

var _drug2 = _interopRequireDefault(_drug);

var _drugStock = require('./resources/drug-stock.resource');

var _drugStock2 = _interopRequireDefault(_drugStock);

var _drugStockCheckResource = require('./resources/drug-stock-check.resource.js');

var _drugStockCheckResource2 = _interopRequireDefault(_drugStockCheckResource);

var _drugStockCheckItemResource = require('./resources/drug-stock-check-item.resource.js');

var _drugStockCheckItemResource2 = _interopRequireDefault(_drugStockCheckItemResource);

var _drugStockTransaction = require('./resources/drug-stock-transaction.resource');

var _drugStockTransaction2 = _interopRequireDefault(_drugStockTransaction);

var _stockCheck = require('./controllers/stock-check.controller');

var _stockCheck2 = _interopRequireDefault(_stockCheck);

var _stockCheckList = require('./controllers/stock-check-list.controller');

var _stockCheckList2 = _interopRequireDefault(_stockCheckList);

var _transactionHistory = require('./controllers/transaction-history.controller');

var _transactionHistory2 = _interopRequireDefault(_transactionHistory);

var _infusion = require('./resources/infusion.resource');

var _infusion2 = _interopRequireDefault(_infusion);

var _infusionList = require('./controllers/infusion-list.controller');

var _infusionList2 = _interopRequireDefault(_infusionList);

var _drugDialog = require('./services/drug-dialog/drug-dialog.service');

var _drugDialog2 = _interopRequireDefault(_drugDialog);

var _infusionDialog = require('./services/infusion-dialog/infusion-dialog.service');

var _infusionDialog2 = _interopRequireDefault(_infusionDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _module = _angular2.default.module('gnaas.pages.admin.drugs', [_angularUiRouter2.default, 'ui.grid', 'ui.grid.pagination', 'ui.grid.autoResize', 'ui.grid.resizeColumns']).config(_module3.default).service('DrugResource', _drug2.default).service('DrugBatchesResource', _drugBatches2.default).service('DrugStockResource', _drugStock2.default).service('DrugStockCheckResource', _drugStockCheckResource2.default).service('DrugStockCheckItemResource', _drugStockCheckItemResource2.default).service('DrugStockTransactionResource', _drugStockTransaction2.default).service('InfusionResource', _infusion2.default).service('DrugDialog', _drugDialog2.default).service('InfusionDialog', _infusionDialog2.default).controller('AllDrugsController', _allDrugs2.default).controller('BatchManagementController', _batchManagement2.default).controller('CreateStockCheckController', _createStockCheck2.default).controller('DrugBreakdownController', _drugsBreakdown2.default).controller('DrugListController', _drugList2.default).controller('NonFormularyDrugListController', _nonFormularyDrugList2.default).controller('StockCheckController', _stockCheck2.default).controller('StockCheckListController', _stockCheckList2.default).controller('TransactionHistoryController', _transactionHistory2.default).controller('InfusionListController', _infusionList2.default);

exports.default = _module.name;

},{"./controllers/all-drugs.controller":287,"./controllers/batch-management.controller":288,"./controllers/create-stock-check.controller":289,"./controllers/drug-list.controller":290,"./controllers/drugs-breakdown.controller":291,"./controllers/infusion-list.controller":292,"./controllers/non-formulary-drug-list.controller":293,"./controllers/stock-check-list.controller":294,"./controllers/stock-check.controller":295,"./controllers/transaction-history.controller":296,"./module.config":297,"./resources/drug-batches.resource":299,"./resources/drug-stock-check-item.resource.js":300,"./resources/drug-stock-check.resource.js":301,"./resources/drug-stock-transaction.resource":302,"./resources/drug-stock.resource":303,"./resources/drug.resource":304,"./resources/infusion.resource":305,"./services/drug-dialog/drug-dialog.service":307,"./services/infusion-dialog/infusion-dialog.service":309,"angular":"angular","angular-ui-router":"angular-ui-router"}],299:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.format = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

var _drug = require('pages/admin/drugs/resources/drug.resource');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _format(item) {
  item.dose_per_unit = parseFloat(item.dose_per_unit);
  if (item.drug) {
    item.drug = (0, _drug.format)(item.drug.data);
  }
  if (item.location) {
    item.location = item.location.data;
  }
  return item;
}

exports.format = _format;

var DrugBatchesResource = function (_RestResource) {
  _inherits(DrugBatchesResource, _RestResource);

  function DrugBatchesResource() {
    _classCallCheck(this, DrugBatchesResource);

    return _possibleConstructorReturn(this, (DrugBatchesResource.__proto__ || Object.getPrototypeOf(DrugBatchesResource)).apply(this, arguments));
  }

  _createClass(DrugBatchesResource, [{
    key: 'init',
    value: function init() {
      _get(DrugBatchesResource.prototype.__proto__ || Object.getPrototypeOf(DrugBatchesResource.prototype), 'init', this).call(this);
      this.resourcePath = 'drug-batches';
    }
  }, {
    key: 'format',
    value: function format(item) {
      return _format(item);
    }
  }]);

  return DrugBatchesResource;
}(_restResource2.default);

exports.default = DrugBatchesResource;

},{"common/resource/services/restResource":60,"pages/admin/drugs/resources/drug.resource":304}],300:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parse = exports.format = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

var _angular = require('angular');

var _drug = require('pages/admin/drugs/resources/drug.resource');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _format(item) {
  item.resolved = !!item.resolved;
  item.drug = (0, _drug.format)(item.drug.data);
  return item;
}

exports.format = _format;
function _parse(item) {
  var i = (0, _angular.copy)(item);
  i.drug_id = i.drug.id;
  delete i.drug;
  return i;
}

exports.parse = _parse;

var DrugStockCheckResource = function (_RestResource) {
  _inherits(DrugStockCheckResource, _RestResource);

  function DrugStockCheckResource() {
    _classCallCheck(this, DrugStockCheckResource);

    return _possibleConstructorReturn(this, (DrugStockCheckResource.__proto__ || Object.getPrototypeOf(DrugStockCheckResource)).apply(this, arguments));
  }

  _createClass(DrugStockCheckResource, [{
    key: 'init',
    value: function init() {
      _get(DrugStockCheckResource.prototype.__proto__ || Object.getPrototypeOf(DrugStockCheckResource.prototype), 'init', this).call(this);
      this.resourcePath = 'drug-stock-check-items';
    }
  }, {
    key: 'parse',
    value: function parse(item) {
      return _parse(item);
    }
  }, {
    key: 'format',
    value: function format(item) {
      return _format(item);
    }
  }]);

  return DrugStockCheckResource;
}(_restResource2.default);

exports.default = DrugStockCheckResource;

},{"angular":"angular","common/resource/services/restResource":60,"pages/admin/drugs/resources/drug.resource":304}],301:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parse = exports.format = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

var _angular = require('angular');

var _module = require('common/resource/module.constants');

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _crewMemberResource = require('pages/crewMembers/services/crewMemberResource');

var _drug = require('pages/admin/drugs/resources/drug.resource');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _format(item) {
  var formatted = {
    id: item.id,
    date: (0, _moment2.default)(item.date),
    controlled: item.controlled,
    discrepancy_count: item.discrepancy_count
  };

  if (item.stockLocation) {
    formatted.stock_location = item.stockLocation.data;
  }

  if (item.user) {
    formatted.user = (0, _crewMemberResource.format)(item.user.data);
  }

  if (item.stockCheckItems) {
    formatted.drug_stock_check_items = item.stockCheckItems.data.map(function (item) {
      item.resolved = !!item.resolved;
      item.drug = (0, _drug.format)(item.drug.data);
      return item;
    });
  }

  return formatted;
}

exports.format = _format;
function _parse(item) {
  var parsed = {
    id: item.id,
    stock_location_id: item.stock_location_id,
    controlled: item.controlled,
    date: (0, _moment2.default)(item.date).format(_module.DATE_FORMAT),
    drug_stock_check_items: item.drug_stock_check_items.map(function (item) {
      var i = (0, _angular.copy)(item);
      i.drug_id = i.drug.id;
      delete i.drug;
      return i;
    })
  };

  if (item.first_user) {
    parsed.first_user = item.first_user;
  }

  if (item.second_user) {
    parsed.second_user = item.second_user;
  }

  return parsed;
}

exports.parse = _parse;

var DrugStockCheckResource = function (_RestResource) {
  _inherits(DrugStockCheckResource, _RestResource);

  function DrugStockCheckResource() {
    _classCallCheck(this, DrugStockCheckResource);

    return _possibleConstructorReturn(this, (DrugStockCheckResource.__proto__ || Object.getPrototypeOf(DrugStockCheckResource)).apply(this, arguments));
  }

  _createClass(DrugStockCheckResource, [{
    key: 'init',
    value: function init() {
      _get(DrugStockCheckResource.prototype.__proto__ || Object.getPrototypeOf(DrugStockCheckResource.prototype), 'init', this).call(this);
      this.resourcePath = 'drug-stock-checks';
    }
  }, {
    key: 'parse',
    value: function parse(item) {
      return _parse(item);
    }
  }, {
    key: 'format',
    value: function format(item) {
      return _format(item);
    }
  }]);

  return DrugStockCheckResource;
}(_restResource2.default);

exports.default = DrugStockCheckResource;

},{"angular":"angular","common/resource/module.constants":57,"common/resource/services/restResource":60,"moment":"moment","pages/admin/drugs/resources/drug.resource":304,"pages/crewMembers/services/crewMemberResource":362}],302:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.format = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

var _drugBatches = require('./drug-batches.resource');

var _crewMemberResource = require('pages/crewMembers/services/crewMemberResource');

var _drug = require('pages/admin/drugs/resources/drug.resource');

var _prfFormatter = require('core/prf/services/prf-formatter.service');

var _prfFormatter2 = _interopRequireDefault(_prfFormatter);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _format(item) {
  var formatted = Object.assign({}, item);

  formatted.amount_given = item.amount_given !== null ? parseFloat(item.amount_given) : null;
  formatted.amount_wasted = item.amount_wasted !== null ? parseFloat(item.amount_wasted) : null;

  if (item.type) {
    formatted.type = item.type.data;
  }
  if (item.batch) {
    formatted.batch = (0, _drugBatches.format)(item.batch.data);
  }
  if (item.drug) {
    formatted.drug = (0, _drug.format)(item.drug.data);
  }
  if (item.location) {
    formatted.location = item.location.data;
  }
  if (item.prf) {
    formatted.prf = (0, _prfFormatter2.default)(item.prf.data);
  }
  if (item.user) {
    formatted.user = (0, _crewMemberResource.format)(item.user.data);
  };

  if (item.approval) {
    formatted.approval = { id: item.approval.data.id };

    if (item.approval.data.firstUser) {
      formatted.approval.first_user = (0, _crewMemberResource.format)(item.approval.data.firstUser.data);
    }

    if (item.approval.data.secondUser) {
      formatted.approval.second_user = (0, _crewMemberResource.format)(item.approval.data.secondUser.data);
    }
  }

  return formatted;
}

exports.format = _format;

var DrugStockTransactionResource = function (_RestResource) {
  _inherits(DrugStockTransactionResource, _RestResource);

  function DrugStockTransactionResource() {
    _classCallCheck(this, DrugStockTransactionResource);

    return _possibleConstructorReturn(this, (DrugStockTransactionResource.__proto__ || Object.getPrototypeOf(DrugStockTransactionResource)).apply(this, arguments));
  }

  _createClass(DrugStockTransactionResource, [{
    key: 'init',
    value: function init() {
      _get(DrugStockTransactionResource.prototype.__proto__ || Object.getPrototypeOf(DrugStockTransactionResource.prototype), 'init', this).call(this);
      this.resourcePath = 'drug-stock-transactions';
    }
  }, {
    key: 'format',
    value: function format(data) {
      return _format(data);
    }
  }, {
    key: 'approve',
    value: function approve(id, data) {
      return this._http.put(this.apiUrl + 'drug-stock-transaction-approvals/' + id, data);
    }
  }]);

  return DrugStockTransactionResource;
}(_restResource2.default);

exports.default = DrugStockTransactionResource;

},{"./drug-batches.resource":299,"common/resource/services/restResource":60,"core/prf/services/prf-formatter.service":228,"pages/admin/drugs/resources/drug.resource":304,"pages/crewMembers/services/crewMemberResource":362}],303:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

var _drug = require('pages/admin/drugs/resources/drug.resource');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var DrugStockResource = function (_RestResource) {
  _inherits(DrugStockResource, _RestResource);

  function DrugStockResource() {
    _classCallCheck(this, DrugStockResource);

    return _possibleConstructorReturn(this, (DrugStockResource.__proto__ || Object.getPrototypeOf(DrugStockResource)).apply(this, arguments));
  }

  _createClass(DrugStockResource, [{
    key: 'init',
    value: function init() {
      _get(DrugStockResource.prototype.__proto__ || Object.getPrototypeOf(DrugStockResource.prototype), 'init', this).call(this);
      this.resourcePath = 'drug-stock';
    }
  }, {
    key: 'format',
    value: function format(item) {
      if (item.drug) {
        item.drug = (0, _drug.format)(item.drug.data);
      }
      if (item.location) {
        item.location = item.location.data;
      }
      return item;
    }
  }, {
    key: 'generateDrugOrderForms',
    value: function generateDrugOrderForms(data) {
      var url = this.apiUrl + 'drug-order-forms';

      return this._http({
        url: url,
        method: 'POST',
        data: data,
        responseType: 'arraybuffer'
      }).then(function (response) {
        return response.data;
      });
    }
  }]);

  return DrugStockResource;
}(_restResource2.default);

exports.default = DrugStockResource;

},{"common/resource/services/restResource":60,"pages/admin/drugs/resources/drug.resource":304}],304:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parse = exports.format = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

var _equipmentLocation = require('core/equipment-locations/resources/equipment-location.resource');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _format(item) {
  var formatted = {
    id: item.id,
    name: item.name,
    measurement_type: item.measurement_type,
    concentration: item.concentration,
    dose_per_unit: parseFloat(item.dose_per_unit),
    is_controlled_drug: !!item.is_controlled_drug,
    exclude_from_stock: !!item.exclude_from_stock,
    form: item.form,
    unit_volume: item.unit_volume,
    comments: item.comments,
    is_non_formulary: !!item.is_non_formulary,
    min_rate: item.min_rate != null ? parseFloat(item.min_rate) : null,
    max_rate: item.max_rate != null ? parseFloat(item.max_rate) : null
  };

  if (item.routes) formatted.routes = item.routes.data;

  if (item.prf) formatted.prf = item.prf.data;

  if (item.stockLocationProperties) {
    formatted.stock_location_properties = item.stockLocationProperties.data.map(function (properties) {
      properties.supplier = properties.supplier.data;
      properties.stock_location = properties.stockLocation.data;
      delete properties.stockLocation;
      return properties;
    });
  }

  if (item.equipmentLocationCompartmentItems) {
    formatted.equipment_location_compartment_items = item.equipmentLocationCompartmentItems.data.map(_equipmentLocation.formatCompartmentItem);
  }

  if (item.productCodes) {
    formatted.product_codes = item.productCodes.data.map(function (pc) {
      if (pc.supplier) pc.supplier = pc.supplier.data;
      return pc;
    });
  }

  return formatted;
}

exports.format = _format;
function _parse(item) {
  var parsed = {
    id: item.id,
    name: item.name,
    measurement_type: item.measurement_type,
    concentration: item.concentration || null,
    dose_per_unit: item.dose_per_unit,
    is_controlled_drug: item.is_controlled_drug ? 1 : 0,
    exclude_from_stock: item.exclude_from_stock ? 1 : 0,
    form: item.form || null,
    unit_volume: item.unit_volume || null,
    comments: item.comments || null,
    routes: item.routes ? item.routes.map(function (i) {
      return i.id;
    }) : [],
    is_non_formulary: item.is_non_formulary ? 1 : 0,
    min_rate: item.min_rate != null ? item.min_rate : null,
    max_rate: item.max_rate
  };

  if (item.is_non_formulary) {
    parsed.patient_report_form_id = item.patient_report_form.id;
  }

  if (item.stock_location_properties) {
    parsed.stock_location_properties = item.stock_location_properties.map(function (prop) {
      var copy = Object.assign({}, prop);
      copy.stock_location_id = copy.stock_location.id;
      copy.supplier_id = copy.supplier.id;
      delete copy.stock_location;
      delete copy.supplier;
      return copy;
    });
  }

  if (item.product_codes) {
    parsed.product_codes = item.product_codes.map(function (pc) {
      return {
        id: pc.id,
        product_code: pc.product_code,
        supplier_id: pc.supplier.id
      };
    });
  }

  return parsed;
}

exports.parse = _parse;

var DrugResource = function (_RestResource) {
  _inherits(DrugResource, _RestResource);

  function DrugResource() {
    _classCallCheck(this, DrugResource);

    return _possibleConstructorReturn(this, (DrugResource.__proto__ || Object.getPrototypeOf(DrugResource)).apply(this, arguments));
  }

  _createClass(DrugResource, [{
    key: 'init',
    value: function init() {
      _get(DrugResource.prototype.__proto__ || Object.getPrototypeOf(DrugResource.prototype), 'init', this).call(this);
      this.resourcePath = 'drugs';
    }
  }, {
    key: 'getMeasurementTypes',
    value: function getMeasurementTypes() {
      var url = this.apiUrl + '/categories/drug-measurement-types';
      return this._http.get(url).then(function (response) {
        return response.data.data;
      });
    }
  }, {
    key: 'format',
    value: function format(item) {
      return _format(item);
    }
  }, {
    key: 'parse',
    value: function parse(item) {
      return _parse(item);
    }
  }, {
    key: 'approve',
    value: function approve(id) {
      var _this2 = this;

      var url = '' + this.apiUrl + this.resourcePath + '/' + id + '/approve';
      return this._http.post(url).then(function (response) {
        return response.data.data ? _this2._formatData(response.data.data) : null;
      });
    }
  }, {
    key: 'reject',
    value: function reject(id, data) {
      var _this3 = this;

      var url = '' + this.apiUrl + this.resourcePath + '/' + id + '/reject';
      return this._http.post(url, { drug_id: data.id }).then(function (response) {
        return response.data.data ? _this3._formatData(response.data.data) : null;
      });
    }
  }]);

  return DrugResource;
}(_restResource2.default);

exports.default = DrugResource;

},{"common/resource/services/restResource":60,"core/equipment-locations/resources/equipment-location.resource":156}],305:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parse = exports.format = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _format(item) {
  var i = {
    id: item.id,
    name: item.name,
    measurement_type: item.measurement_type,
    dose: item.dose != null ? parseFloat(item.dose) : null,
    volume: item.volume,
    min_rate: item.min_rate != null ? parseFloat(item.min_rate) : null,
    max_rate: item.max_rate != null ? parseFloat(item.max_rate) : null,
    comments: item.comments
  };

  if (item.solution) i.solution = item.solution.data;
  if (item.routes) i.routes = item.routes.data;

  return i;
}

exports.format = _format;
function _parse(item) {
  return {
    id: item.id,
    name: item.name,
    measurement_type: item.measurement_type ? item.measurement_type : null,
    dose: item.dose ? item.dose : null,
    volume: item.volume,
    solution_id: item.solution ? item.solution.id : null,
    min_rate: item.min_rate != null ? item.min_rate : null,
    max_rate: item.max_rate,
    routes: item.routes ? item.routes.map(function (i) {
      return i.id;
    }) : [],
    comments: item.comments || null
  };
}

exports.parse = _parse;

var InfusionResource = function (_RestResource) {
  _inherits(InfusionResource, _RestResource);

  function InfusionResource() {
    _classCallCheck(this, InfusionResource);

    return _possibleConstructorReturn(this, (InfusionResource.__proto__ || Object.getPrototypeOf(InfusionResource)).apply(this, arguments));
  }

  _createClass(InfusionResource, [{
    key: 'init',
    value: function init() {
      _get(InfusionResource.prototype.__proto__ || Object.getPrototypeOf(InfusionResource.prototype), 'init', this).call(this);
      this.resourcePath = 'infusions';
    }
  }, {
    key: 'getMeasurementTypes',
    value: function getMeasurementTypes() {
      var url = this.apiUrl + '/categories/drug-measurement-types';
      return this._http.get(url).then(function (response) {
        return response.data.data;
      });
    }
  }, {
    key: 'format',
    value: function format(item) {
      return _format(item);
    }
  }, {
    key: 'parse',
    value: function parse(item) {
      return _parse(item);
    }
  }]);

  return InfusionResource;
}(_restResource2.default);

exports.default = InfusionResource;

},{"common/resource/services/restResource":60}],306:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _nodeUuid = require('node-uuid');

var _nodeUuid2 = _interopRequireDefault(_nodeUuid);

var _resourceDialogController = require('common/resource/controllers/resourceDialogController');

var _resourceDialogController2 = _interopRequireDefault(_resourceDialogController);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function generateStockLocationProperties(stockLocations) {
  return stockLocations.map(function (location) {
    return { id: _nodeUuid2.default.v4(), stock_location: location };
  });
}

function generateMissingStockLocationProperties(stockLocationProps, stockLocations) {
  var existingStockLocationPropIds = stockLocationProps.map(function (item) {
    return item.stock_location.id;
  });
  var pendingStockLocations = stockLocations.filter(function (i) {
    return existingStockLocationPropIds.indexOf(i.id) === -1;
  });
  var generatedStockLocationProps = generateStockLocationProperties(pendingStockLocations);
  return [].concat(_toConsumableArray(stockLocationProps), _toConsumableArray(generatedStockLocationProps));
}

function generateProductCodes(suppliers) {
  return suppliers.map(function (supplier) {
    return { id: _nodeUuid2.default.v4(), supplier: supplier, product_code: null };
  });
}

function generateMissingProductCodes(productCodes, suppliers) {
  var existingProductCodeIds = productCodes.map(function (i) {
    return i.supplier.id;
  });
  var pendingProductCodes = suppliers.filter(function (i) {
    return existingProductCodeIds.indexOf(i.id) === -1;
  });
  var generatedProductCodes = generateProductCodes(pendingProductCodes);
  return [].concat(_toConsumableArray(productCodes), _toConsumableArray(generatedProductCodes));
}

var CreateDrugController = function (_ResourceDialogContro) {
  _inherits(CreateDrugController, _ResourceDialogContro);

  function CreateDrugController() {
    _classCallCheck(this, CreateDrugController);

    return _possibleConstructorReturn(this, (CreateDrugController.__proto__ || Object.getPrototypeOf(CreateDrugController)).apply(this, arguments));
  }

  _createClass(CreateDrugController, [{
    key: 'init',
    value: function init() {
      _get(CreateDrugController.prototype.__proto__ || Object.getPrototypeOf(CreateDrugController.prototype), 'init', this).call(this);
      this.DrugResource = this.$injector.get('DrugResource');
      this.ID = _nodeUuid2.default.v4();

      if (!this.item) {
        this.item = { exclude_from_stock: true };
      }

      if (!this.excludeFromStock) {
        if (!this.item.stock_location_properties || !this.item.stock_location_properties.length) {
          this.item.stock_location_properties = generateStockLocationProperties(this.relationData.stockLocations);
        } else {
          this.item.stock_location_properties = generateMissingStockLocationProperties(this.item.stock_location_properties, this.relationData.stockLocations);
        }

        if (!this.item.product_codes || !this.item.product_codes.length) {
          this.item.product_codes = generateProductCodes(this.relationData.suppliers);
        } else {
          this.item.product_codes = generateMissingProductCodes(this.item.product_codes, this.relationData.suppliers);
        }
      }

      if (this.isNonFormulary) {
        this.item.is_non_formulary = true;
        this.item.patient_report_form = this.prf;
        this.item.min_rate = 0;
        this.item.max_rate = 0;
      }
    }
  }, {
    key: 'delete',
    value: function _delete(id) {
      var _this2 = this;

      this.Dialog.confirm().then(function () {
        _this2.DrugResource.destroy(id).then(function () {
          _this2.Dialog.cancel(id);
        });
      });
    }
  }, {
    key: 'create',
    value: function create(data) {
      var _this3 = this;

      data.id = this.ID;
      if (data.exclude_from_stock) delete data.stock_location_properties;

      this.DrugResource.create(data).then(function () {
        _this3.Dialog.hide(data);
      }).catch(function (err) {
        if (err.status) {
          _this3.Toast.showSimple('Error: could not create drug.');
        }
      });
    }
  }, {
    key: 'update',
    value: function update(data) {
      var _this4 = this;

      if (data.exclude_from_stock) delete data.stock_location_properties;

      this.DrugResource.update(data.id, data, { include: 'productCodes.supplier,stockLocationProperties' }).then(function (drug) {
        _this4.Dialog.hide(drug);
      }).catch(function (err) {
        if (err.status) {
          _this4.Toast.showSimple('Error: could not update drug.');
        }
      });
    }
  }]);

  return CreateDrugController;
}(_resourceDialogController2.default);

exports.default = CreateDrugController;

},{"common/resource/controllers/resourceDialogController":56,"node-uuid":"node-uuid"}],307:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _resourceDialog = require('common/resource/services/resourceDialog');

var _resourceDialog2 = _interopRequireDefault(_resourceDialog);

var _drugDialog = require('./drug-dialog.controller');

var _drugDialog2 = _interopRequireDefault(_drugDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var DrugDialog = function (_ResourceDialog) {
  _inherits(DrugDialog, _ResourceDialog);

  function DrugDialog() {
    _classCallCheck(this, DrugDialog);

    return _possibleConstructorReturn(this, (DrugDialog.__proto__ || Object.getPrototypeOf(DrugDialog)).apply(this, arguments));
  }

  _createClass(DrugDialog, [{
    key: 'init',

    /**
     * @ngInject
     */
    value: function init() {
      _get(DrugDialog.prototype.__proto__ || Object.getPrototypeOf(DrugDialog.prototype), 'init', this).call(this);
      this.setController(_drugDialog2.default);
      this.setTemplateUrl('pages/admin/drugs/services/drug-dialog/drug-dialog.tpl.html');
    }
  }]);

  return DrugDialog;
}(_resourceDialog2.default);

exports.default = DrugDialog;

},{"./drug-dialog.controller":306,"common/resource/services/resourceDialog":59}],308:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _nodeUuid = require('node-uuid');

var _nodeUuid2 = _interopRequireDefault(_nodeUuid);

var _resourceDialogController = require('common/resource/controllers/resourceDialogController');

var _resourceDialogController2 = _interopRequireDefault(_resourceDialogController);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var CreateInfusionController = function (_ResourceDialogContro) {
  _inherits(CreateInfusionController, _ResourceDialogContro);

  function CreateInfusionController() {
    _classCallCheck(this, CreateInfusionController);

    return _possibleConstructorReturn(this, (CreateInfusionController.__proto__ || Object.getPrototypeOf(CreateInfusionController)).apply(this, arguments));
  }

  _createClass(CreateInfusionController, [{
    key: 'init',
    value: function init() {
      _get(CreateInfusionController.prototype.__proto__ || Object.getPrototypeOf(CreateInfusionController.prototype), 'init', this).call(this);
      this.InfusionResource = this.$injector.get('InfusionResource');
      this.ID = _nodeUuid2.default.v4();
    }
  }, {
    key: 'delete',
    value: function _delete(id) {
      var _this2 = this;

      this.Dialog.confirm().then(function () {
        _this2.InfusionResource.destroy(id).then(function () {
          _this2.Dialog.cancel(id);
        });
      });
    }
  }, {
    key: 'create',
    value: function create(data) {
      var _this3 = this;

      data.id = this.ID;

      this.InfusionResource.create(data).then(function () {
        _this3.Dialog.hide(data);
      }).catch(function (err) {
        if (err.status) {
          _this3.Toast.showSimple('Error: could not create infusion.');
        }
      });
    }
  }, {
    key: 'update',
    value: function update(data) {
      var _this4 = this;

      this.InfusionResource.update(data.id, data).then(function (infusion) {
        _this4.Dialog.hide(infusion);
      }).catch(function (err) {
        if (err.status) {
          _this4.Toast.showSimple('Error: could not update infusion.');
        }
      });
    }
  }]);

  return CreateInfusionController;
}(_resourceDialogController2.default);

exports.default = CreateInfusionController;

},{"common/resource/controllers/resourceDialogController":56,"node-uuid":"node-uuid"}],309:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _resourceDialog = require('common/resource/services/resourceDialog');

var _resourceDialog2 = _interopRequireDefault(_resourceDialog);

var _infusionDialog = require('./infusion-dialog.controller');

var _infusionDialog2 = _interopRequireDefault(_infusionDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var InfusionDialog = function (_ResourceDialog) {
  _inherits(InfusionDialog, _ResourceDialog);

  function InfusionDialog() {
    _classCallCheck(this, InfusionDialog);

    return _possibleConstructorReturn(this, (InfusionDialog.__proto__ || Object.getPrototypeOf(InfusionDialog)).apply(this, arguments));
  }

  _createClass(InfusionDialog, [{
    key: 'init',

    /**
     * @ngInject
     */
    value: function init() {
      _get(InfusionDialog.prototype.__proto__ || Object.getPrototypeOf(InfusionDialog.prototype), 'init', this).call(this);
      this.setController(_infusionDialog2.default);
      this.setTemplateUrl('pages/admin/drugs/services/infusion-dialog/infusion-dialog.tpl.html');
    }
  }]);

  return InfusionDialog;
}(_resourceDialog2.default);

exports.default = InfusionDialog;

},{"./infusion-dialog.controller":308,"common/resource/services/resourceDialog":59}],310:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _resourceDialogController = require('common/resource/controllers/resourceDialogController');

var _resourceDialogController2 = _interopRequireDefault(_resourceDialogController);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var RejectDrugController = function (_ResourceDialogContro) {
  _inherits(RejectDrugController, _ResourceDialogContro);

  function RejectDrugController() {
    _classCallCheck(this, RejectDrugController);

    return _possibleConstructorReturn(this, (RejectDrugController.__proto__ || Object.getPrototypeOf(RejectDrugController)).apply(this, arguments));
  }

  _createClass(RejectDrugController, [{
    key: 'init',
    value: function init() {
      _get(RejectDrugController.prototype.__proto__ || Object.getPrototypeOf(RejectDrugController.prototype), 'init', this).call(this);
      this.DrugResource = this.$injector.get('DrugResource');
      this.Toast = this.$injector.get('Toast');
    }
  }, {
    key: 'reject',
    value: function reject(id, drug) {
      var _this2 = this;

      this.DrugResource.reject(id, drug).then(function () {
        _this2.Dialog.hide();
      }).catch(function (err) {
        if (err.status) {
          _this2.Toast.showSimple('Error: could not reject drug.');
        }
      });
    }
  }]);

  return RejectDrugController;
}(_resourceDialogController2.default);

exports.default = RejectDrugController;

},{"common/resource/controllers/resourceDialogController":56}],311:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _angular = require('angular');

var _rejectDialog = require('../reject-dialog/reject-dialog.controller');

var _rejectDialog2 = _interopRequireDefault(_rejectDialog);

var _resourceDialogController = require('common/resource/controllers/resourceDialogController');

var _resourceDialogController2 = _interopRequireDefault(_resourceDialogController);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ReviewDrugController = function (_ResourceDialogContro) {
  _inherits(ReviewDrugController, _ResourceDialogContro);

  function ReviewDrugController() {
    _classCallCheck(this, ReviewDrugController);

    return _possibleConstructorReturn(this, (ReviewDrugController.__proto__ || Object.getPrototypeOf(ReviewDrugController)).apply(this, arguments));
  }

  _createClass(ReviewDrugController, [{
    key: 'init',
    value: function init() {
      _get(ReviewDrugController.prototype.__proto__ || Object.getPrototypeOf(ReviewDrugController.prototype), 'init', this).call(this);
      this.DrugResource = this.$injector.get('DrugResource');
      this._Dialog = this.$injector.get('Dialog');
      this._Utils = this.$injector.get('Utils');
      this._Toast = this.$injector.get('Toast');

      this.rejectDialogController = _rejectDialog2.default;
      this.rejectDialogTemplateUrl = 'pages/admin/drugs/services/reject-dialog/reject-dialog.tpl.html';
    }
  }, {
    key: 'approve',
    value: function approve(id) {
      var _this2 = this;

      this.DrugResource.approve(id).then(function (drug) {
        _this2.Dialog.hide(drug);
      }).catch(function (err) {
        if (err.status) {
          _this2.Toast.showSimple('Error: could not approve drug.');
        }
      });
    }
  }, {
    key: 'reject',
    value: function reject(evt, data) {
      var _this3 = this;

      var dialogParams = {
        controller: this.rejectDialogController,
        locals: {
          item: (0, _angular.copy)(data),
          relationData: this.relationData
        },
        targetEvent: evt,
        templateUrl: this.rejectDialogTemplateUrl
      };

      this._Dialog.show(dialogParams).then(function () {
        _this3._Utils.removeFromArrayById(_this3.items, data.id);
        _this3._Toast.showSimple('Non-formulary Drug rejected.');
      }).catch(function (err) {
        if (err.status) {
          _this3._Toast.showSimple('Error: could not reject drug.');
        }
      });
    }
  }]);

  return ReviewDrugController;
}(_resourceDialogController2.default);

exports.default = ReviewDrugController;

},{"../reject-dialog/reject-dialog.controller":310,"angular":"angular","common/resource/controllers/resourceDialogController":56}],312:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var EquipmentLocationListController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function EquipmentLocationListController($state, EquipmentLocationDialog, EquipmentLocationResource) {
    _classCallCheck(this, EquipmentLocationListController);

    this.$state = $state;
    this.EquipmentLocationDialog = EquipmentLocationDialog;
    this.EquipmentLocationResource = EquipmentLocationResource;
  }

  _createClass(EquipmentLocationListController, [{
    key: '$onInit',
    value: function $onInit() {
      var _this = this;

      this.listFields = { name: 'Name' };
      this.EquipmentLocationResource.index().then(function (items) {
        _this.items = items;
      });
    }
  }, {
    key: 'showCreateDialog',
    value: function showCreateDialog($event) {
      var _this2 = this;

      this.EquipmentLocationDialog.show({ $event: $event }).then(function (data) {
        _this2.goTo(data.id);
      });
    }
  }, {
    key: 'listButtonAction',
    value: function listButtonAction(event, item) {
      this.goTo(item.id);
    }
  }, {
    key: 'goTo',
    value: function goTo(id) {
      return this.$state.go('admin.equipmentLocations.show', { id: id });
    }
  }]);

  return EquipmentLocationListController;
}();

exports.default = {
  controller: EquipmentLocationListController,
  templateUrl: 'pages/admin/equipment-locations/components/equipment-location-list/equipment-location-list.tpl.html'
};

},{}],313:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function reduceCompartments(acc, cur) {
  if (cur.compartments) acc = acc.concat(cur.compartments.reduce(reduceCompartments, []));
  return acc.concat(cur.items);
}

var EquipmentLocationSingleController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function EquipmentLocationSingleController($state, $stateParams, EquipmentLocationDialog, EquipmentLocationResource) {
    _classCallCheck(this, EquipmentLocationSingleController);

    this.$state = $state;
    this.$stateParams = $stateParams;
    this.EquipmentLocationDialog = EquipmentLocationDialog;
    this.EquipmentLocationResource = EquipmentLocationResource;
  }

  _createClass(EquipmentLocationSingleController, [{
    key: '$onInit',
    value: function $onInit() {
      var _this = this;

      this.EquipmentLocationResource.show(this.$stateParams.id, { include: 'compartments.items,compartments.compartments' }).then(function (item) {
        _this.item = _this.formatItem(item);
      });
    }
  }, {
    key: 'showCreateDialog',
    value: function showCreateDialog($event) {
      var _this2 = this;

      this.EquipmentLocationDialog.show({ $event: $event, item: this.item }).then(function (data) {
        _this2.item = _this2.formatItem(data);
      }).catch(function (id) {
        if (id) _this2.$state.go('admin.equipmentLocations.list');
      });
    }
  }, {
    key: 'formatItem',
    value: function formatItem(item) {
      item.compartments.reduce(reduceCompartments, []).forEach(function (i) {
        i.itemTypeString = i.item_type.charAt(0).toUpperCase() + i.item_type.slice(1);
        i.quantityString = i.quantity || '-';
      });
      return item;
    }
  }]);

  return EquipmentLocationSingleController;
}();

var componentName = 'equipment-location-single';
exports.default = {
  controller: EquipmentLocationSingleController,
  templateUrl: 'pages/admin/equipment-locations/components/' + componentName + '/' + componentName + '.tpl.html'
};

},{}],314:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function ($stateProvider) {
  'ngInject';

  $stateProvider.state('admin.equipmentLocations', {
    abstract: true,
    url: '/equipment-locations',
    template: '<div ui-view layout="column" flex></div>'
  }).state('admin.equipmentLocations.list', {
    url: '/',
    controllerAs: '$ctrl',
    template: '<equipment-location-list-page layout="column" flex/>',
    data: {
      title: 'Equipment Locations'
    }
  }).state('admin.equipmentLocations.show', {
    url: '/{id}',
    controllerAs: '$ctrl',
    template: '<equipment-location-single-page layout="column" flex/>',
    data: {
      title: 'Equipment Location'
    }
  });
};

;

},{}],315:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _angularUiRouter = require('angular-ui-router');

var _angularUiRouter2 = _interopRequireDefault(_angularUiRouter);

var _module2 = require('./module.config');

var _module3 = _interopRequireDefault(_module2);

var _equipmentLocationList = require('./components/equipment-location-list/equipment-location-list.component');

var _equipmentLocationList2 = _interopRequireDefault(_equipmentLocationList);

var _equipmentLocationSingle = require('./components/equipment-location-single/equipment-location-single.component');

var _equipmentLocationSingle2 = _interopRequireDefault(_equipmentLocationSingle);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _module = _angular2.default.module('gnaas.pages.admin.equipmentLocations', [_angularUiRouter2.default]).config(_module3.default).component('equipmentLocationListPage', _equipmentLocationList2.default).component('equipmentLocationSinglePage', _equipmentLocationSingle2.default);

exports.default = _module.name;

},{"./components/equipment-location-list/equipment-location-list.component":312,"./components/equipment-location-single/equipment-location-single.component":313,"./module.config":314,"angular":"angular","angular-ui-router":"angular-ui-router"}],316:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var PAGE_SIZE = 25;

function formatItem(item) {
  item.referralNumber = item.incident.incident_number;
  item.flaggedBy = item.flagged_by.first_name + ' ' + item.flagged_by.last_name;
  item.flaggedOn = (0, _moment2.default)(item.flagged_on_time).format('DD-MM-YYYY');
  item.resolvedBy = item.resolved_by ? item.resolved_by.first_name + ' ' + item.resolved_by.last_name : '-';
  item.resolvedOn = item.resolved_on_time ? (0, _moment2.default)(item.resolved_on_time).format('DD-MM-YYYY') : '-';
  return item;
}

var FlaggedIncidentListController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function FlaggedIncidentListController($state, FlaggedIncidentResource, ResolveIncidentDialog, Toast) {
    _classCallCheck(this, FlaggedIncidentListController);

    this.$state = $state;
    this.FlaggedIncidentResource = FlaggedIncidentResource;
    this.ResolveIncidentDialog = ResolveIncidentDialog;
    this.Toast = Toast;
  }

  _createClass(FlaggedIncidentListController, [{
    key: '$onInit',
    value: function $onInit() {
      this.TAB_FLAGGED = 'flagged';
      this.TAB_RESOLVED = 'resolved';
      this.resolved = false;

      this.tabs = [{ id: this.TAB_FLAGGED, name: 'Flagged' }, { id: this.TAB_RESOLVED, name: 'Resolved' }];

      this.listFields = {
        referralNumber: 'Referral Number',
        flaggedBy: 'Flagged by',
        flaggedOn: 'Flagged on',
        resolvedBy: 'Resolved by',
        resolvedOn: 'Resolved on'
      };

      this.currentPage = 1;
      this.totalPages = 1;
      this.loadPage(1);
    }
  }, {
    key: 'selectTab',
    value: function selectTab(tab) {
      if (this.tabs.map(function (item) {
        return item.id;
      }).indexOf(tab.id) === -1) {
        throw new Error('Tab name must be "flagged" or "resolved".');
      }
      this.resolved = tab.id === this.TAB_RESOLVED;
      this.loadPage(1);
    }
  }, {
    key: 'previousPage',
    value: function previousPage() {
      if (this.currentPage === 1) return;
      this.currentPage--;
      this.loadPage(this.currentPage);
    }
  }, {
    key: 'nextPage',
    value: function nextPage() {
      if (this.currentPage === this.totalPages) return;
      this.currentPage++;
      this.loadPage(this.currentPage);
    }
  }, {
    key: 'loadPage',
    value: function loadPage(page) {
      this.currentPage = page;
      this._getItems(this.currentPage, PAGE_SIZE, this.resolved);
    }
  }, {
    key: 'listButtonAction',
    value: function listButtonAction(event, item) {
      var _this = this;

      this.ResolveIncidentDialog.show({ item: item, $event: event }).then(function () {
        _this.Toast.showSimple('Flagged referral resolved.');
        _this.loadPage(_this.currentPage);
      }).catch(function (e) {
        if (e != null && e.status) {
          _this.Toast.showSimple('Error when resolving flagged referral.');
        }
      });
    }
  }, {
    key: '_getItems',
    value: function _getItems(page, pageSize) {
      var _this2 = this;

      var resolved = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

      this.items = [];
      this.FlaggedIncidentResource.index({
        include: 'incident,flaggedBy,resolvedBy',
        resolved: resolved ? 1 : 0,
        page: page,
        page_size: pageSize
      }).then(function (items) {
        _this2.items = items.data.map(formatItem);
        _this2.totalPages = items.pagination.total_pages;
      });
    }
  }]);

  return FlaggedIncidentListController;
}();

exports.default = {
  controller: FlaggedIncidentListController,
  templateUrl: 'pages/admin/flagged-incidents/components/flagged-incident-list/flagged-incident-list.tpl.html'
};

},{"moment":"moment"}],317:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function ($stateProvider) {
  'ngInject';

  $stateProvider.state('admin.flaggedIncidents', {
    url: '/flagged-referrals',
    controllerAs: '$ctrl',
    template: '<flagged-incident-list-page layout="column" flex></flagged-incident-list-page>',
    data: {
      title: 'Flagged Referrals'
    }
  });
};

;

},{}],318:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _angularUiRouter = require('angular-ui-router');

var _angularUiRouter2 = _interopRequireDefault(_angularUiRouter);

var _module2 = require('./module.config');

var _module3 = _interopRequireDefault(_module2);

var _flaggedIncidentList = require('./components/flagged-incident-list/flagged-incident-list.component');

var _flaggedIncidentList2 = _interopRequireDefault(_flaggedIncidentList);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _module = _angular2.default.module('gnaas.pages.admin.flaggedIncidents', [_angularUiRouter2.default]).config(_module3.default).component('flaggedIncidentListPage', _flaggedIncidentList2.default);

exports.default = _module.name;

},{"./components/flagged-incident-list/flagged-incident-list.component":316,"./module.config":317,"angular":"angular","angular-ui-router":"angular-ui-router"}],319:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _groupResource = require('./services/groupResource');

var _groupResource2 = _interopRequireDefault(_groupResource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _module = _angular2.default.module('gnaas.pages.admin.groups', []).service('GroupResource', _groupResource2.default);

exports.default = _module.name;

},{"./services/groupResource":320,"angular":"angular"}],320:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var GroupResource = function (_RestResource) {
  _inherits(GroupResource, _RestResource);

  function GroupResource() {
    _classCallCheck(this, GroupResource);

    return _possibleConstructorReturn(this, (GroupResource.__proto__ || Object.getPrototypeOf(GroupResource)).apply(this, arguments));
  }

  _createClass(GroupResource, [{
    key: 'init',
    value: function init() {
      _get(GroupResource.prototype.__proto__ || Object.getPrototypeOf(GroupResource.prototype), 'init', this).call(this);
      this.resourcePath = 'groups';
    }
  }]);

  return GroupResource;
}(_restResource2.default);

exports.default = GroupResource;

},{"common/resource/services/restResource":60}],321:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _nodeUuid = require('node-uuid');

var _nodeUuid2 = _interopRequireDefault(_nodeUuid);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var CreateHospitalController = function () {
  function CreateHospitalController(HospitalResource, Dialog) {
    'ngInject';

    _classCallCheck(this, CreateHospitalController);

    this._HospitalResource = HospitalResource;
    this._Dialog = Dialog;

    this.ID = _nodeUuid2.default.v4();

    this.selectedSpecialties = [];
    if (this.item && this.item.specialties) {
      this.selectedSpecialties = this.item.specialties;
    }
  }

  _createClass(CreateHospitalController, [{
    key: 'submit',
    value: function submit(data) {
      if (this.form.$invalid) {
        return;
      }

      data.specialties = this.selectedSpecialties;

      if (this.editing) {
        this._update(data);
      } else {
        this._create(data);
      }
    }
  }, {
    key: 'cancel',
    value: function cancel() {
      this._Dialog.cancel();
    }
  }, {
    key: 'delete',
    value: function _delete(id) {
      var _this = this;

      this._Dialog.confirm().then(function () {
        _this._HospitalResource.destroy(id).then(function () {
          _this._Dialog.cancel(id);
        });
      });
    }
  }, {
    key: '_create',
    value: function _create(hospitalData) {
      var _this2 = this;

      hospitalData.id = this.ID;

      this._HospitalResource.create(hospitalData).then(function () {
        _this2._Dialog.hide(hospitalData);
      }).catch(console.log.bind(console));
    }
  }, {
    key: '_update',
    value: function _update(hospitalData) {
      var _this3 = this;

      this._HospitalResource.update(hospitalData.id, hospitalData).then(function (hospital) {
        _this3._Dialog.hide(hospital);
      }).catch(console.log.bind(console));
    }
  }]);

  return CreateHospitalController;
}();

exports.default = CreateHospitalController;

},{"node-uuid":"node-uuid"}],322:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HospitalResourceController;

var _createHospitalController = require('./createHospitalController');

var _createHospitalController2 = _interopRequireDefault(_createHospitalController);

var _module = require('common/resource/module.constants');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function HospitalResourceController(HospitalResource, HospitalSpecialtyResource) {
  'ngInject';

  var _this = this;

  this.singularName = 'Hospital';
  this.pluralName = 'Hospitals';

  this.createDialogController = _createHospitalController2.default;
  this.createDialogTemplateUrl = 'pages/admin/hospitals/templates/createDialog.tpl.html';

  HospitalResource.index().then(function (items) {
    _this.items = items;
  });
  this.relationData = { specialties: [] };
  HospitalSpecialtyResource.index().then(function (items) {
    _this.relationData.specialties = items;
  });

  this.listFields = {
    name: 'Name',
    abbreviation: 'Abbreviation'
  };

  this.hasProfiles = true;
  this.profileFields = [{
    key: 'abbreviation',
    label: 'Abbreviation',
    type: _module.RESOURCE_ITEM_FIELD_TYPES.item
  }, {
    key: 'lat',
    label: 'Latitude',
    type: _module.RESOURCE_ITEM_FIELD_TYPES.item
  }, {
    key: 'lng',
    label: 'Longitude',
    type: _module.RESOURCE_ITEM_FIELD_TYPES.item
  }, {
    key: 'specialties',
    label: 'Specialties',
    type: _module.RESOURCE_ITEM_FIELD_TYPES.collection,
    collectionKey: 'name'
  }];
};

},{"./createHospitalController":321,"common/resource/module.constants":57}],323:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _angularUiRouter = require('angular-ui-router');

var _angularUiRouter2 = _interopRequireDefault(_angularUiRouter);

var _hospitalResourceController = require('./controllers/hospitalResourceController');

var _hospitalResourceController2 = _interopRequireDefault(_hospitalResourceController);

var _hospitalResource = require('./services/hospitalResource');

var _hospitalResource2 = _interopRequireDefault(_hospitalResource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var moduleConfig = function moduleConfig($stateProvider) {
  'ngInject';

  $stateProvider.state('admin.hospitals', {
    url: '/hospitals',
    templateUrl: 'common/resource/templates/resource.tpl.html',
    controller: _hospitalResourceController2.default,
    controllerAs: '$ctrl',
    data: {
      title: 'Hospitals'
    }
  });
};

var _module = _angular2.default.module('gnaas.pages.admin.hospitals', [_angularUiRouter2.default]).config(moduleConfig).service('HospitalResource', _hospitalResource2.default);

exports.default = _module.name;

},{"./controllers/hospitalResourceController":322,"./services/hospitalResource":324,"angular":"angular","angular-ui-router":"angular-ui-router"}],324:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.format = exports.parse = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _parse(item) {
  var parsed = {
    id: item.id || null,
    name: item.name,
    abbreviation: item.abbreviation,
    lat: item.lat,
    lng: item.lng
  };

  if (item.specialties) {
    parsed.specialties = item.specialties.map(function (item) {
      return item.id;
    });
  }

  return parsed;
}

exports.parse = _parse;
function _format(item) {
  return {
    id: item.id,
    name: item.name,
    abbreviation: item.abbreviation,
    lat: item.lat,
    lng: item.lng,
    specialties: item.specialties ? item.specialties.data : []
  };
}

exports.format = _format;

var HospitalResource = function (_RestResource) {
  _inherits(HospitalResource, _RestResource);

  function HospitalResource() {
    _classCallCheck(this, HospitalResource);

    return _possibleConstructorReturn(this, (HospitalResource.__proto__ || Object.getPrototypeOf(HospitalResource)).apply(this, arguments));
  }

  _createClass(HospitalResource, [{
    key: 'init',
    value: function init() {
      _get(HospitalResource.prototype.__proto__ || Object.getPrototypeOf(HospitalResource.prototype), 'init', this).call(this);
      this.resourcePath = 'hospitals';
    }
  }, {
    key: 'parse',
    value: function parse(item) {
      return _parse(item);
    }
  }, {
    key: 'format',
    value: function format(item) {
      return _format(item);
    }
  }]);

  return HospitalResource;
}(_restResource2.default);

exports.default = HospitalResource;

},{"common/resource/services/restResource":60}],325:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _angularUiRouter = require('angular-ui-router');

var _angularUiRouter2 = _interopRequireDefault(_angularUiRouter);

var _module2 = require('./bases/module');

var _module3 = _interopRequireDefault(_module2);

var _module4 = require('./categories/module');

var _module5 = _interopRequireDefault(_module4);

var _module6 = require('./consumables/module');

var _module7 = _interopRequireDefault(_module6);

var _module8 = require('./devices/module');

var _module9 = _interopRequireDefault(_module8);

var _module10 = require('./equipment-locations/module');

var _module11 = _interopRequireDefault(_module10);

var _module12 = require('./flagged-incidents/module');

var _module13 = _interopRequireDefault(_module12);

var _module14 = require('./groups/module');

var _module15 = _interopRequireDefault(_module14);

var _module16 = require('./hospitals/module');

var _module17 = _interopRequireDefault(_module16);

var _module18 = require('./drugs/module');

var _module19 = _interopRequireDefault(_module18);

var _module20 = require('./reports/module');

var _module21 = _interopRequireDefault(_module20);

var _module22 = require('./vehicles/module');

var _module23 = _interopRequireDefault(_module22);

var _module24 = require('./vehicleCallsigns/module');

var _module25 = _interopRequireDefault(_module24);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var moduleConfig = function moduleConfig($stateProvider) {
  'ngInject';

  $stateProvider.state('admin', {
    abstract: true,
    url: '/admin',
    template: '<div ui-view layout="column" flex></div>'
  });
};

var _module = _angular2.default.module('gnaas.pages.admin', [_angularUiRouter2.default, _module3.default, _module5.default, _module7.default, _module9.default, _module11.default, _module13.default, _module15.default, _module17.default, _module19.default, _module21.default, _module23.default, _module25.default]).config(moduleConfig);

exports.default = _module.name;

},{"./bases/module":265,"./categories/module":278,"./consumables/module":282,"./devices/module":286,"./drugs/module":298,"./equipment-locations/module":315,"./flagged-incidents/module":318,"./groups/module":319,"./hospitals/module":323,"./reports/module":328,"./vehicleCallsigns/module":331,"./vehicles/module":337,"angular":"angular","angular-ui-router":"angular-ui-router"}],326:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _nodeUuid = require('node-uuid');

var _nodeUuid2 = _interopRequireDefault(_nodeUuid);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var REPORTABLE_TYPE_DRUG = 'drug';
var REPORTABLE_TYPE_INTERVENTION = 'intervention';

function parseSelected(type, items) {
  return items.map(function (item) {
    if (item.reportable_type) return item;
    var original = Object.assign({}, item);
    return {
      id: _nodeUuid2.default.v4(),
      reportable_id: item.reportable_id,
      reportable_type: type,
      reportable: original
    };
  });
}

function getTitle(type) {
  switch (type) {
    case REPORTABLE_TYPE_DRUG:
      return 'Reportable Drugs';
    case REPORTABLE_TYPE_INTERVENTION:
      return 'Reportable Interventions';
  }
}

/**
 * This page supports multiple resource types.
 * By default all reportables are requested and passed to reportables dialog for sync (it's all or nothing).
 * Only reportables with currently active type are visible in table list.
 *
 * TODO There is a theoretical possibility that IDs might match between different reportables types.
 *      In reality it should never occur. But for future reference - refactor this to only work with a single
 *      type at a time. Would require API changes as well.
 */

var ReportablesPageController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function ReportablesPageController($stateParams, Toast, CheckboxListDialog, ReportablesResource, DrugResource, InterventionResource) {
    var _optionsResources;

    _classCallCheck(this, ReportablesPageController);

    this.$stateParams = $stateParams;
    this.Toast = Toast;
    this.CheckboxListDialog = CheckboxListDialog;
    this.ReportablesResource = ReportablesResource;
    this.optionsResources = (_optionsResources = {}, _defineProperty(_optionsResources, REPORTABLE_TYPE_DRUG, DrugResource), _defineProperty(_optionsResources, REPORTABLE_TYPE_INTERVENTION, InterventionResource), _optionsResources);
  }

  _createClass(ReportablesPageController, [{
    key: '$onInit',
    value: function $onInit() {
      var _this = this;

      this.type = this.$stateParams.type.toLowerCase();
      this.fields = {
        reportableName: 'Name'
      };

      var validTypes = [REPORTABLE_TYPE_DRUG, REPORTABLE_TYPE_INTERVENTION];
      if (!this.type || !~validTypes.indexOf(this.type)) {
        throw new Error('Type not supported. Must be "' + REPORTABLE_TYPE_DRUG + '" or "' + REPORTABLE_TYPE_INTERVENTION + '"');
      }

      this.title = getTitle(this.type);

      this.items = [];
      this.displayItems = [];
      this.ReportablesResource.index({ include: 'reportable' }).then(this._setItems.bind(this));

      this.options = [];
      this.optionsResources[this.type].index().then(function (items) {
        Object.assign(_this.options, items.map(function (i) {
          return Object.assign({}, i, { reportable_id: i.id });
        }));
      });
    }
  }, {
    key: 'showSyncDialog',
    value: function showSyncDialog($event) {
      var _this2 = this;

      var params = {
        $event: $event,
        resource: this.ReportablesResource,
        options: this.options,
        items: this.items,
        parse: parseSelected.bind(null, this.type),
        title: this.title,
        trackByKey: 'reportable_id'
      };

      this.CheckboxListDialog.show(params).then(function (items) {
        _this2._setItems(items);
        _this2.Toast.showSimple(params.title + ' updated');
      }).catch(function (err) {
        if (err && err.status) {
          _this2.Toast.showSimple('Error updating ' + params.title.toLowerCase());
        }
      });
    }
  }, {
    key: '_setItems',
    value: function _setItems(items) {
      var _this3 = this;

      this.items = items.map(function (i) {
        return Object.assign({}, i, { reportableName: i.reportable.name });
      });
      this.displayItems = this.items.filter(function (i) {
        return i.reportable_type === _this3.type;
      });
    }
  }]);

  return ReportablesPageController;
}();

var ReportablesPageComponent = {
  controller: ReportablesPageController,
  templateUrl: 'pages/admin/reports/components/reportables-page/reportables-page.tpl.html'
};

exports.default = ReportablesPageComponent;

},{"node-uuid":"node-uuid"}],327:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function ($stateProvider) {
  'ngInject';

  $stateProvider.state('admin.reports', {
    abstract: true,
    url: '/reports',
    template: '<div ui-view layout="column" flex></div>'
  }).state('admin.reports.reportables', {
    url: '/reportables/:type',
    controllerAs: '$ctrl',
    template: '<reportables-page layout="column" flex></reportables-page>',
    data: {
      title: 'Reportable Items'
    }
  });
};

;

},{}],328:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _angularUiRouter = require('angular-ui-router');

var _angularUiRouter2 = _interopRequireDefault(_angularUiRouter);

var _module2 = require('./module.config');

var _module3 = _interopRequireDefault(_module2);

var _reportablesPage = require('./components/reportables-page/reportables-page.component');

var _reportablesPage2 = _interopRequireDefault(_reportablesPage);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _module = _angular2.default.module('gnaas.pages.admin.reports', [_angularUiRouter2.default]).config(_module3.default).component('reportablesPage', _reportablesPage2.default);

exports.default = _module.name;

},{"./components/reportables-page/reportables-page.component":326,"./module.config":327,"angular":"angular","angular-ui-router":"angular-ui-router"}],329:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _nodeUuid = require('node-uuid');

var _nodeUuid2 = _interopRequireDefault(_nodeUuid);

var _resourceDialogController = require('common/resource/controllers/resourceDialogController');

var _resourceDialogController2 = _interopRequireDefault(_resourceDialogController);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var CreateCallsignController = function (_ResourceDialogContro) {
  _inherits(CreateCallsignController, _ResourceDialogContro);

  function CreateCallsignController() {
    _classCallCheck(this, CreateCallsignController);

    return _possibleConstructorReturn(this, (CreateCallsignController.__proto__ || Object.getPrototypeOf(CreateCallsignController)).apply(this, arguments));
  }

  _createClass(CreateCallsignController, [{
    key: 'init',
    value: function init() {
      _get(CreateCallsignController.prototype.__proto__ || Object.getPrototypeOf(CreateCallsignController.prototype), 'init', this).call(this);
      this._resource = this.$injector.get('VehicleCallsignResource');
      this._ID = _nodeUuid2.default.v4();
    }
  }, {
    key: 'create',
    value: function create(data) {
      var _this2 = this;

      data.id = this._ID;
      this._resource.create(data).then(function () {
        data.statusString = data.online ? 'Online' : 'Offline';
        data.baseName = data.base.name;
        _this2.Dialog.hide(data);
      }).catch(console.log.bind(console));
    }
  }, {
    key: 'update',
    value: function update(data) {
      var _this3 = this;

      this._resource.update(data.id, data, {
        include: 'vehicle,base,offlineReason'
      }).then(function (item) {
        item.baseName = item.base.name;
        _this3.Dialog.hide(item);
      }).catch(console.log.bind(console));
    }
  }, {
    key: 'delete',
    value: function _delete(id) {
      var _this4 = this;

      this.Dialog.confirm().then(function () {
        _this4._resource.destroy(id).then(function () {
          _this4.Dialog.cancel(id);
        });
      });
    }
  }]);

  return CreateCallsignController;
}(_resourceDialogController2.default);

exports.default = CreateCallsignController;

},{"common/resource/controllers/resourceDialogController":56,"node-uuid":"node-uuid"}],330:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = VehicleCallsignResourceController;

var _createVehicleCallsignController = require('./createVehicleCallsignController');

var _createVehicleCallsignController2 = _interopRequireDefault(_createVehicleCallsignController);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function VehicleCallsignResourceController(VehicleCallsignResource, VehicleResource, BaseResource, OfflineReasonResource) {

  'ngInject';

  var _this = this;

  this.singularName = 'Vehicle Callsign';
  this.pluralName = 'Vehicle Callsigns';

  this.createDialogController = _createVehicleCallsignController2.default;
  this.createDialogTemplateUrl = 'pages/admin/vehicleCallsigns/templates/createDialog.tpl.html';

  VehicleCallsignResource.index({ include: 'vehicle,base,offlineReason' }).then(function (items) {
    _this.items = items.map(function (item) {
      item.baseName = item.base.name;
      return item;
    });
  });
  this.relationData = {
    vehicles: [],
    bases: [],
    offlineReasons: [],
    statuses: {
      'Offline': 0,
      'Online': 1
    }
  };
  // TODO Abstract in a VehicleCallsignService
  VehicleResource.index().then(function (data) {
    _this.relationData.vehicles = data;
  });
  BaseResource.index().then(function (data) {
    _this.relationData.bases = data;
  });
  OfflineReasonResource.index().then(function (data) {
    _this.relationData.offlineReasons = data;
  });

  this.listFields = {
    name: 'Name',
    statusString: 'Status',
    baseName: 'Base'
  };

  this.usesSidenav = false;
}

},{"./createVehicleCallsignController":329}],331:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _angularUiRouter = require('angular-ui-router');

var _angularUiRouter2 = _interopRequireDefault(_angularUiRouter);

var _vehicleCallsignResourceController = require('./controllers/vehicleCallsignResourceController');

var _vehicleCallsignResourceController2 = _interopRequireDefault(_vehicleCallsignResourceController);

var _vehicleCallsignResource = require('./services/vehicleCallsignResource');

var _vehicleCallsignResource2 = _interopRequireDefault(_vehicleCallsignResource);

var _vehicleCallsignStatusUpdateResource = require('./services/vehicleCallsignStatusUpdateResource');

var _vehicleCallsignStatusUpdateResource2 = _interopRequireDefault(_vehicleCallsignStatusUpdateResource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var moduleConfig = function moduleConfig($stateProvider) {
  'ngInject';

  $stateProvider.state('admin.vehicleCallsigns', {
    url: '/vehicle-callsigns',
    controller: _vehicleCallsignResourceController2.default,
    controllerAs: '$ctrl',
    templateUrl: 'common/resource/templates/resource.tpl.html',
    data: {
      title: 'Vehicle Callsigns'
    }
  });
};

var _module = _angular2.default.module('gnaas.pages.admin.callsigns', [_angularUiRouter2.default]).config(moduleConfig).service('VehicleCallsignResource', _vehicleCallsignResource2.default).service('VehicleCallsignStatusUpdateResource', _vehicleCallsignStatusUpdateResource2.default);

exports.default = _module.name;

},{"./controllers/vehicleCallsignResourceController":330,"./services/vehicleCallsignResource":332,"./services/vehicleCallsignStatusUpdateResource":333,"angular":"angular","angular-ui-router":"angular-ui-router"}],332:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parse = exports.format = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _vehicleResource = require('pages/admin/vehicles/services/vehicleResource');

var _vehicleCallsignStatusUpdateResource = require('pages/admin/vehicleCallsigns/services/vehicleCallsignStatusUpdateResource');

var _deploymentStatusUpdate = require('core/deployments/resources/deployment-status-update.resource');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _format(item) {
  var formatted = {
    id: item.id,
    name: item.name,
    online: item.online,
    statusString: item.online ? 'Online' : 'Offline'
  };

  if (item.base) {
    formatted.base = item.base.data;
  }

  if (item.vehicle) {
    formatted.vehicle = (0, _vehicleResource.format)(item.vehicle.data);
  }

  if (item.offlineReason) {
    formatted.offline_reason = item.offlineReason.data;
  }

  if (item.deploymentStatusUpdates) {
    formatted.deployment_status_updates = item.deploymentStatusUpdates.data.map(_deploymentStatusUpdate.format);

    if (item.deploymentStatusUpdates.data.length) {
      formatted.latest_status_update = formatted.deployment_status_updates[0];
    }
  }

  if (item.lastDeploymentStatusWithDestination) {
    formatted.lastDeployment = item.lastDeploymentStatusWithDestination.data;

    if (formatted.lastDeployment.destination) {
      formatted.lastDeployment.destination = formatted.lastDeployment.destination.data;
    }

    if (formatted.lastDeployment.deploymentStatus) {
      formatted.lastDeployment.deploymentStatus = formatted.lastDeployment.deploymentStatus.data;
    }
  }

  return formatted;
}

exports.format = _format;
function _parse(item) {
  var parsed = {
    id: item.id,
    name: item.name,
    online: item.online ? item.online : 0
  };

  if (item.vehicle != null) {
    parsed.vehicle_id = item.vehicle ? item.vehicle.id : null;
  }

  if (item.base) {
    parsed.base_id = item.base.id;
  }

  if (!item.online && item.offline_reason) {
    parsed.offline_reason_id = item.offline_reason.id;
  } else {
    parsed.offline_reason_id = null;
  }

  return parsed;
}

exports.parse = _parse;

var CallsignResource = function (_RestResource) {
  _inherits(CallsignResource, _RestResource);

  function CallsignResource() {
    _classCallCheck(this, CallsignResource);

    return _possibleConstructorReturn(this, (CallsignResource.__proto__ || Object.getPrototypeOf(CallsignResource)).apply(this, arguments));
  }

  _createClass(CallsignResource, [{
    key: 'init',
    value: function init() {
      _get(CallsignResource.prototype.__proto__ || Object.getPrototypeOf(CallsignResource.prototype), 'init', this).call(this);
      this.resourcePath = 'vehicle-callsigns';
    }
  }, {
    key: 'format',
    value: function format(item) {
      return _format(item);
    }
  }, {
    key: 'parse',
    value: function parse(item) {
      return _parse(item);
    }
  }, {
    key: 'createStatus',
    value: function createStatus(vehicleCallsignId, formData) {
      var url = '' + this.apiUrl + this.resourcePath + '/' + vehicleCallsignId + '/status-updates';

      var parsed = {
        online: formData.online ? formData.online : 0,
        time: (0, _moment2.default)(formData.time).format('YYYY-MM-DD') + ' ' + (0, _moment2.default)(formData.time).format('HH:mm:ss')
      };

      if (!formData.online && formData.offline_reason) {
        parsed.offline_reason_id = formData.offline_reason.id;
      } else {
        parsed.offline_reason_id = null;
      }

      return this._http.post(url, parsed).then(function (response) {
        return response.data.data ? (0, _vehicleCallsignStatusUpdateResource.format)(response.data.data) : null;
      });
    }
  }, {
    key: 'updateStatus',
    value: function updateStatus(vehicleCallsignId, statusUpdateId, formData) {
      var url = '' + this.apiUrl + this.resourcePath + '/' + vehicleCallsignId + '/status-updates/' + statusUpdateId;

      var parsed = {
        online: formData.online ? formData.online : 0,
        time: (0, _moment2.default)(formData.time).format('YYYY-MM-DD') + ' ' + (0, _moment2.default)(formData.time).format('HH:mm:ss')
      };

      if (!formData.online && formData.offline_reason) {
        parsed.offline_reason_id = formData.offline_reason.id;
      } else {
        parsed.offline_reason_id = null;
      }

      return this._http.put(url, parsed).then(function (response) {
        return response.data.data ? (0, _vehicleCallsignStatusUpdateResource.format)(response.data.data) : null;
      });
    }
  }, {
    key: 'deleteStatus',
    value: function deleteStatus(vehicleCallsignId, id) {
      var url = '' + this.apiUrl + this.resourcePath + '/' + vehicleCallsignId + '/status-updates/' + id;

      return this._http.delete(url).then(function (response) {
        return response.data;
      });
    }
  }]);

  return CallsignResource;
}(_restResource2.default);

exports.default = CallsignResource;

},{"common/resource/services/restResource":60,"core/deployments/resources/deployment-status-update.resource":124,"moment":"moment","pages/admin/vehicleCallsigns/services/vehicleCallsignStatusUpdateResource":333,"pages/admin/vehicles/services/vehicleResource":338}],333:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.format = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _format(item) {
  var dateTime = item.time.date ? item.time.date : item.time;
  var formatted = {
    id: item.id,
    online: !!item.online,
    statusString: item.online ? 'Online' : 'Offline',
    time: (0, _moment2.default)(dateTime, 'YYYY-MM-DD HH:mm:ss')
  };

  if (item.offlineReason) {
    formatted.offlineReason = item.offlineReason.data;
  }

  if (item.vehicleCallsign) {
    formatted.vehicleCallsign = item.vehicleCallsign.data;
  }

  return formatted;
}

exports.format = _format;

var CallsignStatusUpdateResource = function (_RestResource) {
  _inherits(CallsignStatusUpdateResource, _RestResource);

  function CallsignStatusUpdateResource() {
    _classCallCheck(this, CallsignStatusUpdateResource);

    return _possibleConstructorReturn(this, (CallsignStatusUpdateResource.__proto__ || Object.getPrototypeOf(CallsignStatusUpdateResource)).apply(this, arguments));
  }

  _createClass(CallsignStatusUpdateResource, [{
    key: 'init',
    value: function init() {
      _get(CallsignStatusUpdateResource.prototype.__proto__ || Object.getPrototypeOf(CallsignStatusUpdateResource.prototype), 'init', this).call(this);
      this.resourcePath = 'vehicle-callsign-status-updates';
    }
  }, {
    key: 'format',
    value: function format(item) {
      return _format(item);
    }
  }]);

  return CallsignStatusUpdateResource;
}(_restResource2.default);

exports.default = CallsignStatusUpdateResource;

},{"common/resource/services/restResource":60,"moment":"moment"}],334:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _nodeUuid = require('node-uuid');

var _nodeUuid2 = _interopRequireDefault(_nodeUuid);

var _module = require('pages/admin/vehicles/module.constants');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var CreateVehicleController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function CreateVehicleController(VehicleResource, Dialog) {
    _classCallCheck(this, CreateVehicleController);

    this._VehicleResource = VehicleResource;
    this._Dialog = Dialog;
    this.ID = _nodeUuid2.default.v4();
  }

  _createClass(CreateVehicleController, [{
    key: 'submit',
    value: function submit(data) {
      if (this.form.$invalid) {
        return;
      }

      if (this.editing) {
        this._update(data);
      } else {
        this._create(data);
      }
    }
  }, {
    key: 'cancel',
    value: function cancel() {
      this._Dialog.cancel();
    }
  }, {
    key: 'delete',
    value: function _delete(id) {
      var _this = this;

      this._Dialog.confirm().then(function () {
        _this._VehicleResource.destroy(id).then(function () {
          _this._Dialog.cancel(id);
        });
      });
    }
  }, {
    key: '_create',
    value: function _create(data) {
      var _this2 = this;

      data.id = this.ID;
      this._VehicleResource.create(data).then(function () {
        data.typeString = _module.VEHICLE_TYPES_REVERSE[data.type];
        _this2._Dialog.hide(data);
      }).catch(console.log.bind(console));
    }
  }, {
    key: '_update',
    value: function _update(data) {
      var _this3 = this;

      this._VehicleResource.update(data.id, data).then(function (item) {
        _this3._Dialog.hide(item);
      }).catch(console.log.bind(console));
    }
  }]);

  return CreateVehicleController;
}();

exports.default = CreateVehicleController;

},{"node-uuid":"node-uuid","pages/admin/vehicles/module.constants":336}],335:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = VehicleResourceController;

var _createVehicleController = require('./createVehicleController');

var _createVehicleController2 = _interopRequireDefault(_createVehicleController);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function VehicleResourceController(VehicleResource) {
  'ngInject';

  var _this = this;

  this.singularName = 'Vehicle';
  this.pluralName = 'Vehicles';

  this.createDialogController = _createVehicleController2.default;
  this.createDialogTemplateUrl = 'pages/admin/vehicles/templates/createDialog.tpl.html';

  VehicleResource.index().then(function (items) {
    _this.items = items;
  });
  this.relationData = {
    types: {}
  };
  VehicleResource.getTypes().then(function (data) {
    _this.relationData.types = data;
  });

  this.listFields = {
    registration_number: 'Reg. No.',
    typeString: 'Type'
  };

  this.usesSidenav = false;
}

},{"./createVehicleController":334}],336:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var VEHICLE_TYPES = exports.VEHICLE_TYPES = {
  helicopter: 0,
  car: 1
};

var VEHICLE_TYPES_REVERSE = exports.VEHICLE_TYPES_REVERSE = {
  0: 'Helicopter',
  1: 'Car'
};

},{}],337:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _angularUiRouter = require('angular-ui-router');

var _angularUiRouter2 = _interopRequireDefault(_angularUiRouter);

var _vehicleResourceController = require('./controllers/vehicleResourceController');

var _vehicleResourceController2 = _interopRequireDefault(_vehicleResourceController);

var _vehicleResource = require('./services/vehicleResource');

var _vehicleResource2 = _interopRequireDefault(_vehicleResource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var moduleConfig = function moduleConfig($stateProvider) {
  'ngInject';

  $stateProvider.state('admin.vehicles', {
    url: '/vehicles',
    controller: _vehicleResourceController2.default,
    controllerAs: '$ctrl',
    templateUrl: 'common/resource/templates/resource.tpl.html',
    data: {
      title: 'Vehicles'
    }
  });
};

var _module = _angular2.default.module('gnaas.pages.admin.vehicles', [_angularUiRouter2.default]).config(moduleConfig).service('VehicleResource', _vehicleResource2.default);

exports.default = _module.name;

},{"./controllers/vehicleResourceController":335,"./services/vehicleResource":338,"angular":"angular","angular-ui-router":"angular-ui-router"}],338:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.format = exports.parse = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

var _module = require('pages/admin/vehicles/module.constants');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _parse(item) {
  return {
    id: item.id || null,
    registration_number: item.registration_number,
    type: item.type
  };
}

exports.parse = _parse;
function _format(item) {
  return {
    id: item.id,
    registration_number: item.registration_number,
    type: item.type,
    typeString: _module.VEHICLE_TYPES_REVERSE[item.type]
  };
}

exports.format = _format;

var VehicleResource = function (_RestResource) {
  _inherits(VehicleResource, _RestResource);

  function VehicleResource() {
    _classCallCheck(this, VehicleResource);

    return _possibleConstructorReturn(this, (VehicleResource.__proto__ || Object.getPrototypeOf(VehicleResource)).apply(this, arguments));
  }

  _createClass(VehicleResource, [{
    key: 'init',
    value: function init() {
      _get(VehicleResource.prototype.__proto__ || Object.getPrototypeOf(VehicleResource.prototype), 'init', this).call(this);
      this.resourcePath = 'vehicles';
    }
  }, {
    key: 'getTypes',
    value: function getTypes() {
      var url = this.apiUrl + 'categories/vehicle-types';
      return this._http.get(url).then(function (response) {
        return response.data.data;
      });
    }
  }, {
    key: 'parse',
    value: function parse(item) {
      return _parse(item);
    }
  }, {
    key: 'format',
    value: function format(item) {
      return _format(item);
    }
  }]);

  return VehicleResource;
}(_restResource2.default);

exports.default = VehicleResource;

},{"common/resource/services/restResource":60,"pages/admin/vehicles/module.constants":336}],339:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AuditToolController = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _swacctsMapper = require('swacctsMapper');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var AuditToolController = exports.AuditToolController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function AuditToolController(Dialog, ColumnsToDisplayDialog, Toast, AuditToolResource) {
    _classCallCheck(this, AuditToolController);

    this._Dialog = Dialog;
    this._ColumnsToDisplayDialog = ColumnsToDisplayDialog;
    this._Toast = Toast;
    this._AuditToolResource = AuditToolResource;
  }

  _createClass(AuditToolController, [{
    key: '$onInit',
    value: function $onInit() {
      this.selectedTab = 'search';
      this.showLoader = false;
      this.queryFailed = false;
      this.query = {};
      this.loadSearchCriteria();
    }
  }, {
    key: 'selectTab',
    value: function selectTab(tab) {
      if (!(tab === 'search' || tab === 'savedSearches')) {
        throw new Error('Tab name must be "search" or "savedSearches".');
      }
      this.selectedTab = tab;
    }
  }, {
    key: 'changeCategory',
    value: function changeCategory(_ref) {
      var name = _ref.name;

      this.selectedCategoryName = name;
      this.selectedCategory = this.categories[name];
    }
  }, {
    key: 'search',
    value: function search(_ref2) {
      var _this = this;

      var criteria = _ref2.criteria,
          columnsToDisplay = _ref2.columnsToDisplay;

      this._Toast.showSimple('Searching...');
      this.showLoader = true;
      this.queryFailed = false;

      this._AuditToolResource.search(criteria, columnsToDisplay).then(function (data) {
        _this.searchResults = data;
      }).catch(function () {
        _this.queryFailed = true;
      }).finally(function () {
        return _this.showLoader = false;
      });
    }
  }, {
    key: 'loadSearchCriteria',
    value: function loadSearchCriteria() {
      var _this2 = this;

      this.categories = [];
      this.operations = [];
      this.values = [];
      this.showLoader = true;
      this._AuditToolResource.getCategories().then(function (data) {
        _this2.categories = (0, _swacctsMapper.mapSearchCategories)(data.categories);
        _this2.operations = data.operations;
        _this2.values = data.values;
        _this2.changeCategory({ name: Object.keys(data.categories)[0] });
      }).finally(function () {
        return _this2.showLoader = false;
      });
    }

    /*
    saveSearch () {
      if (!this.query.length) {
        return;
      }
       this._Toast.showSimple('Saving search...');
    }
    */

  }]);

  return AuditToolController;
}();

exports.default = {
  controller: AuditToolController,
  templateUrl: 'pages/audit-tool/components/audit-tool-page/audit-tool-page.tpl.html'
};

},{"swacctsMapper":405}],340:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.KPIPageController = undefined;

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _swacctsMapper = require('swacctsMapper');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function getColumnWidth(len) {
  if (len < 5) return len * 20;
  if (len < 10) return len * 15;
  if (len > 19) return len * 11;
  if (len > 13) return len * 14;
  return len * 15;
}

function prettify(str) {
  return str.split('_').map(function (item) {
    return ucFirst(item);
  }).join(' ');
}

function ucFirst(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

var KPIPageController = exports.KPIPageController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function KPIPageController($window, $state, KpiResource, Dialog, Toast) {
    _classCallCheck(this, KPIPageController);

    this.$window = $window;
    this.$state = $state;
    this.KpiResource = KpiResource;
    this.Dialog = Dialog;
    this.Toast = Toast;
  }

  _createClass(KPIPageController, [{
    key: '$onInit',
    value: function $onInit() {
      var _this = this;

      this.showLoader = false;
      this.data = null;
      this.columns = [];
      this.options = {
        enablePagination: true,
        totalItems: 0,
        pageNumber: 1
      };
      this.actionColumn = {
        options: {
          name: 'actions',
          displayName: 'Actions',
          width: 264
        },
        actions: [{
          name: 'Referral',
          callback: function callback(entity) {
            var url = _this.$state.href('incident', { id: entity.incident_id });
            _this.$window.open(url, '_blank');
          }
        }, {
          name: 'Deployment',
          callback: function callback(entity) {
            if (!entity.deployment_id) return;
            var url = _this.$state.href('deployments', { id: entity.deployment_id });
            _this.$window.open(url, '_blank');
          },
          isHidden: function isHidden(entity) {
            return !entity.deployment_id;
          }
        }, {
          name: 'PRF',
          callback: function callback(entity) {
            if (!entity.prf_id) return;
            var url = _this.$state.href('prf.patientDetails', { id: entity.prf_id });
            _this.$window.open(url, '_blank');
          },
          isHidden: function isHidden(entity) {
            return !entity.prf_id;
          }
        }]
      };
      this.maxDate = new Date();
    }
  }, {
    key: 'search',
    value: function search(dateFrom, dateTo) {
      var _this2 = this;

      if (!this.dateFrom || !this.dateTo) {
        this.Dialog.alert('Please set date range.');
        return;
      }

      var from = (0, _moment2.default)(this.dateFrom).format('YYYY-MM-DD');
      var to = (0, _moment2.default)(this.dateTo).format('YYYY-MM-DD');
      this.showLoader = true;
      this.KpiResource.get(from, to).then(function (data) {
        _this2.columns = data.length ? _this2._getColumnDefs(data[0]) : [];
        _this2.options = Object.assign({}, _this2.options, { pageNumber: 1, totalItems: data.length });
        _this2.data = data;
        _this2.showLoader = false;
      }).catch(function () {
        _this2.Toast.showSimple('Error retrieving KPI data.');
        _this2.showLoader = false;
      });
    }
  }, {
    key: 'exportCsv',
    value: function exportCsv() {
      if (!this.data.length) return;
      var keys = Object.keys(this.data[0]).filter(function (k) {
        return k !== '$$hashKey';
      });
      var data = this.data.map(function (item) {
        return Object.entries(item).filter(function (_ref) {
          var _ref2 = _slicedToArray(_ref, 1),
              k = _ref2[0];

          return k !== '$$hashKey';
        })
        // Enclose value in double quotes, to prevent data splitting incorrectly in case a comma is present id value
        .map(function (_ref3) {
          var _ref4 = _slicedToArray(_ref3, 2),
              k = _ref4[0],
              v = _ref4[1];

          return v === null ? '""' : '"' + v + '"';
        });
      });
      var csvContent = [keys].concat(_toConsumableArray(data)).reduce(function (acc, cur) {
        return '' + acc + cur.join(',') + '\r\n';
      }, '');
      this._downloadCsv(csvContent);
    }
  }, {
    key: '_downloadCsv',
    value: function _downloadCsv(csvContent) {
      var encodedUri = 'data:text/csv;charset=utf-8,' + encodeURI(csvContent);
      var from = (0, _moment2.default)(this.dateFrom).format('YYYY-MM-DD');
      var to = (0, _moment2.default)(this.dateTo).format('YYYY-MM-DD');

      var link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', 'kpi_' + from + '_' + to + '.csv');
      link.setAttribute('target', '_blank');

      document.body.appendChild(link); // Required for FF
      link.click();
      document.body.removeChild(link); // Required for FF
    }
  }, {
    key: '_getColumnDefs',
    value: function _getColumnDefs(row) {
      var _this3 = this;

      return Object.keys(row).reduce(function (acc, cur) {
        return Object.assign({}, acc, _defineProperty({}, cur, _this3._getColumnDef(cur)));
      }, {});
    }
  }, {
    key: '_getColumnDef',
    value: function _getColumnDef(key) {
      var name = prettify((0, _swacctsMapper.mapSearchProperty)(key));
      return { name: name, width: getColumnWidth(name.length) };
    }
  }]);

  return KPIPageController;
}();

exports.default = {
  controller: KPIPageController,
  templateUrl: 'pages/audit-tool/components/kpi-page/kpi-page.tpl.html'
};

},{"moment":"moment","swacctsMapper":405}],341:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _angularUiRouter = require('angular-ui-router');

var _angularUiRouter2 = _interopRequireDefault(_angularUiRouter);

var _auditToolPage = require('./components/audit-tool-page/audit-tool-page.component');

var _auditToolPage2 = _interopRequireDefault(_auditToolPage);

var _kpiPage = require('./components/kpi-page/kpi-page.component');

var _kpiPage2 = _interopRequireDefault(_kpiPage);

var _auditTool = require('./resources/audit-tool.resource');

var _auditTool2 = _interopRequireDefault(_auditTool);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var moduleConfig = function moduleConfig($stateProvider) {
  'ngInject';

  $stateProvider.state('auditTool', {
    url: '/audit-tool',
    template: '<audit-tool-page flex layout="column"></audit-tool-page>',
    data: {
      title: 'Audit Tool'
    }
  }).state('kpi', {
    url: '/kpi',
    template: '<kpi-page flex layout="column"></kpi-page>',
    data: {
      title: 'KPI'
    }
  });
};

var _module = _angular2.default.module('gnaas.pages.auditTool', [_angularUiRouter2.default]).config(moduleConfig).component('auditToolPage', _auditToolPage2.default).component('kpiPage', _kpiPage2.default).service('AuditToolResource', _auditTool2.default);

exports.default = _module.name;

},{"./components/audit-tool-page/audit-tool-page.component":339,"./components/kpi-page/kpi-page.component":340,"./resources/audit-tool.resource":342,"angular":"angular","angular-ui-router":"angular-ui-router"}],342:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var AuditToolResource = function () {
  /**
   * @constructor
   * @ngInject
   */
  function AuditToolResource($http, API_URL) {
    _classCallCheck(this, AuditToolResource);

    this._http = $http;
    this.baseUrl = API_URL + "audit-tool";
  }

  _createClass(AuditToolResource, [{
    key: "getCategories",
    value: function getCategories() {
      return this._http.get(this.baseUrl + "/categories").then(function (res) {
        return res.data.data;
      });
    }
  }, {
    key: "search",
    value: function search(criteria, columnsToDisplay) {
      return this._http.post(this.baseUrl + "/search", {
        criteria: criteria,
        columns: columnsToDisplay
      }).then(function (res) {
        return res.data.data;
      });
    }
  }]);

  return AuditToolResource;
}();

exports.default = AuditToolResource;

},{}],343:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var CALENDAR_EXPIRING_DRUGS_SIDENAV = exports.CALENDAR_EXPIRING_DRUGS_SIDENAV = 'sidenav-calendar-expiring-items';

function reduceItems(items) {
  return Array.isArray(items) ? [].reduce.bind(items) : [].reduce.bind(Object.values(items));
}

var CalendarExpiringItemsController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function CalendarExpiringItemsController($mdSidenav) {
    _classCallCheck(this, CalendarExpiringItemsController);

    this._mdSidenav = $mdSidenav;
  }

  _createClass(CalendarExpiringItemsController, [{
    key: '$onInit',
    value: function $onInit() {
      this.visible = {};
    }
  }, {
    key: '$onChanges',
    value: function $onChanges(changes) {
      if (changes.items && changes.items.currentValue) {
        this.visible = this.getVisibleItems(this.stockLocation, changes.items.currentValue);
      }
    }
  }, {
    key: 'close',
    value: function close() {
      this._mdSidenav(CALENDAR_EXPIRING_DRUGS_SIDENAV).close();
    }
  }, {
    key: 'onStockLocationChange',
    value: function onStockLocationChange(_ref) {
      var value = _ref.value;

      this.visible = this.getVisibleItems(value, this.items);
    }
  }, {
    key: 'getVisibleItems',
    value: function getVisibleItems(stockLocation, items) {
      return reduceItems(items)(function (acc, cur) {
        acc[cur.key] = stockLocation ? cur.batches.filter(function (i) {
          return i.location ? i.location.id === stockLocation.id : i.stockLocation.id === stockLocation.id;
        }) : [].concat(_toConsumableArray(cur.batches));
        return acc;
      }, {});
    }
  }]);

  return CalendarExpiringItemsController;
}();

exports.default = {
  bindings: {
    items: '<',
    stockLocations: '<'
  },
  controller: CalendarExpiringItemsController,
  templateUrl: 'pages/calendar/templates/calendar-expiring-items.tpl.html'
};

},{}],344:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CalendarController = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _angular = require('angular');

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

require('moment-range');

var _createCalendarEventController = require('pages/calendar/controllers/createCalendarEventController');

var _createCalendarEventController2 = _interopRequireDefault(_createCalendarEventController);

var _calendarDay = require('./calendarDay');

var _calendarExpiringItems = require('./calendar-expiring-items');

var _module = require('common/resource/module.constants');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var CalendarController = exports.CalendarController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function CalendarController($mdSidenav, $timeout, Dialog, Toast, Utils, CalendarEventResource, CalendarAgendaResource, CalendarEventTypeResource, VehicleResource, CrewMemberResource, BaseResource, DrugBatchesResource, StockLocationResource, ConsumableBatchResource, DestroyBatchDialog) {
    _classCallCheck(this, CalendarController);

    this._mdSidenav = $mdSidenav;
    this._timeout = $timeout;
    this._Dialog = Dialog;
    this._Toast = Toast;
    this._Utils = Utils;
    this._CalendarEventResource = CalendarEventResource;
    this._CalendarAgendaResource = CalendarAgendaResource;
    this._CalendarEventTypeResource = CalendarEventTypeResource;
    this._VehicleResource = VehicleResource;
    this._CrewMemberResource = CrewMemberResource;
    this._BaseResource = BaseResource;
    this._DrugBatchesResource = DrugBatchesResource;
    this._StockLocationResource = StockLocationResource;
    this._ConsumableBatchResource = ConsumableBatchResource;
    this._DestroyBatchDialog = DestroyBatchDialog;
  }

  _createClass(CalendarController, [{
    key: '$onInit',
    value: function $onInit() {
      this.today = (0, _moment2.default)();
      this.date = (0, _moment2.default)();
      this.weeks = [];
      this.calendarDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
      this.stockLocations = [];
      this.relationData = {
        eventTypes: [],
        vehicles: [],
        crewMembers: [],
        bases: []
      };
      this.expiries = {
        drugs: {
          key: 'drug',
          labels: { single: 'Drug', multiple: 'Drugs' },
          batches: [],
          removeBatch: this.onRemoveDrugBatchClick.bind(this)
        },
        consumables: {
          key: 'consumable',
          labels: { single: 'Consumable', multiple: 'Consumables' },
          batches: [],
          removeBatch: this.onRemoveConsumableClick.bind(this)
        }
      };

      this.currentMonth();
      this._loadRelationData();
    }
  }, {
    key: 'currentMonth',
    value: function currentMonth() {
      this.date = (0, _moment2.default)();
      this._loadMonth();
    }
  }, {
    key: 'nextMonth',
    value: function nextMonth() {
      this.date.add(1, 'M');
      this._loadMonth();
    }
  }, {
    key: 'previousMonth',
    value: function previousMonth() {
      this.date.subtract(1, 'M');
      this._loadMonth();
    }
  }, {
    key: 'selectDay',
    value: function selectDay(day) {
      this.selectedDay = day;
      this._mdSidenav(_calendarDay.CALENDAR_DAY_SIDENAV).open();
    }
  }, {
    key: 'showCreateDialog',
    value: function showCreateDialog(evt, date, data) {
      var _this = this;

      var editing = !!data;

      if (date) {
        date.hours((0, _moment2.default)().hours());
      }

      var dialogParams = {
        controller: _createCalendarEventController2.default,
        locals: {
          editing: editing,
          item: (0, _angular.copy)(data),
          relationData: this.relationData,
          date: date || (0, _moment2.default)()
        },
        targetEvent: evt,
        templateUrl: 'pages/calendar/templates/createCalendarEventDialog.tpl.html',
        onShowing: function onShowing($scope, $element) {
          var list = void 0,
              listHeight = void 0;
          $scope.crewMemberListToggled = false;

          _this._timeout(function () {
            list = (0, _angular.element)($element[0].querySelector('div.form-checkbox-list__items'));
            listHeight = list.prop('clientHeight');
            list.css('height', 0);
          }, 100);

          $scope.toggleCrewMemberList = function () {
            $scope.crewMemberListToggled = !$scope.crewMemberListToggled;
            if ($scope.crewMemberListToggled) {
              list.css('height', listHeight + 'px');
            } else {
              list.css('height', 0);
            }
          };
        },
        multiple: true
      };

      this._Dialog.show(dialogParams).then(function (item) {
        if (editing) {
          _this._handleCalendarEvent(item, 'update');
          _this._Toast.showSimple('Calendar Event updated.');
        } else {
          _this._handleCalendarEvent(item, 'create');
          _this._Toast.showSimple('New Calendar Event created.');
        }
      }).catch(function (item) {
        if (item) {
          _this._handleCalendarEvent(item, 'delete');
          _this._Toast.showSimple('Calendar Event deleted.');
        }
      });
    }
  }, {
    key: 'showExpiringDrugs',
    value: function showExpiringDrugs() {
      this._mdSidenav(_calendarExpiringItems.CALENDAR_EXPIRING_DRUGS_SIDENAV).open();
    }
  }, {
    key: 'onRemoveDrugBatchClick',
    value: function onRemoveDrugBatchClick(_ref) {
      var _this2 = this;

      var $event = _ref.$event,
          batch = _ref.batch;

      this._DestroyBatchDialog.show({ $event: $event, item: batch, locals: { fromCalendar: true } }).then(function () {
        var drugBatches = _this2.expiries.drugs.batches.filter(function (i) {
          return i.id !== batch.id;
        });
        _this2.expiries = _this2._getUpdatedExpiries(drugBatches, null);
      });
    }
  }, {
    key: 'onRemoveConsumableClick',
    value: function onRemoveConsumableClick(_ref2) {
      var _this3 = this;

      var $event = _ref2.$event,
          batch = _ref2.batch;

      return this._Dialog.confirm('Are you sure you want to remove this batch?').then(function () {
        return _this3._ConsumableBatchResource.destroy(batch.id);
      }).then(function () {
        var consumableBatches = _this3.expiries.consumables.batches.filter(function (i) {
          return i.id !== batch.id;
        });
        _this3.expiries = _this3._getUpdatedExpiries(null, consumableBatches);
      });
    }
  }, {
    key: '_getUpdatedExpiries',
    value: function _getUpdatedExpiries() {
      var drugBatches = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var consumableBatches = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

      return Object.assign({}, this.expiries, {
        drugs: Object.assign({}, this.expiries.drugs, {
          batches: drugBatches || [].concat(_toConsumableArray(this.expiries.drugs.batches))
        }),
        consumables: Object.assign({}, this.expiries.consumables, {
          batches: consumableBatches || [].concat(_toConsumableArray(this.expiries.consumables.batches))
        })
      });
    }
  }, {
    key: '_loadMonth',
    value: function _loadMonth() {
      var _this4 = this;

      var range = this._getDateRange(this.date);
      this.days = this._getDays(this.today, this.date, range);
      this.weeks = this._getWeeks(this.days.length);

      var startDate = range.start.format(_module.DATE_FORMAT);
      var endDate = range.end.format(_module.DATE_FORMAT);
      var expiryStart = (0, _moment2.default)(this.date).startOf('month').format(_module.DATE_FORMAT);
      var expiryEnd = (0, _moment2.default)(this.date).endOf('month').format(_module.DATE_FORMAT);

      var requestsFinished = 0;
      var requestsRequired = 4;
      this.dataLoading = true;

      this._CalendarEventResource.index({ start_date: startDate, end_date: endDate, include: 'type,vehicle,users,base' }).then(function (events) {
        events.forEach(function (event) {
          _this4._handleCalendarEvent(event, 'create');
        });
      }).finally(function () {
        return _this4._handleLoader('dataLoading', ++requestsFinished, requestsRequired);
      });

      this._CalendarAgendaResource.index({ start_date: startDate, end_date: endDate }).then(function (agendas) {
        agendas.forEach(_this4._handleAgenda.bind(_this4));
      }).finally(function () {
        return _this4._handleLoader('dataLoading', ++requestsFinished, requestsRequired);
      });

      this._DrugBatchesResource.index({
        include: 'drug.equipmentLocationCompartmentItems.compartment.location,location',
        expiry_start: expiryStart,
        expiry_end: expiryEnd,
        quantity_mode: 'non-empty'
      }).then(function (expiringDrugs) {
        _this4.expiries = _this4._getUpdatedExpiries(expiringDrugs, null);
      }).finally(function () {
        return _this4._handleLoader('dataLoading', ++requestsFinished, requestsRequired);
      });

      this._ConsumableBatchResource.index({
        include: 'consumable.equipmentLocationCompartmentItems.compartment.location,stockLocation',
        expiry_start: expiryStart,
        expiry_end: expiryEnd,
        quantity_mode: 'non-empty'
      }).then(function (expiringConsumables) {
        _this4.expiries = _this4._getUpdatedExpiries(null, expiringConsumables);
      }).finally(function () {
        return _this4._handleLoader('dataLoading', ++requestsFinished, requestsRequired);
      });
    }
  }, {
    key: '_loadRelationData',
    value: function _loadRelationData() {
      var _this5 = this;

      var requestsFinished = 0;
      var requestsRequired = 5;
      this.relationDataLoading = true;

      this._CalendarEventTypeResource.index().then(function (items) {
        _this5.relationData.eventTypes = items;
      }).catch(console.log.bind(console)).finally(function () {
        return _this5._handleLoader('relationDataLoading', ++requestsFinished, requestsRequired);
      });

      this._VehicleResource.index().then(function (items) {
        _this5.relationData.vehicles = items;
      }).catch(console.log.bind(console)).finally(function () {
        return _this5._handleLoader('relationDataLoading', ++requestsFinished, requestsRequired);
      });

      this._CrewMemberResource.index().then(function (items) {
        _this5.relationData.crewMembers = items;
      }).catch(console.log.bind(console)).finally(function () {
        return _this5._handleLoader('relationDataLoading', ++requestsFinished, requestsRequired);
      });

      this._BaseResource.index().then(function (items) {
        _this5.relationData.bases = items;
      }).catch(console.log.bind(console)).finally(function () {
        return _this5._handleLoader('relationDataLoading', ++requestsFinished, requestsRequired);
      });

      this._StockLocationResource.index().then(function (items) {
        _this5.stockLocations = items;
      }).catch(console.log.bind(console)).finally(function () {
        return _this5._handleLoader('relationDataLoading', ++requestsFinished, requestsRequired);
      });
    }
  }, {
    key: '_getDateRange',
    value: function _getDateRange(date) {
      return _moment2.default.range((0, _moment2.default)(date).startOf('month').startOf('week'), (0, _moment2.default)(date).endOf('month').endOf('week'));
    }
  }, {
    key: '_getDays',
    value: function _getDays(today, date, range) {
      var days = [];

      range.by('days', function (day) {
        if (day.month() !== date.month()) {
          day.isDisabled = true;
        }
        if (day.date() === today.date() && day.month() === today.month()) {
          day.isToday = true;
        }
        day.events = [];
        day.agenda = null;
        days.push(day);
      });

      return days;
    }
  }, {
    key: '_getWeeks',
    value: function _getWeeks(dayCount) {
      var weekCount = Math.ceil(dayCount / 7);
      var weeks = [];
      while (weekCount) {
        weeks.unshift(--weekCount);
      }
      return weeks;
    }
  }, {
    key: '_handleCalendarEvent',
    value: function _handleCalendarEvent(event) {
      var _this6 = this;

      var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'create';

      if (['create', 'update', 'delete'].indexOf(action) === -1) {
        throw new Error('Action must match "create", "update" or "delete".');
      }

      event.isMultiDayEvent = Math.abs(event.start_date.diff(event.end_date, 'days')) > 0;

      if (action !== 'delete') {
        event.isStartDate = function (date) {
          return Math.abs(event.start_date.diff(date, 'days')) === 0;
        };

        event.isEndDate = function (date) {
          return Math.abs(event.end_date.diff(date, 'days')) === 0;
        };

        event.isMultiDayEventAndStartDay = function (day) {
          return event.isMultiDayEvent && event.isStartDate(day);
        };
      }

      if (event.isMultiDayEvent) {
        for (var i = 0, dl = this.days.length; i < dl; i++) {
          var day = this.days[i];
          if (day.isBetween(event.start_date, event.end_date, 'day', '[]')) {
            this._Utils.removeFromArrayById(day.events, event.id);
            if (action !== 'delete') {
              day.events.push(event);
            }
          }
        }
      } else {
        this.days.some(function (day) {
          if (event.start_date.diff(day, 'days') === 0) {
            _this6._Utils.removeFromArrayById(day.events, event.id);
            if (action !== 'delete') {
              day.events.push(event);
            }
            return true;
          }
          return false;
        });
      }
    }
  }, {
    key: '_handleAgenda',
    value: function _handleAgenda(agenda) {
      this.days.some(function (day) {
        if (agenda.date.diff(day, 'days') === 0) {
          day.agenda = agenda;
          return true;
        }
        return false;
      });
    }
  }, {
    key: '_handleLoader',
    value: function _handleLoader(loaderName, requestsRequired, requestsFinished) {
      if (requestsRequired === requestsFinished) {
        this[loaderName] = false;
      }
    }
  }]);

  return CalendarController;
}();

exports.default = {
  controller: CalendarController,
  templateUrl: 'pages/calendar/templates/calendar.tpl.html'
};

},{"./calendar-expiring-items":343,"./calendarDay":348,"angular":"angular","common/resource/module.constants":57,"moment":"moment","moment-range":"moment-range","pages/calendar/controllers/createCalendarEventController":351}],345:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  bindings: {
    agenda: '@'
  },
  template: '\n    <h3 class="f-subheading f-subheading--small u-padding-wide-x">Agenda</h3>\n    <div class="c-calendar-agenda">\n        <div ng-if="$ctrl.agenda" class="c-calendar-agenda__textarea"\n            ng-bind-html="$ctrl.agenda | nl2br"></div>\n        <div ng-if="$ctrl.agenda" class="c-calendar-agenda__gradient"></div>\n        <span ng-if="!$ctrl.agenda" class="o-no-content o-no-content--padding-x">\n            There is no agenda created for today.\n        </span>\n    </div>\n  '
};

},{}],346:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CalendarCellController = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _angular = require('angular');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var CalendarCellController = exports.CalendarCellController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function CalendarCellController($element, $rootScope, $scope) {
    _classCallCheck(this, CalendarCellController);

    this._element = $element;
    this._rootScope = $rootScope;
    this._scope = $scope;
  }

  _createClass(CalendarCellController, [{
    key: '$onInit',
    value: function $onInit() {
      var _this = this;

      this._more = (0, _angular.element)(this._element[0].querySelectorAll('div.c-calendar-cell__more'));

      this._scope.$watch(function () {
        return _this.day.events;
      }, function (newVal) {
        _this._scope.$evalAsync(function () {
          _this._events = (0, _angular.element)(_this._element[0].querySelectorAll('div.c-calendar-cell__event'));
          if (_this._more.css('display') === 'block') {
            _this._more.css('display', 'none');
          }
          _this._filterEventVisibility();
        });
      }, true);
    }
  }, {
    key: '$postLink',
    value: function $postLink() {
      var _this2 = this;

      this._rootScope.$on('WINDOW_RESIZE', function () {
        _this2._filterEventVisibility();
      });
    }
  }, {
    key: 'isOneDayEvent',
    value: function isOneDayEvent(event) {
      return !event.all_day_event && !event.isMultiDayEvent;
    }
  }, {
    key: 'isStartOfMultiDayEvent',
    value: function isStartOfMultiDayEvent(event) {
      return !event.all_day_event && event.isMultiDayEvent && event.isStartDate(this.day);
    }
  }, {
    key: 'getEventStyles',
    value: function getEventStyles(event) {
      var isMultiOrAllDayEvent = event.all_day_event || event.isMultiDayEvent;
      return {
        'color': isMultiOrAllDayEvent ? '#FFFFFF' : event.type.color,
        'background-color': isMultiOrAllDayEvent ? event.type.color ? event.type.color : '#4C617C' : 'transparent'
      };
    }
  }, {
    key: '_filterEventVisibility',
    value: function _filterEventVisibility() {
      if (!this.day.events.length || this.day.events.length < 3) {
        return;
      }

      var cellHeight = this._element.prop('offsetHeight');
      var requiredHeight = this._events.length * 20 + 12 + 24; // event count * 20 + cell paddings + date field height
      var eventCount = this._events.length;

      if (requiredHeight < cellHeight) {
        if (this._more.css('display') === 'block') {
          this._more.css('display', 'none');
          this._events.css('display', 'block');
        }
        return;
      }

      var hideCount = Math.floor((requiredHeight - cellHeight) / 20) + 2;
      if (eventCount - hideCount < 1) {
        hideCount--;
      }

      this._events.css('display', 'block');
      for (var i = eventCount - 1, counter = 0; i >= 0; i--) {
        this._events[i].style.display = 'none';
        if (++counter === hideCount) {
          break;
        }
      }

      if (this._more.css('display') === 'none') {
        this._more.css('display', 'block');
      }
      this._more.text('+' + hideCount + ' more');
    }
  }]);

  return CalendarCellController;
}();

exports.default = {
  bindings: {
    day: '<',
    onDaySelect: '&'
  },
  controller: CalendarCellController,
  templateUrl: 'pages/calendar/templates/calendarCell.tpl.html'
};

},{"angular":"angular"}],347:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  controller: function controller($scope) {
    'ngInject';

    var _this = this;

    $scope.$watch(function () {
      return _this.dateString ? _this.dateString : null;
    }, function (newVal, oldVal) {
      if (_this.datePicker) {
        _this.date = (0, _moment2.default)(newVal).toDate();
      }
    });
  },
  bindings: {
    maxDate: '<',
    dateString: '@',
    datePicker: '@',
    onDateChange: '&',
    onNextClick: '&',
    onCurrentClick: '&',
    onPreviousClick: '&'
  },
  templateUrl: 'pages/calendar/templates/calendarControls.tpl.html'
};

},{"moment":"moment"}],348:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CalendarAgendaController = exports.CALENDAR_DAY_SIDENAV = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _angular = require('angular');

var _createCalendarAgendaController = require('pages/calendar/controllers/createCalendarAgendaController');

var _createCalendarAgendaController2 = _interopRequireDefault(_createCalendarAgendaController);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var CALENDAR_DAY_SIDENAV = exports.CALENDAR_DAY_SIDENAV = 'sidenav-calendar-day';

var CalendarAgendaController = exports.CalendarAgendaController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function CalendarAgendaController($mdSidenav, Dialog, Toast) {
    _classCallCheck(this, CalendarAgendaController);

    this._mdSidenav = $mdSidenav;
    this._Dialog = Dialog;
    this._Toast = Toast;
  }

  _createClass(CalendarAgendaController, [{
    key: 'close',
    value: function close() {
      this._mdSidenav(CALENDAR_DAY_SIDENAV).close();
    }
  }, {
    key: 'showAgendaDialog',
    value: function showAgendaDialog(evt, agenda) {
      var _this = this;

      var editing = !!agenda;

      var dialogParams = {
        controller: _createCalendarAgendaController2.default,
        editing: editing,
        targetEvent: evt,
        templateUrl: 'pages/calendar/templates/createCalendarAgendaDialog.tpl.html',
        item: (0, _angular.copy)(agenda),
        date: this.day
      };

      this._Dialog.show(dialogParams).then(function (item) {
        _this.day.agenda = item;
      }).catch(function (id) {
        if (id) {
          _this.day.agenda = null;
        }
      });
    }
  }]);

  return CalendarAgendaController;
}();

exports.default = {
  bindings: {
    day: '<',
    onAddEventClick: '&'
  },
  controller: CalendarAgendaController,
  templateUrl: 'pages/calendar/templates/calendarDay.tpl.html'
};

},{"angular":"angular","pages/calendar/controllers/createCalendarAgendaController":350}],349:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  bindings: {
    events: '<',
    day: '<',
    onEventClick: '&?'
  },
  controller: function controller() {
    this.clickActionPresent = typeof this.onEventClick === 'function';
  },
  templateUrl: 'pages/calendar/templates/calendarEvents.tpl.html'
};

},{}],350:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = CreateCalendarAgendaController;

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _nodeUuid = require('node-uuid');

var _nodeUuid2 = _interopRequireDefault(_nodeUuid);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function CreateCalendarAgendaController(Dialog, CalendarAgendaResource) {
  'ngInject';

  var AGENDA_ID = _nodeUuid2.default.v4();
  var AGENDA_DATE = (0, _moment2.default)(this.date);

  var create = function create(data) {
    data.id = AGENDA_ID;
    data.date = AGENDA_DATE;

    CalendarAgendaResource.create(data).then(function () {
      Dialog.hide(data);
    }).catch(console.log.bind(console));
  };

  var update = function update(data) {
    CalendarAgendaResource.update(data.id, data).then(function (agenda) {
      Dialog.hide(agenda);
    }).catch(console.log.bind(console));
  };

  this.submit = function (agenda) {
    if (this.form.$invalid) {
      return false;
    }

    if (this.editing) {
      update(agenda);
    } else {
      create(agenda);
    }
  };

  this.cancel = function () {
    Dialog.cancel();
  };

  this.delete = function (id) {
    Dialog.confirm().then(function () {
      CalendarAgendaResource.destroy(id).then(function () {
        Dialog.cancel(id);
      });
    });
  };
}

},{"moment":"moment","node-uuid":"node-uuid"}],351:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _nodeUuid = require('node-uuid');

var _nodeUuid2 = _interopRequireDefault(_nodeUuid);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var CreateCalendarEventController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function CreateCalendarEventController(Utils, Dialog, CalendarEventResource) {
    _classCallCheck(this, CreateCalendarEventController);

    this._Utils = Utils;
    this._Dialog = Dialog;
    this._CalendarEventResource = CalendarEventResource;

    this.ID = _nodeUuid2.default.v4();

    this.selectedCrewMembers = [];

    if (!this.item) {
      this.item = {
        start_date: this.date.clone().add(1, 'h'),
        start_time: this.date.clone().add(1, 'h').minutes(0).seconds(0).milliseconds(0),
        end_date: this.date.clone().add(2, 'h'),
        end_time: this.date.clone().add(2, 'h').minutes(0).seconds(0).milliseconds(0)
      };
    } else {
      this.selectedCrewMembers = this.item.crew_members || [];
    }
  }

  _createClass(CreateCalendarEventController, [{
    key: 'submit',
    value: function submit(item) {
      if (this.form.$invalid) {
        return false;
      }

      if (this.editing) {
        this._update(item, this.selectedCrewMembers);
      } else {
        this._create(item, this.selectedCrewMembers);
      }
    }
  }, {
    key: 'cancel',
    value: function cancel() {
      this._Dialog.cancel();
    }
  }, {
    key: 'delete',
    value: function _delete(id) {
      var _this = this;

      this._Dialog.confirm().then(function () {
        _this._CalendarEventResource.destroy(id).then(function () {
          _this._Dialog.cancel(_this.item);
        });
      });
    }
  }, {
    key: 'toggleCrewMember',
    value: function toggleCrewMember(item) {
      var object = this._Utils.findObjectInArrayById(this.selectedCrewMembers, item.id);

      if (object) {
        this.selectedCrewMembers.splice(this.selectedCrewMembers.indexOf(object), 1);
      } else {
        this.selectedCrewMembers.push(item);
      }
    }
  }, {
    key: 'crewMemberExists',
    value: function crewMemberExists(item) {
      return this._Utils.findObjectInArrayById(this.selectedCrewMembers, item.id);
    }
  }, {
    key: '_create',
    value: function _create(data, crewMembers) {
      var _this2 = this;

      data.id = this.ID;
      data.crew_members = crewMembers;

      this._CalendarEventResource.create(data).then(function () {
        _this2._Dialog.hide(data);
      }).catch(console.log.bind(console));
    }
  }, {
    key: '_update',
    value: function _update(data, crewMembers) {
      var _this3 = this;

      data.users = crewMembers;

      this._CalendarEventResource.update(data.id, data, {
        include: 'type,vehicle,users,base'
      }).then(function (calendarEvent) {
        _this3._Dialog.hide(calendarEvent);
      }).catch(console.log.bind(console));
    }
  }]);

  return CreateCalendarEventController;
}();

exports.default = CreateCalendarEventController;

},{"node-uuid":"node-uuid"}],352:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _angularUiRouter = require('angular-ui-router');

var _angularUiRouter2 = _interopRequireDefault(_angularUiRouter);

var _calendarEventResource = require('./services/calendarEventResource');

var _calendarEventResource2 = _interopRequireDefault(_calendarEventResource);

var _calendarAgendaResource = require('./services/calendarAgendaResource');

var _calendarAgendaResource2 = _interopRequireDefault(_calendarAgendaResource);

var _calendar = require('./components/calendar');

var _calendar2 = _interopRequireDefault(_calendar);

var _calendarAgenda = require('./components/calendarAgenda');

var _calendarAgenda2 = _interopRequireDefault(_calendarAgenda);

var _calendarCell = require('./components/calendarCell');

var _calendarCell2 = _interopRequireDefault(_calendarCell);

var _calendarControls = require('./components/calendarControls');

var _calendarControls2 = _interopRequireDefault(_calendarControls);

var _calendarDay = require('./components/calendarDay');

var _calendarDay2 = _interopRequireDefault(_calendarDay);

var _calendarEvents = require('./components/calendarEvents');

var _calendarEvents2 = _interopRequireDefault(_calendarEvents);

var _calendarExpiringItems = require('./components/calendar-expiring-items');

var _calendarExpiringItems2 = _interopRequireDefault(_calendarExpiringItems);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var moduleConfig = function moduleConfig($stateProvider) {
  'ngInject';

  $stateProvider.state('calendar', {
    url: '/calendar',
    template: '<calendar flex layout="column" window-resize-vertical-handler></calendar>',
    data: {
      title: 'Calendar'
    }
  });
};

var _module = _angular2.default.module('gnaas.pages.calendar', [_angularUiRouter2.default]).config(moduleConfig).service('CalendarEventResource', _calendarEventResource2.default).service('CalendarAgendaResource', _calendarAgendaResource2.default).component('calendar', _calendar2.default).component('calendarAgenda', _calendarAgenda2.default).component('calendarCell', _calendarCell2.default).component('calendarControls', _calendarControls2.default).component('calendarDay', _calendarDay2.default).component('calendarEvents', _calendarEvents2.default).component('calendarExpiringItems', _calendarExpiringItems2.default);

exports.default = _module.name;

},{"./components/calendar":344,"./components/calendar-expiring-items":343,"./components/calendarAgenda":345,"./components/calendarCell":346,"./components/calendarControls":347,"./components/calendarDay":348,"./components/calendarEvents":349,"./services/calendarAgendaResource":353,"./services/calendarEventResource":354,"angular":"angular","angular-ui-router":"angular-ui-router"}],353:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.format = exports.parse = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _parse(item) {
  return {
    id: item.id,
    text: item.text,
    date: item.date.clone().format('YYYY-MM-DD')
  };
}

exports.parse = _parse;
function _format(item) {
  return {
    id: item.id,
    text: item.text,
    date: (0, _moment2.default)(item.date, 'YYYY-MM-DD')
  };
}

exports.format = _format;

var CalendarAgendaResource = function (_RestResource) {
  _inherits(CalendarAgendaResource, _RestResource);

  function CalendarAgendaResource() {
    _classCallCheck(this, CalendarAgendaResource);

    return _possibleConstructorReturn(this, (CalendarAgendaResource.__proto__ || Object.getPrototypeOf(CalendarAgendaResource)).apply(this, arguments));
  }

  _createClass(CalendarAgendaResource, [{
    key: 'init',
    value: function init() {
      _get(CalendarAgendaResource.prototype.__proto__ || Object.getPrototypeOf(CalendarAgendaResource.prototype), 'init', this).call(this);
      this.resourcePath = 'calendar-agendas';
    }
  }, {
    key: 'format',
    value: function format(item) {
      return _format(item);
    }
  }, {
    key: 'parse',
    value: function parse(item) {
      return _parse(item);
    }
  }]);

  return CalendarAgendaResource;
}(_restResource2.default);

exports.default = CalendarAgendaResource;

},{"common/resource/services/restResource":60,"moment":"moment"}],354:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.format = exports.parse = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

var _crewMemberResource = require('pages/crewMembers/services/crewMemberResource');

var _vehicleResource = require('pages/admin/vehicles/services/vehicleResource');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _parse(item) {
  var parsed = {
    id: item.id,
    title: item.title,
    description: item.description,
    start_date: item.start_date.format('YYYY-MM-DD'),
    start_time: item.start_time._d ? item.start_time.format('HH:mm') : null,
    end_date: item.end_date.format('YYYY-MM-DD'),
    end_time: item.end_time._d ? item.end_time.format('HH:mm') : null,
    all_day_event: item.all_day_event ? item.all_day_event : 0,
    complete: item.complete ? item.complete : 0,
    vehicle_id: item.vehicle ? item.vehicle.id : null,
    base_id: item.base ? item.base.id : null
  };

  if (item.crew_members) {
    parsed.crew_members = item.crew_members.map(function (item) {
      return item.id;
    });
  }

  if (item.type) {
    parsed.calendar_event_type_id = item.type.id;
  }

  return parsed;
}

exports.parse = _parse;
function _format(item) {
  var formatted = {
    id: item.id,
    title: item.title,
    description: item.description,
    type: item.type ? item.type.data : null,
    start_date: (0, _moment2.default)(item.start_date, 'YYYY-MM-DD'),
    end_date: (0, _moment2.default)(item.end_date, 'YYYY-MM-DD'),
    start_time: item.start_time ? (0, _moment2.default)(item.start_time, 'HH:mm') : null,
    end_time: item.end_time ? (0, _moment2.default)(item.end_time, 'HH:mm') : null,
    all_day_event: !!item.all_day_event,
    complete: !!item.complete
  };

  if (item.vehicle) {
    formatted.vehicle = (0, _vehicleResource.format)(item.vehicle.data);
  }
  if (item.base) {
    formatted.base = item.base.data;
  }

  if (item.users) {
    formatted.crew_members = item.users.data.map(function (item) {
      return (0, _crewMemberResource.format)(item);
    });
  }

  return formatted;
}

exports.format = _format;

var CalendarEventResource = function (_RestResource) {
  _inherits(CalendarEventResource, _RestResource);

  function CalendarEventResource() {
    _classCallCheck(this, CalendarEventResource);

    return _possibleConstructorReturn(this, (CalendarEventResource.__proto__ || Object.getPrototypeOf(CalendarEventResource)).apply(this, arguments));
  }

  _createClass(CalendarEventResource, [{
    key: 'init',
    value: function init() {
      _get(CalendarEventResource.prototype.__proto__ || Object.getPrototypeOf(CalendarEventResource.prototype), 'init', this).call(this);
      this.resourcePath = 'calendar-events';
    }
  }, {
    key: 'parse',
    value: function parse(item) {
      return _parse(item);
    }
  }, {
    key: 'format',
    value: function format(item) {
      return _format(item);
    }
  }]);

  return CalendarEventResource;
}(_restResource2.default);

exports.default = CalendarEventResource;

},{"common/resource/services/restResource":60,"moment":"moment","pages/admin/vehicles/services/vehicleResource":338,"pages/crewMembers/services/crewMemberResource":362}],355:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var ChangePasswordController = exports.ChangePasswordController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function ChangePasswordController(CrewMemberResource, Session, $state, $mdDialog, APP_ORG) {
    _classCallCheck(this, ChangePasswordController);

    this.CrewMemberResource = CrewMemberResource;
    this.Session = Session;
    this.$state = $state;
    this.$mdDialog = $mdDialog;
    this.APP_ORG = APP_ORG;
  }

  _createClass(ChangePasswordController, [{
    key: 'submit',
    value: function submit(passwords) {
      var _this = this;

      if (this.form.$invalid) {
        return;
      }

      this.CrewMemberResource.changePassword(passwords).then(function () {
        _this.Session.updateShouldChangePassword(false);

        if (_this.Session.user().group.slug === 'charity-staff') {
          _this.$state.go('calendar');
          return;
        }

        _this.$state.go('dashboard');
      }).catch(console.log.bind(console));
    }
  }]);

  return ChangePasswordController;
}();

exports.default = {
  controller: ChangePasswordController,
  templateUrl: 'pages/changePassword/templates/change-password.tpl.html'
};

},{}],356:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _angularUiRouter = require('angular-ui-router');

var _angularUiRouter2 = _interopRequireDefault(_angularUiRouter);

var _changePassword = require('./components/change-password');

var _changePassword2 = _interopRequireDefault(_changePassword);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var moduleConfig = function moduleConfig($stateProvider) {
  'ngInject';

  $stateProvider.state('changePassword', {
    url: '/change-password',
    template: '<change-password></change-password>',
    data: {
      title: 'Change Password'
    }
  });
};

var _module = _angular2.default.module('gnaas.pages.changePassword', [_angularUiRouter2.default]).config(moduleConfig).component('changePassword', _changePassword2.default);

exports.default = _module.name;

},{"./components/change-password":355,"angular":"angular","angular-ui-router":"angular-ui-router"}],357:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

exports.default = {
  bindings: {
    searchQuery: '@',
    items: '<',
    disableSelection: '@',
    onSelect: '&'
  },
  controller: function () {
    function controller() {
      _classCallCheck(this, controller);
    }

    _createClass(controller, [{
      key: '$onChanges',
      value: function $onChanges(changesObj) {
        if (!this.disableSelection && !this.selectedItemId && changesObj.items && changesObj.items.currentValue && changesObj.items.currentValue.length) {
          this.selectedItemId = this.items[0].id;
        }
      }
    }, {
      key: 'selectItem',
      value: function selectItem(item) {
        this.selectedItemId = item.id;
        this.onSelect({ $event: { crewMember: item } });
      }
    }]);

    return controller;
  }(),
  template: function template($element, $attrs) {
    'ngInject';

    var clickEvent = $attrs.onSelect ? 'on-click="$ctrl.selectItem(crewMember)"' : '';

    return '\n      <div ng-repeat="crewMember in $ctrl.items |\n              filter: $ctrl.searchQuery |\n              orderBy:[\'first_name\',\'last_name\']\n              track by $index">\n          <avatar-item class="c-crew-list__item"\n                  ng-class="{\'s-is-selected\': $ctrl.selectedItemId === crewMember.id}"\n                  ' + clickEvent + '\n                  title="{{ crewMember.first_name }} {{ crewMember.last_name }}"\n                  text="{{ crewMember.profession.title }}"\n                  image="{{ crewMember.profile_image.url }}">\n          </avatar-item>\n          <md-divider md-inset ng-if="!$last"></md-divider>\n      </div>\n    ';
  }
};

},{}],358:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  bindings: {
    crewMember: '<',
    onEditButtonClick: '&'
  },
  controller: function controller(Session) {
    'ngInject';

    this.hasPermissionsToEdit = function (crewMember) {
      var user = Session.user();
      return user.group.slug === 'system-administrators';
    };
  },
  templateUrl: 'pages/crewMembers/templates/crewMemberProfile.tpl.html'
};

},{}],359:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CrewMembersController = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _angular = require('angular');

var _addCrewMemberDialog = require('pages/crewMembers/controllers/addCrewMemberDialog');

var _addCrewMemberDialog2 = _interopRequireDefault(_addCrewMemberDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var CrewMembersController = exports.CrewMembersController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function CrewMembersController(CrewMemberResource, ProfessionResource, GroupResource, Toast, Dialog, Session) {
    _classCallCheck(this, CrewMembersController);

    this._CrewMemberResource = CrewMemberResource;
    this._ProfessionResource = ProfessionResource;
    this._GroupResource = GroupResource;
    this._Toast = Toast;
    this._Dialog = Dialog;
    this._Session = Session;
  }

  _createClass(CrewMembersController, [{
    key: '$onInit',
    value: function $onInit() {
      var _this = this;

      this.categories = {};
      this.activeCrewMember = null;
      this.showLoader = true;

      var requestsFinished = 0;
      var requestsRequired = 3;

      this._CrewMemberResource.index({ include: 'group,profile.profession,profile.image' }).then(function (crewMembers) {
        _this.crewMembers = crewMembers;
        _this._sortCrewMembers();
        _this.selectCrewMember({ crewMember: crewMembers[0] });
      }).finally(function () {
        return _this._handleLoadingIndicator(++requestsFinished, requestsRequired);
      });

      this._ProfessionResource.index().then(function (professions) {
        _this.categories.professions = professions;
      }).finally(function () {
        return _this._handleLoadingIndicator(++requestsFinished, requestsRequired);
      });

      this._GroupResource.index().then(function (groups) {
        _this.categories.groups = groups;
      }).finally(function () {
        return _this._handleLoadingIndicator(++requestsFinished, requestsRequired);
      });
    }
  }, {
    key: 'hasPermissionsToAdd',
    value: function hasPermissionsToAdd() {
      var user = this._Session.user();
      if (!user.group) {
        return false;
      }
      return user.group.slug === 'system-administrators';
    }
  }, {
    key: 'selectCrewMember',
    value: function selectCrewMember(_ref) {
      var crewMember = _ref.crewMember;

      if (!crewMember) {
        return;
      }
      var filtered = this.crewMembers.filter(function (_ref2) {
        var id = _ref2.id;
        return id === crewMember.id;
      });
      this.activeCrewMember = filtered[0];
    }
  }, {
    key: 'showCreateDialog',
    value: function showCreateDialog(_ref3) {
      var evt = _ref3.evt,
          crewMember = _ref3.crewMember;

      var isEditing = !!crewMember;

      var dialogParams = {
        controller: _addCrewMemberDialog2.default,
        locals: {
          editing: isEditing,
          crewMember: (0, _angular.copy)(crewMember),
          categories: this.categories
        },
        targetEvent: evt,
        templateUrl: 'pages/crewMembers/templates/addCrewMemberDialog.tpl.html'
      };

      this._Dialog.show(dialogParams).then(this._handleCreateOrUpdate.bind(this, isEditing)).catch(this._handleDelete.bind(this));
    }
  }, {
    key: '_sortCrewMembers',
    value: function _sortCrewMembers() {
      this.crewMembers.sort(function (a, b) {
        if (!a['first_name']) {
          return 1;
        }
        if (!b['first_name']) {
          return -1;
        }
        a = a['first_name'].toLowerCase();
        b = b['first_name'].toLowerCase();
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      });
    }
  }, {
    key: '_handleCreateOrUpdate',
    value: function _handleCreateOrUpdate(isEditing, crewMember) {
      if (isEditing) {
        this.crewMembers = this.crewMembers.map(function (item) {
          return item.id === crewMember.id ? Object.assign({}, item, crewMember) : item;
        });
        this.selectCrewMember({ crewMember: crewMember });
        if (this._Session.isAuthenticatedUser(crewMember.id)) {
          this._Session.setUser(crewMember);
        }
        this._Toast.showSimple('Crew member updated.');
      } else {
        this.crewMembers = [crewMember].concat(_toConsumableArray(this.crewMembers));
        this._Toast.showSimple('New crew member created.');
      }
      this._sortCrewMembers();
    }
  }, {
    key: '_handleDelete',
    value: function _handleDelete(crewMemberId) {
      if (!crewMemberId) {
        return;
      }
      this.crewMembers = this.crewMembers.filter(function (_ref4) {
        var id = _ref4.id;
        return id !== crewMemberId;
      });
      this.selectCrewMember({ crewMember: this.crewMembers[0] });
      this._Toast.showSimple('Crew member deleted.');
    }
  }, {
    key: '_handleLoadingIndicator',
    value: function _handleLoadingIndicator(requestsFinished, requestsRequired) {
      if (requestsFinished === requestsRequired) {
        this.showLoader = false;
      }
    }
  }]);

  return CrewMembersController;
}();

;

exports.default = {
  controller: CrewMembersController,
  templateUrl: 'pages/crewMembers/templates/crewMembers.tpl.html'
};

},{"angular":"angular","pages/crewMembers/controllers/addCrewMemberDialog":360}],360:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = CreateCrewMemberController;

var _nodeUuid = require('node-uuid');

var _nodeUuid2 = _interopRequireDefault(_nodeUuid);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function CreateCrewMemberController(Dialog, CrewMemberResource, Toast, $timeout, Session) {
  'ngInject';

  var _this = this;

  var ID = _nodeUuid2.default.v4();

  var create = function create(data, image) {
    _this.isUploading = true;
    CrewMemberResource.create(data).then(function () {
      if (!image) {
        Dialog.hide(data);
        return;
      }
      return _this._uploadProfilePicture(image, data);
    }).catch(function () {
      Toast.showSimple('Error while creating a crew member.');
    });
  };

  var update = function update(data, image) {
    CrewMemberResource.update(data.id, data, { include: 'group,profile.profession,profile.image' }).then(function (crewMember) {
      if (!image) {
        Dialog.hide(crewMember);
        return;
      }
      return _this._uploadProfilePicture(image, crewMember);
    }).catch(function () {
      Toast.showSimple('Error while creating a crew member.');
    });
  };

  if (!this.crewMember) {
    this.crewMember = {
      profile_active: true,
      user_active: true
    };
  }

  this._uploadProfilePicture = function (image, crewMember) {
    var _this2 = this;

    this.isUploading = true;
    return CrewMemberResource.uploadProfileImage(image, crewMember.id, {
      id: _nodeUuid2.default.v4(),
      imageable_id: crewMember.id
    }).then(function (newCrewMember) {
      _this2.isUploading = false;
      Dialog.hide(newCrewMember);
    }, function () {
      $timeout(function () {
        Toast.showSimple('Error while uploading profile photo.');
      }, 750);
      Dialog.hide(crewMember);
    }, function (value) {
      _this2.profileImagePercentUploaded = value.loaded / value.total * 100;
    });
  };

  this.isCurrentUser = function (id) {
    return Session.isAuthenticatedUser(id);
  };

  this.submit = function (crewMember) {
    if (this.form.$invalid) {
      return;
    }

    if (this.editing) {
      update(crewMember, this.uploadImage);
    } else {
      crewMember.id = ID;
      create(crewMember, this.uploadImage);
    }
  };

  this.cancel = function () {
    Dialog.cancel();
  };

  this.delete = function (id) {
    Dialog.confirm('Are you sure you want to delete this crew member?').then(function () {
      CrewMemberResource.destroy(id).then(function () {
        Dialog.cancel(id);
      });
    });
  };
};

},{"node-uuid":"node-uuid"}],361:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _angularUiRouter = require('angular-ui-router');

var _angularUiRouter2 = _interopRequireDefault(_angularUiRouter);

var _crewMembers = require('./components/crewMembers');

var _crewMembers2 = _interopRequireDefault(_crewMembers);

var _crewMemberList = require('./components/crewMemberList');

var _crewMemberList2 = _interopRequireDefault(_crewMemberList);

var _crewMemberProfile = require('./components/crewMemberProfile');

var _crewMemberProfile2 = _interopRequireDefault(_crewMemberProfile);

var _crewMemberResource = require('./services/crewMemberResource');

var _crewMemberResource2 = _interopRequireDefault(_crewMemberResource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var moduleConfig = function moduleConfig($stateProvider) {
  'ngInject';

  $stateProvider.state('crewMembers', {
    url: '/crew-members',
    template: '<crew-members layout="column" flex></crew-members>',
    data: {
      title: 'Crew Members'
    }
  });
};

var _module = _angular2.default.module('gnaas.pages.crewMembers', [_angularUiRouter2.default]).config(moduleConfig).component('crewMembers', _crewMembers2.default).component('crewMemberList', _crewMemberList2.default).component('crewMemberProfile', _crewMemberProfile2.default).service('CrewMemberResource', _crewMemberResource2.default);

exports.default = _module.name;

},{"./components/crewMemberList":357,"./components/crewMemberProfile":358,"./components/crewMembers":359,"./services/crewMemberResource":362,"angular":"angular","angular-ui-router":"angular-ui-router"}],362:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.format = exports.parse = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _restResource = require('common/resource/services/restResource');

var _restResource2 = _interopRequireDefault(_restResource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; } /* global FormData */


function _parse(item) {
  var parsed = {
    id: item.id || null,
    email: item.email,
    first_name: item.first_name,
    last_name: item.last_name,
    phone_no: item.phone_no,
    mobile_no: item.mobile_no,
    registration_number: item.registration_number
  };

  if (typeof item.user_active !== 'undefined' && item.user_active !== null) {
    parsed.user_active = item.user_active ? 1 : 0;
  } else {
    parsed.user_active = 1;
  }

  if (typeof item.profile_active !== 'undefined' && item.profile_active !== null) {
    parsed.profile_active = item.profile_active ? 1 : 0;
  } else {
    parsed.profile_active = 1;
  }

  if (item.password) {
    parsed.password = item.password;
  }

  if (item.group) {
    parsed.group_id = item.group.id;
  }

  if (item.profession) {
    parsed.profession_id = item.profession.id;
  }

  return parsed;
}

exports.parse = _parse;
function _format(item) {
  var formatted = {
    id: item.id,
    email: item.email,
    user_active: !!item.active,
    should_change_password: !!item.should_change_password
  };

  if (item.profile) {
    formatted.first_name = item.profile.data.first_name;
    formatted.last_name = item.profile.data.last_name;
    formatted.phone_no = item.profile.data.phone_no;
    formatted.mobile_no = item.profile.data.mobile_no;
    formatted.registration_number = item.profile.data.registration_number;
    formatted.profile_active = !!item.profile.data.active;

    if (item.profile.data.profession) {
      formatted.profession = item.profile.data.profession.data;
    }

    if (item.profile.data.image) {
      formatted.profile_image = item.profile.data.image.data;
    }
  }

  if (item.group) {
    formatted.group = item.group.data;
  }

  if (item.base) {
    formatted.base = item.base;
  }

  return formatted;
}

exports.format = _format;

var CrewMemberResource = function (_RestResource) {
  _inherits(CrewMemberResource, _RestResource);

  function CrewMemberResource() {
    _classCallCheck(this, CrewMemberResource);

    return _possibleConstructorReturn(this, (CrewMemberResource.__proto__ || Object.getPrototypeOf(CrewMemberResource)).apply(this, arguments));
  }

  _createClass(CrewMemberResource, [{
    key: 'init',
    value: function init() {
      _get(CrewMemberResource.prototype.__proto__ || Object.getPrototypeOf(CrewMemberResource.prototype), 'init', this).call(this);
      this.resourcePath = 'users';
    }
  }, {
    key: 'parse',
    value: function parse(item) {
      return _parse(item);
    }
  }, {
    key: 'format',
    value: function format(item) {
      return _format(item);
    }
  }, {
    key: 'changePassword',
    value: function changePassword(passwords) {
      return this._http.post(this.apiUrl + '/' + this.resourcePath + '/change-password', passwords).then(function (response) {
        return response.data.data;
      });
    }
  }, {
    key: 'uploadProfileImage',
    value: function uploadProfileImage(image, id, data) {
      var url = '' + this.apiUrl + this.resourcePath + '/' + id + '/profile-image';
      var deferred = this._q.defer();
      var requestData = Object.assign({}, { image: image }, data);
      this._http({
        method: 'POST',
        url: url,
        headers: {
          'Content-Type': undefined
        },
        uploadEventHandlers: {
          progress: function progress(e) {
            deferred.notify(e);
          }
        },
        data: requestData,
        transformRequest: function transformRequest(data, headersGetter) {
          var formData = new FormData();
          formData.append('id', data.id);
          formData.append('image', data.image);
          formData.append('imageable_id', data.imageable_id);
          formData.append('imageable_type', data.imageable_type);
          return formData;
        }
      }).then(function (response) {
        deferred.resolve(_format(response.data.data));
      }).catch(function (err) {
        deferred.reject(err);
      });

      return deferred.promise;
    }
  }]);

  return CrewMemberResource;
}(_restResource2.default);

exports.default = CrewMemberResource;

},{"common/resource/services/restResource":60}],363:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DashboardController = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _deploymentStatusUpdateResource = require('core/deployments/resources/deployment-status-update.resource.js');

var _module = require('common/resource/module.constants');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function dateToString(date) {
  return (0, _moment2.default)(date || new Date()).format(_module.DATE_FORMAT);
}

var DashboardController = exports.DashboardController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function DashboardController($q, $timeout, DashboardPageService, Dialog, Toast, Utils, IncidentPageService, ShiftCrewDialog, ShiftService, DeploymentStatusUpdateDialog, VehicleCallsignStatusUpdateDialog, APP_ORG_SHORT, Session) {
    _classCallCheck(this, DashboardController);

    this._q = $q;
    this._timeout = $timeout;
    this._DashboardPageService = DashboardPageService;
    this._Dialog = Dialog;
    this._Utils = Utils;
    this._Toast = Toast;
    this._IncidentPageService = IncidentPageService;
    this._ShiftCrewDialog = ShiftCrewDialog;
    this._ShiftService = ShiftService;
    this._DeploymentStatusUpdateDialog = DeploymentStatusUpdateDialog;
    this._VehicleCallsignStatusUpdateDialog = VehicleCallsignStatusUpdateDialog;
    this.APP_ORG_SHORT = APP_ORG_SHORT;
    this._Session = Session;
  }

  _createClass(DashboardController, [{
    key: '$onInit',
    value: function $onInit() {
      this.today = dateToString();
      this.date = dateToString();
      this.dateString = 'Today';
      this._deploymentRelationData = {};
      this._vehicleCallsignRelationData = {};
      this._updateHeaderTimeout = null;

      this._updateHeaderTime();
      this._loadDashboardData();
      this._loadRelationData();
      this._setPermissions();
    }
  }, {
    key: '$onDestroy',
    value: function $onDestroy() {
      this._timeout.cancel(this._updateHeaderTimeout);
    }
  }, {
    key: 'isAfterToday',
    value: function isAfterToday(date) {
      return (0, _moment2.default)(date).startOf('day').isAfter((0, _moment2.default)(this.today).startOf('day'));
    }
  }, {
    key: 'showStatusUpdateDialog',
    value: function showStatusUpdateDialog(_ref) {
      var _this = this;

      var $event = _ref.$event,
          item = _ref.item;

      var deployments = item.deployments.map(function (deployment) {
        deployment.name = deployment.vehicle_callsign.name;
        return deployment;
      });

      var dialogParams = {
        $event: $event,
        relationData: this._deploymentRelationData,
        locals: {
          deployments: deployments
        }
      };

      this._DeploymentStatusUpdateDialog.show(dialogParams).then(function (status) {
        deployments.some(function (item) {
          if (item.id !== status.deployment.id) {
            return false;
          }

          var i = _this._getIndex(item.vehicle_callsign, _this.vehicleCallsigns);
          var currentStatusTime = (0, _moment2.default)(_this.vehicleCallsigns[i].time);
          var newStatusTime = status.time;

          if (newStatusTime.isAfter(currentStatusTime)) {
            _this.vehicleCallsigns[i].lastDeployment = _this._getVehicleCallsignDeploymentStatus(status);
          }

          item.deployment_statuses.push(status);

          return true;
        });

        _this._Toast.showSimple('Deployment status updated.');
      });
    }

    /**
     * If date is passed in explicitly - it overrides data.time property if present.
     * Otherwise data.time is used if found, if not - current date.
     */

  }, {
    key: 'showVehicleCallsignStatusUpdateDialog',
    value: function showVehicleCallsignStatusUpdateDialog(_ref2) {
      var _this2 = this;

      var $event = _ref2.$event,
          data = _ref2.data,
          date = _ref2.date,
          vehicleCallsign = _ref2.vehicleCallsign;

      if (!date) {
        date = data ? data.time : new Date();
      }
      var time = (0, _moment2.default)(date).seconds(0).toDate();

      var dialogParams = {
        $event: $event,
        relationData: this._vehicleCallsignRelationData
      };

      if (data && data.id) {
        dialogParams.item = {
          id: data.id,
          online: data.online ? 1 : 0,
          offline_reason: data.offlineReason,
          time: time,
          vehicleCallsign: data.vehicleCallsign
        };
      } else {
        dialogParams.item = { time: time, vehicleCallsign: vehicleCallsign };
        // Override editing, as passing in an item into ResourceDialog defaults editing to true
        dialogParams.locals = { editing: false };
      }

      this._VehicleCallsignStatusUpdateDialog.show(dialogParams).then(function (status) {
        if (!status) return;
        var i = _this2._getIndex(status, _this2.vehicleCallsignStatusUpdates);
        if (~i) {
          _this2.vehicleCallsignStatusUpdates[i] = status;
        } else {
          _this2.vehicleCallsignStatusUpdates = [].concat(_toConsumableArray(_this2.vehicleCallsignStatusUpdates), [status]);
        }
        _this2._Toast.showSimple('Vehicle status updated.');
      }).catch(function (id) {
        var i = _this2._getIndex({ id: id }, _this2.vehicleCallsignStatusUpdates);
        if (~i) {
          _this2.vehicleCallsignStatusUpdates.splice(i, 1);
          _this2._Toast.showSimple('Vehicle status deleted.');
        }
      }).finally(function () {
        _this2._generateTimeline();
        if (_this2.date === _this2.today) {
          _this2._DashboardPageService.getVehicleCallsign(dialogParams.item.vehicleCallsign.id).then(function (data) {
            _this2._updateVehicleCallsignStatus(data.id, data);
          });
        }
      });
    }
  }, {
    key: 'currentDay',
    value: function currentDay() {
      if (this.date === this.today) {
        return;
      }
      this.date = dateToString();
      this.dateString = 'Today';
      this._loadDay(this.date);
    }
  }, {
    key: 'changeDay',
    value: function changeDay(date) {
      if (dateToString(date) === this.date) {
        return;
      } else if (dateToString(date) === this.today) {
        this.currentDay();
        return;
      }
      this.date = dateToString(date);
      this.dateString = (0, _moment2.default)(date).format('DD-MM-YYYY').toString();
      this._loadDay(this.date);
    }
  }, {
    key: 'showShiftCrewDialog',
    value: function showShiftCrewDialog($event, shift) {
      this._ShiftCrewDialog.show({
        $event: $event,
        locals: {
          shift: shift,
          crewMembers: this.crewMembers,
          date: shift.start_time,
          closeOnSubmit: true
        }
      }).then(function (shiftCrew) {
        if (!(0, _moment2.default)(shift.start_time).isSame(shiftCrew.date, 'date')) return;
        shift.users = shiftCrew.users.map(function (i) {
          return Object.assign({}, i, { name: i.first_name + ' ' + i.last_name });
        });
      });
    }
  }, {
    key: '_loadDay',
    value: function _loadDay(date) {
      var _this3 = this;

      this._DashboardPageService.getCalendarEvents(date).then(function (data) {
        return _this3.calendarEvents = data;
      });
      this._DashboardPageService.getCalendarAgenda(date).then(function (data) {
        return _this3.calendarAgenda = data;
      });
      this.shifts = [];
      this._ShiftService.getShiftsForDate(date).then(function (data) {
        return _this3.shifts = [].concat(_toConsumableArray(_this3.shifts), _toConsumableArray(data));
      });
      this._ShiftService.getOvernightShiftsForDate(date).then(function (data) {
        var overnightShifts = data.map(function (shift) {
          return Object.assign({}, shift, { name: shift.name + ' (previous day)' });
        });
        _this3.shifts = [].concat(_toConsumableArray(_this3.shifts), _toConsumableArray(overnightShifts));
      });
      this._loadTimelineData(date);
    }
  }, {
    key: '_updateHeaderTime',
    value: function _updateHeaderTime() {
      var _this4 = this;

      this.currentTime = (0, _moment2.default)();
      this.today = dateToString(this.currentTime);
      var secDelay = (60 - this.currentTime.second()) * 1000;

      this._updateHeaderTimeout = this._timeout(function () {
        _this4._updateHeaderTime();
      }, secDelay);
    }
  }, {
    key: '_loadDashboardData',
    value: function _loadDashboardData() {
      var _this5 = this;

      this._loadDay(this.date);
      this._DashboardPageService.getBases().then(function (data) {
        _this5.bases = data;
        if (!_this5._deploymentRelationData.destinations) _this5._deploymentRelationData.destinations = {};
        _this5._deploymentRelationData.destinations.bases = data;
      });
      this._DashboardPageService.getCrewMembers().then(function (data) {
        return _this5.crewMembers = data;
      });
      this._DashboardPageService.getVehicleCallsigns().then(function (data) {
        _this5.vehicleCallsigns = data.map(_this5._getVehicleCallsignStatus);
        _this5._deploymentRelationData.vehicleCallsigns = data;
      });
    }
  }, {
    key: '_getVehicleCallsignStatus',
    value: function _getVehicleCallsignStatus(item) {
      var status = item.latest_status_update;
      if (status) {
        var destination = status.destination ? ' - ' + status.destination.name : '';
        item.latestStatus = status.deployment_status.name + destination;
      } else {
        item.latestStatus = '-';
      }
      return item;
    }
  }, {
    key: '_loadTimelineData',
    value: function _loadTimelineData(date) {
      var _this6 = this;

      this._q.all({
        incidents: this._DashboardPageService.getIncidents(date),
        vehicleCallsignStatusUpdates: this._DashboardPageService.getVehicleCallsignStatusUpdates(date)
      }).then(function (data) {
        _this6._assignData(['incidents', 'vehicleCallsignStatusUpdates'], data);
        _this6._generateTimeline();
      });
    }
  }, {
    key: '_assignData',
    value: function _assignData(assignables, data) {
      var assignableData = {};
      Object.keys(data).filter(function (item) {
        if (assignables.indexOf(item) > -1) {
          assignableData[item] = data[item];
        }
      });
      Object.assign(this, assignableData);
    }
  }, {
    key: '_generateTimeline',
    value: function _generateTimeline() {
      var _this7 = this;

      this.timelineEvents = [];

      this.incidents.forEach(function (incident) {
        incident.outcome = _this7._IncidentPageService.getIncidentOutcome(incident);
        _this7.timelineEvents.push({
          item: incident,
          type: 'incident',
          time: incident.times.call.time
        });
      });

      this.vehicleCallsignStatusUpdates.forEach(function (status) {
        _this7.timelineEvents.push({
          item: status,
          type: 'callsign_status_update',
          time: status.time
        });
      });

      this.timelineEvents.sort(function (a, b) {
        return b.time - a.time;
      });
    }
  }, {
    key: '_loadRelationData',
    value: function _loadRelationData() {
      var _this8 = this;

      this._DashboardPageService.getDeploymentRelationData().then(function (data) {
        if (_this8._deploymentRelationData.destinations) {
          Object.assign(data.destinations, _this8._deploymentRelationData.destinations);
        }
        Object.assign(_this8._deploymentRelationData, data);
      });
      this._DashboardPageService.getVehicleCallsignRelationData().then(function (data) {
        Object.assign(_this8._vehicleCallsignRelationData, data);
      });
    }
  }, {
    key: '_getVehicleCallsignDeploymentStatus',
    value: function _getVehicleCallsignDeploymentStatus(status) {
      status.deployment = { data: status.deployment };
      var newStatus = (0, _deploymentStatusUpdateResource.format)(status);
      newStatus.deploymentStatus = status.deployment_status;
      newStatus.destination = status.destination;
      return newStatus;
    }
  }, {
    key: '_updateVehicleCallsignStatus',
    value: function _updateVehicleCallsignStatus(callsignId, status) {
      this.vehicleCallsigns = this.vehicleCallsigns.map(function (item) {
        if (callsignId === item.id) {
          item.online = status.online;
          item.statusString = item.online ? 'Online' : 'Offline';
        }
        return item;
      });
    }
  }, {
    key: '_getIndex',
    value: function _getIndex(item, list) {
      return list.indexOf(this._Utils.findObjectInArrayById(list, item.id));
    }

    // PERMISSIONS

  }, {
    key: '_setPermissions',
    value: function _setPermissions() {
      var isCoreUser = this._userBelongsToGroups(['system-administrators', 'medical-staff', 'medical-staff-prescribers', 'ops-admins', 'pilots', 'call-handlers']);

      this.userCanSetShiftCrew = isCoreUser;
      this.userCanUpdateVehicleStatuses = isCoreUser;
    }
  }, {
    key: '_userBelongsToGroups',
    value: function _userBelongsToGroups(groups) {
      var user = this._Session.user();
      if (!user || !user.group) return false;
      if (typeof groups === 'string') groups = [groups];
      return ~groups.indexOf(user.group.slug);
    }
  }]);

  return DashboardController;
}();

;

exports.default = {
  templateUrl: 'pages/dashboard/templates/dashboard-page.tpl.html',
  controller: DashboardController
};

},{"common/resource/module.constants":57,"core/deployments/resources/deployment-status-update.resource.js":124,"moment":"moment"}],364:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _angularUiRouter = require('angular-ui-router');

var _angularUiRouter2 = _interopRequireDefault(_angularUiRouter);

var _dashboardPage = require('./components/dashboard-page');

var _dashboardPage2 = _interopRequireDefault(_dashboardPage);

var _dashboardPage3 = require('./services/dashboard-page.service');

var _dashboardPage4 = _interopRequireDefault(_dashboardPage3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var moduleConfig = function moduleConfig($stateProvider) {
  'ngInject';

  $stateProvider.state('dashboard', {
    url: '/dashboard',
    template: '<dashboard-page flex layout="column"></dashboard-page>',
    data: {
      title: 'Dashboard'
    }
  });
};

var _module = _angular2.default.module('gnaas.pages.dashboard', [_angularUiRouter2.default]).config(moduleConfig).component('dashboardPage', _dashboardPage2.default).service('DashboardPageService', _dashboardPage4.default);

exports.default = _module.name;

},{"./components/dashboard-page":363,"./services/dashboard-page.service":365,"angular":"angular","angular-ui-router":"angular-ui-router"}],365:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var DashboardPageService = function () {
  /**
   * @constructor
   * @ngInject
   */
  function DashboardPageService($q, BaseResource, CalendarAgendaResource, CalendarEventResource, CrewMemberResource, DeploymentStatusResource, HospitalResource, IncidentResource, LocationResource, OfflineReasonResource, VehicleCallsignResource, VehicleCallsignStatusUpdateResource) {
    _classCallCheck(this, DashboardPageService);

    this._q = $q;
    this._BaseResource = BaseResource;
    this._CalendarAgendaResource = CalendarAgendaResource;
    this._CalendarEventResource = CalendarEventResource;
    this._CrewMemberResource = CrewMemberResource;
    this._DeploymentStatusResource = DeploymentStatusResource;
    this._HospitalResource = HospitalResource;
    this._IncidentResource = IncidentResource;
    this._LocationResource = LocationResource;
    this._OfflineReasonResource = OfflineReasonResource;
    this._VehicleCallsignResource = VehicleCallsignResource;
    this._VehicleCallsignStatusUpdateResource = VehicleCallsignStatusUpdateResource;
  }

  _createClass(DashboardPageService, [{
    key: 'getDeploymentRelationData',
    value: function getDeploymentRelationData() {
      return this._q.all({
        deploymentStatuses: this._DeploymentStatusResource.index(),
        hospitals: this._HospitalResource.index(),
        locations: this._LocationResource.index()
      }).then(function (relationData) {
        return {
          deploymentStatuses: relationData.deploymentStatuses,
          destinations: {
            hospitals: relationData.hospitals,
            locations: relationData.locations
          }
        };
      });
    }
  }, {
    key: 'getVehicleCallsignRelationData',
    value: function getVehicleCallsignRelationData() {
      return this._OfflineReasonResource.index().then(function (relationData) {
        return {
          offlineReasons: relationData,
          statuses: {
            'Offline': 0,
            'Online': 1
          }
        };
      });
    }
  }, {
    key: 'getCrewMembers',
    value: function getCrewMembers() {
      return this._CrewMemberResource.index({
        filter: 'operational-crew',
        include: 'group,profile.profession,profile.image'
      }).then(function (data) {
        return data.map(function (item) {
          return Object.assign({}, item, { name: item.first_name + ' ' + item.last_name });
        });
      });
    }
  }, {
    key: 'getCalendarEvents',
    value: function getCalendarEvents(date) {
      return this._CalendarEventResource.index({
        include: 'type,vehicle,users',
        start_date: date,
        end_date: date
      });
    }
  }, {
    key: 'getCalendarAgenda',
    value: function getCalendarAgenda(date) {
      return this._CalendarAgendaResource.index({
        start_date: date,
        end_date: date
      }).then(function (agendas) {
        return agendas.length ? agendas[0] : null;
      });
    }
  }, {
    key: 'getIncidents',
    value: function getIncidents(date) {
      return this._IncidentResource.index({
        include: ['serviceArea', 'refusalReason', 'ambulanceService', 'deploymentReason', 'deployments.vehicleCallsign', 'deployments.deploymentStatuses', 'deployments.deploymentStatuses.deploymentStatus', 'deployments.patientReportForms'],
        start_date: date,
        end_date: date
      });
    }
  }, {
    key: 'getBases',
    value: function getBases() {
      return this._BaseResource.index();
    }
  }, {
    key: 'getVehicleCallsigns',
    value: function getVehicleCallsigns() {
      return this._VehicleCallsignResource.index({
        include: ['vehicle', 'deploymentStatusUpdates:limit(1):order(time)', 'deploymentStatusUpdates.deploymentStatus', 'deploymentStatusUpdates.destination']
      });
    }
  }, {
    key: 'getVehicleCallsign',
    value: function getVehicleCallsign(id) {
      return this._VehicleCallsignResource.show(id);
    }
  }, {
    key: 'getVehicleCallsignStatusUpdates',
    value: function getVehicleCallsignStatusUpdates(date) {
      return this._VehicleCallsignStatusUpdateResource.index({
        start_date: date,
        end_date: date
      });
    }
  }]);

  return DashboardPageService;
}();

exports.default = DashboardPageService;

},{}],366:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LoginController = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _forgottenPasswordDialog = require('pages/login/controllers/forgotten-password-dialog.controller');

var _forgottenPasswordDialog2 = _interopRequireDefault(_forgottenPasswordDialog);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var LoginController = exports.LoginController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function LoginController($state, AuthResource, CrewMemberResource, JWT, IdleCheck, Session, Toast, Dialog, APP_ORG) {
    _classCallCheck(this, LoginController);

    this._state = $state;
    this._AuthResource = AuthResource;
    this._CrewMemberResource = CrewMemberResource;
    this._JWT = JWT;
    this._IdleCheck = IdleCheck;
    this._Session = Session;
    this._Toast = Toast;
    this._Dialog = Dialog;
    this.APP_ORG = APP_ORG;
  }

  _createClass(LoginController, [{
    key: 'login',
    value: function login(email, password) {
      var _this = this;

      if (this.form.$invalid) {
        return;
      }

      this._AuthResource.login(email, password).then(function (data) {
        _this._JWT.set(data.meta.token);
        _this._Session.start(_this._CrewMemberResource.format(data.data));
        _this._IdleCheck.start();

        if (data.data.should_change_password === true) {
          _this._state.go('changePassword');
          return;
        }

        if (_this._Session.user().group.slug === 'charity-staff') {
          _this._state.go('calendar');
          return;
        }

        if (_this._state.params.previousState) {
          _this._state.go(_this._state.params.previousState.name, _this._state.params.previousState.params);
        } else {
          _this._state.go('dashboard');
        }
      }).catch(function () {
        _this._Toast.showSimple('Invalid username/password!');
      });
    }
  }, {
    key: 'showPasswordResetDialog',
    value: function showPasswordResetDialog($event) {
      var _this2 = this;

      var dialogParams = {
        controller: _forgottenPasswordDialog2.default,
        item: {
          email: this.credentials ? this.credentials.email : ''
        },
        targetEvent: $event,
        templateUrl: 'pages/login/templates/forgotten-password.tpl.html'
      };

      this._Dialog.show(dialogParams).then(function (item) {
        return _this2._Toast.showSimple('Password reset email sent.');
      });
    }
  }]);

  return LoginController;
}();

exports.default = {
  controller: LoginController,
  templateUrl: 'pages/login/templates/login.tpl.html'
};

},{"pages/login/controllers/forgotten-password-dialog.controller":367}],367:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var ForgottenPasswordController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function ForgottenPasswordController(Dialog, $http, API_URL) {
    _classCallCheck(this, ForgottenPasswordController);

    this._Dialog = Dialog;
    this._http = $http;
    this._apiUrl = API_URL;

    this.init();
  }

  _createClass(ForgottenPasswordController, [{
    key: 'init',
    value: function init() {
      this.resourcePath = 'password/email';
    }
  }, {
    key: 'create',
    value: function create(data) {
      var _this = this;

      this._http.post('' + this._apiUrl + this.resourcePath, { email: data.email }).then(function () {
        return _this._Dialog.hide(data.email);
      }).catch(console.log.bind(console));
    }
  }, {
    key: 'submit',
    value: function submit(data) {
      if (this.form.$invalid) {
        return;
      }

      this.create(data);
    }
  }, {
    key: 'cancel',
    value: function cancel() {
      this._Dialog.cancel();
    }
  }]);

  return ForgottenPasswordController;
}();

exports.default = ForgottenPasswordController;

},{}],368:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _angularUiRouter = require('angular-ui-router');

var _angularUiRouter2 = _interopRequireDefault(_angularUiRouter);

var _login = require('./components/login');

var _login2 = _interopRequireDefault(_login);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var moduleConfig = function moduleConfig($stateProvider) {
  'ngInject';

  $stateProvider.state('login', {
    url: '/',
    template: '<login></login>',
    data: {
      title: 'Login'
    },
    params: {
      previousState: null
    }
  });
};

var _module = _angular2.default.module('gnaas.pages.login', [_angularUiRouter2.default]).config(moduleConfig).component('login', _login2.default);

exports.default = _module.name;

},{"./components/login":366,"angular":"angular","angular-ui-router":"angular-ui-router"}],369:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MapController = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _gridRefToCoords = require('common/utils/services/gridRefToCoords');

var _map = require('common/map/services/map');

var _map2 = _interopRequireDefault(_map);

var _mapMarkerIcon = require('common/map/services/mapMarkerIcon');

var _mapMarkerIcon2 = _interopRequireDefault(_mapMarkerIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var MARKER_SIDENAV = 'sidenav-map-markers';

var MapController = exports.MapController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function MapController($timeout, $mdSidenav, MapService, Dialog, Location, BING_MAPS_KEY) {
    _classCallCheck(this, MapController);

    this._timeout = $timeout;
    this._mdSidenav = $mdSidenav;
    this._MapService = MapService;
    this._Dialog = Dialog;
    this._Location = Location;
    this._BING_MAPS_KEY = BING_MAPS_KEY;
  }

  _createClass(MapController, [{
    key: '$onInit',
    value: function $onInit() {
      this.map = null;
      this.mapMarkers = [];
      this.vehicleMarkers = [];
      this.incidentMarker = null;
      this.sidebarOpen = false;
      this.routeDistance = null;
      this._incidentMarkerOpts = { icon: _mapMarkerIcon2.default.predefined('incident') };
      this._routePlanningActive = false;
      this._vehicleUpdateTimer = null;
    }
  }, {
    key: '$postLink',
    value: function $postLink() {
      var _this = this;

      this._timeout(function () {
        _this.map = new _map2.default('map', { bingMapsKey: _this._BING_MAPS_KEY });
        _this._loadMapMarkers();
      });
    }
  }, {
    key: '$onDestroy',
    value: function $onDestroy() {
      this.map.destroy();
      this.map = null;
      this._timeout.cancel(this._vehicleUpdateTimer);
    }

    /**
     * Incidents
     */

  }, {
    key: 'findIncidentLocation',
    value: function findIncidentLocation(location) {
      var _this2 = this;

      this._disableDropIncidentMarker();
      this._Location.resolveCoordinates(location).then(this._setMarker.bind(this)).catch(function (e) {
        _this2._Dialog.alert(e.message, 'Error');
      });
    }
  }, {
    key: '_setMarker',
    value: function _setMarker(coords) {
      if (this.incidentMarker) {
        this._clearIncidentMarker();
      }
      this._createIncidentMarker([coords.lat, coords.lng]);
    }
  }, {
    key: 'toggleDropIncidentMarker',
    value: function toggleDropIncidentMarker() {
      if (this.dropToggleEnabled) {
        this._disableDropIncidentMarker();
      } else {
        this._enableDropIncidentMarker();
      }
    }
  }, {
    key: 'clearIncidentLocation',
    value: function clearIncidentLocation() {
      if (this.incidentMarker) {
        this._clearIncidentMarker();
        if (this.location) {
          this.location = null;
        }
      }
    }
  }, {
    key: '_enableDropIncidentMarker',
    value: function _enableDropIncidentMarker() {
      this.dropToggleEnabled = true;
      this._boundDropMarkerOnClickHandler = this._handleDropIncidentMarker.bind(this);
      this.map.addEventListener('click', this._boundDropMarkerOnClickHandler);
    }
  }, {
    key: '_disableDropIncidentMarker',
    value: function _disableDropIncidentMarker() {
      this.dropToggleEnabled = false;
      if (this._boundDropMarkerOnClickHandler) {
        this.map.removeEventListener('click', this._boundDropMarkerOnClickHandler);
        delete this._boundDropMarkerOnClickHandler;
      }
    }
  }, {
    key: '_handleDropIncidentMarker',
    value: function _handleDropIncidentMarker(e) {
      var _this3 = this;

      this._disableDropIncidentMarker();
      this._timeout(function () {
        _this3._createIncidentMarker(e.latlng);
        _this3._setLocation(e.latlng);
      });
    }
  }, {
    key: '_createIncidentMarker',
    value: function _createIncidentMarker(latLng) {
      var _this4 = this;

      var opts = Object.assign({}, this._incidentMarkerOpts, { draggable: true });
      this.incidentMarker = this.map.addMarker(latLng, opts);
      this.map.panTo(latLng);
      this.incidentMarker.on('dragend', function (e) {
        _this4._timeout(function () {
          _this4._setLocation(e.target._latlng);
        });
      });
      this._calculateMarkerDistances();
    }
  }, {
    key: '_calculateMarkerDistances',
    value: function _calculateMarkerDistances() {
      var _this5 = this;

      this.mapMarkers.forEach(function (marker) {
        var distance = _this5.incidentMarker._latlng.distanceTo(marker.marker._latlng);
        marker.distance = _this5._metresToNauticalMiles(distance);
      });
    }
  }, {
    key: '_clearIncidentMarker',
    value: function _clearIncidentMarker() {
      this.map.removeMarker(this.incidentMarker);
      this.incidentMarker = null;
      this._clearMarkerDistances();
    }
  }, {
    key: '_clearMarkerDistances',
    value: function _clearMarkerDistances() {
      this.mapMarkers.forEach(function (marker) {
        marker.distance = null;
      });
    }
  }, {
    key: '_setLocation',
    value: function _setLocation(coords) {
      var grid = (0, _gridRefToCoords.CoordsToGridRef)(coords);
      this.location = grid.square + ' ' + grid.reference;
    }

    /**
     * Route planning
     */

  }, {
    key: 'toggleRoutePlanning',
    value: function toggleRoutePlanning() {
      if (this._routePlanningActive) {
        this.map.enableRoutePlanning(this._routePlanningCallback.bind(this));
      } else {
        this.map.disableRoutePlanning();
        this.routeDistance = null;
      }
    }
  }, {
    key: 'clearRoute',
    value: function clearRoute() {
      var _this6 = this;

      this._timeout(function () {
        _this6.map.clearRoute();
        _this6.routeDistance = null;
      });
    }
  }, {
    key: 'routePlanningInProgress',
    value: function routePlanningInProgress() {
      return this.routeDistance !== null;
    }
  }, {
    key: '_routePlanningCallback',
    value: function _routePlanningCallback() {
      var _this7 = this;

      this._timeout(function () {
        _this7.routeDistance = _this7._metresToNauticalMiles(_this7.map.getRouteDistance());
      });
    }

    /**
     * Map markers
     */

  }, {
    key: 'onMarkerFilterUpdate',
    value: function onMarkerFilterUpdate(visibleMarkers) {
      this.mapMarkers.forEach(function (item) {
        if (visibleMarkers.indexOf(item.type) > -1) {
          item.show = true;
          item.marker._icon.style.display = null;
        } else {
          item.show = false;
          item.marker._icon.style.display = 'none';
        }
      });
    }
  }, {
    key: '_loadMapMarkers',
    value: function _loadMapMarkers() {
      var _this8 = this;

      this.showLoader = true;
      this._MapService.getMarkerData().then(function (markerData) {
        _this8._addBaseMarkers(markerData.bases);
        _this8._addHospitalMarkers(markerData.hospitals);
        _this8._addLocationMarkers(markerData.locations);

        _this8._addVehicleMarkers(markerData.vehicles);
        _this8._vehicleUpdateTimer = _this8._timeout(function () {
          _this8._addVehicleMarkers(markerData.vehicles);
        }, 60000);
      }).finally(function () {
        return _this8.showLoader = false;
      });
    }
  }, {
    key: '_addBaseMarkers',
    value: function _addBaseMarkers(locations) {
      var _this9 = this;

      var opts = { icon: _mapMarkerIcon2.default.predefined('base') };

      Array.prototype.forEach.call(locations, function (location) {
        var popup = _this9._getMarkerPopup(location.name);

        _this9.mapMarkers.push({
          location: location,
          marker: _this9.map.addMarker([location.lat, location.lng], opts, popup),
          type: 'base',
          show: true
        });
      });
    }
  }, {
    key: '_getMarkerPopup',
    value: function _getMarkerPopup(name, specialties) {
      var open = '<div class="map-popup"><div class="map-popup__header">[header]</div>';
      var close = '</div>';

      var result = open.replace('[header]', name);
      if (specialties && specialties.length) {
        var content = '<div class="map-popup__content">\n        <div class="map-popup__content-label">Hospital specialties</div>\n        <ul>[specialties]</ul>\n      </div>';
        specialties = specialties.map(function (s) {
          return '<li>' + s.name + '</li>';
        }).join('');
        result += content.replace('[specialties]', specialties);
      }
      result += close;

      return result;
    }
  }, {
    key: '_addHospitalMarkers',
    value: function _addHospitalMarkers(locations) {
      var _this10 = this;

      var opts = { icon: _mapMarkerIcon2.default.predefined('hospital') };

      Array.prototype.forEach.call(locations, function (location) {
        var popup = _this10._getMarkerPopup(location.name, location.specialties);

        _this10.mapMarkers.push({
          location: location,
          marker: _this10.map.addMarker([location.lat, location.lng], opts, popup),
          type: 'hospital',
          show: true
        });
      });
    }
  }, {
    key: '_addLocationMarkers',
    value: function _addLocationMarkers(locations) {
      var _this11 = this;

      var availableIcons = _mapMarkerIcon2.default.getAvailableIcons();

      Array.prototype.forEach.call(locations, function (location) {
        var popup = _this11._getMarkerPopup(location.name);
        var opts = {};
        var type = availableIcons.indexOf(location.location_type.slug) > -1 ? location.location_type.slug : 'generic';

        opts.icon = _mapMarkerIcon2.default.predefined(type);
        _this11.mapMarkers.push({
          location: location,
          marker: _this11.map.addMarker([location.lat, location.lng], opts, popup),
          type: type,
          show: true
        });
      });
    }
  }, {
    key: '_addVehicleMarkers',
    value: function _addVehicleMarkers(vehicleCallsigns) {
      var _this12 = this;

      this._clearVehicleMarkers();
      Array.prototype.forEach.call(vehicleCallsigns, function (vehicleCallsign) {
        if (!vehicleCallsign.lastDeployment) {
          return;
        }

        var opts = { icon: _mapMarkerIcon2.default.predefined(vehicleCallsign.vehicle.typeString.toLowerCase()) };
        var popup = _this12._getMarkerPopup(vehicleCallsign.name);

        _this12.vehicleMarkers.push({
          location: vehicleCallsign,
          marker: _this12.map.addMarker([vehicleCallsign.lastDeployment.destination.lat, vehicleCallsign.lastDeployment.destination.lng], opts, popup),
          type: vehicleCallsign.vehicle.typeString.toLowerCase(),
          show: true
        });
      });
    }
  }, {
    key: '_clearVehicleMarkers',
    value: function _clearVehicleMarkers() {
      var _this13 = this;

      this.vehicleMarkers.map(function (item) {
        return item.marker;
      }).forEach(function (item) {
        _this13.map.removeMarker(item);
      });
      this.vehicleMarkers = [];
    }

    /**
     * Sidebar
     */

  }, {
    key: 'toggleSidebar',
    value: function toggleSidebar() {
      this.sidebarOpen = !this._mdSidenav(MARKER_SIDENAV).isOpen();
      this._mdSidenav(MARKER_SIDENAV).toggle();
    }

    /**
     * Utils
     */

  }, {
    key: '_metresToNauticalMiles',
    value: function _metresToNauticalMiles(metres) {
      return parseFloat((metres * 0.000539957).toFixed(2));
    }
  }]);

  return MapController;
}();

exports.default = {
  templateUrl: 'pages/map/templates/map-page.tpl.html',
  controller: MapController
};

},{"common/map/services/map":46,"common/map/services/mapMarkerIcon":47,"common/utils/services/gridRefToCoords":79}],370:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MapFilterSidenavController = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _mapMarkerIcon = require('common/map/services/mapMarkerIcon');

var _mapMarkerIcon2 = _interopRequireDefault(_mapMarkerIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var MAP_MARKER_FILTER_SIDENAV = 'sidenav-map-marker-filters';

var MapFilterSidenavController = exports.MapFilterSidenavController = function () {
  function MapFilterSidenavController($mdSidenav, LocationTypeResource) {
    'ngInject';

    _classCallCheck(this, MapFilterSidenavController);

    this._mdSidenav = $mdSidenav;
    this._LocationTypeResource = LocationTypeResource;
  }

  _createClass(MapFilterSidenavController, [{
    key: '$onInit',
    value: function $onInit() {
      this._setupFilters();
    }
  }, {
    key: 'close',
    value: function close() {
      this._mdSidenav(MAP_MARKER_FILTER_SIDENAV).close();
    }
  }, {
    key: 'filter',
    value: function filter() {
      var markers = this.filters.filter(function (item) {
        return item.show;
      }).map(function (item) {
        return item.type;
      });
      this.onUpdate({ visibleMarkers: markers });
    }
  }, {
    key: 'clearFilters',
    value: function clearFilters() {
      this.filters.forEach(function (filter) {
        filter.show = true;
      });
      this.filter();
    }
  }, {
    key: '_setupFilters',
    value: function _setupFilters() {
      var _this = this;

      var availableIcons = _mapMarkerIcon2.default.getAvailableIcons();

      this.filters = [{
        name: 'Hospitals',
        icon: _mapMarkerIcon2.default.predefinedUrl('hospital'),
        type: 'hospital',
        show: true
      }, {
        name: 'Bases',
        icon: _mapMarkerIcon2.default.predefinedUrl('base'),
        type: 'base',
        show: true
      }];

      this._LocationTypeResource.index().then(function (locationTypes) {
        _this.filters = _this.filters.concat(locationTypes.map(function (item) {
          var iconType = availableIcons.indexOf(item.slug) > -1 ? item.slug : 'target';
          item.icon = _mapMarkerIcon2.default.predefinedUrl(iconType);
          item.type = item.slug;
          item.show = true;
          return item;
        }));
      });
    }
  }]);

  return MapFilterSidenavController;
}();

exports.default = {
  bindings: {
    onUpdate: '&'
  },
  controller: MapFilterSidenavController,
  templateUrl: 'pages/map/templates/mapFilterSidenav.tpl.html'
};

},{"common/map/services/mapMarkerIcon":47}],371:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var MAP_MARKER_FILTER_SIDENAV = 'sidenav-map-marker-filters';

var MapSidenavController = exports.MapSidenavController = function () {
  function MapSidenavController($mdSidenav) {
    'ngInject';

    _classCallCheck(this, MapSidenavController);

    this._mdSidenav = $mdSidenav;
  }

  _createClass(MapSidenavController, [{
    key: 'openFilterSidenav',
    value: function openFilterSidenav() {
      this._mdSidenav(MAP_MARKER_FILTER_SIDENAV).open();
    }
  }]);

  return MapSidenavController;
}();

exports.default = {
  bindings: {
    markers: '<'
  },
  controller: MapSidenavController,
  templateUrl: 'pages/map/templates/mapSidenav.tpl.html'
};

},{}],372:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _angularUiRouter = require('angular-ui-router');

var _angularUiRouter2 = _interopRequireDefault(_angularUiRouter);

var _mapPage = require('./components/map-page');

var _mapPage2 = _interopRequireDefault(_mapPage);

var _mapSidenav = require('./components/mapSidenav');

var _mapSidenav2 = _interopRequireDefault(_mapSidenav);

var _mapFilterSidenav = require('./components/mapFilterSidenav');

var _mapFilterSidenav2 = _interopRequireDefault(_mapFilterSidenav);

var _mapService = require('./services/mapService');

var _mapService2 = _interopRequireDefault(_mapService);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var moduleConfig = function moduleConfig($stateProvider) {
  'ngInject';

  $stateProvider.state('map', {
    url: '/map',
    template: '<map-page layout="column" flex></map-page>',
    data: {
      title: 'Map'
    }
  });
};

var _module = _angular2.default.module('gnaas.pages.map', [_angularUiRouter2.default]).config(moduleConfig).component('mapPage', _mapPage2.default).component('mapSidenav', _mapSidenav2.default).component('mapFilterSidenav', _mapFilterSidenav2.default).service('MapService', _mapService2.default);

exports.default = _module.name;

},{"./components/map-page":369,"./components/mapFilterSidenav":370,"./components/mapSidenav":371,"./services/mapService":373,"angular":"angular","angular-ui-router":"angular-ui-router"}],373:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var MapService = function () {
  /**
   * @constructor
   * @ngInject
   */
  function MapService($q, BaseResource, LocationResource, HospitalResource, VehicleCallsignResource) {
    _classCallCheck(this, MapService);

    this._q = $q;
    this._BaseResource = BaseResource;
    this._LocationResource = LocationResource;
    this._HospitalResource = HospitalResource;
    this._VehicleCallsignResource = VehicleCallsignResource;
  }

  _createClass(MapService, [{
    key: 'getVehicleMarkerData',
    value: function getVehicleMarkerData() {
      return this._VehicleCallsignResource.index({ tracking: 'true' });
    }
  }, {
    key: 'getMarkerData',
    value: function getMarkerData() {
      var results = {};

      return this._q.all([this._BaseResource.index(), this._HospitalResource.index(), this._LocationResource.index({
        include: 'locationType'
      }), this.getVehicleMarkerData()]).then(function (data) {
        results.bases = data[0] || [];
        results.hospitals = data[1] || [];
        results.locations = data[2] || [];
        results.vehicles = data[3] || [];
        return results;
      });
    }
  }]);

  return MapService;
}();

exports.default = MapService;

},{}],374:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _module2 = require('./admin/module');

var _module3 = _interopRequireDefault(_module2);

var _module4 = require('./audit-tool/module');

var _module5 = _interopRequireDefault(_module4);

var _module6 = require('./calendar/module');

var _module7 = _interopRequireDefault(_module6);

var _module8 = require('./crewMembers/module');

var _module9 = _interopRequireDefault(_module8);

var _module10 = require('./dashboard/module');

var _module11 = _interopRequireDefault(_module10);

var _module12 = require('./login/module');

var _module13 = _interopRequireDefault(_module12);

var _module14 = require('./map/module');

var _module15 = _interopRequireDefault(_module14);

var _module16 = require('./passwordReset/module');

var _module17 = _interopRequireDefault(_module16);

var _module18 = require('./changePassword/module');

var _module19 = _interopRequireDefault(_module18);

var _module20 = require('./reports/module');

var _module21 = _interopRequireDefault(_module20);

var _module22 = require('./requestLog/module');

var _module23 = _interopRequireDefault(_module22);

var _module24 = require('./settings/module');

var _module25 = _interopRequireDefault(_module24);

var _module26 = require('./shifts/module');

var _module27 = _interopRequireDefault(_module26);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _module = _angular2.default.module('gnaas.pages', [_module3.default, _module5.default, _module7.default, _module9.default, _module11.default, _module13.default, _module15.default, _module17.default, _module19.default, _module21.default, _module23.default, _module25.default, _module27.default]);

exports.default = _module.name;

},{"./admin/module":325,"./audit-tool/module":341,"./calendar/module":352,"./changePassword/module":356,"./crewMembers/module":361,"./dashboard/module":364,"./login/module":368,"./map/module":372,"./passwordReset/module":376,"./reports/module":379,"./requestLog/module":398,"./settings/module":401,"./shifts/module":404,"angular":"angular"}],375:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var PasswordResetController = exports.PasswordResetController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function PasswordResetController(Dialog, $http, API_URL, $state, $stateParams, APP_ORG) {
    _classCallCheck(this, PasswordResetController);

    this._Dialog = Dialog;
    this._http = $http;
    this._apiUrl = API_URL;
    this._state = $state;
    this._stateParams = $stateParams;
    this.APP_ORG = APP_ORG;

    this.init();
  }

  _createClass(PasswordResetController, [{
    key: 'init',
    value: function init() {
      this.resourcePath = 'password/reset';

      this.email = this._stateParams.email ? this._stateParams.email : '';
      this.token = this._stateParams.token ? this._stateParams.token : '';
    }
  }, {
    key: 'submit',
    value: function submit(data) {
      var _this = this;

      var resetObject = {
        email: this.email,
        token: this.token,
        password: data.password,
        password_confirmation: data.confirmPassword
      };

      this._http.post('' + this._apiUrl + this.resourcePath, resetObject).then(function () {
        _this._Dialog.alert('Please login using your new password.', 'Password changed successfully');
        _this._state.go('login');
      }).catch(function (err) {
        if (!err.status) return;

        if (err.status === 400 && err.data.message === 'passwords.token') {
          _this._Dialog.alert('Password reset link not valid', 'Password reset error');
        } else {
          _this._Dialog.alert('Error during password reset. Please contact administrator.', 'Password reset error');
        }
      });
    }
  }]);

  return PasswordResetController;
}();

exports.default = {
  controller: PasswordResetController,
  templateUrl: 'pages/passwordReset/templates/password-reset.tpl.html'
};

},{}],376:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _angularUiRouter = require('angular-ui-router');

var _angularUiRouter2 = _interopRequireDefault(_angularUiRouter);

var _passwordReset = require('./components/password-reset');

var _passwordReset2 = _interopRequireDefault(_passwordReset);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var moduleConfig = function moduleConfig($stateProvider) {
  'ngInject';

  $stateProvider.state('passwordReset', {
    url: '/password-reset?token&email',
    template: '<password-reset></password-reset>',
    data: {
      title: 'Password Reset'
    }
  });
};

var _module = _angular2.default.module('gnaas.pages.passwordReset', [_angularUiRouter2.default]).config(moduleConfig).component('passwordReset', _passwordReset2.default);

exports.default = _module.name;

},{"./components/password-reset":375,"angular":"angular","angular-ui-router":"angular-ui-router"}],377:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var ReportsPage = function () {
  /**
   * @constructor
   * @ngInject
   */
  function ReportsPage(ReportResource, Toast, VehicleCallsignResource) {
    _classCallCheck(this, ReportsPage);

    this._ReportResource = ReportResource;
    this._Toast = Toast;
    this._VehicleCallsignResource = VehicleCallsignResource;
  }

  _createClass(ReportsPage, [{
    key: '$onInit',
    value: function $onInit() {
      var _this = this;

      this.maxDate = new Date();
      this.date = new Date();
      this._VehicleCallsignResource.index().then(function (data) {
        return _this.vehicleCallsigns = data;
      });
      this.showLoader = false;
    }
  }, {
    key: 'download',
    value: function download(fromDate, toDate) {
      var _this2 = this;

      var vehicleCallsignId = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

      if (this.form.$invalid || !fromDate || !toDate) return;

      var from = (0, _moment2.default)(fromDate).startOf('day').format('YYYY-MM-DD');
      var to = (0, _moment2.default)(toDate).endOf('day').format('YYYY-MM-DD');

      this.showLoader = true;

      this._ReportResource.download({ from: from, to: to, vehicleCallsignId: vehicleCallsignId, csv: true }).then(function (data) {
        var encodedUri = 'data:text/csv;charset=utf-8,' + encodeURI(data);

        var link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'report_' + from + '_-_' + to + '.csv');
        link.setAttribute('target', '_blank');

        document.body.appendChild(link); // Required for FF
        link.click();
        document.body.removeChild(link); // Required for FF
        _this2.showLoader = false;
      }).catch(function () {
        _this2._Toast.showSimple('Error downloading report.');
        _this2.showLoader = false;
      });
    }
  }]);

  return ReportsPage;
}();

exports.default = {
  controller: ReportsPage,
  templateUrl: 'pages/reports/components/reports-page/reports-page.tpl.html'
};

},{"moment":"moment"}],378:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ModuleConfig;
function ModuleConfig($stateProvider) {
  'ngInject';

  $stateProvider.state('reports', {
    url: '/reports',
    template: '<reports-page flex layout="column"></reports-page>',
    data: {
      title: 'Reports'
    }
  });
}

},{}],379:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _angularUiRouter = require('angular-ui-router');

var _angularUiRouter2 = _interopRequireDefault(_angularUiRouter);

var _module2 = require('./module.config');

var _module3 = _interopRequireDefault(_module2);

var _reportsPage = require('./components/reports-page/reports-page');

var _reportsPage2 = _interopRequireDefault(_reportsPage);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _module = _angular2.default.module('gnaas.pages.reports', [_angularUiRouter2.default]).config(_module3.default).component('reportsPage', _reportsPage2.default);

exports.default = _module.name;

},{"./components/reports-page/reports-page":377,"./module.config":378,"angular":"angular","angular-ui-router":"angular-ui-router"}],380:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _nodeUuid = require('node-uuid');

var _nodeUuid2 = _interopRequireDefault(_nodeUuid);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var AttachmentTabController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function AttachmentTabController(Toast, Dialog, PRFResource) {
    _classCallCheck(this, AttachmentTabController);

    this.Toast = Toast;
    this.Dialog = Dialog;
    this.PRFResource = PRFResource;
  }

  _createClass(AttachmentTabController, [{
    key: 'getDialogLocals',
    value: function getDialogLocals() {
      return {
        Dialog: this.Dialog,
        PRFResource: this.PRFResource,
        Toast: this.Toast,
        prf: this.prf,
        onPrfUpdate: this.onPrfUpdate
      };
    }
  }, {
    key: 'showUploadDialog',
    value: function showUploadDialog($event) {
      this.Dialog.show({
        targetEvent: $event,
        template: '\n        <md-dialog class="o-dialog o-dialog--small">\n          <md-dialog-content>\n            <div layout="column" class="u-padding-normal">\n              <label class="f-label u-margin-narrow-bottom">Files</label>\n              <input type="file" file="$ctrl.file" class="u-margin-narrow-bottom">\n              <md-progress-linear class="md-warn" md-mode="buffer" ng-if="$ctrl.isUploading" \n                    value="{{ $ctrl.fileUploadPercentage }}">\n              </md-progress-linear>\n            </div>\n          </md-dialog-content>\n          <md-dialog-actions layout="row">\n            <span flex></span>\n            <md-button class="md-raised" ng-click="$ctrl.close()">Cancel</md-button>\n            <md-button class="md-primary md-raised" ng-click="$ctrl.upload()">Upload</md-button>\n          </md-dialog-actions>\n        </md-dialog>\n      ',
        locals: this.getDialogLocals(),
        controller: function controller() {
          'ngInject';

          var _this = this;

          var ID = _nodeUuid2.default.v4();

          this.close = function () {
            _this.Dialog.hide();
          };

          this.upload = function () {
            if (!_this.file) return;

            _this.isUploading = true;
            _this.PRFResource.uploadAttachment(_this.prf.id, _this.file, ID).then(function (data) {
              _this.isUploading = false;
              _this.prf.attachments = [].concat(_toConsumableArray(_this.prf.attachments), [data]);
              if (typeof _this.onPrfUpdate === 'function') {
                _this.onPrfUpdate({ prf: _this.prf });
              }
              _this.Toast.showSimple('Attachment uploaded.');
              _this.close();
            }, function (err) {
              console.log(err);
              _this.Toast.showSimple('Error uploading attachment.');
            }, function (value) {
              _this.fileUploadPercentage = value.loaded / value.total * 100;
            });
          };
        }
      });
    }
  }, {
    key: 'showActionsDialog',
    value: function showActionsDialog($event, attachment) {
      this.Dialog.show({
        targetEvent: $event,
        template: '\n        <md-dialog class="o-dialog o-dialog--small">\n          <md-dialog-content>\n            <div layout="column" class="u-padding-normal">\n              <span>{{ $ctrl.attachment.file_name }}.{{ $ctrl.attachment.file_extension }}</span>\n            </div>\n          </md-dialog-content>\n          <md-dialog-actions layout="row">\n            <md-button class="md-raised md-warn" ng-click="$ctrl.delete()">Delete</md-button>\n            <span flex></span>\n            <md-button class="md-raised" ng-click="$ctrl.close()">Cancel</md-button>\n            <md-button class="md-primary md-raised" ng-click="$ctrl.open()">Open</md-button>\n          </md-dialog-actions>\n        </md-dialog>\n      ',
        locals: this.getDialogLocals(),
        controller: function controller($window) {
          'ngInject';

          var _this2 = this;

          this.attachment = attachment;

          this.close = function () {
            _this2.Dialog.hide();
          };

          this.delete = function () {
            _this2.Dialog.confirm('Are you sure you want to delete this attahcment?').then(function () {
              return _this2.PRFResource.deleteAttachment(_this2.prf.id, attachment.id);
            }).then(function () {
              _this2.prf.attachments = _this2.prf.attachments.filter(function (i) {
                return i.id !== attachment.id;
              });
              if (typeof _this2.onPrfUpdate === 'function') {
                _this2.onPrfUpdate({ prf: _this2.prf });
              }
              _this2.Toast.showSimple('Attachment deleted.');
              _this2.close();
            }, function () {
              _this2.Toast.showSimple('Error deleting attachment.');
            });
          };

          this.open = function () {
            $window.open(attachment.url, '_blank');
            _this2.close();
          };
        }
      });
    }
  }]);

  return AttachmentTabController;
}();

exports.default = {
  controller: AttachmentTabController,
  templateUrl: 'pages/requestLog/components/attachment-tab/attachment-tab.tpl.html',
  bindings: {
    isEditing: '<',
    prf: '<',
    onPrfUpdate: '&'
  }
};

},{"node-uuid":"node-uuid"}],381:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var ClinicalIncidentTabController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function ClinicalIncidentTabController(ClinicalIncidentDialog, Toast) {
    _classCallCheck(this, ClinicalIncidentTabController);

    this.ClinicalIncidentDialog = ClinicalIncidentDialog;
    this.Toast = Toast;
  }

  _createClass(ClinicalIncidentTabController, [{
    key: 'showClinicalIncidentDialog',
    value: function showClinicalIncidentDialog($event, prf) {
      var _this = this;

      this.ClinicalIncidentDialog.show({
        $event: $event,
        item: prf,
        relationData: this.relationData,
        locals: {
          editing: !!this.prf.clinical_incident
        }
      }).then(function (item) {
        _this.prf = Object.assign({}, _this.prf, item);
        _this.Toast.showSimple('Clinical incident updated.');

        if (typeof _this.onPrfUpdate === 'function') {
          _this.onPrfUpdate({ prf: _this.prf });
        }
      });
    }
  }]);

  return ClinicalIncidentTabController;
}();

exports.default = {
  controller: ClinicalIncidentTabController,
  templateUrl: 'pages/requestLog/components/clinical-incident-tab/clinical-incident-tab.tpl.html',
  bindings: {
    isEditing: '<',
    relationData: '<',
    prf: '<',
    onPrfUpdate: '&'
  }
};

},{}],382:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DeploymentPageController = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _angular = require('angular');

var _module = require('common/resource/module.constants');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var DeploymentPageController = exports.DeploymentPageController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function DeploymentPageController($window, $state, $stateParams, DeploymentPageService, PrfPageService, DeploymentDialog, PatientDetailsDialog, DeploymentListDialog, DeploymentStatusUpdateDialog, Session, ShiftService, Toast) {
    _classCallCheck(this, DeploymentPageController);

    this.Math = $window.Math;

    this._state = $state;
    this._stateParams = $stateParams;
    this._DeploymentPageService = DeploymentPageService;
    this._PrfPageService = PrfPageService;
    this._DeploymentDialog = DeploymentDialog;
    this._PatientDetailsDialog = PatientDetailsDialog;
    this._DeploymentStatusUpdateDialog = DeploymentStatusUpdateDialog;
    this._DeploymentListDialog = DeploymentListDialog;
    this._Session = Session;
    this._ShiftService = ShiftService;
    this._Toast = Toast;
  }

  _createClass(DeploymentPageController, [{
    key: '$onInit',
    value: function $onInit() {
      var _this = this;

      this._deploymentRelationData = { shifts: [] };
      this._prfRelationData = {};

      this._DeploymentPageService.getDeployment(this._stateParams.id).then(function (deployment) {
        _this.deployment = _this._formatDeployment(deployment);
        _this._generateTimelineAndDeploymentTimes();
        _this._generateBreadcrumbs();
        if (_this.hasPermissionToEditDeployment()) {
          _this._loadShiftCrewData(_this.deployment.incident.date.format(_module.DATE_FORMAT));
        }
      });

      if (this.hasPermissionToEditDeployment()) {
        this._loadRelationData();
      }
    }

    // Duplicate code is acceptable in hasPermissionToViewPRFs and hasPermissionToAddPRFs.
    // It can differ between ambulance services, and therefore should be left as separate methods.

  }, {
    key: 'hasPermissionToViewPRFs',
    value: function hasPermissionToViewPRFs() {
      var user = this._Session.user();
      if (!user.group || !user.group) return false;
      return ['system-administrators', 'medical-staff', 'medical-staff-prescribers', 'call-handlers'].indexOf(user.group.slug) > -1;
    }
  }, {
    key: 'hasPermissionToAddPRFs',
    value: function hasPermissionToAddPRFs() {
      var user = this._Session.user();
      if (!user.group || !user.group) return false;
      return ['system-administrators', 'medical-staff', 'medical-staff-prescribers', 'call-handlers'].indexOf(user.group.slug) > -1;
    }
  }, {
    key: 'hasPermissionToEditDeployment',
    value: function hasPermissionToEditDeployment() {
      var user = this._Session.user();
      if (!user.group || !user.group) return false;
      return ['system-administrators', 'medical-staff', 'medical-staff-prescribers', 'ops-admins', 'pilots', 'call-handlers'].indexOf(user.group.slug) > -1;
    }
  }, {
    key: 'hasStartedPRF',
    value: function hasStartedPRF() {
      if (!this.deployment) {
        return false;
      }

      return this.deployment.patient_report_forms && this.deployment.patient_report_forms.length > 0;
    }
  }, {
    key: 'showDeploymentDialog',
    value: function showDeploymentDialog($event) {
      var _this2 = this;

      if (!this.deployment) {
        return;
      }

      var dialogParams = {
        $event: $event,
        item: (0, _angular.copy)(this.deployment),
        relationData: this._deploymentRelationData,
        locals: {
          incident: this.deployment.incident
        }
      };

      this._DeploymentDialog.show(dialogParams).then(function (deployment) {
        _this2._Toast.showSimple('Deployment updated.');
        _this2.deployment = _this2._formatDeployment(deployment);
      }).catch(function (deploymentId) {
        if (typeof deploymentId !== 'string' || !deploymentId) {
          return;
        }
        _this2._Toast.showSimple('Deployment deleted.');
        _this2._state.go('incident', { id: _this2.deployment.incident.id });
      });
    }
  }, {
    key: 'showStatusUpdateDialog',
    value: function showStatusUpdateDialog($event, item) {
      var _this3 = this;

      if (!this.deployment) {
        return;
      }

      var isEditing = !!item;
      if (isEditing) {
        item.time = (0, _moment2.default)(item.time).toDate();
      }

      var deployment = (0, _angular.copy)(this.deployment);
      deployment.name = deployment.vehicle_callsign.name;

      var dialogParams = {
        $event: $event,
        item: item,
        relationData: this._deploymentRelationData,
        locals: {
          deployment: (0, _angular.copy)(deployment)
        }
      };

      this._DeploymentStatusUpdateDialog.show(dialogParams).then(function (status) {
        if (isEditing) {
          _this3.deployment.deployment_statuses = _this3.deployment.deployment_statuses.map(function (item) {
            return item.id === status.id ? Object.assign({}, item, status) : item;
          });
        } else {
          _this3.deployment.deployment_statuses = [].concat(_toConsumableArray(_this3.deployment.deployment_statuses), [status]);
        }
        _this3.deployment.outcome = _this3._DeploymentPageService.getDeploymentOutcomes(_this3.deployment);
        _this3._generateTimelineAndDeploymentTimes();
        _this3._Toast.showSimple('Deployment status updated.');
      }).catch(function (statusId) {
        if (!statusId) {
          return;
        }
        _this3.deployment.deployment_statuses = _this3.deployment.deployment_statuses.filter(function (_ref) {
          var id = _ref.id;
          return id !== statusId;
        });
        _this3.deployment.outcome = _this3._DeploymentPageService.getDeploymentOutcomes(_this3.deployment);
        _this3._generateTimelineAndDeploymentTimes();
        _this3._Toast.showSimple('Deployment status deleted.');
      });
    }
  }, {
    key: 'showDeploymentStatusList',
    value: function showDeploymentStatusList($event) {
      if (!this.deployment) {
        return;
      }

      var dialogParams = {
        $event: $event,
        item: (0, _angular.copy)(this.deployment.deployment_statuses),
        relationData: this._deploymentRelationData,
        locals: {
          incident: this.deployment.incident,
          deploymentStatuses: this.deployment.deployment_statuses,
          showEditDialog: this.showStatusUpdateDialog.bind(this)
        }
      };

      this._DeploymentListDialog.show(dialogParams);
    }
  }, {
    key: 'showPRFDialog',
    value: function showPRFDialog(evt) {
      var _this4 = this;

      if (!this.deployment) {
        return;
      }

      if (!Object.keys(this._prfRelationData).length) {
        // TODO: Replace with patient types repo (body parts and drugs are irrelevant here)
        this._PrfPageService.getRelationData().then(function (relationData) {
          Object.assign(_this4._prfRelationData, relationData);
        });
      }

      var dialogParams = {
        $event: evt,
        locals: {
          deployment: this.deployment,
          incident: this.deployment.incident,
          relationData: this._prfRelationData
        }
      };

      return this._PatientDetailsDialog.show(dialogParams).then(function (item) {
        _this4._state.go('prf.patientDetails', { id: item.id });
      });
    }
  }, {
    key: '_formatDeployment',
    value: function _formatDeployment(deployment) {
      return Object.assign({}, deployment, {
        outcome: this._DeploymentPageService.getDeploymentOutcomes(deployment),
        crew_members: deployment.crew_members.map(function (i) {
          if (i.type !== 'observer' || i.type === 'observer' && i.id != null) return i;
          return Object.assign({}, i, { first_name: 'OTHER', profession: { title: 'Observer' } });
        })
      });
    }
  }, {
    key: '_loadShiftCrewData',
    value: function _loadShiftCrewData(date) {
      var _this5 = this;

      var include = 'properties.vehicleCallsign,users.profile.image,users.profile.profession';
      this._ShiftService.getShiftsForDate(date, include).then(function (shifts) {
        _this5._deploymentRelationData.shifts = [].concat(_toConsumableArray(_this5._deploymentRelationData.shifts), _toConsumableArray(shifts));
      });
      this._ShiftService.getOvernightShiftsForDate(date).then(function (data) {
        var overnightShifts = data.map(function (shift) {
          return Object.assign({}, shift, { name: shift.name + ' (previous day)' });
        });
        _this5._deploymentRelationData.shifts = [].concat(_toConsumableArray(_this5._deploymentRelationData.shifts), _toConsumableArray(overnightShifts));
      });
    }
  }, {
    key: '_loadRelationData',
    value: function _loadRelationData() {
      var _this6 = this;

      this._DeploymentPageService.getRelationData().then(function (relationData) {
        Object.assign(_this6._deploymentRelationData, relationData);
      });

      this._DeploymentPageService.getDestinationData().then(function (relationData) {
        Object.assign(_this6._deploymentRelationData, { destinations: relationData });
      });
    }
  }, {
    key: '_generateTimelineAndDeploymentTimes',
    value: function _generateTimelineAndDeploymentTimes() {
      var _this7 = this;

      this.timelineEvents = this._DeploymentPageService.generateTimeline(this.deployment.deployment_statuses);
      if (this.deployment.deployment_statuses) {
        this.mobileTime = this._DeploymentPageService.calculateMobileTime(this.deployment.deployment_statuses);
        this.responseTime = this._DeploymentPageService.calculateResponseTime(this.deployment.incident.times.call.time, this.deployment.deployment_statuses);
      }

      this.deployment.patient_report_forms.forEach(function (PRF) {
        var transportedTo = _this7._PrfPageService.getTransportedDestination(_this7.deployment.deployment_statuses);
        PRF.outcome = _this7._PrfPageService.getPRFOutcome(transportedTo);
        PRF.surveys.forEach(function (survey, i) {
          var timelineEvent = {
            label: PRF.first_name + ' ' + PRF.last_name + ' - Survey ' + (i + 1),
            time: (0, _moment2.default)(survey.time)
          };
          _this7.timelineEvents.push(timelineEvent);
        });
      });
    }
  }, {
    key: '_generateBreadcrumbs',
    value: function _generateBreadcrumbs() {
      this.routes = [{
        title: 'Request Log',
        state: 'requestLog()'
      }, {
        title: 'Referral',
        state: 'incident({id: \'' + this.deployment.incident.id + '\'})'
      }, {
        title: 'Deployment'
      }];
    }
  }]);

  return DeploymentPageController;
}();

exports.default = {
  controller: DeploymentPageController,
  templateUrl: 'pages/requestLog/components/deployment-page/deployment-page.tpl.html'
};

},{"angular":"angular","common/resource/module.constants":57,"moment":"moment"}],383:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var DeploymentPageService = function () {
  /**
   * @constructor
   * @ngInject
   */
  function DeploymentPageService($q, CrewMemberResource, PrfPageService, DeploymentResource, DeploymentStatusResource, VehicleCallsignResource, HospitalResource, LocationResource, BaseResource, HospitalSpecialtyResource, RiskAssessmentOutcomeResource) {
    _classCallCheck(this, DeploymentPageService);

    this._q = $q;
    this._CrewMemberResource = CrewMemberResource;
    this._PrfPageService = PrfPageService;
    this._DeploymentResource = DeploymentResource;
    this._DeploymentStatusResource = DeploymentStatusResource;
    this._VehicleCallsignResource = VehicleCallsignResource;
    this._HospitalResource = HospitalResource;
    this._LocationResource = LocationResource;
    this._BaseResource = BaseResource;
    this._HospitalSpecialtyResource = HospitalSpecialtyResource;
    this._RiskAssessmentOutcomeResource = RiskAssessmentOutcomeResource;
  }

  _createClass(DeploymentPageService, [{
    key: 'getDeployment',
    value: function getDeployment(id) {
      return this._DeploymentResource.show(id, {
        include: ['deploymentStatuses', 'deploymentStatuses.deploymentStatus', 'deploymentStatuses.destination', 'incident', 'incident.deployments', 'incident.deployments.vehicleCallsign', 'patientReportForms', 'patientReportForms.surveys', 'vehicleCallsign', 'users', 'acceptingHospital', 'acceptingConsultantSpecialty', 'riskAssessmentOutcome', 'departureChecklistCompletedBy', 'departureChecklistApprovedBy', 'refCentreDepartureChecklistCompletedBy', 'refCentreDepartureChecklistApprovedBy']
      });
    }
  }, {
    key: 'getRelationData',
    value: function getRelationData() {
      var promises = {
        deploymentStatuses: this._DeploymentStatusResource.index(),
        users: this._CrewMemberResource.index({
          filter: 'operational-crew',
          include: 'group,profile.profession,profile.image'
        }),
        vehicleCallsigns: this._VehicleCallsignResource.index({ include: ['vehicle', 'deploymentStatusUpdates:limit(1):order(time)', 'deploymentStatusUpdates.deploymentStatus'] }),
        hospitals: this._HospitalResource.index(),
        hospitalSpecialties: this._HospitalSpecialtyResource.index(),
        riskAssessmentOutcomes: this._RiskAssessmentOutcomeResource.index()
      };

      return this._q.all(promises);
    }
  }, {
    key: 'getDestinationData',
    value: function getDestinationData() {
      var promises = {
        hospitals: this._HospitalResource.index(),
        locations: this._LocationResource.index(),
        bases: this._BaseResource.index()
      };

      return this._q.all(promises);
    }
  }, {
    key: 'getDeploymentOutcomes',
    value: function getDeploymentOutcomes(deployment) {
      var _this = this;

      var outcomes = [];
      deployment.deployment_statuses.forEach(function (status) {
        if (outcomes.includes(status.deployment_status.name)) {
          return;
        }

        if (status.deployment_status.is_stand_down || status.deployment_status.is_diverted || status.deployment_status.name.toLowerCase().indexOf('aborted') !== -1) {
          outcomes.push(status.deployment_status.name);
        }
      });

      deployment.patient_report_forms.forEach(function (item) {
        var transportedTo = _this._PrfPageService.getTransportedDestination(deployment.deployment_statuses);
        item.outcome = _this._PrfPageService.getPRFOutcome(transportedTo);
        if (outcomes.includes(item.outcome)) {
          return;
        }

        outcomes.push(item.outcome);
      });

      return this._generateOutcomeString(outcomes);
    }
  }, {
    key: 'generateTimeline',
    value: function generateTimeline(deploymentStatuses) {
      return deploymentStatuses.map(function (status) {
        return {
          time: status.time,
          label: status.deployment_status.name
        };
      });
    }
  }, {
    key: 'calculateMobileTime',
    value: function calculateMobileTime(deploymentStatuses) {
      deploymentStatuses.sort(function (a, b) {
        return a.time > b.time ? 1 : -1;
      });

      var mobileTime = 0;

      deploymentStatuses.forEach(function (status, i) {
        var nextStatus = deploymentStatuses[i + 1];
        if (status.deployment_status.is_mobile && nextStatus) {
          mobileTime += (0, _moment2.default)(nextStatus.time).diff((0, _moment2.default)(status.time), 'minutes');
        }
      });

      return mobileTime;
    }
  }, {
    key: 'calculateResponseTime',
    value: function calculateResponseTime(timeToOrg, deploymentStatuses) {
      deploymentStatuses.sort(function (a, b) {
        return a.time > b.time ? 1 : -1;
      });

      var responseTime = 0;
      var mobileStatuses = deploymentStatuses.filter(function (status) {
        return status.deployment_status.is_mobile;
      });

      if (mobileStatuses.length) {
        var statusTime = (0, _moment2.default)(mobileStatuses[0].time);
        var ttg = (0, _moment2.default)(timeToOrg);

        statusTime.year(ttg.year()).month(ttg.month()).date(ttg.date());
        responseTime = statusTime.diff(ttg, 'minutes');
      }

      return responseTime;
    }
  }, {
    key: '_generateOutcomeString',
    value: function _generateOutcomeString(outcomes) {
      var string = '';
      outcomes.forEach(function (outcome, i) {
        string += outcome && i !== outcomes.length - 1 ? outcome + ', ' : outcome;
      });
      return string;
    }
  }]);

  return DeploymentPageService;
}();

exports.default = DeploymentPageService;

},{"moment":"moment"}],384:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var HandoverNotesTabController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function HandoverNotesTabController(HandoverDialog, Toast) {
    _classCallCheck(this, HandoverNotesTabController);

    this.HandoverDialog = HandoverDialog;
    this.Toast = Toast;
  }

  _createClass(HandoverNotesTabController, [{
    key: 'showHandoverNotesDialog',
    value: function showHandoverNotesDialog($event, prf) {
      var _this = this;

      this.HandoverDialog.show({
        $event: $event,
        item: prf,
        locals: {
          editing: !!this.prf.handover
        }
      }).then(function (item) {
        _this.prf = Object.assign({}, _this.prf, item);
        _this.Toast.showSimple('Handover updated.');

        if (typeof _this.onPrfUpdate === 'function') {
          _this.onPrfUpdate({ prf: _this.prf });
        }
      });
    }
  }]);

  return HandoverNotesTabController;
}();

exports.default = {
  controller: HandoverNotesTabController,
  templateUrl: 'pages/requestLog/components/handover-notes-tab/handover-notes-tab.tpl.html',
  bindings: {
    isEditing: '<',
    prf: '<',
    onPrfUpdate: '&'
  }
};

},{}],385:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IncidentPageController = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _angular = require('angular');

var _module = require('common/resource/module.constants');

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var IncidentPageController = exports.IncidentPageController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function IncidentPageController($state, $stateParams, IncidentPageService, Toast, Utils, FlaggedIncidentDialog, DeploymentPageService, DeploymentDialog, IncidentDialog, Session, ShiftService) {
    _classCallCheck(this, IncidentPageController);

    this._state = $state;
    this._stateParams = $stateParams;
    this._Toast = Toast;
    this._Utils = Utils;
    this._IncidentPageService = IncidentPageService;
    this._FlaggedIncidentDialog = FlaggedIncidentDialog;
    this._DeploymentPageService = DeploymentPageService;
    this._DeploymentDialog = DeploymentDialog;
    this._IncidentDialog = IncidentDialog;
    this._Session = Session;
    this._ShiftService = ShiftService;
  }

  _createClass(IncidentPageController, [{
    key: '$onInit',
    value: function $onInit() {
      var _this = this;

      this._incidentRelationData = {};
      this._deploymentRelationData = { shifts: [] };

      if (this.hasPermissionToEditIncident()) {
        this._loadRelationData();
      }

      this._IncidentPageService.getIncident(this._stateParams.id).then(function (incident) {
        _this.incident = incident;
        _this.incident.outcome = _this._IncidentPageService.getIncidentOutcome(_this.incident);
        _this.incident.deployments.map(function (deployment) {
          deployment.outcome = _this._DeploymentPageService.getDeploymentOutcomes(deployment);
          return deployment;
        });
        _this.unresolvedFlagCount = _this.incident.flagged_incidents.filter(function (i) {
          return !i.resolved;
        }).length;
        _this._generateTimeline();
        _this._generateBreadCrumbs();
        if (_this.hasPermissionToEditIncident()) {
          _this._loadShiftCrewData(_this.incident.date.format(_module.DATE_FORMAT));
        }
      });
    }
  }, {
    key: 'hasPermissionToEditIncident',
    value: function hasPermissionToEditIncident() {
      var user = this._Session.user();
      if (!user || !user.group) return false;
      return ['system-administrators', 'medical-staff', 'medical-staff-prescribers', 'ops-admins', 'pilots', 'call-handlers'].indexOf(user.group.slug) > -1;
    }
  }, {
    key: 'hasStartedDeployment',
    value: function hasStartedDeployment() {
      if (!this.incident) {
        return false;
      }

      return this.incident.deployments && this.incident.deployments.length > 0;
    }
  }, {
    key: 'showIncidentDialog',
    value: function showIncidentDialog($event) {
      var _this2 = this;

      var dialogParams = {
        $event: $event,
        item: (0, _angular.copy)(this.incident),
        editing: true,
        relationData: this._incidentRelationData,
        multiple: true
      };

      this._IncidentDialog.show(dialogParams).then(function (incident) {
        _this2.incident = Object.assign({}, _this2.incident, incident);
        _this2._loadShiftCrewData(_this2.incident.date.format(_module.DATE_FORMAT));
        _this2._generateTimeline();
        _this2._Toast.showSimple('Referral updated.');
      }).catch(function (incidentId) {
        if (typeof incidentId !== 'string' || !incidentId) {
          return;
        }
        _this2._state.go('requestLog');
        _this2._Toast.showSimple('Referral deleted.');
      });
    }
  }, {
    key: 'showDeploymentDialog',
    value: function showDeploymentDialog($event) {
      var _this3 = this;

      var dialogParams = {
        $event: $event,
        editing: false,
        relationData: this._deploymentRelationData,
        locals: {
          incident: this.incident
        }
      };

      this._DeploymentDialog.show(dialogParams).then(function (deployment) {
        _this3._Toast.showSimple('Deployment created.');
        _this3._state.go('deployments', { id: deployment.id });
      });
    }
  }, {
    key: 'showFlagIncidentDialog',
    value: function showFlagIncidentDialog($event) {
      var _this4 = this;

      this._FlaggedIncidentDialog.show({ $event: $event, incident: this.incident }).then(function () {
        _this4._Toast.showSimple('Referral flagged.');
        _this4.unresolvedFlagCount++;
      }).catch(function (e) {
        if (e != null && e.status) {
          _this4._Toast.showSimple('Error when flagging referral.');
        }
      });
    }
  }, {
    key: '_generateTimeline',
    value: function _generateTimeline() {
      this.timelineEvents = this._IncidentPageService.generateTimeline(this.incident.times, this.incident.deployments);
    }
  }, {
    key: '_loadShiftCrewData',
    value: function _loadShiftCrewData(date) {
      var _this5 = this;

      var include = 'properties.vehicleCallsign,users.profile.image,users.profile.profession';
      this._ShiftService.getShiftsForDate(date, include).then(function (shifts) {
        _this5._deploymentRelationData.shifts = [].concat(_toConsumableArray(_this5._deploymentRelationData.shifts), _toConsumableArray(shifts));
        _this5._ShiftService.getOvernightShiftsForDate(date).then(function (data) {
          var overnightShifts = data.map(function (shift) {
            return Object.assign({}, shift, { name: shift.name + ' (previous day)' });
          });
          _this5._deploymentRelationData.shifts = [].concat(_toConsumableArray(_this5._deploymentRelationData.shifts), _toConsumableArray(overnightShifts));
        });
      });
    }
  }, {
    key: '_loadRelationData',
    value: function _loadRelationData() {
      var _this6 = this;

      this._IncidentPageService.getRelationData().then(function (relationData) {
        Object.assign(_this6._incidentRelationData, relationData);
      });
      this._DeploymentPageService.getRelationData().then(function (relationData) {
        Object.assign(_this6._deploymentRelationData, relationData);
      });
    }
  }, {
    key: '_generateBreadCrumbs',
    value: function _generateBreadCrumbs() {
      this.routes = [{
        title: 'Request Log',
        state: 'requestLog()'
      }, {
        title: 'Referral'
      }];
    }
  }]);

  return IncidentPageController;
}();

exports.default = {
  controller: IncidentPageController,
  templateUrl: 'pages/requestLog/components/incident-page/incident-page.tpl.html'
};

},{"angular":"angular","common/resource/module.constants":57}],386:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var IncidentPageService = function () {

  /**
   * @constructor
   * @ngInject
   */
  function IncidentPageService($q, PrfPageService, AmbulanceServiceResource, DeploymentReasonResource, RefusalReasonResource, ServiceAreaResource, IncidentResource, BaseResource, HospitalResource, HospitalSpecialtyResource, GradeResource, WardResource) {
    _classCallCheck(this, IncidentPageService);

    this._q = $q;
    this._PrfPageService = PrfPageService;
    this._AmbulanceServiceResource = AmbulanceServiceResource;
    this._DeploymentReasonResource = DeploymentReasonResource;
    this._RefusalReasonResource = RefusalReasonResource;
    this._ServiceAreaResource = ServiceAreaResource;
    this._IncidentResource = IncidentResource;
    this._BaseResource = BaseResource;
    this._HospitalResource = HospitalResource;
    this._HospitalSpecialtyResource = HospitalSpecialtyResource;
    this._GradeResource = GradeResource;
    this._WardResource = WardResource;
  }

  _createClass(IncidentPageService, [{
    key: 'getIncident',
    value: function getIncident(id) {
      return this._IncidentResource.show(id, {
        include: ['serviceArea', 'refusalReason', 'referringHospital', 'base', 'referrerGrade', 'referrerWard', 'ambulanceService', 'deploymentReason', 'flaggedIncidents', 'referrerSpecialty', 'deployments.vehicleCallsign', 'deployments.deploymentStatuses', 'deployments.deploymentStatuses.deploymentStatus', 'deployments.patientReportForms']
      });
    }
  }, {
    key: 'getIncidentOutcome',
    value: function getIncidentOutcome(incident) {
      var _this = this;

      if (!incident.deployments.length) {
        return;
      }

      var outcomes = [];

      incident.deployments.forEach(function (deployment) {
        deployment.deployment_statuses.forEach(function (status) {
          if (outcomes.includes(status.deployment_status.name)) {
            return;
          }

          if (status.deployment_status.is_stand_down || status.deployment_status.is_diverted || status.deployment_status.name.toLowerCase().indexOf('aborted') !== -1) {
            outcomes.push(status.deployment_status.name);
          }
        });

        deployment.patient_report_forms.forEach(function (item) {
          var transportedTo = _this._PrfPageService.getTransportedDestination(deployment.deployment_statuses);
          item.outcome = _this._PrfPageService.getPRFOutcome(transportedTo);
          if (outcomes.includes(item.outcome)) {
            return;
          }

          outcomes.push(item.outcome);
        });
      });

      return this._generateOutcomeString(outcomes);
    }
  }, {
    key: 'getRelationData',
    value: function getRelationData() {
      return this._q.all({
        ambulanceServices: this._AmbulanceServiceResource.index(),
        deploymentReasons: this._DeploymentReasonResource.index(),
        refusalReasons: this._RefusalReasonResource.index(),
        serviceAreas: this._ServiceAreaResource.index(),
        bases: this._BaseResource.index(),
        hospitals: this._HospitalResource.index(),
        hospitalSpecialties: this._HospitalSpecialtyResource.index(),
        grades: this._GradeResource.index(),
        wards: this._WardResource.index()
      }).then(function (r) {
        return Object.assign(r, {
          status: {
            'Pending': 'pending',
            'Accepted': 'accepted',
            'Declined': 'declined'
          }
        });
      }).catch(console.log.bind(console));
    }
  }, {
    key: 'generateTimeline',
    value: function generateTimeline(times, deployments) {
      var timelineEvents = Object.keys(times).map(function (key) {
        return times[key];
      });

      if (deployments) {
        deployments.forEach(function (deployment) {
          deployment.deployment_statuses.forEach(function (status) {
            timelineEvents.push({
              label: deployment.vehicle_callsign.name + ' - ' + status.deployment_status.name,
              time: status.time
            });
          });
        });
      }

      return timelineEvents;
    }
  }, {
    key: '_generateOutcomeString',
    value: function _generateOutcomeString(outcomes) {
      var string = '';
      outcomes.forEach(function (outcome, i) {
        string += outcome && i !== outcomes.length - 1 ? outcome + ', ' : outcome;
      });
      return string;
    }
  }]);

  return IncidentPageService;
}();

exports.default = IncidentPageService;

},{}],387:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var PatientDetailsTabController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function PatientDetailsTabController($state, PatientDetailsDialog, Toast) {
    _classCallCheck(this, PatientDetailsTabController);

    this.$state = $state;
    this.PatientDetailsDialog = PatientDetailsDialog;
    this.Toast = Toast;
  }

  _createClass(PatientDetailsTabController, [{
    key: 'showPatientDialog',
    value: function showPatientDialog($event, prf) {
      var _this = this;

      this.PatientDetailsDialog.show({
        $event: $event,
        item: prf,
        relationData: this.relationData
      }).then(function (item) {
        _this.prf = Object.assign({}, _this.prf, { infection_status: null }, item);
        _this.Toast.showSimple('Patient details updated.');

        if (typeof _this.onPrfUpdate === 'function') {
          _this.onPrfUpdate({ prf: _this.prf });
        }
      }).catch(function (prfId) {
        if (typeof prfId !== 'string' || !prfId) {
          return;
        }
        _this.Toast.showSimple('PRF deleted.');
        _this.$state.go('deployments', { id: _this.prf.deployment.id });
      });
    }
  }]);

  return PatientDetailsTabController;
}();

exports.default = {
  controller: PatientDetailsTabController,
  templateUrl: 'pages/requestLog/components/patient-details-tab/patient-details-tab.tpl.html',
  bindings: {
    isEditing: '<',
    relationData: '<',
    prf: '<',
    onPrfUpdate: '&'
  }
};

},{}],388:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _nodeUuid = require('node-uuid');

var _nodeUuid2 = _interopRequireDefault(_nodeUuid);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var PhotosTabController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function PhotosTabController(Toast, Dialog, PRFResource) {
    _classCallCheck(this, PhotosTabController);

    this.Toast = Toast;
    this.Dialog = Dialog;
    this.PRFResource = PRFResource;
  }

  _createClass(PhotosTabController, [{
    key: 'getDialogLocals',
    value: function getDialogLocals() {
      return {
        Dialog: this.Dialog,
        PRFResource: this.PRFResource,
        Toast: this.Toast,
        prf: this.prf,
        onPrfUpdate: this.onPrfUpdate
      };
    }
  }, {
    key: 'showUploadDialog',
    value: function showUploadDialog($event) {
      this.Dialog.show({
        targetEvent: $event,
        template: '\n        <md-dialog class="o-dialog o-dialog--small">\n          <md-dialog-content>\n            <div layout="column" class="u-padding-normal">\n              <label class="f-label u-margin-narrow-bottom">Files</label>\n              <input type="file" file="$ctrl.file" class="u-margin-narrow-bottom" accept="image/*">\n              <md-progress-linear class="md-warn" md-mode="buffer" ng-if="$ctrl.isUploading"\n                    value="{{ $ctrl.fileUploadPercentage }}">\n              </md-progress-linear>\n            </div>\n          </md-dialog-content>\n          <md-dialog-actions layout="row">\n            <span flex></span>\n            <md-button class="md-raised" ng-click="$ctrl.close()">Cancel</md-button>\n            <md-button class="md-primary md-raised" ng-click="$ctrl.upload()">Upload</md-button>\n          </md-dialog-actions>\n        </md-dialog>\n      ',
        locals: this.getDialogLocals(),
        controller: function controller() {
          'ngInject';

          var _this = this;

          var ID = _nodeUuid2.default.v4();

          this.close = function () {
            _this.Dialog.hide();
          };

          this.upload = function () {
            if (!_this.file) return;

            _this.isUploading = true;
            _this.PRFResource.uploadImage(_this.prf.id, _this.file, ID).then(function (data) {
              _this.isUploading = false;
              _this.prf.images = [].concat(_toConsumableArray(_this.prf.images), [data]);
              if (typeof _this.onPrfUpdate === 'function') {
                _this.onPrfUpdate({ prf: _this.prf });
              }
              _this.Toast.showSimple('Image uploaded.');
              _this.close();
            }, function (err) {
              console.log(err);
              _this.Toast.showSimple('Error uploading image.');
            }, function (value) {
              _this.fileUploadPercentage = value.loaded / value.total * 100;
            });
          };
        }
      });
    }
  }, {
    key: 'showImage',
    value: function showImage($event, img) {
      this.Dialog.show({
        targetEvent: $event,
        escapeToClose: true,
        fullscreen: true,
        template: '\n        <md-dialog aria-label="Image dialog" ng-style="$ctrl.styles">\n          <md-dialog-content ng-style="$ctrl.styles">\n              <img src="' + img.url + '" alt="' + img.id + '" ng-style="$ctrl.imageStyles">\n          </md-dialog-content>\n          <md-dialog-actions>\n            <md-button ng-click="$ctrl.delete()" class="md-raised md-warn">Delete</md-button>\n            <span flex></span>\n            <md-button ng-click="$ctrl.close()" class="md-raised">Cancel</md-button>\n          </md-dialog-actions>\n        </md-dialog>',
        locals: this.getDialogLocals(),
        controller: function controller($element) {
          'ngInject';

          var _this2 = this;

          this.styles = { width: '100%', height: '100%' };
          this.imageStyles = Object.assign({}, this.styles, { display: 'block', 'object-fit': 'contain' });

          this.close = function () {
            _this2.Dialog.hide();
          };

          this.delete = function () {
            _this2.Dialog.confirm('Are you sure you want to delete this image?').then(function () {
              return _this2.PRFResource.deleteImage(_this2.prf.id, img.id);
            }).then(function () {
              _this2.prf.images = _this2.prf.images.filter(function (i) {
                return i.id !== img.id;
              });
              if (typeof _this2.onPrfUpdate === 'function') {
                _this2.onPrfUpdate({ prf: _this2.prf });
              }
              _this2.Toast.showSimple('Image deleted.');
              _this2.close();
            }, function () {
              _this2.Toast.showSimple('Error deleting image.');
            });
          };
        }
      });
    }
  }]);

  return PhotosTabController;
}();

exports.default = {
  controller: PhotosTabController,
  templateUrl: 'pages/requestLog/components/photos-tab/photos-tab.tpl.html',
  bindings: {
    isEditing: '<',
    prf: '<',
    onPrfUpdate: '&'
  }
};

},{"node-uuid":"node-uuid"}],389:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }(); /* global Blob URL */


var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var PrfPageController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function PrfPageController($state, $stateParams, Toast, PrfPageService, Dialog, Session, PRFResource, PrintablePRFResource, RecordDrugUseDialog, ReviewDrugUseDialog, PRFApprovalDialog) {
    _classCallCheck(this, PrfPageController);

    this.$state = $state;
    this.$stateParams = $stateParams;
    this.Toast = Toast;
    this.PrfPageService = PrfPageService;
    this.Dialog = Dialog;
    this.Session = Session;
    this.PRFResource = PRFResource;
    this.PrintablePRFResource = PrintablePRFResource;
    this.RecordDrugUseDialog = RecordDrugUseDialog;
    this.ReviewDrugUseDialog = ReviewDrugUseDialog;
    this.PRFApprovalDialog = PRFApprovalDialog;
  }

  _createClass(PrfPageController, [{
    key: '$onInit',
    value: function $onInit() {
      var _this = this;

      this.relationData = {};
      this.isEditing = false;

      this.tabs = this._getTabs();
      this.selectedTab = this.tabs.find(function (tab) {
        return tab.state === _this.$state.current.name;
      }).id;

      this.PrfPageService.getPrf(this.$stateParams.id).then(function (prf) {
        _this.prf = prf;
        _this.formattedDob = _this.calculateFormattedDob(prf);
        _this.prf.deployment.crew_members = _this.prf.deployment.crew_members.map(function (i) {
          if (i.type !== 'observer' || i.type === 'observer' && i.id != null) return i;
          return Object.assign({}, i, { first_name: 'OTHER', profession: { title: 'Observer' } });
        });

        _this.medics = [].concat(_toConsumableArray(_this.prf.deployment.crew_members));
        _this.breadcrumbRoutes = _this.PrfPageService.getBreadcrumbRoutes(prf.incident.id, _this.prf.deployment.id);

        _this._setPermissions(prf);
        _this._setApprovalPendingFromUser(prf);
      });

      if (this._userHasPermissionToEdit()) {
        this.PrfPageService.getRelationData(this.$stateParams.id).then(function (relationData) {
          _this.relationData = Object.assign({}, _this.relationData, relationData);
        });
      }
    }

    //
    // CALLBACKS
    //

  }, {
    key: 'onPrfUpdate',
    value: function onPrfUpdate(prf) {
      this.prf = Object.assign({}, this.prf, prf);
      this.formattedDob = this.calculateFormattedDob(prf);
    }

    //
    // TABS
    //

  }, {
    key: 'selectTab',
    value: function selectTab(tab) {
      if (this.tabs.map(function (item) {
        return item.id;
      }).indexOf(tab.id) === -1) {
        throw new Error('Invalid tab.');
      }
      this.$state.go(tab.state);
    }
  }, {
    key: 'submitPrfApproval',
    value: function submitPrfApproval(_ref) {
      var _this2 = this;

      var $event = _ref.$event;

      if (!this.userCanApprovePRF) return;

      this.Dialog.confirm('Are you sure you want to approve the PRF?').then(function () {
        var hasAdministeredDrugs = _this2.prf.surveys.some(function (survey) {
          return survey.administered_drugs && survey.administered_drugs.length;
        });

        var users = _this2.medics.map(function (i) {
          return { email: i.email, name: i.first_name + ' ' + i.last_name };
        });
        if (_this2.prf.approval_status === 1) {
          var data = null;
          if (_this2.pendingUser) {
            data = { second_user: { email: _this2.pendingUser.email } };
          } else if (_this2.userCanApprovePRF) {
            data = { second_user: { email: _this2._getUser().email } };
          }

          // If stock transactions are recorded - review them, otherwise show approval dialog
          if (_this2.prf.stock_transactions && _this2.prf.stock_transactions.length) {
            _this2.ReviewDrugUseDialog.show({
              $event: $event,
              prf: _this2.prf,
              onApproveClick: function onApproveClick(_ref2) {
                var $event = _ref2.$event;

                return _this2.PRFApprovalDialog.showSingle({ $event: $event, users: users, data: data }).then(function (users) {
                  return _this2.secondPrfApproval(users);
                });
              },
              onRejectClick: function onRejectClick() {
                return _this2.rejectPrf();
              }
            });
          } else {
            _this2.PRFApprovalDialog.showSingle({ $event: $event, users: users, data: data }).then(function (users) {
              return _this2.secondPrfApproval(users);
            });
          }
        } else if (hasAdministeredDrugs) {
          _this2.RecordDrugUseDialog.show({
            $event: $event,
            locals: {
              prf: _this2.prf,
              onFormSubmit: _this2.approvePrf.bind(_this2)
            }
          });
        } else {
          var _data = { first_user: { email: _this2._getUser().email } };
          _this2.PRFApprovalDialog.show({ $event: $event, users: users, data: _data }).then(function (users) {
            return _this2.approvePrf(Object.assign({}, users));
          });
        }
      });
    }
  }, {
    key: 'approvePrf',
    value: function approvePrf(data) {
      if (data.second_user && (!data.second_user.email || !data.second_user.password)) {
        delete data.second_user;
      }

      return this.PRFResource.approve(this.prf.id, data).then(this._handleApprovalSuccess.bind(this)).catch(this._handleApprovalError.bind(this));
    }
  }, {
    key: 'secondPrfApproval',
    value: function secondPrfApproval(data) {
      return this.PRFResource.secondApproval(this.prf.id, data).then(this._handleApprovalSuccess.bind(this)).catch(this._handleApprovalError.bind(this));
    }
  }, {
    key: 'rejectPrf',
    value: function rejectPrf() {
      var _this3 = this;

      if (!this.userCanRejectPRF) return;

      return this.Dialog.confirm('Are you sure you want to reject the PRF?').then(function () {
        return _this3.PRFResource.reject(_this3.prf.id).then(function (approval) {
          _this3.prf.approval = approval;
          _this3.prf.approval_status = 0;
          _this3._setPermissions(_this3.prf);
          _this3._setApprovalPendingFromUser(_this3.prf);
          _this3.Toast.showSimple('Patient report form rejected.');
        }).catch(function (err) {
          if (err.status === 403) {
            _this3.Dialog.alert(err.data.message);
          } else {
            _this3.Dialog.alert('Error while trying to reject the patient report form.');
          }
          throw err;
        });
      });
    }
  }, {
    key: '_handleApprovalSuccess',
    value: function _handleApprovalSuccess(approval) {
      var _this4 = this;

      this.PrfPageService.getPrf(this.$stateParams.id).then(function (prf) {
        _this4.prf = prf;
        if (_this4.medics.length > 1) {
          _this4.prf.approval_status = approval.first_user && approval.second_user ? 2 : 1;
        } else {
          _this4.prf.approval_status = 2;
        }
        _this4._setPermissions(_this4.prf);
        _this4._setApprovalPendingFromUser(_this4.prf);
        _this4.Toast.showSimple('Patient report form approved.');
      });
    }
  }, {
    key: '_handleApprovalError',
    value: function _handleApprovalError(err) {
      if (!err || !err.status) return;
      if (err.status === 422 && err.data.errors && err.data.errors.quantity) {
        this.Dialog.alert(err.data.errors.quantity[0]);
      } else if (err.status === 428 || err.status === 403) {
        this.Dialog.alert(err.data.message);
      } else {
        this.Dialog.alert('Error while trying to approve the patient report form.');
      }
      throw err;
    }
  }, {
    key: '_setApprovalPendingFromUser',
    value: function _setApprovalPendingFromUser(prf) {
      if (prf.approval_status !== 1 || this.medics.length > 2 || !prf.approval || !prf.approval.first_user) {
        this.pendingUser = null;
        return;
      }
      var pendingUsers = this.medics.filter(function (item) {
        return item.id !== prf.approval.first_user.id;
      });
      this.pendingUser = pendingUsers.length ? pendingUsers[0] : null;
    }
  }, {
    key: '_userHasApprovalPending',
    value: function _userHasApprovalPending(approval) {
      if (!approval || !approval.first_user) return false;
      return this._userHasPermissionToEdit() && this._userIsOnCrew() && approval.first_user.id !== this._getUser().id;
    }

    //
    // PRINTABLE PRF
    //

  }, {
    key: 'getPrintablePDF',
    value: function getPrintablePDF() {
      var _this5 = this;

      this.PrintablePRFResource.show(this.prf.id).then(function (pdf) {
        var file = new Blob([pdf], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }).catch(function () {
        _this5.Toast.showSimple('PDF generation error.');
      });
    }

    //
    // PERMISSIONS
    //

  }, {
    key: '_setPermissions',
    value: function _setPermissions(prf) {
      var userCanEdit = this._userHasPermissionToEdit();
      // TODO Replace deployment status check with user group check (desk staff)
      var userIsOnCrew = this._userIsOnCrew() || prf.deployment.status === 'referral';
      this.isEditing = prf.approval_status === 0 && userCanEdit && userIsOnCrew;
      if (prf.deployment.status === 'transfer') {
        var userHasApprovalPending = this._userHasApprovalPending(prf.approval) && prf.approval_status === 1;
        this.userCanRejectPRF = this.Session.isSysadminUser() && prf.approval_status === 2 || userHasApprovalPending;
        this.userCanApprovePRF = this.isEditing && prf.approval_status === 0 || userHasApprovalPending;
      } else {
        this.userCanRejectPRF = false;
        this.userCanApprovePRF = false;
      }
      this.userCanGeneratePdf = this._userHasPermissionToGeneratePdf();
    }
  }, {
    key: '_getUser',
    value: function _getUser() {
      return this.Session.user();
    }
  }, {
    key: '_userHasPermissionToEdit',
    value: function _userHasPermissionToEdit() {
      var user = this._getUser();
      if (!user || !user.group) return false;
      return ~['system-administrators', 'medical-staff', 'medical-staff-prescribers', 'call-handlers'].indexOf(user.group.slug);
    }
  }, {
    key: '_userHasPermissionToGeneratePdf',
    value: function _userHasPermissionToGeneratePdf() {
      var user = this._getUser();
      if (!user || !user.group) return false;
      return ~['system-administrators', 'medical-staff', 'medical-staff-prescribers'].indexOf(user.group.slug);
    }
  }, {
    key: '_userIsOnCrew',
    value: function _userIsOnCrew() {
      var user = this._getUser();
      var matchingMedics = this.medics.filter(function (item) {
        return item.id === user.id;
      });
      return matchingMedics.length > 0;
    }
  }, {
    key: '_getTabs',
    value: function _getTabs() {
      var user = this._getUser();
      if (!user.group) return [];

      var tabs = [{ id: 'patient-details', name: 'Patient Details', state: 'prf.patientDetails' }, { id: 'surveys', name: 'Surveys', state: 'prf.surveys' }, { id: 'handover', name: 'Handover', state: 'prf.handover' }, { id: 'photos', name: 'Photos', state: 'prf.photos' }, { id: 'attachments', name: 'Attachments', state: 'prf.attachments' }, { id: 'clinical-incident', name: 'Clinical Incident', state: 'prf.clinicalIncident' }];

      if (user.group.slug === 'call-handlers') return [tabs[0]];
      return tabs;
    }
  }, {
    key: 'calculateFormattedDob',
    value: function calculateFormattedDob(prf) {
      if (!prf || !prf.date_of_birth) {
        return null;
      }

      return (0, _moment2.default)(prf.date_of_birth).format('DD/MM/YYYY');
    }
  }]);

  return PrfPageController;
}();

exports.default = {
  controller: PrfPageController,
  templateUrl: 'pages/requestLog/components/prf-page/prf-page.tpl.html'
};

},{"moment":"moment"}],390:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var PrfPageService = function () {
  /**
   * @constructor
   * @ngInject
   */
  function PrfPageService($q, BodyPartResource, DrugResource, InfusionResource, PatientTypeResource, PRFResource, CommunicationResource, EquipmentProblemResource, OrganisationalDelayResource, PhysiologicalDeteriorationResource, MedicationRouteResource, InfusionSolutionResource, InfectionStatusResource) {
    _classCallCheck(this, PrfPageService);

    this._q = $q;
    this._BodyPartResource = BodyPartResource;
    this._DrugResource = DrugResource;
    this._InfusionResource = InfusionResource;
    this._PatientTypeResource = PatientTypeResource;
    this._PRFResource = PRFResource;
    this._CommunicationResource = CommunicationResource;
    this._EquipmentProblemResource = EquipmentProblemResource;
    this._OrganisationalDelayResource = OrganisationalDelayResource;
    this._PhysiologicalDeteriorationResource = PhysiologicalDeteriorationResource;
    this._RouteResource = MedicationRouteResource;
    this._SolutionResource = InfusionSolutionResource;
    this._InfectionStatusResource = InfectionStatusResource;
  }

  _createClass(PrfPageService, [{
    key: 'getRelationData',
    value: function getRelationData(id) {
      var drugsSearchParams = {
        withNonFormulary: 0
      };

      if (id) {
        drugsSearchParams.withNonFormulary = 1;
        drugsSearchParams.prf = id;
      }

      return this._q.all({
        bodyParts: this._BodyPartResource.index(),
        drugs: this._DrugResource.index(drugsSearchParams),
        infusions: this._InfusionResource.index(),
        patientTypes: this._PatientTypeResource.index(),
        communications: this._CommunicationResource.index(),
        equipmentProblems: this._EquipmentProblemResource.index(),
        organisationalDelays: this._OrganisationalDelayResource.index(),
        physiologicalDeteriorations: this._PhysiologicalDeteriorationResource.index(),
        routes: this._RouteResource.index(),
        solutions: this._SolutionResource.index(),
        measurementTypes: this._DrugResource.getMeasurementTypes(),
        infectionStatuses: this._InfectionStatusResource.index()
      }).catch(console.log.bind(console));
    }
  }, {
    key: 'getPrf',
    value: function getPrf(id) {
      return this._PRFResource.show(id, {
        include: ['approval', 'incident', 'stockTransactions', 'images', 'attachments', 'deployment', 'deployment.users', 'deployment.vehicleCallsign', 'deployment.acceptingHospital', 'surveys', 'surveys.observations', 'surveys.administeredDrugs', 'surveys.assessedInjuries', 'surveys.assessedIllnesses', 'surveys.administeredInterventions', 'deployment.deploymentStatuses', 'deployment.deploymentStatuses.deploymentStatus', 'deployment.deploymentStatuses.destination', 'clinicalIncident', 'handover', 'surveys.laboratoryFindings', 'surveys.administeredInfusions', 'surveys.prescribedDrugs', 'surveys.prescribedInfusions', 'infectionStatuses']
      });
    }
  }, {
    key: 'getTransportedDestination',
    value: function getTransportedDestination(deploymentStatuses) {
      var atHospital = deploymentStatuses.filter(function (item) {
        return !!item.deployment_status.is_at_hospital;
      });
      if (atHospital.length) {
        var destination = atHospital[atHospital.length - 1].destination;
        return destination ? destination.name : '';
      }
      return null;
    }
  }, {
    key: 'getPRFOutcome',
    value: function getPRFOutcome(transportedTo) {
      if (transportedTo !== '' && !transportedTo) {
        return 'Treated on scene';
      }

      var outcome = 'Transported to hospital';
      if (transportedTo !== '') {
        outcome += ' - ' + transportedTo;
      }
      return outcome;
    }
  }, {
    key: 'getBreadcrumbRoutes',
    value: function getBreadcrumbRoutes(incidentId, deploymentId) {
      return [{
        title: 'Request Log',
        state: 'requestLog()'
      }, {
        title: 'Referral',
        state: 'incident({id: \'' + incidentId + '\'})'
      }, {
        title: 'Deployment',
        state: 'deployments({id: \'' + deploymentId + '\'})'
      }, {
        title: 'PRF'
      }];
    }
  }]);

  return PrfPageService;
}();

exports.default = PrfPageService;

},{}],391:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RequestLogController = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

require('moment-range');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var RequestLogController = exports.RequestLogController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function RequestLogController($state, $stateParams, Dialog, Toast, IncidentDialog, IncidentPageService, RequestLogPageService, Session, Utils) {
    _classCallCheck(this, RequestLogController);

    this._state = $state;
    this._stateParams = $stateParams;
    this._Dialog = Dialog;
    this._Toast = Toast;
    this._IncidentDialog = IncidentDialog;
    this._IncidentPageService = IncidentPageService;
    this._RequestLogPageService = RequestLogPageService;
    this._Session = Session;
    this._Utils = Utils;
  }

  _createClass(RequestLogController, [{
    key: '$onInit',
    value: function $onInit() {
      var _this = this;

      this.showLoader = false;
      this.days = [];
      this.dateString = '';
      this.selectedTab = 'accepted';
      this.hasRequests = { accepted: false, declined: false };
      this._incidentRelationData = {};
      this.date = this._stateParams.fromDate ? (0, _moment2.default)(this._stateParams.fromDate, 'YYYY-MM-DD') : (0, _moment2.default)();

      this.tabs = [{ id: 'accepted', name: 'Accepted' }, { id: 'declined', name: 'Declined' }, { id: 'pending', name: 'Pending' }];

      this._loadWeek();

      if (this.hasPermissionToCreateIncident()) {
        this._IncidentPageService.getRelationData().then(function (relationData) {
          Object.assign(_this._incidentRelationData, relationData);
        });
      }

      if (this._stateParams.showDialog) {
        this._showDialog();
      }
    }
  }, {
    key: 'hasPermissionToCreateIncident',
    value: function hasPermissionToCreateIncident() {
      var user = this._Session.user();
      if (!user || !user.group) return false;
      return ['system-administrators', 'medical-staff', 'medical-staff-prescribers', 'ops-admins', 'pilots', 'call-handlers'].indexOf(user.group.slug) > -1;
    }
  }, {
    key: 'currentWeek',
    value: function currentWeek() {
      this.date = (0, _moment2.default)();
      this._loadWeek();
    }
  }, {
    key: 'nextWeek',
    value: function nextWeek() {
      this.date.add(1, 'w');
      this._loadWeek();
    }
  }, {
    key: 'previousWeek',
    value: function previousWeek() {
      this.date.subtract(1, 'w');
      this._loadWeek();
    }
  }, {
    key: 'selectTab',
    value: function selectTab(tab) {
      if (this.tabs.map(function (item) {
        return item.id;
      }).indexOf(tab.id) === -1) {
        throw new Error('Tab name must be "accepted" or "declined".');
      }
      this.selectedTab = tab.id;
    }
  }, {
    key: 'showIncidentDialog',
    value: function showIncidentDialog(_ref) {
      var _this2 = this;

      var $event = _ref.$event,
          locals = _ref.locals;

      var dialogParams = {
        $event: $event,
        relationData: this._incidentRelationData,
        locals: locals
      };

      this._IncidentDialog.show(dialogParams).then(function (incident) {
        _this2._state.go('incident', { id: incident.id });
        _this2._Toast.showSimple('New referral created.');
      });
    }
  }, {
    key: '_showDialog',
    value: function _showDialog() {
      var locals = {};
      if (this._stateParams.location) {
        locals.location = this._stateParams.location;
      };
      this.showIncidentDialog({ locals: locals });
    }
  }, {
    key: '_loadWeek',
    value: function _loadWeek() {
      var _this3 = this;

      this.hasRequests = { accepted: false, declined: false };
      var range = this._RequestLogPageService.getWeekRange(this.date);
      this._state.transitionTo(this._state.current.name, {
        fromDate: range.start.format('YYYY-MM-DD')
      }, { notify: false });
      this.days = this._RequestLogPageService.getDays(range);
      this.dateString = this._Utils.getDateRangeString(range.start, range.end);

      this.showLoader = true;
      this._RequestLogPageService.getIncidentsForDateRange(range.start.format('YYYY-MM-DD'), range.end.format('YYYY-MM-DD')).then(function (items) {
        items.forEach(_this3._handleIncident.bind(_this3));
      }).finally(function () {
        _this3.showLoader = false;
      });
    }
  }, {
    key: '_handleIncident',
    value: function _handleIncident(incident) {
      var _this4 = this;

      this.days.some(function (day) {
        if (incident.date.diff(day, 'days') === 0) {
          incident.outcome = _this4._IncidentPageService.getIncidentOutcome(incident);
          day.incidents[incident.status].push(incident);
          if (!_this4.hasRequests[incident.status]) {
            _this4.hasRequests[incident.status] = true;
          }
          return true;
        }
        return false;
      });
    }
  }]);

  return RequestLogController;
}();

exports.default = {
  controller: RequestLogController,
  templateUrl: 'pages/requestLog/components/request-log-page/request-log-page.tpl.html'
};

},{"moment":"moment","moment-range":"moment-range"}],392:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var RequestLogPageService = function () {
  /**
   * @constructor
   * @ngInject
   */
  function RequestLogPageService(IncidentResource) {
    _classCallCheck(this, RequestLogPageService);

    this._IncidentResource = IncidentResource;
  }

  _createClass(RequestLogPageService, [{
    key: 'getIncidentsForDateRange',
    value: function getIncidentsForDateRange(startDate, endDate) {
      return this._IncidentResource.index({
        include: ['refusalReason', 'deployments', 'deployments.deploymentStatuses', 'deployments.deploymentStatuses.deploymentStatus', 'deployments.vehicleCallsign', 'deployments.vehicle', 'deployments.patientReportForms'],
        start_date: startDate,
        end_date: endDate
      });
    }
  }, {
    key: 'getWeekRange',
    value: function getWeekRange(date) {
      return _moment2.default.range((0, _moment2.default)(date).startOf('week'), (0, _moment2.default)(date).endOf('week'));
    }
  }, {
    key: 'getDays',
    value: function getDays(range) {
      var days = [];
      range.by('days', function (day) {
        day.incidents = { accepted: [], declined: [], pending: [] };
        days.push(day);
      });
      return days.reverse();
    }
  }]);

  return RequestLogPageService;
}();

exports.default = RequestLogPageService;

},{"moment":"moment"}],393:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  bindings: {
    item: '<'
  },
  controller: function controller(DeploymentPageService) {
    'ngInject';

    this.deployments = [];
    if (this.item.deployments) {
      this.deployments = this.item.deployments.map(function (deployment) {
        deployment.outcome = DeploymentPageService.getDeploymentOutcomes(deployment);
        if (deployment.deployment_statuses && deployment.deployment_statuses.length) {
          var index = deployment.deployment_statuses.length - 1;
          deployment.deploymentTime = deployment.deployment_statuses[index].time.format('HH:mm');
        }
        return deployment;
      });
    }
  },
  templateUrl: 'pages/requestLog/components/requestListItem.tpl.html'
};

},{}],394:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _angular = require('angular');

var _nodeUuid = require('node-uuid');

var _nodeUuid2 = _interopRequireDefault(_nodeUuid);

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _deepEqual = require('deep-equal');

var _deepEqual2 = _interopRequireDefault(_deepEqual);

var _administeredInfusion = require('core/prf/services/administered-infusion.service');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function createMarker(item) {
  var marker = Object.assign({}, item.body_part.marker);

  if (item.intervention) {
    marker.class = 'green';
  } else if (item.status === 'amber') {
    marker.class = 'amber';
  } else if (item.status === 'red') {
    marker.class = 'red';
  }

  return marker;
}

var SurveysTabController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function SurveysTabController($q, $rootScope, $timeout, Dialog, IllnessDialog, InjuryDialog, InterventionDialog, Toast, SurveyResource, LaboratoryFindingsDialog, Session, TimestampDialog, InfusionDialog, DrugDialog) {
    var _this = this;

    _classCallCheck(this, SurveysTabController);

    this.$q = $q;
    this.$rootScope = $rootScope;
    this.$timeout = $timeout;
    this.Dialog = Dialog;
    this.IllnessDialog = IllnessDialog;
    this.InjuryDialog = InjuryDialog;
    this.InterventionDialog = InterventionDialog;
    this.Toast = Toast;
    this.SurveyResource = SurveyResource;
    this.LabFindingsDialog = LaboratoryFindingsDialog;
    this.Session = Session;
    this.TimestampDialog = TimestampDialog;
    this.InfusionDialog = InfusionDialog;
    this.DrugDialog = DrugDialog;

    this.selectedSurveyOriginal = null;
    this.selectedSurveys = [];
    this.administeredInfusionDetails = [];
    this.prescribedInfusions = [];
    this.prescribedDrugs = [];
    this.bodyMarkers = [];
    this.viewAllSurveys = false;
    this.infusionRangeMap = {};

    this.onInfusionRangeChange = function (id, minRate, maxRate) {
      _this.infusionRangeMap[id] = [minRate, maxRate];
    };
  }

  _createClass(SurveysTabController, [{
    key: '$onInit',
    value: function $onInit() {
      var _this2 = this;

      this.stateChangeListener = this.$rootScope.$on('$stateChangeStart', function (e) {
        if (!_this2.checkSelectedSurvey()) {
          e.preventDefault();
        }
      });

      var group = this.Session.user().group.slug;
      this.userCanPrescribe = ~['medical-user-prescriber', 'medical-staff-prescribers', 'system-administrators'].indexOf(group);
    }
  }, {
    key: '$onDestroy',
    value: function $onDestroy() {
      this.stateChangeListener();
    }
  }, {
    key: '$onChanges',
    value: function $onChanges(changes) {
      var _this3 = this;

      var _changes = Object.assign({}, changes),
          isEditing = _changes.isEditing,
          prf = _changes.prf;

      if (prf && prf.currentValue && prf.currentValue.surveys && prf.currentValue.surveys.length) {
        this.prf.surveys = this.prf.surveys.map(function (survey) {
          survey.deployment_status = _this3.calculateAssociatedDeploymentStatus(survey.time);
          return survey;
        });

        if (!this.selectedSurveys.length) {
          this.selectSurvey({ index: this.isEditing ? 0 : null });
        }
      }

      if (isEditing && this.prf && this.prf.surveys && this.prf.surveys.length) {
        this.selectSurvey({ index: isEditing.currentValue ? 0 : null });
      }
    }
  }, {
    key: 'addSurvey',
    value: function addSurvey() {
      if (!this.checkSelectedSurvey()) return false;
      if (!this.prf.surveys) {
        this.prf.surveys = [];
      }
      this.prf.surveys = [].concat(_toConsumableArray(this.prf.surveys), [{
        date: this.prf.deployment.date ? (0, _moment2.default)(this.prf.deployment.date).toDate() : new Date(),
        time: (0, _moment2.default)().startOf('minute').toDate(),
        observations: [],
        prescribed_drugs: [],
        administered_drugs: [],
        prescribed_infusions: [],
        administered_infusions: [],
        assessed_injuries: [],
        assessed_illnesses: [],
        administered_interventions: [],
        laboratory_findings: [],
        patient_report_form: { id: this.prf.id },
        deployment_status: null
      }]);
      this.selectSurvey({ index: this.prf.surveys.length - 1 });
      return true;
    }
  }, {
    key: 'selectSurvey',
    value: function selectSurvey(_ref) {
      var index = _ref.index;

      if (!this.checkSelectedSurvey()) return false;

      if (index == null) {
        this.selectAllSurveys();
      } else {
        this.selectSingleSurvey(index);
        this.denormalizeAdministeredInfusionDetails();
      }
      this.updateMarkers(this.selectedSurveys);
      return true;
    }
  }, {
    key: 'addSurveyItem',
    value: function addSurveyItem(key) {
      if (~['prescribed_drugs', 'prescribed_infusions'].indexOf(key) && !this.userCanPrescribe) return;

      var item = {
        id: _nodeUuid2.default.v4(),
        date: (0, _moment2.default)(this.selectedSurveys[0].date).toDate(),
        time: (0, _moment2.default)().startOf('minute').toDate()
      };

      if (~['prescribed_drugs', 'prescribed_infusions'].indexOf(key)) {
        item.prescribed_by = this.Session.user();
      }

      if (~['administered_drugs'].indexOf(key)) {
        item.administered_by = this.Session.user();
      }

      if (~['observations'].indexOf(key)) {
        item.disabledFields = [];
      }

      this.selectedSurveys[0][key].push(item);
    }
  }, {
    key: 'removeSurveyItem',
    value: function removeSurveyItem(_ref2) {
      var _this4 = this;

      var key = _ref2.key,
          item = _ref2.item;

      if (~['prescribed_drugs', 'prescribed_infusions'].indexOf(key)) {
        var surveyObjKey = key === 'prescribed_drugs' ? 'administered_drugs' : 'administered_infusions';
        if (this.selectedSurveys[0][surveyObjKey].find(function (i) {
          return i.prescription && i.prescription.id === item.id;
        })) {
          var errMsg = 'This prescription has one or more matching administration records. ' + 'Please remove the administration record/-s before deleting the prescription.';
          this.Dialog.alert(errMsg, 'Administration record found');
          return;
        }
      }

      this.Dialog.confirm('Are you sure you wish to delete this item?').then(function () {
        _this4.selectedSurveys[0][key] = _this4.selectedSurveys[0][key].filter(function (i) {
          return item.id !== i.id;
        });
      });
    }
  }, {
    key: 'addAdministeredInfusion',
    value: function addAdministeredInfusion() {
      this.normalizeAdministeredInfusions();
      this.selectedSurveys[0].administered_infusions.push({
        id: _nodeUuid2.default.v4(),
        administered_by: this.Session.user(),
        details: []
      });
      this.denormalizeAdministeredInfusionDetails();
    }
  }, {
    key: 'addAdministeredInfusionDetails',
    value: function addAdministeredInfusionDetails() {
      var _this5 = this;

      this.normalizeAdministeredInfusions();
      this.TimestampDialog.show({
        locals: {
          validateAgainst: (0, _administeredInfusion.getUniqueInfusionDetailTimestamps)(this.selectedSurveys[0].administered_infusions),
          validationErrorMessage: 'Timestamp must be unique, this value already exists.'
        }
      }).then(function (timestamp) {
        _this5.selectedSurveys[0].administered_infusions.forEach(function (item) {
          item.details.push({
            id: _nodeUuid2.default.v4(),
            rate: null,
            date: (0, _moment2.default)(timestamp.date).toDate(),
            time: (0, _moment2.default)(timestamp.time).startOf('minute').toDate()
          });
        });
        _this5.denormalizeAdministeredInfusionDetails();
      });
    }
  }, {
    key: 'removeAdministeredInfusion',
    value: function removeAdministeredInfusion(_ref3) {
      var _this6 = this;

      var item = _ref3.item;

      this.normalizeAdministeredInfusions();
      this.Dialog.confirm('Are you sure you wish to delete this infusion?').then(function () {
        _this6.selectedSurveys[0].administered_infusions = _this6.selectedSurveys[0].administered_infusions.filter(function (i) {
          return i.id !== item.id;
        });
        _this6.denormalizeAdministeredInfusionDetails();
      });
    }
  }, {
    key: 'removeAdministeredInfusionDetails',
    value: function removeAdministeredInfusionDetails(_ref4) {
      var _this7 = this;

      var item = _ref4.item;

      this.normalizeAdministeredInfusions();
      this.Dialog.confirm('Are you sure you wish to delete these details?').then(function () {
        var removedDetailIds = item.rates.map(function (i) {
          return i.id;
        });
        _this7.selectedSurveys[0].administered_infusions.forEach(function (infusion) {
          infusion.details = infusion.details.filter(function (i) {
            return removedDetailIds.indexOf(i.id) === -1;
          });
        });
        _this7.denormalizeAdministeredInfusionDetails();
      });
    }
  }, {
    key: 'saveSelectedSurvey',
    value: function saveSelectedSurvey() {
      var _this8 = this;

      if (this.form.$invalid) {
        var formsToCheck = {
          '$ctrl.surveyDetailsForm': 'Survey Details',
          '$ctrl.observationsForm': 'Observations',
          '$ctrl.prescribedDrugsForm': 'Prescribed Drugs',
          '$ctrl.administeredDrugsForm': 'Administered Drugs',
          '$ctrl.prescribedInfusionsForm': 'Prescribed Infusions',
          '$ctrl.administeredInfusionsForm': 'Administered Infusions'
        };
        var formsWithErrors = Object.keys(formsToCheck).reduce(function (acc, cur) {
          if (_this8.form[cur] && _this8.form[cur].$invalid) return [].concat(_toConsumableArray(acc), [formsToCheck[cur]]);
          return acc;
        }, []);
        this.Dialog.alert('Could not save the survey. Please validate the data for the following - ' + formsWithErrors.join(', ') + '.');
        return;
      }

      this.normalizeAdministeredInfusions();

      var survey = this.selectedSurveys[0];
      if (survey.id) {
        this.updateSurvey(survey).then(function () {
          _this8.handleSurveySaved('Survey updated.');
        }).catch(function () {
          _this8.Toast.showSimple('Error updating survey.');
        });
      } else {
        this.createSurvey(survey).then(function (data) {
          _this8.selectedSurveys[0].id = data.id;
          _this8.handleSurveySaved('Survey created.');
        }).catch(function () {
          _this8.Toast.showSimple('Error creating survey.');
        });
      }
    }
  }, {
    key: 'handleSurveySaved',
    value: function handleSurveySaved(toastMsg) {
      this.selectedSurveyOriginal = (0, _angular.copy)(this.selectedSurveys[0]);
      this.Toast.showSimple(toastMsg);
      if (typeof this.onPrfUpdate === 'function') {
        this.onPrfUpdate({ prf: this.prf });
      }
    }
  }, {
    key: 'deleteSelectedSurvey',
    value: function deleteSelectedSurvey() {
      var _this9 = this;

      var index = this.prf.surveys.indexOf(this.selectedSurveys[0]);

      this.Dialog.confirm('Once deleted, surveys can not be recovered.').then(function () {
        var survey = _this9.selectedSurveys[0];
        _this9.$q.when(survey.id ? _this9.SurveyResource.destroy(survey.id) : null).then(function (data) {
          _this9.prf.surveys = _this9.prf.surveys.filter(function (item) {
            return data == null ? item !== survey : item.id !== survey.id;
          });
          _this9.handleSurveyDeleted(index);
        });
      });
    }
  }, {
    key: 'handleSurveyDeleted',
    value: function handleSurveyDeleted(index) {
      this.clearSelectedSurvey();

      if (this.prf.surveys.length === 0) {
        return;
      }

      if (index > 0) {
        this.selectSurvey({ index: index - 1 });
      } else if (index === 0) {
        this.selectSurvey({ index: 0 });
      }

      this.Toast.showSimple('Survey deleted.');
      if (typeof this.onPrfUpdate === 'function') {
        this.onPrfUpdate({ prf: this.prf });
      }
    }
  }, {
    key: 'discardSelectedSurveyChanges',
    value: function discardSelectedSurveyChanges() {
      var _this10 = this;

      if (!this.isEditing) return;
      this.Dialog.confirm('This will reset all the changes you have made on the selected survey.').then(function () {
        Object.assign(_this10.selectedSurveys[0], _this10.selectedSurveyOriginal);
        _this10.selectedSurveys[0] = (0, _angular.copy)(_this10.selectedSurveyOriginal);
        _this10.denormalizeAdministeredInfusionDetails();
        _this10.infusionRangeMap = {};
      });
    }
  }, {
    key: 'getSurveyOrderNumber',
    value: function getSurveyOrderNumber(survey) {
      return this.prf.surveys.indexOf(survey) + 1;
    }
  }, {
    key: 'selectAllSurveys',
    value: function selectAllSurveys() {
      this.viewAllSurveys = true;
      this.selectedSurveys = [].concat(_toConsumableArray(this.prf.surveys));
      this.selectedSurveyOriginal = null;
    }
  }, {
    key: 'selectSingleSurvey',
    value: function selectSingleSurvey(index) {
      this.viewAllSurveys = false;
      this.selectedSurveys = [this.prf.surveys[index]];
      this.selectedSurveyOriginal = (0, _angular.copy)(this.selectedSurveys[0]);
    }
  }, {
    key: 'clearSelectedSurvey',
    value: function clearSelectedSurvey() {
      this.selectedSurveys = [];
      this.selectedSurveyOriginal = null;
    }
  }, {
    key: 'checkSelectedSurvey',
    value: function checkSelectedSurvey() {
      if (!this.isEditing || !this.selectedSurveyOriginal || !this.selectedSurveys.length) return true;

      if (this.administeredInfusionDetails.length) {
        this.normalizeAdministeredInfusions();
      }

      var checkSurveyEquality = function checkSurveyEquality(one, two) {
        var preProcess = function preProcess(data) {
          return Object.assign({}, data, {
            prescribed_infusions: data.prescribed_infusions.map(function (pi) {
              return {
                infusion: Object.assign({}, pi.infusion, {
                  routes: [].concat(_toConsumableArray(pi.infusion.routes)).sort(function (ra, rb) {
                    return ra.id.localeCompare(rb.id);
                  })
                })
              };
            }),

            administered_infusions: data.administered_infusions.map(function (pi) {
              return {
                prescription: Object.assign({}, pi.prescription, {
                  infusion: Object.assign({}, pi.prescription.infusion, {
                    routes: [].concat(_toConsumableArray(pi.prescription.infusion.routes)).sort(function (ra, rb) {
                      return ra.id.localeCompare(rb.id);
                    })
                  })
                })
              };
            })
          });
        };

        var checkOne = preProcess((0, _angular.copy)(one));
        var checkTwo = preProcess((0, _angular.copy)(two));

        return (0, _deepEqual2.default)(checkOne, checkTwo, { strict: true });
      };

      if (!this.selectedSurveys[0].id || !checkSurveyEquality(this.selectedSurveyOriginal, this.selectedSurveys[0])) {
        this.Dialog.alert('Please save, delete or discard the changes on selected survey.', 'Survey has been changed.');
        return false;
      }

      return true;
    }
  }, {
    key: 'showInjuryDialog',
    value: function showInjuryDialog(_ref5) {
      var _this11 = this;

      var $event = _ref5.$event,
          data = _ref5.data;

      var isEditing = data && data.id != null;
      var survey = this.selectedSurveys[0];
      this.InjuryDialog.show({
        $event: $event,
        item: data,
        relationData: { bodyParts: this.relationData.bodyParts }
      }).then(function (_ref6) {
        var item = _ref6.item,
            addAnother = _ref6.addAnother;

        survey.assessed_injuries = _this11.handleCreateOrUpdate(isEditing, survey.assessed_injuries, item);
        if (addAnother) {
          var newItem = (0, _angular.copy)(item);
          delete newItem.id;
          delete newItem.notes;
          delete newItem.injury_type;
          _this11.showInjuryDialog({ $event: $event, data: newItem });
        }
      }).catch(function (id) {
        survey.assessed_injuries = _this11.handleDelete(survey.assessed_injuries, id);
      }).finally(function () {
        _this11.updateMarkers([_this11.selectedSurveys[0]]);
      });
    }
  }, {
    key: 'showIllnessDialog',
    value: function showIllnessDialog(_ref7) {
      var _this12 = this;

      var $event = _ref7.$event,
          data = _ref7.data;

      var isEditing = data && data.id != null;
      var survey = this.selectedSurveys[0];
      this.IllnessDialog.show({
        $event: $event,
        item: data,
        relationData: { bodyParts: this.relationData.bodyParts }
      }).then(function (_ref8) {
        var item = _ref8.item,
            addAnother = _ref8.addAnother;

        survey.assessed_illnesses = _this12.handleCreateOrUpdate(isEditing, survey.assessed_illnesses, item);
        if (addAnother) {
          var newItem = (0, _angular.copy)(item);
          delete newItem.id;
          delete newItem.notes;
          delete newItem.illness_type;
          _this12.showIllnessDialog({ $event: $event, data: newItem });
        }
      }).catch(function (id) {
        survey.assessed_illnesses = _this12.handleDelete(survey.assessed_illnesses, id);
      }).finally(function () {
        _this12.updateMarkers([_this12.selectedSurveys[0]]);
      });
    }
  }, {
    key: 'showInterventionDialog',
    value: function showInterventionDialog(_ref9) {
      var _this13 = this;

      var $event = _ref9.$event,
          data = _ref9.data;

      var isEditing = data && data.id != null;
      var survey = this.selectedSurveys[0];
      this.InterventionDialog.show({
        $event: $event,
        item: data,
        relationData: {
          bodyParts: this.relationData.bodyParts,
          crewMembers: [].concat(_toConsumableArray(this.prf.deployment.crew_members))
        }
      }).then(function (_ref10) {
        var item = _ref10.item,
            addAnother = _ref10.addAnother;

        survey.administered_interventions = _this13.handleCreateOrUpdate(isEditing, survey.administered_interventions, item);
        if (addAnother) {
          var newItem = (0, _angular.copy)(item);
          delete newItem.id;
          delete newItem.notes;
          delete newItem.performed_by_third_party;
          if (newItem.attributes) {
            newItem.attributes.forEach(function (attribute) {
              delete attribute.value;
              delete attribute.intervention_attribute_value;
            });
          }
          _this13.showInterventionDialog({ $event: $event, data: newItem });
        }
      }).catch(function (id) {
        survey.administered_interventions = _this13.handleDelete(survey.administered_interventions, id);
      }).finally(function () {
        _this13.updateMarkers([_this13.selectedSurveys[0]]);
      });
    }
  }, {
    key: 'showLabFindingsDialog',
    value: function showLabFindingsDialog(_ref11) {
      var _this14 = this;

      var $event = _ref11.$event,
          data = _ref11.data;

      var isEditing = data && data.id != null;
      var survey = this.selectedSurveys[0];
      this.LabFindingsDialog.show({
        $event: $event,
        item: data
      }).then(function (_ref12) {
        var item = _ref12.item;

        survey.laboratory_findings = _this14.handleCreateOrUpdate(isEditing, survey.laboratory_findings, item);
      }).catch(function (id) {
        if (!id) return;
        survey.laboratory_findings = _this14.handleDelete(survey.laboratory_findings, id);
      });
    }

    //
    // BODY MARKERS
    //

  }, {
    key: 'updateMarkers',
    value: function updateMarkers(surveys) {
      var _ref13;

      this.bodyMarkers = (_ref13 = []).concat.apply(_ref13, _toConsumableArray(surveys.map(function (survey) {
        return [].concat(_toConsumableArray(survey.administered_interventions.map(function (intervention) {
          return createMarker(intervention);
        })), _toConsumableArray(survey.assessed_injuries.map(function (injury) {
          return createMarker(injury);
        })), _toConsumableArray(survey.assessed_illnesses.map(function (illness) {
          return createMarker(illness);
        })));
      })));
    }
  }, {
    key: 'handleCreateOrUpdate',
    value: function handleCreateOrUpdate(isEditing, array, item) {
      if (isEditing) {
        return array.map(function (i) {
          return i.id === item.id ? Object.assign({}, i, item) : i;
        });
      }
      return [].concat(_toConsumableArray(array), [item]);
    }
  }, {
    key: 'handleDelete',
    value: function handleDelete(array, deletedItemId) {
      return array.filter(function (_ref14) {
        var id = _ref14.id;
        return id !== deletedItemId;
      });
    }
  }, {
    key: 'calculateAssociatedDeploymentStatus',
    value: function calculateAssociatedDeploymentStatus(surveyTime) {
      var time = _moment2.default.isMoment(surveyTime) ? surveyTime : (0, _moment2.default)(surveyTime);
      var statuses = this.prf.deployment.deployment_statuses;
      var status = null;

      statuses.forEach(function (item, i) {
        if (time.isAfter((0, _moment2.default)(item.time)) && statuses[i + 1] && time.isBefore((0, _moment2.default)(statuses[i + 1].time))) {
          status = item.deployment_status.name;
          return;
        }
      });

      return status;
    }
  }, {
    key: 'createSurvey',
    value: function createSurvey(surveyObject) {
      var survey = this.parseSurveyData((0, _angular.copy)(surveyObject));
      survey.id = _nodeUuid2.default.v4();
      return this.SurveyResource.create(survey).then(function () {
        return survey;
      });
    }
  }, {
    key: 'updateSurvey',
    value: function updateSurvey(surveyObject) {
      var survey = this.parseSurveyData((0, _angular.copy)(surveyObject));
      return this.SurveyResource.update(survey.id, survey);
    }
  }, {
    key: 'parseSurveyData',
    value: function parseSurveyData(survey) {
      survey.administered_infusions = survey.administered_infusions.map(function (infusion) {
        return Object.assign({}, infusion, {
          details: infusion.details.filter(function (detail) {
            return detail.rate != null;
          })
        });
      });
      return survey;
    }
  }, {
    key: 'normalizeAdministeredInfusions',
    value: function normalizeAdministeredInfusions() {
      this.selectedSurveys[0].administered_infusions = (0, _administeredInfusion.normalizeAdministeredInfusions)(this.selectedSurveys[0].administered_infusions, this.administeredInfusionDetails);
    }
  }, {
    key: 'denormalizeAdministeredInfusionDetails',
    value: function denormalizeAdministeredInfusionDetails() {
      this.administeredInfusionDetails = (0, _administeredInfusion.denormalizeAdministeredInfusionDetails)(this.selectedSurveys[0].administered_infusions);
    }
  }, {
    key: 'showAddInfusionDialog',
    value: function showAddInfusionDialog() {
      var _this15 = this;

      this.InfusionDialog.show({
        locals: {
          relationData: this.relationData
        }
      }).then(function (data) {
        _this15.relationData.infusions = [].concat(_toConsumableArray(_this15.relationData.infusions), [data]);
      });
    }
  }, {
    key: 'showAddDrugDialog',
    value: function showAddDrugDialog() {
      var _this16 = this;

      this.DrugDialog.show({
        locals: {
          relationData: this.relationData,
          excludeFromStock: true,
          isNonFormulary: true,
          prf: this.prf
        }
      }).then(function (data) {
        _this16.relationData.drugs = [].concat(_toConsumableArray(_this16.relationData.drugs), [data]);
      });
    }
  }]);

  return SurveysTabController;
}();

exports.default = {
  controller: SurveysTabController,
  templateUrl: 'pages/requestLog/components/survey-tab/survey-tab.tpl.html',
  bindings: {
    isEditing: '<',
    prf: '<',
    relationData: '<',
    onPrfUpdate: '&'
  }
};

},{"angular":"angular","core/prf/services/administered-infusion.service":194,"deep-equal":"deep-equal","moment":"moment","node-uuid":"node-uuid"}],395:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = filterDailyRequests;
function matchesIncidentNumber(incidentNumber, value) {
  return incidentNumber.toLowerCase().indexOf(value.toLowerCase()) > -1;
}

function matchesDate(date, value) {
  return date.format('DD-MM-YYYY').indexOf(value) > -1;
}

function filterDailyRequests() {
  return function (items, value) {
    if (typeof value === 'undefined') {
      return items;
    }

    return items.filter(function (item) {
      return matchesDate(item.date, value) || matchesIncidentNumber(item.incident_number, value);
    });
  };
};

},{}],396:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = filterRequests;
function matchesIncidentNumber(incidents, value) {
  if (!incidents || !incidents.length) {
    return false;
  }
  return incidents.some(function (incident) {
    return incident.incident_number.toLowerCase().indexOf(value.toLowerCase()) > -1;
  });
}

function matchesDate(date, value) {
  return date.format('DD-MM-YYYY').indexOf(value) > -1;
}

function filterRequests() {
  return function (items, value, status) {
    if (typeof value === 'undefined') {
      return items;
    }

    return items.filter(function (item) {
      return matchesDate(item, value) || matchesIncidentNumber(item.incidents[status], value);
    });
  };
};

},{}],397:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ModuleConfig;
function ModuleConfig($stateProvider) {
  'ngInject';

  $stateProvider.state('requestLog', {
    url: '/request-log?fromDate',
    template: '<request-log-page flex layout="column"></request-log-page>',
    params: {
      showDialog: false,
      location: null,
      fromDate: null
    },
    data: {
      title: 'Request log'
    }
  }).state('incident', {
    url: '/referrals/:id',
    template: '<incident-page flex layout="column"></incident-page>',
    data: {
      title: 'Referral'
    }
  }).state('deployments', {
    url: '/deployments/:id',
    template: '<deployment-page flex layout="column"></deployment-page>',
    data: {
      title: 'Deployment'
    }
  }).state('prf', {
    abstract: true,
    url: '/prf/:id',
    template: '<prf-page flex layout="column"></prf-page>',
    data: {
      title: 'PRF'
    }
  }).state('prf.patientDetails', {
    url: '/patient-details',
    template: '<patient-details-tab flex layout="column" prf="$ctrl.prf" is-editing="$ctrl.isEditing"\n        relation-data="$ctrl.relationData" on-prf-update="$ctrl.onPrfUpdate(prf)"></patient-details-tab>'
  }).state('prf.surveys', {
    url: '/surveys',
    template: '<survey-tab flex layout="column" prf="$ctrl.prf" is-editing="$ctrl.isEditing"\n        relation-data="$ctrl.relationData" on-prf-update="$ctrl.onPrfUpdate(prf)"></survey-tab>'
  }).state('prf.handover', {
    url: '/handover',
    template: '<handover-notes-tab flex layout="column" prf="$ctrl.prf" is-editing="$ctrl.isEditing"\n        on-prf-update="$ctrl.onPrfUpdate(prf)"></handover-notes-tab>'
  }).state('prf.photos', {
    url: '/photos',
    template: '<photos-tab flex layout="column" prf="$ctrl.prf" is-editing="$ctrl.isEditing"\n        on-prf-update="$ctrl.onPrfUpdate(prf)"></photos-tab>'
  }).state('prf.attachments', {
    url: '/attachments',
    template: '<attachment-tab flex layout="column" prf="$ctrl.prf" is-editing="$ctrl.isEditing"\n        on-prf-update="$ctrl.onPrfUpdate(prf)"></attachment-tab>'
  }).state('prf.clinicalIncident', {
    url: '/clinical-incident',
    template: '<clinical-incident-tab flex layout="column" prf="$ctrl.prf" is-editing="$ctrl.isEditing"\n        relation-data="$ctrl.relationData" on-prf-update="$ctrl.onPrfUpdate(prf)"></clinical-incident-tab>'
  });
}

},{}],398:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _angularUiRouter = require('angular-ui-router');

var _angularUiRouter2 = _interopRequireDefault(_angularUiRouter);

var _module2 = require('./module.config');

var _module3 = _interopRequireDefault(_module2);

var _deploymentPage = require('./components/deployment-page/deployment-page');

var _deploymentPage2 = _interopRequireDefault(_deploymentPage);

var _incidentPage = require('./components/incident-page/incident-page');

var _incidentPage2 = _interopRequireDefault(_incidentPage);

var _prfPage = require('./components/prf-page/prf-page');

var _prfPage2 = _interopRequireDefault(_prfPage);

var _requestListItem = require('./components/requestListItem');

var _requestListItem2 = _interopRequireDefault(_requestListItem);

var _requestLogPage = require('./components/request-log-page/request-log-page');

var _requestLogPage2 = _interopRequireDefault(_requestLogPage);

var _filterDailyRequests = require('./filters/filterDailyRequests');

var _filterDailyRequests2 = _interopRequireDefault(_filterDailyRequests);

var _filterRequests = require('./filters/filterRequests');

var _filterRequests2 = _interopRequireDefault(_filterRequests);

var _attachmentTab = require('./components/attachment-tab/attachment-tab');

var _attachmentTab2 = _interopRequireDefault(_attachmentTab);

var _deploymentPage3 = require('./components/deployment-page/deployment-page.service');

var _deploymentPage4 = _interopRequireDefault(_deploymentPage3);

var _handoverNotesTab = require('./components/handover-notes-tab/handover-notes-tab');

var _handoverNotesTab2 = _interopRequireDefault(_handoverNotesTab);

var _incidentPage3 = require('./components/incident-page/incident-page.service');

var _incidentPage4 = _interopRequireDefault(_incidentPage3);

var _patientDetailsTab = require('./components/patient-details-tab/patient-details-tab');

var _patientDetailsTab2 = _interopRequireDefault(_patientDetailsTab);

var _photosTab = require('./components/photos-tab/photos-tab');

var _photosTab2 = _interopRequireDefault(_photosTab);

var _prfPage3 = require('./components/prf-page/prf-page.service');

var _prfPage4 = _interopRequireDefault(_prfPage3);

var _requestLogPage3 = require('./components/request-log-page/request-log-page.service');

var _requestLogPage4 = _interopRequireDefault(_requestLogPage3);

var _recordDrugUseDialog = require('core/prf/services/record-drug-use-dialog/record-drug-use-dialog.service');

var _recordDrugUseDialog2 = _interopRequireDefault(_recordDrugUseDialog);

var _surveyTab = require('./components/survey-tab/survey-tab');

var _surveyTab2 = _interopRequireDefault(_surveyTab);

var _clinicalIncidentTab = require('./components/clinical-incident-tab/clinical-incident-tab');

var _clinicalIncidentTab2 = _interopRequireDefault(_clinicalIncidentTab);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _module = _angular2.default.module('gnaas.pages.requestLog', [_angularUiRouter2.default]).config(_module3.default).component('attachmentTab', _attachmentTab2.default).component('deploymentPage', _deploymentPage2.default).component('handoverNotesTab', _handoverNotesTab2.default).component('incidentPage', _incidentPage2.default).component('patientDetailsTab', _patientDetailsTab2.default).component('photosTab', _photosTab2.default).component('prfPage', _prfPage2.default).component('requestListItem', _requestListItem2.default).component('requestLogPage', _requestLogPage2.default).component('surveyTab', _surveyTab2.default).component('clinicalIncidentTab', _clinicalIncidentTab2.default).filter('filterDailyRequests', _filterDailyRequests2.default).filter('filterRequests', _filterRequests2.default).service('DeploymentPageService', _deploymentPage4.default).service('IncidentPageService', _incidentPage4.default).service('PrfPageService', _prfPage4.default).service('RequestLogPageService', _requestLogPage4.default).service('RecordDrugUseDialogService', _recordDrugUseDialog2.default);

exports.default = _module.name;

},{"./components/attachment-tab/attachment-tab":380,"./components/clinical-incident-tab/clinical-incident-tab":381,"./components/deployment-page/deployment-page":382,"./components/deployment-page/deployment-page.service":383,"./components/handover-notes-tab/handover-notes-tab":384,"./components/incident-page/incident-page":385,"./components/incident-page/incident-page.service":386,"./components/patient-details-tab/patient-details-tab":387,"./components/photos-tab/photos-tab":388,"./components/prf-page/prf-page":389,"./components/prf-page/prf-page.service":390,"./components/request-log-page/request-log-page":391,"./components/request-log-page/request-log-page.service":392,"./components/requestListItem":393,"./components/survey-tab/survey-tab":394,"./filters/filterDailyRequests":395,"./filters/filterRequests":396,"./module.config":397,"angular":"angular","angular-ui-router":"angular-ui-router","core/prf/services/record-drug-use-dialog/record-drug-use-dialog.service":231}],399:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SettingsController = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _angular = require('angular');

var _nodeUuid = require('node-uuid');

var _nodeUuid2 = _interopRequireDefault(_nodeUuid);

var _changePasswordController = require('pages/settings/controllers/changePasswordController');

var _changePasswordController2 = _interopRequireDefault(_changePasswordController);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var SettingsController = exports.SettingsController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function SettingsController(CrewMemberResource, Session, Dialog, Toast, API_URL) {
    _classCallCheck(this, SettingsController);

    this._CrewMemberResource = CrewMemberResource;
    this._Session = Session;
    this._Dialog = Dialog;
    this._Toast = Toast;
    this._API_URL = API_URL;
  }

  _createClass(SettingsController, [{
    key: '$onInit',
    value: function $onInit() {
      var _this = this;

      this.user = Object.assign({}, this._Session.user());
      this._CrewMemberResource.show(this.user.id, { include: 'group,profile.image' }).then(function (user) {
        _this.user = user;
      });
    }
  }, {
    key: 'submit',
    value: function submit(user) {
      var _this2 = this;

      var isNewFormData = !this.form.$pristine;
      var isNewImage = !!this.uploadImage;

      if (!isNewFormData && !isNewImage || this.form.$invalid) {
        return;
      }

      user = (0, _angular.copy)(user);
      delete user.group;
      delete user.profession;

      if (isNewFormData) {
        this._CrewMemberResource.update(user.id, user, { include: 'group,profile.image' }).then(function (data) {
          _this2.user = data;
          _this2._updateSessionUser(data);
          _this2._Toast.showSimple('Profile data updated.');
        });
      }

      if (isNewImage) {
        var imageId = _nodeUuid2.default.v4();
        this.isUploading = true;
        this._CrewMemberResource.uploadProfileImage(this.uploadImage, user.id, {
          id: imageId,
          imageable_id: user.id,
          imageable_type: 'crew_member'
        }).then(function (data) {
          _this2.isUploading = false;
          _this2.user.profile_image = data.profile_image;
          _this2._updateSessionUser(data);
          _this2._Toast.showSimple('Profile avatar updated.');
        }, function (err) {
          console.log(err);
        }, function (value) {
          _this2.profileImagePercentUploaded = value.loaded / value.total * 100;
        });
      }
    }
  }, {
    key: 'showChangePasswordDialog',
    value: function showChangePasswordDialog(evt) {
      var _this3 = this;

      var dialogParams = {
        controller: _changePasswordController2.default,
        targetEvent: evt,
        templateUrl: 'pages/settings/templates/changePasswordDialog.tpl.html'
      };

      this._Dialog.show(dialogParams).then(function (item) {
        _this3._Toast.showSimple('Password successfully changed.');
      }).catch(function (error) {
        if (error) {
          _this3._Toast.showSimple('Error while changing password.');
        }
      });
    }
  }, {
    key: 'isAdmin',
    value: function isAdmin() {
      var user = this._Session.user();
      return user.group.slug === 'system-administrators';
    }
  }, {
    key: '_updateSessionUser',
    value: function _updateSessionUser(data) {
      this._Session.setUser(data);
    }
  }]);

  return SettingsController;
}();

exports.default = {
  controller: SettingsController,
  templateUrl: 'pages/settings/templates/settings.tpl.html'
};

},{"angular":"angular","node-uuid":"node-uuid","pages/settings/controllers/changePasswordController":400}],400:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ChangePasswordController;
function ChangePasswordController($mdDialog, CrewMemberResource) {
  'ngInject';

  var _this = this;

  this.submit = function (passwords) {
    if (_this.form.$invalid) {
      return;
    }

    CrewMemberResource.changePassword(passwords).then(function () {
      $mdDialog.hide();
    }).catch(console.log.bind(console));
  };

  this.cancel = function () {
    $mdDialog.cancel();
  };
}

},{}],401:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _angularUiRouter = require('angular-ui-router');

var _angularUiRouter2 = _interopRequireDefault(_angularUiRouter);

var _settings = require('./components/settings');

var _settings2 = _interopRequireDefault(_settings);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var moduleConfig = function moduleConfig($stateProvider) {
  'ngInject';

  $stateProvider.state('settings', {
    url: '/settings',
    template: '<settings flex layout="column"></settings>',
    data: {
      title: 'Settings'
    }
  });
};

var _module = _angular2.default.module('gnaas.pages.settings', [_angularUiRouter2.default]).config(moduleConfig).component('settings', _settings2.default);

exports.default = _module.name;

},{"./components/settings":399,"angular":"angular","angular-ui-router":"angular-ui-router"}],402:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _angular = require('angular');

var _module = require('core/shifts/module.constants');

var _module2 = require('common/resource/module.constants');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var TABS = [{ id: 'active', name: 'Active' }, { id: 'inactive', name: 'Inactive' }];

var TABLE_FIELDS = {
  name: 'Name',
  startTimeString: 'Start Time',
  endTimeString: 'End Time',
  baseName: 'Base',
  vehicleCallsignName: 'Vehicle Callsign'
};

function formatShift(shift) {
  return Object.assign({}, shift, {
    baseName: shift.base ? shift.base.name : '-',
    vehicleCallsignName: shift.vehicle_callsign ? shift.vehicle_callsign.name : '-',
    endDateString: shift.end_date ? (0, _moment2.default)(shift.end_date).format(_module2.DATE_FORMAT) : '-',
    startTimeString: (0, _moment2.default)(shift.start_time).format('HH:mm'),
    endTimeString: (0, _moment2.default)(shift.end_time).format('HH:mm')
  });
}

var ShiftsPageController = function () {
  /**
   * @constructor
   * @ngInject
   */
  function ShiftsPageController($mdSidenav, $rootScope, BaseResource, CrewMemberResource, ShiftDialog, ShiftResource, VehicleCallsignResource) {
    _classCallCheck(this, ShiftsPageController);

    this.$mdSidneav = $mdSidenav;
    this.$rootScope = $rootScope;
    this.BaseResource = BaseResource;
    this.CrewMemberResource = CrewMemberResource;
    this.ShiftDialog = ShiftDialog;
    this.ShiftResource = ShiftResource;
    this.VehicleCallsignResource = VehicleCallsignResource;
  }

  _createClass(ShiftsPageController, [{
    key: '$onInit',
    value: function $onInit() {
      var _this = this;

      this.today = (0, _moment2.default)().format(_module2.DATE_FORMAT);
      this.tabs = TABS;
      this.selectedTab = this.tabs[0].id;
      this.tableFields = TABLE_FIELDS;

      // Set up pagination & load shifts
      this.resetPagination();
      this.loadPage(1);

      // Load relation data
      this.loadRelations();

      // Listen to shift updated & deleted events
      this.$rootScope.$on(_module.SHIFT_EVENTS.updated, function (e, shift) {
        _this.shifts = _this.shifts.map(function (item) {
          return item.id === shift.id ? formatShift(shift) : item;
        });
        _this.loadPage(_this.pagination.currentPage);
      });
      this.$rootScope.$on(_module.SHIFT_EVENTS.deleted, function (e, shift) {
        _this.shifts = _this.shifts.filter(function (item) {
          return item.id !== shift.id;
        });
        _this.loadPage(_this.pagination.currentPage);
        _this.selectedShift = null;
      });
    }

    /**
     * Pagination & shift data loading
     */

  }, {
    key: 'resetPagination',
    value: function resetPagination() {
      this.pagination = { currentPage: 1, totalPages: 1 };
    }
  }, {
    key: 'loadPage',
    value: function loadPage(page) {
      var _this2 = this;

      this.dataLoading = true;
      this.ShiftResource.index({
        include: 'properties.base,properties.vehicleCallsign',
        page: page,
        'active_status': this.selectedTab,
        'order_by': 'start_date'
      }).then(function (shifts) {
        _this2.shifts = shifts.data.map(formatShift);
        _this2.pagination.currentPage = shifts.pagination.current_page;
        _this2.pagination.totalPages = shifts.pagination.total_pages;
      }).finally(function () {
        return _this2.dataLoading = false;
      });
    }
  }, {
    key: 'previousPage',
    value: function previousPage() {
      if (this.pagination.currentPage === 1) return;
      this.pagination.currentPage--;
      this.loadPage(this.pagination.currentPage);
    }
  }, {
    key: 'nextPage',
    value: function nextPage() {
      if (this.pagination.currentPage === this.pagination.totalPages) return;
      this.pagination.currentPage++;
      this.loadPage(this.pagination.currentPage);
    }
  }, {
    key: 'loadRelations',
    value: function loadRelations() {
      var _this3 = this;

      var requestsFinished = 0;
      var requestsRequired = 3;
      this.relationDataLoading = true;
      this.BaseResource.index().then(function (data) {
        return _this3.bases = data;
      }).finally(function () {
        return _this3._handleDependencyLoadingIndicator(++requestsFinished, requestsRequired);
      });
      this.VehicleCallsignResource.index().then(function (data) {
        return _this3.vehicleCallsigns = data;
      }).finally(function () {
        return _this3._handleDependencyLoadingIndicator(++requestsFinished, requestsRequired);
      });
      this.CrewMemberResource.index({ filter: 'operational-crew', include: 'profile.profession,profile.image' }).then(function (data) {
        _this3.crewMembers = data.map(function (item) {
          return Object.assign({}, item, { name: item.first_name + ' ' + item.last_name });
        });
      }).finally(function () {
        return _this3._handleDependencyLoadingIndicator(++requestsFinished, requestsRequired);
      });
    }

    /**
     * Tabs
     */

  }, {
    key: 'selectTab',
    value: function selectTab(tab) {
      if (this.selectedTab === tab.id) return;
      if (this.tabs.map(function (item) {
        return item.id;
      }).indexOf(tab.id) === -1) {
        throw new Error('Invalid tab.');
      }
      this.selectedTab = tab.id;
      this.resetPagination();
      this.loadPage(1);
    }

    /**
     * Sidenavs & dialogs
     */

  }, {
    key: 'showSidenav',
    value: function showSidenav(shift) {
      this.selectedShift = (0, _angular.copy)(shift);
      this.$mdSidneav('shift-sidebar-item').open();
    }
  }, {
    key: 'showShiftDialog',
    value: function showShiftDialog(_ref) {
      var _this4 = this;

      var $event = _ref.$event;

      var dialogParams = {
        $event: $event,
        locals: {
          bases: this.bases,
          vehicleCallsigns: this.vehicleCallsigns
        }
      };

      this.ShiftDialog.show(dialogParams).then(function (shift) {
        _this4.loadPage(_this4.pagination.currentPage);
      });
    }

    /**
     * Helpers
     */

  }, {
    key: '_handleDependencyLoadingIndicator',
    value: function _handleDependencyLoadingIndicator(requestsFinished, requestsRequired) {
      if (requestsFinished === requestsRequired) {
        this.relationDataLoading = false;
      }
    }
  }]);

  return ShiftsPageController;
}();

exports.default = {
  controller: ShiftsPageController,
  templateUrl: 'pages/shifts/components/shifts-page/shifts-page.tpl.html'
};

},{"angular":"angular","common/resource/module.constants":57,"core/shifts/module.constants":251,"moment":"moment"}],403:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ModuleConfig;
function ModuleConfig($stateProvider) {
  'ngInject';

  $stateProvider.state('shifts', {
    url: '/shifts',
    template: '<shifts-page flex layout="column"></shifts-page>',
    data: {
      title: 'Shifts'
    }
  });
}

},{}],404:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _angular = require('angular');

var _angular2 = _interopRequireDefault(_angular);

var _angularUiRouter = require('angular-ui-router');

var _angularUiRouter2 = _interopRequireDefault(_angularUiRouter);

var _module2 = require('./module.config');

var _module3 = _interopRequireDefault(_module2);

var _shiftsPage = require('./components/shifts-page/shifts-page');

var _shiftsPage2 = _interopRequireDefault(_shiftsPage);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _module = _angular2.default.module('gnaas.pages.shifts', [_angularUiRouter2.default]).config(_module3.default).component('shiftsPage', _shiftsPage2.default);

exports.default = _module.name;

},{"./components/shifts-page/shifts-page":402,"./module.config":403,"angular":"angular","angular-ui-router":"angular-ui-router"}],405:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mapSearchCategories = mapSearchCategories;
exports.mapSearchCategoryName = mapSearchCategoryName;
exports.mapSearchProperty = mapSearchProperty;
exports.mapSearchResultHeading = mapSearchResultHeading;
/**
 * Helpers
 * Temporary - should be moved to app-wide utils
 */
function ucFirst(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function prettify(str) {
  return str.split('_').map(function (item) {
    return ucFirst(item);
  }).join(' ');
}

/**
 * Search category property mapping
 */

function mapSearchCategories(categories) {
  return Object.keys(categories).reduce(function (acc, cur) {
    if (cur === 'incidents') {
      acc[cur] = mapIncidentProperties(acc[cur]);
    }
    return acc;
  }, JSON.parse(JSON.stringify(categories)));
}

function mapIncidentProperties(properties) {
  return properties.map(function (item) {
    switch (item.name.toLowerCase()) {
      case 'incidents.incident_number':
        item.title = 'Referral Number';
        return item;

      case 'incidents.deployment_reason_id':
        item.title = 'Reason for Transfer';
        return item;

      case 'incidents.call_connect_time':
        item.title = 'Time to Retrieve';
        return item;

      case 'incidents.mission_refused_time':
        item.title = 'Task Declined Time';
        return item;

      case 'incidents.mission_accepted_time':
        item.title = 'Task Accepted Time';
        return item;

      case 'incidents.status':
        item.title = 'Referral Status';
        return item;

      default:
        return item;
    }
  });
}

/**
 * Search category name mapping
 */

var SEARCH_CATEGORY_NAME_MAP = {
  'incidents': 'referrals'
};

function mapSearchCategoryName(value) {
  var pretty = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

  var mappedName = SEARCH_CATEGORY_NAME_MAP[value] || value;
  return pretty ? prettify(mappedName) : mappedName;
}

/**
 * Search property mapping
 */

var INCIDENT_SEARCH_PROPERTY_MAP = {
  'incident_number': 'referral_number',
  'incident_date': 'referral_date',
  'incident_created_by': 'referral_created_by',
  'deployment_reason': 'reason_for_transfer',
  'call_connect_time': 'time_to_retrieve',
  'mission_refused_time': 'task_declined_time',
  'mission_accepted_time': 'task_accepted_time',
  'status': 'referral_status'
};

var DEPLOYMENT_SEARCH_PROPERTY_MAP = {
  'status': 'deployment_status'
};

function mapSearchProperty(category, value) {
  if (category === 'incidents') {
    return INCIDENT_SEARCH_PROPERTY_MAP[value] || value;
  } else if (category === 'deployments') {
    return DEPLOYMENT_SEARCH_PROPERTY_MAP[value] || value;
  }

  return value;
}

/**
 * Search result heading mapping
 */

function mapSearchResultHeading(value) {
  var parts = value.split('.');
  var category = mapSearchCategoryName(parts[0]);
  var property = mapSearchProperty(parts[0], parts[1]);
  return category + '.' + property;
}

},{}]},{},[4,1])

