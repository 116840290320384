export const oxygenOptionMap = {
  cpap: {
    label: '%',
    unitKey: 'pct',
    min: 21,
    max: 100,
  },
  'ippv-spont': {
    label: '%',
    unitKey: 'pct',
    min: 21,
    max: 100,
  },
  'ippv-mand': {
    label: '%',
    unitKey: 'pct',
    min: 21,
    max: 100,
  },
  niv: {
    label: '%',
    unitKey: 'pct',
    min: 21,
    max: 100,
  },
  sv: {
    label: 'Room Air',
    unitKey: 'room_air',
  },
  fm: {
    label: 'L/min',
    unitKey: 'l-min',
    min: 1,
    max: 15,
  },
  nc: {
    label: 'L/min',
    unitKey: 'l-min',
    min: 1,
    max: 15,
  },
  hfno: {
    label: '%',
    unitKey: 'pct',
    min: 21,
    max: 100,
  },
};

export const oxygenOptions = [
  { label: '-', value: null, modes: [] },
  { label: 'FiO2', value: 'fio2', modes: [] },
  { label: 'L/min', value: 'l-min', modes: [ 'fm', 'nc' ] },
  { label: '%', value: 'pct', modes: [ 'hfno', 'cpap', 'ippv-spont', 'ippv-mand', 'niv' ] },
  { label: 'Room Air', value: 'room_air', modes: [ 'sv' ] },
];

export const ventilationFields = [
  { name: 'oxygen', modes: [ 'sv', 'fm', 'nc', 'hfno', 'cpap', 'ippv-spont', 'ippv-mand', 'niv' ] },
  { name: 'respiratory_rate', modes: [ 'sv', 'fm', 'nc', 'hfno', 'cpap', 'ippv-spont', 'ippv-mand', 'niv' ] },
  { name: 'spo2', modes: [ 'sv', 'fm', 'nc', 'hfno', 'cpap', 'ippv-spont', 'ippv-mand', 'niv' ] },
  { name: 'etco2', modes: [ 'cpap', 'ippv-spont', 'ippv-mand', 'niv' ] },
  { name: 'vt', modes: [ 'ippv-spont', 'ippv-mand' ] },
  { name: 'mv', modes: [ 'cpap', 'ippv-spont', 'ippv-mand', 'niv' ] },
  { name: 'flow', modes: [ 'hfno' ] },
  { name: 'ppeak', modes: [ 'ippv-spont', 'ippv-mand' ] },
  { name: 'peep', modes: [ 'cpap', 'ippv-spont', 'ippv-mand', 'niv' ] },
  { name: 'psupp', modes: [ 'ippv-spont', 'ippv-mand', 'niv' ] },
  { name: 'ti', modes: [ 'ippv-mand' ] },
  { name: 'cpap', modes: [] },
  { name: 'fio2', modes: [] },
];

/**
 * This function is used to define which fields should be disabled
 * and which ones should be kept enabled
 */
export function applyVentilationIntelligence (obs) {
  obs.disabledFields.length = 0;

  if (obs.breathing_mode === null || obs.breathing_mode === 'sv') {
    obs.oxygen = null;
    obs.oxygenModeOptions = null;
    return true;
  }

  obs.oxygenModeOptions = oxygenOptionMap[obs.breathing_mode];

  ventilationFields.forEach((vField) => {
    if (!vField.modes.includes(obs.breathing_mode)) {
      obs[vField.name] = null;
      obs.disabledFields.push(vField.name);
    }
  });
};
