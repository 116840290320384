import { copy } from 'angular';
import uuid from 'node-uuid';
import moment from 'moment';
import deepEqual from 'deep-equal';
import {
  normalizeAdministeredInfusions,
  denormalizeAdministeredInfusionDetails,
  getUniqueInfusionDetailTimestamps,
} from 'core/prf/services/administered-infusion.service';

function createMarker (item) {
  const marker = { ...item.body_part.marker };

  if (item.intervention) {
    marker.class = 'green';
  } else if (item.status === 'amber') {
    marker.class = 'amber';
  } else if (item.status === 'red') {
    marker.class = 'red';
  }

  return marker;
}

class SurveysTabController {
  /**
   * @constructor
   * @ngInject
   */
  constructor (
    $q, $rootScope, $timeout, Dialog, IllnessDialog, InjuryDialog, InterventionDialog,
    Toast, SurveyResource, LaboratoryFindingsDialog, Session, TimestampDialog,
    InfusionDialog, DrugDialog
  ) {
    this.$q = $q;
    this.$rootScope = $rootScope;
    this.$timeout = $timeout;
    this.Dialog = Dialog;
    this.IllnessDialog = IllnessDialog;
    this.InjuryDialog = InjuryDialog;
    this.InterventionDialog = InterventionDialog;
    this.Toast = Toast;
    this.SurveyResource = SurveyResource;
    this.LabFindingsDialog = LaboratoryFindingsDialog;
    this.Session = Session;
    this.TimestampDialog = TimestampDialog;
    this.InfusionDialog = InfusionDialog;
    this.DrugDialog = DrugDialog;

    this.selectedSurveyOriginal = null;
    this.selectedSurveys = [];
    this.administeredInfusionDetails = [];
    this.prescribedInfusions = [];
    this.prescribedDrugs = [];
    this.bodyMarkers = [];
    this.viewAllSurveys = false;
    this.infusionRangeMap = {};

    this.onInfusionRangeChange = (id, minRate, maxRate) => {
      this.infusionRangeMap[id] = [minRate, maxRate];
    };
  }

  $onInit () {
    this.stateChangeListener = this.$rootScope.$on('$stateChangeStart', (e) => {
      if (!this.checkSelectedSurvey()) {
        e.preventDefault();
      }
    });

    const group = this.Session.user().group.slug;
    this.userCanPrescribe = ~[
      'medical-user-prescriber',
      'medical-staff-prescribers',
      'system-administrators',
    ].indexOf(group);
  }

  $onDestroy () {
    this.stateChangeListener();
  }

  $onChanges (changes) {
    const { isEditing, prf } = { ...changes };

    if (prf && prf.currentValue && prf.currentValue.surveys && prf.currentValue.surveys.length) {
      this.prf.surveys = this.prf.surveys.map(survey => {
        survey.deployment_status = this.calculateAssociatedDeploymentStatus(survey.time);
        return survey;
      });

      if (!this.selectedSurveys.length) {
        this.selectSurvey({ index: this.isEditing ? 0 : null });
      }
    }

    if (isEditing && this.prf && this.prf.surveys && this.prf.surveys.length) {
      this.selectSurvey({ index: isEditing.currentValue ? 0 : null });
    }
  }

  addSurvey () {
    if (!this.checkSelectedSurvey()) return false;
    if (!this.prf.surveys) {
      this.prf.surveys = [];
    }
    this.prf.surveys = [
      ...this.prf.surveys,
      {
        date: this.prf.deployment.date ? moment(this.prf.deployment.date).toDate() : new Date(),
        time: moment().startOf('minute').toDate(),
        observations: [],
        prescribed_drugs: [],
        administered_drugs: [],
        prescribed_infusions: [],
        administered_infusions: [],
        assessed_injuries: [],
        assessed_illnesses: [],
        administered_interventions: [],
        laboratory_findings: [],
        patient_report_form: { id: this.prf.id },
        deployment_status: null,
      },
    ];
    this.selectSurvey({ index: this.prf.surveys.length - 1 });
    return true;
  }

  selectSurvey ({ index }) {
    if (!this.checkSelectedSurvey()) return false;

    if (index == null) {
      this.selectAllSurveys();
    } else {
      this.selectSingleSurvey(index);
      this.denormalizeAdministeredInfusionDetails();
    }
    this.updateMarkers(this.selectedSurveys);
    return true;
  }

  addSurveyItem (key) {
    if (~['prescribed_drugs', 'prescribed_infusions'].indexOf(key) && !this.userCanPrescribe) return;

    const item = {
      id: uuid.v4(),
      date: moment(this.selectedSurveys[0].date).toDate(),
      time: moment().startOf('minute').toDate(),
    };

    if (~['prescribed_drugs', 'prescribed_infusions'].indexOf(key)) {
      item.prescribed_by = this.Session.user();
    }

    if (~['administered_drugs'].indexOf(key)) {
      item.administered_by = this.Session.user();
    }

    if (~['observations'].indexOf(key)) {
      item.disabledFields = [];
    }

    this.selectedSurveys[0][key].push(item);
  }

  removeSurveyItem ({ key, item }) {
    if (~['prescribed_drugs', 'prescribed_infusions'].indexOf(key)) {
      const surveyObjKey = key === 'prescribed_drugs' ? 'administered_drugs' : 'administered_infusions';
      if (this.selectedSurveys[0][surveyObjKey].find(i => i.prescription && i.prescription.id === item.id)) {
        const errMsg = 'This prescription has one or more matching administration records. ' +
          'Please remove the administration record/-s before deleting the prescription.';
        this.Dialog.alert(errMsg, 'Administration record found');
        return;
      }
    }

    this.Dialog.confirm('Are you sure you wish to delete this item?').then(() => {
      this.selectedSurveys[0][key] = this.selectedSurveys[0][key].filter(i => item.id !== i.id);
    });
  }

  addAdministeredInfusion () {
    this.normalizeAdministeredInfusions();
    this.selectedSurveys[0].administered_infusions.push({
      id: uuid.v4(),
      administered_by: this.Session.user(),
      details: [],
    });
    this.denormalizeAdministeredInfusionDetails();
  }

  addAdministeredInfusionDetails () {
    this.normalizeAdministeredInfusions();
    this.TimestampDialog.show({
      locals: {
        validateAgainst: getUniqueInfusionDetailTimestamps(this.selectedSurveys[0].administered_infusions),
        validationErrorMessage: 'Timestamp must be unique, this value already exists.',
      },
    }).then(timestamp => {
      this.selectedSurveys[0].administered_infusions.forEach(item => {
        item.details.push({
          id: uuid.v4(),
          rate: null,
          date: moment(timestamp.date).toDate(),
          time: moment(timestamp.time).startOf('minute').toDate(),
        });
      });
      this.denormalizeAdministeredInfusionDetails();
    });
  }

  removeAdministeredInfusion ({ item }) {
    this.normalizeAdministeredInfusions();
    this.Dialog.confirm('Are you sure you wish to delete this infusion?').then(() => {
      this.selectedSurveys[0].administered_infusions =
        this.selectedSurveys[0].administered_infusions.filter(i => i.id !== item.id);
      this.denormalizeAdministeredInfusionDetails();
    });
  }

  removeAdministeredInfusionDetails ({ item }) {
    this.normalizeAdministeredInfusions();
    this.Dialog.confirm('Are you sure you wish to delete these details?').then(() => {
      const removedDetailIds = item.rates.map(i => i.id);
      this.selectedSurveys[0].administered_infusions.forEach(infusion => {
        infusion.details = infusion.details.filter(i => removedDetailIds.indexOf(i.id) === -1);
      });
      this.denormalizeAdministeredInfusionDetails();
    });
  }

  saveSelectedSurvey () {
    if (this.form.$invalid) {
      const formsToCheck = {
        '$ctrl.surveyDetailsForm': 'Survey Details',
        '$ctrl.observationsForm': 'Observations',
        '$ctrl.prescribedDrugsForm': 'Prescribed Drugs',
        '$ctrl.administeredDrugsForm': 'Administered Drugs',
        '$ctrl.prescribedInfusionsForm': 'Prescribed Infusions',
        '$ctrl.administeredInfusionsForm': 'Administered Infusions',
      };
      const formsWithErrors = Object.keys(formsToCheck).reduce((acc, cur) => {
        if (this.form[cur] && this.form[cur].$invalid) return [...acc, formsToCheck[cur]];
        return acc;
      }, []);
      this.Dialog.alert(
        'Could not save the survey. Please validate the data for the following - ' + formsWithErrors.join(', ') + '.'
      );
      return;
    }

    this.normalizeAdministeredInfusions();

    const survey = this.selectedSurveys[0];
    if (survey.id) {
      this.updateSurvey(survey).then(() => {
        this.handleSurveySaved('Survey updated.');
      }).catch(() => {
        this.Toast.showSimple('Error updating survey.');
      });
    } else {
      this.createSurvey(survey).then(data => {
        this.selectedSurveys[0].id = data.id;
        this.handleSurveySaved('Survey created.');
      }).catch(() => {
        this.Toast.showSimple('Error creating survey.');
      });
    }
  }

  handleSurveySaved (toastMsg) {
    this.selectedSurveyOriginal = copy(this.selectedSurveys[0]);
    this.Toast.showSimple(toastMsg);
    if (typeof this.onPrfUpdate === 'function') {
      this.onPrfUpdate({ prf: this.prf });
    }
  }

  deleteSelectedSurvey () {
    const index = this.prf.surveys.indexOf(this.selectedSurveys[0]);

    this.Dialog.confirm('Once deleted, surveys can not be recovered.').then(() => {
      const survey = this.selectedSurveys[0];
      this.$q.when(survey.id ? this.SurveyResource.destroy(survey.id) : null)
        .then(data => {
          this.prf.surveys = this.prf.surveys.filter(item => {
            return data == null ? item !== survey : item.id !== survey.id;
          });
          this.handleSurveyDeleted(index);
        });
    });
  }

  handleSurveyDeleted (index) {
    this.clearSelectedSurvey();

    if (this.prf.surveys.length === 0) {
      return;
    }

    if (index > 0) {
      this.selectSurvey({ index: index - 1 });
    } else if (index === 0) {
      this.selectSurvey({ index: 0 });
    }

    this.Toast.showSimple('Survey deleted.');
    if (typeof this.onPrfUpdate === 'function') {
      this.onPrfUpdate({ prf: this.prf });
    }
  }

  discardSelectedSurveyChanges () {
    if (!this.isEditing) return;
    this.Dialog.confirm('This will reset all the changes you have made on the selected survey.').then(() => {
      Object.assign(this.selectedSurveys[0], this.selectedSurveyOriginal);
      this.selectedSurveys[0] = copy(this.selectedSurveyOriginal);
      this.denormalizeAdministeredInfusionDetails();
      this.infusionRangeMap = {};
    });
  }

  getSurveyOrderNumber (survey) {
    return this.prf.surveys.indexOf(survey) + 1;
  }

  selectAllSurveys () {
    this.viewAllSurveys = true;
    this.selectedSurveys = [...this.prf.surveys];
    this.selectedSurveyOriginal = null;
  }

  selectSingleSurvey (index) {
    this.viewAllSurveys = false;
    this.selectedSurveys = [this.prf.surveys[index]];
    this.selectedSurveyOriginal = copy(this.selectedSurveys[0]);
  }

  clearSelectedSurvey () {
    this.selectedSurveys = [];
    this.selectedSurveyOriginal = null;
  }

  checkSelectedSurvey () {
    if (!this.isEditing || !this.selectedSurveyOriginal || !this.selectedSurveys.length) return true;

    if (this.administeredInfusionDetails.length) {
      this.normalizeAdministeredInfusions();
    }

    const checkSurveyEquality = (one, two) => {
      const preProcess = (data) => ({
        ...data,
        prescribed_infusions: data.prescribed_infusions.map(pi => ({
          infusion: {
            ...pi.infusion,
            routes: [...pi.infusion.routes].sort((ra, rb) => ra.id.localeCompare(rb.id)),
          },
        })),

        administered_infusions: data.administered_infusions.map(pi => ({
          prescription: {
            ...pi.prescription,
            infusion: {
              ...pi.prescription.infusion,
              routes: [...pi.prescription.infusion.routes].sort((ra, rb) => ra.id.localeCompare(rb.id)),
            },
          },
        })),
      });

      const checkOne = preProcess(copy(one));
      const checkTwo = preProcess(copy(two));

      return deepEqual(checkOne, checkTwo, { strict: true });
    };

    if (
      (!this.selectedSurveys[0].id || !checkSurveyEquality(this.selectedSurveyOriginal, this.selectedSurveys[0]))
    ) {
      this.Dialog.alert(
        'Please save, delete or discard the changes on selected survey.',
        'Survey has been changed.'
      );
      return false;
    }

    return true;
  }

  showInjuryDialog ({ $event, data }) {
    const isEditing = data && data.id != null;
    const survey = this.selectedSurveys[0];
    this.InjuryDialog
      .show({
        $event: $event,
        item: data,
        relationData: { bodyParts: this.relationData.bodyParts },
      })
      .then(({ item, addAnother }) => {
        survey.assessed_injuries = this.handleCreateOrUpdate(isEditing, survey.assessed_injuries, item);
        if (addAnother) {
          const newItem = copy(item);
          delete newItem.id;
          delete newItem.notes;
          delete newItem.injury_type;
          this.showInjuryDialog({ $event, data: newItem });
        }
      }).catch(id => {
        survey.assessed_injuries = this.handleDelete(survey.assessed_injuries, id);
      }).finally(() => {
        this.updateMarkers([this.selectedSurveys[0]]);
      });
  }

  showIllnessDialog ({ $event, data }) {
    const isEditing = data && data.id != null;
    const survey = this.selectedSurveys[0];
    this.IllnessDialog
      .show({
        $event: $event,
        item: data,
        relationData: { bodyParts: this.relationData.bodyParts },
      })
      .then(({ item, addAnother }) => {
        survey.assessed_illnesses = this.handleCreateOrUpdate(isEditing, survey.assessed_illnesses, item);
        if (addAnother) {
          const newItem = copy(item);
          delete newItem.id;
          delete newItem.notes;
          delete newItem.illness_type;
          this.showIllnessDialog({ $event, data: newItem });
        }
      }).catch(id => {
        survey.assessed_illnesses = this.handleDelete(survey.assessed_illnesses, id);
      }).finally(() => {
        this.updateMarkers([this.selectedSurveys[0]]);
      });
  }

  showInterventionDialog ({ $event, data }) {
    const isEditing = data && data.id != null;
    const survey = this.selectedSurveys[0];
    this.InterventionDialog
      .show({
        $event: $event,
        item: data,
        relationData: {
          bodyParts: this.relationData.bodyParts,
          crewMembers: [...this.prf.deployment.crew_members],
        },
      })
      .then(({ item, addAnother }) => {
        survey.administered_interventions =
          this.handleCreateOrUpdate(isEditing, survey.administered_interventions, item);
        if (addAnother) {
          const newItem = copy(item);
          delete newItem.id;
          delete newItem.notes;
          delete newItem.performed_by_third_party;
          if (newItem.attributes) {
            newItem.attributes.forEach(attribute => {
              delete attribute.value;
              delete attribute.intervention_attribute_value;
            });
          }
          this.showInterventionDialog({ $event, data: newItem });
        }
      }).catch(id => {
        survey.administered_interventions = this.handleDelete(survey.administered_interventions, id);
      }).finally(() => {
        this.updateMarkers([this.selectedSurveys[0]]);
      });
  }

  showLabFindingsDialog ({ $event, data }) {
    const isEditing = data && data.id != null;
    const survey = this.selectedSurveys[0];
    this.LabFindingsDialog.show({
      $event: $event,
      item: data,
    }).then(({ item }) => {
      survey.laboratory_findings = this.handleCreateOrUpdate(isEditing, survey.laboratory_findings, item);
    }).catch(id => {
      if (!id) return;
      survey.laboratory_findings = this.handleDelete(survey.laboratory_findings, id);
    });
  }

  //
  // BODY MARKERS
  //

  updateMarkers (surveys) {
    this.bodyMarkers = [].concat(...surveys.map(survey => [
      ...survey.administered_interventions.map(intervention => createMarker(intervention)),
      ...survey.assessed_injuries.map(injury => createMarker(injury)),
      ...survey.assessed_illnesses.map(illness => createMarker(illness)),
    ]));
  }

  handleCreateOrUpdate (isEditing, array, item) {
    if (isEditing) {
      return array.map(i => i.id === item.id ? { ...i, ...item } : i);
    }
    return [...array, item];
  }

  handleDelete (array, deletedItemId) {
    return array.filter(({ id }) => id !== deletedItemId);
  }

  calculateAssociatedDeploymentStatus (surveyTime) {
    const time = moment.isMoment(surveyTime) ? surveyTime : moment(surveyTime);
    const statuses = this.prf.deployment.deployment_statuses;
    let status = null;

    statuses.forEach((item, i) => {
      if (time.isAfter(moment(item.time)) && (statuses[i + 1] && time.isBefore(moment(statuses[i + 1].time)))) {
        status = item.deployment_status.name;
        return;
      }
    });

    return status;
  }

  createSurvey (surveyObject) {
    const survey = this.parseSurveyData(copy(surveyObject));
    survey.id = uuid.v4();
    return this.SurveyResource.create(survey).then(() => survey);
  }

  updateSurvey (surveyObject) {
    const survey = this.parseSurveyData(copy(surveyObject));
    return this.SurveyResource.update(survey.id, survey);
  }

  parseSurveyData (survey) {
    survey.administered_infusions = survey.administered_infusions.map(infusion => ({
      ...infusion,
      details: infusion.details.filter(detail => detail.rate != null),
    }));
    return survey;
  }

  normalizeAdministeredInfusions () {
    this.selectedSurveys[0].administered_infusions = normalizeAdministeredInfusions(
      this.selectedSurveys[0].administered_infusions,
      this.administeredInfusionDetails
    );
  }

  denormalizeAdministeredInfusionDetails () {
    this.administeredInfusionDetails =
      denormalizeAdministeredInfusionDetails(this.selectedSurveys[0].administered_infusions);
  }

  showAddInfusionDialog () {
    this.InfusionDialog.show({
      locals: {
        relationData: this.relationData,
      },
    }).then(data => {
      this.relationData.infusions = [ ...this.relationData.infusions, data ];
    });
  }

  showAddDrugDialog () {
    this.DrugDialog.show({
      locals: {
        relationData: this.relationData,
        excludeFromStock: true,
        isNonFormulary: true,
        prf: this.prf,
      },
    }).then(data => {
      this.relationData.drugs = [ ...this.relationData.drugs, data ];
    });
  }
}

export default {
  controller: SurveysTabController,
  templateUrl: 'pages/requestLog/components/survey-tab/survey-tab.tpl.html',
  bindings: {
    isEditing: '<',
    prf: '<',
    relationData: '<',
    onPrfUpdate: '&',
  },
};
