import moment from 'moment';
import { DATE_FORMAT, TIME_FORMAT } from 'common/resource/module.constants';

export default function parse ({ oxygenModeOptions, oxygen, ...item }) {
  const dateStr = moment(item.date).format(DATE_FORMAT) + ' ' + moment(item.time).format(TIME_FORMAT);

  const i = {
    ...item,
    time: dateStr,
    pupil_size_left: item.pupil_size_left != null ? parseInt(item.pupil_size_left, 10) : null,
    pupil_size_right: item.pupil_size_right != null ? parseInt(item.pupil_size_right, 10) : null,
    nibp_systolic: item.nibp != null ? item.nibp.split('/')[0] : null,
    nibp_diastolic: item.nibp != null ? item.nibp.split('/')[1] : null,
    abp_systolic: item.abp != null ? item.abp.split('/')[0] : null,
    abp_diastolic: item.abp != null ? item.abp.split('/')[1] : null,
    gcs: item.gcs || null,
    oxygen: (oxygenModeOptions || {}).unitKey,
    oxygen_value: oxygen,
  };

  delete i.nibp;
  delete i.abp;
  delete i.disabledFields;

  return i;
};
