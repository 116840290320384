import moment from 'moment';
import {DATE_TIME_FORMAT} from 'common/resource/module.constants';
import { applyVentilationIntelligence, oxygenOptionMap } from 'core/prf/services/observations.service';

export default function format ({ oxygen, oxygen_value, ...item }) {
  const dateTime = moment(item.time, DATE_TIME_FORMAT).seconds(0).toDate();

  const i = {
    ...item,
    nibp: (item.nibp_systolic != null && item.nibp_diastolic != null)
      ? item.nibp_systolic + '/' + item.nibp_diastolic
      : null,
    abp: (item.abp_systolic != null && item.abp_diastolic != null)
      ? item.abp_systolic + '/' + item.abp_diastolic
      : null,
    etco2: item.etco2 != null ? parseFloat(item.etco2) : null,
    temp: item.temp != null ? parseFloat(item.temp) : null,
    fio2: item.fio2 != null ? parseFloat(item.fio2) : null,
    flow: item.flow != null ? parseFloat(item.flow) : null,
    mv: item.mv != null ? parseFloat(item.mv) : null,
    psupp: item.psupp != null ? parseFloat(item.psupp) : null,
    cpap: item.cpap != null ? parseFloat(item.cpap) : null,
    ti: item.ti != null ? parseFloat(item.ti) : null,
    time: dateTime,
    date: dateTime,
    disabledFields: [],
    oxygen: parseFloat(oxygen_value),
    oxygenModeOptions: oxygenOptionMap[item.breathing_mode],
    pupil_size_left: item.pupil_size_left != null ? `${item.pupil_size_left}` : null,
    pupil_size_right: item.pupil_size_right != null ? `${item.pupil_size_right}` : null,
  };

  delete item.nibp_systolic;
  delete item.nibp_diastolic;
  delete item.abp_systolic;
  delete item.abp_diastolic;

  applyVentilationIntelligence(i);

  return i;
};
