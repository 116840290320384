class AdministeredDrugsController {
  $onInit () {
    this.clearSearch();
  }

  getAdministeredDrugMap (administeredDrugs) {
    return administeredDrugs.reduce((drugMap, drug) => ({
      ...drugMap,
      [drug.id]: {
        prescription: drug.prescription,
      },
    }), {});
  }

  $onChanges (changes) {
    if (changes.administeredDrugs) {
      this.drugMap = this.getAdministeredDrugMap(changes.administeredDrugs.currentValue);
    }
  }

  onDrugChange (drug) {
    this.drugMap = this.getAdministeredDrugMap(this.administeredDrugs);

    if (!drug || !drug.prescription) return;
    drug.dose = drug.prescription.dose;
    drug.rate = 0;
  }

  initSearch (index) {
    this.openIndex = index;
  }

  clearSearch () {
    this.openIndex = null;
    this.searchTerm = '';
  }

  filterOptions (index) {
    if (this.openIndex === index) return this.searchTerm;
  }

  getClasses (name, index) {
    return {
      'has-errors': this.administeredDrugsForm[name + '_' + index].$invalid,
      't-no-padding': ~['prescription', 'date'].indexOf(name),
    };
  }

  getSelectedName (prescription) {
    return prescription && prescription.drug ? prescription.drug.name : '-';
  }

  getRateLimit (administeredDrugId, key) {
    if (!this.drugMap[administeredDrugId] || !this.drugMap[administeredDrugId].prescription) {
      return null;
    }

    if (key === 'min') {
      return this.drugMap[administeredDrugId].prescription.min_rate;
    }

    return this.drugMap[administeredDrugId].prescription.max_rate;
  }
}

export default {
  bindings: {
    isEditing: '<',
    viewAllSurveys: '<',
    surveys: '<',
    administeredDrugs: '<',
    prescriptions: '<',
    onAddClick: '&',
    onRemoveClick: '&',
  },
  controller: AdministeredDrugsController,
  templateUrl: 'core/prf/components/administered-drugs/administered-drugs.tpl.html',
};
