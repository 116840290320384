import { applyVentilationIntelligence, oxygenOptions } from 'core/prf/services/observations.service';

const bpPattern = /^([01]?[0-9][0-9]?|2[0-4][0-9]|300)\/([01]?[0-9][0-9]?|2[0-4][0-9]|150)$/;
const singleDecimalPattern = /^\d{1,2}(.\d{1})?$/;
const doubleDecimalPattern = /^\d{1,2}(.\d{1,2})?$/;

const ecgOptions = [
  { label: '-', value: null },
  { label: 'SR', value: 'sr' },
  { label: 'SB', value: 'sb' },
  { label: 'ST', value: 'st' },
  { label: 'SVT', value: 'svt' },
  { label: 'VT', value: 'vt' },
  { label: 'AF', value: 'af' },
  { label: 'Arrest - asyst.', value: 'arrest-asystole' },
  { label: 'Arrest - PEA', value: 'arrest-pea' },
  { label: 'Arrest - VF', value: 'arrest-vf' },
  { label: 'Arrest - VT', value: 'arrest-vt' },
];

const breathingModeOptions = [
  { label: '-', value: null },
  { label: 'SV', value: 'sv' },
  { label: 'FM', value: 'fm' },
  { label: 'NC', value: 'nc' },
  { label: 'HFNC', value: 'hfno' },
  { label: 'CPAP', value: 'cpap' },
  { label: 'NIV', value: 'niv' },
  { label: 'IPPV (Spont)', value: 'ippv-spont' },
  { label: 'IPPV (Mand)', value: 'ippv-mand' },
];

const pupilSizeOptions = [
  { label: '-', value: null },
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
  { label: '6', value: '6' },
  { label: '7', value: '7' },
  { label: '8', value: '8' },
];

const pupilReactionOptions = [
  { label: '-', value: null },
  { label: 'Reactive', value: 'reactive' },
  { label: 'Sluggish', value: 'sluggish' },
  { label: 'Unreactive', value: 'unreactive' },
];

const calculateGcs = function (obs) {
  if (obs.gcse == null && obs.gcsv == null && obs.gcsm == null) {
    obs.gcs = null;
    return;
  }
  obs.gcs = 0;
  if (obs.gcse !== null && !isNaN(obs.gcse)) obs.gcs += parseInt(obs.gcse, 10);
  if (obs.gcsv !== null && !isNaN(obs.gcsv)) obs.gcs += parseInt(obs.gcsv, 10);
  if (obs.gcsm !== null && !isNaN(obs.gcsm)) obs.gcs += parseInt(obs.gcsm, 10);
};

const gcsFieldRequired = function (field, obs) {
  return ['gcse', 'gcsv', 'gcsm'].filter(i => {
    return i !== field.name && !isNaN(obs[i]) && obs[i] !== null;
  }).length > 0;
};

const disableDecimals = function ($event) {
  if ($event.key === '.') $event.preventDefault();
};

class ObservationsController {
  $onInit () {
    this.fields = [
      { label: 'Date', name: 'date', type: 'date', required: true },
      { label: 'Time', name: 'time', type: 'time', required: true },
      { label: 'ECG', name: 'ecg', type: 'predefined', options: ecgOptions },
      { label: 'HR', name: 'pulse', type: 'number', min: 0, max: 300, onKeyDown: disableDecimals },
      { label: 'NIBP', name: 'nibp', type: 'string', placeholder: '###/###', pattern: bpPattern },
      { label: 'NIBP (Mean)', name: 'nibp_mean', type: 'number', min: 0, max: 150, onKeyDown: disableDecimals },
      { label: 'ABP', name: 'abp', type: 'string', placeholder: '###/###', pattern: bpPattern },
      { label: 'MAP', name: 'abp_mean', type: 'number', min: 0, max: 200, onKeyDown: disableDecimals },
      { label: 'Temp', name: 'temp', type: 'number', min: 20.0, max: 45.0, pattern: singleDecimalPattern },
      { label: 'Breathing Mode', name: 'breathing_mode', type: 'predefined', options: breathingModeOptions,
        onChange: applyVentilationIntelligence },
      { label: 'Oxygen', name: 'oxygen', type: 'oxygen', options: oxygenOptions },
      { label: 'RR', name: 'respiratory_rate', type: 'number', min: 0, max: 100, onKeyDown: disableDecimals },
      { label: 'SPO2', name: 'spo2', type: 'number', min: 0, max: 100, onKeyDown: disableDecimals },
      { label: 'ETCO2', name: 'etco2', type: 'number', min: 0.0, max: 16.0, pattern: singleDecimalPattern },
      { label: 'Vt', name: 'vt', type: 'number', min: 0, max: 1500, onKeyDown: disableDecimals },
      { label: 'MV', name: 'mv', type: 'number', min: 0, max: 25.00, pattern: doubleDecimalPattern },
      { label: 'FiO2', name: 'fio2', type: 'number', min: 0.21, max: 1.00, pattern: doubleDecimalPattern },
      { label: 'Ppeak', name: 'ppeak', type: 'number', min: 0, max: 50, onKeyDown: disableDecimals },
      { label: 'PEEP', name: 'peep', type: 'number', min: 0, max: 20, onKeyDown: disableDecimals },
      { label: 'Psupp', name: 'psupp', type: 'number', min: 0, max: 30.00, pattern: doubleDecimalPattern },
      { label: 'Ti', name: 'ti', type: 'number', min: 0, max: 2, pattern: doubleDecimalPattern },
      { label: 'CPAP', name: 'cpap', type: 'number', min: 0, max: 25.00, pattern: doubleDecimalPattern },
      { label: 'Flow', name: 'flow', type: 'number', min: 0, max: 80.00, pattern: doubleDecimalPattern },
      { label: 'GCSE', name: 'gcse', type: 'number', min: 1, max: 4, onKeyDown: disableDecimals,
        onChange: calculateGcs, required: gcsFieldRequired },
      { label: 'GCSV', name: 'gcsv', type: 'number', min: 1, max: 5, onKeyDown: disableDecimals,
        onChange: calculateGcs, required: gcsFieldRequired },
      { label: 'GCSM', name: 'gcsm', type: 'number', min: 1, max: 6, onKeyDown: disableDecimals,
        onChange: calculateGcs, required: gcsFieldRequired },
      { label: 'GCS', name: 'gcs', type: 'number', disabled: true },
      { label: 'Left Pupil Size', name: 'pupil_size_left', type: 'predefined', options: pupilSizeOptions },
      { label: 'Left Pupil Reaction', name: 'pupil_reaction_left', type: 'predefined', options: pupilReactionOptions },
      { label: 'Right Pupil Size', name: 'pupil_size_right', type: 'predefined', options: pupilSizeOptions },
      { label: 'Right Pupil Reaction', name: 'pupil_reaction_right', type: 'predefined',
        options: pupilReactionOptions },
      { label: 'Urine output (mls)', name: 'urine_output_mls', type: 'number', min: 0, max: 5000,
        onKeyDown: disableDecimals },
      { label: 'Drain output (mls)', name: 'drain_output_mls', type: 'number', min: 0, max: 5000,
        onKeyDown: disableDecimals },
    ];
  }

  isRequired (field, obs) {
    return typeof field.required === 'function' ? field.required(field, obs) : field.required;
  }
}

export default {
  bindings: {
    isEditing: '<',
    viewAllSurveys: '<',
    surveys: '<',
    onAddClick: '&',
    onRemoveClick: '&',
  },
  controller: ObservationsController,
  templateUrl: 'core/prf/components/observations/observations.tpl.html',
};
